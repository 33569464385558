import { ChangeEvent, useState, Dispatch, SetStateAction } from "react";
import { Button, Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { colors } from "../../../../components/styled/common";
import { MdError } from "@react-icons/all-files/md/MdError";
import { WarnMessageBox } from "../../../../components/styled";
import { breakPoints } from "../../../../components/styled/media";
import { useParams } from "react-router-dom";
import { fetchClient } from "../../../../lib/api/axios";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { add_owner_in_room } from "../../../../lib/api/queries/urls";
import { IRoom } from "../../../../lib/types/Imodels";
import { queryClient } from "../../../..";
import { room_list_or_detail } from "../../../../lib/api/queries/keys";

interface isActiveOption {
  isActiveOption: boolean;
}

const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ModalTitle = styled.p`
  color: #111827;
  font-family: Noto Sans KR;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const SubTitle = styled.span`
  color: #6b7280;
  font-family: Noto Sans CJK KR;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 12px;
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
`;

const SubmitButton = styled(Button)`
  display: flex;
  max-width: 214px;
  max-height: 100px;
  min-height: 42px;
  height: auto;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 1;
  border-radius: 8px;
  background-color: #2563eb;
  opacity: ${(props: isActiveOption) => (props.isActiveOption ? "1" : "0.4")};
  color: #ffffff;

  &:hover {
    background-color: #2563eb;
    box-shadow: none;
  }
  :hover[disabled] {
    background-color: #2563eb;
    box-shadow: none;
  }
`;

const PhoneNumberContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const TextBox = styled.input`
  appearance: none;
  width: 100%;
  border: 1px solid ${colors.gray3};
  padding: 0.75rem;
  border-radius: 0.5rem;
  color: ${colors.gray4};
  ::placeholder {
    color: ${colors.gray2};
  }
  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
    border: 1px solid ${colors.gray2};
  }
`;

const NameLength = styled.p`
  color: #9ca3af;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px; /* 150% */
`;

const ComplaintWarnBox = styled(WarnMessageBox)`
  margin-top: 8px;
  font-size: 1rem;
  align-items: flex-start;

  svg {
    width: 16px;
    height: 16px;
  }
  div {
    word-break: keep-all;
    width: calc(100% - 1.5rem);
    color: #ff3d3d;
    font-family: "Noto Sans CJK KR";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
  }
  @media ${breakPoints.mobile} {
    svg {
      margin: 3px 4px 0 0;
    }
  }
`;

interface owners {
  name: string;
  phone_number: string;
}

export default function OwnerAddModal(props: {
  onClose: () => void;
  isOpen: boolean;
  ownerList: owners[];
  setOwnerList: Dispatch<SetStateAction<owners[]>>;
}) {
  const { room_id } = useParams();

  const [name, setName] = useState<string>("");
  const [middlePhoneNumber, setMiddlePhoneNumber] = useState<string>("");
  const [lastPhoneNumber, setLastPhoneNumber] = useState<string>("");
  const [duplicatePhoneNumber, setDuplicatePhoneNumber] = useState<boolean>(false);

  const submitValidation = name.length > 1 && middlePhoneNumber.length > 2 && lastPhoneNumber.length > 3;

  const onCloseDrawer = () => {
    props.onClose && props.onClose();
    return;
  };

  const numbericValidate = (paramText: string) => {
    const numericRegex = /^[0-9]+$/;

    return numericRegex.test(paramText);
  };

  const nameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const middleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (numbericValidate(e.target.value) || e.target.value === "") {
      setMiddlePhoneNumber(e.target.value);
    }
  };

  const lastNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (numbericValidate(e.target.value) || e.target.value === "") {
      setLastPhoneNumber(e.target.value);
    }
  };

  // 호실 소유주 추가 api 호출
  const addOwnerInRoom = () => {
    fetchClient
      .patch<BaseResponse<IRoom>>(add_owner_in_room, {
        room_id: room_id,
        name: name,
        phone_number: "010" + middlePhoneNumber + lastPhoneNumber,
      })
      .then((res) => {
        queryClient.invalidateQueries([room_list_or_detail]);
        props.onClose();
        return res.data.data;
      });
  };

  const onSaveClick = () => {
    // 연락처 중복 검사
    const phoneNumber = "010" + middlePhoneNumber + lastPhoneNumber;

    for (let i = 0; i < props.ownerList.length; i++) {
      if (phoneNumber === props.ownerList[i].phone_number) {
        setDuplicatePhoneNumber(true);
        return;
      }
    }

    if (room_id !== undefined) {
      addOwnerInRoom();
    } else {
      const tempOwnerList = [...props.ownerList];
      tempOwnerList.push({ name: name, phone_number: phoneNumber });
      props.setOwnerList(tempOwnerList);
    }

    onCloseDrawer();
  };

  return (
    <Modal isOpen={props.isOpen} onClose={onCloseDrawer} variant="default" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <ModalHead>
            <ModalTitle>소유자 등록</ModalTitle>
            <span style={{ color: "#2563EB" }}>* 필수</span>
          </ModalHead>
          <div style={{ marginBottom: "48px" }}>
            <div style={{ marginBottom: "24px" }}>
              <div style={{ marginBottom: "12px" }}>
                <SubTitle>소유자의 성함을 입력해주세요.</SubTitle>
                <span style={{ color: "#2563EB" }}>*</span>
              </div>
              <TextBox
                style={{ width: "100%", marginBottom: "4px" }}
                // disabled={true}
                value={name}
                placeholder="예시 : 홍길동"
                onChange={nameChange}
                maxLength={15}
              />
              <NameLength>{name.length}/15</NameLength>
            </div>
            <div>
              <div style={{ marginBottom: "12px" }}>
                <SubTitle>해당 소유자의 연락처를 입력해주세요.</SubTitle>
                <span style={{ color: "#2563EB" }}>*</span>
              </div>
              <PhoneNumberContainer>
                <TextBox disabled={true} value={"010"} />
                <TextBox value={middlePhoneNumber} onChange={middleNumberChange} maxLength={4} />
                <TextBox value={lastPhoneNumber} onChange={lastNumberChange} maxLength={4} />
              </PhoneNumberContainer>
            </div>
            {duplicatePhoneNumber && (
              <ComplaintWarnBox>
                <MdError />
                <div>이미 등록된 휴대폰 번호입니다.</div>
              </ComplaintWarnBox>
            )}
          </div>
          <BottomContainer>
            <Button onClick={() => props.onClose()} w="100%" variant="m_gray" minW="142px">
              취소
            </Button>
            <SubmitButton
              w="100%"
              minW="142px"
              isActiveOption={submitValidation}
              isDisabled={!submitValidation}
              onClick={onSaveClick}
            >
              저장하기
            </SubmitButton>
          </BottomContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
