import * as S from "../styled";
import * as C from "../../../components/styled";
import { IOpenSection } from "../types";
import { useFormContext } from "react-hook-form";
import { ChangeEvent } from "react";
import { onPreventInput } from "../../../components/hooks/useInputEvent";

// 2. 입주자 현황
export default function TenantStatus(props: IOpenSection) {
  const { register, setValue, formState } = useFormContext();

  const onSetValues = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const id = e.target.id;

    if (id === "status.tenant_registration_number" || id === "status.tenant_num")
      setValue(id, value.replace(/(^0+)/, ""));
  };

  return (
    <S.ToggleBox>
      <S.ToggleTitle onClick={() => props.setIsOpen((prev) => !prev)}>
        2. 입주자 현황
        <S.ToggleIcon src="/arrow.png" alt="화살표 아이콘" isOpen={props.isOpen} />
      </S.ToggleTitle>
      <S.ToggleContent
        id="status"
        isHeight={(document.getElementById("status")?.scrollHeight || 0) + 20}
        isOpen={props.isOpen}
      >
        <S.InputBox>
          <S.InputName>
            업체명 (개인의 경우 성함 기재) <span>*</span>
          </S.InputName>
          <input
            onKeyDown={(e) => {
              if (e.currentTarget.value.length > 99) e.preventDefault();
            }}
            {...register("status.tenant_name")}
          />
        </S.InputBox>
        <S.InputBox>
          <S.InputName>
            사업자(주민)등록번호 <span>*</span>
          </S.InputName>
          <input
            type="number"
            id="status.tenant_registration_number"
            onKeyDown={onPreventInput}
            onWheel={(e) => e.currentTarget.blur()}
            {...register("status.tenant_registration_number", {
              onChange: (e) => onSetValues(e),
            })}
          />
        </S.InputBox>
        <S.InputBox>
          <S.InputName>
            입주사 대표자명 <span>*</span>
          </S.InputName>
          <input {...register("status.ceo_name")} />
        </S.InputBox>
        <S.NumberBox>
          <S.InputName style={{ padding: "0" }}>
            입주사 대표 연락처 <span>*</span>
          </S.InputName>
          <S.InfoText>해당 연락처로 건물 안내가 전달 될 예정이니 전화번호를 정확히 입력해주세요.</S.InfoText>
          <S.PhoneNumberBox style={{ paddingTop: "8px" }}>
            <input
              type="number"
              style={{ margin: "0 4px" }}
              onKeyDown={(e) => onPreventInput(e, 3)}
              onWheel={(e) => e.currentTarget.blur()}
              {...register("status.first")}
            />
            -
            <input
              type="number"
              style={{ margin: "0 4px" }}
              onKeyDown={(e) => onPreventInput(e, 3)}
              onWheel={(e) => e.currentTarget.blur()}
              {...register("status.second")}
            />
            <input
              type="number"
              style={{ margin: "0 4px" }}
              onKeyDown={(e) => onPreventInput(e, 3)}
              onWheel={(e) => e.currentTarget.blur()}
              {...register("status.third")}
            />
          </S.PhoneNumberBox>
        </S.NumberBox>
        <S.InputBox>
          <S.InputName>
            이메일 (세금 계산서 발행용) <span>*</span>
          </S.InputName>
          <input type="email" {...register("status.email")} />
          <C.WarnMessageBox style={{ fontWeight: 400, padding: "4px 0px 0px 4px" }}>
            <>{formState.errors.email?.message}</>
          </C.WarnMessageBox>
        </S.InputBox>
        <S.InputBox>
          <S.InputName>팩스번호</S.InputName>
          <input
            type="number"
            onWheel={(e) => e.currentTarget.blur()}
            style={{ marginRight: "4px" }}
            onKeyDown={onPreventInput}
            {...register("status.fax")}
          />
        </S.InputBox>
        <S.NumberBox>
          <S.InputName>직원 및 동거인 수</S.InputName>
          <input
            id="status.tenant_num"
            type="number"
            onKeyDown={(e) => onPreventInput(e, 3)}
            onWheel={(e) => e.currentTarget.blur()}
            style={{ marginRight: "4px" }}
            {...register("status.tenant_num", {
              onChange: (e) => onSetValues(e),
            })}
          />
          명
        </S.NumberBox>
      </S.ToggleContent>
    </S.ToggleBox>
  );
}
