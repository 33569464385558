import { useContext, useState } from "react";
import { GlobalContext } from "../../../../App";
import { Text, Button, DrawerBody, useDisclosure, UseModalProps } from "@chakra-ui/react";
import { DetailBoxInDrawer, DetailsInfo, GrayInfoBox, UpdateIconBox } from "../../../../components/styled/webApp";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import CommonDrawerModal from "../../../../components/Drawer";
import EditModalNoHeader from "../../../../components/modal/responsive/edit";
import RespCancelModal from "../../../../components/modal/responsive/cancel";
import GrayRadiusTextArea from "../../../../components/textarea";
import ChangedPassword from "../components/password";
import DesignationDepartmentAuth from "../components/designation";
import { IUser } from "../../../../lib/types/Imodels";
import { useMutation } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { profile_delete } from "../../../../lib/api/queries/urls";
import { AxiosError } from "axios";
import { queryClient } from "../../../..";
import { user_list_key } from "../../../../lib/api/queries/keys";
import { IUserDepartment } from "../commons/types";
import EditUserInfoInModal from "../components/editInfo";
import { useMutationUserInfo } from "../commons/queries";
import { toast } from "../../../../lib/utils/toast";

interface IUserInfoDrawer extends UseModalProps {
  data: IUser;
}

//내 정보 + 관리자 정보 보는 drawer
export default function UserInfoInDrawer({ data, ...props }: IUserInfoDrawer) {
  const { user } = useContext(GlobalContext); //로그인한 계정
  const { editUser } = useMutationUserInfo();

  const { isOpen: isInfo, onOpen: onInfo, onClose: offInfo } = useDisclosure(); //관리자 정보수정 modal
  const { isOpen: isRole, onOpen: onRole, onClose: offRole } = useDisclosure(); //관리자 권한 modal
  const { isOpen, onOpen, onClose } = useDisclosure(); //비밀번호 modal
  const { isOpen: isDel, onOpen: onDel, onClose: offDel } = useDisclosure(); //관리자 삭제 modal

  const isMobile = window.navigator.userAgent.includes("Mobi");
  const isMine = user?._id === data._id;

  const initial = {
    auth: String(data.auth_id._id),
    team: String(data.department_id ? data.department_id._id : ""),
    description: data.description || "",
  };
  const [roles, setRoles] = useState<IUserDepartment>(initial);

  const onChangeDepartmentAuth = async () => {
    if (!roles.auth || (data.user_type !== "master" && !roles.team)) return;

    const formData = new FormData();
    formData.append("user_id", String(data._id));
    formData.append("auth_id", roles.auth);
    data.user_type !== "master" && formData.append("department_id", roles.team);
    formData.append("description", roles.description);

    try {
      await editUser.mutateAsync(formData);

      offRole();
      toast.success({
        title: "관리자 정보 변경",
        description: "관리자 정보가 변경되었습니다.",
      });
    } catch (err) {
      if (editUser.error?.response?.status === 504)
        return toast.warning({
          title: "관리자 정보 변경",
          description: "존재하지 않는 관리자입니다.",
        });

      toast.warning({
        title: "관리자 정보 변경",
        description:
          "일시적으로 관리자 수정에 실패했습니다. 새로고침 후에도 동일한 오류가 반복될 경우 오피스너 담당자에게 문의해주세요.",
      });
    }
  };

  const mutation = useMutation(() =>
    fetchClient
      .post(profile_delete, { user_id: String(data._id) })
      .then((res) => {
        offDel();
        props.onClose();
        queryClient.invalidateQueries([user_list_key]);
        toast.success({
          title: "관리자 삭제 완료",
          description: "관리자가 정상적으로 삭제되었습니다. 관리자 목록 페이지로 이동합니다.",
        });

        return res.data;
      })
      .catch((err: AxiosError) => {
        console.error(err);
        toast.warning({
          title: "관리자 삭제 실패",
          description: "관리자 삭제에 실패했습니다. 새로고침 후 다시 시도해주세요.",
        });
      }),
  );

  const onDeleteManager = () => {
    if (mutation.isLoading) return;
    mutation.mutate();
  };

  return (
    <CommonDrawerModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={data.name}
      maxW={isMobile ? "100%" : undefined}
    >
      <DrawerBody>
        <DetailBoxInDrawer>
          <div className="header" style={{ paddingBottom: "0" }}>
            <h4>기본 정보</h4>
            {isMine && (
              <UpdateIconBox onClick={onInfo}>
                수정하기
                <FiChevronRight />
              </UpdateIconBox>
            )}
          </div>
          <DetailsInfo>{data.name}</DetailsInfo>
          {data.phone_number && <DetailsInfo>{data.phone_number}</DetailsInfo>}
          {data.email && <DetailsInfo>{data.email}</DetailsInfo>}
        </DetailBoxInDrawer>
        {isInfo && <EditUserInfoInModal isOpen={isInfo} onClose={offInfo} data={data} />}
        <DetailBoxInDrawer>
          <div className="header">
            <h4>소속 및 권한 정보</h4>
            <UpdateIconBox onClick={onRole}>
              수정하기
              <FiChevronRight />
            </UpdateIconBox>
          </div>
          <GrayInfoBox>{data.department_id?.name || "전체"}</GrayInfoBox>
          <GrayInfoBox>{data.auth_id?.name}</GrayInfoBox>
          <GrayRadiusTextArea
            readOnly={true}
            _style={{ fontSize: "16px" }}
            value={data.description || ""}
            placeholder="직무, 직위 등을 입력해주세요."
            _onChange={() => console.log("입력 없음")}
          />
        </DetailBoxInDrawer>
        {isRole && (
          <EditModalNoHeader
            value="변경"
            isOpen={isRole}
            onClickUpdate={onChangeDepartmentAuth}
            onClose={() => {
              offRole();
              setRoles(initial);
            }}
          >
            <DesignationDepartmentAuth isSingle={true} data={roles} setData={setRoles} auth={data.auth_id.name} />
          </EditModalNoHeader>
        )}
        <DetailBoxInDrawer>
          <div className="header">
            <h4>비밀번호 변경</h4>
          </div>
          <Button w="8rem" variant="m_gray" onClick={onOpen}>
            비밀번호 변경
          </Button>
        </DetailBoxInDrawer>
        {isOpen && <ChangedPassword onClose={onClose} isOpen={isOpen} data={data} isNeed={user?._id === data._id} />}
        {user?.user_type === "master" && data.user_type !== "master" && (
          <DetailBoxInDrawer>
            <div className="header">
              <h4>관리자 삭제</h4>
            </div>
            <div style={{ marginBottom: "0.75rem" }}>
              관리자 삭제시 해당 관리자는 더 이상 관리시스템에 접속할 수 없습니다.
            </div>
            <Text variant="text_red" onClick={onDel} textDecoration="underline" cursor="pointer">
              관리자 삭제
            </Text>
          </DetailBoxInDrawer>
        )}
      </DrawerBody>
      {isDel && (
        <RespCancelModal
          isOpen={isDel}
          onClose={offDel}
          title="관리자를 삭제할까요?"
          next="삭제"
          onAction={onDeleteManager}
        >
          관리자 삭제 후에는 더 이상 관리시스템에 접속할 수 없습니다.
        </RespCancelModal>
      )}
    </CommonDrawerModal>
  );
}
