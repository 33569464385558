import { useContext } from "react";
import { NewRequestContext } from "../../_view/SubmitNewRequest";
import { IEditComplaint } from "../../commons/types";
import { DeleteIcon, ImageArr, ShowImages } from "../../../../components/styled";

export default function RequestUploadFile(props: IEditComplaint) {
  const { files, setFiles } = useContext(NewRequestContext);

  const onEditFile = (idx: number) => {
    if (!props.images) return;
    const newArr = props.images.filter((_, index) => index !== idx);

    props.setImages && props.setImages(newArr);
  };

  const onDeleteFiles = (idx: number) => {
    const newArr = files?.filter((_, index) => index !== idx);

    setFiles && setFiles(newArr?.length === 0 ? undefined : newArr);
  };

  return (
    <ImageArr>
      {props.images &&
        props.images.map((el, index) => (
          <div key={index}>
            <ShowImages src={el} alt="민원 이미지" />
            <DeleteIcon onClick={() => onEditFile(index)} />
          </div>
        ))}
      {Array.from(files || []).map((el, index) => (
        <div key={`local${index}`}>
          <ShowImages src={URL.createObjectURL(el)} alt="민원 이미지" />
          <DeleteIcon onClick={() => onDeleteFiles(index)} />
        </div>
      ))}
    </ImageArr>
  );
}
