import {
  Text,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  ModalFooter,
  Select,
  useToast,
  UseModalProps,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import * as C from "./styled";
import * as S from "../../../components/styled";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { ContentBox, DoubleColumn, TitleBox } from "../../company/units/styled";
import { CalendarRange } from "../../../components/datePicker";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import { IRegularCar } from "../../../lib/types/Imodels";
import { format_progress } from "../../../lib/utils/dataFormat";
import { useMutation } from "@tanstack/react-query";
import { fetchClient } from "../../../lib/api/axios";
import { approve_car, update_car } from "../../../lib/api/queries/urls";
import { queryClient } from "../../..";
import { success, unAuthUser, warning } from "../../../lib/theme/toast";
import { parking_history_keys } from "../../../lib/api/queries/keys";
import useUserAuth from "../../../components/hooks/useUserAuth";

type updateInfo = {
  car_id: IRegularCar;
  is_paid?: string;
  end_date?: Date | null | string;
  start_date?: Date | null | string;
};

interface IVehicleInfo extends UseModalProps {
  el: IRegularCar;
}

export default function VehicleInformation(props: IVehicleInfo) {
  const toast = useToast();
  const auth = useUserAuth("car");
  const isCanceled = props.el.progress_type === "canceled";
  const isApproval = props.el.progress_type === "approval_requested";
  const [payment, setPayment] = useState("");
  const [onDate, setOnDate] = useState<Date | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure(); //등록, 수정 확인창

  useEffect(() => {
    setPayment(props.el.is_paid !== undefined ? String(props.el.is_paid) : "");
    setOnDate(props.el.end_date ? props.el.end_date : null);
  }, [props.el, props.el.progress_type]);

  const mutation = useMutation((date: updateInfo) =>
    fetchClient
      .post(isApproval ? approve_car : update_car, date)
      .then((res) => {
        toast({
          ...success,
          title: "등록 상태 변경 완료",
          description: "정기 주차 차량의 상태가 변경되었습니다.",
        });
        queryClient.invalidateQueries([parking_history_keys]);
        onClose();
        props.onClose();
        return res.data.data;
      })
      .catch((err) => {
        if (err.response?.status === 401) return toast({ ...unAuthUser });
        toast({
          ...warning,
          title: "등록 상태 변경 오류",
          description: "정기 주차 차량 상태 변경에 실패했습니다. 정보를 다시 확인 후 등록해주세요.",
        });
      }),
  );

  const onChangeStatus = () => {
    const newObj: updateInfo = { car_id: props.el._id };
    newObj.is_paid = payment;

    if (isApproval) {
      // 신청서 -> 둘다 무조건 변화
      newObj.start_date = `${moment(onDate).format("YYYY-MM-DDT00:00:00")}Z`;
      return mutation.mutate(newObj);
    } else if (onDate === null) {
      return mutation.mutate(newObj); //여기서부터는 변경
    } else {
      newObj.end_date = `${moment(onDate).format("YYYY-MM-DDT00:00:00")}Z`;
    }

    mutation.mutate(newObj);
  };

  return (
    <Modal size="xl" variant="contents" isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent minW="912px">
        <ModalHeader>{isApproval ? "신규 정기 주차 신청서" : "정기 주차 차량 정보"}</ModalHeader>
        <ModalBody>
          <C.ModalInfoBox>
            {isApproval ? (
              <p>
                신규 정기 주차 신청서 입니다. <br />
                정기 주차 차량 등록을 하시려면
                <span> 서비스 시작일자와 유/무료 여부</span>를 선택하여 등록해주세요.
              </p>
            ) : (
              <p>
                정기 주차 차량 정보입니다. <br />
                <span>유/무료 여부 변경과 서비스 종료일자(해지일) 설정</span>이 가능합니다. 정보 변경시 입주자도 앱에서
                확인이 가능합니다.
              </p>
            )}
          </C.ModalInfoBox>
          <div>
            <Text variant="title">1. 차주 정보</Text>
            <DoubleColumn>
              <TitleBox>입주사명</TitleBox>
              <ContentBox>{props.el.tenant_name}</ContentBox>
            </DoubleColumn>
            <DoubleColumn>
              <TitleBox>차주이름</TitleBox>
              <ContentBox>{props.el.user_name}</ContentBox>
            </DoubleColumn>
            <DoubleColumn>
              <TitleBox>차주 연락처</TitleBox>
              <ContentBox>{props.el.user_phone_number}</ContentBox>
            </DoubleColumn>
          </div>
          <S.IntervalBox>
            <Text variant="title">2. 차량 정보</Text>
            <DoubleColumn>
              <TitleBox>차종</TitleBox>
              <ContentBox>{props.el.car_model || ""}</ContentBox>
              <TitleBox>차량번호</TitleBox>
              <ContentBox>{props.el.car_number}</ContentBox>
            </DoubleColumn>
            <DoubleColumn>
              <TitleBox>차량등록증/이면계약서</TitleBox>
              <C.LinkBox>
                {props.el.car_registration_files.map((el, index) => (
                  <a href={el} key={el} target="_blank" rel="noreferrer">
                    {el.includes(".pdf") ? <span>차량 등록증 {index + 1}.pdf</span> : <img src={el} />}
                  </a>
                ))}
              </C.LinkBox>
            </DoubleColumn>
          </S.IntervalBox>
          <S.IntervalBox>
            <Text variant="title">3. 등록 상태</Text>
            <DoubleColumn>
              <TitleBox>등록 상태</TitleBox>
              <ContentBox>
                {format_progress(props.el.progress_type)}
                {props.el.progress_date ? `${moment.utc(props.el.progress_date).format("(YYYY. MM. DD HH:mm)")}` : ""}
              </ContentBox>
            </DoubleColumn>
            <DoubleColumn>
              <TitleBox>결제 수단</TitleBox>
              <ContentBox>
                {props.el.payment_method === "person" ? "개인이 직접 결제" : "입주 호실 일괄 결제"}
              </ContentBox>
              <TitleBox style={{ marginRight: "16px" }}>유/무료 여부</TitleBox>
              <ContentBox>
                {isCanceled || auth !== "edit" ? (
                  <>{props.el.is_paid ? "유료" : "무료"}</>
                ) : (
                  <Select width="180px" value={payment} variant="default" onChange={(e) => setPayment(e.target.value)}>
                    <option value="" disabled>
                      유/무료 선택
                    </option>
                    <option value="true">유료</option>
                    <option value="false">무료</option>
                  </Select>
                )}
              </ContentBox>
            </DoubleColumn>
            <DoubleColumn>
              <TitleBox>서비스 시작 일자</TitleBox>
              {isApproval && auth === "edit" ? (
                <C.CalendarBoxInParking>
                  <DatePicker
                    locale={ko}
                    dateFormat="yyyy. MM. dd"
                    onChange={(date: Date | null) => setOnDate(date)}
                    selected={onDate === null ? null : new Date(onDate)}
                    customInput={
                      <CalendarRange style={{ width: "184px" }} defaultValue="시작일 선택" value={String(onDate)} />
                    }
                  />
                </C.CalendarBoxInParking>
              ) : (
                <ContentBox>{moment(props.el.start_date).format("YYYY. MM. DD")}</ContentBox>
              )}
              {!isApproval && (
                <>
                  <TitleBox style={{ marginRight: "16px" }}>서비스 종료 일자(해지일)</TitleBox>
                  {isCanceled || auth !== "edit" ? (
                    <ContentBox>{props.el.end_date ? moment(props.el.end_date).format("YYYY. MM. DD") : ""}</ContentBox>
                  ) : (
                    <C.CalendarBoxInParking>
                      <DatePicker
                        locale={ko}
                        isClearable
                        dateFormat="yyyy. MM. dd"
                        selected={onDate === null ? null : new Date(onDate)}
                        onChange={(date: Date | null) => setOnDate(date)}
                        customInput={
                          <CalendarRange defaultValue="종료일 선택" value={String(onDate)} style={{ width: "184px" }} />
                        }
                      />
                    </C.CalendarBoxInParking>
                  )}
                </>
              )}
            </DoubleColumn>
          </S.IntervalBox>
        </ModalBody>
        <ModalFooter>
          {isCanceled || auth !== "edit" ? (
            <Button variant="cancel" onClick={props.onClose}>
              확인
            </Button>
          ) : (
            <>
              <Button variant="cancel" onClick={props.onClose}>
                취소
              </Button>
              <Button
                variant="bgBlue"
                onClick={onOpen}
                isDisabled={
                  isApproval
                    ? payment === "" || onDate === null //신규신청은 모두해야..
                    : payment === String(props.el.is_paid) && onDate === null
                }
              >
                {isApproval ? "정기 주차 차량 등록하기" : "저장하기"}
              </Button>
              {/* 취소일땐 나오지 않을 모달 */}
              <ConfirmModal
                isOpen={isOpen}
                close={isApproval ? "등록하기" : "변경하기"}
                title={isApproval ? "차량 등록" : "정기 주차 등록 상태 변경"}
                onClose={onClose}
                onClickCancle={onClose}
                blockScrollOnMount={false}
                onClickSave={onChangeStatus}
                styleConfig={{ marginTop: isApproval ? "4rem" : "2rem" }}
              >
                {isApproval ? (
                  <>
                    <Text>해당 차량을 정기 주차 차량으로 등록합니다.</Text>
                    <Text>등록하시겠습니까?</Text>
                  </>
                ) : (
                  <>
                    <Text>변경되는 항목을 다시 한 번 확인해주세요.</Text>
                    <ul style={{ fontWeight: "500", padding: "0 0 0 24px" }}>
                      {payment !== String(props.el.is_paid) && (
                        <li>
                          유/무료 여부 : {props.el.is_paid ? "유료" : "무료"} →{payment === "true" ? " 유료" : " 무료"}
                        </li>
                      )}
                      {onDate !== null && <li>서비스 종료 일자(해지일) :{moment(onDate).format(" YYYY. MM. DD")}</li>}
                    </ul>
                    <Text pt="24px">위 내용으로 정보를 변경할까요?</Text>
                  </>
                )}
              </ConfirmModal>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
