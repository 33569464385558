import { HTMLAttributes } from "react";

export type Props = object & HTMLAttributes<HTMLOrSVGElement>;

export default function IconCheck(props: Props) {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        {...props}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8655 5.81502C16.1057 6.06288 16.0996 6.45856 15.8517 6.69879L8.1136 14.1988C7.87119 14.4337 7.48603 14.4337 7.24363 14.1988L4.14839 11.1988C3.90053 10.9586 3.89435 10.5629 4.13458 10.315C4.37482 10.0672 4.7705 10.061 5.01836 10.3012L7.67861 12.8796L14.9817 5.80121C15.2296 5.56097 15.6253 5.56716 15.8655 5.81502Z"
        fill="white"
      />
    </svg>
  );
}
