import { useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../lib/api/axios";
import { BaseResponse } from "../../lib/api/queries/commons/types";
import { building_floor_location_key } from "../../lib/api/queries/keys";
import { building_floor_location } from "../../lib/api/queries/urls";

interface IFloorLocation {
  dong: string;
  floor: string;
  location: string;
}

export const useTemperatureLocation = () =>
  useQuery(
    [building_floor_location_key],
    () =>
      fetchClient
        .get<BaseResponse<IFloorLocation[]>>(building_floor_location)
        .then((res) => res.data.data),
    { staleTime: Infinity }
  );
