import * as R from "../../commons/styled";
import { useState } from "react";
import { ICloumnObj } from "../../../../lib/types/Imodels";

interface IRental {
  ele: ICloumnObj;
  // isChanged: boolean;
  items: ICloumnObj[];
  // onChangeCharge: (el: ITenantFeeDetail, newItem: ICloumnObj[]) => void;
  onChangeItems: (id: ICloumnObj[], value: ICloumnObj) => void;
  // onChangeCharge: (el: ITenantFeeDetail, id: number, value: string) => void;
}

export const NewInputColumn = (props: IRental) => {
  const [price, setPrice] = useState<string>("");
  const canInput = props.ele.calculation_method && props.ele.calculation_method.includes("direct");
  // const [items, setItems] = useState<ICloumnObj[]>(props.el);

  return (
    <R.SkySquare p={true}>
      <R.RentalInput
        value={price || Number(Math.ceil(Number(props.ele.value)).toFixed(0)).toLocaleString("kr")}
        readOnly={!canInput}
        // defaultValue={props.ele.value === String(0) ? "" : props.ele.value}
        onChange={(e) => {
          if (isNaN(Number(e.target.value)) || e.target.value.length > 15) return;
          setPrice(String(Number(e.target.value)));
          // console.log(String(Number(e.target.value)));
          props.onChangeItems(props.items, {
            key: props.ele.key,
            value: String(Number(e.target.value)),
          });
        }}
      />
    </R.SkySquare>
  );
};

// export const MemoizedChild = memo(NewCloumn);
