import * as S from "../styled";
import { IOpenSection } from "../types";
import { useFormContext } from "react-hook-form";

// 3. 담당자 정보
export default function ManagerInfo(props: IOpenSection) {
  const { register } = useFormContext();

  return (
    <S.ToggleBox>
      <S.ToggleTitle onClick={() => props.setIsOpen((prev) => !prev)}>
        3. 담당자 정보
        <S.ToggleIcon src="/arrow.png" alt="화살표 아이콘" isOpen={props.isOpen} />
      </S.ToggleTitle>
      <S.ToggleContent id="manager" isHeight={document.getElementById("manager")?.scrollHeight} isOpen={props.isOpen}>
        <S.InputBox>
          <S.InputName>입주사 대표자명</S.InputName>
          <input {...register("tenant_master_name")} />
        </S.InputBox>
        <S.NumberBox>
          <S.InputName>입주사 대표 연락처</S.InputName>
          <S.PhoneNumberBox>
            <input style={{ margin: "0 4px" }} {...register("first")} />
            -
            <input style={{ marginLeft: "4px" }} {...register("second")} />
            -
            <input style={{ marginLeft: "4px" }} {...register("third")} />
          </S.PhoneNumberBox>
        </S.NumberBox>
      </S.ToggleContent>
    </S.ToggleBox>
  );
}
