import { Checkbox, Tag, Text } from "@chakra-ui/react";
import { INotice } from "../../../lib/types/Imodels";
import * as S from "../../../components/styled";
import moment from "moment";
import { format_type } from "../../../lib/utils/dataFormat";
import { useNavigate } from "react-router-dom";
import { useRoomType } from "../../../lib/utils";

interface NotiITemType {
  el: INotice;
  notices: string[];
  onChangeNotices: (checked: boolean, id: string) => void;
}

export default function NoticeTableItems(props: NotiITemType) {
  const navigate = useNavigate();
  const room_type = useRoomType();

  const onClickDetail = () => {
    navigate(`/notice/${props.el?._id}`);
  };

  return (
    <S.ListTableBody onClick={onClickDetail} className="cursor-pointer bg-white transition-all hover:bg-[#eff6ff]">
      <div
        className="flex w-[5%] items-center justify-center"
        style={{ width: "5%" }}
        onClick={(e) => e.stopPropagation()}
      >
        <Checkbox
          variant="default"
          id={String(props.el?._id)}
          onChange={(e) => {
            props.onChangeNotices(e.currentTarget.checked, `${props.el?._id}`);
          }}
          isChecked={props.notices.includes(`${props.el?._id}`) ? true : false}
        />
      </div>
      <div style={{ width: "10%" }}>{props.el?.notice_type}</div>
      <S.FixedTitle style={{ width: "10%", padding: "0 8px", textAlign: "center" }}>
        {props.el?.notice_to.length === room_type.length ? (
          <Text>전체</Text>
        ) : (
          <span>{format_type(props.el?.notice_to)}</span>
        )}
      </S.FixedTitle>
      <S.FixedTitle style={{ width: "35%" }} id="title">
        {props.el?.title}
        <S.NTag id="tag" isTrue={"true"}>
          {props.el?.is_important && (
            <Tag className="rounded" variant="important">
              중요글
            </Tag>
          )}
        </S.NTag>
      </S.FixedTitle>
      <div style={{ width: "10%" }}>{props.el?.is_scheduled ? "유" : "-"}</div>
      <div>{props.el?.user_id?.name}</div>
      <div>{moment.utc(props.el?.createdAt).format("YYYY-MM-DD HH:mm")}</div>
    </S.ListTableBody>
  );
}
