import { Checkbox, MenuItem } from "@chakra-ui/react";
import styled from "@emotion/styled";

const ListStyle = styled(MenuItem)`
  color: #6b7280;
  border-bottom: 1px solid #f4f4f5;
  padding: 8px 12px;
  text-align: left;
`;

export default function SelectConditionButton(props: any) {
  return (
    <ListStyle value={`${props.value}`}>
      <Checkbox
        w="100%"
        variant="default"
        isChecked={
          props.checkedItems?.includes(`${props.value}`) ? true : false
        }
        // isDisabled={}
        value={props.value}
        onChange={(e) => {
          props.onChangeChecked(e.currentTarget.checked, `${props.value}`);
        }}
      >
        {props.value}
      </Checkbox>
    </ListStyle>
  );
}
