import { useContext } from "react";
import { GlobalContext } from "../../../App";

//두가지 권한 교집합때문에 만든것, 자주 쓰이면 hooks로 이동
const useAccessBank = (keys: string[]) => {
  const { user } = useContext(GlobalContext);
  if (!user || !user.auth_id) return undefined; //새로고침이나 데이터 받아올 시간동안 보여질 것

  const newObj = user.auth_id.details.filter((el) => keys.includes(el.key)) || [
    {
      value: "no_auth",
    },
  ];
  // const isAuth = newObj.value;
  const no_auth = newObj.every((el) => el.value === "no_auth"); // no_auth :true -> 모두 포함
  const read = newObj.some((el) => el.value === "read"); //read 하나라도 없을때만 false
  const edit = newObj.some((el) => el.value === "edit");

  if (no_auth) return "no_auth";
  if (edit) return "edit";
  if (read) return "read";
};

export default useAccessBank;
