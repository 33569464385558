import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import { IUserDepartment } from "../commons/types";
import styled from "@emotion/styled";
import { UseModalProps } from "@chakra-ui/react";
import { StaticInput } from "../../../../components/Input/responsive";
import { WarnMessageBox } from "../../../../components/styled";
import { ShadowBox } from "../../../../components/styled/webApp";
import ModalSaveWithContents from "../../../../components/modal/responsive/content";
import DesignationDepartmentAuth from "../components/designation";
import { IoEye } from "@react-icons/all-files/io5/IoEye";
import { FiInfo } from "@react-icons/all-files/fi/FiInfo";
import { useMutation } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { rester_user } from "../../../../lib/api/queries/urls";
import { user_list_key } from "../../../../lib/api/queries/keys";
import { IUser } from "../../../../lib/types/Imodels";
import { AxiosError } from "axios";
import { queryClient } from "../../../..";
import { toast } from "../../../../lib/utils/toast";

//기존 이모티콘 삭제 예정
const EyeIcon = styled(IoEye)`
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 4%;
  font-size: 20px;
  transform: translateY(-50%);
  color: #6b7280;
`;

const schema = yup
  .object()
  .shape({
    email: yup.string().matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, "아이디는 이메일 형식으로 정확히 입력해주세요."),
    password: yup
      .string()
      .min(8, "비밀번호는 최소 8자 이상입니다.")
      .max(16, "비밀번호는 최대 16자 이하입니다.")
      .matches(/[0-9]/, "0-9사이 숫자가 1개 이상 필요합니다.")
      .matches(/[a-z]/, "영문 소문자가 1자 이상 필요합니다.")
      .matches(/[^\w]/, "특수문자가 1자 이상 필요합니다."),
    confirm: yup.string().oneOf([yup.ref("password"), null], "비밀번호가 일치하지 않습니다."),
    name: yup.string().min(1, "이름을 정확히 입력해주세요."),
    contact: yup
      .string()
      .matches(/^[0-9]+$/, "연락 가능한 번호를 '-'없이 입력해주세요.")
      .min(8, "연락 가능한 번호를 '-'없이 입력해주세요.")
      .max(12, "연락 가능한 번호를 '-'없이 입력해주세요."),
  })
  .required();

export default function CreateUser(props: UseModalProps) {
  const { isOpen, onClose } = props;

  const [isShowPw, setIsShowPw] = useState(false);
  const [isShowRePw, setIsShowRePw] = useState(false);
  const [roles, setRoles] = useState<IUserDepartment>({
    auth: "",
    team: "",
    description: "",
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    // watch,
  } = useForm<FieldValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const mutation = useMutation((data: FieldValues) =>
    fetchClient
      .post<BaseResponse<IUser>>(rester_user, {
        email: data.email,
        password: data.password,
        name: data.name,
        phone_number: data.contact,
        department_id: roles.team,
        auth_id: roles.auth,
        description: roles.description,
      })
      .then(() => {
        onClose();
        toast.success({
          title: "신규 관리자 등록 완료",
          description: `신규 관리자 등록이 완료되었습니다. 지금부터 해당 계정으로 로그인이 가능합니다.`,
        });
        return queryClient.invalidateQueries([user_list_key]);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          return toast.warning({
            title: "신규 관리자 등록 오류",
            description: `해당 계정은 이미 등록된 관리자 계정입니다. 다시 확인 후 등록해주세요.`,
          });
        }
        if (err.response?.status === 504) {
          return toast.warning({
            title: "신규 관리자 등록 오류",
            description: `관리자 생성시 선택한 권한이 존재하지 않습니다. 새로고침 후 다시 시도해주세요.`,
          });
        }
      }),
  );

  const onClickRegister: SubmitHandler<FieldValues> = (data) => {
    if (mutation.isLoading) return;
    if (!roles.auth || !roles.team)
      return toast.warning({
        title: "신규 관리자 추가",
        description: `신규 관리자 추가를 위해 필수 입력사항을 모두 입력해주세요.`,
      });

    mutation.mutate(data);
  };

  return (
    <ModalSaveWithContents
      isOpen={isOpen}
      onClose={onClose}
      title="신규 관리자 추가"
      _isRequired={true}
      next="저장하기"
      before="취소"
      onAction={handleSubmit(onClickRegister)}
      scrollBehavior="inside"
    >
      <form autoComplete="off">
        <ShadowBox isModal={true} isMore={true} style={{ padding: "0 0 1rem 0" }}>
          <div>
            <h4>
              로그인에 사용할 이메일을 입력해주세요. <span>*</span>
            </h4>
            <StaticInput
              autoComplete="off"
              placeholder="이메일 형식으로 입력해주세요."
              // _onChange={() => console.log("dd")}
              {...register("email")}
            />
            {errors.email && (
              <WarnMessageBox>
                <FiInfo />
                <>{errors.email.message}</>
              </WarnMessageBox>
            )}
          </div>
          <div>
            <h4>
              비밀번호를 입력해주세요. <span>*</span>
            </h4>
            <div style={{ position: "relative" }}>
              <StaticInput
                type={isShowPw ? "text" : "password"}
                autoComplete="off"
                placeholder="8자 이상의 영문, 숫자, 특수문자의 조합"
                // name='password'
                // onChange={(e) => console.log("")}
                {...register("password")}
              />
              <EyeIcon onClick={() => setIsShowPw((prev) => !prev)} />
            </div>
            {errors.password && (
              <WarnMessageBox>
                <FiInfo />
                <>{errors.password.message}</>
              </WarnMessageBox>
            )}
            <div style={{ position: "relative", marginTop: "12px" }}>
              <StaticInput
                type={isShowRePw ? "text" : "password"}
                autoComplete="off"
                placeholder="비밀번호를 다시 한번 입력해주세요."
                {...register("confirm")}
                // onChange={(e) => setValue("confirm", e.target.value)}
              />
              <EyeIcon onClick={() => setIsShowRePw((prev) => !prev)} />
            </div>
            {errors.confirm && (
              <WarnMessageBox>
                <FiInfo />
                <>{errors.confirm.message}</>
              </WarnMessageBox>
            )}
          </div>
          <div>
            <h4>
              이름을 입력해주세요. <span>*</span>
            </h4>
            <StaticInput
              autoComplete="off"
              placeholder="관리자 이름을 입력해주세요."
              // _onChange={() => console.log("dd")}
              {...register("name")}
            />
            {errors.name && (
              <WarnMessageBox>
                <FiInfo />
                <>{errors.name.message}</>
              </WarnMessageBox>
            )}
          </div>
          <div>
            <h4>
              연락처를 입력해주세요. <span>*</span>
            </h4>
            <StaticInput
              autoComplete="off"
              placeholder="‘-’ 없이 숫자로 입력해주세요."
              // _onChange={() => console.log("dd")}
              {...register("contact")}
            />
            {errors.contact && (
              <WarnMessageBox>
                <FiInfo />
                <>{errors.contact.message}</>
              </WarnMessageBox>
            )}
          </div>
          <DesignationDepartmentAuth isSingle={false} data={roles} setData={setRoles} />
        </ShadowBox>
      </form>
    </ModalSaveWithContents>
  );
}
