import { Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FixedTitle } from "../../../components/styled";
import { colors } from "../../../components/styled/common";
import { MouseShadowBox } from "../../../components/styled/webApp";

export const NewTemperatureList = styled.div<{ isAdd: boolean }>`
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-left: 1.25rem;
  color: ${colors.gray4};
  .chakra-checkbox__label {
    ${({ isAdd }) => isAdd && `color : ${colors.mainBlue}`};
  }
`;

export const DateAlignBox = styled(MouseShadowBox)`
  margin-right: 0.75rem;
  min-width: 17.5rem;
`;

export const SelectBox = styled.div`
  padding: 1.25rem 0;
  border-bottom: 1px solid ${colors.gray2};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const AnswerList = styled.div<{ isChecked: boolean }>`
  width: 100%;
  padding: 1rem 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid
    ${({ isChecked }) => (isChecked ? colors.mainBlue : colors.gray2)};
  color: ${({ isChecked }) => (isChecked ? colors.mainBlue : colors.gray4)};
  cursor: pointer;
  user-select: none;
  svg {
    font-size: x-large;
  }
  :last-of-type {
    width: 100%;
    margin-bottom: 0;
    /* padding-bottom: 10px; */
    align-items: flex-start;
    flex-wrap: nowrap;
    span {
      margin-right: 4px;
    }
  }
`;

export const WriteBox = styled.div`
  width: calc(100% - 6rem);
  padding-left: 4px;
  textarea {
    width: 100%;
    resize: none;
  }
`;

export const FloorTable = styled.div`
  width: 100%;
  display: flex;
  background-color: ${colors.gray1};
  border-top: 1px solid ${colors.gray4};
  border-bottom: 1px solid ${colors.gray4};
  > div {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${colors.gray4};
    :last-of-type {
      border-right: none;
    }
  }
`;

export const SubHeader = styled(FixedTitle)`
  width: 100%;
  padding: 8px;
  text-align: center;
  border-top: 1px solid ${colors.gray4};
  :first-of-type {
    border-right: 1px solid ${colors.gray4};
  }
`;

export const FloorBody = styled(FloorTable)`
  background-color: #fff;
  border-top: none;
`;

export const TableCell = styled(Flex)<{ s?: string }>`
  width: 100%;
  padding: 12px 24px;
  min-height: 48px;
  justify-content: center;
`;

export const FixedTableCell = styled(FixedTitle)<{ s?: string }>`
  padding: 12px 4px;
  text-align: center;
`;

export const SmallCellBox = styled(TableCell)`
  padding: 0;
`;

export const AnswerCell = styled(TableCell)`
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;
