import { Combobox, ComboboxButton, Transition } from "@headlessui/react";
import { useState } from "react";
import Icon from "../types/solar-icon";
import { ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

type Props = {
  themeSize?: "sm" | "md";
  data: { name: string; value: string; disabled?: boolean; statusText?: string }[];
  getValue: () => string;
  onChange: (data: { name: string; value: string }) => void;
  disabled?: boolean;
};

export default function CustomComboBox({ themeSize = "sm", data, getValue, onChange, disabled = false }: Props) {
  const [query, setQuery] = useState("");

  const sizeClass = {
    sm: "h-9 w-full rounded-lg border border-gray-300 bg-white px-3 text-start text-xs font-medium leading-4 group-hover:bg-gray-50 ui-open:bg-gray-50 ui-open:outline-none ui-open:outline-2 ui-open:outline-offset-0 ui-open:outline-gray-200",
    md: "h-12 w-full rounded-lg border border-gray-300 bg-white px-4 text-start text-base font-medium leading-5 focus:outline-none focus:outline-2 focus:outline-offset-0 focus:outline-gray-200 group-hover:bg-gray-50 ui-open:bg-gray-50 ui-open:outline-none ui-open:outline-2 ui-open:outline-offset-0 ui-open:outline-gray-200",
  }[themeSize];

  const filteredData =
    query === ""
      ? data
      : data.filter((item) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      value={data.find((item) => item.value === getValue()) || data[0]}
      onChange={onChange}
      virtual={{ options: filteredData }}
      onClose={() => setQuery("")}
      disabled={disabled}
    >
      {({ open }) => (
        <div className="relative w-full">
          <div className={"group relative flex items-center text-gray-500 hover:text-gray-600 ui-open:text-gray-500"}>
            <ComboboxInput
              className={twMerge(
                sizeClass,
                data[0].value !== getValue() && "border-gray-400 text-gray-700",
                disabled && "cursor-not-allowed opacity-50",
              )}
              displayValue={(item: { name?: string }) => (item?.name ? item.name : data[0].name)}
              onChange={(event) => setQuery(event.target.value || "")}
            />
            <ComboboxButton
              className={twMerge(
                "absolute inset-y-0 right-0 flex items-center pr-2",
                data[0].value !== getValue() && "text-gray-600",
              )}
            >
              <Icon.AltArrowDown
                size={sizeClass === "sm" ? 16 : 20}
                className={twMerge("overflow-hidden", open && "rotate-180")}
                aria-hidden="true"
              />
            </ComboboxButton>
          </div>
          <Transition
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            {filteredData.length === 0 && query !== "" ? (
              <div className="absolute z-10 mt-1.5 max-h-[22.5rem] w-full overflow-scroll rounded-lg border border-gray-300 bg-white text-sm shadow-lg">
                <div
                  className={twMerge(
                    "flex h-9 cursor-default select-none items-center p-3 text-xs text-gray-500",
                    themeSize === "md" && "h-12 p-6 text-base leading-5",
                  )}
                >
                  일치하는 목록이 없습니다
                </div>
              </div>
            ) : (
              <ComboboxOptions className="absolute z-10 mt-1.5 max-h-[22.5rem] w-full overflow-scroll rounded-lg border border-gray-300 bg-white text-sm shadow-lg empty:invisible">
                {({ option: item }) => (
                  <ComboboxOption
                    value={item}
                    className={twMerge(
                      "flex h-9 w-full cursor-pointer select-none items-center gap-2 p-3 ui-active:bg-gray-50",
                      themeSize === "md" && "h-12 px-4 py-3",
                      item.disabled && "cursor-not-allowed",
                    )}
                    disabled={item.disabled}
                  >
                    <div className="flex w-full items-center justify-between">
                      <span
                        className={twMerge(
                          "text-xs font-medium leading-4 text-gray-500 ui-active:text-gray-600",
                          themeSize === "md" && "text-base font-normal leading-5",
                          item.disabled && "opacity-50",
                        )}
                      >
                        {item.name}
                      </span>
                      {item.statusText && (
                        <span className="text-xs font-medium leading-4 text-gray-400">{item.statusText}</span>
                      )}
                    </div>
                  </ComboboxOption>
                )}
              </ComboboxOptions>
            )}
          </Transition>
        </div>
      )}
    </Combobox>
  );
}
