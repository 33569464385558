import React from "react";

export default function IconSend() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5297 13.0584L16.9601 8.76729C18.2096 5.01864 18.8344 3.14431 17.845 2.15492C16.8556 1.16553 14.9813 1.79031 11.2326 3.03986L6.94152 4.47023C3.91599 5.47874 2.40323 5.98299 1.97334 6.72244C1.56439 7.4259 1.56439 8.2947 1.97334 8.99815C2.40322 9.73761 3.91599 10.2419 6.94151 11.2504C7.31647 11.3754 7.7383 11.2861 8.01907 11.0079L12.6071 6.46242C12.8652 6.2067 13.2818 6.20863 13.5375 6.46675C13.7932 6.72487 13.7913 7.14142 13.5332 7.39715L9.01922 11.8693C8.70975 12.1759 8.61179 12.6451 8.74955 13.0584C9.75805 16.0839 10.2623 17.5967 11.0018 18.0266C11.7052 18.4355 12.574 18.4355 13.2775 18.0266C14.0169 17.5967 14.5212 16.0839 15.5297 13.0584Z"
        fill="white"
      />
    </svg>
  );
}
