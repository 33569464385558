import { useMutation } from "@tanstack/react-query";
import { httpV2 } from "../lib/httpV2";

type Request = {
  text: string;
};

export const useSendSlackMessageMutation = () => {
  return useMutation({
    mutationFn: ({ text }: Request) => {
      return httpV2.post<Request, unknown>("/webhook/slack", { text });
    },
  });
};
