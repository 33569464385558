import { useMutation } from "@tanstack/react-query";
import { httpV2 } from "../../lib/httpV2";
import { queryClient } from "../..";

type CreateCompanyRequest = {
  buildingId: string;
  name: string;
  members: {
    name: string;
    phoneNumber: string;
  };
  tenantType: "office" | "store" | "residential" | "etc";
  rooms: string[];
  moveInType?: "own_house" | "monthly_rent" | "etc";
  rentStartDate?: Date;
  rentEndDate?: Date;
  companyRegistrationNumber?: string;
  taxEmail?: string;
  fax?: string;
};

export const useCreateCompanyMutation = () => {
  return useMutation({
    mutationFn: (request: CreateCompanyRequest) => {
      return httpV2.post<CreateCompanyRequest, unknown>("/companies", request);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["/companies"]);
    },
  });
};
