import * as R from "../../commons/styled";
import { ITenantFeeDetail } from "../../../../lib/types/Imodels";
import { memo, useState } from "react";

interface IRental {
  el: ITenantFeeDetail;
  idx: number;
  length: number | undefined;
  onChange: (el: ITenantFeeDetail, value: string) => void;
}

export function RentalItem(props: IRental) {
  const [price, setPrice] = useState<number>();

  return (
    <R.TableBodyBox>
      <R.Square
        isManage={true}
        style={{
          padding: "12px 4px",
          width: (props.length || 0) > 999 ? "5%" : "4%",
        }}
      >
        {props.idx + 1}
      </R.Square>
      <R.Square w="20%">{props.el.tenant_name}</R.Square>
      <R.Square w="25%">
        {props.el.dong_ho.map((ele, idx) => (
          <span key={String(idx)}>
            {ele.dong}동 {ele.ho}호
          </span>
        ))}
      </R.Square>
      <R.SkySquare p={true} w="20%" style={{ verticalAlign: "middle" }}>
        <R.RentalInput
          value={price || Number(props.el.price || 0)}
          onChange={(e) => {
            if (isNaN(Number(e.target.value)) || e.target.value.length > 15) return;
            setPrice(Number(e.target.value));
            props.onChange(props.el, e.target.value);
          }}
        />
      </R.SkySquare>
      <div style={{ width: "30%" }} />
    </R.TableBodyBox>
  );
}

export const MemoizedItem = memo(RentalItem);
