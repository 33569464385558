import styled from "@emotion/styled";
import { FileFormat, isExtension } from "../../../../lib/utils/format";

const ContentBox = styled.div`
  max-width: 80%;
  min-width: 240px;
  color: #6b7280;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 44px;
    height: 44px;
    margin-right: 8px;
    cursor: pointer;
  }
`;

const Download = styled.a`
  margin-right: 8px;
  :hover {
    text-decoration: underline;
  }
`;

function FileComponent({
  data,
}: {
  data: { directory: string; name: string }[];
}) {
  if (!data.every((el) => el.directory)) return <ContentBox />; // 데이터 변경 안하고 사이트 터짐 방지, 추후 삭제 O

  return (
    <ContentBox>
      {data?.map((el, idx) => {
        return FileFormat.includes(isExtension(el.directory)) ? (
          <Download
            key={`${el} 이미지`}
            href={el.directory}
            target="_blank"
            rel="noreferrer"
          >
            <img src={el.directory} alt="이미지" />
          </Download>
        ) : (
          <Download key={idx} href={el.directory} target="_blank" download>
            {el.name}
          </Download>
        );
      })}
    </ContentBox>
  );
}

export default FileComponent;
