import styled from "@emotion/styled";
import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { MouseEvent, useContext, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { complaint_assign_team } from "../../../../lib/api/queries/urls";
import { success, warning } from "../../../../lib/theme/toast";
import { queryClient } from "../../../..";
import { complaint_detail_key } from "../../../../lib/api/queries/keys";
import { colors } from "../../../../components/styled/common";
import { ActionClickBox } from "../style";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { MiniStandard } from "../../../../components/styled/webApp";
import { HideSheet, BottomSheetBox } from "../../../../components/Menu/bottomSheet";
import { usefetchTeamList } from "../../../control/manager/commons/queries";
import { GlobalContext } from "../../../../App";

const BottomSheet = styled(BottomSheetBox)`
  top: 2rem;
  ul {
    .disabled {
      cursor: not-allowed;
      opacity: 0.7;
      :hover {
        background-color: #fff;
      }
    }
  }
`;

const ActiveList = styled.li<{ isActive: boolean }>`
  :active {
    color: ${colors.mainBlue};
  }
  ${({ isActive }) => isActive && `color: ${colors.mainBlue}`};
`;

interface ISelectTeamBox {
  data: { _id: string; name: string };
}

function ChangedTeamInDetail({ data }: ISelectTeamBox) {
  const toast = useToast();
  const { complaintId } = useParams();

  const { AppToken } = useContext(GlobalContext);
  const [team, setTeam] = useState(data._id);
  const [isChangedTeam, setIsChangedTeam] = useState(false); //특정 답글 선택

  const { data: teams } = usefetchTeamList(true);
  const mutation = useMutation(
    (str: string) =>
      fetchClient
        .post(complaint_assign_team, {
          complaint_id: complaintId,
          department_id: str,
        })
        .then((res) => res.data.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([complaint_detail_key, complaintId]);
        toast({
          ...success,
          title: "담당팀 변경",
          description: "담당팀이 변경되었습니다. 변경된 팀으로 알림이 전달됩니다.",
        });
      },
      onError: () => {
        setTeam(data._id);
        toast({
          ...warning,
          title: "담당팀 변경",
          description:
            "일시적으로 담당팀 변경에 실패했습니다. 새로고침 후에도 동일한 오류가 반복될 경우 오피스너 담당자에게 문의해주세요.",
        });
      },
    },
  );

  const teamArr = [
    ...[{ name: "담당팀없음", _id: "" }],
    ...(teams?.map((el) => ({ name: el.name, _id: String(el._id) })) || []),
  ];

  const onChangeStatus = (e: MouseEvent<HTMLLIElement>) => {
    // if (auth !== "edit") return toast({ ...unAuthUser });
    if (mutation.isLoading) return;
    if (data._id === e.currentTarget.id) return; //선택이 같을경우 모달 닫기
    if (e.currentTarget.id === "") return e.stopPropagation(); //담당팀 없음 선택시 모달 유지, 변경 X

    setTeam(e.currentTarget.id);
    mutation.mutate(e.currentTarget.id);
    // console.log(e.currentTarget.id);
    // onOpen();
  };

  return (
    <>
      <ActionClickBox onClick={() => setIsChangedTeam((prev) => !prev)}>
        {teamArr.find((el) => el._id === data._id)?.name || "담당팀없음"}
        <FiChevronDown />
      </ActionClickBox>
      <MiniStandard
        style={{ position: "absolute", top: "30%", right: 0 }}
        isOpen={isChangedTeam}
        onClick={() => setIsChangedTeam((prev) => !prev)}
      >
        <HideSheet isOpen={isChangedTeam} />
        <BottomSheet isOpen={isChangedTeam}>
          <ul style={AppToken ? { paddingBottom: "34px" } : {}}>
            {teamArr.map((el) => (
              <ActiveList
                className={el._id === "" ? "disabled" : ""}
                key={el._id}
                id={el._id}
                isActive={team === el._id}
                onClick={onChangeStatus}
              >
                {el.name}
              </ActiveList>
            ))}
          </ul>
        </BottomSheet>
      </MiniStandard>
    </>
  );
}

export default ChangedTeamInDetail;
