import { Box, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { colors } from "../../../../components/styled/common";

export const TableFlex = styled(Flex)`
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 8px;
`;

export const ScheduleList = styled.li`
  font-weight: 700;
  ul {
    padding-top: 8px;
    font-weight: 400;
    list-style: none;
  }
`;

export const ScheduleItem = styled.div`
  margin-top: 40px;
  padding: 16px 24px;
  border: 1px solid ${colors.gray2};
  border-left: none;
  border-right: none;
`;

export const InfoBox = styled(Box)`
  width: 100%;
  color: ${colors.gray4};
  padding: 8px 12px;
  font-weight: 500;
  font-size: 18px;
  background-color: ${colors.gray1};
  p {
    color: #fb4667;
    line-height: 1.5;
  }
`;

export const RoomInput = styled.input`
  appearance: none;
  padding: 10px 12px;
  width: auto;
  margin-right: 0;
  background-color: #fff;
  color: ${colors.gray4};
  border-radius: 0;
  border: 1px solid ${colors.gray3};
  ::placeholder {
    color: ${colors.gray2};
  }
`;

export const UpdateList = styled.ul`
  padding: 0px 16px;
  li {
    font-weight: 500;
  }
`;
