import styled from "@emotion/styled";
import { colors } from "../../../../components/styled/common";

export const CalcBox = styled.div`
  width: 100%;
  border: 1px solid ${colors.gray2};
  margin-top: 24px;
  max-height: 416px;
  overflow-y: scroll;
`;

export const ChildWrapBox = styled.div<{ isDisabled?: boolean }>`
  //한 칸 가로 묶음
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.gray2};
  :last-of-type {
    border-bottom: none;
  }
  > div {
    //한 칸
    width: 30%;
    height: 48px;
    /* height: 48px;
    line-height: 40px;
    display: inline-block;
    vertical-align: middle; 칸 넘기는 경우 대비*/
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-right: 1px solid ${colors.gray2};
    ${({ isDisabled }) => isDisabled && `opacity:0.25`};
  }
  > div:last-of-type {
    border-right: none;
  }
  ${({ isDisabled }) =>
    isDisabled && `background: ${colors.gray1}; color:${colors.gray4}`};
`;

export const DifferenceBox = styled.span`
  font-size: 14px;
  color: ${colors.gray4};
`;

export const DirectInput = styled.input`
  appearance: none;
  padding: 8px 12px;
  width: 100%;
  background-color: inherit;
  border: 1px solid ${colors.gray3};
  /* line-height: 1.5; */
  :read-only {
    cursor: default;
    padding: 8px 0;
    border: none;
  }
`;
