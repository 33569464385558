import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IReservationProduct, IRoomPublic } from "../../../../lib/types/Imodels";
import { isCheckedClosedDay, onFormatDays, weeklyArr } from "../../commons/func";
import { useMutatePublicItem, useMutatePublicRoom } from "../../commons/queries";
import { SavedDetails } from "../../commons/styled";
import { UpdateIconBox, BoxInDetail, ShadowBoxInDetail } from "../../../../components/styled/webApp";
import { ClosedDays } from "./OperationItem";
import EditModalNoHeader from "../../../../components/modal/responsive/edit";
import { toast } from "../../../../lib/utils/toast";

interface ISavedDays {
  isPublic: boolean; //-> 공용시설인지, 상품인지 판단
  data?: IRoomPublic | IReservationProduct;
}

export default function SavedCloseDays({ data, isPublic }: ISavedDays) {
  const { updatePublicRoom } = useMutatePublicRoom();
  const { onOpen, isOpen, onClose } = useDisclosure(); //휴무일
  const [holidays, setHolidays] = useState<string[]>([]);
  const [isMonthly, setIsMonthly] = useState(true);
  const { updateMutation } = useMutatePublicItem(true);
  const dayOff = { holidays, setHolidays, isMonthly, setIsMonthly };
  const dayArr = ["일", "월", "화", "수", "목", "금", "토"];
  const newArr = [...(data?.regular_holiday_days || [])];

  useEffect(() => {
    if (!data) return;
    if (
      data.regular_holiday_days?.length === 0 &&
      data.regular_holiday_weeks?.length === 0 &&
      data.holidays?.length === 0
    ) {
      setHolidays(["none"]);
    } else {
      data.regular_holiday_weeks?.length === 5 ? setIsMonthly(false) : setIsMonthly(true);
      setHolidays([
        "yes",
        ...newArr.map((el) => `${dayArr[el]}요일`),
        ...(data.regular_holiday_weeks?.map((el) => weeklyArr[el]) || []),
        ...(data.holidays?.map((el) => (el === "추석" ? "추석 당일" : el === "설날" ? "설날 당일" : el)) || []),
      ]);
    }

    // newArr.splice(data.regular_holiday_days?.length || 1, 0, 0);
    // console.log(newArr);
  }, [data]);

  const onClickToInitial = () => {
    if (!data) return;
    onClose();

    if (
      data.regular_holiday_days?.length === 0 &&
      data.regular_holiday_weeks?.length === 0 &&
      data.holidays?.length === 0
    )
      return setHolidays(["none"]);
    data.regular_holiday_weeks?.length === 5 ? setIsMonthly(false) : setIsMonthly(true);
    setHolidays([
      "yes",
      ...newArr.map((el) => `${dayArr[el]}요일`),
      ...(data.regular_holiday_weeks?.map((el) => weeklyArr[el]) || []),
      ...(data.holidays?.map((el) => (el === "추석" ? "추석 당일" : el === "설날" ? "설날 당일" : el)) || []),
    ]);
  };

  const onBeforeUpdate = async () => {
    const errors = isCheckedClosedDay(holidays);

    switch (errors) {
      case "휴무일 선택 오류":
        return toast.warning({
          title: "알림",
          description: "휴무일을 선택해주세요.",
        });
      case "휴무일 설정 오류":
        return toast.warning({
          title: "알림",
          description: "휴무일을 모두 설정했는 지 다시 한번 확인해주세요.",
        });
      // default:
      //   return mutation.mutate()
    }

    const holiday = {
      regular_holiday_weeks: holidays.includes("none") ? [] : onFormatDays(holidays, "주"),
      regular_holiday_days: holidays.includes("none") ? [] : onFormatDays(holidays, "요일"),
      holidays: holidays.includes("none") ? [] : onFormatDays(holidays, "공휴일"),
    };

    try {
      isPublic
        ? await updatePublicRoom.mutateAsync({
            room_public_id: String(data?._id),
            ...holiday,
          })
        : await updateMutation.mutateAsync({
            reservation_product_id: String(data?._id),
            ...holiday,
          });

      toast.success({
        title: "휴무일 정보 변경",
        description: `해당 ${isPublic ? "시설" : "상품"}의 휴무일이 변경되었습니다.`,
      });
    } catch {
      return toast.warning({
        title: "휴무일 수정 오류",
        description: "휴무일 수정에 일시적으로 실패했습니다. 잠시 후 다시 시도해주세요.",
      });
    }

    onClose();
    // isOpen ? mutation.mutate(time) : mutation.mutate(holiday);
  };

  return (
    <ShadowBoxInDetail>
      <BoxInDetail>
        <h4>휴무일</h4>
        <UpdateIconBox onClick={onOpen}>
          수정하기 <FiChevronRight />
        </UpdateIconBox>
      </BoxInDetail>
      {!data?.regular_holiday_weeks?.length && !data?.regular_holiday_days?.length && !data?.holidays?.length ? (
        <SavedDetails style={{ paddingTop: 0, borderBottom: "none" }}>
          <div>휴무일이 없습니다.</div>
        </SavedDetails>
      ) : (
        <>
          <SavedDetails style={{ paddingTop: 0 }}>
            <div className="section">정기휴무일</div>
            <div className="holidays">
              {data?.regular_holiday_weeks?.length === 5 ? (
                "매주 "
              ) : (
                <>
                  매달{" "}
                  {data?.regular_holiday_weeks
                    ?.sort()
                    .map((el, idx) =>
                      idx === (data?.regular_holiday_weeks?.length || 1) - 1
                        ? `${weeklyArr[el]} `
                        : `${weeklyArr[el]}, `,
                    )}
                </>
              )}
              {data.regular_holiday_days
                ?.sort((a, b) => (a === 0 ? 1 : b === 0 ? -1 : a > b ? 1 : -1))
                .map((el, idx) =>
                  idx === (data.regular_holiday_days?.length || 0) - 1 ? `${dayArr[el]}요일` : `${dayArr[el]}요일, `,
                )}
            </div>
          </SavedDetails>
          {data?.holidays && data?.holidays.length !== 0 && (
            <SavedDetails>
              <div className="section">법정공휴일</div>
              <div className="holidays">
                {data?.holidays.map((el, idx) =>
                  el.includes("-1") && idx === (data?.holidays || []).length - 1
                    ? el.replace("-1", " 전날")
                    : el.includes("-1")
                      ? el.replace("-1", " 전날, ")
                      : el.includes("+1") && idx === (data?.holidays || []).length - 1
                        ? el.replace("+1", " 다음날")
                        : el.includes("+1")
                          ? el.replace("+1", " 다음날, ")
                          : idx === (data?.holidays || []).length - 1
                            ? el
                            : `${el}, `,
                )}
              </div>
            </SavedDetails>
          )}
        </>
      )}
      <EditModalNoHeader isOpen={isOpen} onClose={onClickToInitial} onClickUpdate={onBeforeUpdate}>
        <ClosedDays isModal={true} {...dayOff} />
      </EditModalNoHeader>
    </ShadowBoxInDetail>
  );
}
