import { Button, useDisclosure, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { INewRequest } from "../commons/types";
import RequestUploadFile from "../create/_view/RequestUploadFile";
import { ChangeEvent, useContext, useRef, useState, useEffect } from "react";
import { NewRequestContext } from "./SubmitNewRequest";
import { fileImageValidate, limitContent, onLimitTextNum } from "../../../lib/utils/format";
import SearchInTenantList from "../../../components/modal/search/Tenant";
import { useFetchCategories } from "../commons/queries";
import { GlobalContext } from "../../../App";
import SubframePart from "../../../components/Main/subframePart";
import { MainActionBox, Required, ShadowBox, LastShadowBox } from "../../../components/styled/webApp";
import GrayDropdown from "../../../components/select/gray";
import { breakPoints } from "../../../components/styled/media";
import GrayRadiusInput from "../../../components/Input/responsive";
import { MdSearch } from "@react-icons/all-files/md/MdSearch";
import GrayRadiusTextArea from "../../../components/textarea";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import { usefetchTeamList } from "../../control/manager/commons/queries";
import { IComplainList } from "../../../lib/types/Imodels";
import { toast } from "../../../lib/utils/toast";

const SelectWrapBox = styled(Flex)`
  > div:first-of-type {
    margin-right: 12px;
  }
  @media ${breakPoints.phones} {
    flex-wrap: wrap;
    > div {
      margin-bottom: 12px;
    }
    > div:first-of-type {
      margin-right: 0;
    }
    > div:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const SelectBox = styled(Flex)`
  > div {
    margin: 12px 0;
    width: 48%;
  }
  @media ${breakPoints.phones} {
    > div {
      width: 100%;
    }
  }
`;

const InputWrapBox = styled(Flex)`
  input:first-of-type {
    margin-right: 0.75rem;
  }
  @media ${breakPoints.phones} {
    flex-wrap: wrap;
    input:first-of-type {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }
`;

const ExplainP = styled.p`
  color: var(--3, #9ca3af);
  font-family: Noto Sans CJK KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
`;

export default function SubmitNewRequestUI(props: INewRequest) {
  // 커스텀 카테고리 권한유무 확인
  const [customCategoryOption, setCustomCategoryOption] = useState<boolean>(false);

  const { selected, files, setSelected, setFiles } = useContext(NewRequestContext);
  console.log(useContext(NewRequestContext));
  const { isOpen, onOpen, onClose } = useDisclosure(); //입주자 검색

  const { buildInfo } = useContext(GlobalContext);
  const { data: category } = useFetchCategories("complaint_type");
  const { data: teams } = usefetchTeamList(true);

  const fileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (buildInfo?.options?.length) {
      for (let i = 0; i < buildInfo?.options?.length; i++) {
        if (buildInfo?.options[i].name === "민원 관리") {
          setCustomCategoryOption(buildInfo?.options[i].enable);
          break;
        }
      }
    }
  }, []);

  const onChangeFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target.files;

    if (target === null) return;
    const valid = fileImageValidate(target);
    if (!valid) return toast.underFiveImage({});

    const newFileArr = [...(files || []), ...Array.from(target)];
    setFiles && setFiles(Array.from(newFileArr));
    e.target.value = "";
  };

  const onChangeSelectLocation = (e: ChangeEvent<HTMLSelectElement>) => {
    const newObj = { ...props.place };
    const Id = e.target.id;
    if (Id === "dong" || Id === "floor" || Id === "location") newObj[Id] = e.target.value;

    props.setPlace(newObj);
  };

  const onChangeCategory = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    props.setCategory(value);

    if (value === "기타") return props.setSub("");
  };

  const onChangeSelectedMainCategory = (e: ChangeEvent<HTMLSelectElement>) => {
    props.setSelectedMainCategory(e.target.value);
    props.setSelectedSubCategory(""); // 선택한 서브 카테고리 초기화

    // 전체 카테고리 리스트
    props.categoryList.map((item: IComplainList) => {
      if (item.name === e.target.value) {
        props.setSubCategories(item.sub_category);
      }
    });
  };

  console.log("selected", selected);

  return (
    <SubframePart name={props.isEdit ? "민원 수정" : "민원 등록"}>
      <MainActionBox>
        <Required>* 필수입력</Required>
        {customCategoryOption && (
          <ShadowBox>
            <div>
              <h4>관리팀 내부에 기록할 민원 카테고리를 선택해주세요.</h4>
              <ExplainP>아래에서 선택하신 카테고리로 해당 건물의 민원 데이터를 추출할 수 있습니다.</ExplainP>
              <GrayDropdown
                w="100%"
                value={props.selectedMainCategory}
                // disabled={data.auth === "마스터"}
                _onChange={onChangeSelectedMainCategory}
                _style={{ marginBottom: "12px" }}
              >
                <option value="" disabled>
                  1차 카테고리 선택
                </option>
                {props.mainCategories?.map((el, index) => (
                  <option key={String(index)} value={el}>
                    {el}
                  </option>
                ))}
              </GrayDropdown>
              <GrayDropdown
                w="100%"
                value={props.selectedSubCateogry}
                _onChange={(e) => props.setSelectedSubCategory(e.target.value)}
                disabled={props.selectedMainCategory === ""}
              >
                <option value="" disabled>
                  2차 카테고리 선택
                </option>
                {props.subCategories?.map((el, index) => (
                  <option key={String(index)} value={el}>
                    {el}
                  </option>
                ))}
              </GrayDropdown>
            </div>
          </ShadowBox>
        )}
        {!props.isEdit && (
          <ShadowBox>
            <div>
              <h4>
                담당팀을 선택해주세요. <span>*</span>
              </h4>
              <GrayDropdown
                w="100%"
                value={props.department}
                // disabled={data.auth === "마스터"}
                _onChange={(e) => props.setDepartment(e.target.value)}
              >
                <option value="" disabled>
                  소속선택
                </option>
                {teams?.map((el) => (
                  <option key={String(el._id)} value={String(el._id)}>
                    {el.name}
                  </option>
                ))}
              </GrayDropdown>
            </div>
          </ShadowBox>
        )}
        <ShadowBox isMore={true}>
          <div>
            <h4>
              기록하실 민원의 유형을 선택해주세요. <span>*</span>
            </h4>
            <SelectWrapBox>
              <GrayDropdown w="100%" value={props.category} _onChange={onChangeCategory}>
                <option value="" disabled>
                  유형 선택
                </option>
                {category?.map((el) => {
                  if (el.title === "실내온도") return;
                  return (
                    <option key={String(el._id)} value={el.title}>
                      {el.title}
                    </option>
                  );
                })}
                <option value="기타">기타</option>
              </GrayDropdown>
              <GrayDropdown
                w="100%"
                value={props.sub}
                _onChange={(e) => props.setSub(e.target.value)}
                disabled={!props.category || props.category === "기타"}
                // _onChange={(e) =>
                //   setData({ ...data, ...{ auth: e.target.value } })
                // }
              >
                <option value="" disabled>
                  세부유형 선택
                </option>
                {category
                  ?.find((el) => el.title === props.category)
                  ?.sub_category?.map((ele) => (
                    <option key={String(ele.title)} value={ele.title}>
                      {ele.title}
                    </option>
                  ))}
              </GrayDropdown>
            </SelectWrapBox>
          </div>
          <div>
            <h4>
              발생장소를 입력해주세요. <span>*</span>
            </h4>
            <SelectWrapBox>
              <GrayDropdown w="100%" id="dong" value={props.place.dong} _onChange={onChangeSelectLocation}>
                <option value="" disabled>
                  동 선택
                </option>
                {buildInfo?.dong_floor?.map((el) => (
                  <option key={String(el._id)} value={el.dong}>
                    {el.dong}
                  </option>
                ))}
              </GrayDropdown>
              <GrayDropdown w="100%" id="floor" value={props.place.floor} _onChange={onChangeSelectLocation}>
                <option value="" disabled>
                  층 선택
                </option>
                {buildInfo?.dong_floor
                  .find((el) => el.dong === props.place.dong)
                  ?.floor.map((ele) => (
                    <option key={ele} value={ele}>
                      {ele}층
                    </option>
                  ))}
              </GrayDropdown>
            </SelectWrapBox>
            <SelectBox>
              <GrayDropdown w="100%" id="location" value={props.place.location} _onChange={onChangeSelectLocation}>
                <option value="" disabled>
                  세부장소 선택
                </option>
                {props.location?.map((el) => (
                  <option key={String(el._id)} value={el.title}>
                    {el.title}
                  </option>
                ))}
                <option value="direct">직접 입력</option>
              </GrayDropdown>
            </SelectBox>
            <GrayRadiusInput
              placeholder="직접 입력 (최대 50자)"
              disabled={props.place.location !== "direct"}
              value={props.place.location === "direct" ? props.direct : ""}
              _onChange={(e) => onLimitTextNum(e, props.setDirect)}
            />
          </div>
        </ShadowBox>
        <ShadowBox isMore={true}>
          <div>
            <h4 style={{ paddingBottom: 0 }}>신청자의 회사를 선택해주세요.</h4>
            <span>선택한 회사의 최고관리자에게만 해당 민원이 노출됩니다.</span>
            <Flex mt="0.75rem">
              {selected ? (
                <>
                  <GrayRadiusInput
                    disabled={true}
                    value={selected.name}
                    _onChange={() => console.log("")}
                    _style={{ marginRight: "12px" }}
                  />
                  <Button variant="m_primary_line" onClick={onOpen}>
                    수정
                  </Button>
                </>
              ) : (
                <Button variant="m_primary_line" onClick={onOpen}>
                  <MdSearch />
                  입주사 검색
                </Button>
              )}
            </Flex>
            {isOpen && <SearchInTenantList isOpen={isOpen} onClose={onClose} setSelected={setSelected} />}
          </div>
          <div>
            <h4>신청자의 이름과 연락처를 입력해주세요.</h4>
            <InputWrapBox>
              <GrayRadiusInput
                _style={{ width: "8rem" }}
                // disabled={true}
                value={props.applicant ? props.applicant.name : ""}
                placeholder="신청자 이름"
                _onChange={(e) =>
                  props.setApplicant(
                    props.applicant
                      ? { ...props.applicant, ...{ name: e.target.value } }
                      : { name: e.target.value, num: "" },
                  )
                }
              />
              <GrayRadiusInput
                // disabled={true}
                value={props.applicant ? props.applicant.num : ""}
                placeholder="연락처를 입력해주세요."
                _onChange={(e) =>
                  props.setApplicant(
                    props.applicant
                      ? { ...props.applicant, ...{ num: e.target.value } }
                      : { num: e.target.value, name: "" },
                  )
                }
              />
            </InputWrapBox>
          </div>
        </ShadowBox>
        <LastShadowBox isMore={true}>
          <div style={{ paddingBottom: "0.75rem" }}>
            <h4>
              민원 내용을 입력해주세요.<span>*</span>
            </h4>
            <GrayRadiusTextArea
              isLimited={true}
              num={3000}
              value={props.body}
              placeholder="민원 내용을 입력해주세요."
              _onChange={(e) => {
                if (!limitContent(e.target.value)) return;
                props.setBody(e.target.value);
              }}
            />
          </div>
          <div>
            <h4>사진을 등록해주세요.</h4>
            <Button variant="m_primary_line" onClick={() => fileRef.current?.click()}>
              <FiPlus />
              사진 등록
            </Button>
            <RequestUploadFile
              isEdit={props.isEdit}
              images={props.isEdit ? props.images : undefined}
              setImages={props.isEdit ? props.setImages : undefined}
            />
            <input type="file" multiple={true} ref={fileRef} onChange={onChangeFiles} style={{ display: "none" }} />
          </div>
        </LastShadowBox>
        <Button
          w="100%"
          fontSize="1.25rem"
          variant="m_blue"
          // isDisabled={true}
          onClick={props.onSubmitRequest}
        >
          {props.isEdit ? "저장" : "등록완료"}
        </Button>
      </MainActionBox>
    </SubframePart>
  );
}
