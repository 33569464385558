import styled from "@emotion/styled";
import { colors } from "../../styled/common";
import { breakPoints } from "../../styled/media";
import { ItemSetBox } from "../../styled/webApp";

//처리 상태 컴포넌트 타입 -> 민원과 합쳐서 상태값과 이벤트만 남도록,
export type ITagStatus = {
  id: string;
  title: string;
  // number: number;
};

interface ITag {
  el: ITagStatus;
  onClick: (str: string) => void;
  status: string;
}

interface ITagComponent {
  tagArr: ITagStatus[];
  onClick: (str: string) => void;
  status: string;
}

const ProgressItem = styled.div<{ isActive: boolean }>`
  color: ${colors.gray4};
  font-size: 0.875rem;
  padding: 4px 12px;
  border-radius: 1rem;
  margin-right: 0.5rem;
  border: 1px solid ${colors.gray2};
  cursor: pointer;
  @media ${breakPoints.phones} {
    padding: 4px 10px;
  }
  ${({ isActive }) =>
    isActive &&
    `color:${colors.whiteColor}; border: 1px solid ${colors.mainBlue}; background-color:${colors.mainBlue}; font-weight: 700`};
`;

function ProgressTagItem(props: ITag) {
  return (
    <ProgressItem
      id={props.el.id}
      isActive={props.status === props.el.id}
      onClick={(e) => props.onClick(e.currentTarget.id)}
    >
      {props.el.title}
    </ProgressItem>
  );
}

export default function ChangeStatusByTags(prop: ITagComponent) {
  const { tagArr, ...props } = prop;
  // console.log("바뀌는 지 확인 ---->", props.checkedItems);

  return (
    <ItemSetBox
      style={{ justifyContent: "flex-start", padding: "4px 0 1.5rem" }}
    >
      {tagArr.map((el) => (
        <ProgressTagItem el={el} key={el.title} {...props} />
      ))}
    </ItemSetBox>
  );
}
