import { Text, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Button, ModalFooter } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { queryClient } from "../../../..";
import ContentInputs from "../../../../components/Input/middleContents";
import * as C from "../../../../components/styled";
import { fetchClient } from "../../../../lib/api/axios";
import { fileValidation } from "../../../../lib/utils/format";
import * as N from "../../units/styled";
import { company_list_key } from "../../../../lib/api/queries/keys";
import { toast } from "../../../../lib/utils/toast";

interface IRegistTenant {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  setPage: Dispatch<SetStateAction<number>>;
  setCompanyNum?: Dispatch<SetStateAction<number>>;
}

export default function AllTenantRegisterModal(props: IRegistTenant) {
  const [files, setFiles] = useState<File | null>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const onloadFileName = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file === undefined) return;
    const isVaild = fileValidation(file);
    if (!isVaild)
      return toast.warning({
        title: "파일 등록 실패",
        description:
          "일괄 등록을 위한 파일은 xlsx 형식만 가능합니다. 파일 형식 확인 후 동일한 에러가 반복될 경우에는 오피스너 담당자에게 문의해주세요.",
      });
    setFiles(file);
  };

  const mutation = useMutation((formdata: FormData) =>
    fetchClient
      .post("/building/tenant/create_batch", formdata)
      .then((res) => {
        props.setPage(1);
        queryClient.invalidateQueries([company_list_key]);
        toast.success({
          title: "입주사 일괄등록 완료",
          description: "입주사 일괄 등록이 완료되었습니다.",
        });
        setFiles(null);
        props.onClose();
        return res.data.data;
      })
      .catch((error) => console.log(error.message)),
  );

  const onRegisterTenantFiles = () => {
    if (files === null)
      return toast.warning({
        title: "파일 오류",
        description: "일괄 등록할 파일이 없습니다. 파일을 먼저 업로드 후 전송해주세요.",
      });

    const formdata = new FormData();
    formdata.append("tenant_files", files);
    mutation.mutate(formdata);
  };

  useEffect(() => {
    if (!props.isOpen) return;
    return () => setFiles(null);
  }, [props.isOpen]);

  return (
    <Modal size={"lg"} variant="default" isOpen={props.isOpen} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent mt={"7.75rem"} position="relative">
        {mutation.isLoading ? (
          <C.CustomSpin thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        ) : (
          <>
            <ModalHeader>입주사 일괄등록</ModalHeader>
            <ModalBody>
              <p>
                <span>
                  입주사 정보를 일괄 등록하기 위해 양식에 맞는 엑셀 파일을 등록해야하며, 파일 양식은 ERP시스템의 입주사
                  현황 파일과 같습니다. 자세한 내용은 이용가이드를 참고해주세요.
                </span>
                {"  "}
                <a
                  className="font-semibold text-blue-600 underline  hover:text-blue-700"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.notion.so/bohome-livable/NEW-4f52610d4d7e4f258364ed4e2a230e0e?pvs=4"
                >
                  이용가이드 바로가기
                </a>
              </p>
              <ul className="list-disc px-6 pt-6 font-medium text-red-500">
                <li>공실 상태의 호실에만 정보가 등록됩니다.</li>
                <li>입주호실의 정보가 없거나 이미 등록되어 있는 호실의 경우 파일 업로드 후에도 등록되지 않습니다.</li>
              </ul>
              <div className={"mt-5 border-t border-gray-200 pt-3"}>
                <Button variant="blueLine" ml={0} onClick={() => toast.prepare()}>
                  파일 양식 다운로드
                </Button>
                <N.UploadBox>
                  <ContentInputs isReadOnly value={files?.name || ""} />
                  <input className="hidden" type="file" multiple={false} ref={fileRef} onChange={onloadFileName} />
                  <Button variant="basic" onClick={() => fileRef.current?.click()}>
                    파일 선택
                  </Button>
                </N.UploadBox>
              </div>
              <ModalFooter position="relative" marginTop="36px">
                <Button onClick={props.onClose} variant="cancel">
                  취소
                </Button>
                <Button onClick={onRegisterTenantFiles} variant="bgBlue">
                  전송하기
                </Button>
              </ModalFooter>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
