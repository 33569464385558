import { ButtonHTMLAttributes, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  disabled?: boolean;
  designSchema:
    | "primary"
    | "secondaryBlue"
    | "secondaryGray"
    | "neutral"
    | "accent"
    | "warning"
    | "error"
    | "ghostBlue";
  iconElement?: ReactNode;
  buttonText: string;
  size: "sm" | "md" | "lg";
} & ButtonHTMLAttributes<HTMLButtonElement>;

export default function Button({ designSchema, iconElement, buttonText, size, disabled = false, ...props }: Props) {
  const themeClasses = {
    primary: "bg-blue-600 hover:bg-blue-700 active:bg-blue-800 disabled:opacity-40 text-white",
    secondaryBlue:
      "bg-white hover:bg-blue-50 active:bg-blue-100 disabled:opacity-40 text-blue-600 font-medium border border-blue-600",
    secondaryGray:
      "bg-white hover:bg-gray-50 active:bg-gray-100 disabled:opacity-40 text-gray-500 font-medium border border-gray-300 box-border",
    neutral: "bg-gray-100 hover:bg-gray-200 active:bg-gray-300 disabled:opacity-40 text-gray-500 font-medium",
    accent: "bg-gray-50 hover:bg-gray-100 active:bg-gray-200 disabled:opacity-40 text-blue-500 font-medium",
    warning: "bg-red-500 hover:bg-red-600 active:bg-red-700 disabled:opacity-40 text-white font-medium",
    error: "bg-red-50 hover:bg-red-100 active:bg-red-200 disabled:opacity-40 text-red-500 font-medium",
    ghostBlue: "hover:bg-blue-50 active:bg-blue-100 disabled:opacity-40 text-blue-600 font-medium",
  };

  const sizeClasses = {
    sm: "px-2.5 text-xs h-9 gap-0.5 rounded-lg font-medium",
    md: "px-3.5 text-base h-12 gap-1 rounded-[10px] font-medium",
    lg: "px-3.5 text-lg h-[52px] gap-2 rounded-xl font-semibold",
  };

  const buttonClass = twMerge(
    "flex items-center justify-center font-medium transition-all break-keep box-border whitespace-nowrap",
    sizeClasses[size],
    themeClasses[designSchema],
    props.className,
  );

  return (
    <button {...props} className={buttonClass} disabled={disabled}>
      {iconElement}
      <span>{buttonText}</span>
    </button>
  );
}
