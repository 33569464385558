import { Text, Button, Box, Tag, useToast, UseDisclosureProps, useDisclosure } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { DetailBody } from "../../../../components/styled";
import { colors } from "../../../../components/styled/common";
import * as C from "../../../../components/styled/index";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";
import CancleModal from "../../../../components/modal/CancelModal";
import Dompurify from "dompurify";
import moment from "moment";
import { format_type } from "../../../../lib/utils/dataFormat";
import { useNavigate } from "react-router-dom";
import useUserAuth from "../../../../components/hooks/useUserAuth";
import { unAuthUser, warning } from "../../../../lib/theme/toast";
import { INotice } from "../../../../lib/types/Imodels";
import { useRef, MouseEvent } from "react";
import { useRoomType } from "../../../../lib/utils";
import ViewerModal from "./viewerList";
import { useViewerList } from "../../queries";
import QuestionMarkHover from "../../../../components/hover/question";

const ViewedList = styled(C.InfoBoxText)<{ isOpen: boolean }>`
  margin-right: 4px;
  ${({ isOpen }) => isOpen && `text-decoration: underline; cursor: pointer`};
`;

const BodyFooter = styled(DetailBody)`
  word-break: break-all;
  a {
    text-decoration: underline;
    color: ${colors.mainBlue};
  }
`;

const FileBox = styled.div`
  width: 100%;
  font-size: 20px;
  padding: 20px 16px 0;
  display: flex;
  flex-wrap: wrap;
`;

const FileList = styled.div`
  padding: 0px 32px 16px 0;
  max-width: 100%;
  img {
    width: 88px;
    height: 88px;
  }
  a:hover {
    text-decoration: underline;
  }
`;

interface IDetailPage extends UseDisclosureProps {
  data: INotice | undefined;
  onMoveToEditNotice: () => void;
  onClickDelete: () => void;
}

export default function NoticeDetailPageUI(props: IDetailPage) {
  const toast = useToast();
  const navigate = useNavigate();
  const auth = useUserAuth("notice");
  const room_type = useRoomType();
  const quillRef = useRef<HTMLDivElement>(null);
  const { onOpen, isOpen, onClose } = useDisclosure(); //읽은 입주사 조회
  const { data, isError } = useViewerList(String(props.data?._id), isOpen);

  const onOpenViewers = () => {
    if (!props.data)
      return toast({
        ...warning,
        title: "알림",
        description: "게시물을 조회한 입주사를 불러오고 있습니다. 잠시 후 다시 시도해주세요.",
      });

    if (isError) {
      onClose();
      return toast({
        ...warning,
        title: "입주사 조회",
        description: "게시글을 읽은 입주사가 없습니다.",
      });
    }

    if ((props.data.count || 0) > 1) onOpen();
  };

  const onCheckAuth = () => {
    if (auth !== "edit") return toast({ ...unAuthUser });
    props.onOpen && props.onOpen();
  };

  const onOpenImage = (e: MouseEvent<HTMLDivElement>) => {
    // const parent = e.currentTarget;
    const target = e.target;

    const arr = quillRef.current?.querySelectorAll("img");
    arr?.forEach((el) => {
      el.style.cursor = "pointer";

      if (el !== target) return;
      window.open(el.src);
    });
    // what.addEventListener("click", (event) => {
    //   // window.open('')
    //   const img = event.target
    //   if(img) img.
    //   // console.log(e);
    // });
    // const img = target.closest("img");
    // const img = target.nodeName
  };

  return (
    <C.Wrapper>
      <Box>
        <Text variant="static">
          <C.LeftIcons onClick={() => navigate(-1)} />
          공지/일정 게시글
        </Text>
      </Box>
      <Box>
        <C.DetailViewHeader>
          <C.DetailTitleBox>
            <div>
              <span>{props.data?.notice_type}</span>
              {props.data?.is_important && <Tag variant="important">중요글</Tag>}
              {props.data?.is_scheduled && <Tag variant="schedule">일정</Tag>}
              {props.data?.reservation_date && <Tag variant="reservation">예약</Tag>}
            </div>
            <Text>{props.data?.title}</Text>
          </C.DetailTitleBox>
          {auth === "edit" && (
            <C.TitleAlign>
              <Button onClick={props.onMoveToEditNotice} variant="blueLine">
                게시물 수정
              </Button>
              <Button variant="delete" onClick={onCheckAuth}>
                삭제
              </Button>
            </C.TitleAlign>
          )}
          <CancleModal
            isOpen={props.isOpen || false}
            onClose={() => props.onClose && props.onClose()}
            onClickCancel={() => props.onClose && props.onClose()}
            onClickSave={props.onClickDelete}
          >
            <Text>이 게시글을 모두 삭제할까요?</Text>
            <Text>삭제된 데이터는 복구할 수 없습니다.</Text>
          </CancleModal>
        </C.DetailViewHeader>
        <C.DetailInfoBox style={{ alignItems: "center" }}>
          <Text>
            공지대상{" "}
            <C.InfoBoxText>
              {props.data?.notice_to.length === room_type.length ? "전체" : format_type(props.data?.notice_to)}
            </C.InfoBoxText>
          </Text>
          {props.data?.schedule_id && (
            <Text>
              일정
              <C.InfoBoxText>
                {moment.utc(props.data?.schedule_id.start_date).format(" YYYY-MM-DD")}
                {" ~ "}
                {moment.utc(props.data?.schedule_id.end_date).format(" YYYY-MM-DD")}
              </C.InfoBoxText>
            </Text>
          )}
          <Text>
            상태<C.InfoBoxText> 공지중</C.InfoBoxText>
          </Text>
          <Text>
            작성자<C.InfoBoxText> {props.data?.user_id?.name}</C.InfoBoxText>
          </Text>
          <Text>
            작성일
            <C.InfoBoxText>{moment.utc(props.data?.createdAt).format(" YYYY-MM-DD HH:mm")}</C.InfoBoxText>
          </Text>
          <Text>
            조회수{" "}
            <ViewedList isOpen={(props.data?.count || 0) > 1} onClick={onOpenViewers}>
              {props.data?.count ? props.data?.count : 0}명 읽음
            </ViewedList>
          </Text>
          <QuestionMarkHover description="조회수와 상관없이 입주사를 기준으로 읽음 여부를 집계합니다." />
        </C.DetailInfoBox>
        {data && <ViewerModal isOpen={isOpen} onClose={onClose} _id={props.data?._id} />}
        <BodyFooter
          ref={quillRef}
          onClick={onOpenImage}
          className="view ql-editor"
          dangerouslySetInnerHTML={{
            __html: Dompurify.sanitize(String(props.data?.body)),
          }}
        />
        <FileBox>
          {props.data?.attached_files.map((el, idx) => (
            <FileList key={String(idx)}>
              <a href={el.location} target="_blank" rel="noreferrer">
                {el.file_original_name}
              </a>
            </FileList>
          ))}
        </FileBox>
      </Box>
    </C.Wrapper>
  );
}
