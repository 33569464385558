import { useDisclosure } from "@chakra-ui/react";
import { IAuth } from "../../../../lib/types/Imodels";
import { useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { permission_detail } from "../../../../lib/api/queries/urls";
import { permission_detail_key } from "../../../../lib/api/queries/keys";
import { GrayInfoBox } from "../../../../components/styled/webApp";
import NewAuth from "./Auth";

interface IAuthItem {
  el: IAuth;
}

export default function AuthItem(props: IAuthItem) {
  const { isOpen, onOpen, onClose } = useDisclosure(); //권한 등록 모달
  const isMaster = props.el.name === "마스터";

  const { data } = useQuery(
    [permission_detail_key, props.el._id],
    () =>
      fetchClient
        .post<BaseResponse<IAuth>>(permission_detail, {
          auth_id: props.el._id,
        })
        .then((res) => res.data.data),
    { enabled: isOpen }
  );

  const onOpenAuth = () => {
    if (isMaster) return;
    onOpen();
  };

  return (
    <GrayInfoBox onClick={onOpenAuth}>
      <div>{isMaster ? "최고관리자" : props.el.name}</div>
      {props.el.name !== "마스터" && <div className="edit">수정</div>}
      {isOpen && (
        <NewAuth isEdit={true} el={data} isOpen={isOpen} onClose={onClose} />
      )}
    </GrayInfoBox>
  );
}
