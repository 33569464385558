import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { CSSProperties, FC } from "react";

interface IMobileModal extends ModalProps {
  title: string;
  next: string;
  before: string;
  onAction: () => void;
  onEditClose?: () => void;
  _isRequired?: boolean;
  _content?: CSSProperties;
  _style?: CSSProperties;
  _isDisabled?: boolean;
}

const Required = styled.span`
  color: #2563eb;
  font-size: 1rem;
`;

const ModalSaveWithContents: FC<IMobileModal> = ({ children, ...props }) => {
  // 관리자 등록, 권한 등록 등 내용이 들어가는 팝업으로 사용
  // const { _style, _content, size, ...props } = rest;

  return (
    <Modal
      size="lg"
      variant="with_contents"
      isOpen={props.isOpen}
      onClose={props.onAction}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      scrollBehavior={props.scrollBehavior}
      blockScrollOnMount={props.blockScrollOnMount}
    >
      <ModalOverlay />
      <ModalContent style={props._content}>
        <ModalHeader
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {props.title} {props._isRequired && <Required>* 필수</Required>}
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button w="100%" mr="0.5rem" variant="m_gray" onClick={props.onClose}>
            {props.before}
          </Button>
          <Button
            isDisabled={props._isDisabled}
            w="100%"
            variant="m_blue"
            onClick={props.onAction}
          >
            {props.next}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalSaveWithContents;
