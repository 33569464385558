import CustomModal from "../../../components/CustomModal";
import Icon from "../../../types/iconsax";
import formatPhoneNumber from "../../../utils/formatPhoneNumber";
import toast from "react-hot-toast";
import { useUpdateCompanyMutation } from "../../../requests/company/useUpdateCompanyMutation";
import CustomToast from "../../../components/CustomToast";
import { useNavigate } from "react-router-dom";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  companyId: string;
  companyName: string;
};

export default function CompanyCheckoutModal({ isOpen, onClose, companyId, companyName }: Props) {
  const { mutateAsync: updateCompany } = useUpdateCompanyMutation();
  const navigate = useNavigate();

  if (!isOpen) return null;

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title="퇴실 처리"
      exit={true}
      neutralButtonText="취소"
      onNeutralButtonClick={onClose}
      warningButtonText="퇴실처리하기"
      onWarningButtonClick={() => {
        toast.promise(updateCompany({ companyId, isActivated: false }), {
          success: () => {
            onClose();
            navigate("/companies");
            return (
              <CustomToast
                iconType="check"
                title="퇴실 처리 완료"
                description={`${companyName}님을 퇴실 처리되었습니다.`}
              />
            );
          },
          error: () => {
            onClose();
            return <CustomToast iconType="error" title="퇴실 처리 중 에러가 발생하였습니다." />;
          },
          loading: <CustomToast iconType="loading" title="Loading" />,
        });
      }}
    >
      <div className="flex max-w-[400px] flex-col px-6 pb-4 pt-3">
        <span className="text-base text-gray-700">정말로 퇴실 처리할까요?</span>
        <span className="text-base text-red-500">
          퇴실 후에는 해당 입주사 구성원들의 앱이용이 제한되며, 복구할 수 없습니다.
        </span>
      </div>
    </CustomModal>
  );
}
