import { useParams } from "react-router-dom";
import SubmitNewRequest from "../../_view/SubmitNewRequest";
import { useFetchComplaint } from "../../commons/queries";

export default function EditComplaintPage() {
  const { complaintId } = useParams();
  const { data } = useFetchComplaint(complaintId);

  return <SubmitNewRequest isEdit={true} data={data} />;
}
