import { Checkbox, MenuItem } from "@chakra-ui/react";
import styled from "@emotion/styled";

const ListStyle = styled(MenuItem)`
  color: #6b7280;
  border-bottom: 1px solid #f5f5f5;
  padding: 8px 12px;
  text-align: left;
`;

export default function SelectButton(props: any) {
  // console.log("바뀌는 지 확인 ---->", props.checkedItems);
  return (
    <ListStyle value={`${props.label}`}>
      <Checkbox
        variant="default"
        w={"100%"}
        // isChecked={
        //   props.isEdit
        //     ? props.el?.includes(`${props.label}`)
        //       ? true
        //       : false
        //     : props.checkedItems?.includes(`${props.label}`)
        // }
        isChecked={
          props.checkedItems?.includes(`${props.label}`) ? true : false
        }
        value={props.label}
        onChange={(e) => {
          props.onChangeChecked(e.currentTarget.checked, `${props.label}`);
        }}
      >
        {props.value}
      </Checkbox>
    </ListStyle>
  );
}
