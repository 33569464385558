import { useNavigate } from "react-router-dom";
import { CSSProperties, FC, PropsWithChildren, ReactNode } from "react";
import { MainContainer } from "../styled/webApp";
import { LeftIcons, Wrapper } from "../styled";
import { Flex, useDisclosure } from "@chakra-ui/react";
import ResponsiveConfirm from "../modal/responsive/confirm";
import styled from "@emotion/styled";

interface ISubFrame extends PropsWithChildren {
  name: string;
  styled?: CSSProperties;
  isModal?: boolean;
  isWarnMessage?: () => boolean;
  additional?: ReactNode;
}

export const SubFrameContainer = styled(Wrapper)`
  padding: 0;
`;

export const HeaderBox = styled(MainContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  border-top: none;
`;

const SubframePart: FC<ISubFrame> = ({ children, name, ...props }) => {
  const navigate = useNavigate();
  const { onOpen, isOpen, onClose } = useDisclosure(); //되돌아가기

  const onCloseModal = () => {
    onClose();
    navigate(-1);
  };

  const isNeedModal = () => {
    if (props.isModal) return onOpen();
    navigate(-1);
  };

  return (
    <SubFrameContainer style={props.styled}>
      <HeaderBox>
        <Flex>
          <LeftIcons onClick={() => (props.isWarnMessage && props.isWarnMessage() ? onOpen() : isNeedModal())} />
          <p>{name}</p>
        </Flex>
        {props.additional && props.additional}
      </HeaderBox>
      <ResponsiveConfirm
        next="이동"
        before="취소"
        title="되돌아가기"
        isOpen={isOpen}
        onClose={onClose}
        onAction={onCloseModal}
      >
        <>
          지금까지 작성하신 내용은 저장되지 않습니다.
          <br />
          이전 화면으로 이동하시겠습니까?
        </>
      </ResponsiveConfirm>
      <MainContainer>{children}</MainContainer>
    </SubFrameContainer>
  );
};

export default SubframePart;
