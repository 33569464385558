import { useQuery } from "@tanstack/react-query";
import { signOut } from "firebase/auth";
import { deleteToken, onMessage } from "firebase/messaging";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IHeader } from "..";
import { queryClient } from "../../..";
import { GlobalContext } from "../../../App";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { building_key, dashboard_group_key, notification_group_key } from "../../../lib/api/queries/keys";
import { building } from "../../../lib/api/queries/urls";
import { auth, messaging } from "../../../lib/firebase";
import { IBuilding } from "../../../lib/types/Imodels";
import { notification_action, onActionInNotification } from "./func";
import LayoutHeaderUI from "./presenter";
import { toast } from "../../../lib/utils/toast";

export default function LayoutHeader(props: IHeader) {
  const { ReactNativeWebView } = window;

  const navigate = useNavigate();
  const location = useLocation();
  const { user: data, setBuildInfo, setUser, AppToken } = useContext(GlobalContext);
  const [notification, setNotification] = useState("");

  const onClickLogout = () => {
    if (AppToken) {
      //토큰 있을때(앱)
      if (ReactNativeWebView) {
        //로그아웃 메세지 전달
        ReactNativeWebView.postMessage(JSON.stringify({ message: "signout" }));
      }
    } else {
      signOut(auth)
        .then(() => {
          props.setIsMenu(false);
          messaging !== null && deleteToken(messaging);
          setUser && setUser(undefined);
          setBuildInfo && setBuildInfo(undefined);
          toast.warning({
            title: "알림",
            description: "로그아웃되었습니다. 로그인 페이지로 이동합니다.",
          });
          navigate("/");
        })
        .catch((err) => console.log(err));
    }
  };

  //FCM Web notification
  useEffect(() => {
    if (messaging === null) return;
    onMessage(messaging, (payload) => {
      if (payload.notification !== undefined) {
        queryClient.invalidateQueries([notification_group_key]);
        setNotification(payload.notification.body || "");

        //데이터가져오기
        if (location.pathname === "/dashboard") queryClient.invalidateQueries([dashboard_group_key]);
        notification_action(payload.data?.notification_type);

        const notificationTitle = payload.notification.title || "";
        const notificationOptions = {
          body: payload.notification.body,
        };

        const push_alert = new Notification(notificationTitle, notificationOptions);

        push_alert.addEventListener("click", () => {
          if (!payload.data?.notification_type) return;
          if (
            payload.data?.notification_type === "tenant_master_approval_request" ||
            payload.data?.notification_type === "tenant_master_auto_approval"
          ) {
            onActionInNotification(navigate, payload.data?.notification_type, payload.data?.tenant_id);
          }
          onActionInNotification(navigate, payload.data?.notification_type, payload.data?.data);
          push_alert.close();
        });

        return push_alert;
      } else {
        if (payload.data?.notification_type === "seat_reservation") {
          queryClient.invalidateQueries(["nfc_seats"]);
        }
      }
    });
  }, [messaging]);

  const { data: build } = useQuery(
    [building_key, "context_building"],
    () =>
      fetchClient.post<BaseResponse<IBuilding>>(building).then((res) => {
        setBuildInfo && setBuildInfo(res.data.data);
        return res.data.data;
      }),
    { enabled: data?.company_type === "building", staleTime: Infinity },
  );

  return (
    <LayoutHeaderUI
      data={data}
      onClickLogout={onClickLogout}
      build={build}
      AppToken={AppToken ? AppToken : null}
      {...props}
    />
  );
}
