import { useToast, useDisclosure, DrawerBody, DrawerFooter, Flex, Text, Button } from "@chakra-ui/react";
import moment from "moment";
import { queryClient } from "../../../..";
import { IReservationType } from "./types";
import { useState } from "react";
import { fetchClient } from "../../../../lib/api/axios";
import { ISchedule } from "../../../../lib/types/Imodels";
import { getDate, getTime } from "../../../../lib/utils/format";
import { schedule_approved } from "../../../../lib/api/queries/urls";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { success, unAuthUser, warning } from "../../../../lib/theme/toast";
import PublicRoomInfo from "../../components/detailModal/publicRoomInfo";
import { public_list_key } from "../../../../lib/api/queries/keys";
import { useMutation } from "@tanstack/react-query";
import CommonDrawerModal from "../../../../components/Drawer";
import { DetailsInfo } from "../../../../components/styled/webApp";
import { BookingStatus, ToggleStatus, UpArrowIcon } from "../../../facility/booking/[publicId]/_view/userItem";
import { format_user_status } from "../../../../lib/utils/dataFormat";
import RespCancelModal from "../../../../components/modal/responsive/cancel";
import GrayRadiusTextArea from "../../../../components/textarea";
import { usePublicDetail } from "../../commons/queries";
import ResponsiveConfirm from "../../../../components/modal/responsive/confirm";

type IApprovedLog = {
  date: string;
  check_status: string;
  rejected_reason?: string;
};

export default function ReservationInfo(props: IReservationType) {
  const toast = useToast();
  const [rejected, setRejected] = useState<string>();

  const { isOpen, onOpen, onClose } = useDisclosure(); //회의실 예약 상세 모달
  const { isOpen: isRoom, onOpen: onRoom, onClose: offRoom } = useDisclosure(); //예약 확정 확인 모달
  const { isOpen: isRej, onOpen: onRej, onClose: offRej } = useDisclosure(); //회의실 예약 반려 경고 모달

  const { data } = usePublicDetail(String(props.data?.room_public_id?._id), isOpen);

  const onResetReason = () => {
    setRejected(undefined);
    offRej();
  };

  const onCloseModals = () => {
    offRoom();
    props.onClose();
  };

  const mutation = useMutation((obj: IApprovedLog) =>
    fetchClient
      .post<BaseResponse<ISchedule>>(schedule_approved, {
        schedule_id: props.data?._id,
        check_status: obj.check_status,
        rejected_reason: rejected,
      })
      .then((res) => {
        isRej && (onResetReason(), props.onClose()); // 예약 반려
        isRoom && onCloseModals(); // 이건 예약 승인일때
        // onReset();
        toast({
          ...success,
          title: "예약 상태 변경 완료",
          description: `예약 상태 변경이 완료되었습니다.`,
        });
        props.refetch ? props.refetch() : queryClient.invalidateQueries([public_list_key]);
        return res.data.data;
      })
      .catch((err) => {
        if (err.response?.status === 401) return toast({ ...unAuthUser });
      }),
  );

  const onRejectedReservation = () => {
    if (!rejected)
      return toast({
        ...warning,
        title: "알림",
        description: "예약 취소를 위해 취소 사유를 입력해주세요.",
      });
    if (mutation.isLoading) return;

    const logObj: IApprovedLog = {
      date: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      check_status: "rejected",
      rejected_reason: rejected,
    };

    mutation.mutate(logObj);
  };

  const onClickApproval = () => {
    if (mutation.isLoading) return;
    const logObj: IApprovedLog = {
      date: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      check_status: "approved",
      rejected_reason: rejected,
    };

    mutation.mutate(logObj);
  };

  const [onToggle, setOnToggle] = useState(false);

  return (
    <CommonDrawerModal
      title={props.title}
      isOpen={props.isOpen}
      onClose={() => {
        if (onToggle) setOnToggle(false);
        props.onClose();
      }}
    >
      <DrawerBody>
        <DetailsInfo>
          <h6>신청일</h6>
          <div>{moment.utc(props.data?.createdAt).format("YYYY-MM-DD HH:mm")}</div>
        </DetailsInfo>
        <DetailsInfo>
          <h6>회의실명 / 위치</h6>
          <div className="items" onClick={onOpen}>
            {props.data?.room_public_id?.name} / {props.data?.room_public_id?.dong}동{" "}
            {props.data?.room_public_id?.floor}층
          </div>
          {data && <PublicRoomInfo isOpen={isOpen} onClose={onClose} data={data} />}
        </DetailsInfo>
        <DetailsInfo>
          <h6>이용기간</h6>
          <div>
            {getDate(props.data?.start_date)} {getTime(props.data?.start_date)} - {getTime(props.data?.end_date)}
          </div>
        </DetailsInfo>
        <DetailsInfo>
          <h6>예약자</h6>
          <div>{props.data?.user_id.name} </div>
        </DetailsInfo>
        <DetailsInfo>
          <h6>연락처</h6>
          <div>{props.data?.user_id.phone_number}</div>
        </DetailsInfo>
        <DetailsInfo>
          <h6>소속</h6>
          <div style={{ width: "calc(100% - 2.5rem)", textAlign: "right" }}>
            {props.data?.tenant_id.xperp_name}({props.data?.tenant_id.name}) /
            {props.data?.tenant_id?.room_ids.length === 0
              ? ""
              : ` ${props.data?.tenant_id?.room_ids[0].dong}동 ${props.data?.tenant_id.room_ids[0].ho}호`}
          </div>
        </DetailsInfo>
        <DetailsInfo>
          <h6>참여인원</h6>
          <div>{String(props.data?.people_num || 0)}명</div>
        </DetailsInfo>
        <DetailsInfo>
          <h6>회의명</h6>
          <div style={{ width: "calc(100% - 4rem)", textAlign: "right" }}>
            {props.data?.purpose ? props.data?.purpose : ""}
          </div>
        </DetailsInfo>
        <DetailsInfo>
          <h6>예약상태</h6>
          <div style={{ width: "calc(100% - 4rem)" }}>
            {props.data?.check_status === "rejected" || props.data?.check_status === "completed" ? (
              <>
                <BookingStatus
                  el={props.data?.check_status || ""}
                  style={{ cursor: "pointer" }}
                  onClick={() => setOnToggle((prev) => !prev)}
                >
                  {props.data?.check_status === "rejected"
                    ? `관리자취소 (${moment.utc(props.data?.rejected_date).format("YY-MM-DD HH:mm")})`
                    : "이용완료"}
                  <UpArrowIcon isopen={String(onToggle)} />
                </BookingStatus>
                {(props.data?.check_status === "rejected" || props.data?.approved_date) && (
                  <ToggleStatus
                    isOpen={onToggle}
                    h={document.querySelector("#status")?.clientHeight}
                    reason={props.data?.approved_date ? true : false}
                  >
                    <BookingStatus id="status" style={{ wordBreak: "break-word" }} el={props.data?.check_status || ""}>
                      {props.data.rejected_reason || ""}
                    </BookingStatus>
                    {props.data?.approved_date && (
                      <div>예약 확정 ({moment.utc(props.data.approved_date).format("YYYY-MM-DD HH:mm")})</div>
                    )}
                  </ToggleStatus>
                )}
              </>
            ) : (
              <BookingStatus el={props.data?.check_status || ""}>
                {format_user_status(props.data?.check_status)}
                {props.data?.approved_date && <>({moment.utc(props.data?.approved_date).format("YYYY-MM-DD HH:mm")})</>}
              </BookingStatus>
            )}
          </div>
        </DetailsInfo>
        <DetailsInfo>
          <h6>결제상태</h6>
          <div>
            {props.data?.check_status === "rejected" ? "환불완료" : "결제완료"}(
            {props.data?.payment_method_and_price?.price}포인트)
          </div>
        </DetailsInfo>
      </DrawerBody>
      {props.auth === "edit" && (
        <DrawerFooter>
          {(props.data?.check_status === "pending" && moment() > moment.utc(props.data?.end_date)) ||
          props.data?.check_status === "rejected" ? (
            <></>
          ) : (
            <>
              {props.data?.check_status === "pending" &&
              moment().format("YYYY-MM-DD HH:mm") < moment.utc(props.data?.end_date).format("YYYY-MM-DD HH:mm") ? (
                <Flex w="100%">
                  <Button variant="m_light_danger" w="100%" mr="0.5rem" onClick={onRej}>
                    예약취소
                  </Button>
                  <Button variant="m_blue" w="100%" onClick={onRoom}>
                    예약확정
                  </Button>
                </Flex>
              ) : (
                <Button variant="m_light_danger" w="100%" onClick={onRej}>
                  예약취소
                </Button>
              )}
            </>
          )}
        </DrawerFooter>
      )}
      <ResponsiveConfirm
        title="예약확정"
        isOpen={isRoom}
        before="취소"
        next="예약확정"
        onClose={onResetReason}
        onAction={onClickApproval}
      >
        <Text fontSize="1rem">해당 예약건을 ‘예약확정’ 하시나요?</Text>
      </ResponsiveConfirm>
      <RespCancelModal
        title="예약취소"
        isOpen={isRej}
        next="예약취소"
        onClose={offRej}
        onAction={onRejectedReservation}
        styled={{ maxWidth: "30rem" }}
      >
        <div style={{ fontSize: "1rem" }}>
          <Text>해당 예약건을 ‘예약취소(반려)’ 하시나요?</Text>
          <Text>예약취소 사유를 입력해주세요. 예약취소 후에는 재승인 할 수 없습니다.</Text>
        </div>
        <div style={{ padding: "1.25rem 0 1rem" }}>
          <GrayRadiusTextArea
            rows={1}
            isLimited={true}
            value={rejected || ""}
            placeholder="예약을 취소하는 사유를 입력해주세요."
            _onChange={(e) => {
              if (e.target.value.length > 1000) return;
              setRejected(e.target.value);
            }}
          />
        </div>
      </RespCancelModal>
    </CommonDrawerModal>
  );
}
