import { MutableRefObject, useContext } from "react";
import { GlobalContext } from "../../App";

export const useRoomType = () => {
  const { buildInfo } = useContext(GlobalContext);

  if (!buildInfo) return [];
  if (buildInfo.building_type === "knowledge_industry_center") {
    return [
      { id: 1, label: "office", value: "사무용" },
      { id: 2, label: "residential", value: "주거용" },
      { id: 3, label: "store", value: "상가용" },
      { id: 4, label: "owner", value: "소유자" },
    ];
  }
  if (buildInfo.building_type === "rental_building")
    return [
      { id: 1, label: "office", value: "사무용" },
      { id: 3, label: "store", value: "상가용" },
    ];

  return [{ id: 1, label: "office", value: "사무용" }];
};

type T = HTMLDivElement[] | null[] | HTMLInputElement[];

export const useCloseModalWhenClicked = (refs: MutableRefObject<T>) =>
  Array.from(refs.current).forEach((el) => {
    if (!el) return;
    if (el.id === "true") el.click();
  });
