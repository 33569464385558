import { HiArrowLeft } from "@react-icons/all-files/hi/HiArrowLeft";
import { HiArrowRight } from "@react-icons/all-files/hi/HiArrowRight";
import styled from "@emotion/styled";
import { Dispatch, SetStateAction } from "react";
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPrevious,
  usePagination,
} from "@ajna/pagination";
import { colors } from "../styled/common";

interface PaginationType {
  onChangeFunc?: (page: number) => void; //사용하게되면
  allPage: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
}

const Wrapper = styled.div`
  width: auto;
`;

const container = {
  width: "42px",
  height: "42px",
  border: `1px solid ${colors.gray2}`,
  backgroundColor: colors.whiteColor,
  borderRadius: 0,
  fontWeight: 500,
  color: colors.gray2,
};

const active = {
  ...container,
  border: "1px solid #111827 !important",
  color: "#111827 !important",
};

export default function PaginationArrowOnly(props: PaginationType) {
  const { setCurrentPage } = usePagination({
    pagesCount: props.allPage,
    initialState: { pageSize: 10, currentPage: props.page },
    limits: {
      outer: 2,
      inner: 2,
    },
  });

  return (
    <Wrapper>
      <Pagination
        pagesCount={props.allPage}
        currentPage={props.page}
        onPageChange={(page) => {
          setCurrentPage(page);
          props.setPage(page);
        }}
      >
        <PaginationContainer align="center" justify="flex-end" w="100%">
          <PaginationPrevious
            variant="default"
            style={{ ...container, ...{ marginRight: "12px" } }}
            _hover={{ ...active }}
            _focus={{ boxShadow: "none" }}
          >
            <span>
              <HiArrowLeft style={{ fontSize: "16px" }} />
            </span>
          </PaginationPrevious>
          <PaginationNext
            variant="default"
            style={{ ...container }}
            _hover={{ ...active }}
            _focus={{ boxShadow: "none" }}
          >
            <span>
              <HiArrowRight style={{ fontSize: "16px" }} />
            </span>
          </PaginationNext>
        </PaginationContainer>
      </Pagination>
    </Wrapper>
  );
}
