//공통 로그인 & 회원
export const userVerify = "/building/token/verify";
export const rester_user = "/building/profile/init";
export const user_detail = "/building/profile";
export const user_list = "/building/profile/list_by_department";
export const company_approval = "/building/profile/approve";
export const password_update = "/building/profile/update_password";
export const profile_update = "/building/profile/update";
export const profile_delete = "/building/profile/delete";
export const upload_file = "/building/notice/upload_files"; //유저 권한 필요
export const upload_images = "/building/upload"; //유저 권한 X

//건물 관련 endpoints
export const building = "/building/building";
export const building_update = "/building/building/update";
export const building_list = "/building/name_list";
export const building_floor_list = "/building/building/floor";
export const building_floor_location = "/building/building/dong_floor_location"; // 온도조절용 동,층,위치
export const management_office_update = "/building/building/update_management_office";
export const category_list = "/building/category/list";

// 건물 FAQ
export const faq_list = "/building/faq/list";
export const delete_faq = "/building/faq/delete";
export const create_faq = "/building/faq/create";
export const update_faq = "/building/faq/update";

//관리자 소속 관련 endpoints
export const department_list = "/building/department/list";
export const department_detail = "/building/department";
export const create_department = "/building/department/create";
export const delete_department = "/building/department/delete";
export const update_department = "/building/department/update";

//관리자 권한 관련 endpoints
export const create_permission = "/building/auth/create";
export const update_permission = "/building/auth/update";
export const permission_list = "/building/auth/list";
export const permission_detail = "/building/auth";
export const assign_permission = "/building/auth/assign";
export const delete_permission = "/building/auth/delete";

//입주사 관련 endpoints
export const tenant_detail = "/building/tenant";
export const tenant_delete = "/building/tenant/delete";
export const tenant_create = "/building/tenant/create";
export const tenant_update = "/building/tenant/update";
export const tenant_approve = "/building/profile/auto_approve";
export const send_message = "/building/message/sign_up_link/to_tenant";
export const send_message_to_owner = "/building/message/sign_up_link/to_owner";
export const send_message_to_owner_one = "/building/message/sign_up_link/to_owner_one";
export const send_message_to_owner_many = "/building/message/sign_up_link/to_owner_many";
export const whole_owner_create = "/building/room_owner/create_batch";
export const company_search = "/building/tenant/list";
export const change_representative = "building/room_owner/change_representative";

//입주 카드 관련 endpoints
export const register_card_list = "/building/resident_card/list";
export const register_card_create = "/building/resident_card/create";
export const register_card = "/building/resident_card";
export const register_card_delete = "/building/resident_card/delete";
export const register_card_approval = "/building/resident_card/complete";

//호실 관련 endpoints
export const company_list = "/building/room/list";
export const empty_room_list = "/building/room/empty";
export const room_list = "/building/room";
export const register_room = "/building/room/create";
export const room_update = "/building/room/update";
export const room_delete = "/building/room/delete";
export const room_owner_update = "building/room_owner/update";
export const add_owner_in_room = "building/room_owner/create";
export const checkout_owner_in_room = "building/room_owner/checkout"; // 소유자 퇴실

//공지 관련 endpoints
export const notice_list = "/building/notice/list";
export const notice_detail = "/building/notice";
export const notice_detail_update = "/building/notice/update";
export const notice_category = "/building/notice_category/list";
export const notice_delete = "/building/notice/delete";
export const notice_create = "building/notice/create";
export const notice_viewer_list = "/building/notice/reading_tenant";

//공용시설 관련 endpoints
export const schedule_list = "/building/schedule/reserve/list";
export const schedule_detail_list = "/building/schedule";
export const public_category = "/building/room_public_category/list";
export const public_create = "/building/room_public/create";
export const public_update = "/building/room_public/update";
export const public_update_batch = "/building/room_public/update_batch";
export const public_list = "/building/room_public/list";
export const public_detail = "/building/room_public";
export const public_delete = "/building/room_public/delete";
export const schedule_approved = "building/room_public/reserve/approve";
export const public_reserve = "/building/room_public/reserve/create";
export const public_reserve_delete = "/building/room_public/reserve/delete";

//공용시설 예약 상품 관련 endpoints
export const create_booking_item = "/building/reservation_product/create";
export const booking_item_list = "/building/reservation_product/list";
export const update_booking_item = "/building/reservation_product/update";
export const booking_item = "/building/reservation_product";
export const delete_booking_item = "/building/reservation_product/delete";
export const update_batch_item = "/building/reservation_product/update_batch";
export const change_items_order = "/building/reservation_product/update_index";
export const booking_user_list = "/building/schedule/reserve_reservation_product/list";

//포인트 관련 endpoints
export const tenant_point_list = "/building/point/list";
export const tenant_point_history = "/building/point/list_by_tenant";
export const tenant_point = "/building/point/by_tenant";
export const tenant_point_recharge = "/building/point/recharge";
export const point_recharge = "/building/point/set/auto_recharge";
export const individual_point_recharge = "/building/point/set/manual_recharge";
export const tenant_regular_point_list = "/building/tenant/rooms/point";

//좌석 예약 endpoints
export const seat_list = "/building/seat/list";
export const seat_schedule = "/building/seat_schedule";
export const seat_schedule_list = "/building/seat_schedule/list";

//관리비 관련 endpoints
export const manage_fee = "/building/management_fee/list";
export const create_manage_fee = "/building/management_fee/create_batch";
export const update_manage_fee = "/building/management_fee/update_batch";
export const manage_fee_list = "/building/management_fee_detail/list";
export const manage_fee_delete = "/building/management_fee/delete";
export const manage_fee_notify = "/building/management_fee/notify";
export const account_list = "/building/account/list";
export const bank_list = "/building/bank/list";
export const register_account = "/building/account/create";
export const delete_account = "/building/account/delete";
export const update_payment = "/building/payment_date/update";
export const saved_fixed_fee = "/building/management_fee_detail/save";

//민원 관련 endpoints
export const complaint_create = "/building/complaint/create";
export const complaint_list = "/building/complaint/list";
export const complaint_count = "/building/complaint/count";
export const complaint_detail = "/building/complaint";
export const complaint_comment = "/building/complaint/create_comment";
export const complaint_comment_update = "/building/complaint/update_comment";
export const complaint_comment_delete = "/building/complaint/delete_comment";
export const complaint_admin_memo = "/building/complaint/descript";
export const complaint_progress = "/building/complaint/update_progress";
export const complaint_delete = "/building/complaint/delete";
export const complaint_update = "/building/complaint/update";
export const complaint_history = "/building/complaint/history_list";
export const complaint_assign_team = "/building/complaint/assign";

//설문조사 관련 endpoints
export const survey_list = "/building/survey/list";
export const survey_detail = "/building/survey";
export const survey_create = "/building/survey/create";
export const survey_update = "/building/survey/update";

//주차 관련 endpoints
export const parking_history = "/building/car/list";
export const parking_detail = "/building/car";
export const create_car = "/building/car/create";
export const approve_car = "/building/car/approve";
export const update_car_info = "/building/car/descript";
export const update_car = "/building/car/update";

//알림 관련 endpoints
// export const notification_group = "/building/notification/group";
export const notification_list = "/building/notification/list";
export const dashboard_group = "/building/dashboard";

// **************** 임대건물용, 기본은 지산 기준 ********************************

// 임대료, 관리비
export const create_rental = "/building/rent_fee/create";
export const update_rental = "/building/rent_fee/update";
export const rental_list = "/building/rent_fee/list";
export const detail_rental = "/building/rent_fee";
export const update_rental_stauts = "/building/rent_fee/update_type";
export const update_management_stauts = "/building/management_fee/update_type";
export const delete_rental = "/building/rent_fee/delete";
export const notify_rental = "/building/rent_fee/notify";
export const notify_manage = "/building/management_fee/notify";
export const manage_tenant_list = "/building/room/tenants";
export const tenant_rental_list = "/building/tenant/rooms";

//온도 조절 관련 endpoints
export const temperature_list = "/building/temperature/table";
export const temperature_update = "/building/temperature/update";
export const temperature_history = "/building/temperature/list";

// 식단표 관련 endpoints
export const meal_planner_list = "/building/cafeteria/list";
export const detail_meal_planner = "/building/cafeteria";
export const delete_meal_plan = "/building/cafeteria/delete";
export const create_meal_plan = "/building/cafeteria/create";
export const update_meal_plan = "/building/cafeteria/update";

// **************** 공통 사용되는 endpoints ********************************
export const registered_facility_list = "/building/room_public/category/list";

// **************** 데이터 추출 endpoints ********************************
export const complaint_file = "building/complaint/list_for_excel";
export const temperature_file = "building/temperature/list_for_excel";
export const point_file = "building/point/excel";
