import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ModalProps } from "@chakra-ui/react";
import { CSSProperties } from "react";
import Button from "../button/Button";

type Props = {
  title: string;
  close?: string;
  onClickCancle?: () => void;
  onClickSave?: () => void;
  style?: CSSProperties;
  width?: number;
  height?: number;
} & ModalProps;

export default function ConfirmModal(rest: Props) {
  const {
    children,
    styleConfig,
    style,
    onClickCancle,
    onClickSave,
    close,
    title = "삭제",
    width = 312,
    height,
    ...props
  } = rest;

  return (
    <Modal
      {...props}
      isOpen={props.isOpen}
      onClose={props.onClose}
      blockScrollOnMount={props.blockScrollOnMount}
      closeOnOverlayClick={props.closeOnOverlayClick}
      isCentered={props.isCentered || true}
    >
      <ModalOverlay />
      <ModalContent padding={6} maxW={width} height={height} rounded={20}>
        <ModalHeader padding={0} fontSize={20} fontWeight={500} marginBottom={3}>
          {title}
        </ModalHeader>
        <ModalBody padding={0} fontSize={14} marginBottom={6}>
          {children}
        </ModalBody>
        <ModalFooter padding={0} gap={2} marginTop={3}>
          <Button
            onClick={() => {
              if (!onClickCancle) props.onClose();
              onClickCancle && onClickCancle();
            }}
            className="flex-1"
            designSchema={"neutral"}
            buttonText={"취소"}
            size={"md"}
          />
          <Button
            onClick={onClickSave}
            className="flex-1"
            designSchema={"primary"}
            buttonText={close || "확인"}
            size={"md"}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
