import { Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { PropsWithChildren } from "react";
// import { useNavigate } from "react-router-dom";
// import useUserAuth from "../hooks/useUserAuth";
import { Wrapper } from "../styled";
import { colors } from "../styled/common";

interface ISubPart extends PropsWithChildren {
  title: string; //타이틀
}

export const BodyHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;

const WrapperHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.gray2};
  padding-bottom: 20px;
`;

const CompactHeader: React.FC<ISubPart> = ({ children, ...props }) => {
  return (
    <Wrapper>
      <WrapperHeader>
        <Text variant="bolder">{props.title}</Text>
      </WrapperHeader>
      {children}
    </Wrapper>
  );
};

export default CompactHeader;
