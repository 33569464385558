import { useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import useUserAuth from "../../../components/hooks/useUserAuth";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { notice_category_key, notice_list_key, notice_viewer_key } from "../../../lib/api/queries/keys";
import { notice_category, notice_list, notice_viewer_list } from "../../../lib/api/queries/urls";
import { warning } from "../../../lib/theme/toast";
import { ICategory, INotice, IViewer } from "../../../lib/types/Imodels";
import { SearchType } from "../units/types";

export const useNoticeSearch = (
  checkedItems: string[],
  searchArr: SearchType,
  startDate: Date | null,
  endDate: Date | null,
  currentPage: number,
  setAllPage: Dispatch<SetStateAction<number>>,
  setTotal: Dispatch<SetStateAction<number>>,
) => {
  const isAuth = useUserAuth("notice");

  return useQuery(
    [notice_list_key, currentPage],
    () =>
      fetchClient
        .get<BaseResponse<INotice[]>>(notice_list, {
          params: {
            is_important: false,
            limit: 10,
            page: currentPage,
            notice_category: searchArr.category,
            notice_to: checkedItems.length === 0 ? "" : JSON.stringify(checkedItems),
            is_scheduled: searchArr.schedule,
            start_date: startDate !== null ? moment(startDate).format("YYYY-MM-DD") : "",
            end_date: endDate !== null ? moment(endDate).format("YYYY-MM-DD") : "",
            notice_status: searchArr.status,
            search: searchArr.text,
          },
        })
        .then((res) => {
          setAllPage(res.data?.total_page_num || 0);
          setTotal(res.data?.total_num);
          return res.data.data;
        })
        .catch((err) => console.log(err.message)),
    {
      keepPreviousData: true,
      enabled: isAuth === "read" || isAuth === "edit",
    },
  );
};

export const useFetchCategory = () => {
  return useQuery(
    [notice_category_key],
    () => fetchClient.get<BaseResponse<ICategory[]>>(notice_category).then((res) => res.data.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useViewerList = (ids: string, enabled?: boolean, page?: number) => {
  const toast = useToast();
  return useQuery(
    [notice_viewer_key, ids, String(page || 1)],
    () =>
      fetchClient
        .get<{
          message: string;
          reading: BaseResponse<IViewer[]>;
          unreading: BaseResponse<IViewer[]>;
        }>(notice_viewer_list, {
          params: {
            limit: 10,
            page: page || 1,
            notice_id: ids,
          },
        })
        .then((res) => res.data)
        .catch((err: AxiosError) => {
          if (err.response?.status === 504)
            toast({
              ...warning,
              title: "입주사 조회",
              description: "게시글을 읽은 입주사가 없습니다.",
            });
          return undefined;
        }),
    { enabled, retry: 1 },
  );
};
