export default function getSearchRoomIndex(roomInfos: Array<{ ho: string }>, searchText: string) {
  if (searchText === "") return 0;
  return Math.max(roomInfos.map((room) => room.ho).indexOf(searchText), 0);
}

export function getSearchRoomText(roomInfos: Array<{ dong: string; ho: string }>, searchText: string) {
  const index = getSearchRoomIndex(roomInfos, searchText);
  const roomInfo = roomInfos[index];
  return `${roomInfo.dong}동 ${roomInfo.ho}호`;
}
