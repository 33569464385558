import { ForwardedRef, forwardRef, InputHTMLAttributes, ReactElement } from "react";
import IconCircleClose from "../icons/IconCircleClose";
import { twMerge } from "tailwind-merge";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  themeSize?: "sm" | "md" | "lg";
  iconElement?: ReactElement;
  showDeleteButton?: boolean;
  onDelete?: () => void;
  disabled?: boolean;
}

const Input = forwardRef<HTMLInputElement, Props>(
  (
    { iconElement, showDeleteButton, onDelete, themeSize = "lg", disabled = false, ...props },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const sizeClass = {
      sm: twMerge("text-base placeholder:text-base h-[36px] pr-2.5", iconElement ? "pl-[40px]" : "pl-2.5"),
      md: twMerge("text-base placeholder:text-base h-[48px] pr-3", iconElement ? "pl-[46px]" : "pl-3"),
      lg: twMerge("text-lg placeholder:text-lg h-[52px] pr-3", iconElement ? "pl-[46px]" : "pl-3"),
    }[themeSize];
    return (
      <div className="relative w-full">
        {iconElement && <div className="absolute left-3 top-1/2 -translate-y-1/2 leading-none">{iconElement}</div>}
        <input
          ref={ref}
          {...props}
          className={twMerge(
            `peer w-full rounded-lg border border-gray-300 bg-white py-1 transition-all placeholder:text-gray-700/40 invalid:border-red-500 invalid:ring-2 invalid:ring-gray-200 focus:border-blue-600`,
            sizeClass,
            disabled && "cursor-not-allowed bg-gray-100",
          )}
          disabled={disabled}
        />
        {showDeleteButton && (
          <button
            type="button"
            className="absolute right-4 top-1/2 -translate-y-1/2 transform peer-placeholder-shown:hidden"
            onClick={onDelete}
            tabIndex={-1}
          >
            <IconCircleClose />
          </button>
        )}
      </div>
    );
  },
);

export default Input;
