import styled from "@emotion/styled";
import { ChangeEvent, CSSProperties, FC } from "react";
import { CountNum } from "../../pages/facility/commons/styled";
import { colors } from "../styled/common";

interface IRadiusTextArea {
  id?: string;
  value?: string;
  readOnly?: boolean;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string;
  rows?: number;
  _style?: CSSProperties;
  _onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  isLimited?: boolean;
  num?: number;
}

const StaticTextArea = styled.textarea`
  width: 100%;
  height: 7.5rem;
  border: 1px solid ${colors.gray3};
  padding: 0.75rem;
  border-radius: 0.5rem;
  resize: none;
  margin: 0;
  ::placeholder {
    color: ${colors.gray2};
  }
`;

//모바일친화 textarea
const GrayRadiusTextArea: FC<IRadiusTextArea> = ({ ...props }) => {
  // console.log(props);
  const { _style, _onChange, isLimited, num, ...rest } = props;

  return (
    <>
      <StaticTextArea
        style={_style}
        autoComplete="off"
        onChange={_onChange}
        {...rest}
      />
      {isLimited && (
        <CountNum>
          <span>{props.value ? props.value.length : 0}자</span> /{" "}
          {num ? num : 1000}자
        </CountNum>
      )}
    </>
  );
};

export default GrayRadiusTextArea;
