import { useContext, useState } from "react";
import * as P from "../../components/styled/webApp";
import { GlobalContext } from "../../App";
import { useTenantType } from "../facility/commons/func";
import { fetchClient } from "../../lib/api/axios";
import { useQuery } from "@tanstack/react-query";
import TenantPointListItem from "./_view/tenantItem";
import { BaseResponse } from "../../lib/api/queries/commons/types";
import { ITenant } from "../../lib/types/Imodels";
import { tenant_point_list_key } from "../../lib/api/queries/keys";
import { company_search } from "../../lib/api/queries/urls";
import SkeletonTable from "../../components/Skeleton/table";
import styled from "@emotion/styled";
import { Flex } from "@chakra-ui/react";
import GrayColorSelect from "../../components/select";
import GrayRadiusWithIcon from "../../components/Input/search";
import QuestionMarkHover from "../../components/hover/question";
import GrayShadowSelect from "../../components/select/shadow";
import { usePagination } from "@ajna/pagination";
import PaginationUI from "../../components/pagination";

const PointListTable = styled(P.TableHeaderWrapper)<{ length: number }>`
  > div {
    width: ${({ length }) => (length > 1 ? "20%" : "25%")};
  }
`;

export default function PointHistoryPage() {
  const { buildInfo } = useContext(GlobalContext);
  const tenant_type = useTenantType(buildInfo);

  const [status, setStatus] = useState("");
  const [dong, setDong] = useState("");
  const [search, setSearch] = useState({ id: "search_name", value: "" });

  const [allPage, setAllPage] = useState<number>(0);
  // const [total, setTotal] = useState<number>(0);
  const { currentPage, setCurrentPage } = usePagination({
    initialState: { currentPage: 1 },
  });

  const { data, refetch } = useQuery([tenant_point_list_key, currentPage, status, dong], () =>
    fetchClient
      .get<BaseResponse<ITenant[]>>(company_search, {
        params: {
          limit: 10,
          page: currentPage,
          is_all: true,
          room_type: status,
          dong,
          [search.id]: search.value,
        },
      })
      .then((res) => {
        setAllPage(res.data.total_page_num || 1);
        return res.data.data;
      }),
  );

  const onClickRefetch = () => {
    currentPage === 1 ? refetch() : setCurrentPage(1);
  };

  return (
    <P.MainContainer style={{ paddingBottom: "1.75rem" }}>
      <P.FliterBox>
        <Flex>
          <GrayShadowSelect value={search.id} _onChange={(e) => setSearch({ id: e.target.value, value: "" })}>
            <option value="search_name">입주사명</option>
            <option value="search_ho">호실번호</option>
          </GrayShadowSelect>
          <GrayRadiusWithIcon
            _style={{ minWidth: "17.5rem" }}
            value={search.value}
            placeholder={`검색할 ${search.id === "search_name" ? "입주사명을" : "호실번호를"}입력하세요.`}
            _onChange={(e) => setSearch({ id: search.id, value: e.target.value })}
            _onKeyUp={(e) => e.key === "Enter" && onClickRefetch()}
            _onClick={() => onClickRefetch()}
          />
        </Flex>
        <Flex pt="1rem">
          {tenant_type.length > 1 && (
            <GrayColorSelect
              value={status}
              _style={{ textAlign: "left" }}
              _onChange={(e) => {
                if (currentPage !== 1) setCurrentPage(1);
                setStatus(e.target.value);
              }}
            >
              <option value="" disabled>
                입주유형
              </option>
              {tenant_type.map((el) => (
                <option key={el.id} value={el.label}>
                  {el.value}
                </option>
              ))}
            </GrayColorSelect>
          )}
          <GrayColorSelect
            value={dong}
            _style={{ textAlign: "left" }}
            _onChange={(e) => {
              if (currentPage !== 1) setCurrentPage(1);
              setDong(e.target.value);
            }}
          >
            <option value="" disabled>
              동
            </option>
            {buildInfo?.dong_floor.map((el) => (
              <option key={el._id} value={el.dong}>
                {el.dong}동
              </option>
            ))}
          </GrayColorSelect>
        </Flex>
      </P.FliterBox>
      <P.TableWrapper>
        <PointListTable length={tenant_type.length}>
          <div>입주사명</div>
          {tenant_type.length > 1 && <div>입주유형</div>}
          <div>동/호수</div>
          <div>잔여포인트</div>
          <Flex alignItems="center">
            입주상태
            <QuestionMarkHover
              isHide={true}
              styled={{ width: "30rem", right: "-7rem" }}
              after="25%"
              description="서비스 가입상태와 별개로 등록된 입주사 현황을 기준으로
                표시합니다."
            />
          </Flex>
        </PointListTable>
        {!data ? (
          <SkeletonTable />
        ) : (
          <>
            {data
              .sort((a) => (a.is_activated ? -1 : 1))
              .map((el) => (
                <TenantPointListItem key={String(el._id)} el={el} />
              ))}
          </>
        )}
      </P.TableWrapper>
      <PaginationUI
        page={currentPage}
        setPage={setCurrentPage}
        onChangeFunc={(page) => setCurrentPage(page)}
        allPage={allPage}
      />
    </P.MainContainer>
  );
}
