import styled from "@emotion/styled";
import * as C from "../../commons/styled";
import { Flex } from "@chakra-ui/react";
import { ChangeEvent, Dispatch, FC, PropsWithChildren, SetStateAction, useRef, useState } from "react";
import { checkFileValidation } from "../../../../lib/utils/format";
import SkeletonImage from "../../../../components/Skeleton/images";
import { DeleteIcon } from "../../../../components/styled";
import { useUploadFile } from "../../../../lib/api/queries/commons";
import { ShadowBox } from "../../../../components/styled/webApp";
import { toast } from "../../../../lib/utils/toast";

const UploadDesc = styled.p`
  font-size: 0.875rem;
  padding-top: 8px;
`;

export interface IDefaultImage extends PropsWithChildren {
  fileUrl: string;
  setFileUrl: Dispatch<SetStateAction<string>>;
  auth: string;
}

const DetailImage: FC<IDefaultImage> = ({ children, ...props }) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(true); //이미지 로딩 대기

  const onChangeImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const isVaild = checkFileValidation(file);
    if (!isVaild) return toast.checkFileValidate();

    const url = await useUploadFile([file]);
    props.setFileUrl(url.data.data[0]);

    e.target.value = "";
  };

  return (
    <>
      <ShadowBox>
        <div style={{ paddingBottom: 0 }}>
          <h4>대표 사진을 추가해주세요.</h4>
          <Flex style={{ fontSize: "0.875rem" }}>
            {props.fileUrl ? (
              <Flex>
                <C.RepresentBox>
                  {loading && <SkeletonImage />}
                  {props.auth === "edit" ? (
                    <>
                      <C.RepreImage
                        isLoading={loading}
                        alt="대표 사진"
                        src={props.fileUrl}
                        onLoad={() => setLoading(false)}
                      />
                      <DeleteIcon onClick={() => props.setFileUrl("")} />
                    </>
                  ) : (
                    <a target="_blank" rel="noreferrer" href={props.fileUrl}>
                      <C.RepreImage
                        isLoading={loading}
                        alt="대표 사진"
                        src={props.fileUrl}
                        onLoad={() => setLoading(false)}
                      />
                    </a>
                  )}
                  <C.FirstMark>대표</C.FirstMark>
                </C.RepresentBox>
              </Flex>
            ) : (
              <C.UploadBox onClick={() => fileRef.current?.click()}>
                <span>+</span>
                <p>사진추가</p>
                <input type="file" ref={fileRef} multiple={false} onChange={onChangeImage} />
              </C.UploadBox>
            )}
          </Flex>
          <UploadDesc>*한 장당 최대 20MB</UploadDesc>
        </div>
      </ShadowBox>
      {children}
    </>
  );
};

export default DetailImage;
