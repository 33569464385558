import { Button, Flex, Modal, ModalContent, ModalOverlay, Text, useDisclosure, useToast } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import * as C from "../../../../components/styled";
import * as R from "../../commons/styled";
import { useNavigate, useParams } from "react-router-dom";
import { BaseMutation } from "../../../../lib/api/queries/commons/types";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { throttle, uniqueId } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
// import { limitContent } from "../../../../lib/utils/format"; //메모 보류
// import { MoreContentInputs } from "../../../../components/Input/contents";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import CancleModal from "../../../../components/modal/CancelModal";
import { useMutation } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { create_manage_fee, update_manage_fee } from "../../../../lib/api/queries/urls";
import { ICloumnObj, IRentalList, ISavedManagement, ITenantFeeDetail } from "../../../../lib/types/Imodels";
import { MemoizedItem } from "./TenantMaintenanceItem";
import { AxiosError } from "axios";
import { unAuthUser, warning } from "../../../../lib/theme/toast";
import { IFetchMenagementList } from "../../commons/types";
import { useDeleteLetter } from "../../commons/queries";
import { colors } from "../../../../components/styled/common";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import CalculateFeeInModal from "../calculate";

type IUpdateManage = {
  description?: string;
  desired_date: string;
  management_fee_details: ITenantFeeDetail[];
  management_fee_id?: string;
};

interface IManagementBill extends BaseMutation<IRentalList> {
  room: IFetchMenagementList | undefined;
  saved: { key: string; value: ISavedManagement[] } | undefined;
}

export interface IManagementCloumn {
  key: string;
  calculation_method: "by_room" | "by_area" | "by_use" | "direct_input_with_save" | "direct_input_with_no_save";
}

export default function CreateManagementBill(props: IManagementBill) {
  const toast = useToast();
  const navigate = useNavigate();
  const { billId } = useParams();
  const { mutation: mutate } = useDeleteLetter(navigate);

  const [month, setMonth] = useState(new Date());
  // const [centents, setCentents] = useState("");
  const [details, setDetails] = useState<ITenantFeeDetail[]>();
  const [items, setItems] = useState<ICloumnObj[]>();
  const [calculate, setCalculate] = useState<IManagementCloumn>();
  const [position, setPosition] = useState(0);
  const boxRef = useRef<HTMLDivElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure(); //납부 고지서 등록
  const { isOpen: isBack, onOpen: goBack, onClose: offBack } = useDisclosure(); //되돌아가기
  const { isOpen: isDel, onOpen: onDel, onClose: offDel } = useDisclosure(); // 삭제
  const { isOpen: isMethod, onOpen: onMethod, onClose: offMethod } = useDisclosure(); //납부 고지서 등록

  useEffect(() => {
    if (props.data) {
      setDetails(props.data.management_fee_details);
      const forEach: ICloumnObj[] = [];
      props.data.management_fee_details[0].items.forEach((el) => {
        if (el.key === "납기내") return;
        forEach.push({
          key: el.key,
          value: "",
          calculation_method: el.calculation_method,
        });
      });
      setItems(forEach);
      props.data?.date && setMonth(new Date(props.data?.date));
    } else {
      if (!props.room) return;
      const newArr = props.room.data2.map((el) => ({
        key: el.key,
        value: "0",
        calculation_method: el.calculation_method,
      }));
      setItems(newArr);

      const result = props.room.data1.map((el) => {
        el = { ...el, items: newArr };
        if (!props.saved || !props.saved.key) return el; //저장 관리비 없으면 saved = [] => key도 없음

        //세팅된 items 배열의 관리비 칼럼과 저장된 관리비 칼럼의 순서 확인 후 저장된 관리비 값 넣기
        const idx = el.items.findIndex((item) => item.key === props.saved?.key);
        const room_idx = props.saved.value.findIndex((item) => item.room_id === el.room_id);
        if (idx === -1 || room_idx === -1) return el;
        // console.log(newArr[idx], props.saved.value[room_idx]);
        const items: ICloumnObj[] = JSON.parse(JSON.stringify(newArr));
        items[idx].value = props.saved.value[room_idx].value;
        el.price = items.reduce((cur, acc) => cur + Number.parseFloat(acc.value), 0);
        return { ...el, items: items };
      });
      setDetails(result);
    }
  }, [props.data, props.room, props.saved]);

  const handleScroll = useMemo(
    () =>
      throttle(() => {
        const wrapper = boxRef.current;
        if (!wrapper) return;
        if (wrapper.scrollTop >= 275) {
          setPosition(wrapper.scrollTop);
        } else {
          setPosition(0);
        }
      }, 300),
    [],
  );

  useEffect(() => {
    const wrapper = boxRef.current;
    if (!wrapper) return;
    wrapper?.addEventListener("scroll", () => handleScroll());

    return () => {
      wrapper?.removeEventListener("scroll", () => handleScroll());
    };
  }, []);

  const mutation = useMutation((newObj: IUpdateManage) =>
    fetchClient
      .post<IFetchMenagementList>(props.isEdit ? update_manage_fee : create_manage_fee, newObj)
      .then(() => {
        alert(
          `관리비 납부 고지서가 정상적으로 ${props.isEdit ? "수정" : "등록"}되었습니다. 확인을 누르면 ${
            props.isEdit ? "수정된 관리비" : "관리비 현황 조회"
          } 페이지로 이동합니다.`,
        );
        sessionStorage.clear();
        navigate(props.isEdit ? `/maintenance-fee/${billId}` : "/maintenance-fee");
      })
      .catch((err: AxiosError) => {
        err.response?.status === 401 && toast({ ...unAuthUser });
        err.response?.status === 400 &&
          toast({
            ...warning,
            title: "알림",
            description: "해당 년월에 이미 관리비가 등록되었습니다. 납부 일자 확인 후 다시 등록해주세요.",
          });
      }),
  );

  const onUploadDirect = () => {
    if (mutation.isLoading) return;

    let newArr = [];
    newArr = [...(details || [])];
    const getArr = newArr.map((el) => {
      const { price, ...rest } = el;
      const idx = rest.items.findIndex((el) => el.key === "납기내");

      if (idx === -1) {
        //등록시에는 납기내 없으니 price값 넣어주어야
        rest.items.push({
          key: "납기내",
          value: String(price || "0"),
        });
      } else if (props.isEdit) {
        rest.items[idx].value = String(
          price === undefined
            ? Number(rest.items[idx].value || 0) //price 없다 - 수정 X === 원래 납기내 데이터
            : price,
        );
      } else {
        rest.items[idx].value = String(price || 0);
        //납기내있으면 총 합값 넣기
      }
      return rest;
    });

    const newObj: IUpdateManage = {
      // description: centents,
      desired_date: `${month.getFullYear()}-${String(month.getMonth() + 1).padStart(2, "0")}-01`,
      management_fee_details: getArr,
    };
    if (props.isEdit) newObj.management_fee_id = billId;

    console.log("api result ====>", newObj);
    mutation.mutate(newObj);
  };

  const onDeleteBill = () => {
    if (mutate.isLoading || !billId) return;
    mutate.mutate(billId);
  };

  const onChangeCharge = useCallback((el: ITenantFeeDetail, newItem: ICloumnObj[]) => {
    // const price = newItem
    //   .map((el) => el.value)
    //   .reduce((cur, acc) => Number(cur || 0) + Number(acc || 0), 0);
    // console.log(
    //   data?.map((ele) =>
    //     ele.room_id === el.room_id ? { ...el, items: newItem, price } : ele
    //   )
    // );
    el.items = newItem;
    // const price = newItem
    //   .map((el) => (el.key === "납기내" ? "" : el.value))
    //   .reduce((cur, acc) => Number(cur || 0) + Number(acc || 0), 0);
    // if(el.items && el.items.find((el) => el.key === '납기내')) {
    //   el.items.find((el) => el.key === '납기내').value = String(price || 0);
    // }
    el.price = newItem
      .map((el) => (el.key === "납기내" ? "" : el.value))
      .reduce((cur, acc) => cur + Math.ceil(Number.parseFloat(acc) || 0), 0);
    // setItems(newItem);
    // console.log(newItem.map((el) => el.vlaue))
    // setTotal(newItem)
    // console.log(el);
  }, []);

  const modal = {
    data: details || [],
    onClose: offMethod,
    isOpen: isMethod,
    onChangeCharge,
    isEdit: props.isEdit,
  };

  return (
    <>
      <R.RentWarpper auth="edit" ref={boxRef}>
        <Text variant="static">
          <C.LeftIcons
            onClick={() => {
              sessionStorage.clear();
              goBack();
            }}
          />
          관리비 납부고지서 {props.isEdit ? "수정" : "생성"}
        </Text>
        <ConfirmModal
          close="확인"
          title="되돌아가기"
          onClose={offBack}
          isOpen={isBack}
          onClickCancle={offBack}
          onClickSave={() => navigate(-1)}
          blockScrollOnMount={false}
        >
          <Text>지금까지 작성하신 내용은 저장되지 않습니다.</Text>
          <Text>이전 페이지로 이동할까요?</Text>
        </ConfirmModal>
        <R.CreatebillInfo>
          <li>납부 요청 보내기까지 완료해야 입주자에게 납부 고지가 됩니다.</li>
        </R.CreatebillInfo>
        <C.NewPostBox style={{ borderTop: "1px solid #d1d5db" }}>
          <C.NewPost>
            <C.PostTitle>부과년월</C.PostTitle>
            <C.DateWithIconBox style={{ width: "180px" }}>
              <DatePicker
                locale={ko}
                selected={month}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                onChange={(date: Date) => setMonth(date)}
                customInput={
                  <R.MonthInput>
                    <Text>{moment(month).format("YYYY년 MM월")}</Text>
                    <FiChevronDown />
                  </R.MonthInput>
                }
              />
            </C.DateWithIconBox>
          </C.NewPost>
        </C.NewPostBox>
        <R.BillContainer>
          <Text fontWeight="500">관리비</Text>
        </R.BillContainer>
        <div>
          <R.BillTable>
            <R.FixedHead p={position}>
              <Flex>
                <R.BillStickyBox
                  w={true}
                  style={{
                    borderTop: `1px solid ${colors.gray4}`,
                    borderBottomColor: colors.gray4,
                  }}
                >
                  <R.TrangleSquare w="8%">
                    <span />
                  </R.TrangleSquare>
                  <R.HeaderSquare w="25%">호실</R.HeaderSquare>
                  <R.HeaderSquare w="25%">입주사명</R.HeaderSquare>
                  <R.HeaderSquare w="22%">사업자 등록번호</R.HeaderSquare>
                  <R.CostSquare w="20%">총 납부금액</R.CostSquare>
                </R.BillStickyBox>
                <R.LetterDetails
                  style={{
                    borderTop: `1px solid ${colors.gray4}`,
                    borderBottomColor: colors.gray4,
                  }}
                >
                  {items?.map((el) => (
                    <R.ManagementColumn key={el.key} w={el.calculation_method?.includes("by")}>
                      <p>{el.key}</p>
                      {el.calculation_method?.includes("by") && (
                        <R.CalcIconBox
                          onClick={() => {
                            // if (el.calculation_method)
                            setCalculate({
                              key: el.key,
                              calculation_method: el.calculation_method || "by_room",
                            });
                            onMethod();
                          }}
                        >
                          <FiPlus />
                        </R.CalcIconBox>
                      )}
                    </R.ManagementColumn>
                  ))}
                </R.LetterDetails>
              </Flex>
            </R.FixedHead>
            {details?.map((el, index) => (
              <MemoizedItem
                key={uniqueId()}
                el={el}
                method={items || []}
                onChangeCharge={onChangeCharge}
                idx={index + 1}
              />
            ))}
          </R.BillTable>
        </div>
      </R.RentWarpper>
      {calculate && isMethod && (
        // <>
        //   {calculate.calculation_method === "by_use" ? (
        //     <UsageInModal calculate={calculate} {...modal} />
        //   ) : calculate.calculation_method === "by_room" ? (
        //     <RoomInModal calculate={calculate} {...modal} />
        //   ) : (
        //     <AreaInModal calculate={calculate} {...modal} />
        //   )}
        // </>
        <CalculateFeeInModal calculate={calculate} {...modal} />
      )}
      <CancleModal isOpen={isDel} onClose={offDel} onClickCancel={offDel} onClickSave={onDeleteBill}>
        <Text>해당 납부고지서를 삭제할까요?</Text>
        <Text>삭제된 데이터는 복구할 수 없습니다.</Text>
      </CancleModal>
      <R.ActionBox>
        {props.isEdit ? (
          <Button variant="delete" m="0" onClick={onDel}>
            삭제
          </Button> //수정일때는 항상 보여지고 활성화해야되니까 isEdit이랑 조건 같이
        ) : (
          <div />
        )}
        <Button variant="bgBlue" onClick={onOpen}>
          {props.isEdit ? "수정" : "저장"}하기
        </Button>
        {mutation.isLoading ? (
          <Modal variant="contents" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent position="relative">
              <C.CustomSpin thickness="4px" speed="0.65s" size="xl" />
            </ModalContent>
          </Modal>
        ) : (
          <ConfirmModal
            close={props.isEdit ? "수정하기" : "저장하기"}
            isOpen={isOpen}
            onClose={onClose}
            onClickCancle={onClose}
            onClickSave={onUploadDirect}
            title={props.isEdit ? "경고" : "납부고지서 저장하기"}
          >
            {props.isEdit ? (
              <>
                <Text>
                  이미 납부고지가 된 고지서를 수정하는 경우에는 기존 납부고지서가 모두 삭제되어 입주자 앱에서도 확인이
                  불가합니다.
                </Text>
                <Text>납부 고지서 수정 후 보내기까지 완료해주세요.</Text>
              </>
            ) : (
              <>
                <Text>관리비 항목을 모두 확인하셨나요?</Text>
                <Text>관리비 등록 후 납부 요청 보내기까지 완료하셔야 입주자에게 납부 고지가 됩니다.</Text>
              </>
            )}
          </ConfirmModal>
        )}
      </R.ActionBox>
    </>
  );
}
