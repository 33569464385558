import CompactHeader from "../../components/Main/CompactHeader";
import { Button, Text, useDisclosure } from "@chakra-ui/react";
import FullPageSpinner from "../../components/Spinner";
import NotFound from "../not-found";
import * as C from "../../components/styled/index";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import RentalListItem from "./_view/rentalItem";
import { GlobalContext } from "../../App";
import PreCheckModal from "../maintenance-fee/component/precheckModal";
import { rental_list } from "../../lib/api/queries/urls";
import { useQuery } from "@tanstack/react-query";
import { rental_list_key } from "../../lib/api/queries/keys";
import { IRentalList } from "../../lib/types/Imodels";
import { BaseResponse } from "../../lib/api/queries/commons/types";
import { fetchClient } from "../../lib/api/axios";
import { TableWrapBox } from "../maintenance-fee/commons/styled";
import useUserAuth from "../../components/hooks/useUserAuth";
import GetAuthorized from "../../components/Authrized";
import { useAccountList } from "../maintenance-fee/commons/queries";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { styled_btn } from "../../lib/theme/common";

export default function RentFeeListPage() {
  const navigate = useNavigate();
  const { buildInfo } = useContext(GlobalContext);
  const auth = useUserAuth("rent_fee");
  const hasFunc = buildInfo?.services.RENT_FEE?.enabled;
  const [year, setYear] = useState(new Date());
  const { data: bank } = useAccountList();
  const { isOpen, onOpen, onClose } = useDisclosure(); //업로드 전 계좌 등록 모달
  const isExist = !bank?.length || !buildInfo?.payment_date;

  const { data } = useQuery(
    [rental_list_key, moment(year).format("YYYY-01-01")],
    () =>
      fetchClient
        .get<BaseResponse<IRentalList[]>>(rental_list, {
          params: { date: moment(year).format("YYYY-01-01") },
        })
        .then((res) => res.data.data),
    {
      enabled: auth === "edit" || auth === "read",
    },
  );

  if (hasFunc === undefined || !auth) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;
  if (auth === "no_auth") return <GetAuthorized />;
  return (
    <CompactHeader title="임대료">
      <div className="flex w-full justify-between py-5">
        <C.DateWithIconBox>
          <DatePicker
            locale={ko}
            selected={year}
            dateFormat="MM/yyyy"
            showYearPicker
            onChange={(date: Date) => setYear(date)}
            customInput={
              <C.InnerTextBox>
                <Text>{moment.utc(year).format("YYYY년")}</Text>
                <FiChevronDown />
              </C.InnerTextBox>
            }
          />
        </C.DateWithIconBox>
        {auth === "edit" && (
          <>
            <Button
              variant="bgBlue"
              style={{ ...styled_btn }}
              onClick={() => (isExist ? onOpen() : navigate("/rent-fee/create"))}
            >
              임대료 고지서 생성
            </Button>
            <PreCheckModal isOpen={isOpen} onClose={onClose} onClick={() => navigate("/account/list")} />
          </>
        )}
      </div>
      <C.ListTableBox>
        <C.ListTableHeader w="20%">
          <TableWrapBox style={{ width: "80%" }}>
            <div>부과년월</div>
            <div>총 부과금액</div>
            <div>최종편집자</div>
            <div>업데이트일시</div>
          </TableWrapBox>
          <div>고지 상태</div>
        </C.ListTableHeader>
        {/* <RentalListItem /> */}
        {data?.map((el) => (
          <RentalListItem key={String(el._id)} el={el} auth={auth} type={true} path={`/rent-fee/${el._id}`} />
        ))}
      </C.ListTableBox>
    </CompactHeader>
  );
}
