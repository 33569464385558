import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Dispatch, SetStateAction } from "react";
import useUserAuth from "../../../components/hooks/useUserAuth";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import {
  company_list_key,
  empty_room_list_key,
  room_list_or_detail,
  tenant_detail_key,
} from "../../../lib/api/queries/keys";
import {
  company_list,
  company_search,
  empty_room_list,
  room_owner_update,
  send_message,
  send_message_to_owner,
  tenant_detail,
  upload_images,
} from "../../../lib/api/queries/urls";
import { IEmptyRoom, IRoom, ITenant } from "../../../lib/types/Imodels";
import { IOwner } from "./type";

type filterType = {
  types: string;
  dong: string;
  floor: string;
  status: string;
  ho: string;
  account: string;
};

export const fetchRooms = (
  currentPage: number,
  setTotal: Dispatch<SetStateAction<number>>,
  setAllpage: Dispatch<SetStateAction<number>>,
  select: filterType
) => {
  const auth = useUserAuth("tenant");
  return useQuery(
    [room_list_or_detail, "list", currentPage],
    () =>
      fetchClient
        .get<BaseResponse<IRoom[]>>(company_list, {
          params: {
            is_tenant: false,
            limit: 10,
            page: currentPage,
            room_type: select.types === "" ? "" : [select.types],
            dong: select.dong,
            floor: select.floor,
            account: select.account,
            is_empty: select.status === "" ? "" : select.status,
            search_ho: select.ho,
          },
        })
        .then((res) => {
          setTotal(res.data.total_num);
          setAllpage(res.data.total_page_num || 0);
          return res.data.data;
        }),
    {
      keepPreviousData: true,
      enabled: auth === "edit" || auth === "read",
    }
  );
};

export const onSendSMS = (id: string[]) =>
  fetchClient.post(send_message, {
    tenant_ids: id,
  });

export const useResults = (
  isReset: boolean,
  searchParams: URLSearchParams,
  id: string,
  currentPage: number,
  setTotal: Dispatch<SetStateAction<number>>,
  setAllPage: Dispatch<SetStateAction<number>>
) => {
  const room = searchParams.get("room_type");
  const sort = searchParams.get("sort") || "updated";

  return useQuery(
    [company_list_key, currentPage, sort, isReset],
    () =>
      fetchClient
        .get<BaseResponse<ITenant[]>>(company_search, {
          params: {
            limit: 10,
            page: currentPage,
            room_type: room || "",
            officener_join_status:
              searchParams.getAll("status").length === 0
                ? ""
                : searchParams.getAll("status"),
            dong: searchParams.get("dong") || "",
            sorting: sort === null ? "updated" : sort, // "dong_floor", //updated
            [id]:
              id === "search_name"
                ? searchParams.get("search_name") || ""
                : id === "search_ho"
                ? searchParams.get("search_ho") || ""
                : searchParams.get("search_phone_number") || "",
          },
        })
        .then((res) => {
          setAllPage(res.data.total_page_num || 0);
          setTotal(res.data.total_num);
          return res.data.data;
        }),
    {
      // enabled: false,
      keepPreviousData: true,
    }
  );
};

export const useEmptyRoomList = (building_id: string, enabled: boolean) => {
  return useQuery(
    [empty_room_list_key],
    () =>
      fetchClient
        .post<BaseResponse<IEmptyRoom[]>>(empty_room_list, {
          building_id,
          // room_type: "residential",
        })
        .then((res) => res.data.data),
    { enabled }
  );
};

//파일 다운로드용 입주자 조회
export const useFetchAllList = (
  isLoad: boolean,
  searchParams: URLSearchParams,
  sort: string,
  id: string
) => {
  const room = searchParams.get("room_type");
  return useQuery(
    ["load_all_company"],
    () =>
      fetchClient
        .get<BaseResponse<IRoom[]>>(company_list, {
          params: {
            is_tenant: true,
            room_type: room === null ? undefined : [room],
            officener_join_status:
              searchParams.getAll("join_status").length === 0
                ? ""
                : searchParams.getAll("join_status"),
            dong: searchParams.get("dong") || "",
            sorting:
              searchParams.get("sort") === null
                ? sort
                  ? "dong_floor"
                  : "updated"
                : searchParams.get("sort"), // "dong_floor", //updated
            [id]:
              id === "search_name"
                ? searchParams.get("search_name") || ""
                : id === "search_ho"
                ? searchParams.get("search_ho") || ""
                : searchParams.get("search_phone_number") || "",
          },
        })
        .then((res) => res.data.data),
    { enabled: isLoad }
  );
};

//소유자 문자 전송
export const useMessageToOwner = (ids: string[]) => {
  return fetchClient.post<BaseResponse<string>>(send_message_to_owner, {
    room_ids: ids,
  });
};

//소유자 등록, 수정
export const updateOwner = (room_id: string, owner: IOwner) => {
  return fetchClient.post<BaseResponse<IRoom>>(room_owner_update, {
    room_id,
    owner_name: owner.name,
    owner_phone_number: owner.num,
  });
};

export const useTenantDetail = (
  id: string | null,
  isOpen: boolean,
  onErrorResult: (err: AxiosError) => undefined
) => {
  return useQuery(
    [tenant_detail_key, id],
    () =>
      fetchClient
        .post<BaseResponse<ITenant>>(tenant_detail, {
          tenant_id: id,
        })
        .then((res) => res.data.data),
    {
      enabled: isOpen,
      retry: 1,
      onError: (err: AxiosError) => onErrorResult(err),
    }
  );
};

export const uploadAllFiles = async (
  data: { directory: string | File; name: string }[]
) => {
  const newArr = [...data];

  const formData = new FormData();
  data
    .filter((el) => typeof el.directory !== "string")
    .map((el) => formData.append("files", el.directory));

  try {
    const result = await fetchClient.post<BaseResponse<string[]>>(
      upload_images,
      formData
    );

    const stringArr = result.data.data;
    if (!stringArr || !stringArr.length) throw new Error();

    return newArr.map((el, idx) => ({
      directory: stringArr[idx],
      name: el.name,
    }));
  } catch (err) {
    return undefined;
  }
};
