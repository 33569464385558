import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import * as S from "../../../components/styled";
import { v4 as uuidv4 } from "uuid";
import { useContext, useState } from "react";
import MoreContentInputs from "../../../components/Input/contents";
import * as C from "../../../components/styled/index";
import * as F from "../commons/styled";
import CancleModal from "../../../components/modal/CancelModal";
import { Payment_date } from "../../../lib/utils/dataFormat";
import { IBankType } from "./types";
import { useEffect } from "react";
import { update_payment } from "../../../lib/api/queries/urls";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { success, warning } from "../../../lib/theme/toast";
import { GlobalContext } from "../../../App";
import { queryClient } from "../../..";
import { building_key } from "../../../lib/api/queries/keys";
import MainHeaderPart from "../../../components/Main/HeaderPart";
import useAccessBank from "./access";
import { useBankAccount } from "../commons/queries";

export default function AdministrationBankUI(props: IBankType) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure(); //계좌 삭제 모달
  const { isOpen: isDate, onOpen: onDate, onClose: closeDate } = useDisclosure(); //납부일 등록 모달
  const { data: bank } = useBankAccount(props.isOpen);
  const { buildInfo } = useContext(GlobalContext);
  const [date, setDate] = useState<number>();

  const auth = useAccessBank(["management_fee", "rent_fee"]);
  const isEdit = auth === "edit";

  const onUpdatePayment = () => {
    if (date === buildInfo?.payment_date)
      return toast({
        ...warning,
        title: "알림",
        description: "관리비 납부일이 현재 설정된 날짜와 동일합니다.",
      });

    fetchClient
      .post<BaseResponse<string>>(update_payment, {
        payment_date: date,
      })
      .then(() => {
        toast({
          ...success,
          title: "관리비 납부일 수정 완료",
          description: "관리비 납부일이 수정되었습니다.",
        });
        closeDate();
        queryClient.invalidateQueries([building_key, "context_building"]);
      })
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    if (isDate) {
      //기존 등록된 납부일 보여주어야함
      if (buildInfo?.payment_date === undefined) return;
      setDate(buildInfo.payment_date);
    } else {
      setDate(undefined);
    }
  }, [isDate]);

  return (
    // <C.Wrapper>
    <div className="flex min-h-full w-full flex-col bg-white p-0 md:px-6">
      <p className="mt-5">
        관리비를 수납하는 은행의 계좌 관리 페이지입니다. 입주사는 앱을 통해 아래 계좌를 확인할 수 있습니다.
      </p>
      {isEdit && (
        <Box pt="20px">
          <Button m="0" variant="bgBlue" onClick={props.onOpen}>
            납부계좌 등록
          </Button>
          <Button variant="blueLine" onClick={onDate}>
            납부일 등록
          </Button>
        </Box>
      )}
      {/* </MainHeaderPart> */}
      <C.WrapperBody>
        <C.TableTopBox>
          <C.TableText>
            총 <C.TableCount>{props.data?.length || 0}건</C.TableCount>의 등록내역이 조회되었습니다.
          </C.TableText>
          {auth === "edit" && (
            <>
              <Button onClick={onOpen} variant="delete">
                삭제
              </Button>
              <CancleModal
                onClose={onClose}
                isOpen={isOpen}
                onClickCancel={onClose}
                onClickSave={() => {
                  props.onDeleteAccount();
                  onClose();
                }}
              >
                <Text>체크하신 계좌 정보를 삭제할까요?</Text>
                <Text>삭제된 데이터는 복구할 수 없습니다.</Text>
              </CancleModal>
            </>
          )}
        </C.TableTopBox>
        <C.ListTableBox>
          <C.ListTableHeader w="25%">
            <C.ListCheckBox style={{ width: "5%" }}>
              <Checkbox
                variant="default"
                isChecked={props.checkedArr.length === props.data?.length ? true : false}
                onChange={(e) => props.onCheckedAll(e.target.checked)}
              />
            </C.ListCheckBox>
            <div>NO</div>
            <div>은행</div>
            <div>계좌번호</div>
            <div>예금주명</div>
          </C.ListTableHeader>
          {props.data?.map((el, idx) => (
            <>
              <S.WholeTableList w="25%">
                <S.ListCheckBox style={{ width: "5%" }}>
                  <Checkbox
                    variant="default"
                    isChecked={props.checkedArr.includes(String(el._id)) ? true : false}
                    onChange={(e) => props.onChangeChecked(e.currentTarget.checked, String(el._id))}
                  />
                </S.ListCheckBox>
                <div>{idx + 1}</div>
                <div>{el?.bank_id?.name}</div>
                <div>{el?.account_number}</div>
                <div>{el?.account_name}</div>
              </S.WholeTableList>
            </>
          ))}
        </C.ListTableBox>
      </C.WrapperBody>
      <Modal variant="contents" isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <C.TableContent>
          <ModalHeader>납부계좌 등록</ModalHeader>
          <ModalBody p="0 28px">
            <Table variant="contents">
              <Tbody>
                <Tr>
                  <F.TableTitle>은행</F.TableTitle>
                  <Td>
                    <Select
                      w="180px"
                      value={props.bankId}
                      variant="fitContent"
                      onChange={(e) => props.setBankId(e.target.value)}
                    >
                      <option value="">은행 선택</option>
                      {bank?.map((el) => (
                        <option key={String(el._id)} value={String(el._id)}>
                          {el.name}
                        </option>
                      ))}
                    </Select>
                  </Td>
                </Tr>
                <Tr>
                  <F.TableTitle>계좌번호</F.TableTitle>
                  <Td>
                    <MoreContentInputs
                      placeholder="'-'없이 숫자만 입력"
                      style={{ width: "368px", marginRight: "0" }}
                      onChange={(e) => props.setAccount(e.target.value)}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <F.TableTitle>예금주명</F.TableTitle>
                  <Td>
                    <MoreContentInputs
                      style={{ marginRight: "0" }}
                      placeholder="예금주명을 확인하여 입력해주세요"
                      marginRight={0}
                      onChange={(e) => props.setOwner(e.target.value)}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => props.onReset()} variant="cancel">
              취소
            </Button>
            <Button onClick={props.onRegisterAccount} variant="bgBlue">
              저장하기
            </Button>
          </ModalFooter>
        </C.TableContent>
      </Modal>
      <Modal variant="contents" isOpen={isDate} onClose={closeDate}>
        <ModalOverlay />
        <C.TableContent>
          <ModalHeader style={{ width: "584px" }}>납부일 등록</ModalHeader>
          <ModalBody p="8px 28px">
            <F.PaymentBox>
              <Box fontWeight={500} w="160px">
                납부일
              </Box>
              <Select w="180px" value={date} variant="fitContent" onChange={(e) => setDate(Number(e.target.value))}>
                <option value="">날짜 선택</option>
                {Payment_date.map((el) => (
                  <option key={uuidv4()} value={el}>
                    {el}일
                  </option>
                ))}
                <option value={32}>말일</option>
              </Select>
            </F.PaymentBox>
          </ModalBody>
          <ModalFooter mt="132px">
            <Box>
              <Button onClick={closeDate} variant="cancel">
                취소
              </Button>
              <Button onClick={onUpdatePayment} variant="bgBlue">
                저장하기
              </Button>
            </Box>
          </ModalFooter>
        </C.TableContent>
      </Modal>
    </div>
  );
}
