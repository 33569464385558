import moment from "moment";
import "./_view/timeline.css";
import "react-calendar-timeline/lib/Timeline.css";
import { SidebarTitle } from "./_view/timeline";
import { Id } from "react-calendar-timeline";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { IRoomPublic, ISchedule } from "../../../lib/types/Imodels";
import MeetingRoomPageUI from "./_view/presenter";
import { useDisclosure, useToast } from "@chakra-ui/react";
import ReservationInfo from "./_view/reservationInfo";
import { public_list } from "../../../lib/api/queries/urls";
import { public_list_key } from "../../../lib/api/queries/keys";
import FullPageSpinner from "../../../components/Spinner";
import useUserAuth from "../../../components/hooks/useUserAuth";
import GetAuthorized from "../../../components/Authrized";
import { fetchDetailReserved } from "../commons/queries";
import { warning } from "../../../lib/theme/toast";

export default function MeetingRoomPage() {
  const toast = useToast();
  const auth = useUserAuth("room_public");
  const [today, setToday] = useState(moment().format("YYYY-MM-DD"));
  const { isOpen, onOpen, onClose } = useDisclosure(); //예약 완료 내역 모달
  const [dongArr, setDongArr] = useState<string[]>([]);
  const [floorArr, setFloorArr] = useState<string[]>([]);
  const checklocation = { dongArr, floorArr, setDongArr, setFloorArr };
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState("");
  const [item, setItem] = useState<ISchedule[]>([]);

  const changeItemList = (data: IRoomPublic[]) => {
    const newArr: ISchedule[] = [];
    data.forEach((el) => {
      if (el.schedule_ids && el.schedule_ids.length > 0) el.schedule_ids.map((schedule) => newArr.push(schedule));
    });
    setItem(newArr);
  };

  const { data, refetch } = useQuery(
    [public_list_key, today],
    () =>
      fetchClient
        .get<BaseResponse<IRoomPublic[]>>(public_list, {
          params: {
            is_only_room_public: false,
            desired_date: moment(today).format("YYYY-MM-DD"),
            dong_list: dongArr.length === 0 ? "" : JSON.stringify(dongArr),
            floor_list: floorArr.length === 0 ? "" : JSON.stringify(floorArr),
            check_status: status,
            search_name: search,
          },
        })
        .then((res) => {
          changeItemList(res.data.data);
          return res.data.data;
        }),
    { enabled: auth === "edit" || auth === "read" },
  );

  const onClickSubmit = () => {
    if (!auth || auth === "no_auth") return;
    refetch();
  };

  const groups = data?.map((el) => ({
    id: String(el._id),
    title: <SidebarTitle el={el} />,
  }));

  const reservation = item.map((res) => ({
    id: String(res._id),
    group: String(res.room_public_id),
    title:
      res.check_status === "admin_rights"
        ? ""
        : `${String(res.tenant_id.xperp_name || "기숙사")}(${String(
            res.tenant_id ? res.tenant_id.name : res.user_id?.name,
          )})`,
    start_time: moment(moment.utc(res.start_date).format("YYYY-MM-DD HH:mm")),
    end_time: moment(moment.utc(res.end_date).format("YYYY-MM-DD HH:mm")),
    className:
      res.check_status === "pending"
        ? "need-approval"
        : res.check_status === "admin_rights"
          ? "disAvailable"
          : "confirm-reservation",
  }));

  const [schedule_detail, setScheduleDetail] = useState<ISchedule>();
  const detailBooked = async (id: Id) => {
    if (reservation[reservation.findIndex((el) => el.id === id)].className === "disAvailable") return;

    try {
      const result = await fetchDetailReserved(String(id));

      setScheduleDetail(result);
      onOpen();
    } catch (err) {
      toast({
        ...warning,
        title: "알림",
        description: "일시적인 오류로 예약 상세 정보를 불러오는데 실패했습니다. 잠시 후 다시 시도해주세요.",
      });
    }
  };

  if (!auth) return <FullPageSpinner />;
  if (auth === "no_auth") return <GetAuthorized />;
  return (
    <>
      <MeetingRoomPageUI
        groups={groups}
        reservation={reservation}
        detailBooked={detailBooked}
        status={status}
        setStatus={setStatus}
        search={search}
        setSearch={setSearch}
        onClickSubmit={onClickSubmit}
        today={today}
        setToday={setToday}
        {...checklocation}
      />
      {schedule_detail && (
        <ReservationInfo auth={auth} title="예약 정보" isOpen={isOpen} onClose={onClose} data={schedule_detail} />
      )}
    </>
  );
}
