import moment from "moment";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

export const TimeFormat = (date: Date | undefined, type?: string) => {
  if (!date) return "";

  if (type === "days") return moment.utc(date).format("YYYY-MM-DD");
  if (type === "sec") return moment.utc(date).format("YYYY-MM-DD HH:mm:ss");
  if (type === "yy") {
    // const indefinite = moment.utc(new Date());
    if (moment.utc(date).diff(moment(), "years") > 10) return ""; //무기한 예약제한 위해서
    return moment.utc(date).format("YY-MM-DD HH:mm");
  }

  return moment.utc(date).format("YYYY-MM-DD HH:mm");
};

export const getTime = (time: Date | undefined) => {
  //등록한 시간만 보여주도록
  if (time === undefined) return;

  const date = String(time).slice(11, -8);
  return `${date}`;
};

export const getDate = (time: Date | undefined) => {
  // 등록한 날짜만 보여주도록
  if (time === undefined) return;
  const date = String(time).slice(0, 10);
  return `${date}`;
};

//작년, 올해, 내년
export const lastYear = moment().add(-1, "year").format("YYYY");
export const Year = moment().format("YYYY");
export const nextYear = moment()
  .add(+1, "year")
  .format("YYYY");

export const fileValidation = (file: File) => {
  if (!file.size) return false;
  if (!file.name.includes("xlsx")) return false;

  return true;
};

export const FileFormat = ["gif", "jpg", "jpeg", "png"];

export const checkFileValidation = (file: File) => {
  if (file.size > 20 * 1024 * 1024) return false;

  const ext = file.type.split("/");
  if (!FileFormat.includes(ext[1])) return false;

  return true;
};

export const fileImageValidate = (files: FileList | null) => {
  if (files === null) return false;

  if (Array.from(files).filter((el) => el.size > 10 * 1024 * 1024).length !== 0)
    return false;

  if (files.length > 5) return false;

  const newArr = Array.from(files).map(
    (el) => el.name.split(".")[el.name.split(".").length - 1]
  );
  if (
    newArr.filter((el) => !FileFormat.includes(el.toLowerCase())).length !== 0
  ) {
    return false;
  }

  return true;
};

//설문, 공지 등 텍스트 제한
export const limitContent = (value: string) => {
  if (value.length > 3000) return false;
  return true;
};

export const limitTitle = (value: string) => {
  if (value.length > 100) return false;
  return true;
};

//받아온 데이터가 이미지 파일인지 확장자로 확인
export const isExtension = (value: string) => {
  const newArr = value.split(".");

  return newArr[newArr.length - 1].toLowerCase();
};

//input 50자 제한
export const onLimitTextNum = (
  e: ChangeEvent<HTMLInputElement>,
  setState:
    | Dispatch<SetStateAction<string>>
    | Dispatch<SetStateAction<string | undefined>>
) => {
  if (e.target.value.length > 50)
    return alert("최대 50자까지 입력 가능합니다.");
  setState(e.target.value);
};

//input 숫자 제한
export const onLimitText = (
  e: ChangeEvent<HTMLInputElement>,
  setState:
    | Dispatch<SetStateAction<string>>
    | Dispatch<SetStateAction<string | undefined>>
) => {
  const value = e.target.value;
  const valid = /^[가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z]+$/g;
  const empty = /^$/;

  if (!valid.test(value) && !empty.test(value)) return;
  if (value.length > 50) return alert("최대 50자까지 입력 가능합니다.");
  setState(e.target.value);
};

//input 숫자만 허용
export const onLimitNum = (
  e: ChangeEvent<HTMLInputElement>,
  setState:
    | Dispatch<SetStateAction<string>>
    | Dispatch<SetStateAction<string | undefined>>
) => {
  // if (!isTrue) return;
  const valid = /^[0-9]+$/;
  const empty = /^$/;
  const str = e.target.value;

  if (!valid.test(str) && !empty.test(str)) return;
  if (str.length > 20) return alert("최대 20자리 이하의 숫자로 입력해주세요.");
  setState(str);
};

//파일 JS 제한
// export const preventJS = (str: string | undefined) => {
//   if (!str) return false;
//   if (str.includes("javascript:")) return false;
//   if (str.includes("<script>")) return false;

//   return true;
// };
export const format_number = (str: string) => {
  // if (!isTrue) return;
  const valid = /^[0-9]+$/;
  const empty = /^$/;

  if (!valid.test(str) && !empty.test(str)) return "false";
  return str.length > 1 ? (str = str.replace(/(^0+)/, "")) : str;
};

export const format_point = (str: string) => {
  let value = str;

  return value.length > 1 && value.includes(".")
    ? value
    : value.length > 1
    ? (value = value.replace(/(^0+)/, ""))
    : value;
};
