import { Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GetAuthorized from "../../../components/Authrized";
import useUserAuth from "../../../components/hooks/useUserAuth";
import FullPageSpinner from "../../../components/Spinner";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { useUploadFile } from "../../../lib/api/queries/commons";
import { complaint_create, complaint_update } from "../../../lib/api/queries/urls";
import { success, warning } from "../../../lib/theme/toast";
import { IReqComplaint } from "../../../lib/types/Imodels";
import NotFound from "../../not-found";
import { ICreatePerson, IEditComplaint, ILocation, INewRequest } from "../commons/types";
import SubmitNewRequestUI from "./presenter";
import { ISelectTenant } from "../../../components/modal/search/Tenant";
import { useFetchCategories } from "../commons/queries";
import { complain_category_list } from "../../../lib/api/queries/keys";
import ResponsiveConfirm from "../../../components/modal/responsive/confirm";
import { GlobalContext } from "../../../App";

interface PropsContextType {
  selected?: ISelectTenant | undefined;
  setSelected?: Dispatch<SetStateAction<ISelectTenant | undefined>>;
  files?: File[] | undefined;
  setFiles?: Dispatch<SetStateAction<File[] | undefined>>;
}

type IPayload = {
  images: string[];
  isEdit: boolean | undefined;
  department_id?: string;
  complaint_id?: string;
};

export const NewRequestContext = createContext<PropsContextType>({});

export default function SubmitNewRequest({ isEdit, data }: IEditComplaint) {
  const { buildInfo } = useContext(GlobalContext);
  const toast = useToast();
  const navigate = useNavigate();
  const { complaintId } = useParams();
  const hasFunc = buildInfo?.services.COMPLAINT?.enabled;
  const auth = useUserAuth("complaint");
  const { data: location } = useFetchCategories("complaint_location_type");
  const { isOpen, onOpen, onClose } = useDisclosure(); //등록 확인

  const [department, setDepartment] = useState("");
  const [category, setCategory] = useState("");
  const [sub, setSub] = useState("");
  const [place, setPlace] = useState<ILocation>({
    dong: "",
    floor: "",
    location: "",
  });
  const [direct, setDirect] = useState("");
  const [selected, setSelected] = useState<ISelectTenant>();
  const [applicant, setApplicant] = useState<ICreatePerson>();
  const [body, setBody] = useState("");
  const [files, setFiles] = useState<File[]>();
  const [images, setImages] = useState<string[]>([]);
  const [categoryList, setCategoryList] = useState([]);
  const [mainCategories, setMainCategories] = useState<string[]>([]); // 메인 카테고리 리스트
  const [subCategories, setSubCategories] = useState<string[]>([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState("");
  const [selectedSubCateogry, setSelectedSubCategory] = useState("");

  useQuery([], () =>
    fetchClient.get(complain_category_list).then((res) => {
      setCategoryList(res.data.data.category);
      const tempCategoryList = res.data.data.category.map((item: { name: string }) => {
        return item.name;
      });
      setMainCategories(tempCategoryList);
    }),
  );

  // 민원 등록 수정
  const mutation = useMutation((newObj: IPayload) => {
    const { isEdit, ...rest } = newObj;
    return fetchClient
      .post<BaseResponse<IReqComplaint>>(isEdit ? complaint_update : complaint_create, {
        complaint_type: category,
        complaint_sub_type: sub,
        dong: place.dong,
        floor: place.floor,
        location: place.location === "direct" ? direct : place.location,
        tenant_id: selected?._id && String(selected?._id),
        filed_user_name: applicant?.name,
        filed_user_phone_number: applicant?.num,
        body,
        complainCategory: {
          mainCategory: selectedMainCategory,
          subCategory: selectedSubCateogry,
        },
        ...rest,
      })
      .then(() => {
        toast({
          ...success,
          title: `민원 ${isEdit ? "수정" : "등록"} 완료`,
          description: `민원이 정상적으로 ${isEdit ? "수정" : "등록"}되었습니다.`,
        });
        if (!isEdit) {
          onClose();
        }
        return navigate("/complaints");
      })
      .catch((err: AxiosError) => {
        if (!isEdit)
          toast({
            ...warning,
            title: `민원 ${isEdit ? "수정" : "등록"} 오류`,
            description:
              err.response?.status === 401
                ? `민원 ${
                    isEdit ? "수정" : "등록"
                  } 권한이 없습니다. 관리자 권한 변경이 필요하다면 최고관리자에게 문의해주세요.`
                : "일시적으로 요청에 실패했습니다. 새로고침 후에도 동일한 오류가 반복될 경우 오피스너 담당자에게 문의해주세요.",
          });
      });
  });

  const onConfirmMutate = () => {
    const sendObj: IPayload = { isEdit, images: images };

    isEdit ? (sendObj.complaint_id = String(complaintId || "")) : (sendObj.department_id = department);

    if (files && files.length !== 0) {
      useUploadFile(files).then((res) =>
        mutation.mutate({
          ...sendObj,
          images: [...(sendObj.images || []), ...res.data.data],
        }),
      );
    } else {
      mutation.mutate(sendObj);
    }
  };

  const onSubmitRequest = () => {
    if (mutation.isLoading) return;

    const failed = () =>
      toast({
        ...warning,
        title: "민원 등록 실패",
        description: "사진 파일과 회사를 제외한 모든 항목은 필수로 입력해주세요.",
      });

    if (!department || !category || (category !== "기타" && sub === "")) return failed();
    if (!body) return failed();
    if (!place.dong || !place.floor || !place.location || (place.location === "direct" && !direct)) return failed();

    isEdit ? onConfirmMutate() : onOpen();
  };

  useEffect(() => {
    if (!isEdit || !data || !location) return;

    const direct = location.filter((el) => el.title === data.location);
    const isDirect = direct.length === 0; // 직접입력

    setDepartment(String(data.department_id || ""));
    setCategory(data.complaint_type);
    setSub(data.complaint_sub_type);
    setPlace({
      dong: data.dong || "",
      floor: data.floor || "",
      location: isDirect ? "direct" : data.location || "",
    });
    if (isDirect) setDirect(data.location || "");
    if (data.tenant_id)
      setSelected({
        _id: data.tenant_id,
        name: `${data.tenant_xperp_name}(${data.tenant_name})`,
      });
    setApplicant({
      name: data.filed_user_name || "",
      num: data.filed_user_phone_number || "",
    });
    setBody(data.body);
    setImages(data.images);
  }, [isEdit, data, location]);

  const props: INewRequest = {
    department,
    setDepartment,
    category,
    setCategory,
    selectedMainCategory,
    setSelectedMainCategory,
    selectedSubCateogry,
    setSelectedSubCategory,
    sub,
    setSub,
    applicant,
    setApplicant,
    place,
    setPlace,
    direct,
    setDirect,
    body,
    setBody,
    onSubmitRequest,
    isEdit,
    setImages,
    images,
    data,
    location,
    mainCategories,
    subCategories,
    setSubCategories,
    categoryList,
  };

  if (!auth || hasFunc === undefined) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;
  if (auth !== "edit") return <GetAuthorized />;
  return (
    <NewRequestContext.Provider value={{ selected, setSelected, files, setFiles }}>
      <SubmitNewRequestUI {...props} />
      {isOpen && (
        <ResponsiveConfirm
          title="민원 등록"
          isOpen={isOpen}
          before="취소"
          next="등록"
          onClose={onClose}
          onAction={onConfirmMutate}
        >
          <>
            <Text>민원을 등록하시겠습니까?</Text>
            <Text>선택한 회사의 최고관리자에게만 해당 민원이 노출됩니다.</Text>
          </>
        </ResponsiveConfirm>
      )}
    </NewRequestContext.Provider>
  );
}
