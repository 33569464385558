import { IHeader } from "..";
import { queryClient } from "../../..";
import { useDisclosure } from "@chakra-ui/react";
import { notification_group_key } from "../../../lib/api/queries/keys";
import { MouseEvent } from "react";
import { IBuilding, IUser } from "../../../lib/types/Imodels";
import UserInfoInDrawer from "../../../pages/control/manager/details/userInfo";
import { usefetchUserDetails } from "../../../pages/control/manager/commons/queries";
import { colors } from "../../styled/common";
import NotificationBox from "./notification";
import { twMerge } from "tailwind-merge";
import { Menu } from "@headlessui/react";
import Icon from "../../../types/solar-icon";

interface LoginType extends IHeader {
  data: IUser | undefined;
  onClickLogout: () => void;
  build: IBuilding | undefined;
  AppToken: string | null;
}

export default function LayoutHeaderUI(props: LoginType) {
  const { isOpen, onOpen, onClose } = useDisclosure(); //관리자 정보 수정 + 비밀번호 modal
  const { isOpen: isAlert, onOpen: onAlert, onClose: offAlert } = useDisclosure(); //알림

  const { data } = usefetchUserDetails(isOpen && props.data?._id ? true : false, String(props.data?._id));

  const onOpenMenu = (e: MouseEvent) => {
    e.stopPropagation();
    if (isAlert) offAlert();
    props.setIsMenu((prev) => !prev);
  };

  const onOpenAlert = () => {
    if (props.isMenu) props.setIsMenu(false);
    queryClient.invalidateQueries([notification_group_key]);
    onAlert();
  };

  return (
    <div className="flex h-[4.5rem] w-full items-center justify-between border-b border-gray-300 bg-white px-6">
      <h1 className="text-3xl text-blue-600">오피스너</h1>
      <div className="flex cursor-pointer items-center gap-3">
        <div onClick={onOpenAlert} className="relative flex cursor-pointer items-center">
          <Icon.BellBing size={24} color="#6B7280" />
          {props.dots && <div className="absolute right-0 top-0 h-1.5 w-1.5 animate-ping rounded-full bg-orange-600" />}
        </div>
        {props.list && isAlert && (
          <NotificationBox data={props.list} isOpen={isAlert} onClose={offAlert} setDots={props.setDots} />
        )}
        <div className="h-5 w-[1px] bg-gray-300" />

        <Menu></Menu>
        <div className="relative z-10 cursor-pointer" onClick={(e) => onOpenMenu(e)}>
          <div
            className={twMerge("flex items-center gap-1 font-medium", props.isMenu ? "text-blue-600" : "text-gray-500")}
          >
            <Icon.UserCircle size={24} color={props.isMenu ? colors.mainBlue : colors.gray4} />
            <p>{props.data?.name}님</p>
            <Icon.AltArrowDown color={props.isMenu ? colors.mainBlue : colors.gray4} />
          </div>
          <div className={twMerge("absolute right-0 top-10 w-40", props.isMenu ? "" : "hidden")}>
            <div className="w-full divide-y rounded-lg border border-gray-200 bg-white">
              <li
                onClick={() =>
                  window.open(
                    "https://www.notion.so/bohome-livable/NEW-4f52610d4d7e4f258364ed4e2a230e0e?pvs=4",
                    "_blank",
                  )
                }
                className="flex items-center gap-2 px-4 py-2 transition-all hover:bg-gray-100"
              >
                <Icon.InfoCircle size={20} color="#2563EB" />
                <span className="text-sm">오피스너 메뉴얼</span>
              </li>
              <li
                onClick={() => onOpen()}
                className="flex items-center gap-2 px-4 py-2 transition-all hover:bg-gray-100"
              >
                <Icon.User size={20} color="#2563EB" />
                <span className="text-sm">내 정보</span>
              </li>
              <li
                onClick={() => props.onClickLogout()}
                className="flex items-center gap-2 px-4 py-2 transition-all hover:bg-gray-100"
              >
                <Icon.Exit size={20} color="#2563EB" />
                <span className="text-sm">로그아웃</span>
              </li>
            </div>
          </div>
        </div>
        {isOpen && data && <UserInfoInDrawer isOpen={isOpen} onClose={onClose} data={data} />}
      </div>
    </div>
  );
}
