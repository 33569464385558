import * as R from "../../commons/styled";
import { IRentalList } from "../../../../lib/types/Imodels";
import MaintenanceItem from "./MaintenanceItem";
import { Flex } from "@chakra-ui/react";

export default function MaintenanceBox({
  data,
  isDirect,
}: {
  data: IRentalList | undefined;
  isDirect: boolean;
}) {
  return (
    <div>
      <R.BillTable style={{ borderTop: "1px solid #6b7280" }}>
        <div>
          <Flex>
            <R.BillStickyBox
              w={isDirect}
              style={{ borderBottomColor: "#6b7280" }}
            >
              <R.TrangleSquare w={isDirect ? "5%" : "10%"}>
                <span />
              </R.TrangleSquare>
              <R.HeaderSquare w={isDirect ? "20%" : "45%"}>호실</R.HeaderSquare>
              {isDirect && (
                <>
                  <R.HeaderSquare w="20%">입주사명</R.HeaderSquare>
                  <R.HeaderSquare w="20%">사업자 등록번호</R.HeaderSquare>
                </>
              )}
              <R.CostSquare w={isDirect ? "20%" : "45%"}>
                총 납부금액
              </R.CostSquare>
              {isDirect && (
                <R.CostSquare w="15%" style={{ borderLeft: "none" }}>
                  납부상태
                </R.CostSquare>
              )}
            </R.BillStickyBox>
            <R.LetterDetails style={{ borderBottom: "1px solid #6b7280" }}>
              {isDirect ? (
                <>
                  {data?.selected_columns_with_calculation_method?.map((el) => (
                    <R.SkySquare key={el.key}>{el.key}</R.SkySquare>
                  ))}
                </>
              ) : (
                <>
                  {/* 파일 등록으로 보여주는 방식에서는 칼럼에 납기내, 납기후 포함된 상태. */}
                  {data?.selected_columns
                    ?.filter((el) => el !== "납기내" && el !== "납기후")
                    .map((el) => (
                      <R.SkySquare key={el}>{el}</R.SkySquare>
                    ))}
                </>
              )}
            </R.LetterDetails>
          </Flex>
        </div>
        {data?.management_fee_details.map((el, idx) => (
          <MaintenanceItem
            key={String(el._id)}
            el={el}
            idx={idx + 1}
            isDirect={isDirect}
          />
        ))}
      </R.BillTable>
    </div>
  );
}
