import { useGetVoteDetailQuery } from "../../../requests/vote";
import { useParams } from "react-router-dom";
import FullPageSpinner from "../../../components/Spinner";
import VoteDetail from "../../../views/vote/VoteDetail";
import VoteCreate from "../../../views/vote/VoteCreate";

export default function VoteDetailPage() {
  const { voteId } = useParams() as { voteId: string };
  const { data, isLoading, isError } = useGetVoteDetailQuery(voteId);

  if (isLoading) return <FullPageSpinner />;
  if (isError) return <></>;

  return (
    <>
      {data.data.status === "진행전" ? (
        <VoteCreate
          voteId={voteId}
          title={data.data.title}
          description={data.data.description}
          agendas={data.data.agendas}
          senderName={data.data.senderName}
          senderContact={data.data.sender_contact_number}
          updatedAt={new Date(data.data.updatedAt)}
        />
      ) : (
        <VoteDetail {...data.data} />
      )}
    </>
  );
}
