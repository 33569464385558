import styled from "@emotion/styled";
import { Box, Flex } from "@chakra-ui/react";
import { ChangeEvent, Dispatch, SetStateAction, memo } from "react";
import { ICollection } from "../../../lib/types/Imodels";
import { colors } from "../../../components/styled/common";
import { IoClose } from "@react-icons/all-files/io5/IoClose";

const CollectBox = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${colors.gray2};
  min-height: 56px;
  padding: 8px 0;
  color: ${colors.gray4};
`;

const SubTitle = styled.div<{ w: string }>`
  font-weight: 500;
  width: ${({ w }) => w};
  color: ${colors.gray5};
  display: flex;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Input = styled.input`
  color: ${colors.gray4};
  margin-right: 8px;
  border-radius: 0;
  border: 1px solid ${colors.gray3};
  min-width: 248px;
  padding: 8px 12px;
  -webkit-padding-after: 9px;
  -ms-padding-after: 9px;
  -moz-padding-after: 9px;
  ::placeholder {
    color: ${colors.gray2};
  }
`;

type keys = "name" | "phone_number" | "description";
interface IContract {
  el: ICollection;
  // _id:string;
  idx: number;
  auth: string | undefined;
  onChangeObj: (el: ICollection, id: keys, value: string) => void;
  data: ICollection[];
  setCollection: Dispatch<SetStateAction<ICollection[]>>;
}

const ContractItem = (props: IContract) => {
  const isMaster = props.auth === "master";

  const onValidNum = (e: ChangeEvent<HTMLInputElement>) => {
    const str = e.target.value;
    if (e.target.id === "phone_number") {
      const empty = /^$/;
      const valid = /^[0-9]+$/;
      const all = /[^0-9]/gi;

      if (!valid.test(str) && !empty.test(str)) {
        alert("연락처는 숫자로만 입력이 가능합니다.");
        props.onChangeObj(props.el, e.target.id, str.replace(all, ""));
        // setObject({ ...object, phone_number: str.replace(all, "") });
        return (e.target.value = str.replace(all, ""));
      }
      props.onChangeObj(props.el, e.target.id, str);
    }
  };

  const onValidateInput = (e: ChangeEvent<HTMLInputElement>) => {
    const str = e.target.value;
    if (str.length > 100) {
      alert(
        `${
          e.target.id === "name" ? "담당자명은" : "안내문구는"
        } 최대 100자까지 입력 가능합니다.`
      );

      if (e.target.id === "name")
        props.onChangeObj(props.el, e.target.id, str.slice(0, -1));
      if (e.target.id === "description")
        props.onChangeObj(props.el, e.target.id, str.slice(0, -1));
      return (e.target.value = str.slice(0, -1));
    }

    if (e.target.id === "name") props.onChangeObj(props.el, e.target.id, str);
    if (e.target.id === "description")
      props.onChangeObj(props.el, e.target.id, str);
    // setObject({ ...object, [e.target.id]: str });
    return str;
  };

  const onDeleteContract = () => {
    // if (props.data.length === 1) return;
    props.setCollection(props.data.filter((el) => el._id !== props.el._id));
  };

  return (
    <CollectBox>
      <SubTitle w="20%" style={{ paddingTop: "10px" }}>
        비상연락망 {props.idx}
      </SubTitle>
      <Content>
        <Flex pb="8px" alignItems="center">
          <SubTitle w="25%">담당자명 / 연락처</SubTitle>
          <Box w="100%">
            <Input
              id="name"
              readOnly={!isMaster}
              onChange={onValidateInput}
              // onChange={(e) =>
              //   props.onChangeObj(props.el, "name", e.target.value)
              // }
              defaultValue={props.el.name || ""}
              placeholder="담당자명 또는 담당부서명"
              // onKeyDown={onPressKey}
            />
            <Input
              id="phone_number"
              readOnly={!isMaster}
              // onBlur={onHandleInput}
              // onFocus={onHandleInput}
              onChange={onValidNum}
              placeholder="연락처를 숫자로 입력하세요."
              defaultValue={props.el.phone_number || ""}
            />
            {isMaster && (
              <IoClose
                className="react-icon-static"
                onClick={onDeleteContract}
                style={{ fontSize: "24px" }}
              />
            )}
          </Box>
        </Flex>
        <Flex alignItems="center">
          <SubTitle w="25%">안내문구</SubTitle>
          <Input
            id="description"
            readOnly={!isMaster}
            // onBlur={onHandleInput}
            // onFocus={onHandleInput}
            onChange={onValidateInput}
            defaultValue={props.el.description || ""}
            style={{ width: "100%", marginRight: 0 }}
            placeholder="담당자 혹은 부서의 간단한 소개를 최대 100자 이내로 입력해주세요."
          />
        </Flex>
      </Content>
    </CollectBox>
  );
};

export const MemoizedContract = memo(ContractItem);
