import { Button } from "@chakra-ui/react";
import styled from "@emotion/styled";
import * as S from "../styled";
import { IEmptyValue, IOpenSection } from "../types";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef, HTMLProps, Ref, useState } from "react";
import { useFieldArray, Controller, useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useLocation } from "react-router-dom";
import { IEmptyRoom } from "../../../lib/types/Imodels";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { breakPoints } from "../../../components/styled/media";
import { useToast } from "@chakra-ui/react";
import { warning } from "../../../lib/theme/toast";
import { colors, m_color } from "../../../components/styled/common";
import { empty_room_list } from "../../../lib/api/queries/urls";
import { empty_room_list_key } from "../../../lib/api/queries/keys";
import { DateWithIconBox, DownIcon, InnerTextBox } from "../../../components/styled";

const Select = styled.select`
  width: 100%;
  margin: 8px 0;
  background: ${colors.gray1} url("/commons/select.svg") no-repeat 96% center;
`;

const SmallSelect = styled.select`
  width: 96px;
  margin-right: 8px;
  background: ${colors.gray1} url("/commons/select.svg") no-repeat 85% center;
  :disabled {
    cursor: not-allowed;
  }
  @media ${breakPoints.phones} {
    width: 80px;
  }
`;

const AddButton = styled(Button)`
  font-size: 1em;
  width: 96px;
  line-height: inherit;
  font-weight: 400;
  :hover,
  :hover:disabled {
    background-color: ${m_color.primary1};
  }
  @media ${breakPoints.phones} {
    width: 80px;
  }
`;

const RoundedSelectDate = styled(InnerTextBox)`
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  font-size: 1em;
  font-weight: 400;
  svg {
    font-size: 1em;
  }
  input {
    width: 100%;
    background-color: ${colors.gray1};
  }
`;

const RoomInfo = styled(S.InfoText)`
  display: flex;
  padding: 1em 0 0.85em;
  color: ${colors.red1};
  span {
    padding: 0.2em 0.4em;
    margin-right: 4px;
    border-radius: 4px;
    background: ${colors.red1};
    font-weight: 600;
    color: ${colors.whiteColor};
    max-height: 2em;
  }
`;

const RoundedGrayRange = forwardRef(
  ({ value, style, ...props }: HTMLProps<HTMLInputElement>, ref: Ref<HTMLInputElement>) => {
    return (
      <RoundedSelectDate style={style}>
        <input
          ref={ref}
          value={value !== "" ? value : props.name}
          // onClick={(e) => console.log(e)}
          {...props}
        />
        <DownIcon style={{ right: "4%" }} />
      </RoundedSelectDate>
    );
  },
);

// 1. 입주정보
export default function TenantInfo(props: IOpenSection) {
  const toast = useToast();
  const location = useLocation();
  const building_id = location.search.split("=")?.[1];

  const [ids, setIds] = useState<{ id: string; room: string }[]>([]);
  const [dong, setDong] = useState("");
  const [ho, setHo] = useState("");

  const { control, watch, getValues, register } = useFormContext();
  const room_ids = useFieldArray({ control, name: "info.room_ids" });

  const sortFunc = (a: IEmptyValue, b: IEmptyValue) => {
    const prev = a.ho || "";
    const next = b.ho || "";

    return prev > next ? 1 : prev < next ? -1 : 0;
  };

  const { data } = useQuery(
    [empty_room_list_key, getValues("info.room_type")],
    () =>
      axios
        .post<BaseResponse<IEmptyRoom[]>>(`${process.env.REACT_APP_SERVER_URL}${empty_room_list}`, {
          building_id,
          room_type: getValues("info.room_type"),
        })
        .then((res) => res.data.data)
        .catch((err: AxiosError) => console.log(err)),
    { enabled: !!watch("info.room_type") },
  );

  const num = data?.findIndex((el) => el.dong === dong);

  const onAddIds = () => {
    if (dong === "" || ho === "")
      return toast({
        ...warning,
        title: "알림",
        description: "동, 호를 먼저 선택 후 추가해주세요.",
      });
    const newArr = [...ids];
    const _id = ho.split(",")[0];
    const Ho = `${dong}동 ${ho.split(",")[1]}호`;

    if (newArr.filter((el) => el.id === _id).length !== 0)
      return toast({
        ...warning,
        title: "알림",
        description: "이미 선택한 호실입니다. 다시 확인해주세요.",
      });

    newArr.push({ id: _id, room: Ho });
    setIds(newArr);
    room_ids.append(_id);
  };

  const onSubtractRoom = (_id: number) => {
    const newArr = [...ids];

    setIds(newArr.filter((_, idx) => idx !== _id));
    room_ids.remove(_id);
  };

  const onChangeTypes = () => {
    room_ids.remove();
    setDong("");
    setHo("");
    setIds([]);
  };

  return (
    <S.ToggleBox>
      <S.ToggleTitle onClick={() => props.setIsOpen((prev) => !prev)}>
        1. 입주정보 *
        <S.ToggleIcon src="/arrow.png" alt="화살표 아이콘" isOpen={props.isOpen} />
      </S.ToggleTitle>
      <S.ToggleContent
        id="info"
        isHeight={(document.getElementById("info")?.scrollHeight || 270) + 105}
        isOpen={props.isOpen}
      >
        <S.InfoText>
          <p>같은 입주 유형만 등록이 가능합니다.</p>
          <p>예시{")"} 사무동, 기숙사동 모두 이용 → 입주카드 2개 제출</p>
        </S.InfoText>
        <Select
          {...register("info.room_type", {
            onChange: onChangeTypes,
          })}
        >
          <option value="">입주 유형</option>
          <option value="office">업무용 (오피스,지산)</option>
          <option value="store">상가용 (근린생활시설)</option>
          <option value="residential">주거용 (기숙사,오피스텔)</option>
        </Select>
        <div style={{ marginBottom: "8px" }}>
          <SmallSelect
            disabled={data === undefined ? true : !watch("info.room_type")}
            value={dong}
            onChange={(e) => setDong(e.target.value)}
          >
            <option value="">동 선택</option>
            {data?.map((el) => (
              <option value={el.dong} key={el.dong}>
                {el.dong}동
              </option>
            ))}
          </SmallSelect>
          <SmallSelect
            disabled={data === undefined ? true : !watch("info.room_type")}
            value={ho}
            onChange={(e) => setHo(e.target.value)}
          >
            <option value="">호 선택</option>
            {num !== undefined && num !== -1 ? (
              <>
                {data?.[num].value.sort(sortFunc).map((el) => (
                  <option value={`${el._id},${el.ho}`} key={String(el._id)}>
                    {el.ho}호
                  </option>
                ))}
              </>
            ) : (
              <></>
            )}
          </SmallSelect>
          <AddButton
            variant="m_primary"
            isDisabled={data === undefined ? true : false}
            type="button"
            onClick={onAddIds}
          >
            추가
          </AddButton>
          {getValues("info.room_type") && ids.length === 0 && (
            <RoomInfo>
              <span>안내</span>
              <p>
                현재 공실 상태인 호실만 선택 가능합니다. 만약 입주하신 호실을 찾을 수 없다면 입주담당관리자에게
                문의해주세요.
              </p>
            </RoomInfo>
          )}
        </div>
        <S.SelectedRoomBox>
          {ids.map((el, idx) => (
            <S.SelectedRoom key={el.id}>
              {el.room}
              <S.CancleButton onClick={() => onSubtractRoom(idx)} />
            </S.SelectedRoom>
          ))}
        </S.SelectedRoomBox>
        <DateWithIconBox>
          <Controller
            control={control}
            name="info.move_in_date"
            render={({ field }) => (
              <DatePicker
                locale={ko}
                dateFormat="yyyy-MM-dd"
                selected={field.value}
                onChange={field.onChange}
                name="입주일 선택"
                // onChange={(date: Date) => setInfo({ ...Info, date })}
                customInput={<RoundedGrayRange value={field.value} />}
              />
            )}
          />
        </DateWithIconBox>
        <DateWithIconBox>
          <Controller
            control={control}
            name="info.period"
            render={({ field }) => (
              <DatePicker
                locale={ko}
                dateFormat="yyyy-MM-dd"
                selectsRange
                monthsShown={1}
                showYearDropdown
                startDate={field.value[0]}
                endDate={field.value[1]}
                onChange={field.onChange}
                name="임대 기간 선택"
                // onChange={(date: Date) => setInfo({ ...Info, date })}
                customInput={<RoundedGrayRange style={{ marginTop: "8px" }} value={JSON.stringify(field.value)} />}
              />
            )}
          />
        </DateWithIconBox>
        <Select {...register("info.move_in_type")}>
          <option value="">입주 형태</option>
          <option value="monthly_rent">월세</option>
          <option value="own_house">자가</option>
          <option value="etc">기타</option>
        </Select>
      </S.ToggleContent>
    </S.ToggleBox>
  );
}
