import { Button } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../lib/api/axios";
import { complaint_list_key } from "../../lib/api/queries/keys";
import { complaint_list } from "../../lib/api/queries/urls";
import RequestListItem from "./_view/requestItem";
import { IComplaintStatus } from "./commons/types";
import { MouseEvent, useContext, useEffect, useState } from "react";
import { usePagination } from "@ajna/pagination";
import PaginationUI from "../../components/pagination";
import { BaseResponse } from "../../lib/api/queries/commons/types";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useUserAuth from "../../components/hooks/useUserAuth";
import FullPageSpinner from "../../components/Spinner";
import GetAuthorized from "../../components/Authrized";
import { useFetchCategories } from "./commons/queries";
import NotFound from "../not-found";
import CommonMenuTabframe from "../../components/Main/MenuTabFrame";
import {
  DateInputWrap,
  MainContainer,
  NoHistoryInData,
  ShowedCount,
  TableHeaderWrapper,
  TableWrapper,
} from "../../components/styled/webApp";
import GrayColorSelect from "../../components/select";
import GrayRadiusWithIcon from "../../components/Input/search";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import ChangeStatusByTags from "../../components/selectBox/tags";
import * as R from "./commons/styled";
import { DateWithIconBox, FixedTitle } from "../../components/styled";
import { GlobalContext } from "../../App";
import { usefetchTeamList } from "../control/manager/commons/queries";
import moment from "moment";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import ExcelFileDownload from "./_view/download";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { GrayBackGroundRange } from "../../components/datePicker";

type Response = {
  _id: string;
  complaint_type: string;
  progress_type: string;
  dong: string;
  floor: string;
  title: string;
  body?: string;
  is_new?: boolean;
  is_new_comment?: boolean;
  companyInfo?: {
    name: string;
    members: {
      _id: string;
      user_id?: string;
      permission?: 1 | 2 | 3;
      name?: string;
      phone_number?: string;
    }[];
    id: string;
    tenantType: "office" | "store" | "residential" | "etc";
  }[];
  createdAt: Date;
};

// 일반 민원, 소속별 민원 같이 쓰일 것 -> 실제 데이터 컴포넌트 따로 구성할 필요가 있음
export default function ComplaintListPage() {
  const navigate = useNavigate();
  const { user, buildInfo } = useContext(GlobalContext);
  const { department_id } = useParams();
  const auth = useUserAuth("complaint");
  const hasFunc = buildInfo?.services.COMPLAINT?.enabled;
  const { data: teams } = usefetchTeamList(user?.user_type === "master");
  const isEdit = auth === "edit";

  const [subtabs, setSubtabs] = useState<{ _id?: string; tab: string; isActive: boolean }[]>([]);
  const [tabPath, setTabPath] = useState(department_id || "");
  const [searchParams, setSearchParams] = useSearchParams();
  const progress_type = searchParams.get("progress") || "all";
  const complaint = searchParams.get("complaint") || "";
  // const search = searchParams.get("search") || "";
  // const [progress_type, setProgressType] = useState("all");
  // const [complaint, setComplaint] = useState(
  //   searchParams.get("complaint") || ""
  // );
  // const [startDate, setStartDate] = useState<Date | null>(
  //   new Date(moment().add(-1, "year").format("YYYY-MM-DD"))
  // );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const [allPage, setAllPage] = useState(0);
  const { setCurrentPage } = usePagination({
    initialState: { currentPage: Number(searchParams.get("page") || 1) },
  });

  const params = useLocation();
  const isNeedDepartment = params.pathname.includes("todo");
  const initialParams = {
    page: String(1),
    progress: progress_type,
    complaint,
    search,
  };
  const start_date = (startDate !== "" ? moment(startDate) : moment().add(-1, "year")).format("YYYY-MM-DD");
  const end_date = (endDate !== "" ? moment(endDate) : moment()).format("YYYY-MM-DD");

  useEffect(() => {
    if (!user) return;
    //기간 선택 초기화
    setStartDate("");
    setEndDate("");

    const newArr =
      user.user_type === "master"
        ? teams?.map((el) => ({
            tab: el.name,
            _id: String(el._id),
            isActive: params.pathname.includes(String(el._id)),
          })) || []
        : [
            {
              tab: user.department_id?.name || "",
              _id: String(user.department_id?._id),
              isActive: params.pathname.includes(String(user.department_id?._id)),
            },
          ];
    if (newArr.length === 0) return;

    setSubtabs(newArr);
    setTabPath(department_id ? department_id : newArr[0]._id);
  }, [teams, user, params.pathname]);

  const { data: category } = useFetchCategories("complaint_type");
  const { data, refetch } = useQuery(
    [complaint_list_key, progress_type, complaint, searchParams.get("page") || "1", isNeedDepartment ? tabPath : "all"],
    () =>
      fetchClient
        .get<BaseResponse<Response[]>>(complaint_list, {
          params: {
            limit: 10,
            page: searchParams.get("page") || "1",
            start_date,
            end_date,
            progress_type,
            complaint_type: complaint,
            search,
            department_id: isNeedDepartment ? tabPath : undefined,
          },
        })
        .then((res) => {
          setAllPage(res.data.total_page_num || 0);
          return res.data;
        }),
    { enabled: auth === "edit" || auth === "read" },
  );

  const updateParams = (str: string) => {
    setSearchParams({
      ...initialParams,
      ...{ progress: str },
    });
  };

  const onClickResult = () => {
    setSearchParams({ ...initialParams });
    if (Number(searchParams.get("page") || "1") === 1) refetch();
  };

  const requestArr: IComplaintStatus[] = [
    { id: "all", title: "전체" },
    { id: "waiting", title: "처리대기" },
    { id: "proceeding", title: "처리중" },
    { id: "completed", title: "처리완료" },
  ];

  const menuArr = [
    {
      section: "민원보드",
      path: "/complaints",
      isChecked: !isNeedDepartment,
    },
    {
      section: "할 일",
      path: `/complaints/todo/${tabPath}`,
      isChecked: isNeedDepartment,
    },
  ];

  const tabs = isNeedDepartment ? subtabs : [{ tab: "일반민원", isActive: true }];

  const onMovePath = (e: MouseEvent<HTMLLIElement>) => {
    //이동할 서브탭 추후 실시간 현황 생기면 사용
    //일단 민원 할일 팀선택만 진행
    const ID = e.currentTarget.id;
    const idx = subtabs.findIndex((tab) => tab.tab === ID);
    setTabPath(subtabs[idx]._id || "");

    switch (ID) {
      case subtabs[idx].tab:
        return navigate(`/complaints/todo/${subtabs[idx]._id || ""}`);
      default:
        return navigate(`/complaints`);
    }
  };

  const onMoveToCreateComplaint = () => {
    navigate("/complaints/create");
  };

  const onChangeStartDate = (value: Date | null) => {
    const next = endDate ? moment(endDate) : moment();
    // console.log(next.add(-1, "year").format("YYYY-MM-DD"));

    if (!value) return setStartDate(next.add(-1, "year").format("YYYY-MM-DD"));

    const date = moment(value);
    if (date.diff(next.add(-1, "year")) < 0) return setStartDate(next.add(-1, "year").format("YYYY-MM-DD"));

    if (next.diff(date) > 0) {
      // IOS 끝나는 날 혹은 오늘보다 선택한 날이 최신일 경우
      setEndDate(next.format("YYYY-MM-DD"));
      return setStartDate(next.format("YYYY-MM-DD"));
    }

    setStartDate(moment(value).format("YYYY-MM-DD"));
  };

  const onChangeEndDate = (value: Date | null) => {
    const date = moment(value);
    const today = moment();
    const prev = startDate ? moment(startDate) : moment().add(-1, "year");

    //for safari, IOS
    if (!value || date.diff(today) > 0) return setEndDate(today.format("YYYY-MM-DD"));
    if (prev.diff(date) > 0) return setEndDate(prev.format("YYYY-MM-DD"));
    if (startDate && date.diff(moment(startDate).add(1, "year")) > 0) {
      //시작일이 있고, 종료일 설정이 1년 이상일 경우
      //종료일 설정이 오늘보다 크면 변화없이 종료
      if (date.diff(today) > 0) return;
      //종료일 설정에 맞춰서 시작 날짜도 변경
      setEndDate(date.format("YYYY-MM-DD"));
      return setStartDate(moment(date).add(-1, "year").format("YYYY-MM-DD"));
    }
    //default
    setEndDate(moment(value).format("YYYY-MM-DD"));
  };

  if (!auth || hasFunc === undefined || !data) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;
  if (auth === "no_auth") return <GetAuthorized />;
  return (
    <CommonMenuTabframe menuTabs={menuArr} tabs={tabs} onClickMovePage={onMovePath}>
      <MainContainer>
        <R.ShowedResultBox>
          <R.ActionResultBox>
            <GrayColorSelect
              className="category"
              value={complaint}
              _style={{ textAlign: "left" }}
              _onChange={(e) =>
                setSearchParams({
                  ...initialParams,
                  ...{ complaint: e.target.value },
                })
              }
            >
              <option value="">민원유형</option>
              {category?.map((el) => {
                if (el.title === "실내온도") return;
                return (
                  <option key={String(el._id)} value={el.title}>
                    {el.title}
                  </option>
                );
              })}
              <option value="기타">기타</option>
            </GrayColorSelect>
            <DateInputWrap>
              <DateWithIconBox>
                <DatePicker
                  locale={ko}
                  // isClearable
                  selected={new Date(start_date)}
                  dateFormat="yyyy-MM-dd"
                  minDate={
                    startDate && moment().add(-1, "year").diff(moment(startDate)) < 0
                      ? new Date(startDate)
                      : new Date(moment().add(-1, "year").format("YYYY-MM-DD"))
                  }
                  maxDate={endDate && moment().diff(moment(endDate)) > 0 ? new Date(endDate) : new Date()}
                  onChange={onChangeStartDate}
                  customInput={
                    <GrayBackGroundRange
                      // style={{ background: "inherit" }}
                      value={startDate}
                    />
                  }
                />
              </DateWithIconBox>
              <div>~</div>
              <DateWithIconBox>
                <DatePicker
                  locale={ko}
                  selected={endDate ? new Date(endDate) : new Date()}
                  minDate={new Date(start_date)}
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                  onChange={onChangeEndDate}
                  customInput={
                    <GrayBackGroundRange
                      style={{ margin: "0 24px 0 8px" }}
                      // style={{ background: "inherit" }}
                      value={end_date}
                    />
                  }
                />
              </DateWithIconBox>
              <FiChevronDown />
            </DateInputWrap>
            <GrayRadiusWithIcon
              className="search-box"
              value={search}
              placeholder="동/층, 내용, 입주사명으로 검색가능"
              _onChange={(e) => setSearch(e.target.value)}
              _onKeyUp={(e) => e.key === "Enter" && onClickResult()}
              _onClick={onClickResult}
            />
          </R.ActionResultBox>
          <R.ActionButtonBox>
            {/* <Button
              className="none"
              variant="m_gray"
              mr="0.75rem"
              onClick={() => {
                if (!data.total_num)
                  return toast({
                    ...warning,
                    title: "데이터 추출",
                    description:
                      "추출할 데이터가 없습니다. 필터를 다시 설정 후 시도해주세요.",
                  });
                onOpen();
              }}
            >
              <RiDownloadLine />
              데이터 추출
            </Button> */}
            <ExcelFileDownload
              isToast={data.total_num === 0}
              dateRange={`${start_date} ~ ${end_date}`}
              {...{ search, tabPath }}
            />
            {isEdit && (
              <Button variant="m_blue" onClick={onMoveToCreateComplaint}>
                <FiPlus />
                민원 등록
              </Button>
            )}
          </R.ActionButtonBox>
        </R.ShowedResultBox>
        <ChangeStatusByTags tagArr={requestArr} onClick={updateParams} status={progress_type} />
        <ShowedCount>조회 결과 {data?.total_num || 0}건</ShowedCount>
        <TableWrapper>
          <TableHeaderWrapper>
            <div style={{ minWidth: "10%" }} className="none">
              유형
            </div>
            <R.SpecificCloum>내용</R.SpecificCloum>
            <div className="none">발생위치</div>
            <FixedTitle className="none">입주사명</FixedTitle>
            <div className="none">등록일시</div>
            <div style={{ textAlign: "center", minWidth: "10%" }}>처리상태</div>
          </TableHeaderWrapper>
          {data.data.length === 0 ? (
            <NoHistoryInData style={{ height: "35vh" }}>
              {isNeedDepartment ? "현재 요청받은 내역" : "접수된 민원"}이 없습니다.
            </NoHistoryInData>
          ) : (
            <>
              {data.data.map((el) => (
                <RequestListItem el={el} key={String(el._id)} />
              ))}
              <PaginationUI
                // page={currentPage}
                page={Number(searchParams.get("page") || 1)}
                onChangeFunc={(page) =>
                  setSearchParams({
                    ...initialParams,
                    ...{ page: String(page) },
                  })
                }
                setPage={setCurrentPage}
                allPage={allPage}
              />
            </>
          )}
        </TableWrapper>
      </MainContainer>
    </CommonMenuTabframe>
  );
}
