import styled from "@emotion/styled";
import { colors } from "../styled/common";
import { StepShadowBox } from "../styled/webApp";

const UnActiveStep = styled.div<{ active: boolean; angle: boolean }>`
  width: 100%;
  padding: 1.25rem 0;
  font-size: 0.875rem;
  text-align: center;
  font-weight: ${({ active }) => (active ? 700 : 500)};
  background-color: ${({ active }) =>
    active ? colors.mainBlue : colors.whiteColor};
  color: ${({ active }) => (active ? colors.whiteColor : colors.gray4)};
  position: relative;
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-color: transparent;
    border-width: 32px 0 30px 16px;
    border-style: solid;
    border-left: 16px solid ${({ angle }) => (angle ? "#2563eb" : "#fff")};
  }
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: ${({ angle }) => (angle ? 0 : "1px")};
    border-color: transparent;
    border-width: 32px 0 30px 16px;
    border-style: solid;
    border-left: 16px solid ${({ angle }) => (angle ? "#2563eb" : "#e1e1e1")};
  }
  :first-of-type {
    border-radius: 8px 0 0 8px;
    ::after {
      content: none;
    }
    ::before {
      content: none;
    }
  }
  :last-of-type {
    border-radius: 0 8px 8px 0;
  }
`;

function ShadowArrowStepBox(props: { step: string[]; isActive: number }) {
  const { step, isActive } = props;

  return (
    <StepShadowBox>
      {step.map((el, idx) => (
        <UnActiveStep
          key={el}
          active={el === step[isActive]}
          angle={step[idx] === step[isActive + 1]}
          style={idx === 1 ? { padding: "1.25rem 0 1.25rem 0.5rem" } : {}}
        >
          {el}
        </UnActiveStep>
      ))}
    </StepShadowBox>
  );
}

export default ShadowArrowStepBox;
