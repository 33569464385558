export const form = {
  fontSize: {
    title: "1.75em",
    fs24: "1.5em",
    fs20: "1.25em",
    fs16: "1em",
    fs14: "0.875em",
    fs12: "0.75em",
  },
};

export const colors = {
  mainBlue: "#2563EB",
  skyBlue1: "#dbeafe",
  whiteColor: "#ffffff",
  gray1: "#f4f4f5",
  gray2: "#d1d5db",
  gray3: "#9CA3AF",
  gray4: "#6B7280",
  gray5: "#111827",
  green1: "#3cb979",
  red1: "#FB4667",
  red2: "#FFEBEE", //투명 빨강
  orange1: "#FF460B", //투명 추가될수도, 지금은 빈도 낮아서 추가 안함
};

export const m_color = {
  mainBlue: "#2563EB",
  whiteColor: "#ffffff",
  //red
  danger1: "#FEEFEF", //light background
  danger2: "#FCE0E0", //light_hover background
  danger3: "#FACCCA", //light_press background
  danger4: "#F13D2C", //default_hover background, text color
  danger5: "#F36151", //default background
  danger6: "#DE291D", //default_press background
  //blue
  primary1: "#E7F2FE", //light_blue background
  primary2: "#D4E3FB", //light_hover background
  primary3: "#C0D4F8", //light_press background
  blue1: "#2952C8", //cta_hover background
  blue2: "#203787", //cta_press background
};
