import { useEffect, ReactElement, useContext } from "react";
import { useNavigate, Outlet } from "react-router";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { GlobalContext } from "../../App";

export default function PublicRoute(): ReactElement | null {
  // const toast = useToast();
  const [user, loading] = useAuthState(auth);
  const { AppToken } = useContext(GlobalContext);
  const navigate = useNavigate();
  const isMobile = window.navigator.userAgent.includes("Mobi");

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user === null) {
        if (AppToken) navigate("/complaints", { replace: true });
      } else {
        navigate(isMobile ? "/complaints" : "/dashboard", { replace: true });
      }
    });
  }, [user, AppToken]);

  if (loading) return <></>;
  return <Outlet />;
}
