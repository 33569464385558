import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import SubframePart from "../../../../../components/Main/subframePart";
import { v4 as uuidv4 } from "uuid";
import { useCallback, useState } from "react";
import { ItemInfo } from "../../../[publicType]/_view/InfoItem";
import { ClosedDays } from "../../../[publicType]/operation/OperationItem";
import { BookingTime } from "../../components/bookingTime";
import { MemoizedMoreRequest, MemoizedPermission, MemoizedPaymentInfo, PaymentMethod } from "../../components/Payments";
import { IPaymentMethod, IPeopleTime } from "../../../../../lib/types/Imodels";
import { IItemBasicInfo, IMutateTime } from "../../../commons/types";
import { isCheckedItemOperation, onFormatDays } from "../../../commons/func";
import { useMutation } from "@tanstack/react-query";
import { fetchClient } from "../../../../../lib/api/axios";
import { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { create_booking_item } from "../../../../../lib/api/queries/urls";
import { MainActionBox } from "../../../../../components/styled/webApp";
import ShadowArrowStepBox from "../../../../../components/step";
import ResponsiveConfirm from "../../../../../components/modal/responsive/confirm";
import { toast } from "../../../../../lib/utils/toast";

export interface ITimeSet {
  _id?: string;
  start: string;
  end: string;
  time: IPeopleTime[];
  individual?: boolean;
  people_num?: number;
  max_num?: number;
}

export default function CreateBookingItems() {
  const navigate = useNavigate();
  const { publicType, publicId } = useParams();
  //팝업
  const { onOpen, isOpen, onClose } = useDisclosure(); //되돌아가기
  const { onOpen: onLast, isOpen: isLast, onClose: offLast } = useDisclosure(); //최종 등록
  //단계 이동 관련
  const bookingArr = ["기본정보", "예약 운영시간", "휴무일", "예약 ・ 결제"];
  const [isActive, setIsActive] = useState(0);
  //기본 정보 관련
  const [images, setImages] = useState("");
  const [itemInfo, setItemInfo] = useState<IItemBasicInfo>({
    name: "",
    introduction_description: "",
  });
  const onClickToOperation = () => {
    if (!itemInfo.name || !itemInfo.introduction_description)
      return toast.warning({
        title: "상품 정보 오류",
        description: "상품명과 상품 소개글은 필수로 입력해주세요.",
      });
    setIsActive(1);
  };

  //예약 관련
  const [interval, setInterval] = useState("");
  const [isEveryDay, setIsEveryDay] = useState("true");
  const timeSetId = uuidv4();
  const [weekDayArr, setWeekDayArr] = useState<ITimeSet[]>([
    { _id: timeSetId, start: "00:00", end: "00:00", time: [] },
  ]);
  const [weeklyArr, setWeeklyArr] = useState<ITimeSet[]>([]);
  const timeArr = { weekDayArr, setWeekDayArr, weeklyArr, setWeeklyArr };

  const onValidateOperation = () => {
    const isValid = isCheckedItemOperation(weekDayArr, weeklyArr);
    if (isValid === "운영 시간 설정 안내") return toast.operationTimeWarning({});
    if (isValid === "시간 설정 안내")
      return toast.operationTimeWarning({
        description: "운영시간의 시작 시간은 마지막 시간보다 이전으로 설정해주세요.",
      });
    if (isValid === "예약 인원 설정 안내")
      return toast.operationTimeWarning({
        description: "예약 인원은 1명 이상으로 설정해주세요.",
      });
    if (isValid === "운영 시간 겹침")
      return toast.operationTimeWarning({
        description: "운영 시간은 겹칠 수 없습니다.",
      });

    setIsActive(2);
  };

  //휴무일 관련
  const [isMonthly, setIsMonthly] = useState(true);
  const [holidays, setHolidays] = useState<string[]>([]);
  const dayOff = { holidays, setHolidays, isMonthly, setIsMonthly };

  const onClickToPayment = () => {
    if (holidays.includes("yes") && holidays.length === 1)
      return toast.warning({
        title: "휴무일 설정 오류",
        description: "휴무일을 정확히 설정했는 지 다시 한 번 확인해주세요.",
      });

    setIsActive(3);
  };

  //예약 확정 방식 관련
  const [isPermission, setIsPermission] = useState<boolean>();
  const onUpdateNeedPermission = useCallback(
    (status: boolean) => {
      if (status === isPermission) return;
      setIsPermission(status);
    },
    [isPermission],
  );
  //예약 결제 관련
  const [isFree, setIsFree] = useState(false);
  const [method, setMethod] = useState<IPaymentMethod[]>([{ _id: "first", payment_method: "point", price: "" }]);
  const onUpdateIsFree = useCallback((isFree: boolean) => setIsFree(isFree), [isFree]);
  //결제 안내사항
  const [paymentInfo, setPaymentInfo] = useState("");
  //요청사항
  const [isMoreRequest, setIsMoreRequest] = useState<boolean>();
  const onUpdateMoreRequest = useCallback(
    (status: boolean) => {
      if (status === isMoreRequest) return;
      setIsMoreRequest(status);
    },
    [isMoreRequest],
  );

  const mutation = useMutation((newObj: IMutateTime) =>
    fetchClient
      .post(create_booking_item, {
        room_public_id: publicId,
        picture: images,
        name: itemInfo.name,
        introduction_description: itemInfo.introduction_description,
        reservation_and_visit_description: itemInfo.reservation_and_visit_description,
        time_unit: interval,
        is_same_weekdays_and_weekends: JSON.parse(isEveryDay),
        people_per_time_slot_weekday: newObj.weekend,
        people_per_time_slot_weekend: isEveryDay === "false" ? newObj.weekly : newObj.weekend,
        regular_holiday_weeks: holidays.includes("none") ? [] : onFormatDays(holidays, "주"),
        regular_holiday_days: holidays.includes("none") ? [] : onFormatDays(holidays, "요일"),
        holidays: holidays.includes("none") ? [] : onFormatDays(holidays, "공휴일"),
        is_approval_required: isPermission,
        payment_method_and_price: isFree
          ? [{ payment_method: "free" }]
          : method.map((el) => ({
              payment_method: "point",
              price: el.price,
            })),
        payment_description: paymentInfo,
        is_additional_request: isMoreRequest || false,
      })
      .then((res) => {
        toast.success({
          title: "신규 상품 등록 완료",
          description: "신규 예약 상품이 등록되었습니다. 예약 상품 조회 화면으로 이동합니다.",
        });
        offLast();
        // navigate(`/time-type/${publicType}/booking/${publicId}/items`);
        navigate(`/facility/${publicType}/booking/${publicId}/items`);
        return res.data.data;
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 402)
          return toast.warning({
            title: "신규 상품 등록 오류",
            description: "해당 상품과 같은 이름의 상품이 이미 등록되었습니다. 확인 후 다시 등록해주세요.",
          });
        if (err.response?.status === 401) return toast.unAuthUser();
      }),
  );

  const onClickToCreateItem = () => {
    if (isPermission === undefined)
      return toast.warning({
        title: "예약 확정 방식 선택",
        description: "예약 확정 방식을 선택해주세요.",
      });
    if (!isFree && method.some((el) => !Number(el.price)))
      return toast.warning({
        title: "결제 수단 설정",
        description: "무료 이용 시설은 무료 이용을 선택, 유료 시설은 결제 금액을 정확히 입력해주세요.",
      });

    onLast();
  };

  const onCreateItems = () => {
    const newObj: IMutateTime = { weekend: [] };
    weekDayArr.forEach((el) =>
      el.time.forEach((ele) =>
        newObj.weekend.push({
          time: ele.time,
          people_num: ele.people_num,
        }),
      ),
    );
    if (isEveryDay === "false") {
      newObj.weekly = [];
      weeklyArr.forEach((el) =>
        el.time.forEach((ele) =>
          newObj.weekly?.push({
            time: ele.time,
            people_num: ele.people_num,
          }),
        ),
      );
    }
    mutation.mutate(newObj);
  };

  return (
    <SubframePart isModal={true} name="신규 예약상품 등록">
      <MainActionBox>
        <ShadowArrowStepBox step={bookingArr} isActive={isActive} />
        {isActive === 0 ? (
          <>
            <ItemInfo
              itemInfo={itemInfo}
              setItemInfo={setItemInfo}
              fileUrl={images}
              setFileUrl={setImages}
              auth="edit"
            />
            <Button variant="m_blue" fontSize="1.25rem" onClick={onClickToOperation}>
              다음
            </Button>
          </>
        ) : isActive === 1 ? (
          <>
            {/* <OperationPlan /> */}
            <BookingTime
              interval={interval}
              setInterval={setInterval}
              isEveryDay={isEveryDay}
              setIsEveryDay={setIsEveryDay}
              {...timeArr}
            />
            <Flex>
              <Button variant="m_gray" fontSize="1.25rem" w="100%" mr="1rem" onClick={onOpen}>
                이전
              </Button>
              <Button variant="m_blue" fontSize="1.25rem" w="100%" onClick={onValidateOperation}>
                다음
              </Button>
            </Flex>
          </>
        ) : isActive === 2 ? (
          <>
            <ClosedDays isModal={false} {...dayOff} />
            <Flex>
              <Button variant="m_gray" fontSize="1.25rem" w="100%" mr="1rem" onClick={onOpen}>
                이전
              </Button>
              <Button w="100%" variant="m_blue" fontSize="1.25rem" onClick={onClickToPayment}>
                다음
              </Button>
            </Flex>
          </>
        ) : (
          <>
            <MemoizedPermission isActive={isPermission} onUpdateActive={onUpdateNeedPermission} />
            <PaymentMethod setMethod={setMethod} method={method} onUpdateIsFree={onUpdateIsFree} isFree={isFree} />
            <MemoizedPaymentInfo paymentInfo={paymentInfo} setPaymentInfo={setPaymentInfo} />
            <MemoizedMoreRequest isActive={isMoreRequest} onUpdateActive={onUpdateMoreRequest} />
          </>
        )}
        <ResponsiveConfirm
          next={isOpen ? "이동" : "등록"}
          before="취소"
          title={isOpen ? "이전 단계 이동" : "신규 예약 상품 등록"}
          isOpen={isOpen || isLast}
          onClose={isOpen ? onClose : offLast}
          onAction={() => {
            if (isOpen) {
              if (isActive === 1) {
                setInterval("");
                setIsEveryDay("true");
                setWeekDayArr([{ _id: timeSetId, start: "00:00", end: "00:00", time: [] }]);
                setWeeklyArr([]);
              } else if (isActive === 2) {
                setIsMonthly(true);
                setHolidays([]);
              } else {
                setIsPermission(undefined);
                setIsMoreRequest(undefined);
                setPaymentInfo("");
                setMethod([{ _id: "first", payment_method: "point", price: "" }]);
              }
              setIsActive(isActive - 1);
              onClose();
            } else {
              onCreateItems();
            }
          }}
        >
          <>
            {isOpen ? (
              <>
                이전 단계로 이동할까요? <br />
                이전 단계로 이동 시 입력한
                {isActive === 1 ? " 예약 운영시간 " : isActive === 2 ? " 휴무일 " : " 예약 및 결제 "}
                정보는 모두 사라집니다.
              </>
            ) : (
              <>작성하신 정보로 예약 상품을 등록할까요?</>
            )}
          </>
        </ResponsiveConfirm>
        {isActive === 3 && (
          <Flex>
            <Button w="100%" mr="1rem" variant="m_gray" fontSize="1.25rem" onClick={onOpen}>
              이전
            </Button>
            <Button w="100%" fontSize="1.25rem" variant="m_blue" onClick={onClickToCreateItem}>
              등록완료
            </Button>
          </Flex>
        )}
      </MainActionBox>
    </SubframePart>
  );
}
