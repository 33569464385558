import { Flex } from "@chakra-ui/react";
import * as P from "../../../../components/styled/webApp";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import Basicframe from "../../component/basicframe";
import { DateWithIconBox } from "../../../../components/styled";
import UserListItem from "./_view/userItem";
import { useEffect, useState } from "react";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { booking_user_list } from "../../../../lib/api/queries/urls";
import { booking_user_list_key } from "../../../../lib/api/queries/keys";
import { useFetchItemList } from "../../commons/queries";
import { URLSearchParamsInit, useParams, useSearchParams } from "react-router-dom";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { IReservedItemUser } from "../../../../lib/types/Imodels";
import { ShadowDateRange } from "../../../../components/datePicker";
import GrayColorSelect from "../../../../components/select";
import GrayRadiusWithIcon from "../../../../components/Input/search";
import GrayShadowSelect from "../../../../components/select/shadow";
import styled from "@emotion/styled";

const ReservationTable = styled(P.TableHeaderWrapper)`
  > div {
    width: 20%;
  }
`;

const BookingList = () => {
  const { publicType, publicId } = useParams();
  const { fetchItemlist } = useFetchItemList(publicId || "");
  const [searchParams, setSearchParams] = useSearchParams();
  const criteria = searchParams.get("criteria") || "use";
  // const [date, setDate] = useState(new Date());
  const start_params = searchParams.get("start");
  const end_params = searchParams.get("end");
  const start_date = start_params ? new Date(start_params) : new Date();
  const end_date = end_params ? new Date(end_params) : new Date();
  const [range, setRange] = useState<[Date | null, Date | null]>([start_date, end_date]);
  // const search = searchParams.get("search") || "";
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const ids = searchParams.get("ids") || "";
  const status = searchParams.get("status") || "";
  // const [payment, setPayment] = useState("");
  const params: URLSearchParamsInit = {
    page: String(1),
    criteria,
    search,
    ids,
    status,
  };

  const kor = decodeURIComponent(publicType || "");
  const { data, refetch } = useQuery(
    [booking_user_list_key, criteria, ids, status], //payment
    () =>
      fetchClient
        .get<BaseResponse<IReservedItemUser[]>>(booking_user_list, {
          params: {
            room_public_type: kor,
            start_date: start_params ?? moment().format("YYYY-MM-DD"),
            end_date: end_params ?? moment().format("YYYY-MM-DD"),
            // date: "2023-05-03",
            search,
            reservation_product_id: ids,
            date_type: criteria,
            check_status: status,
            // payment_status: payment,
          },
        })
        .then((res) => res.data.data),
    { enabled: !!publicType },
  );

  const onChangeUrls = (keys: string, value: string) => {
    params[keys] = value;
    setSearchParams(params);
  };

  const onDirectSearch = () => {
    setSearchParams(params);
    refetch();
  };

  useEffect(() => {
    if (!start_params || !end_params) return;
    if (range.every((el) => el !== null)) refetch();
  }, [start_params, end_params]);

  return (
    <P.MainContainer>
      <P.FliterBox>
        <Flex>
          <GrayShadowSelect value={criteria || "use"} _onChange={(e) => onChangeUrls("criteria", e.target.value)}>
            <option value="use">이용일</option>
            <option value="application">신청일</option>
          </GrayShadowSelect>
          <DateWithIconBox>
            <DatePicker
              locale={ko}
              selectsRange={true}
              startDate={range[0]}
              endDate={range[1]}
              dateFormat="yyyy. MM. dd"
              isClearable={false}
              onChange={(date) => {
                setRange(date);
                if (date[0] !== null) params.start = moment(date[0]).format("YYYY-MM-DD");
                if (date[1] !== null) params.end = moment(date[1]).format("YYYY-MM-DD");
                setSearchParams(params);
              }}
              customInput={<ShadowDateRange value={JSON.stringify(range)} />}
            />
          </DateWithIconBox>
          <GrayRadiusWithIcon
            _style={{ minWidth: "17.5rem" }}
            value={search}
            placeholder="예약자명, 전화번호로 검색가능"
            _onChange={(e) => setSearch(e.target.value)}
            _onKeyUp={(e) => e.key === "Enter" && onDirectSearch()}
            _onClick={onDirectSearch}
          />
        </Flex>
        <Flex pt="1rem">
          <GrayColorSelect
            value={ids}
            _style={{ textAlign: "left" }}
            _onChange={(e) => onChangeUrls("ids", e.target.value)}
          >
            <option value="">상품</option>
            {fetchItemlist.data?.map((el) => (
              <option key={String(el._id)} value={String(el._id)}>
                {el.name}
              </option>
            ))}
          </GrayColorSelect>
          <GrayColorSelect
            w="11rem"
            value={status}
            _style={{ textAlign: "left" }}
            _onChange={(e) => onChangeUrls("status", e.target.value)}
          >
            <option value="">예약상태</option>
            <option value="pending">예약신청</option>
            <option value="approved">예약확정</option>
            <option value="completed">이용완료</option>
            <option value="canceled">예약취소(이용자)</option>
            <option value="rejected">예약취소(관리자)</option>
          </GrayColorSelect>
          {/* <P.SelectGrayColor
            value={payment}
            onChange={(e) => setPayment(e.target.value)}
          >
            <option value="">결제상태</option>
            <option value="pending">결제대기</option>
            <option value="completed">결제완료</option>
            <option value="canceled">결제취소</option>
          </P.SelectGrayColor> */}
        </Flex>
      </P.FliterBox>
      <P.TableWrapper>
        <ReservationTable>
          <div>예약상태</div>
          <div>예약자 / 소속</div>
          <div>상품명</div>
          <div>이용기간</div>
          {/* <div>결제상태</div> */}
          <div>신청일시</div>
        </ReservationTable>
        {data?.map((el) => <UserListItem key={String(el._id)} el={el} />)}
      </P.TableWrapper>
    </P.MainContainer>
  );
};

export default function BookingListFrame() {
  return (
    <Basicframe>
      <BookingList />
    </Basicframe>
  );
}
