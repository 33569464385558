import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalFooter } from "@chakra-ui/react";
import { resetVoteStore, useVoteStore } from "../../../hooks/use-vote-store";
import VoteStepper from "./VoteStepper";
import VoteTargetSelect from "./VoteTargetSelect";
import VotePeriodSet from "./VotePeriodSet";
import VoteComplete from "./VoteComplete";
import Button from "../../../components/button/Button";
import { useGetOwnersQuery } from "../../../requests/owner";
import { useState } from "react";
import { useSendVoteMutation } from "../../../requests/vote";

type Props = {
  voteId: string;
  isOpen: boolean;
  onClose: () => void;
};

export default function VoteSendModal({ voteId, isOpen, onClose }: Props) {
  const [activeStep, setActiveStep] = useState<number>(1);
  const { data, isLoading, isError } = useGetOwnersQuery();
  const { mutateAsync: sendVoteMutateAsync } = useSendVoteMutation();
  const { selectOwners, checkWarn, endDate } = useVoteStore();

  if (isError) return <></>;
  if (isLoading) return <></>;

  const isNextButtonValid = activeStep === 1 ? selectOwners.length > 0 : checkWarn;
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      colorScheme="#2563EB"
      onCloseComplete={() => {
        setActiveStep(1);
        resetVoteStore();
      }}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} maxW={1200} h={700}>
        <ModalHeader paddingX={6} paddingBottom={0} paddingTop={6} borderBottom={"solid 1px"} borderColor="gray.200">
          <div className="pb-3">투표발송</div>
          <VoteStepper activeStep={activeStep} />
        </ModalHeader>
        <ModalCloseButton />
        {activeStep === 1 && <VoteTargetSelect data={data?.data} />}
        {activeStep === 2 && <VotePeriodSet />}
        {activeStep === 3 && <VoteComplete />}
        <ModalFooter paddingY={5} className="flex gap-7 border-t border-gray-200 ">
          <Button onClick={onClose} designSchema={"neutral"} buttonText={"취소"} size={"lg"} className="flex-1" />
          <Button
            disabled={!isNextButtonValid}
            onClick={() => {
              if (activeStep === 2) {
                if (!endDate) return;
                console.info(endDate.toISOString());
                sendVoteMutateAsync({
                  voteId,
                  endDate: endDate.toISOString(),
                  voterList: selectOwners.map((owner) => ({
                    name: owner.user_name,
                    phone_number: owner.user_phone_number,
                    room_ids: owner.room_ids,
                  })),
                }).then(() => {
                  setActiveStep(3);
                });
                return;
              }
              if (activeStep === 3) {
                onClose();
                return;
              }
              setActiveStep(activeStep + 1);
            }}
            designSchema={"primary"}
            buttonText={activeStep === 2 ? "발송하기" : "다음"}
            size={"lg"}
            className="flex-1"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
