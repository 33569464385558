import { Skeleton } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { colors } from "../styled/common";

const SkeletonBox = styled.div`
  background-color: ${colors.whiteColor};
  border-bottom: 1px solid ${colors.gray3};
  padding: 0.75rem 1rem;
`;

export default function SkeletonTable() {
  return (
    <>
      {Array(5)
        .fill("")
        .map((_, idx) => (
          <SkeletonBox key={idx}>
            <Skeleton w="100%" variant="default" />
          </SkeletonBox>
        ))}
    </>
  );
}
