import { useQuery } from "@tanstack/react-query";
import { http } from "../lib/http";
import { BaseResponse } from "../lib/api/queries/commons/types";
import { ServiceType } from "../types/services";

type Response = {
  location: {
    type: string;
    coordinates: number[];
  };
  temperature_list: any[];
  _id: string;
  code: string;
  name: string;
  address: string;
  logo_image: any[];
  room_location_type: string;
  dong_floor: {
    dong: string;
    floor: string[];
    _id: string;
  }[];
  room_public_dong: string[];
  room_public_floor: string[];
  auto_approval: boolean;
  is_onboarding: boolean;
  management_fee_details: any[];
  management_fee_selected_details: string[];
  employee_contact_list: {
    name: string;
    phone_number: string;
    description: string;
    _id: string;
  }[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  building_master_id: {
    business_card: any[];
    _id: string;
    firebase_uid: string;
    is_activated: boolean;
    is_notificated: boolean;
    company_type: string;
    user_type: string;
    check_status: string;
    building_id: string;
    name: string;
    email: string;
    phone_number: string;
    profile_picture: any[];
    auth_id: string;
    tenants: any[];
    notifications: {
      _id: string;
      type: string;
      is_activated: boolean;
    }[];
    createdAt: string;
    updatedAt: string;
    __v: number;
    description: string;
    fcm_token: string;
    nickname: string;
  };
  management_fee_upload_type: string;
  sign_up_type: string;
  room_public_end_time: string;
  room_public_start_time: string;
  management_office_description: string;
  building_type: string;
  building_description: string;
  payment_date: number;
  white_labeling: boolean;
  is_complaint_message: boolean;
  point_per_month: number;
  building_picture: string[];
  complaint_type: string;
  parking_description: string;
  pm_id: string;
  is_conference_room_point_used: boolean;
  imported_type: string;
  is_community: boolean;
  services: ServiceType;
};

export const useBuildingInfoQuery = () => {
  return useQuery<BaseResponse<Response>>({
    queryKey: ["/building/building"],
    queryFn: () => http.post("/building/building"),
  });
};
