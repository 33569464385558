import { Box, Flex, ModalFooter, Td, Input, Select } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { InnerTextBox, NewPost, FixedTitle, Wrapper } from "../../../components/styled";
import { colors } from "../../../components/styled/common";

export const SelectContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: 28px 0;
`;

export const ChangeButton = styled.button`
  padding: 8px;
  background-color: ${colors.mainBlue};
  border: none;
  font-size: 15px;
  font-weight: 400;
  border-radius: 4px;
  color: ${colors.whiteColor};
`;

export const TableTitle = styled(Td)`
  min-width: 160px;
  font-weight: 500;
`;

export const FixedFile = styled(FixedTitle)`
  text-align: center;
  &:hover {
    text-decoration: underline;
  }
`;

//payment_date
export const PaymentBox = styled(Flex)`
  align-items: center;
  border-bottom: 1px solid ${colors.gray2};
  padding: 8px 0;
`;

//spin table
export const SpinBox = styled.div`
  height: 100px;
  position: relative;
`;

//파일 변경 모달 띄우기
export const ChangeBox = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    width: 20%;
  }
`;

//관리비 파일 수정. 삭제
export const FileFooter = styled(ModalFooter)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 0px;
`;

export const TableWrapBox = styled.div`
  display: flex;
  justify-content: space-around;
  div {
    width: 25%;
  }
`;

export const MonthInput = styled(InnerTextBox)`
  background-color: ${colors.whiteColor};
  border: 1px solid ${colors.gray3};
  font-size: 16px;
  font-weight: 400;
  justify-content: space-between;
  width: 100%;
`;

//input과 함께 있는 table
export const BillContainer = styled(NewPost)`
  padding: 16px 0 16px 16px;
  border-bottom: none;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderSquare = styled.div<{ w?: string }>`
  border-right: 1px solid ${colors.gray4};
  background-color: ${colors.gray1};
  padding: 12px;
  ${({ w }) => w && `width : ${w}`};
`;

export const CostBox = styled.div`
  display: flex;
  border-left: 1px solid ${colors.gray4};
`;

export const SkySquare = styled(HeaderSquare)<{ p?: boolean }>`
  background-color: #eaf3ff;
  ${({ p }) => p && `padding : 6px;`};
`;

export const ManagementColumn = styled.div<{ w?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: #eaf3ff;
  border-right: 1px solid ${colors.gray4};
  padding: 12px 4px;
  p {
    /* width: calc(100% - 28px); */
    text-align: center;
    ${({ w }) => (w ? `width: calc(100% - 28px)` : `width : 100%`)};
  }
`;

export const CalcIconBox = styled.div`
  border-radius: 4px;
  background-color: ${colors.mainBlue};
  color: ${colors.whiteColor};
  padding: 4px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 4%;
  transform: translateY(-50%);
`;

export const Square = styled(HeaderSquare)<{ isManage?: boolean }>`
  background-color: ${colors.whiteColor};
  text-align: ${({ isManage }) => (isManage ? "center" : "left")};
  span {
    margin-right: 4px;
  }
`;

export const ActionBox = styled.div`
  background-color: ${colors.whiteColor};
  border-radius: 16px;
  margin-top: 8px;
  padding: 24px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const RentalInput = styled(Input)`
  width: 100%;
  height: auto;
  border: 1px solid ${colors.gray2};
  border-radius: 0;
  background-color: ${colors.whiteColor};
  padding: 4px;
  text-align: right;
  &:focus-visible {
    border-color: ${colors.mainBlue};
  }
  :read-only {
    cursor: default;
    &:focus-visible {
      border-color: ${colors.gray2};
    }
  }
`;

export const CreatebillInfo = styled.ul`
  padding: 20px 24px;
  span {
    font-weight: 500;
    text-decoration: underline;
  }
`;

export const RentalSelect = styled(Select)`
  border: 1px solid ${colors.gray2};
  border-radius: 0;
  background-color: ${colors.whiteColor};
  padding: 5px;
  height: auto;
  width: 100%;
  &:focus {
    border: 1px solid ${colors.gray2};
  }
`;

//only maintenance -> 추후 합쳐도될듯 ? 시간 되면
export const CostSquare = styled(SkySquare)`
  border-left: none;
  background-color: rgba(219, 234, 254);
`;

export const TrangleSquare = styled(HeaderSquare)`
  padding: 4px;
  min-height: 48px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  span {
    width: 0px;
    height: 0px;
    border-bottom: 24px solid ${colors.gray2};
    border-left: 24px solid transparent;
    border-right: 0px solid ${colors.gray2};
  }
`;

export const LetterDetails = styled(CostBox)`
  border-bottom: 1px solid ${colors.gray2};
  > div {
    width: 184px;
    &:last-of-type {
      border-right: none;
    }
  }
`;

export const BillTable = styled.div`
  position: relative;
  overflow-x: scroll;
  width: 100%;
  > div {
    display: flex;
    text-align: center;
  }
`;

export const FixedHead = styled.div<{ p?: number }>`
  z-index: 50;
  top: 0;
  left: 0;
  ${({ p }) =>
    p ? `position: absolute; transform: translate3d(0px,${p - 278}px, 0px)` : "transform: translate3d(0px, 0px, 0px)"};
`;

export const BillStickyBox = styled.div<{ w?: boolean }>`
  display: flex;
  max-width: ${({ w }) => (w ? "904px" : "552px")};
  min-width: ${({ w }) => (w ? "904px" : "552px")};
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  border-bottom: 1px solid ${colors.gray2};
  border-right: 1px solid ${colors.gray4};
`;
//

//only rent page
export const TableBox = styled.div<{ w?: boolean }>`
  /* border-top: 1px solid ${colors.gray4}; */
  width: ${({ w }) => (w ? "5%" : "4%")};
  border-right: 1px solid ${colors.gray4};
  vertical-align: bottom;
`;

export const TableHeader = styled.div<{ w?: string }>`
  width: 100%;
  background-color: ${colors.gray1};
  border-top: 1px solid ${colors.gray4};
  border-bottom: 1px solid ${colors.gray4};
  display: table;
  text-align: center;
  > div {
    display: table-cell;
  }
`;

export const TrangleGraySquare = styled(HeaderSquare)`
  padding: 4px;
  display: flex;
  border-right: none;
  justify-content: flex-end;
  align-items: flex-end;
  div {
    width: 0px;
    height: 0px;
    border-bottom: 24px solid ${colors.gray2};
    border-left: 24px solid transparent;
    border-right: 0px solid ${colors.gray2};
  }
`;

export const TableBodyBox = styled.div`
  display: table;
  > div {
    border-bottom: 1px solid ${colors.gray2};
    display: table-cell;
    vertical-align: middle;
    word-break: normal;
  }
`;
//

export const RentWarpper = styled(Wrapper)<{
  auth?: string;
}>`
  //일단 지금은 여기, 자주 쓰이면 옮김
  overflow-y: scroll;
  transform: translate3d(0px);
  min-height: ${({ auth }) => (auth === "edit" ? "calc(100% - 100px)" : "100%")};
  ${({ auth }) => auth === "edit" && "height:calc(100% - 100px)"};
`;
