import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import IconArrow from "../../../components/icons/IconArrow";
import * as Icon from "solar-icon-set";
import { twMerge } from "tailwind-merge";
import IconCake from "../../../components/icons/IconCake";

// 가입회원 테스트 데이터
const testMember = {
  id: 1,
  name: "홍길동",
  phoneNumber: "010-1234-1234",
  email: "hong@email.com",
  nickname: "길동이",
  birth: "1990.01.10",
};

export default function MemberDetailLayout() {
  const { memberId } = useParams();
  const { pathname } = useLocation();

  return (
    <main className="flex h-full flex-col">
      <div className="flex h-10 items-center border-b border-gray-200 px-6 py-1.5">
        <span className="text-xs font-medium text-gray-500">회원관리</span>
        <div className="px-1">
          <IconArrow />
        </div>
        <span className="text-xs font-medium text-gray-500">인증대기</span>
        <div className="px-1">
          <IconArrow />
        </div>
        <span className="text-xs font-medium text-gray-500">홍길동</span>
      </div>
      <div className="flex px-6 py-5">
        <div className="flex gap-4">
          <div
            className={twMerge(
              "flex h-[62px] w-[62px] items-center justify-center rounded-2xl bg-green-500",
              testMember.id !== 1 && "bg-red-100",
            )}
          >
            <Icon.UserRounded size={24} iconStyle="Bold" color={testMember.id === 1 ? "#F0FDF4" : "#FFFFFF"} />
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex gap-2">
              <span className="text-xl font-bold leading-normal">{testMember.name}</span>
              <span
                className={twMerge(
                  "text-xl font-bold leading-normal text-green-500",
                  testMember.id !== 1 && "text-red-500",
                )}
              >
                {testMember.id === 1 ? "인증된 회원" : "미가입한 회원"}
              </span>
            </div>
            <div className="flex items-start gap-5">
              <div className="flex items-center gap-1">
                <Icon.Phone color="#374151" />
                <span className="text-sm font-medium text-gray-900">
                  {testMember.phoneNumber + (testMember.id !== 1 ? " (미인증)" : "")}
                </span>
              </div>
              <div className="flex items-center gap-1">
                <Icon.Letter color="#374151" />
                <span className={twMerge("text-sm font-medium text-gray-900", testMember.id !== 1 && "text-gray-300")}>
                  {testMember.id === 1 ? testMember.email : "정보없음"}
                </span>
              </div>
              <div className="flex items-center gap-1">
                <Icon.SmileCircle color="#374151" />
                <span className={twMerge("text-sm font-medium text-gray-900", testMember.id !== 1 && "text-gray-300")}>
                  {testMember.id === 1 ? testMember.nickname : "정보없음"}
                </span>
              </div>
              <div className="flex items-center gap-1">
                <IconCake />
                <span className={twMerge("text-sm font-medium text-gray-900", testMember.id !== 1 && "text-gray-300")}>
                  {testMember.id === 1 ? testMember.birth : "정보없음"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-8 border-b border-gray-200 px-6">
        <Link
          className={twMerge(
            "flex items-center border-gray-700 px-1 py-4 text-sm font-medium text-gray-400 transition-all",
            pathname === `/members/${memberId}/companies` && "border-b  text-gray-700",
          )}
          to={`/members/${memberId}/companies`}
          replace
        >
          소속된 회사
        </Link>
        <Link
          className={twMerge(
            "flex items-center border-gray-700 px-1 py-4 text-sm font-medium text-gray-400 transition-all",
            pathname === `/members/${memberId}/rooms` && "border-b  text-gray-700",
          )}
          to={`/members/${memberId}/rooms`}
          replace
        >
          소유하고 있는 호실
        </Link>
      </div>
      <Outlet />
    </main>
  );
}
