import { IoClose } from "@react-icons/all-files/io5/IoClose";
import { Button, Flex, Select, Switch, Text, useToast } from "@chakra-ui/react";
import { ChangeEvent, memo, useCallback, useEffect, useRef, useState } from "react";
import * as S from "../styled";
import * as C from "../../../../components/styled/index";
import { v4 as uuidv4 } from "uuid";
import MoreContentInputs from "../../../../components/Input/contents";
import { fetchClient } from "../../../../lib/api/axios";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { upload_file } from "../../../../lib/api/queries/urls";
import { checkFileValidate, warning } from "../../../../lib/theme/toast";
import { checkFileValidation, limitTitle } from "../../../../lib/utils/format";
import { IQuestion } from "../types";
import { ISurveyAnswer } from "../../../../lib/types/Imodels";
import { MemoizedAnswer } from "./AnswerItems";

const QuestionItems = (props: IQuestion) => {
  const toast = useToast();
  // const { inputs, setInputs, onDeleteQuestion } = useContext(SurveyContext);
  const [division, setDivision] = useState("single_choice"); //essay_question, multiple_choice
  const [required, setRequired] = useState(props.num === 1 ? true : false);
  const [title, setTitle] = useState("");
  const [response, setResponse] = useState<ISurveyAnswer[]>(props.el.answers);
  // const [answerArr, setAnswerArr] = useState([
  //   <AnswerItems key={uuidv4()} id={initial} num={1} isEdit={props.isEdit} />,
  // ]);
  // const [newObj, setNewObj] = useState<QuestionBox>();
  const fileRef = useRef<HTMLInputElement>(null);
  const [fileUrl, setFileUrl] = useState("");

  useEffect(() => {
    setRequired(props.num === 1 ? true : false);
    props.onChangeRequired(props.num === 1 ? true : false, props.el);
  }, [props.num]);

  useEffect(() => {
    if (props.isEdit === undefined) return setResponse(props.el.answers);
    if (!props.el) return;

    setDivision(props.el.question_type);
    setRequired(props.el.is_required);
    setTitle(props.el.question);
    props.el?.image && setFileUrl(props.el?.image);
    // if (props.el.answer_num !== undefined) setNum(props.el.answer_num);
    if (props.el.answers.length !== 0) {
      const newArr = props.el.answers.map((el, idx) => {
        el._id = String(idx);
        return el;
      });
      setResponse(newArr);
    }
  }, [props.isEdit, props.el]);

  const onAddAnswer = () => {
    // const newArr = [...answerArr];
    const newArr = [...response];
    const newId = uuidv4();
    // newArr.push(
    //   <AnswerItems key={uuidv4()} id={newId} num={answerArr.length + 1} />
    // );
    // setAnswerArr(newArr);
    // const newInput = [...response];
    newArr.push({
      _id: newId,
      answer: "",
      participated_num: 0,
    });
    setResponse(newArr);
    props.onChangeAnswers(newArr, props.el);
  };

  const onGetFileUrl = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file === undefined) return;
    const isVaild = checkFileValidation(file);
    if (!isVaild) return toast({ ...checkFileValidate });

    const formData = new FormData();
    formData.append("body_files", file);

    try {
      const newArr = await fetchClient.post<BaseResponse<string[]>>(upload_file, formData);
      setFileUrl(newArr.data.data?.[0]);
      props.onChangeValues(newArr.data.data?.[0], props.el, "image");
    } catch (err) {
      console.log(err);
    }

    e.target.value = "";
  };

  const onDeleteAnswer = (idx: string) => {
    if (response.length === 1)
      return toast({
        ...warning,
        title: "응답 삭제 실패",
        description: "최소 1개 이상의 응답을 등록해야합니다.",
      });
    // const newArr = answerArr.filter((el) => el.props.id !== idx);
    // setAnswerArr(newArr);
    const newInput = response.filter((el) => el._id !== idx);
    setResponse(newInput);
    props.onChangeAnswers(newInput, props.el);
  };

  const onChangeDivision = (e: ChangeEvent<HTMLSelectElement>) => {
    const newQuestion = { ...props.el };
    const initial = {
      _id: uuidv4(),
      answer: "",
      participated_num: 0,
    };
    setDivision(e.target.value);

    if (e.target.value === "essay_question") {
      newQuestion.answers = [];
      props.onChangeValues(e.target.value, newQuestion, "question_type");
    } else if (division === "essay_question" && e.target.value !== "essay_question") {
      const newQuestion = { ...props.el };
      newQuestion.answers = [initial];
      setResponse([initial]);
      props.onChangeValues(e.target.value, newQuestion, "question_type");
    } else {
      // setResponse(props.el);
      props.onChangeValues(e.target.value, props.el, "question_type");
    }
  };

  const onChangeAnswer = useCallback(
    (str: string, ele: ISurveyAnswer, _id: "answer" | "image") => {
      const newObj = { ...ele };
      newObj[_id] = str;

      const newArr = response.map((el) => (el._id === newObj._id ? newObj : el));
      setResponse(newArr);
      props.onChangeAnswers(newArr, props.el);
    },
    [response],
  );

  return (
    // <QuestionContext.Provider value={{ response, setResponse, onDeleteAnswer }}>
    <C.NewPost>
      <C.PostTitle>질문 {props.num}</C.PostTitle>
      <Flex w="100%" flexDirection="column">
        <S.CategoryBox>
          <Text minW="100px">구분</Text>
          <Select w="180px" variant="default" value={division} onChange={onChangeDivision}>
            <option value="single_choice">단일 선택</option>
            <option value="multiple_choice">복수 선택</option>
            <option value="essay_question">주관식</option>
          </Select>
          {/* {division === "multiple_choice" && (
              <Flex>
              <SmallInputs
                  onChange={(e) => setNum(Number(e.target.value))}
                  placeholder="응답 개수를 숫자로 입력"
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                  value={num}
                  style={{
                    minWidth: "180px",
                    margin: "0 0 0 12px",
                    padding: "10px 12px",
                  }}
                />
                <Select w="100px" ml="0" variant="fitContent">
                  <option>이하</option>
                  <option>이상</option>
                </Select>
              </Flex>
            )} */}
          <S.CloseBox>
            <IoClose className="react-icon-static" onClick={() => props.onDeleteQuestion(String(props.el?._id))} />
          </S.CloseBox>
        </S.CategoryBox>
        <S.CategoryBox style={{ padding: "12px 0" }}>
          <Text minW="100px">필수</Text>
          <Switch
            isDisabled={props.num === 1 ? true : false}
            _disabled={{ span: { opacity: 1 } }}
            isChecked={required}
            onChange={(e) => {
              setRequired(e.target.checked);
              props.onChangeRequired(e.target.checked, props.el);
            }}
          />
        </S.CategoryBox>
        <S.CategoryBox>
          <Text minW="100px">질문</Text>
          <Flex w="100%">
            <MoreContentInputs
              maxLength={100}
              onChange={(e) => {
                const isLimit = limitTitle(e.target.value);
                if (!isLimit) return alert("질문은 최대 100자까지 입력할 수 있습니다.");
                setTitle(e.target.value);
                // props.onChangeValues(e.target.value, props.el, "question");
              }}
              onBlur={(e) => {
                // if (e.target.value === title) return;
                props.onChangeValues(e.target.value, props.el, "question");
              }}
              value={title}
              placeholder="질문 내용을 입력하세요"
            />
            <input type="file" style={{ display: "none" }} onChange={onGetFileUrl} ref={fileRef} />
            <Button onClick={() => fileRef.current?.click()} variant="basic">
              사진선택
            </Button>
          </Flex>
        </S.CategoryBox>
        {fileUrl !== "" && (
          <S.CategoryBox>
            <Text minW="100px" />
            <S.SurveyImage>
              <img src={fileUrl} alt="질문 이미지" />
              <C.DeleteIcon onClick={() => setFileUrl("")} />
            </S.SurveyImage>
          </S.CategoryBox>
        )}
        {division !== "essay_question" && (
          <>
            {response.map((el, idx) => (
              <MemoizedAnswer
                key={el._id}
                el={el}
                isEdit={props.isEdit}
                onDeleteAnswer={onDeleteAnswer}
                num={idx + 1}
                onChangeAnswer={onChangeAnswer}
              />
            ))}
            {/* {answerArr.map((el) => el)} */}
            <S.CategoryBox>
              <Text minW="100px"></Text>
              <Button variant="basic" onClick={onAddAnswer}>
                응답 추가
              </Button>
            </S.CategoryBox>
          </>
        )}
      </Flex>
    </C.NewPost>
    // </QuestionContext.Provider>
  );
};

export const MemoizedQuestion = memo(QuestionItems);
