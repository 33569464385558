import { HTMLAttributes } from "react";

export type Props = {
  isChecked: boolean;
  className?: string;
} & HTMLAttributes<HTMLOrSVGElement>;

export default function IconCheckbox(props: Props) {
  return props.isChecked ? (
    <svg
      className={props.className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5172 8.4569C16.8172 8.74256 16.8288 9.2173 16.5431 9.51724L10.8288 15.5172C10.6873 15.6659 10.491 15.75 10.2857 15.75C10.0805 15.75 9.88417 15.6659 9.74261 15.5172L7.4569 13.1172C7.17123 12.8173 7.18281 12.3426 7.48276 12.0569C7.78271 11.7712 8.25744 11.7828 8.5431 12.0828L10.2857 13.9125L15.4569 8.48276C15.7426 8.18281 16.2173 8.17123 16.5172 8.4569Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM16.5431 9.51724C16.8288 9.2173 16.8172 8.74256 16.5172 8.4569C16.2173 8.17123 15.7426 8.18281 15.4569 8.48276L10.2857 13.9125L8.54311 12.0828C8.25744 11.7828 7.78271 11.7712 7.48276 12.0569C7.18281 12.3426 7.17123 12.8173 7.4569 13.1172L9.74261 15.5172C9.88417 15.6659 10.0805 15.75 10.2857 15.75C10.491 15.75 10.6873 15.6659 10.8288 15.5172L16.5431 9.51724Z"
        fill="#2563EB"
      />
    </svg>
  ) : (
    <svg
      className={props.className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="2" width="20" height="20" rx="5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3.5H7C5.067 3.5 3.5 5.067 3.5 7V17C3.5 18.933 5.067 20.5 7 20.5H17C18.933 20.5 20.5 18.933 20.5 17V7C20.5 5.067 18.933 3.5 17 3.5ZM7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7Z"
        fill="#D1D5DB"
      />
    </svg>
  );
}
