/* eslint-disable no-constant-condition */
import { ChangeEvent, useCallback, useContext, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import CustomComboBox from "../../components/CustomComboBox";
import { useRoomsQuery } from "../../requests/room";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../../components/custom/Pagination";
import Input from "../../components/Input/Input";
import IconSearch from "../../components/icons/IconSearch";
import Button from "../../components/button/Button";
import IconWhitePlus from "../../components/icons/IconWhitePlus";
import Icon from "../../types/solar-icon";
import { useBuildingFloor } from "../../lib/api/queries/commons";
import formatRoomType from "../../utils/foramatRoomType";
import formatPhoneNumber from "../../utils/formatPhoneNumber";
import { Button as ButtonA, Spinner, Text, useDisclosure, useToast } from "@chakra-ui/react";
import useUserAuth from "../../components/hooks/useUserAuth";
import { success, unAuthUser, warning } from "../../lib/theme/toast";
import ConfirmModal from "../../components/modal/ConfirmModal";
import { useMutation } from "@tanstack/react-query";
import { fetchClient } from "../../lib/api/axios";
import { BaseResponse } from "../../lib/api/queries/commons/types";
import { room_list_or_detail } from "../../lib/api/queries/keys";
import { whole_owner_create } from "../../lib/api/queries/urls";
import { queryClient } from "../..";
import * as S from "../../pages/company/settings/units/styled";
import MoreContentInputs from "../../components/Input/contents";
import { fileValidation } from "../../lib/utils/format";
import SendToOwnerModal from "../company/components/Modal/SendToOwnerModal";
import { useDebounce } from "../../hooks/operators/use-debounce";
import { GlobalContext } from "../../App";
import FullPageSpinner from "../../components/Spinner";
import CustomHeading from "../../components/CustomHeading";
import GetAuthorized from "../../components/Authrized";

export default function RoomListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { buildInfo } = useContext(GlobalContext);

  const params = new URLSearchParams(search);
  const auth = useUserAuth("tenant");
  const isEdit = auth === "edit";

  const searchParamsObject = {
    buildingId: buildInfo?._id.toString() || "",
    searchText: searchParams.get("searchText") || "",
    roomType: searchParams.get("roomType") as "office" | "store" | "residential" | "etc",
    dong: searchParams.get("dong") || "",
    floor: searchParams.get("floor") || "",
    page: Number(searchParams.get("page")) || 1,
    limit: Number(searchParams.get("limit")) || 10,
  };

  const { data, isLoading } = useRoomsQuery(searchParamsObject, !!buildInfo?._id);

  const floorInfo = useBuildingFloor();

  // useRoomType은 owners 소유자가 존재해서 만든 함수
  const getRoomType = () => {
    if (buildInfo?.building_type === "knowledge_industry_center")
      return [
        { name: "사무", value: "office" },
        { name: "상가", value: "store" },
        { name: "기숙사", value: "residential" },
        { name: "창고", value: "etc" },
      ];
    if (buildInfo?.building_type === "rental_building")
      return [
        { name: "사무", value: "office" },
        { name: "상가", value: "store" },
      ];
    return [{ name: "사무", value: "office" }];
  };

  const tabs = [
    {
      name: "전체",
      value: "",
    },
    ...getRoomType(),
  ];

  const dongs = [
    {
      name: "동",
      value: "",
    },
    ...(buildInfo?.dong_floor?.map((item) => ({
      name: item.dong + "동",
      value: item.dong,
    })) ?? []),
  ];

  const floors = [
    {
      name: "층",
      value: "",
    },
    ...(searchParamsObject.dong === ""
      ? floorInfo.data?.map((item) => ({
          name: item + "층",
          value: item,
        })) ?? []
      : buildInfo?.dong_floor
          .filter((item) => item.dong === searchParamsObject.dong)[0]
          ?.floor.map((floorItem) => ({
            name: floorItem + "층",
            value: floorItem,
          })) ?? []),
  ];

  const createQueryString = useCallback(
    (key: string, value: string, deleteKey?: string[]) => {
      params.set(key, value);
      if (value === "") params.delete(key);
      deleteKey && deleteKey.forEach((key) => params.delete(key));
      params.delete("page");
      return params.toString();
    },
    [search],
  );

  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleTextChange = useDebounce(
    (text) => {
      navigate(pathname + "?" + createQueryString("searchText", text));
    },
    300,
    [search],
  );

  // 임시 소유자 일괄 등록 모달 롤백
  const { isOpen: isReg, onClose: offReg, onOpen: onReg } = useDisclosure(); //일괄 등록 모달
  const { isOpen, onClose, onOpen } = useDisclosure(); //가입 요청 모달
  const [file, setFile] = useState<File | null>(null);

  const fileRef = useRef<HTMLInputElement>(null);

  const toast = useToast();

  const onResetState = () => {
    //초기화
    // setTypes("");
    // setDong("");
    // setFloor("");
    // setStatus("");
    // setHo("");
    // setAccount("");
    if (searchParamsObject.page !== 1) setSearchParams({ page: "1" });
  };

  const onUploadOwnerFile = () => {
    if (auth !== "edit") return toast({ ...unAuthUser });
    setFile(null);
    onReg();
  };

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file === undefined) return;
    const valid = fileValidation(file);
    if (!valid)
      return toast({
        ...warning,
        title: "파일 업로드 오류",
        description: "일괄 등록을 위한 파일은 xlsx 형식만 업로드 가능합니다. 파일 확인 후 다시 시도해주세요.",
      });

    setFile(file);
    e.target.value = "";
  };

  const mutation = useMutation((formdata: FormData) =>
    fetchClient.post<BaseResponse<string[]>>(whole_owner_create, formdata).then((res) => {
      onResetState();
      searchParamsObject.page !== 1 && queryClient.invalidateQueries([room_list_or_detail, "list"]);
      toast({
        ...success,
        title: "소유자 일괄 등록 완료",
        description: "소유자 일괄 등록이 완료되었습니다.",
      });
      if (res.data.data.length === 0) {
        offReg();
      } else {
        onOpen();
        // setRoomIds(res.data.data);
      }
      return res.data.data;
    }),
  );

  const onRegisterOwner = () => {
    if (auth !== "edit") return toast({ ...unAuthUser });
    if (file === null) {
      return toast({
        ...warning,
        title: "소유자 일괄등록 오류",
        description: "일괄 등록을 위한 파일을 먼저 업로드 후 등록해주세요.",
      });
    }

    const formdata = new FormData();
    formdata.append("room_files", file, file.name);
    buildInfo?._id && formdata.append("building_id", String(buildInfo._id));

    mutation.mutate(formdata);
  };

  // 임시 일괄 초대 모달
  const beforeSendMessage = () => {
    if (auth !== "edit") return toast({ ...unAuthUser });

    onOpen();
  };

  if (auth === "no_auth") return <GetAuthorized />;

  return (
    <div className="mb-[60px] w-full">
      <CustomHeading headings="호실관리" sizeTheme="md" />
      <div className="flex h-12 gap-6 border-b border-gray-200 px-6">
        {tabs.map((item, index) => (
          <button
            key={index}
            className={twMerge(
              "text-base font-semibold text-gray-400",
              (params.get("roomType") === null ? "" : params.get("roomType")) === item.value &&
                "border-b-2 border-gray-900 text-black",
            )}
            onClick={() => {
              if (searchInputRef.current) searchInputRef.current.value = "";
              navigate(pathname + "?" + createQueryString("roomType", item.value, ["dong", "floor", "searchText"]));
            }}
          >
            {item.name}
          </button>
        ))}
      </div>
      {/* 임시 소유자 일괄 등록 모달 */}
      {isEdit && (
        <ConfirmModal
          isOpen={isReg}
          onClose={offReg}
          onClickSave={onRegisterOwner}
          onClickCancle={offReg}
          title="소유자 일괄등록"
          close="등록하기"
          blockScrollOnMount={false}
          styleConfig={{ marginTop: "20px" }}
        >
          {mutation.isLoading ? (
            <S.SpinBox>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
                position="absolute"
                style={{ top: "40%", left: "45%" }}
              />
            </S.SpinBox>
          ) : (
            <>
              <Text>소유자 정보를 일괄 등록하기 위해 소유자의 성함과 연락처가 포함된 파일을 등록해주세요.</Text>
              <S.NoticeList>
                <li>일괄 등록 시 소유자 정보가 없는 호실에만 등록됩니다.</li>
                <li>이미 소유자 정보가 있는 호실은 개별 호실 정보에서 수정해주세요.</li>
              </S.NoticeList>
              <S.UploadFileBox>
                <MoreContentInputs isReadOnly value={file?.name || ""} />
                <ButtonA variant="basic" onClick={() => fileRef.current?.click()}>
                  파일 선택
                </ButtonA>
                <input type="file" multiple={false} ref={fileRef} style={{ display: "none" }} onChange={onChangeFile} />
              </S.UploadFileBox>
            </>
          )}
        </ConfirmModal>
      )}
      <div className="flex h-[60px] w-full items-center gap-6 border-b border-gray-200 bg-gray-50 px-6">
        <div className="flex w-[21.75rem] gap-3">
          <CustomComboBox
            data={dongs}
            getValue={() => searchParamsObject.dong || dongs[0].value}
            onChange={(data) =>
              data !== null && navigate(pathname + "?" + createQueryString("dong", data.value, ["floor"]))
            }
          />
          <CustomComboBox
            data={floors}
            getValue={() => searchParamsObject.floor || floors[0].value}
            onChange={(data) => data !== null && navigate(pathname + "?" + createQueryString("floor", data.value))}
          />
        </div>

        <div className="flex flex-1 items-center justify-end gap-6">
          <div className="flex max-w-[27.75rem] flex-1">
            {/* 우선 호실만 검색되게 */}
            <Input
              ref={searchInputRef}
              themeSize="sm"
              placeholder="호실, 대표소유자 전화번호"
              defaultValue={searchParamsObject.searchText}
              iconElement={<IconSearch className="h-5 w-5" />}
              onChange={(e) => handleTextChange(e.target.value)}
              maxLength={20}
              showDeleteButton={true}
              onDelete={() => {
                if (searchInputRef.current) searchInputRef.current.value = "";
                navigate(pathname + "?" + createQueryString("searchText", ""));
              }}
            />
          </div>
          {isEdit && (
            <div className="flex items-center gap-3">
              <Button
                designSchema="secondaryBlue"
                iconElement={<Icon.UploadMinimalistic iconStyle="Outline" />}
                buttonText="소유자 일괄등록"
                size="sm"
                // onClick={() => navigate("/company/room/create")}
                onClick={onUploadOwnerFile}
              />
              <Button
                designSchema="primary"
                iconElement={<IconWhitePlus className="h-4 w-4" />}
                buttonText="신규 호실 추가"
                size="sm"
                onClick={() => navigate("/rooms/create")}
              />
            </div>
          )}
        </div>
      </div>
      <div className="w-full px-6">
        <table className="w-full table-fixed">
          <thead>
            <tr className="border-b border-gray-200">
              <th className="w-1/5 px-2 py-3 text-left leading-normal">동 층 호</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">호실용도</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">입주사 정보</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">대표 소유자 정보 (매칭상태)</th>
            </tr>
          </thead>
          <tbody>
            {data?.data.rooms.map((item) => {
              return (
                <tr
                  key={item._id}
                  // onClick={() => navigate(`/companies/${item._id}/info`)}
                  // 호실 상세 롤백
                  onClick={() => navigate(`/rooms/modify/${item._id}`)}
                  className="cursor-pointer border-y border-gray-200 transition-all duration-75 hover:bg-blue-50 hover:opacity-75"
                >
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">
                    {item.dong}동 {item.floor}층 {item.ho}호
                  </td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">{formatRoomType(item.roomType)}</td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">
                    {item.companyInfo.isCompany ? item.companyInfo.name : <span className="text-gray-400">공실</span>}
                  </td>
                  <td className="items-center px-2 py-3 text-sm leading-normal text-gray-900">
                    {item.owners[0] && item.owners[0].name ? (
                      <div className="flex items-center gap-2">
                        <span
                          className={twMerge("flex leading-4 text-gray-300", item.owners[0].userId && "text-green-500")}
                        >
                          <Icon.ShieldCheck iconStyle="Bold" size={20} />
                        </span>
                        {`${item.owners[0].name} (${formatPhoneNumber(item.owners[0].phoneNumber ?? "")})`}
                      </div>
                    ) : (
                      <span className="text-gray-400">미등록</span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {isLoading ? (
          <FullPageSpinner />
        ) : (
          <>
            {data?.data.rooms.length === 0 ? (
              <div className="flex h-[25rem] flex-col items-center justify-center gap-4 text-gray-700">
                <Icon.DangerCircle size={40} />
                {searchParamsObject.searchText === "" &&
                searchParamsObject.dong === "" &&
                searchParamsObject.floor === "" ? (
                  <>
                    <span className="text-xl font-medium leading-normal">현재 등록된 호실이 없습니다.</span>
                    <span className="font-medium leading-normal text-gray-400">호실을 등록해주세요.</span>
                  </>
                ) : (
                  <>
                    <span className="text-xl font-medium leading-normal">검색 결과가 없습니다.</span>
                    <span className="font-medium leading-normal text-gray-400">
                      다른 검색어를 시도해 보거나 검색 필터를 삭제하세요.
                    </span>
                  </>
                )}
              </div>
            ) : (
              <div className="mt-3 flex items-center justify-between">
                <div className="flex gap-0.5">
                  {auth === "edit" && buildInfo?.building_type === "knowledge_industry_center" && (
                    <>
                      <Button
                        designSchema="neutral"
                        iconElement={<Icon.Plain />}
                        size="sm"
                        buttonText="가입요청보내기"
                        // 임시 일괄 가입 초대 모달
                        onClick={beforeSendMessage}
                      />
                      {/* 일괄 가입 초대 모달 */}
                      <SendToOwnerModal isOpen={isOpen} onClose={onClose} />
                    </>
                  )}
                </div>
                <div className="flex items-center gap-3">
                  <p className="text-base leading-normal text-gray-500">총 {data?.data.totalCount || 0}개의 결과</p>
                  <Pagination
                    currentPage={searchParamsObject.page}
                    itemCount={data?.data.totalCount || 0}
                    showPageAmount={10}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
