import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ModalProps } from "@chakra-ui/react";
import Button from "../button/Button";

type Props = {
  onClickCancel?: () => void;
  onClickSave?: () => void;
  blockScrollOnMount?: boolean;
  title?: string;
  width?: number;
  confirmButtonText?: string;
} & ModalProps;

export default function CancelModal({ children, width = 312, ...props }: Props) {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      blockScrollOnMount={props.blockScrollOnMount}
      closeOnOverlayClick={props.closeOnOverlayClick}
      isCentered={props.isCentered || true}
    >
      <ModalOverlay />
      <ModalContent padding={6} width={width} rounded={20}>
        <ModalHeader padding={0} fontSize={20} fontWeight={500} marginBottom={3}>
          <h4>{props.title || "삭제"}</h4>
        </ModalHeader>
        <ModalBody padding={0} fontSize={14} marginBottom={6}>
          {children}
        </ModalBody>
        <ModalFooter padding={0} gap={2} marginTop={3}>
          <Button
            onClick={() => {
              if (!props.onClickCancel) props.onClose();
              props.onClickCancel && props.onClickCancel();
            }}
            className="flex-1"
            designSchema={"neutral"}
            buttonText={"취소"}
            size={"md"}
          />
          <Button
            onClick={props.onClickSave}
            className="flex-1"
            designSchema={"warning"}
            buttonText={props.confirmButtonText || "삭제"}
            size={"md"}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
