import { useToast } from "@chakra-ui/react";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../App";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import { colors } from "../../../../components/styled/common";
import { warning } from "../../../../lib/theme/toast";
import { ITenantFeeDetail } from "../../../../lib/types/Imodels";
import { format_point } from "../../../../lib/utils/format";
import { CalcBox, ChildWrapBox, DifferenceBox, DirectInput } from "./styled";
import { IUsage, IRoomUsage } from "./types";
import { format_method, onChangePrice, onChangeUsage, total_price, usage_obj } from "./utils";

export default function CalculateFeeInModal(rest: IRoomUsage) {
  const { calculate, isEdit, ...props } = rest;
  const decimal_point = /^(\d*)\.?(\d{1,2})?$/g;

  const toast = useToast();
  const { buildInfo } = useContext(GlobalContext);
  const [usage, setUsage] = useState<IUsage[]>([]);
  const [allUsage, setAllUsage] = useState<string>("");
  const [allPrice, setAllPrice] = useState(0);
  const [difference, setDifference] = useState(0);
  const [tenantArea, setTenantArea] = useState(0); //입주 호실 비율
  // const {onChangeByRoomUsage} = onChangeUsage()
  // const {1번_계산식, 2번_계산식, 3번_계산식} = useCustomHook()
  // const {1번_계산_onChange, 2번_계산_onChange, 3번_계산_onChange} = useCustomHook()
  // const edited_usage = usage_obj(calculate.calculation_method);
  const entered_price = total_price(calculate.calculation_method);
  const diff = difference - allPrice;

  useEffect(() => {
    // const newArr: IUsage[] = [];
    let sum = 0;

    const byUsage = sessionStorage.getItem(calculate.key);
    let newArr: IUsage[] = [];
    if (byUsage) {
      newArr = JSON.parse(byUsage);
      newArr.forEach((el) => el.area && (sum = sum + el.area));
    } else {
      props.data.forEach((el) => {
        const idx = el.items.findIndex((item) => item.key === calculate.key);
        const individual = el.items[idx].use || "0";
        if (!el.tenant_id) return;
        const newObj: IUsage = {
          _id: el.room_id,
          count: isEdit ? individual || "" : "",
          price: parseFloat(el.items[idx].value) || 0,
        };
        if (calculate.calculation_method === "by_area") {
          newObj.area = el.area || 0;
          el.area && (sum = sum + el.area);
        }
        newArr.push(newObj);
      });
    }
    if (!byUsage && calculate.calculation_method === "by_use") {
      const all_use = newArr.reduce((cur, acc) => cur + parseFloat(acc.count ? acc.count : "0"), 0);
      if (all_use >= 1)
        newArr.forEach(
          (el) => (el.percentage = isEdit ? parseFloat(((parseFloat(el.count) * 100) / all_use).toFixed(4)) : 0),
        );
    }
    setUsage(newArr);
    sum && setTenantArea(sum); //면적별에서만 사용

    const cost = newArr.reduce((cur, acc) => cur + parseFloat(acc.count ? acc.count : "0"), 0);
    setAllUsage(cost ? cost.toFixed(2) : "");
    const savedPrice = sessionStorage.getItem(entered_price + "_" + calculate.key);
    savedPrice
      ? setAllPrice(Number(savedPrice))
      : setAllPrice(Math.round(newArr.reduce((cur, acc) => cur + acc.price, 0))); //저장된 값있으면 보여주고, 수정이 아닌 이상 0, 수정일 경우 소수점 반올림
    setDifference(newArr.reduce((cur, acc) => cur + Math.ceil(acc.price), 0)); //수정일 경우 올림 처리
  }, []);

  const onCalculationPrice = () => {
    //소수점 0.1 아래 입력이거나 총 요금이 없거나
    if (parseFloat(allUsage) < 0.1 || !allPrice)
      return toast({
        ...warning,
        title: "알림",
        description: "관리비 반영을 위해 전체 사용량과 요금을 정확히 입력해주세요.",
      });

    // const result: ITenantFeeDetail[] = [];
    const test: ITenantFeeDetail[] = [...props.data];
    console.log("clicked ======> ", test, props.data);

    usage.forEach((el) => {
      const arr = [...props.data];
      const data = arr.find((ele) => el._id === ele.room_id);
      if (!data) return;
      // const newSet = [...data.items];
      const result = data.items.map((item) => {
        const newEl = { ...item };
        if (newEl.key === calculate.key) {
          newEl.value = String(el.price);
          newEl.use = String(el.count);
          return newEl;
        }
        return item;
      });
      props.onChangeCharge(data, result);
    });

    sessionStorage.setItem(calculate.key, JSON.stringify(usage)); // row data type [ {호실정보, 사용량, 금액}] 정보
    sessionStorage.setItem(entered_price + "_" + calculate.key, String(allPrice));
    props.onClose();
  };

  const onChangeIndividualUsage = (e: ChangeEvent<HTMLInputElement>, str: string) => {
    if (calculate.calculation_method !== "by_use") return; //사용량별 요금 아니면 early return;
    if (e.target.value.length > 9) return; // 9자리 이상 작성 X
    if (!decimal_point.test(e.target.value)) return;
    const value = format_point(e.target.value);

    let changed = 0;
    const newArr = [...usage];
    const obj = newArr.find((el) => el._id === str);
    if (!obj) return;
    obj.count = value; //입주해있는 호실의 개별 사용량 추가

    const total = newArr.reduce((cur, acc) => cur + parseFloat(acc.count ? acc.count : "0"), 0);
    newArr.forEach((el) => {
      if (!el.count || !total) return (el.percentage = 0), (el.price = 0);
      //입주해있는 호실의 사용량 비율
      el.percentage = el.count ? parseFloat(((parseFloat(el.count) * 100) / total).toFixed(4)) : 0;
      if (allPrice && el.percentage)
        //총 요금이 있을 경우
        el.price = parseFloat((allPrice * (el.percentage / 100)).toFixed(4));
      changed = changed + Math.ceil(allPrice * (el.percentage / 100));
    });

    setAllUsage(total.toFixed(2));
    setUsage(newArr);
    setDifference(changed);
  };

  const onChangeAllUsage = (e: ChangeEvent<HTMLInputElement>) => {
    const allUseValue = e.target.value.replaceAll(",", "");

    if (allUseValue.length > 9) return; // 9자리 이상 작성 X
    if (!decimal_point.test(allUseValue)) return;
    // const value = String(parseFloat(e.target.value));
    const value = format_point(allUseValue);

    setAllUsage(value);
    const newArr = [...usage];
    const count = isNaN(parseFloat(allUseValue)) ? 0 : parseFloat(allUseValue);
    onChangeUsage({
      count,
      type: calculate.calculation_method,
      usage: newArr,
      tenantArea: calculate.calculation_method === "by_area" ? tenantArea : undefined,
      setUsage,
    });

    // if (calculate.calculation_method === "by_area") {
    //   newArr.forEach((el) => {
    //     if (el.area) {
    //       const percent = parseFloat(
    //         ((el.area * 100) / tenantArea).toFixed(2)
    //       );
    //       (el.count = (count * (percent / 100)).toFixed(2)), percent;
    //     }
    //   });
    // } else if (calculate.calculation_method === "by_room") {
    //   const calc = (count / newArr.length).toFixed(2); //소수점 처리 필요
    //   setUsage(
    //     newArr.map((el) => ({
    //       ...el,
    //       ...{ count: calc },
    //     }))
    //   );
    // }
  };

  const onChangeTotalPrice = (e: ChangeEvent<HTMLInputElement>) => {
    // if(!allUsage) return toast({...warning, title:"알림", description:"요금 계산을 "})
    const count = Number(e.target.value.replaceAll(",", ""));
    if (e.target.value.length > 11) return; // 9자리 이상(1억) 작성 X + 콤마(,)가 생겨서 9자리 + 2
    if (isNaN(count)) return;

    setAllPrice(count);
    const newArr = [...usage];
    // newArr.forEach((el) => {
    //   if (el.percentage) el.price = Math.round(count * (el.percentage / 100));
    // });
    // setUsage(newArr);
    onChangePrice({
      count,
      type: calculate.calculation_method,
      tenantArea: calculate.calculation_method === "by_area" ? tenantArea : undefined,
      usage: newArr,
      setUsage,
      setDifference,
    });
    // console.log("changed", props.data);
  };

  console.log(`${calculate.calculation_method} =====> `, usage);

  return (
    <ConfirmModal
      close="저장하기"
      isOpen={props.isOpen}
      onClose={props.onClose}
      onClickCancle={props.onClose}
      onClickSave={onCalculationPrice}
      title={calculate.key}
      styleConfig={{ marginTop: "36px" }}
      scrollBehavior="inside"
      style={{ marginTop: "5rem" }}
    >
      <h6>계산 방식 : {format_method(calculate.calculation_method)}</h6>
      <CalcBox>
        <ChildWrapBox>
          <div style={{ width: "40%" }}>구분</div>
          <div>사용량</div>
          <div style={{ justifyContent: "space-between" }}>
            요금
            {difference !== 0 && diff !== 0 && (
              <DifferenceBox>{Math.sign(diff) === 1 ? `+ ${diff}` : `- ${diff * -1}`}(원)</DifferenceBox>
            )}
          </div>
        </ChildWrapBox>
        <ChildWrapBox>
          <div style={{ width: "40%", color: colors.gray4 }}>전체 호실</div>
          <div>
            <DirectInput
              value={
                !allUsage && calculate.calculation_method === "by_use" ? "0.00" : Number(allUsage).toLocaleString("kr")
              }
              onChange={(e) => onChangeAllUsage(e)}
              readOnly={calculate.calculation_method === "by_use" ? true : false}
            />
          </div>
          <div>
            <DirectInput value={String(allPrice.toLocaleString("kr") || "")} onChange={(e) => onChangeTotalPrice(e)} />
          </div>
        </ChildWrapBox>
        {props.data.map((el) => (
          <ChildWrapBox key={el.room_id} isDisabled={!el.tenant_id}>
            <div style={{ width: "40%", color: colors.gray4 }}>
              {el.dong}동 {el.ho}호
              {calculate.calculation_method === "by_area"
                ? ` (${buildInfo?.total_area && el.area ? ((el.area * 100) / buildInfo.total_area).toFixed(2) : 0}%)`
                : ""}
            </div>
            <div>
              <DirectInput
                readOnly={calculate.calculation_method === "by_use" ? false : true}
                onChange={(e) => onChangeIndividualUsage(e, el.room_id)}
                value={
                  calculate.calculation_method === "by_use"
                    ? usage.find((use) => use._id === el.room_id)?.count || ""
                    : parseFloat(usage.find((use) => use._id === el.room_id)?.count || "0").toFixed(2)
                }
              />
            </div>
            <div>
              <DirectInput
                readOnly={true}
                value={Math.ceil(usage.find((use) => use._id === el.room_id)?.price || 0).toLocaleString("kr")}
              />
            </div>
          </ChildWrapBox>
        ))}
      </CalcBox>
    </ConfirmModal>
  );
}
