import { twMerge } from "tailwind-merge";

type Props = {
  title: string;
  number: number | undefined;
  onClick: () => void;
  disabled: boolean;
  hoverText: string;
};

export default function DashboardCard({ title, number, onClick, disabled, hoverText }: Props) {
  return (
    <div
      className={twMerge(
        "relative my-2.5 mr-5 flex h-[136px] w-[200px] cursor-pointer flex-col justify-between rounded-xl border border-gray-300 bg-white p-5",
        !disabled ? "bg-blue/95" : "bg-gray/95",
      )}
      onClick={onClick}
    >
      <div className="bg-gray/95 absolute bottom-0 left-0 flex h-full w-full items-center rounded-xl px-5 text-sm font-medium text-gray-500 opacity-0 transition-opacity hover:opacity-100 hover:backdrop-blur-md">
        {hoverText}
      </div>
      <div className="whitespace-pre-line text-sm font-medium leading-normal">
        <p>{title}</p>
      </div>
      <div
        className={twMerge(
          "flex w-full items-end justify-between text-2xl font-bold",
          !disabled ? "text-blue-500" : "text-gray-300",
        )}
      >
        {String(number || 0).padStart(2, "0")}
      </div>
    </div>
  );
}
