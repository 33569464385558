import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import {
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { PropsWithChildren } from "react";
import { ICategory } from "../../../../lib/types/Imodels";
import { useRoomType } from "../../../../lib/utils";

interface ISelectBox extends PropsWithChildren {
  default: string;
  checkedItems: string[];
  // setCheckedItems: Dispatch<SetStateAction<string[]>>;
  onCheckedAll: (checked: boolean) => void;
  category?: void | ICategory[] | undefined;
  isEdit?: boolean;
  data?: string[];
}

const ListStyle = styled(MenuItem)`
  color: #6b7280;
  border-bottom: 1px solid #f4f4f5;
  padding: 8px 12px;
  text-align: left;
`;

const ButtonContents = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PublicCheckedBox: React.FC<ISelectBox> = ({
  children,
  ...props
}) => {
  const room_type = useRoomType();
  const all_checked =
    JSON.stringify(room_type.map((el) => el.label)) ===
    JSON.stringify(props.checkedItems);
  // console.log(room_type.every((el) => props.checkedItems.includes(el.label)));

  return (
    <>
      <Menu variant="default" closeOnSelect={false}>
        <MenuButton type="button">
          <ButtonContents>
            {all_checked
              ? "입주사 전체"
              : props.checkedItems.length === 0
              ? "입주사 선택"
              : `${props.checkedItems.length}개 선택됨`}
            <FiChevronDown fontSize="large" />
          </ButtonContents>
        </MenuButton>
        <MenuList>
          <MenuOptionGroup type="checkbox">
            <ListStyle value={`${props.default}`}>
              <Checkbox
                variant="default"
                isChecked={
                  props.checkedItems?.length === 0 ||
                  props.checkedItems?.length !== room_type?.length
                    ? false
                    : true
                }
                onChange={(e) => props.onCheckedAll(e.target.checked)}
                w={"100%"}
                value={JSON.stringify(props.checkedItems)}
              >
                {props.default}
              </Checkbox>
            </ListStyle>
            {children}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </>
  );
};

export default PublicCheckedBox;
