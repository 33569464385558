export default function BlueCancel() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16943_3825)">
        <path d="M9.99935 2.16699C5.39102 2.16699 1.66602 5.89199 1.66602 10.5003C1.66602 15.1087 5.39102 18.8337 9.99935 18.8337C14.6077 18.8337 18.3327 15.1087 18.3327 10.5003C18.3327 5.89199 14.6077 2.16699 9.99935 2.16699ZM13.5827 14.0837C13.2577 14.4087 12.7327 14.4087 12.4077 14.0837L9.99935 11.6753L7.59102 14.0837C7.26602 14.4087 6.74102 14.4087 6.41602 14.0837C6.09102 13.7587 6.09102 13.2337 6.41602 12.9087L8.82435 10.5003L6.41602 8.09199C6.09102 7.76699 6.09102 7.24199 6.41602 6.91699C6.74102 6.59199 7.26602 6.59199 7.59102 6.91699L9.99935 9.32533L12.4077 6.91699C12.7327 6.59199 13.2577 6.59199 13.5827 6.91699C13.9077 7.24199 13.9077 7.76699 13.5827 8.09199L11.1743 10.5003L13.5827 12.9087C13.8993 13.2253 13.8993 13.7587 13.5827 14.0837Z" fill="#2563EB"/>
      </g>
      <defs>
        <clipPath id="clip0_16943_3825">
          <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
      </defs>
    </svg>
  )
}