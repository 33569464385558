import { forwardRef, HTMLProps, Ref } from "react";
import * as N from "./styled";

export interface IScheduleCalendar extends HTMLProps<HTMLInputElement> {
  radio: string;
}

export const CustomWithTime = forwardRef(
  (
    { radio, value, onClick, ...rest }: IScheduleCalendar,
    ref: Ref<HTMLInputElement>
  ) => {
    //게시 예약
    return (
      <N.ReservedButton radio={radio}>
        <input value={value} ref={ref} onClick={onClick} {...rest} />
      </N.ReservedButton>
    );
  }
);

export interface ICalendarRange extends HTMLProps<HTMLInputElement> {
  isschedule: string[];
}

export const CustomDateRange = forwardRef(
  (
    { value, isschedule, ...props }: ICalendarRange,
    ref: Ref<HTMLInputElement>
  ) => {
    //일정 등록
    return (
      <N.ScheduleButton isschedule={isschedule}>
        <input
          ref={ref}
          // defaultValue={
          //   isEdit && !dateRange.includes(null)
          //     ? `${startDate} ~ ${endDate}`
          //     : ""
          // }
          value={value}
          {...props}
        />
      </N.ScheduleButton>
    );
  }
);
