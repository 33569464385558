import styled from "@emotion/styled";
import { FixedTitle } from "../../../components/styled";
import { breakPoints } from "../../../components/styled/media";
import {
  ItemSetBox,
  TableHeaderWrapper,
} from "../../../components/styled/webApp";

export const ShowedResultBox = styled(ItemSetBox)`
  @media (max-width: 1360px) {
    align-items: flex-start;
  }
  @media ${breakPoints.desktop} {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

export const ActionResultBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  .search-box {
    min-width: 20rem;
    width: 20rem;
  }
  @media (max-width: 1360px) {
    max-width: 700px; /* 반응형 flex-wrap */
    .search-box {
      margin-top: 1rem;
    }
  }
  @media ${breakPoints.desktop} {
    width: 100%;
    flex-direction: column;
    .category {
      display: none;
    }
  }
  @media (max-width: 500px) {
    .search-box {
      width: 100%;
    }
  }
`;

export const ActionButtonBox = styled.div`
  display: flex;
  button {
    min-width: auto;
  }
  @media ${breakPoints.desktop} {
    width: 100%;
    padding-bottom: 1rem; // 모바일 -> column-reverse
  }
`;

export const ComplaintWrap = styled(TableHeaderWrapper)`
  justify-content: space-between;
`;

export const SpecificCloum = styled(FixedTitle)`
  width: 20%;
  @media ${breakPoints.mobile} {
    width: 75%;
  }
`;
