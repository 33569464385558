import { Text, UseModalProps } from "@chakra-ui/react";
import ConfirmModal from "../../../components/modal/ConfirmModal";

interface ICheckModal extends UseModalProps {
  onClick: () => void;
}

export default function PreCheckModal(props: ICheckModal) {
  return (
    <ConfirmModal
      onClose={props.onClose}
      isOpen={props.isOpen}
      title="알림"
      close="설정하러가기"
      onClickCancle={props.onClose}
      onClickSave={props.onClick}
    >
      <Text>관리비 수납은행 및 납부일 미설정 상태입니다.</Text>
      <Text>관리비를 업로드하기 전 수납은행과 관리비 납부일을 먼저 설정해주세요!</Text>
    </ConfirmModal>
  );
}
