import { HiArrowLeft } from "@react-icons/all-files/hi/HiArrowLeft";
import { HiArrowRight } from "@react-icons/all-files/hi/HiArrowRight";
import { Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Dispatch, SetStateAction } from "react";
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
  usePagination,
} from "@ajna/pagination";
import { colors } from "../styled/common";

interface PaginationType {
  onChangeFunc?: (page: number) => void;
  allPage: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
}

const container = {
  width: "42px",
  height: "42px",
  border: `1px solid ${colors.gray2}`,
  backgroundColor: colors.whiteColor,
  fontWeight: 500,
  color: colors.gray2,
};

const active = {
  ...container,
  border: "1px solid #111827 !important",
  color: "#111827 !important",
};

export default function PaginationUI(props: PaginationType) {
  const { pages, setCurrentPage } = usePagination({
    pagesCount: props.allPage,
    initialState: { pageSize: 10, currentPage: props.page },
    limits: {
      outer: 2,
      inner: 2,
    },
  });

  return (
    <div className="flex w-full justify-end pt-7">
      <Pagination
        pagesCount={props.allPage}
        currentPage={props.page}
        onPageChange={(page) => {
          setCurrentPage(page);
          props.setPage(page);
          props.onChangeFunc && props.onChangeFunc(page);
        }}
      >
        <PaginationContainer align="center" justify="flex-end" w="100%">
          <PaginationPrevious
            variant="default"
            style={{ ...container }}
            _hover={{ ...active }}
            _focus={{ boxShadow: "none" }}
          >
            <HiArrowLeft className="absolute h-4 w-4" />
          </PaginationPrevious>
          <PaginationPageGroup
            separator={<PaginationSeparator _focus={{ boxShadow: "none" }} />}
            align="center"
            m="0 4px"
          >
            {pages.map((page: number) => (
              <PaginationPage
                style={{ ...container }}
                _hover={{ ...active }}
                _current={{ ...active }}
                _focus={{ boxShadow: "none" }}
                key={`pagination_page_${page}`}
                page={page}
              />
            ))}
          </PaginationPageGroup>
          <PaginationNext
            variant="default"
            style={{ ...container }}
            _hover={{ ...active }}
            _focus={{ boxShadow: "none" }}
          >
            <HiArrowRight className="absolute h-4 w-4" />
          </PaginationNext>
        </PaginationContainer>
      </Pagination>
    </div>
  );
}
