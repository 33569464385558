import { Link } from "react-router-dom";
import { useCreateVoteMutation, useGetVotesQuery } from "../../requests/vote";
import { useState } from "react";
import IconGrayPlus from "../../components/icons/IconGrayPlus";
import FullPageSpinner from "../../components/Spinner";
import CustomHeading from "../../components/CustomHeading";
import useUserAuth from "../../components/hooks/useUserAuth";
import GetAuthorized from "../../components/Authrized";

export default function VotesPage() {
  const TAB_MENUS = [
    { label: "전체", value: "전체" },
    { label: "진행전", value: "진행전" },
    { label: "진행중", value: "진행중" },
    { label: "투표 완료", value: "진행완료" },
    { label: "취소된 투표", value: "중단" },
  ];
  const [selectTab, setSelectTab] = useState<"전체" | "진행전" | "진행중" | "진행완료" | "중단">("전체");

  const auth = useUserAuth("vote");
  const isEdit = auth === "edit";

  const { mutateAsync: createVote, isLoading: isCreateVoteLoading } = useCreateVoteMutation();
  const { data: votesData, isLoading: isGetVotesLoading, isError } = useGetVotesQuery();

  const filteredVotes = votesData?.data.filter((vote) => selectTab === "전체" || vote.status === selectTab) || [];

  if (isCreateVoteLoading) return <FullPageSpinner />;
  if (isGetVotesLoading) return <></>;
  if (isError) return <></>;

  if (auth === "no_auth") return <GetAuthorized />;

  return (
    <div className="h-full w-full rounded-2xl bg-white">
      <div className="border-b border-gray-200">
        <CustomHeading headings="투표" sizeTheme="md" className="px-7" />
      </div>
      <div className="flex h-12 gap-7 border-b border-gray-200 px-7 font-semibold text-gray-400">
        {TAB_MENUS.map((tab, index) => (
          <button
            key={index}
            className={`transition-all ${tab.value === selectTab ? "border-b border-gray-700 text-gray-900" : "text-gray-400 hover:text-gray-500"}`}
            onClick={() => setSelectTab(tab.value as "전체" | "진행전" | "진행중" | "진행완료" | "중단")}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="flex flex-wrap gap-3 p-7">
        {isEdit && (
          <button
            onClick={() => createVote()}
            className="flex h-[160px] w-[260px] flex-col items-center justify-center gap-2 rounded-lg border border-gray-300 bg-gray-100 transition-all hover:bg-gray-200 active:bg-gray-300"
          >
            <IconGrayPlus />
            <p className="font-medium text-gray-400">새 투표 만들기</p>
          </button>
        )}
        {filteredVotes.map((item, key) => (
          <Link
            to={`/votes/${item._id}`}
            key={key}
            className="flex h-[160px] w-[260px] flex-col rounded-lg border border-gray-300 p-4 transition-all hover:bg-gray-50 active:bg-gray-100"
          >
            <div className="flex w-full justify-between">
              <p className="flex h-6 w-fit items-center gap-1.5 rounded-lg border border-gray-200 px-2">
                <span
                  className={`h-1.5 w-1.5 rounded-full ${
                    {
                      진행전: "bg-gray-400",
                      진행중: "bg-green-500",
                      중단: "bg-red-500",
                      진행완료: "bg-cyan-400",
                    }[item.status]
                  }`}
                />
                <span className="text-xs">
                  {
                    {
                      진행전: "진행전",
                      진행중: "진행중",
                      중단: "취소된 투표",
                      진행완료: "투표 완료",
                    }[item.status]
                  }
                </span>
              </p>
            </div>
            <div className="h-6" />
            <p className="font-bold text-gray-600">{item.title || "제목없음"}</p>
            <div className="h-5" />
            {item.status === "진행전" ? (
              <p className="text-xs text-gray-600">미설정</p>
            ) : (
              <p className="text-sm">{item.senderName}</p>
            )}
          </Link>
        ))}
      </div>
    </div>
  );
}
