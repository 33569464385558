import CustomModal from "../../../components/CustomModal";
import Icon from "../../../types/iconsax";
import formatPhoneNumber from "../../../utils/formatPhoneNumber";
import toast from "react-hot-toast";
import { useDeleteCompanyMemberMutation } from "../../../requests/company/useDeleteCompanyMemberMutation";
import CustomToast from "../../../components/CustomToast";
import { twMerge } from "tailwind-merge";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  memberData: {
    id: string;
    name: string;
    phoneNumber: string;
    userId: string;
    companyId: string;
    companyName: string;
  };
};

export default function CompanyDeleteMemberModal({ isOpen, onClose, memberData }: Props) {
  const { id: memberId, name, phoneNumber, userId, companyId, companyName } = memberData;
  const { mutateAsync: deleteCompanyMember } = useDeleteCompanyMemberMutation();

  if (!isOpen) return null;

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title="내보내기"
      exit={true}
      warningButtonText="내보내기"
      onWarningButtonClick={() => {
        toast.promise(deleteCompanyMember({ companyId, memberId }), {
          success: () => {
            onClose();
            return (
              <CustomToast
                iconType="check"
                title="퇴실 처리 완료"
                description={`${name.trim() || "이름 없는 구성원"}님을 퇴실 처리되었습니다.`}
              />
            );
          },
          error: () => {
            onClose();
            return <CustomToast iconType="error" title="퇴실 처리 중 에러가 발생하였습니다." />;
          },
          loading: <CustomToast iconType="loading" title="Loading" />,
        });
      }}
      neutralButtonText="취소"
      onNeutralButtonClick={onClose}
    >
      <div className="flex w-[488px] flex-col gap-3 p-6 pt-3">
        <div className="flex w-full items-center gap-4 rounded-xl border border-gray-200 px-6 py-4">
          <div className="flex size-10 items-center justify-center rounded-full bg-indigo-100">
            <Icon.Profile variant="Bold" size={20} color="#818CF8" />
          </div>
          <div className="flex flex-col">
            <span className={twMerge("text-sm font-medium text-gray-900", name.trim() === "" && "text-gray-400")}>
              {name.trim() || "이름 없는 구성원"}
            </span>
            <span className="text-sm font-normal text-gray-500">{formatPhoneNumber(phoneNumber ?? "-")}</span>
          </div>
          <div className="flex flex-1">
            <span className="flex-1 text-right text-sm font-medium text-gray-900">
              {userId ? "가입 회원" : "미가입"}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-base font-semibold text-gray-700">{`'${companyName}' 에서 ${name}님을 퇴실처리할까요?`}</span>
          <span className="text-base font-semibold text-gray-700">퇴실 후에는 데이터를 복구할 수 없습니다.</span>
        </div>
      </div>
    </CustomModal>
  );
}
