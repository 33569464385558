import { IoClose } from "@react-icons/all-files/io5/IoClose";
import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { useRef, useEffect, memo } from "react";
import { ChangeEvent, useState } from "react";
// import { AnswerBox } from ".";
import MoreContentInputs from "../../../../components/Input/contents";
import { fetchClient } from "../../../../lib/api/axios";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { upload_file } from "../../../../lib/api/queries/urls";
import { checkFileValidate } from "../../../../lib/theme/toast";
import { checkFileValidation, limitTitle } from "../../../../lib/utils/format";
// import { QuestionContext } from "./questionItems";
import * as S from "../styled";
import { IAnswer } from "../types";
import { DeleteIcon } from "../../../../components/styled";

const AnswerItems = (props: IAnswer) => {
  const toast = useToast();
  const fileRef = useRef<HTMLInputElement>(null);
  const [body, setBody] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  useEffect(() => {
    if (props.isEdit === undefined) return;
    if (props.el === undefined) return;
    setBody(props.el.answer);
    if (props.el.image) setFileUrl(props.el.image);
  }, [props.isEdit, props.el]);

  const onGetFileUrl = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file === undefined) return;
    const isVaild = checkFileValidation(file);
    if (!isVaild) return toast({ ...checkFileValidate });

    const formData = new FormData();
    formData.append("body_files", file);

    try {
      const newArr = await fetchClient.post<BaseResponse<string[]>>(upload_file, formData);
      setFileUrl(newArr.data.data?.[0]);
      props.onChangeAnswer(newArr.data.data?.[0], props.el, "image");
    } catch (err) {
      console.log(err);
    }

    e.target.value = "";
  };

  // useEffect(() => {
  //   if (fileUrl === "") {
  //     setNewObj({
  //       _id: props.id,
  //       participated_num: 0,
  //       answer: body,
  //     });
  //   } else {
  //     setNewObj({
  //       _id: props.id,
  //       participated_num: 0,
  //       answer: body,
  //       image: fileUrl,
  //     });
  //   }
  // }, [body, fileUrl]);

  // useEffect(() => {
  //   if (response === undefined) return;
  //   if (setResponse === undefined) return;
  //   if (newObj === undefined) return;

  //   setResponse(response.map((el) => (el.id === props.id ? newObj : el)));
  // }, [newObj]);

  return (
    <Box>
      <S.CategoryBox>
        <Text minW="100px">응답 {props.num}</Text>
        <Flex w="100%" alignItems="center">
          <Flex w="100%">
            <MoreContentInputs
              value={body}
              maxLength={100}
              onChange={(e) => {
                const isLimit = limitTitle(e.target.value);
                if (!isLimit) return alert("응답은 최대 100자까지 입력할 수 있습니다.");
                setBody(e.target.value);
                // props.onChangeAnswer(e.target.value, props.el, "answer");
              }}
              onBlur={(e) => {
                props.onChangeAnswer(e.target.value, props.el, "answer");
              }}
              placeholder="응답 내용을 입력하세요"
            />
            <input type="file" style={{ display: "none" }} onChange={onGetFileUrl} ref={fileRef} />
            <Button variant="basic" onClick={() => fileRef.current?.click()}>
              사진선택
            </Button>
          </Flex>
          <S.CloseBox>
            <IoClose className="react-icon-static" onClick={() => props.onDeleteAnswer(String(props.el._id))} />
          </S.CloseBox>
        </Flex>
      </S.CategoryBox>
      {fileUrl !== "" && (
        <S.CategoryBox>
          <Text minW="100px"></Text>
          <S.SurveyImage>
            <img src={fileUrl} alt="응답 이미지" />
            <DeleteIcon onClick={() => setFileUrl("")} />
          </S.SurveyImage>
        </S.CategoryBox>
      )}
    </Box>
  );
};

export const MemoizedAnswer = memo(AnswerItems);
