import styled from "@emotion/styled";
import { CloseIconWithImg, DateWithIconBox } from "../../../components/styled";
import { colors } from "../../../components/styled/common";
import { ContentBox } from "../../company/units/styled";

//차량 등록 모달
export const CalendarBoxInParking = styled(DateWithIconBox)`
  width: 100%;
  min-width: 258px;
  .react-datepicker__input-container {
    width: 180px;
  }
  .react-datepicker__triangle {
    display: none;
  }
`;

export const WithFile = styled.div`
  width: 100%;
  display: flex;
`;

export const FileTextBox = styled.div<{ value: File[] | null }>`
  width: 100%;
  flex-wrap: wrap;
  background-color: ${colors.whiteColor};
  color: ${({ value }) =>
    value === null || value.length === 0 ? colors.gray2 : colors.gray4};
  margin-right: 8px;
  border-radius: 0;
  border: 1px solid ${colors.gray3};
  min-height: 44px;
  padding: 4px 12px 8px 12px;
  -webkit-padding-after: 9px;
  -ms-padding-after: 9px;
  -moz-padding-after: 9px;
`;

export const SelectedRoom = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 8px 0 0;
  cursor: pointer;
`;

export const FileText = styled(CloseIconWithImg)`
  position: static;
  border-radius: 100%;
  margin-left: 4px;
`;

//차량조회
export const FocusText = styled.div<{ status: string }>`
  ${({ status }) => status === "approval_requested" && `color:#2563EB`};
`;

//정기 주차 상세 페이지
export const ModalInfoBox = styled.div`
  width: 100%;
  color: ${colors.gray4};
  padding: 8px 12px;
  font-weight: 500;
  margin-bottom: 16px;
  background-color: ${colors.gray1};
  span {
    color: ${colors.red1};
    line-height: 1.5;
  }
`;

export const LinkBox = styled(ContentBox)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  a {
    margin: 4px 8px 4px 0;
  }
  img {
    margin-right: 0;
  }
  a:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
