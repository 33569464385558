import * as T from "./styled";
import { Checkbox, Flex } from "@chakra-ui/react";
import moment from "moment";
import "moment/locale/ko";
import { IAccTemperature, INewTemperature } from "../../../lib/types/Imodels";
import { colors, m_color } from "../../../components/styled/common";

const tempBorder = { borderRight: `1px solid ${colors.gray4}` };

interface ITempList {
  el: string;
  date: string;
  checkedItems: string[];
  accArr: IAccTemperature[];
  newArr: INewTemperature[];
  onChangeChecked: (checked: boolean, id: string) => true | undefined;
}

export default function FloorListItem({
  el,
  onChangeChecked,
  ...props
}: ITempList) {
  const newHot = props.newArr.filter((el) => el._id.request_type === "hot");
  const accHot = props.accArr.filter((el) => el._id.request_type === "hot");
  const newCold = props.newArr.filter((el) => el._id.request_type === "cold");
  const accCold = props.accArr.filter((el) => el._id.request_type === "cold");

  const format_content = (str: string) => {
    switch (str) {
      case "관리센터에서 상태를 업데이트 했습니다.":
        return "상태 업데이트";
      case "관리센터에서 실내 온도를 높였습니다.":
        return "온도 올림";
      case "관리센터에서 실내 온도를 낮췄습니다.":
        return "온도 내림";
      default:
        return str;
    }
  };

  return (
    <T.FloorBody>
      <T.TableCell style={{ width: "10%" }}>
        {props.newArr.length !== 0 &&
          props.date === moment.utc(new Date()).format("L") && ( //신규 신청이 있고 오늘 날짜여야만 체크 활성화
            <Checkbox
              variant="default"
              isChecked={props.checkedItems.includes(el)}
              onChange={(e) => onChangeChecked(e.target.checked, el)}
            />
          )}
      </T.TableCell>
      <T.FixedTableCell style={{ width: "20%", display: "inline-block" }}>
        {el}
      </T.FixedTableCell>
      <Flex>
        <T.SmallCellBox style={tempBorder}>
          <T.TableCell
            style={{
              fontWeight: newHot.length ? 500 : 400,
              color: newHot.length ? m_color.danger4 : colors.gray5,
              ...tempBorder,
            }}
          >
            {newHot.length ? newHot[0].cnt || "-" : "-"}
          </T.TableCell>
          <T.TableCell>
            {accHot.length ? accHot[0].cnt || "-" : "-"}
          </T.TableCell>
        </T.SmallCellBox>
        <T.SmallCellBox>
          <T.TableCell
            style={{
              fontWeight: newCold.length ? 500 : 400,
              color: newCold.length ? colors.mainBlue : colors.gray5,
              ...tempBorder,
            }}
          >
            {newCold.length ? newCold[0].cnt || "-" : "-"}
          </T.TableCell>
          <T.TableCell>
            {accCold.length ? accCold[0].cnt || "-" : "-"}
          </T.TableCell>
        </T.SmallCellBox>
      </Flex>
      <Flex>
        <T.AnswerCell style={tempBorder}>
          {props.accArr.length
            ? format_content(props.accArr[0].updated.answer || "")
            : ""}
        </T.AnswerCell>
        <T.TableCell>
          {props.accArr.length && props.accArr[0].updated.progress_date
            ? moment.utc(props.accArr[0].updated.progress_date).format("LT")
            : ""}
        </T.TableCell>
      </Flex>
    </T.FloorBody>
  );
}
