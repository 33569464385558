import { usePagination } from "@ajna/pagination";
import { useToast, useDisclosure, Flex, Select, Text } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { warning } from "framer-motion";
import { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import GetAuthorized from "../../components/Authrized";
import SmallInputs from "../../components/Input/shortText";
import MainHeaderPart from "../../components/Main/HeaderPart";
import FullPageSpinner from "../../components/Spinner";
import useUserAuth from "../../components/hooks/useUserAuth";
import { fetchClient } from "../../lib/api/axios";
import { BaseResponse } from "../../lib/api/queries/commons/types";
import { parking_history_keys, parking_detail_key } from "../../lib/api/queries/keys";
import { parking_history, parking_detail } from "../../lib/api/queries/urls";
import { IRegularCar } from "../../lib/types/Imodels";
import NotFound from "../not-found";
import VehicleRegistration from "./history/NewVehicle";
import VehicleInformation from "./history/VehicleInfo";
import VehicleItem from "./history/VehicleItem";
import { useQuery } from "@tanstack/react-query";
import * as C from "../../components/styled/index";
import Button from "../../components/button/Button";
import Pagination from "../../components/custom/Pagination";
import RegularModal from "./RegularModal";
import { GlobalContext } from "../../App";

export default function ParkingPage() {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure(); //차량 등록
  const { isOpen: isOpenParking, onOpen: onOpenParking, onClose: onCloseParking } = useDisclosure(); //
  const auth = useUserAuth("car");
  const { buildInfo } = useContext(GlobalContext);
  const hasFunc = buildInfo?.services.REGULAR_PARKING?.enabled;
  const [searchParams, setSearchParams] = useSearchParams();

  const pay = searchParams.get("pay") || "all";
  const progress = searchParams.get("progress") || "all";
  const tenant = searchParams.get("search_tenant_name") || "";
  const ho = searchParams.get("search_ho") || "";
  const car = searchParams.get("search_car_number") || "";

  const currentPage = Number(searchParams.get("page") || 1);
  const { setCurrentPage } = usePagination({
    initialState: { currentPage },
  });
  const [allPage, setAllPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [isReset, setIsReset] = useState(false); //초기화
  const initialParams: any = {
    page: String(currentPage),
    pay,
    progress,
  };
  if (tenant !== "") initialParams.search_tenant_name = tenant;
  if (car !== "") initialParams.search_car_number = car;
  if (ho !== "") initialParams.search_ho = ho;

  const { data, refetch } = useQuery(
    [parking_history_keys, currentPage, isReset],
    () =>
      fetchClient
        .get<BaseResponse<IRegularCar[]>>(parking_history, {
          params: {
            limit: 10,
            page: currentPage,
            is_paid: pay,
            progress_type: progress,
            search_car_number: searchParams.has("search_car_number") ? car : "",
            search_ho: searchParams.has("search_ho") ? ho : "",
            search_tenant_name: searchParams.has("search_tenant_name") ? tenant : "",
          },
        })
        .then((res) => {
          setTotal(res.data.total_num);
          setAllPage(res.data.total_page_num || 0);
          // setIsReset(false)
          return res.data.data;
        }),
    { enabled: auth === "read" || auth === "edit" },
  );

  const onResetState = () => {
    setSearchParams({ page: String(1) });
    currentPage === 1 ? setIsReset((prev) => !prev) : setCurrentPage(1);
  };

  const onUpdateParams = (value: string, str: string) => {
    setSearchParams({ ...initialParams, ...{ [value]: str } });
  };

  const onSearchHistory = () => {
    setSearchParams({ ...initialParams, ...{ page: String(1) } });
    currentPage !== 1 ? setCurrentPage(1) : refetch();
  };

  const ids = sessionStorage.getItem("car_register"); //차량 상세 정보
  const [isModalOpen, setIsModalOpen] = useState(ids ? true : false);

  const onCloseModal = () => {
    setIsModalOpen(false);
    sessionStorage.removeItem("car_register");
  };

  const { data: detail } = useQuery(
    [parking_detail_key, ids],
    () =>
      fetchClient
        .post<BaseResponse<IRegularCar>>(parking_detail, {
          car_id: ids,
        })
        .then((res) => res.data.data),
    {
      enabled: isModalOpen,
      retry: 1,
      onError: (err: AxiosError) => {
        onCloseModal();
        if (err.response?.status === 504)
          toast({
            ...warning,
            title: "알림",
            description: "정보가 삭제되었거나 확인할 수 없습니다.",
          });
        return undefined;
      },
    },
  );

  useEffect(() => {
    if (ids === null) return;

    setIsModalOpen(true);
  }, [ids]);

  if (!auth || hasFunc === undefined) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;
  if (auth === "no_auth") return <GetAuthorized />;
  return (
    <div className="flex min-h-full w-full flex-col rounded-lg bg-white px-6 py-10">
      <MainHeaderPart isIcon={false} title="주차 등록 현황" authKey="car">
        <p>정기 주차관리를 위한 페이지입니다.</p>
        <p>
          신규 신청과 해지 신청 건을 모두 처리해주시고, 관리자가 직접 차량 등록을 진행하는 경우 ‘차량 등록’에서 진행할
          수 있습니다.
        </p>
        <div className="mt-2 flex gap-2">
          <Button onClick={onOpen} designSchema={"primary"} buttonText={"차량 등록"} size={"md"} />
          <Button onClick={onOpenParking} designSchema={"primary"} buttonText={"주차 안내 설정"} size={"md"} />
        </div>
      </MainHeaderPart>
      {isOpen && <VehicleRegistration isOpen={isOpen} onClose={onClose} />}
      <C.WrapperBody>
        <Flex>
          <C.SelectBox>
            <Text variant="default">등록상태</Text>
            <Select variant="default" value={progress} onChange={(e) => onUpdateParams("progress", e.target.value)}>
              <option value="all">전체</option>
              <option value="approval_requested">신규신청</option>
              <option value="in_use">등록완료</option>
              <option value="canceled">해지(정산)완료</option>
            </Select>
          </C.SelectBox>
          <C.SelectBox>
            <Text variant="default">무/유료 여부</Text>
            <Select value={pay} variant="default" onChange={(e) => onUpdateParams("pay", e.target.value)}>
              <option value="all">전체</option>
              <option value="true">유료</option>
              <option value="false">무료</option>
            </Select>
          </C.SelectBox>
        </Flex>
        <C.SearchBox style={{ paddingBottom: "0" }}>
          <C.SelectBox>
            <Text variant="default">검색</Text>
            <Select
              variant="default"
              value={
                searchParams.has("search_tenant_name")
                  ? "search_tenant_name"
                  : searchParams.has("search_ho")
                    ? "search_ho"
                    : "search_car_number"
              }
              onChange={(e) =>
                setSearchParams({
                  ...initialParams,
                  ...{ [e.target.value]: "" },
                })
              }
            >
              <option value="search_car_number">차량번호</option>
              <option value="search_ho">호실번호</option>
              <option value="search_tenant_name">입주사명</option>
            </Select>
          </C.SelectBox>
          <SmallInputs
            w="368px"
            value={searchParams.has("search_tenant_name") ? tenant : searchParams.has("search_ho") ? ho : car}
            placeholder={`${
              searchParams.has("search_tenant_name")
                ? "검색하실 입주사명를 입력해주세요"
                : searchParams.has("search_ho")
                  ? "검색하실 호실번호를 입력해주세요"
                  : "검색하실 차량번호를 입력해주세요"
            }`}
            onChange={(e) =>
              onUpdateParams(
                searchParams.has("search_tenant_name")
                  ? "search_tenant_name"
                  : searchParams.has("search_ho")
                    ? "search_ho"
                    : "search_car_number",
                e.target.value,
              )
            }
          />
          <Button onClick={onSearchHistory} designSchema={"secondaryGray"} buttonText={"검색"} size={"md"} />
          <C.SearchReset variant="basic" onClick={onResetState}>
            초기화
          </C.SearchReset>
        </C.SearchBox>
        <C.TableListBox>
          <C.TableText>
            총 <C.TableCount>{total}개</C.TableCount>의 등록 차량이 조회되었습니다.
          </C.TableText>
          <C.ListTableBox>
            <C.ListTableHeader w="20%">
              <div style={{ width: "25%" }}>입주사명</div>
              <div>차량번호</div>
              <div style={{ width: "25%" }}>신청일시</div>
              <div>등록상태</div>
              <div>유/무료 여부</div>
            </C.ListTableHeader>
            {data?.map((el) => <VehicleItem el={el} key={String(el._id)} setIsModalOpen={setIsModalOpen} />)}
          </C.ListTableBox>
        </C.TableListBox>
        {detail && ids === String(detail?._id) && (
          <VehicleInformation isOpen={isModalOpen} onClose={onCloseModal} el={detail} />
        )}
        <div className="size-6" />
        <div className="flex justify-end">
          <Pagination currentPage={currentPage} itemCount={allPage} showPageAmount={10} />
        </div>
        {isOpenParking && <RegularModal isOpen={isOpenParking} onClose={onCloseParking} />}
      </C.WrapperBody>
    </div>
  );
}
