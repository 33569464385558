import styled from "@emotion/styled";
import { WordItem } from "../../../components/styled";
import { colors } from "../../../components/styled/common";
import { breakPoints } from "../../../components/styled/media";

export const OfficeItem = styled(WordItem)`
  color: ${colors.gray5};
  justify-content: space-between;
  cursor: pointer;
`;

export const OpenItem = styled.div<{ saved: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ saved }) => (saved ? colors.green1 : colors.red1)};
  svg {
    color: ${colors.gray5};
    font-size: 24px;
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 4px;
  p {
    width: auto;
    padding-bottom: 0;
  }
`;

export const HoverPreviewBox = styled.div`
  position: relative;
  padding-left: 8px;
  cursor: pointer;
  &:hover {
    .preview {
      display: flex;
    }
  }
`;

export const PreviewBox = styled.div`
  display: none;
  position: absolute;
  top: 120%;
  left: 8px;
  min-width: 35rem;
  flex-direction: column;
  padding: 32px;
  background-color: ${colors.gray1};
  z-index: 10;
  @media ${breakPoints.moniter} {
    width: 50vw;
  }
`;

export const PreviewExample = styled.div`
  h4 {
    font-weight: 500;
  }
  p {
    padding-bottom: 1.25rem;
  }
  div {
    width: 100%;
    display: flex;
  }
`;

export const AppImages = styled.img`
  max-width: 10rem;
  margin-right: 16px;
`;
