import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { CSSProperties, FC } from "react";

interface IMobileModal extends ModalProps {
  title: string;
  next: string;
  styled?: CSSProperties;
  onAction: () => void;
}

const CustomFooter = styled(ModalFooter)`
  display: flex;
  button {
    width: 100%;
  }
`;

const RespCancelModal: FC<IMobileModal> = ({ children, ...props }) => {
  return (
    <Modal
      size="xs"
      variant="responsive"
      isOpen={props.isOpen}
      onClose={props.onAction}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount={props.blockScrollOnMount}
    >
      <ModalOverlay />
      <ModalContent style={props.styled}>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <CustomFooter>
          <Button mr="0.5rem" variant="m_gray" onClick={props.onClose}>
            취소
          </Button>
          <Button variant="m_danger" onClick={props.onAction}>
            {props.next}
          </Button>
        </CustomFooter>
      </ModalContent>
    </Modal>
  );
};

export default RespCancelModal;
