import { Text, Switch, useToast, useDisclosure } from "@chakra-ui/react";
import { IRoomPublic } from "../../../../lib/types/Imodels";
import * as C from "../../../../components/styled";
import * as R from "../../timeline/_view/styled";
import { getTime, TimeFormat } from "../../../../lib/utils/format";
import { format_type } from "../../../../lib/utils/dataFormat";
import { useRoomType } from "../../../../lib/utils";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import LimitModal from "./limitModal";
import SingleRow, { WrapFlex } from "../../../../components/modal/table/singleRow";
import InfoModal from "../../../../components/modal/info";
import { public_reserve_delete } from "../../../../lib/api/queries/urls";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { fetchClient } from "../../../../lib/api/axios";
import { success, unAuthUser, warning } from "../../../../lib/theme/toast";
import { queryClient } from "../../../..";
import { public_detail_key, public_list_key } from "../../../../lib/api/queries/keys";
import moment from "moment";
import useUserAuth from "../../../../components/hooks/useUserAuth";
import { AxiosError } from "axios";
import { IMeetingRoomDetails } from "../../commons/types";

type ILimits = {
  data: IRoomPublic;
  setOpen: boolean;
};

const SwitchLimit = ({ data, setOpen }: ILimits) => {
  const toast = useToast();
  const auth = useUserAuth("room_public");
  const { isOpen, onClose, onOpen } = useDisclosure(); //예약 제한 모달
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    data.admin_end_date ? setToggle(true) : setToggle(false);

    // if (moment(data?.admin_end_date).add(-9, "hours").isBefore(moment())) {
    //   console.log(
    //     "here",
    //     data?.admin_end_date,
    //     moment(data?.admin_end_date).add(-9, "hours").isBefore(moment())
    //   );
    //   return setLimit(false);
    // } //예약 제한 있는데 시간이 지났다면 false
    // console.log("?");
    // return setLimit(true); //예약 제한 있으면 true
  }, [data.admin_end_date, setOpen]);

  const onSetTimeLimit = (e: ChangeEvent<HTMLInputElement>) => {
    if (auth !== "edit") return toast({ ...unAuthUser });
    if (e.target.checked) onOpen();
    if (!e.target.checked && data?.admin_start_date) {
      fetchClient
        .post<BaseResponse<string>>(public_reserve_delete, {
          room_public_id: data._id,
        })
        .then(() => {
          queryClient.invalidateQueries([public_detail_key, data._id]);
          queryClient.invalidateQueries([public_list_key, moment().format("YYYY-MM-DD")]);
          setToggle(e.target.checked);
          toast({
            ...success,
            title: "예약 제한 해제",
            description: `예약 제한이 해제되었습니다. 지금부터 ${data.name} 시설은 오피스너 어플에서 이용 예약이 가능합니다.`,
          });
        })
        .catch((err: AxiosError) => {
          toast({
            ...warning,
            title: err.response?.status === 400 ? "관리자 권한 오류" : "예약 제한 해제 실패",
            description:
              err.response?.status === 401
                ? "해당 회의실의 상태를 변경하기 위한 권한이 없습니다. 권한 필요 시 최고관리자에게 문의해주세요."
                : `회의실 예약 제한 해제에 실패했습니다. 잠시 후 다시 시도해주세요.`,
          });
        });
    }
  };

  return (
    <R.AutoBox>
      <Switch size="md" variant="default" isChecked={toggle} onChange={onSetTimeLimit} />
      <LimitModal data={data} isOpen={isOpen} onOpen={onOpen} onClose={onClose} setToggle={setToggle} />
      <R.AutoText>
        예약 제한
        {data?.admin_end_date && (
          <>
            {` ${TimeFormat(data?.admin_start_date, "yy")}${
              data?.admin_start_date ? " ~ " : ""
            }${TimeFormat(data?.admin_end_date, "yy")}`}
          </>
        )}
      </R.AutoText>
    </R.AutoBox>
  );
};

export default function PublicRoomInfo({ data, ...props }: IMeetingRoomDetails) {
  // console.log(props.data);
  const room_type = useRoomType();

  return (
    <InfoModal
      title={`${data.name} 회의실 상세정보`}
      isOpen={props.isOpen}
      onClose={props.onClose}
      element={<SwitchLimit data={data} setOpen={props.isOpen} />}
      style={{ width: "860px", maxWidth: "inherit" }}
    >
      <C.IntervalBox style={{ marginTop: "8px" }}>
        <C.SubTitleBox variant="title">
          1. 기본 정보
          <span>* 상세정보 수정은 회의실 설정에서 개별 수정이 가능합니다.</span>
        </C.SubTitleBox>
        <SingleRow title="위치">
          {data.dong}동 {data.floor}층
        </SingleRow>
        <SingleRow title="회의실명">{data.name}</SingleRow>
        <SingleRow title="최대 수용 인원">{data.people_num}명</SingleRow>
        <SingleRow title="시설 옵션">
          {data.options.map((el, index) => (data.options.length === index + 1 ? el : `${el}, `))}
        </SingleRow>
      </C.IntervalBox>
      <C.IntervalBox>
        <Text variant="title">2. 이용 가능 조건</Text>
        {room_type.length > 1 && (
          <SingleRow title="입주사 조건">
            {data.available_rooms.length === room_type.length ? (
              "입주사 전체"
            ) : (
              <>{format_type(data.available_rooms)}</>
            )}
          </SingleRow>
        )}
        {/* <SingleRow title="위치 조건">동 전체 / 층 전체</SingleRow> */}
        <SingleRow title="이용 가능 시간">
          {data.start_time_holiday
            ? `평일 ${getTime(data.start_time_weekday)} - ${
                getTime(data.end_time_weekday) === "00:00" ? "24:00" : getTime(data.end_time_weekday)
              } / 주말 ${getTime(data.start_time_holiday)} - ${
                getTime(data.end_time_holiday) === "00:00" ? "24:00" : getTime(data.end_time_holiday)
              }`
            : `평일 ${getTime(data.start_time_weekday)} - ${
                getTime(data.end_time_weekday) === "00:00" ? "24:00" : getTime(data.end_time_weekday)
              }`}
        </SingleRow>
      </C.IntervalBox>
      <C.IntervalBox>
        <Text variant="title">3. 예약</Text>
        <SingleRow title="예약확정 방식">
          {data.is_approval_required ? "관리자 확인 후 확정" : "예약신청과 동시에 확정"}
        </SingleRow>
        <SingleRow title="결제수단 및 금액">
          30분 /
          {data.payment_method_and_price.length === 0
            ? " 무료"
            : data.payment_method_and_price.map((el) => (
                <Fragment key={String(el._id)}>
                  {el.payment_method === "free" ? " 무료 " : ` 포인트 ${(el.price || 0).toLocaleString("kr")}P`}
                </Fragment>
              ))}
        </SingleRow>
        <SingleRow title="이용정책">
          <WrapFlex style={{ padding: 0 }}>
            <R.TextGuide value={data.description} readOnly />
          </WrapFlex>
        </SingleRow>
        <SingleRow title="결제안내">
          <WrapFlex style={{ padding: 0 }}>
            <R.TextGuide value={data.payment_description} readOnly />
          </WrapFlex>
        </SingleRow>
      </C.IntervalBox>
    </InfoModal>
  );
}
