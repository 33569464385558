import { twMerge } from "tailwind-merge";
import IconArrow from "./icons/IconArrow";
import { Link, useLocation } from "react-router-dom";

type Props = {
  data: {
    label: string;
    path: string[];
  }[];
} & React.HTMLAttributes<HTMLDivElement>;

export default function CustomBreadcrumb({ data }: Props) {
  const { pathname } = useLocation();

  return (
    <div className="flex min-h-10 items-center border-b border-gray-200 px-6 py-1.5">
      {data.map((item, index) => (
        <div key={item.path[0] + index} className="flex items-center">
          <Link
            to={item.path[0]}
            className={twMerge(
              "text-xs font-medium text-gray-500 hover:text-gray-600 active:text-gray-700",
              item.path.filter((v) => v === pathname).length > 0 && "text-gray-700",
            )}
          >
            {item?.label}
          </Link>
          {(data.length ?? 0) > index + 1 && (
            <div className="px-1">
              <IconArrow />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
