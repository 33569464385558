import { UseModalProps, useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { ChangeEvent, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { complaint_progress } from "../../../../lib/api/queries/urls";
import { success, warning } from "../../../../lib/theme/toast";
import { queryClient } from "../../../..";
import { complaint_detail_key } from "../../../../lib/api/queries/keys";
import { useUpdateComment } from "../../commons/queries";
import { colors } from "../../../../components/styled/common";
import { ShadowBox } from "../../../../components/styled/webApp";
import ModalSaveWithContents from "../../../../components/modal/responsive/content";
import GrayColorSelect from "../../../../components/select";
import GrayRadiusTextArea from "../../../../components/textarea";
import { MdError } from "@react-icons/all-files/md/MdError";
import { ComplaintWarnBox } from "../style";

interface ISelectBox extends UseModalProps {
  type: string;
  data: string;
}

export default function ChangedStatusInModal({ data, ...props }: ISelectBox) {
  const toast = useToast();
  const { complaintId } = useParams();

  const [changeState, setChangeState] = useState(data);
  const [comment, setComment] = useState(
    `불편사항 처리가 완료되었습니다. \n추가 문의 사항이 있으시면 언제든지 연락주세요. 더 나은 서비스를 제공하기 위해 노력하겠습니다. 감사합니다. \n처리 내역: `,
  );

  const { createComment } = useUpdateComment();
  const mutation = useMutation(
    () =>
      fetchClient
        .post(complaint_progress, {
          complaint_id: complaintId,
          progress_type: changeState,
        })
        .then((res) => res.data.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([complaint_detail_key, complaintId]);

        toast({
          ...success,
          title: "처리 상태 변경",
          description:
            changeState === "completed"
              ? props.type === "building"
                ? "민원 상태가 ‘처리 완료’로 변경되었습니다."
                : "처리상태와 함께 답글을 접수자에게 전달하였습니다."
              : "민원 상태가 ‘처리 중’으로 변경되었습니다.",
        });
        props.onClose();
      },
      onError: () => {
        toast({
          ...warning,
          title: "처리 상태 변경",
          description:
            "일시적으로 처리 상태 변경에 실패했습니다. 새로고침 후에도 동일한 오류가 반복될 경우 오피스너 담당자에게 문의해주세요.",
        });
      },
    },
  );

  const onCheckBeforeChageStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    // if (auth !== "edit") return toast({ ...unAuthUser });
    // props.setStatus(e.target.value);
    if (e.target.value === "waiting") return;
    // props.onOpen();
    setChangeState(e.target.value);
  };

  const onChangeStatus = () => {
    if (mutation.isLoading || !complaintId) return;

    if (changeState === data) return props.onClose();
    if (changeState === "completed" && comment && props.type === "tenant") {
      const obj = {
        complaint_id: complaintId,
        body: comment,
      };

      createComment.mutateAsync(obj).then(() => {
        mutation.mutate();
      });
    } else {
      mutation.mutate();
    }
  };

  return (
    <ModalSaveWithContents
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="처리 상태 업데이트"
      next="전달하기"
      before="취소"
      onAction={onChangeStatus}
      scrollBehavior="inside"
      // _content={{ minHeight: "30rem" }}
    >
      <ShadowBox isModal={true} isMore={true} style={{ padding: "0 0 1rem 0" }}>
        <div>
          <h4 style={{ color: colors.gray4, fontWeight: 700 }}>처리상태를 선택해주세요.</h4>
          <GrayColorSelect
            w="100%"
            value={changeState}
            _onChange={onCheckBeforeChageStatus}
            _style={{ textAlign: "left", backgroundPositionX: "95%" }}
          >
            <option disabled value="waiting">
              처리대기
            </option>
            <option value="proceeding">처리중</option>
            <option value="completed">처리완료</option>
          </GrayColorSelect>
          {props.type === "building" ? (
            <ComplaintWarnBox>
              <MdError />
              <div>해당 민원은 관리자가 직접 등록한 민원입니다. 접수자에게 별도로 답변을 전달해주시기 바랍니다.</div>
            </ComplaintWarnBox>
          ) : (
            <>
              {changeState === "proceeding" && (
                <ComplaintWarnBox>
                  <MdError />
                  <div>‘처리 중’ 상태는 접수자에게 알림이 가지 않습니다.</div>
                </ComplaintWarnBox>
              )}
            </>
          )}
        </div>
        {changeState === "completed" && props.type === "tenant" && (
          <div>
            <h4 style={{ color: colors.gray4, fontWeight: 700 }}>
              처리상태와 함께 접수자에게 전달할 답글을 입력해주세요.
            </h4>
            <GrayRadiusTextArea
              _style={{ minHeight: "10rem" }}
              value={comment}
              placeholder="처리상태와 함께 접수자에게 전달할 답글을 입력해주세요."
              isLimited={true}
              num={3000}
              _onChange={(e) => {
                if (e.target.value.length > 3000) return;
                setComment(e.target.value);
              }}
            />
          </div>
        )}
      </ShadowBox>
    </ModalSaveWithContents>
  );
}
