import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from "@headlessui/react";
import Icon from "../types/solar-icon";
import { twMerge } from "tailwind-merge";
import CustomCheckBox from "./CustomCheckBox";

type Props = {
  themeSize?: "sm" | "md";
  data: { [key: string]: string }[];
  getValue: () => string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

export default function CustomListBox({ themeSize = "sm", data, getValue, onChange, disabled, ...props }: Props) {
  const sizeClass = {
    sm: "h-9 w-full rounded-lg border border-gray-300 bg-white px-3 text-start text-xs font-medium leading-4 group-hover:bg-gray-50 ui-open:bg-gray-50 ui-open:outline-none ui-open:outline-2 ui-open:outline-offset-0 ui-open:outline-gray-200",
    md: "h-12 w-full rounded-lg border border-gray-300 bg-white px-4 text-start text-base font-medium leading-5 group-hover:bg-gray-50 ui-open:bg-gray-50 ui-open:outline-none ui-open:outline-2 ui-open:outline-offset-0 ui-open:outline-gray-200",
  }[themeSize];

  return (
    <Listbox value={getValue()} onChange={onChange} disabled={disabled} {...props}>
      <div className="relative w-full">
        <div
          className={"group relative flex flex-1 items-center text-gray-500 hover:text-gray-600 ui-open:text-gray-600"}
        >
          <ListboxButton
            className={twMerge(
              sizeClass,
              data[0].value !== getValue() && "border-gray-400 text-gray-700",
              disabled && "cursor-not-allowed opacity-50",
            )}
          >
            {(data.find((item) => item.value === getValue()) || data[0]).name}
            <div
              className={twMerge(
                "absolute inset-y-0 right-0 flex items-center pr-2",
                data[0].value !== getValue() && "text-gray-600",
              )}
            >
              <Icon.AltArrowDown
                size={themeSize === "sm" ? 16 : 20}
                className="ui-open:rotate-180"
                aria-hidden="true"
              />
            </div>
          </ListboxButton>
        </div>
        <Transition leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <ListboxOptions
            className={twMerge(
              "absolute mt-[42px] max-h-[22.5rem] w-full overflow-scroll rounded-lg border border-gray-300 bg-white shadow-lg empty:hidden",
              themeSize === "md" && "mt-[54px]",
            )}
          >
            {data.map((item, index) => (
              <ListboxOption
                key={index}
                value={item.value}
                className={twMerge(
                  "flex h-9 cursor-pointer select-none items-center gap-2 p-3 ui-active:bg-gray-50",
                  themeSize === "md" && "h-12 p-6",
                )}
              >
                <div
                  className={twMerge(
                    "text-xs font-medium text-gray-500 ui-active:text-gray-600",
                    themeSize === "md" && "text-base font-normal leading-5",
                  )}
                >
                  {item.name}
                </div>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Transition>
      </div>
    </Listbox>
  );
}

export type MultipleProps = {
  data: { [key: string]: string }[];
  getValue: () => string[];
  onChange: (value: string[]) => void;
  label: string;
};

export function CustomMultipleListBox({ data, getValue, onChange, label }: MultipleProps) {
  return (
    <Listbox value={getValue()} onChange={onChange} multiple>
      <div className="relative w-full">
        <div className="group relative flex flex-1 items-center text-gray-500 hover:text-gray-600 ui-open:text-gray-600">
          <ListboxButton
            className={twMerge(
              "flex h-9 w-full items-center gap-1 rounded-lg border border-gray-300 bg-white px-3 text-start group-hover:bg-gray-50 ui-open:bg-gray-50 ui-open:outline-none ui-open:outline-2 ui-open:outline-offset-0 ui-open:outline-gray-200",
              getValue().length > 0 && "border-gray-400",
            )}
          >
            <span className={twMerge("text-xs font-medium leading-3", getValue().length > 0 && "text-gray-700")}>
              {label}
            </span>
            {getValue().length > 0 && (
              <span className="text-xs font-medium leading-3 text-blue-500">{getValue().length}</span>
            )}
            <div className={"absolute inset-y-0 right-0 flex items-center pr-2"}>
              <Icon.AltArrowDown className="h-4 w-4 ui-open:rotate-180" aria-hidden="true" />
            </div>
          </ListboxButton>
        </div>
        <Transition leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <ListboxOptions
            className={
              "absolute mt-[42px] w-full overflow-hidden rounded-lg border border-gray-300 bg-white text-sm shadow-lg empty:hidden"
            }
          >
            {data.map((item, index) => (
              <ListboxOption
                key={index}
                value={item.value}
                className={({ focus, selected }) =>
                  twMerge(
                    "flex h-9 cursor-pointer select-none items-center gap-1 bg-white p-3 text-gray-500",
                    focus && "bg-gray-50 text-gray-600",
                    selected && "bg-blue-50 text-blue-600",
                    focus && selected && "opacity-65",
                  )
                }
              >
                <CustomCheckBox size="sm" enabled={getValue().includes(item.value)} disabled={true} />
                <div className="text-xs font-medium">{item.name}</div>
              </ListboxOption>
            ))}
            <div
              className="flex h-9 cursor-pointer select-none items-center gap-1 border-t border-gray-300 p-3 text-gray-500 hover:bg-gray-50"
              onClick={() => onChange([])}
            >
              <Icon.Restart />
              <div className="text-xs font-medium">초기화</div>
            </div>
          </ListboxOptions>
        </Transition>
      </div>
    </Listbox>
  );
}
