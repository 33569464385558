import styled from "@emotion/styled";
import * as C from "../../commons/styled";
import { LastShadowBox, ShadowBox } from "../../../../components/styled/webApp";
import { Button, Checkbox, Flex } from "@chakra-ui/react";
import { colors } from "../../../../components/styled/common";
import { allTime } from "../../../../lib/utils/dataFormat";
import { IOpenModal, IOperateInfo } from "../../commons/types";
import { Dispatch, SetStateAction } from "react";
import { dayArr, holidayArr, onPushItems, weeklyArr } from "../../commons/func";
import { useLocation } from "react-router-dom";
import GrayColorSelect from "../../../../components/select";

const DateDivision = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 0.875rem;
    width: 5.5rem;
  }
`;

interface IOperationTime extends IOpenModal {
  timeObj: IOperateInfo;
  setTimeObj: Dispatch<SetStateAction<IOperateInfo>>;
}

type ITime =
  | "start_time_weekday"
  | "end_time_weekday"
  | "start_time_holiday"
  | "end_time_holiday";

export const OperationTime = ({ isModal, ...props }: IOperationTime) => {
  const onChangeTime = (time: string, _id: ITime) => {
    const newObj = { ...props.timeObj };
    newObj[_id] = time;
    props.setTimeObj(newObj);
  };

  const onCloseWeekend = (isChecked: boolean) => {
    // console.log(isChecked);
    const newObj = { ...props.timeObj };
    if (isChecked) {
      newObj.end_time_holiday = "none";
      newObj.start_time_holiday = "none";
      props.setTimeObj(newObj);
    } else {
      newObj.end_time_holiday = "00:00";
      newObj.start_time_holiday = "00:00";
      props.setTimeObj(newObj);
    }
  };

  return (
    <>
      <ShadowBox isModal={isModal}>
        <div>
          <h4>운영시간을 알려주세요. {!isModal && <span>*</span>}</h4>
          <DateDivision>
            <p>평일(월-금)</p>
            <C.TimeRange>
              <GrayColorSelect
                value={props.timeObj.start_time_weekday || "00:00"}
                _style={{ background: colors.gray1 }}
                _onChange={(e) =>
                  onChangeTime(e.target.value, "start_time_weekday")
                }
              >
                {allTime.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </GrayColorSelect>
              <span>-</span>
              <GrayColorSelect
                _style={{ background: colors.gray1 }}
                value={props.timeObj.end_time_weekday || "00:00"}
                _onChange={(e) =>
                  onChangeTime(e.target.value, "end_time_weekday")
                }
              >
                {allTime.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </GrayColorSelect>
            </C.TimeRange>
          </DateDivision>
          <DateDivision style={{ margin: "0.75rem 0 0.5rem" }}>
            <p>주말</p>
            <C.TimeRange>
              <GrayColorSelect
                disabled={props.timeObj.start_time_holiday === "none"}
                _style={{ background: colors.gray1 }}
                value={props.timeObj.start_time_holiday || "00:00"}
                _onChange={(e) =>
                  onChangeTime(e.target.value, "start_time_holiday")
                }
              >
                {allTime.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </GrayColorSelect>
              <span>-</span>
              <GrayColorSelect
                disabled={props.timeObj.start_time_holiday === "none"}
                _style={{ background: colors.gray1 }}
                value={props.timeObj.end_time_holiday || "00:00"}
                _onChange={(e) =>
                  onChangeTime(e.target.value, "end_time_holiday")
                }
              >
                {allTime.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </GrayColorSelect>
            </C.TimeRange>
          </DateDivision>
          <DateDivision>
            <p />
            <Checkbox
              variant="default"
              isChecked={props.timeObj.start_time_holiday === "none"}
              onChange={(e) => onCloseWeekend(e.target.checked)}
            >
              주말운영안함
            </Checkbox>
          </DateDivision>
        </div>
      </ShadowBox>
    </>
  );
};

const DayoffBox = styled.div`
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid ${colors.gray1};
`;

const HolidayBundle = styled(Button)`
  :first-of-type {
    border-radius: 0.5rem 0 0 0.5rem;
  }
  :nth-of-type(2) {
    border-radius: 0;
    border-left: 1px solid ${colors.gray2};
    border-right: 1px solid ${colors.gray2};
  }
  :last-of-type {
    border-radius: 0 0.5rem 0.5rem 0;
    margin-right: 0.5rem;
  }
`;

interface IClosedDays extends IOpenModal {
  holidays: string[];
  setHolidays: Dispatch<SetStateAction<string[]>>;
  isMonthly: boolean;
  setIsMonthly: Dispatch<React.SetStateAction<boolean>>;
}

export const ClosedDays = ({ isModal, ...props }: IClosedDays) => {
  const newArr = holidayArr.flat();
  const location = useLocation();
  const isReserved = location.pathname.includes("booking"); //필수값 여부 확인

  return (
    <>
      <LastShadowBox isModal={isModal}>
        <div>
          <h4>휴무일이 있나요? {!isModal && !isReserved && <span>*</span>}</h4>
          <Flex>
            <C.ActiveButton
              variant="m_primary"
              clicked={String(props.holidays.includes("yes"))}
              onClick={() =>
                !props.holidays.includes("yes") && props.setHolidays(["yes"])
              }
            >
              휴무일이 있어요.
            </C.ActiveButton>
            <C.ActiveButton
              variant="m_primary"
              clicked={String(props.holidays.includes("none"))}
              onClick={() =>
                !props.holidays.includes("none") && props.setHolidays(["none"])
              }
            >
              휴무일이 없어요.
            </C.ActiveButton>
          </Flex>
          {props.holidays.includes("yes") && (
            <DayoffBox>
              <div style={{ paddingBottom: "1rem" }}>
                <h4>정기 휴무일</h4>
                <div>
                  <GrayColorSelect
                    value={props.isMonthly ? "monthly" : "weekly"}
                    _style={{ textAlign: "left", marginBottom: "0.75rem" }}
                    _onChange={(e) => {
                      let newArr = [];
                      const intervalWeek = [...props.holidays];
                      const temp = intervalWeek.filter(
                        (el) => !el.includes("째")
                      );
                      if (e.target.value === "weekly") {
                        newArr = [...temp, ...weeklyArr];
                        props.setIsMonthly(false);
                        props.setHolidays(newArr);
                      } else {
                        props.setIsMonthly(true);
                        props.setHolidays([...temp]);
                      }
                    }}
                  >
                    <option value="monthly">매달</option>
                    <option value="weekly">매주</option>
                  </GrayColorSelect>
                  {props.isMonthly && (
                    <Flex flexWrap="wrap">
                      {weeklyArr.map((el) => (
                        <Button
                          key={el}
                          variant={
                            props.holidays.includes(el) ? "m_primary" : "m_gray"
                          }
                          p="12px"
                          m="0 4px 12px 0"
                          style={{ fontWeight: 400 }}
                          onClick={() =>
                            onPushItems(el, props.holidays, props.setHolidays)
                          }
                        >
                          {el}
                        </Button>
                      ))}
                    </Flex>
                  )}
                  <Flex flexWrap="wrap">
                    {dayArr.map((el) => (
                      <Button
                        key={el}
                        variant={
                          props.holidays.includes(`${el}요일`)
                            ? "m_primary"
                            : "m_gray"
                        }
                        p="12px"
                        m="0 4px 12px 0"
                        style={{ fontWeight: 400 }}
                        onClick={() =>
                          onPushItems(
                            `${el}요일`,
                            props.holidays,
                            props.setHolidays
                          )
                        }
                      >
                        {el}
                      </Button>
                    ))}
                  </Flex>
                </div>
              </div>
              <div>
                <h4>다음 공휴일 중 휴무일이 있나요?</h4>
                <div>
                  <Checkbox
                    variant="default"
                    onChange={(e) => {
                      const origin = props.holidays.filter(
                        (el) => el.includes("요일") || el.includes("째")
                      );
                      // console.log(origin);
                      e.target.checked
                        ? props.setHolidays([...props.holidays, ...newArr])
                        : props.setHolidays(["yes", ...origin]);
                    }}
                  >
                    전체 휴무
                  </Checkbox>
                  <Flex flexWrap="wrap" mt="0.75rem">
                    {holidayArr.map((el) => {
                      if (typeof el === "string")
                        return (
                          <Button
                            key={el}
                            variant={
                              props.holidays.includes(el)
                                ? "m_primary"
                                : "m_gray"
                            }
                            style={{
                              margin: "0 0.5rem 0.5rem 0",
                              fontWeight: 400,
                            }}
                            onClick={() =>
                              onPushItems(el, props.holidays, props.setHolidays)
                            }
                          >
                            {el}
                          </Button>
                        );
                      return (
                        <div
                          key={JSON.stringify(el)}
                          style={{ marginBottom: "0.5rem" }}
                        >
                          {el.map((ele) => (
                            <HolidayBundle
                              key={ele}
                              variant={
                                props.holidays.includes(ele)
                                  ? "m_primary"
                                  : "m_gray"
                              }
                              fontWeight={400}
                              onClick={() =>
                                onPushItems(
                                  ele,
                                  props.holidays,
                                  props.setHolidays
                                )
                              }
                            >
                              {ele.includes("-") || ele.includes("+")
                                ? "연휴"
                                : ele}
                            </HolidayBundle>
                          ))}
                        </div>
                      );
                    })}
                  </Flex>
                </div>
              </div>
            </DayoffBox>
          )}
        </div>
      </LastShadowBox>
    </>
  );
};

// export const OperationBox = () => {
//   const { onOpen, isOpen, onClose } = useDisclosure(); //되돌아가기
//   return (
//     <>
//       <OperationTime />
//       <ClosedDays />
//       <ResponsiveConfirm
//         next="이동"
//         before="취소"
//         title="이전 단계 이동"
//         isOpen={isOpen}
//         onClose={onClose}
//         onAction={() => console.log("스테이트써야")}
//       >
//         <>
//           {isOpen ? (
//             <>
//               이전 단계로 이동할까요? <br />
//               이전 단계로 이동 시 입력한 운영시간 정보는 모두 사라집니다.
//             </>
//           ) : (
//             <>작성하신 정보로 시설을 등록할까요?</>
//           )}
//         </>
//       </ResponsiveConfirm>
//       <Flex>
//         <Button
//           variant="m_gray"
//           fontSize="1.25rem"
//           w="100%"
//           mr="1rem"
//           onClick={onOpen}
//         >
//           이전
//         </Button>
//         <Button variant="m_blue" fontSize="1.25rem" w="100%">
//           다음
//         </Button>
//       </Flex>
//     </>
//   );
// };
