import { Button, Text, useDisclosure } from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import * as C from "../../../components/styled/index";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import SwiperModal from "./swiperModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../../lib/api/axios";
import { IMeal } from "../../../lib/types/Imodels";
import { delete_meal_plan, detail_meal_planner } from "../../../lib/api/queries/urls";
import { detail_meal_planner_key } from "../../../lib/api/queries/keys";
import moment from "moment";
import CancleModal from "../../../components/modal/CancelModal";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import useUserAuth from "../../../components/hooks/useUserAuth";
import FullPageSpinner from "../../../components/Spinner";
import NotFound from "../../not-found";
import GetAuthorized from "../../../components/Authrized";
import { toast } from "../../../lib/utils/toast";
import { useContext } from "react";
import { GlobalContext } from "../../../App";

const ImageBox = styled(C.DetailInfoBox)`
  flex-wrap: wrap;
  img {
    width: 160px;
    height: 160px;
    object-fit: fill;
    margin-right: 16px;
    cursor: pointer;
  }
`;

export default function DetailMealPlan() {
  const navigate = useNavigate();
  const { plannerId } = useParams();
  const auth = useUserAuth("notice");
  const { buildInfo } = useContext(GlobalContext);
  const hasFunc = buildInfo?.services.CAFETERIA?.enabled;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDel, onOpen: onDel, onClose: offDel } = useDisclosure();
  const isEdit = auth === "edit";

  const { data } = useQuery([detail_meal_planner_key, plannerId], () =>
    fetchClient
      .post<BaseResponse<IMeal>>(detail_meal_planner, {
        cafeteria_id: plannerId,
      })
      .then((res) => res.data.data)
      .catch(() => navigate(-1)),
  );

  const onClickMoveToEditPlan = () => {
    navigate(`/cafeteria/${plannerId}/edit`);
  };

  const mutation = useMutation(() =>
    fetchClient
      .post(delete_meal_plan, { cafeteria_id: plannerId })
      .then(() => {
        toast.success({
          title: "식단표 삭제 성공",
          description: "해당 식단표를 삭제했습니다. 식단표 조회 페이지로 이동합니다.",
        });
      })
      .catch(() => {
        toast.warning({
          title: "알림",
          description:
            "일시적으로 식단표 삭제에 실패했습니다. 새로고침 후에도 동일한 오류가 반복될 경우 오피스너 담당자에게 문의해주세요.",
        });
      }),
  );

  if (hasFunc === undefined || !auth) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;
  if (auth === "no_auth") return <GetAuthorized />;
  return (
    <C.Wrapper>
      <Text variant="static">
        <C.LeftIcons onClick={() => navigate(-1)} />
        식단표
      </Text>
      <C.DetailViewHeader style={{ paddingRight: "0" }}>
        <C.DetailTitleBox>
          <Text>{data?.title}</Text>
        </C.DetailTitleBox>
        {isEdit && (
          <C.TitleAlign>
            <Button variant="blueLine" onClick={onClickMoveToEditPlan}>
              게시물 수정
            </Button>
            <Button onClick={onDel} variant="delete">
              삭제
            </Button>
            <CancleModal
              isOpen={isDel}
              onClose={offDel}
              onClickCancel={offDel}
              onClickSave={() => {
                if (mutation.isLoading) return;
                mutation.mutate();
              }}
            >
              <Text>해당 식단표를 삭제할까요?</Text>
              <Text>삭제된 데이터는 복구할 수 없습니다.</Text>
            </CancleModal>
          </C.TitleAlign>
        )}
      </C.DetailViewHeader>
      <C.DetailInfoBox>
        <Text>
          날짜
          <C.InfoBoxText>
            {moment(data?.start_date).format(" YYYY-MM-DD ~ ")}
            {moment(data?.end_date).format("YYYY-MM-DD")}
          </C.InfoBoxText>
        </Text>
        <Text>
          등록일시
          <C.InfoBoxText>{data?.createdAt ? moment.utc(data?.createdAt).format(" YYYY-MM-DD") : ""}</C.InfoBoxText>
        </Text>
      </C.DetailInfoBox>
      {data?.pictures.length === 0 ? (
        <></>
      ) : (
        <ImageBox>
          {data?.pictures.map((el, index) => <img src={el} alt="식단표" key={el + String(index)} onClick={onOpen} />)}
        </ImageBox>
      )}
      <SwiperModal isOpen={isOpen} onClose={onClose} data={data?.pictures || []} />
      <C.DetailInfoBox>
        <Text>
          내용 <C.InfoBoxText>{data?.body || ""}</C.InfoBoxText>
        </Text>
      </C.DetailInfoBox>
    </C.Wrapper>
  );
}
