import { useMutation, useQuery } from "@tanstack/react-query";
import { BaseResponse } from "../lib/api/queries/commons/types";
import { IRoom } from "../lib/types/Imodels";
import { success, warning } from "../lib/theme/toast";
import { queryClient } from "..";
import { room_list_or_detail } from "../lib/api/queries/keys";
import { useToast } from "@chakra-ui/react";
import { http } from "../lib/http";
import { httpV2 } from "../lib/httpV2";

export const useRoomDeleteMutation = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: async (room_id: string) => {
      return http.post<{ room_id: string }, BaseResponse<IRoom>>("/building/room/delete", {
        room_id,
      });
    },
    onSuccess: () => {
      toast({
        ...success,
        title: "호실 삭제 완료",
        description: "호실 삭제가 완료되었습니다.",
      });
      queryClient.invalidateQueries([room_list_or_detail, "list"]);
    },
    onError: () => {
      toast({
        ...warning,
        title: "호실 삭제 실패",
        description: "호실 삭제에 실패했습니다. 새로고침 후 다시 시도해주세요.",
      });
    },
  });
};

type Params = {
  buildingId: string;
  searchText: string;
  roomType?: "office" | "store" | "residential" | "etc";
  dong: string;
  floor: string;
  page: number;
  limit?: number;
};

type Response = {
  rooms: Array<{
    _id: string;
    dong: string;
    floor: string;
    ho: string;
    roomType: "office" | "store" | "residential" | "etc";
    owners: Array<{
      userId: string;
      name: string;
      phoneNumber: string;
    }>;
    companyInfo: {
      name: string;
      isCompany: boolean;
    };
  }>;
  totalCount: number;
};

export const useRoomsQuery = (
  { buildingId, searchText, roomType, dong, floor, page = 1, limit }: Params,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ["/rooms", buildingId, searchText, roomType, dong, floor, page, limit],
    queryFn: async () =>
      httpV2.get<Response>("rooms", {
        buildingId,
        searchText,
        roomType,
        dong,
        floor,
        page,
        limit,
      }),
    enabled,
  });
};
