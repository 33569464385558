import * as C from "../../commons/styled";
import { ShadowBox } from "../../../../components/styled/webApp";
import { Checkbox, Flex } from "@chakra-ui/react";
import { IOpenModal } from "../../commons/types";
import { Dispatch, memo, SetStateAction } from "react";
interface IClickedBoolean extends IOpenModal {
  isActive: boolean | undefined;
  onUpdateActive: (status: boolean) => void;
}

const NeedPermission = ({ isModal, ...props }: IClickedBoolean) => {
  return (
    <>
      <ShadowBox isModal={isModal}>
        <div>
          <h4>예약 확정 방식을 선택해주세요. {!isModal && <span>*</span>}</h4>
          <C.ActiveButton
            variant="m_primary"
            clicked={
              props.isActive === undefined ? "false" : String(!props.isActive)
            }
            onClick={() => props.onUpdateActive(false)}
          >
            예약신청과 동시에 확정
          </C.ActiveButton>
          <C.ActiveButton
            variant="m_primary"
            clicked={
              props.isActive === undefined ? "false" : String(props.isActive)
            }
            onClick={() => props.onUpdateActive(true)}
          >
            관리자 확인 후 확정
          </C.ActiveButton>
        </div>
      </ShadowBox>
    </>
  );
};

export const MemoizedPermission = memo(NeedPermission);

// 현재는 예약방식 필요없음
// export const BookingRole = ({ isModal }: IOpenModal) => {
//   return (
//     <>
//       <C.ShadowBox isModal={isModal}>
//         <div>
//           <h4>원하는 예약 방식을 선택해주세요.</h4>
//           <Flex>
//             <C.ActiveButton clicked={true} style={{ minWidth: "7rem" }}>
//               금액까지 안내
//             </C.ActiveButton>
//             <C.ActiveButton clicked={false} style={{ minWidth: "7rem" }}>
//               관리자 확인 후 확정
//             </C.ActiveButton>
//           </Flex>
//         </div>
//       </C.ShadowBox>
//     </>
//   );
// };

import { IPaymentMethod } from "../../../../lib/types/Imodels";
import GrayRadiusInput from "../../../../components/Input/responsive";
import GrayRadiusTextArea from "../../../../components/textarea";
import GrayColorSelect from "../../../../components/select";
import styled from "@emotion/styled";

const ChoosePayment = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem 0;
  border-bottom: 1px solid #f4f4f5;
  select {
    text-align: left;
  }
  input {
    width: 11rem;
    text-align: right;
    :disabled {
      opacity: 0.7;
    }
  }
`;

// const PaymentDivisionBtn = styled.button`
//   font-weight: 700;
//   :disabled {
//     opacity: 0.7;
//     cursor: not-allowed;
//   }
// `;

interface IPaymentItem {
  el: IPaymentMethod;
  isDisabled: boolean;
  method: IPaymentMethod[];
  setMethod: Dispatch<SetStateAction<IPaymentMethod[]>>;
}

const PaymentItem = ({ el, isDisabled, method, setMethod }: IPaymentItem) => {
  return (
    <ChoosePayment>
      <GrayColorSelect defaultValue={el.payment_method} disabled={isDisabled}>
        {/* <option value="" disabled>
          결제수단
        </option> */}
        <option value="point">포인트</option>
        {/* <option value="direct">현장결제</option> */}
      </GrayColorSelect>
      <GrayRadiusInput
        disabled={isDisabled}
        placeholder="-"
        value={el.price ? el.price : ""}
        _onChange={(e) => {
          if (Number.isNaN(Number(e.target.value))) return;
          const newObj = { ...el };
          newObj.price =
            e.target.value === "" ? "" : String(Number(e.target.value));
          setMethod(
            method.map((ele) => (ele._id === newObj._id ? newObj : ele))
          );
        }}
      />
      {/* 구분 추가 임시 삭제
      {method[0]._id !== el._id && (
        <BookingDeleteIcon
          style={{ top: "10px", right: "calc(100% - 19.5rem)" }}
          onClick={() => {
            const newArr = [...method];
            setMethod(newArr.filter((ele) => ele._id !== el._id));
          }}
        />
      )} */}
    </ChoosePayment>
  );
};

interface IMethodFC extends IOpenModal {
  method: IPaymentMethod[];
  setMethod: Dispatch<SetStateAction<IPaymentMethod[]>>;
  isFree: boolean;
  onUpdateIsFree: (isFree: boolean) => void;
}

export const PaymentMethod = ({ isModal, ...props }: IMethodFC) => {
  return (
    <>
      <ShadowBox isModal={isModal}>
        <div>
          <h4>
            결제수단 구분과 결제금액을 입력해주세요.
            {!isModal && <span> *</span>}
          </h4>
          <Checkbox
            variant="default"
            isChecked={props.isFree}
            onChange={(e) => props.onUpdateIsFree(e.target.checked)}
          >
            무료 이용
          </Checkbox>
          {props.method.map((el) => (
            <PaymentItem
              key={el._id}
              el={el}
              method={props.method}
              setMethod={props.setMethod}
              isDisabled={props.isFree}
            />
          ))}
          {/* 구분 추가 임시 삭제
          <MoreTimeButton style={{ marginTop: "0.5rem" }}>
            <PaymentDivisionBtn
              disabled={props.isFree}
              onClick={() => {
                const newArr = [...props.method];
                newArr.push({
                  _id: uuidv4(),
                  payment_method: "point",
                  price: "",
                });
                props.setMethod(newArr);
              }}
            >
              + 구분 추가
            </PaymentDivisionBtn>
          </MoreTimeButton> */}
        </div>
      </ShadowBox>
    </>
  );
};

interface IPaymentInfo extends IOpenModal {
  paymentInfo: string;
  setPaymentInfo: Dispatch<SetStateAction<string>>;
}

const PaymentInfo = ({ isModal, ...props }: IPaymentInfo) => {
  return (
    <>
      <ShadowBox isModal={isModal}>
        <div>
          <h4>결제 안내 사항을 적어주세요.</h4>
          <GrayRadiusTextArea
            rows={1}
            value={props.paymentInfo}
            placeholder="결제 관련 안내 사항을 적어주세요."
            isLimited={true}
            _onChange={(e) => {
              if (e.target.value.length > 1000) return;
              props.setPaymentInfo(e.target.value);
            }}
          />
        </div>
      </ShadowBox>
    </>
  );
};

export const MemoizedPaymentInfo = memo(PaymentInfo);

const MoreRequest = ({ isModal, ...props }: IClickedBoolean) => {
  return (
    <>
      <ShadowBox isModal={isModal}>
        <div>
          <h4>추가 요청사항을 받을까요?</h4>
          <Flex>
            <C.ActiveButton
              variant="m_primary"
              clicked={
                props.isActive === undefined ? "false" : String(props.isActive)
              }
              onClick={() => props.onUpdateActive(true)}
            >
              받을래요.
            </C.ActiveButton>
            <C.ActiveButton
              variant="m_primary"
              clicked={
                props.isActive === undefined ? "false" : String(!props.isActive)
              }
              onClick={() => props.onUpdateActive(false)}
            >
              안받을래요.
            </C.ActiveButton>
          </Flex>
        </div>
      </ShadowBox>
    </>
  );
};

export const MemoizedMoreRequest = memo(MoreRequest);
