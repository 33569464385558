import { useContext } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import SelectConditionBox from "./condition";
import SelectConditionButton from "./conditionButton";
import { GlobalContext } from "../../../../App";
import { useBuildingFloor } from "../../../../lib/api/queries/commons";
import { useSearchParams } from "react-router-dom";
import { ICheckDongFloor } from "../../timeline/_view/types";

export default function CheckLocationBox({ dongArr, floorArr, setDongArr, setFloorArr }: ICheckDongFloor) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { buildInfo: data } = useContext(GlobalContext);
  const { data: floor } = useBuildingFloor();

  const onUpdateArrParams = (value: string, arr: string[]) => {
    searchParams.delete(value);
    arr.forEach((el) => searchParams.append(value, el));
    setSearchParams(searchParams);
  };

  const onCheckedDong = (checked: boolean) => {
    if (checked) {
      const allCheckedArr: string[] = [];
      data?.dong_floor.forEach((el) => allCheckedArr.push(el.dong));
      if (setDongArr) return setDongArr(allCheckedArr);
      onUpdateArrParams("dong", allCheckedArr);
    } else {
      if (setDongArr) return setDongArr([]);
      searchParams.delete("dong");
      setSearchParams(searchParams);
    }
  };

  const onChangeDongChecked = (checked: boolean, id: string) => {
    if (checked) {
      const arr = [...dongArr, id];
      if (setDongArr) return setDongArr(arr);
      onUpdateArrParams("dong", arr);
    } else {
      const newArr = dongArr.filter((checked) => checked !== id);
      if (setDongArr) return setDongArr(newArr);
      onUpdateArrParams("dong", newArr);
    }
    if (dongArr.length === data?.dong_floor.length) {
      return (checked = true);
    }
  };

  const onCheckedFloor = (checked: boolean) => {
    if (checked) {
      const allCheckedArr: string[] = [];
      floor?.forEach((ele) => allCheckedArr.push(ele));
      if (setFloorArr) return setFloorArr(allCheckedArr);
      onUpdateArrParams("floor", allCheckedArr);
    } else {
      if (setFloorArr) return setFloorArr([]);
      searchParams.delete("floor");
      setSearchParams(searchParams);
    }
  };

  const onChangeFloorChecked = (checked: boolean, id: string) => {
    if (checked) {
      const arr = [...floorArr, id];
      if (setFloorArr) return setFloorArr(arr);
      onUpdateArrParams("floor", arr);
    } else {
      const newArr = floorArr.filter((checked) => checked !== id);
      if (setFloorArr) return setFloorArr(newArr);
      onUpdateArrParams("floor", newArr);
    }

    if (floorArr.length === floor?.length) checked = true;
  };

  return (
    <Box>
      <Text variant="default">시설 위치</Text>
      <Flex>
        <SelectConditionBox
          checkedItems={dongArr}
          onCheckedAll={onCheckedDong}
          default="전체"
          text={dongArr.length === 0 ? "동 선택" : dongArr}
          typeArr={data?.dong_floor.map((el) => el.dong)}
        >
          {data?.dong_floor?.map((el) => (
            <SelectConditionButton
              key={el._id}
              checkedItems={dongArr}
              onChangeChecked={onChangeDongChecked}
              value={el.dong}
            />
          ))}
        </SelectConditionBox>
        <SelectConditionBox
          checkedItems={floorArr}
          onCheckedAll={onCheckedFloor}
          default="전체"
          text={floorArr.length === 0 ? "층 선택" : floorArr}
          typeArr={floor}
        >
          {floor?.map((el) => (
            <SelectConditionButton key={el} checkedItems={floorArr} onChangeChecked={onChangeFloorChecked} value={el} />
          ))}
        </SelectConditionBox>
      </Flex>
    </Box>
  );
}
