import { useMutation } from "@tanstack/react-query";
import { useEffect, useState, MouseEvent, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { complaint_admin_memo, complaint_delete } from "../../../lib/api/queries/urls";
import { IReqComplaint } from "../../../lib/types/Imodels";
import debounce from "lodash/debounce";
import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";
import { success, warning } from "../../../lib/theme/toast";
import { useFetchComplaint } from "../commons/queries";
import RequestDetailComponent from "./_view/presenter";
import useUserAuth from "../../../components/hooks/useUserAuth";
import FullPageSpinner from "../../../components/Spinner";
import NotFound from "../../not-found";
import GetAuthorized from "../../../components/Authrized";
import { GlobalContext } from "../../../App";

export default function ComplaintDetailPage() {
  const { buildInfo } = useContext(GlobalContext);
  const toast = useToast();
  const navigate = useNavigate();
  const { complaintId } = useParams();
  const auth = useUserAuth("complaint");
  const hasFunc = buildInfo?.services.COMPLAINT?.enabled;

  const [adminMemo, setAdminMemo] = useState("");
  const [isOpen, setIsOpen] = useState(false); //관리자 직접 등록시 내용 수정, 삭제

  const { data, refetch } = useFetchComplaint(complaintId);

  const onClickRequestAction = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    setIsOpen(false);
    navigate(`/complaints/${complaintId}/edit`);
  };

  useEffect(() => {
    if (!data) return;

    setAdminMemo(data.description || "");
  }, [data]);

  const onSaveMemo = debounce((value: string) => {
    // console.log(value);
    setAdminMemo(value);
    fetchClient
      .post<BaseResponse<IReqComplaint>>(complaint_admin_memo, {
        complaint_id: complaintId,
        description: value,
      })
      .then(() => refetch())
      .catch((err: AxiosError) => {
        toast({
          ...warning,
          title: "관리자 메모 등록 오류",
          description:
            err.response?.status === 401
              ? "관리자 메모 등록 권한이 없습니다. 계정 확인 후 다시 시도해주세요."
              : "일시적으로 메모 등록에 실패했습니다. 새로고침 후에도 동일한 오류가 반복될 경우 오피스너 담당자에게 문의해주세요.",
        });
      });
  }, 1000);

  const mutation = useMutation(
    () => fetchClient.post(complaint_delete, { complaint_id: complaintId }).then((res) => res.data.data),
    {
      onSuccess: () => {
        toast({
          ...success,
          title: "민원 삭제 완료",
          description: "해당 민원이 성공적으로 삭제되었습니다.",
        });
        navigate("/complaints", { replace: true });
      },
      onError: (err: AxiosError) => {
        toast({
          ...warning,
          title: "민원 삭제 오류",
          description:
            err.response?.status === 401
              ? "게시물 삭제 권한이 없습니다. 계정 확인 후 다시 시도해주세요."
              : "일시적으로 민원 삭제에 실패했습니다. 새로고침 후에도 동일한 오류가 반복될 경우 오피스너 담당자에게 문의해주세요.",
        });
      },
    },
  );

  const onDeleteRequest = () => {
    if (mutation.isLoading) return;
    mutation.mutate();
  };

  if (!auth || hasFunc === undefined || !data) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;
  if (auth === "no_auth") return <GetAuthorized />;
  return (
    <RequestDetailComponent
      data={data}
      adminMemo={adminMemo}
      onSaveMemo={onSaveMemo}
      onClickRequestAction={onClickRequestAction}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onDeleteRequest={onDeleteRequest}
      auth={auth}
    />
  );
}
