import moment from "moment";
import { useNavigate } from "react-router-dom";
import * as C from "../../components/styled/index";
import { IMeal } from "../../lib/types/Imodels";

export default function MealTableItem({ el }: { el: IMeal }) {
  const navigate = useNavigate();

  const onClickMoveToDetailPlan = () => {
    navigate(`/cafeteria/${el._id}`);
  };

  return (
    <div
      className="cursor-pointer border-b border-gray-400 hover:bg-blue-50 hover:opacity-75"
      onClick={onClickMoveToDetailPlan}
    >
      <div style={{ width: "30%" }}>
        {moment(el.start_date).format("YYYY-MM-DD")} ~{moment(el.end_date).format(" YYYY-MM-DD")}
      </div>
      <C.FixedTitle style={{ width: "45%" }}>{el.title}</C.FixedTitle>
      <div className="w-1/4">{moment.utc(el.createdAt).format("YYYY-MM-DD")}</div>
    </div>
  );
}
