import { forwardRef, useState } from "react";
import IconCalendar from "../../../components/icons/IconCalendar";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import { useVoteExtendTimeMutation } from "../../../requests/vote";
import DatePicker from "react-datepicker";
import { useToast } from "@chakra-ui/react";

type Props = {
  voteId: string;
  isOpen: boolean;
  onClose: () => void;
  startAt: string;
  endAt: string;
};

export default function VoteExtendTimeModal({ isOpen, onClose, startAt, endAt, voteId }: Props) {
  const [endDate, setEndDate] = useState<Date>(new Date(endAt));
  const { mutateAsync: extendTime } = useVoteExtendTimeMutation();
  const toast = useToast();

  const oneDayFromNow = new Date();
  oneDayFromNow.setDate(oneDayFromNow.getDate() + 1);

  const EndTimeInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <div
      ref={ref}
      onClick={onClick}
      className="flex h-12 w-[200px] cursor-pointer items-center gap-2 rounded-lg border border-gray-400 px-3 transition-all hover:bg-gray-50"
    >
      <IconCalendar className="h-5 w-5" />
      <p className="text-base font-medium">{endDate?.toLocaleDateString()}</p>
    </div>
  ));

  const formatDateToISO = (date: Date) => {
    const pad = (num: number) => num.toString().padStart(2, "0");
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}`;
  };

  return (
    <ConfirmModal
      title="투표 기간 연장"
      close="연장하기"
      onClickCancle={onClose}
      isOpen={isOpen}
      onClose={onClose}
      width={488}
      size={"lg"}
      onClickSave={() => {
        console.info(formatDateToISO(endDate));
        toast.promise(extendTime({ voteId: voteId, expireDate: formatDateToISO(endDate) }), {
          success: { position: "top", title: "투표 기간이 연장되었습니다." },
          error: { position: "top", title: "투표 기간 연장에 실패했습니다." },
          loading: { position: "top", title: "투표 기간을 연장 중입니다." },
        });
        onClose();
      }}
    >
      <p className="text-base font-semibold text-gray-500">투표 마감기간 *</p>
      <p className="mt-3 text-gray-400">현재 투표 기간은 연장만 가능합니다.</p>
      <div className="mt-3 flex items-center gap-2">
        <div className="flex h-12 w-[200px] items-center gap-2 rounded-lg border border-gray-300 px-3 opacity-40">
          <IconCalendar className="h-5 w-5" />
          <p className="text-base font-medium">{new Date(startAt).toLocaleDateString()}</p>
        </div>
        <p className="w-2 text-base font-medium text-gray-300">-</p>
        <DatePicker
          selected={endDate || new Date(endAt)}
          onChange={(date) => setEndDate(date || new Date(endAt))}
          customInput={<EndTimeInput />}
          minDate={new Date(endAt)}
        />
      </div>
    </ConfirmModal>
  );
}
