import Button from "./button/Button";
import IconClose from "./icons/IconClose";
import { AnimatePresence, motion } from "framer-motion";
import { Dialog, DialogPanel } from "@headlessui/react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  required?: boolean;
  exit?: boolean;
  onSubmit?: () => void;
  primaryButtonText?: string;
  primaryButtonIcon?: React.ReactNode;
  onPrimaryButtonClick?: () => void;
  warningButtonText?: string;
  warningButtonIcon?: React.ReactNode;
  onWarningButtonClick?: () => void;
  neutralButtonText?: string;
  neutralButtonIcon?: React.ReactNode;
  onNeutralButtonClick?: () => void;
  errorButtonText?: string;
  errorButtonIcon?: React.ReactNode;
  onErrorButtonClick?: () => void;
  primaryButtonDisable?: boolean;
  warningButtonDisable?: boolean;
  inputRefs?: Array<React.RefObject<HTMLInputElement>>;
  children: React.ReactNode;
};

export default function CustomModal({
  isOpen,
  onClose,
  title = "",
  required = false,
  exit = false,
  onSubmit,
  primaryButtonText,
  primaryButtonIcon,
  onPrimaryButtonClick,
  warningButtonText,
  warningButtonIcon,
  onWarningButtonClick,
  neutralButtonText,
  neutralButtonIcon,
  onNeutralButtonClick,
  errorButtonText,
  errorButtonIcon,
  onErrorButtonClick,
  primaryButtonDisable = false,
  warningButtonDisable = false,
  children,
}: Props) {
  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <Dialog static open={isOpen} onClose={onClose} className="relative z-50 font-pre">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/30"
          />
          <div className="fixed inset-0 flex w-screen items-center justify-center">
            <DialogPanel
              as={motion.div}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="flex min-w-[400px] flex-col rounded-2xl bg-white"
            >
              <form onSubmit={onSubmit}>
                <div className="flex flex-1 items-center justify-end gap-2 p-6 pb-1 pr-5">
                  <span className="flex flex-1 text-2xl font-bold leading-9">{title}</span>
                  {required && <span className="text-base font-medium text-blue-600">* 필수</span>}
                  {exit && (
                    <button onClick={onClose} type="button">
                      <IconClose fill="#6B7280" className="size-6" />
                    </button>
                  )}
                </div>
                {children}
                <div className="flex justify-between p-6 pt-3">
                  <div className="flex gap-3">
                    {errorButtonText && (
                      <Button
                        designSchema="error"
                        size="md"
                        buttonText={errorButtonText}
                        iconElement={errorButtonIcon}
                        onClick={onErrorButtonClick}
                        type="button"
                      />
                    )}
                  </div>
                  <div className="flex gap-3">
                    {neutralButtonText && (
                      <Button
                        designSchema="neutral"
                        size="md"
                        buttonText={neutralButtonText}
                        iconElement={neutralButtonIcon}
                        onClick={onNeutralButtonClick}
                        type="button"
                      />
                    )}
                    {warningButtonText && (
                      <Button
                        designSchema="warning"
                        size="md"
                        buttonText={warningButtonText}
                        iconElement={warningButtonIcon}
                        onClick={onWarningButtonClick}
                        disabled={warningButtonDisable}
                        type="button"
                      />
                    )}
                    {primaryButtonText && (
                      <Button
                        designSchema="primary"
                        size="md"
                        buttonText={primaryButtonText}
                        iconElement={primaryButtonIcon}
                        onClick={onPrimaryButtonClick}
                        disabled={primaryButtonDisable}
                        type={onSubmit ? "submit" : "button"}
                      />
                    )}
                  </div>
                </div>
              </form>
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
}
