import { useMutation } from "@tanstack/react-query";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { queryClient } from "..";
import { BaseResponse } from "../lib/api/queries/commons/types";
import { auth } from "../lib/firebase";
import { IUser } from "../lib/types/Imodels";
import { useToast } from "@chakra-ui/react";
import { ILoginValues } from "../pages/Login";
import { warning } from "../lib/theme/toast";
import { http } from "../lib/http";

export const useSignInMutation = () => {
  const toast = useToast();
  return useMutation(["signin"], {
    mutationFn: async (data: ILoginValues) => {
      const userCredential = await signInWithEmailAndPassword(auth, data?.id, data?.pw);
      const response = await http.post<unknown, BaseResponse<IUser>>("/building/token/verify");
      queryClient.invalidateQueries(["building"]);
      if (response.data.company_type !== "building") {
        signOut(auth);
        throw new Error();
      }
      return userCredential;
    },
    onError: (err) => {
      console.error(err);
      toast({
        ...warning,
        title: "로그인 실패",
        description: "아이디 혹은 비밀번호를 정확히 입력해주세요.",
      });
    },
    onSuccess: (userCredential, { isChecked }) => {
      isChecked ? localStorage.setItem("user", userCredential.user.email || "") : localStorage.removeItem("user");
    },
  });
};
