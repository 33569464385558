import { Link, Outlet, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import useUserAuth from "../../components/hooks/useUserAuth";
import GetAuthorized from "../../components/Authrized";

export default function PointLayout() {
  const { pathname } = useLocation();
  const auth = useUserAuth("room_public");

  if (auth === "no_auth") return <GetAuthorized />;

  return (
    <div className="w-full">
      <div className="flex w-[8.5rem] items-center justify-center rounded-t-2xl bg-blue-600 py-3 text-white">
        포인트관리
      </div>
      <div className="flex items-center gap-3 px-6">
        <Link
          to={"/points"}
          className={twMerge(
            "flex h-16 items-center border-gray-900 leading-6 text-gray-400",
            pathname === "/points" && "border-b-2 font-bold text-gray-900",
          )}
        >
          이용내역
        </Link>
        <Link
          to={"/points/setting"}
          className={twMerge(
            "flex h-16 items-center border-gray-900 leading-6 text-gray-400",
            pathname === "/points/setting" && "border-b-2 font-bold text-gray-900",
          )}
        >
          기본설정
        </Link>
      </div>
      <Outlet />
    </div>
  );
}
