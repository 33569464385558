import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import moment from "moment";
import { queryClient } from "../../../..";
import CancleModal from "../../../../components/modal/CancelModal";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import * as S from "../../../../components/styled";
import { fetchClient } from "../../../../lib/api/axios";
import { register_card_detail_key, register_card_key } from "../../../../lib/api/queries/keys";
import { register_card_approval, register_card_delete, tenant_create } from "../../../../lib/api/queries/urls";
import { format_move_type, format_room } from "../../../../lib/utils/dataFormat";
import { ICardInfo } from "../../commons/type";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { ITenant } from "../../../../lib/types/Imodels";
import { onSendSMS } from "../../commons";
import { useContext, useRef } from "react";
import useUserAuth from "../../../../components/hooks/useUserAuth";
import { GlobalContext } from "../../../../App";
import { colors } from "../../../../components/styled/common";
import FileComponent from "../../../company/components/File";
import { toast } from "../../../../lib/utils/toast";

const ModalBackground = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: ${({ isOpen }) => (isOpen ? "100vh" : "0")};
  background-color: ${({ isOpen }) => (isOpen ? "#00000066" : "none")};
  display: flex;
  overflow-y: scroll;
  justify-content: center;
`;

const OpenModal = styled.div`
  width: 860px;
  height: fit-content;
  margin: 3.75rem 0;
  background-color: ${colors.whiteColor};
  border-radius: 4px;
  z-index: 100;
`;

const Header = styled.div`
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px 24px;
  border-bottom: 1px solid ${colors.gray3};
  span {
    color: ${colors.gray4};
    font-weight: 400;
    font-size: 18px;
  }
`;

const MaxWidth = styled(S.FixedTitle)<{ t: boolean }>`
  width: ${({ t }) => (t ? "412px" : "632px")};
`;

const Body = styled.div`
  padding: 32px 24px 0;
  text-align: left;
  span {
    color: ${colors.gray4};
    font-size: 18px;
  }
`;

const DoubleColumn = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.gray2};
  div {
    min-height: 56px;
    display: flex;
    align-items: center;
  }
`;

const TitleBox = styled.div`
  width: 20%;
  min-width: 168px;
  font-weight: 500;
`;

const ContentBox = styled.div`
  max-width: 80%;
  min-width: 240px;
  color: ${colors.gray4};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 44px;
    height: 44px;
    margin-right: 8px;
    cursor: pointer;
  }
`;

const Footer = styled.div<{ auth: string | undefined }>`
  padding: 20px 24px 32px;
  width: 100%;
  display: flex;
  justify-content: ${({ auth }) => (auth !== "edit" ? "flex-end" : "space-between")};
`;

export default function TenantCardModal(props: ICardInfo) {
  const { buildInfo } = useContext(GlobalContext);
  const onlyDIMC = String(buildInfo?._id) === "633d7820031ec8ac5a62a7a5";
  const auth = useUserAuth("tenant");
  const { isOpen, onClose, onOpen } = useDisclosure(); //승인 확인 모달
  const { isOpen: isRoom, onOpen: onRoom, onClose: Room } = useDisclosure(); //중복 호실 모달
  const { isOpen: isDelete, onClose: Delete, onOpen: onDelete } = useDisclosure(); //삭제 확인 모달
  const { isOpen: isFail, onClose: failClose, onOpen: onFail } = useDisclosure(); //등록 제한 모달

  const mutation = useMutation(
    (newArr: string[]) => {
      // 1. 호실 등록
      return fetchClient
        .post<BaseResponse<ITenant>>(tenant_create, {
          room_ids: newArr,
          name: props.data?.tenant_name,
          phone_number: props.data?.tenant_phone_number,
          resident_card_id: String(props.data?._id),
        })
        .then((res) => {
          // 2. 가입 요청
          return onSendSMS([String(res.data.data._id)]).then(() => {
            // 3. mutate
            return fetchClient
              .post(register_card_approval, {
                resident_card_id: String(props.data?._id),
              })
              .then(() => {
                queryClient.invalidateQueries([register_card_detail_key]);
                queryClient.invalidateQueries([register_card_key]);

                toast.success({
                  title: "입주사 등록 완료",
                  description: "입주사 등록이 완료되었습니다.",
                });
              })
              .catch((err: AxiosError) => console.error(err));
          });
        })
        .catch((err: AxiosError) => {
          err.response?.status === 400 && onFail();
          err.response?.status === 402 && onRoom();
        });
    },
    {
      onSuccess: () => onClose(),
    },
  );

  const onNotieFail = () => {
    failClose();
    onClose();
  };

  const onDeleteCard = () => {
    if (auth !== "edit") return toast.unAuthUser();

    fetchClient
      .post(register_card_delete, {
        resident_card_id: String(props.data?._id),
      })
      .then((res) => {
        queryClient.invalidateQueries([register_card_key]);

        toast.success({
          title: "입주카드 삭제 완료",
          description: "입주카드가 정상적으로 삭제되었습니다.",
        });
        Delete();
        props.onClose();
        return res.data.data;
      })
      .catch((err: AxiosError) => console.error(err));
  };

  const onUpdateCard = () => {
    if (props.data === undefined) return;
    if (auth !== "edit") return toast.unAuthUser();
    if (mutation.isLoading) return;
    const newArr = props.data.room_ids.map((el) => el._id);

    mutation.mutate(newArr);
  };

  const modalRef = useRef<HTMLDivElement>(null);
  if (props.data === undefined) return <></>;
  return (
    <ModalBackground
      isOpen={props.isOpen}
      ref={modalRef}
      onClick={(e) => modalRef.current === e.target && props.onClose()}
    >
      <OpenModal>
        <Header>
          <MaxWidth t={props.data.registration_type === "completed"}>{props.data.tenant_name} 입주카드</MaxWidth>
          <span>
            제출
            {moment.utc(props.data.createdAt).format(" YYYY-MM-DD HH:mm")}
            {props.data.registration_type === "completed" &&
              ` 등록 완료 ${moment.utc(props.data.updatedAt).format("YYYY-MM-DD HH:mm")}`}
          </span>
        </Header>
        <Body>
          <Text variant="title">1. 입주 정보</Text>
          <DoubleColumn>
            <TitleBox>동/호 *</TitleBox>
            <ContentBox>
              {props.data.room_ids[0].dong}동 {props.data.room_ids[0].ho}호
              {props.data.room_ids.length > 1 && ` 외 ${props.data.room_ids.length - 1}`}
            </ContentBox>
          </DoubleColumn>
          <DoubleColumn>
            <TitleBox>호실용도 *</TitleBox>
            <ContentBox>{format_room(props.data.room_type)}</ContentBox>
            <TitleBox>입주일 *</TitleBox>
            <ContentBox>{moment.utc(props.data.move_in_date).format("YYYY-MM-DD")}</ContentBox>
          </DoubleColumn>
          <DoubleColumn>
            <TitleBox>입주 형태 *</TitleBox>
            <ContentBox>{format_move_type(props.data.move_in_type || "")}</ContentBox>
          </DoubleColumn>
        </Body>
        <Body>
          <Text variant="title">2. 입주 현황</Text>
          <DoubleColumn>
            <TitleBox>업체명 *</TitleBox>
            <ContentBox>{props.data.tenant_name}</ContentBox>
            <TitleBox>사업자(주민)등록번호 *</TitleBox>
            <ContentBox>{props.data.tenant_registration_number}</ContentBox>
          </DoubleColumn>
          <DoubleColumn>
            <TitleBox>입주사 대표자명 *</TitleBox>
            <ContentBox>{props.data.ceo_name}</ContentBox>
            <TitleBox>입주사 연락처 *</TitleBox>
            <ContentBox>{props.data.tenant_phone_number}</ContentBox>
          </DoubleColumn>
          <DoubleColumn>
            <TitleBox>
              이메일 *
              <br />
              (세금계산서 발행용)
            </TitleBox>
            <ContentBox>{props.data.email}</ContentBox>
            <TitleBox>팩스번호</TitleBox>
            <ContentBox>{props.data.fax && props.data.fax}</ContentBox>
          </DoubleColumn>
          <DoubleColumn>
            <TitleBox>직원 및 동거인 수</TitleBox>
            <ContentBox>{props.data.tenant_num && `${props.data.tenant_num}명`}</ContentBox>
          </DoubleColumn>
        </Body>
        <Body>
          <Text variant="title">3. 담당자 정보</Text>
          <DoubleColumn>
            <TitleBox>담당자명</TitleBox>
            <ContentBox>{props.data.tenant_master_name}</ContentBox>
            <TitleBox>담당자 전화번호</TitleBox>
            <ContentBox>{props.data.tenant_master_phone_number}</ContentBox>
          </DoubleColumn>
        </Body>
        <Body>
          <Text variant="title">4. 소유자 현황</Text>
          <DoubleColumn>
            <TitleBox>소유자명 *</TitleBox>
            <ContentBox>{props.data.owner_name}</ContentBox>
            <TitleBox>소유자 전화번호 *</TitleBox>
            <ContentBox>{props.data.owner_phone_number}</ContentBox>
          </DoubleColumn>
          <DoubleColumn>
            <TitleBox>주소</TitleBox>
            <ContentBox>{props.data.owner_address}</ContentBox>
          </DoubleColumn>
          <DoubleColumn>
            <TitleBox>임대기간 *</TitleBox>
            {props.data.rent_start_date && props.data.rent_end_date && (
              <ContentBox>
                {/* {props.data.rent_period && `${props.data.rent_period}년`} */}
                {moment(props.data.rent_start_date).format("YYYY년 MM월 DD일 ~ ")}
                {moment(props.data.rent_end_date).format("YYYY년 MM월 DD일")}
              </ContentBox>
            )}
          </DoubleColumn>
        </Body>
        <Body>
          <Text variant="title">5. 첨부서류</Text>
          <DoubleColumn>
            <TitleBox>
              분양계약서 <br />
              /임대차 계약서 *
            </TitleBox>
            <FileComponent
              data={props.data.sales_or_lease_contract_files.map((el) => ({
                directory: el.directory || "",
                name: el.name || "",
              }))}
            />
          </DoubleColumn>
          <DoubleColumn>
            <TitleBox>사업자 등록증</TitleBox>
            {props.data.business_registration_files.length !== 0 && (
              <FileComponent
                data={props.data.business_registration_files.map((el) => ({
                  directory: el.directory || "",
                  name: el.name || "",
                }))}
              />
            )}
          </DoubleColumn>
        </Body>
        {onlyDIMC && ( //테라타워만
          <Body>
            <Text variant="title">5. 무료 정기 주차등록 (호실별 1대 한정)</Text>
            <DoubleColumn>
              <TitleBox>차량 등록여부 *</TitleBox>
              <ContentBox>{props.data.is_car ? "등록" : "미등록"}</ContentBox>
            </DoubleColumn>
            <DoubleColumn>
              <TitleBox>성명 {props.data.is_car && <>*</>}</TitleBox>
              <ContentBox>{props.data.car_owner_name || ""}</ContentBox>
              <TitleBox>연락처 {props.data.is_car && <>*</>}</TitleBox>
              <ContentBox>{props.data.car_owner_phone_number || ""}</ContentBox>
            </DoubleColumn>
            <DoubleColumn>
              <TitleBox>차량 번호 {props.data.is_car && <>*</>}</TitleBox>
              <ContentBox>{props.data.car_number || ""}</ContentBox>
              <TitleBox>차종 {props.data.is_car && <>*</>}</TitleBox>
              <ContentBox>{props.data.car_type || ""}</ContentBox>
            </DoubleColumn>
            <DoubleColumn>
              <TitleBox>차량등록증 {props.data.is_car && <>*</>}</TitleBox>
              {props.data.vehicle_registration_files.length !== 0 && (
                <FileComponent
                  data={props.data.vehicle_registration_files.map((el) => ({
                    directory: el.directory || "",
                    name: el.name || "",
                  }))}
                />
              )}
            </DoubleColumn>
          </Body>
        )}
        <Footer auth={auth}>
          {auth === "edit" && (
            <>
              <CancleModal
                isOpen={isDelete}
                onClose={Delete}
                blockScrollOnMount={false}
                onClickCancel={Delete}
                onClickSave={onDeleteCard}
              >
                <Text>해당 입주카드를 삭제할까요?</Text>
                <Text>삭제된 데이터는 복구할 수 없습니다.</Text>
              </CancleModal>
              <Button ml="0" onClick={onDelete} variant="delete">
                삭제
              </Button>
            </>
          )}
          {props.data.registration_type === "pending" && auth === "edit" ? (
            <Box>
              <Button variant="cancel" onClick={props.onClose}>
                취소
              </Button>
              <Button onClick={onOpen} variant="bgBlue">
                입주사 등록하기
              </Button>
            </Box>
          ) : (
            <Button variant="cancel" onClick={props.onClose}>
              확인
            </Button>
          )}
          <ConfirmModal
            title="입주사 등록하기"
            close="등록하고 가입요청 보내기"
            onClose={onClose}
            onClickCancle={onClose}
            onClickSave={onUpdateCard}
            isOpen={isOpen}
            blockScrollOnMount={false}
          >
            <Box position="relative">
              <Text>해당 정보로 입주사 등록할까요?</Text>
              <Text>등록 시 입주사 담당자의 연락처로 오피스너 앱 가입 안내 SMS도 함께 전송됩니다.</Text>
              {mutation.isLoading && <S.CustomSpin />}
            </Box>
          </ConfirmModal>
          <Modal blockScrollOnMount={false} variant="default" isOpen={isFail} onClose={failClose}>
            <ModalOverlay />
            <ModalContent mt="7.75rem">
              <ModalHeader>입주사 등록 제한</ModalHeader>
              <ModalBody>
                <Text>이미 등록된 입주사에요.</Text>
                <Text>기존 입주사의 정보 및 호실 수정은 입주 관리 - 상세정보에서 수정하세요.</Text>
                <ModalFooter justifyContent="flex-end">
                  <Button onClick={onNotieFail} variant="cancel">
                    확인
                  </Button>
                </ModalFooter>
              </ModalBody>
            </ModalContent>
          </Modal>
          <Modal blockScrollOnMount={false} variant="default" isOpen={isRoom} onClose={Room}>
            <ModalOverlay />
            <ModalContent mt="7.75rem">
              <ModalHeader>중복 호실 등록</ModalHeader>
              <ModalBody>
                <Text>해당 입주카드에서 선택한 호실은 이미 입주사가 입주해있습니다.</Text>
                <Text>등록하실 입주카드를 다시 확인해주세요.</Text>
                <ModalFooter justifyContent="flex-end">
                  <Button onClick={Room} variant="cancel">
                    확인
                  </Button>
                </ModalFooter>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Footer>
      </OpenModal>
    </ModalBackground>
  );
}
