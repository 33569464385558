import { IoClose } from "@react-icons/all-files/io5/IoClose";
import styled from "@emotion/styled";
import { colors } from "../../../../components/styled/common";
import { ActiveBox } from "../../../../components/styled/webApp";

export const SelectTimeSection = styled.div`
  width: 100%;
  display: flex;
  padding-top: 1.25rem;
  margin-top: 1.25rem;
  border-top: 1px solid ${colors.gray1};
  font-weight: 400;
  .section {
    font-size: 0.875rem;
    padding-top: 0.75rem; //반응형 padding:0
    color: ${colors.gray5};
    font-weight: 500;
    width: 7.5rem;
  }
  input {
    margin-right: 4px;
    text-align: right;
    ::placeholder {
      color: ${colors.gray2};
    }
  }
`;

//timeslot css
export const SelectTime = styled.div`
  display: flex;
  align-items: center;
  padding-right: 4px;
  select {
    width: 4rem;
    margin: 0;
    background: ${colors.gray1};
  }
`;

export const SetUserBox = styled.div<{ isActive: boolean }>`
  display: ${({ isActive }) => (isActive ? "flex" : "none")};
  flex-direction: column;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  div {
    width: 100%;
    display: flex;
    align-items: center;
    input {
      flex: 1;
    }
  }
`;

export const MaximumBox = styled.div`
  background-color: ${colors.gray1};
  color: ${colors.mainBlue};
  padding: 0.5rem;
  input {
    margin-right: 4px;
    background-color: inherit;
  }
`;

export const HourLimitBox = styled.div`
  padding: 0.5rem;
  border-bottom: 1px solid ${colors.gray1};
`;

export const HourActiveBox = styled(ActiveBox)<{ isDay: boolean }>`
  ${({ isDay }) => isDay && `opacity:0.7; cursor:not-allowed`};
`;

export const BookingDeleteIcon = styled(IoClose)`
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: ${colors.whiteColor};
  border-radius: 100%;
  padding: 2px;
  border: 1px solid ${colors.gray2};
  color: ${colors.gray2};
  cursor: pointer;
  font-size: 1.25rem;
`;
