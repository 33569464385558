import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as S from "./styled";
import * as C from "../../components/styled";
import TenantInfo from "./units/TenantInfo";
import TenantStatus from "./units/TenantStatus";
import ManagerInfo from "./units/ManagerInfo";
import OwnerInfo from "./units/OwnerInfo";
import AttachedDocs from "./units/AttachedDocs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { BaseResponse } from "../../lib/api/queries/commons/types";
import { IBuilding } from "../../lib/types/Imodels";
import { building, register_card_create } from "../../lib/api/queries/urls";
import axios, { AxiosError } from "axios";
import { Select, Text, useToast } from "@chakra-ui/react";
import AgreementModal from "./AgreeModal";
import RegularCar from "./units/RegularCar";
import { ICarForm, IFileObj } from "../company/commons/type";
import { IOwnerInfo, ITenantInfo, ITenantStatus } from "./types";
import moment from "moment";
import { uploadAllFiles } from "../company/commons";
import { onPreventEnter } from "../../components/hooks/useInputEvent";
import { toast } from "../../lib/utils/toast";

export interface ICreateCard {
  author_type: "tenant" | "owner";
  info: ITenantInfo;
  status: ITenantStatus;
  tenant_master_name?: string;
  tenant_master_phone_number?: string;
  first?: string;
  second?: string;
  third?: string;
  owner: IOwnerInfo;
  sales_or_lease_contract_files: IFileObj[];
  business_registration_files: IFileObj[];
  is_car?: boolean;
  car?: ICarForm;
}

const schema = yup.object({
  author_type: yup.string().required(),
  info: yup.object({
    room_type: yup.string().required(),
    room_ids: yup.array().of(yup.string()).min(1).required(),
    move_in_date: yup.date().required(),
    period: yup.array().of(yup.date()).max(2).required(),
    move_in_type: yup.string().required(),
  }),
  status: yup.object({
    tenant_name: yup.string().required(),
    tenant_registration_number: yup.string().required(),
    ceo_name: yup.string().required(),
    first: yup
      .string()
      .matches(/^[0-9]+$/, "0-9사이 숫자가 1개 이상 필요합니다.")
      .required(),
    second: yup
      .string()
      .matches(/^[0-9]+$/, "0-9사이 숫자가 1개 이상 필요합니다.")
      .required(),
    third: yup
      .string()
      .matches(/^[0-9]+$/, "0-9사이 숫자가 1개 이상 필요합니다.")
      .required(),
    email: yup.string().matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, "이메일 형식을 정확히 입력해주세요."),
    fax: yup.string(),
    tenant_num: yup.string(),
  }),
  tenant_master_name: yup.string(),
  tenant_master_phone_number: yup.string(),
  owner: yup.object({
    name: yup.string().required(),
    first: yup
      .string()
      .matches(/^[0-9]+$/, "0-9사이 숫자가 1개 이상 필요합니다.")
      .required(),
    second: yup
      .string()
      .matches(/^[0-9]+$/, "0-9사이 숫자가 1개 이상 필요합니다.")
      .required(),
    third: yup
      .string()
      .matches(/^[0-9]+$/, "0-9사이 숫자가 1개 이상 필요합니다.")
      .required(),
    address: yup.string(),
    address_detail: yup.string(),
  }),
  sales_or_lease_contract_files: yup.array().of(yup.object()).min(1),
  business_registration_files: yup.array().of(yup.object()).required(),
  is_car: yup.boolean(),
});

export default function GetMoveInForms() {
  const navigate = useNavigate();
  const location = useLocation();
  const building_id = location.search.split("=")?.[1];
  const onlyDIMC = building_id === "633d7820031ec8ac5a62a7a5" || building_id === "66a9df13c17c3816d897bcb5";

  const [isInfo, setIsInfo] = useState(true);
  const [isStatus, setIsStatus] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isDocs, setIsDocs] = useState(false);
  const [isCar, setIsCar] = useState(false); //차량 여부

  const [isOpen, setIsOpen] = useState(false); //agree modal open
  const [scrollBottom, setScrollBottom] = useState(0);

  const { data, isError, isLoading } = useQuery(
    [building_id],
    () =>
      axios
        .post<BaseResponse<IBuilding>>(`${process.env.REACT_APP_SERVER_URL}${building}`, { building_id })
        .then((res) => res.data.data),
    { enabled: !!building_id, staleTime: 1800000 },
  );

  const methods = useForm<ICreateCard>({
    defaultValues: {
      author_type: undefined,
      info: {
        room_type: "",
        room_ids: [],
        move_in_date: null,
        period: [null, null],
        move_in_type: "",
      },
      status: {
        tenant_name: "",
        tenant_registration_number: "",
        ceo_name: "",
        first: "",
        second: "",
        third: "",
        email: "",
        fax: undefined,
        tenant_num: undefined,
      },
      tenant_master_name: undefined,
      tenant_master_phone_number: undefined,
      first: undefined,
      second: undefined,
      third: undefined,
      owner: {
        name: "",
        first: "",
        second: "",
        third: "",
        address: undefined,
        address_detail: undefined,
      },
      sales_or_lease_contract_files: [],
      business_registration_files: [],
      is_car: undefined,
      car: {},
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const mutation = useMutation(
    (newObj: { building_id: string; data: ICreateCard }) => {
      const { building_id, data } = newObj;

      const { info, status, owner, first: notUsed, second: notUse, is_car, car, author_type, ...props } = data;
      const { period, move_in_date, ...tenant_info } = info;
      const { first, second, third, ...tenant } = status;

      let car_owner_phone_number: string | undefined = undefined;
      if (car?.first && car?.second && car?.third) car_owner_phone_number = `${car.first}${car.second}${car.third}`;

      return axios
        .post(`${process.env.REACT_APP_SERVER_URL}${register_card_create}`, {
          building_id,
          ...tenant_info,
          move_in_date: moment(move_in_date).format("YYYY-MM-DD"),
          rent_start_date: period[0] ? moment(period[0]).format("YYYY-MM-DD") : undefined,
          rent_end_date: period[1] ? moment(period[1]).format("YYYY-MM-DD") : undefined,
          tenant_phone_number: `${first}${second}${third}`,
          ...tenant,
          owner_name: owner.name,
          owner_phone_number: owner.phone_number || "",
          owner_address: `${owner.address}${owner.address_detail}`,
          is_car: is_car === undefined ? false : is_car,
          car_owner_name: car?.name,
          car_owner_phone_number,
          car_number: car?.number,
          car_type: car?.type,
          vehicle_registration_files: car?.files,
          author_type,
          ...props,
        })
        .then((res) => res.data.data);
    },
    {
      onSuccess: () => navigate("/forms/submit"),
      onError: (err: AxiosError) => {
        // console.log(err);
        if (err.response?.status === 502) toast.createCardWarning();
      },
    },
  );

  const onClickSubmit = async (data: ICreateCard) => {
    if (!building_id || mutation.isLoading) return;

    const newObj = { building_id, data };
    const { first, second, third, owner } = data;
    const { first: num_1, second: num_2, third: num_3, ...info } = owner;
    if (first && second) data.tenant_master_phone_number = `${first}${second}${third}`;
    if (num_1 && num_2 && num_3) info.phone_number = `${num_1}${num_2}${num_3}`;
    data.owner = info;

    if (data.sales_or_lease_contract_files.length) {
      const fileArr = await uploadAllFiles(data.sales_or_lease_contract_files);

      if (!fileArr) return toast.createCardWarning();
      data.sales_or_lease_contract_files = fileArr ? fileArr : [];
    }
    if (data.business_registration_files.length) {
      const fileArr = await uploadAllFiles(data.business_registration_files);

      if (!fileArr) return toast.createCardWarning();
      data.business_registration_files = fileArr ? fileArr : [];
    }
    if (data.car?.files?.length) {
      const fileArr = await uploadAllFiles(data.car.files);

      if (!fileArr) return toast.createCardWarning();
      data.car && (data.car.files = fileArr ? fileArr : []);
    }

    mutation
      .mutateAsync(newObj)
      .then(() => {
        setIsOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (isError || isLoading) return <C.CustomSpin />;
  return (
    <S.FormWrapper>
      <S.FormBox isOpen={isOpen}>
        <S.FormHeader>
          <S.FormTitle>
            <div className="buildingName">
              <Text>{data?.name}</Text>
              {/* <Text>현대 DIMC 테라타워</Text> */}
              <Text>입주 카드</Text>
            </div>
            {data?.building_picture[0] && (
              <S.PersonalImage>
                <img src={data?.building_picture[0]} alt="" />
              </S.PersonalImage>
            )}
          </S.FormTitle>
          <S.DocsInfo>분양계약 또는 임대차 계약서, 사업자 등록증 사본, 차량등록증을 미리 준비해주세요.</S.DocsInfo>
          <S.MustInfo>*필수 항목</S.MustInfo>
        </S.FormHeader>
        <FormProvider {...methods}>
          <form
            style={{ width: "100%" }}
            onKeyDown={onPreventEnter}
            onSubmit={methods.handleSubmit(onClickSubmit, (data) => console.log(methods.formState, data))}
          >
            <S.FormBody>
              <div className="mb-1 border-b border-blue-600 py-4">
                <p className="font-medium text-blue-600">0. 작성자 유형 선택 *</p>
              </div>
              <Select bg="#e5e7eb" color="black" {...methods.register("author_type", { required: true })}>
                <option value="">작성자 유형</option>
                <option value="tenant">입주사</option>
                <option value="owner">소유자</option>
              </Select>
              <TenantInfo isOpen={isInfo} setIsOpen={setIsInfo} />
              <TenantStatus isOpen={isStatus} setIsOpen={setIsStatus} />
              <ManagerInfo isOpen={isManager} setIsOpen={setIsManager} />
              <OwnerInfo isOpen={isOwner} setIsOpen={setIsOwner} />
              <AttachedDocs isOpen={isDocs} setIsOpen={setIsDocs} />
              {onlyDIMC && (
                <RegularCar isOpen={isCar} setIsOpen={setIsCar} /> //테라타워 전용
              )}
            </S.FormBody>
            <S.SubmitBox>
              <S.SubmitButton
                type="button"
                onClick={() => {
                  setScrollBottom(document.documentElement.scrollTop);
                  setIsOpen(true);
                }}
                disabled={!methods.formState.isValid}
                isValid={!methods.formState.isValid}
              >
                입주 카드 제출하기
              </S.SubmitButton>
            </S.SubmitBox>
            <AgreementModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              scroll={scrollBottom}
              isLoading={mutation.isLoading}
            />
          </form>
        </FormProvider>
      </S.FormBox>
    </S.FormWrapper>
  );
}
