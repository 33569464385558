import { useState, Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";
import { MdMoreHoriz } from "@react-icons/all-files/md/MdMoreHoriz";
import { IUser } from "../../../../lib/types/Imodels";
import OwnerCheckoutModal from "../units/ownerCheckoutModal";
import { useDisclosure } from "@chakra-ui/react";
import { fetchClient } from "../../../../lib/api/axios";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { send_message_to_owner_one, change_representative } from "../../../../lib/api/queries/urls";
import { queryClient } from "../../../..";
import { useParams } from "react-router-dom";
import { IRoom } from "../../../../lib/types/Imodels";
import { room_list_or_detail } from "../../../../lib/api/queries/keys";
// import BlueEmail from '../../../../components/icons/BlueEmail';
import BlueCheck from "../../../../components/icons/BlueCheck";
import BlueCancel from "../../../../components/icons/BlueCancel";
import BlueEmail from "../../../../components/icons/BlueEmail";
import { toast } from "../../../../lib/utils/toast";
import useUserAuth from "../../../../components/hooks/useUserAuth";
import GetAuthorized from "../../../../components/Authrized";

const OwnerItems = styled.div`
  width: 100%;
  height: 66px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  margin-bottom: 20px;
`;

const OwnerNameSpan = styled.span<{ account: boolean }>`
  color: ${(props) => (props.account ? "#111827" : "#9CA3AF")};
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  margin-right: 8px;
`;

const OwnerPhoneSpan = styled.span`
  color: #9ca3af;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
`;

const StatusContainer = styled.div`
  height: 24px;
  display: flex;
  padding: 2px 8px;
  line-height: 16px;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  background: #fff;

  > span {
    color: #6b7280;
    font-family: "Noto Sans CJK KR";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    height: 18px;
  }

  > li {
    width: 6px;
    height: 26px;
  }

  > img {
    height: 24px;
  }
`;

const SubDiv = styled.div<{ height: string }>`
  width: 178px;
  height: ${(props) => props.height};
  position: absolute;
  top: 41px;
  right: -17px;
  background-color: #fff;
  display: inline-flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
`;

const SubButton = styled.button`
  display: flex;
  gap: 8px;
  padding: 8px 12px 8px 8px;
`;

interface owners {
  name: string;
  phone_number: string;
}

export default function OwnerInfoItem(props: {
  data: {
    _id: string;
    name: string;
    phone_number: string;
    user_id: IUser;
  };
  roomInfo: {
    dong: string | undefined;
    ho: string | undefined;
  };
  representative: boolean;
  setOwners: Dispatch<SetStateAction<owners[]>>;
}) {
  const { room_id } = useParams();
  const representative = props.representative;
  const { isOpen: isOwnerCheckout, onOpen: onOwnerCheckout, onClose: offOwnerCheckout } = useDisclosure();
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false);

  const auth = useUserAuth("tenant");
  const isEdit = auth === "edit";

  if (auth === "no_auth") return <GetAuthorized />;

  const ownerCheckoutClick = () => {
    onOwnerCheckout();
  };

  const sendOwner = () => {
    fetchClient
      .post<BaseResponse<IRoom>>(send_message_to_owner_one, {
        room_id: room_id,
        _id: props.data._id,
      })
      .then((res) => {
        toast.success({
          title: "신규 소유자 등록 완료",
          description: `${props.roomInfo.dong}동 ${props.roomInfo.ho}호가 정상적으로 추가되었습니다. 등록된 소유자에게 가입초대 문자가 전송됩니다.`,
        });

        queryClient.invalidateQueries([room_list_or_detail]);

        return res.data.data;
      });
  };

  const changeOwner = () => {
    fetchClient
      .patch<BaseResponse<IRoom>>(change_representative, {
        room_id: room_id,
        _id: props.data._id,
      })
      .then((res) => {
        setSubMenuOpen(false);
        queryClient.invalidateQueries([room_list_or_detail]);
        return res.data.data;
      });
  };

  return (
    <div>
      <OwnerItems>
        <div>
          <OwnerNameSpan account={props.data.user_id !== undefined}>
            {props.data.user_id ? props.data.user_id.name : props.data.name}
          </OwnerNameSpan>
          <OwnerPhoneSpan>
            {props.data.user_id ? props.data.user_id.phone_number : props.data.phone_number}
          </OwnerPhoneSpan>
        </div>
        {props.data.user_id ? (
          <div style={{ display: "flex", gap: "16px" }}>
            {representative && (
              <StatusContainer>
                <span>대표 소유자</span>
              </StatusContainer>
            )}
            <StatusContainer>
              <li style={{ color: "#3CB979" }}></li>
              <span>가입완료</span>
            </StatusContainer>
            {isEdit && (
              <div style={{ position: "relative" }}>
                <MdMoreHoriz
                  style={{ height: "24px", cursor: "pointer" }}
                  onClick={() => setSubMenuOpen(!subMenuOpen)}
                />
                {subMenuOpen && (
                  <SubDiv height={representative ? "47px" : "86px"}>
                    {!representative && (
                      <SubButton style={{ width: "100%", height: "39px" }} onClick={changeOwner}>
                        <BlueCheck />
                        대표 소유자 변경
                      </SubButton>
                    )}
                    <SubButton style={{ width: "100%", height: "39px" }} onClick={ownerCheckoutClick}>
                      <BlueCancel />
                      퇴실
                    </SubButton>
                  </SubDiv>
                )}
              </div>
            )}
          </div>
        ) : (
          <div style={{ display: "flex", gap: "16px" }}>
            {representative && (
              <StatusContainer>
                <span>대표 소유자</span>
              </StatusContainer>
            )}
            <StatusContainer>
              <li style={{ color: "#FF3D3D" }}></li>
              <span>미가입</span>
            </StatusContainer>
            {isEdit && (
              <div style={{ position: "relative" }}>
                <MdMoreHoriz
                  style={{ height: "24px", cursor: "pointer" }}
                  onClick={() => setSubMenuOpen(!subMenuOpen)}
                />
                {subMenuOpen && (
                  <SubDiv height={representative ? "86px" : "125px"}>
                    {!representative && (
                      <SubButton style={{ width: "100%", height: "39px" }} onClick={changeOwner}>
                        <BlueCheck />
                        대표 소유자 변경
                      </SubButton>
                    )}
                    <SubButton style={{ width: "100%", height: "39px" }} onClick={sendOwner}>
                      <BlueEmail />
                      가입 초대 전송
                    </SubButton>
                    <SubButton style={{ width: "100%", height: "39px" }} onClick={ownerCheckoutClick}>
                      <BlueCancel />
                      퇴실
                    </SubButton>
                  </SubDiv>
                )}
              </div>
            )}
          </div>
        )}
      </OwnerItems>
      {isOwnerCheckout && (
        <OwnerCheckoutModal
          isOpen={true}
          onClose={offOwnerCheckout}
          _id={props.data._id}
          name={props.data.user_id ? props.data.user_id.name : props.data.name}
          setOwners={props.setOwners}
        />
      )}
    </div>
  );
}
