import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import * as N from "./styled";
import { ChangeEvent, useRef } from "react";
import { IUpload } from "../types/type";
import { warning } from "../../../../lib/theme/toast";

export default function UploadFile(props: IUpload) {
  //여기 파일 업로드 API 적용되면 반영
  const toast = useToast();
  const fileRef = useRef<HTMLInputElement>(null);

  const onSelectFile = () => {
    if (props.fileArr.length + props.files.length >= 5)
      return toast({
        ...warning,
        title: "파일 업로드",
        description: "파일은 5개까지만 업로드하실 수 있습니다.",
      });

    fileRef.current?.click();
  };

  const onClickFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.files;
    if (value === null) return;

    if (props.fileArr.length + value.length + props.files.length > 5) {
      return toast({
        ...warning,
        title: "파일 업로드",
        description: "파일은 5개까지만 업로드하실 수 있습니다.",
      });
    }
    if (Array.from(value || []).filter((el) => el.size > 5 * 1024 * 1024).length !== 0) {
      return toast({
        ...warning,
        title: "파일 업로드",
        description: `파일 1개당 5MB 이하만 첨부 가능합니다.`,
      });
    }

    const regex = new RegExp(`\\.(pdf)$`, "i");

    if (Array.from(value || []).filter((el) => !regex.test(el.name)).length !== 0) {
      return toast({
        ...warning,
        title: "파일 업로드",
        description: `공지사항 파일은 pdf 형식만 가능합니다. 파일 형식 확인 후 동일한 에러가 반복될 경우에는 오피스너 담당자에게 문의해주세요..`,
      });
    }

    props.setFiles([...props.files, ...Array.from(value)]);
    e.target.value = "";
  };

  const onDelOrigin = (idx: number) => {
    const newArr = props.fileArr.filter((_, index) => index !== idx);
    props.setFileArr(newArr);
  };

  const onDelFiles = (idx: number) => {
    const newArr = Array.from(props.files).filter((_, index) => index !== idx);
    props.setFiles(newArr);
  };

  return (
    <Flex w="100%" flexDirection="column">
      <Text mb="2">파일 1개당 최대 첨부용량 5MB, 파일 업로드 문서당 최대 5개, PDF만 업로드 가능합니다.</Text>
      <Flex w="100%" alignItems="flex-start">
        <N.FileText>
          {props.fileArr.map((el, idx) => (
            <N.FileList key={String(idx)}>
              {el.file_original_name}
              <N.DeleteIcon onClick={() => onDelOrigin(idx)} />
            </N.FileList>
          ))}
          {Array.from(props.files).map((el, idx) => (
            <N.FileList key={String(el.size + idx)}>
              {el.name}
              <N.DeleteIcon onClick={() => onDelFiles(idx)} />
            </N.FileList>
          ))}
        </N.FileText>
        <Button onClick={onSelectFile} variant="basic">
          파일 선택
        </Button>
      </Flex>
      <input ref={fileRef} type="file" multiple={true} style={{ display: "none" }} onChange={onClickFiles} />
    </Flex>
  );
}
