import ConfirmModal from "../../../components/modal/ConfirmModal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function VoteAddMemberModal({ isOpen, onClose }: Props) {
  return (
    <ConfirmModal title={"투표 대상 추가"} width={1066} height={700} isOpen={isOpen} onClose={onClose}>
      {/* <VoteTargetSelect onClose={onClose} /> */}asfd
    </ConfirmModal>
  );
}
