import styled from "@emotion/styled";
import { CloseIconWithImg } from "../../components/styled";
import { colors, form } from "../../components/styled/common";
import { breakPoints } from "../../components/styled/media";

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  line-height: 1.2;
  font-size: 16px;
  @media ${breakPoints.phones} {
    font-size: 14px;
  }
`;

export const FormBox = styled.div<{ isOpen?: boolean }>`
  width: 540px;
  background-color: ${colors.whiteColor};
  display: flex;
  flex-direction: column;
  @media ${breakPoints.phones} {
    width: 100%;
  }
  ${({ isOpen }) =>
    isOpen &&
    `
      height: 100vh;
      overflow: hidden;
  `}
`;

export const SubmitBox = styled.div`
  width: 100%;
  padding: 24px 20px 36px;
  display: flex;
  justify-content: center;
  @media (max-width: 297px) {
    padding: 20px 12px 28px;
  }
`;

export const SubmitButton = styled.button<{ isValid?: boolean }>`
  width: 100%;
  font-size: ${form.fontSize.fs20};
  color: ${colors.whiteColor};
  border-radius: 12px;
  background-color: ${({ isValid }) =>
    isValid ? `${colors.gray2}` : `${colors.mainBlue}`};
  padding: 12px;
`;

export const FormHeader = styled.div`
  width: 100%;
  padding: 68px 20px 36px;
  background-color: ${colors.mainBlue};
  color: ${colors.whiteColor};
  font-weight: 500;
  @media (max-width: 297px) {
    padding: 60px 20px 32px;
  }
  @media (max-width: 297px) {
    padding: 60px 12px 32px;
  }
`;

export const FormTitle = styled.div`
  width: 100%;
  padding-bottom: 1.5em;
  display: flex;
  justify-content: space-between;
  font-size: ${form.fontSize.title};
  font-weight: 700;
  .buildingName {
    max-width: 280px;
  }
  @media ${breakPoints.phones} {
    .buildingName {
      max-width: 100%;
    }
  }
  @media (max-width: 297px) {
    font-size: 22px;
    padding-bottom: 28px;
  }
`;

export const PersonalImage = styled.div`
  width: 60px;
  height: 60px;
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  @media ${breakPoints.phones} {
    display: none;
  }
`;

export const DocsInfo = styled.div`
  line-height: 1.5;
  @media ${breakPoints.phones} {
    font-size: 13px;
  }
`;

export const MustInfo = styled.div`
  font-size: 12px;
  padding-top: 4px;
`;

export const FormBody = styled.div`
  width: 100%;
  padding: 0 20px;
  background-color: ${colors.whiteColor};
  font-size: 16px;
  @media ${breakPoints.phones} {
    font-size: 14px;
  }
  @media (max-width: 297px) {
    padding: 0 12px;
  }
`;

type IToggle = {
  isOpen: boolean;
  isHeight?: number;
};

export const ToggleBox = styled.div`
  width: 100%;
  padding-top: 24px;
  color: ${colors.gray4};
  select {
    padding: 12px;
    border-radius: 8px;
    appearance: none;
    position: relative;
    cursor: pointer;
  }
  @media ${breakPoints.phones} {
    font-size: 14px;
    padding-top: 20px;
  }
`;

export const ToggleTitle = styled.div`
  padding: 0 0.5em 1em 0;
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.mainBlue};
  color: ${colors.mainBlue};
  font-weight: 500;
  cursor: pointer;
`;

export const ToggleIcon = styled.img`
  width: 16px;
  height: 16px;
  transform: ${(props: IToggle) => !props.isOpen && "rotateZ(-180deg)"};
  transition: transform 0.7s ease;
`;

export const ToggleContent = styled.div`
  max-height: ${(props: IToggle) =>
    !props.isOpen ? 0 : `${props.isHeight}px`};
  overflow: hidden;
  transition: max-height 1s ease;
`;

export const SelectedRoomBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const SelectedRoom = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 12px 8px 0;
  @media ${breakPoints.phones} {
    margin: 2px 12px 8px 0;
  }
`;

export const EllipsisFile = styled.div`
  width: 98px;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: none;
  text-align: left;
`;

export const CancleButton = styled(CloseIconWithImg)`
  position: static;
  margin-left: 8px;
`;

export const InputBox = styled.div`
  padding: 8px 0;
  font-size: ${form.fontSize.fs14};
  font-weight: 500;
  input {
    width: 100%;
    border: 1px solid ${colors.gray3};
    padding: 12px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
  }
  @media ${breakPoints.phones} {
    padding: 6px 0;
  }
`;

export const InputName = styled.div`
  padding-bottom: 8px;
  span {
    color: ${colors.mainBlue};
  }
`;

export const MoreInfoButton = styled.button`
  width: 100%;
  color: #696f79;
  border-radius: 8px;
  background-color: ${colors.gray1};
  padding: 12px;
  margin-bottom: 4px;
`;

export const DocsStyle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.gray5};
  padding-bottom: 8px;
  span {
    color: ${colors.mainBlue};
  }
  @media ${breakPoints.phones} {
    font-size: 12.25px;
  }
`;

export const NumberBox = styled.div`
  padding: 8px 0;
  font-size: ${form.fontSize.fs14};
  font-weight: 500;
  input {
    width: 96px;
    border: 1px solid ${colors.gray3};
    padding: 12px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
  }
  @media ${breakPoints.phones} {
    input {
      width: 80px;
      padding: 10px;
    }
  }
  @media (max-width: 297px) {
    input {
      width: 72px;
      padding: 10px;
    }
  }
`;

// 010-
export const PhoneNumberBox = styled.div`
  display: flex;
  align-items: center;
`;

export const PhoneNumber = styled.div`
  width: 96px;
  text-align: center;
  @media ${breakPoints.phones} {
    width: 80px;
  }
`;

// 상세 안내 멘트
export const InfoText = styled.div`
  font-size: 14px;
  color: ${colors.gray5};
  font-weight: 500;
  line-height: 1.5;
  padding: 8px 0;
  @media ${breakPoints.phones} {
    font-size: 12px;
  }
`;
