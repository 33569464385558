import { ModalBody, Spinner } from "@chakra-ui/react";
import IconSearch from "../../../components/icons/IconSearch";
import IconCheckbox from "../../../components/icons/IconCheckbox";
import { debounce } from "lodash";
import BlueCancel from "../../../components/icons/BlueCancel";
import IconCircleClose from "../../../components/icons/IconCircleClose";
import { setSelectOwners } from "../../../hooks/use-vote-store";
import { useEffect, useState } from "react";

type Props = {
  data: {
    _id: string;
    count: number;
    dong: string;
    ho: string;
    user_name: string;
    user_phone_number: string;
    room_ids: string[];
  }[];
};
export default function VoteTargetSelect({ data }: Props) {
  const [allSelect, setAllSelect] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<Props["data"]>();
  const [search, setSearch] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<{ id: string; checked: boolean; phone_number: string }[]>(
    data.map((item) => ({
      id: item._id,
      phone_number: item.user_phone_number,
      checked: false,
    })),
  );

  useEffect(() => {
    const debouncedFilter = debounce(() => {
      if (data) {
        const filtered = data.filter((item) => {
          return item.user_name?.includes(search) || item.user_phone_number?.includes(search);
        });
        setFilteredData(filtered);
      }
    }, 300);
    debouncedFilter();
    return () => debouncedFilter.cancel();
  }, [search, data]);

  useEffect(() => {
    if (filteredData && selectedItems.length > 0) {
      const allSelected = selectedItems.every((item) => item.checked);
      setAllSelect(allSelected);
    }
  }, [selectedItems, filteredData]);

  const selectLength = selectedItems.filter((item) => item.checked).length;

  useEffect(() => {
    const selectedOwners = selectedItems
      .filter((item) => item.checked)
      .map((item) => data?.find((dataItem) => dataItem._id === item.id))
      .filter((item): item is NonNullable<typeof item> => item !== undefined);
    setSelectOwners(selectedOwners);
  }, [selectedItems, data]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSelectAllToggle = () => {
    setSelectedItems(selectedItems.map((item) => ({ ...item, checked: !allSelect })));
    setAllSelect(!allSelect);
  };

  const handleItemCheckChange = (id: string) => {
    console.log("selected", id);
    const updatedItems = selectedItems.map((item) => (item.id === id ? { ...item, checked: !item.checked } : item));
    setSelectedItems(updatedItems);
  };

  const handleClearSelection = () => {
    setSelectedItems(selectedItems.map((item) => ({ ...item, checked: false })));
    setAllSelect(false);
  };

  return (
    <>
      <ModalBody className="flex" padding={0}>
        <div className="flex h-full w-[634px] flex-col border-r border-gray-200 px-6 pb-7 pt-3">
          <div className="relative flex h-12 w-full gap-2 rounded-xl border border-gray-200 p-3">
            <IconSearch />
            <input
              value={search}
              onChange={handleSearchChange}
              className="peer flex-1 placeholder:text-gray-400"
              placeholder="검색어를 입력하세요"
            />
            <button
              type="button"
              onClick={() => setSearch("")}
              className="absolute right-4 top-1/2 -translate-y-1/2 transform"
              tabIndex={-1}
            >
              <IconCircleClose />
            </button>
          </div>
          <label
            className="my-1 flex h-10 w-fit cursor-pointer items-center gap-2 rounded-lg px-5 transition-all hover:bg-gray-200"
            onClick={handleSelectAllToggle}
          >
            <IconCheckbox isChecked={allSelect} />
            <span>전체</span>
          </label>
          <div className="h-[330px] overflow-y-scroll border border-gray-200" style={{ maxHeight: "330px" }}>
            {!filteredData ? (
              <div className="flex h-full w-full items-center justify-center">
                <Spinner size={"xl"} />
              </div>
            ) : filteredData.length === 0 ? (
              <p className="p-4 text-gray-400">검색 결과가 존재하지 않습니다.</p>
            ) : (
              filteredData.map((item, index) => {
                const selectedItem = selectedItems.find((selected) => selected.id === item._id);
                console.log(item);
                return (
                  <div
                    key={item._id}
                    className={`flex h-12 cursor-pointer items-center pr-5 transition-all hover:opacity-60 ${index % 2 === 0 ? "bg-[#F4F4F5]" : "bg-white"}`}
                    onClick={() => handleItemCheckChange(item._id)}
                  >
                    <IconCheckbox className="mx-4" isChecked={!!selectedItem?.checked} />
                    <p className="w-[70px] pl-2">소유자</p>
                    <p className="flex-1 pl-2">{item.user_name}</p>
                    <p className="w-[125px] pl-2">{item.user_phone_number}</p>
                    <p className="w-[130px] pl-2">
                      {item.dong}동 {item.ho}호 {item.count === 1 ? "" : `외 ${item.count - 1}`}
                    </p>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="flex-1 px-5">
          <p className="py-6 font-bold text-gray-400">선택 ({selectLength})</p>
          {selectLength > 0 && (
            <button
              className="flex items-center gap-2.5 rounded bg-blue-100 px-3 py-2.5"
              onClick={handleClearSelection}
            >
              <span className="font-medium text-primary">선택한 소유자 ({selectLength})명</span>
              <BlueCancel />
            </button>
          )}
        </div>
      </ModalBody>
    </>
  );
}
