import { createSearchParams, useNavigate } from "react-router-dom";
import { ITenant } from "../../../lib/types/Imodels";
import { format_tenant_type } from "../../../lib/utils/dataFormat";
import { ColumnActive } from "../commons/styled";
import { useTenantType } from "../../facility/commons/func";
import { useContext } from "react";
import { GlobalContext } from "../../../App";
import { twMerge } from "tailwind-merge";

export default function TenantPointListItem({ el }: { el: ITenant }) {
  const navigate = useNavigate();
  const { buildInfo } = useContext(GlobalContext);
  const tenant_type = useTenantType(buildInfo);
  const onClickTenantHistory = () => {
    navigate({
      pathname: `/points/${el._id}`,
      search: `?${createSearchParams({
        tenant: el.name,
        isActive: JSON.stringify(el.is_activated),
      })}`,
    });
  };

  return (
    <div
      className="flex w-full cursor-pointer border-b border-gray-300 bg-white px-4 py-3 text-left transition-all duration-75 hover:bg-blue-50 hover:opacity-75"
      onClick={onClickTenantHistory}
    >
      <div
        className={twMerge(
          "relative inline-block w-1/4 overflow-hidden text-ellipsis whitespace-nowrap border-b-0 text-left align-middle",
          (tenant_type.length ?? 0) > 1 && "w-1/5",
        )}
      >
        {el.name}
      </div>
      {tenant_type.length > 1 && <div className="w-1/5">{format_tenant_type(el.tenant_type)}</div>}
      <div className={twMerge("w-1/4", (tenant_type.length ?? 0) > 1 && "w-1/5")}>
        {el.dong_ho}
        {/* {el.room_ids.length === 0
          ? ""
          : `${el.room_ids[0].dong}동 ${el.room_ids[0].ho}호 ${
              el.room_ids.length > 1 ? `외 ${el.room_ids.length - 1}개` : ""
            }`} */}
      </div>
      <div className={twMerge("w-1/4", (tenant_type.length ?? 0) > 1 && "w-1/5")}>{el.point || 0}P</div>
      <ColumnActive isActive={el.is_activated}>{el.is_activated ? "입주중" : "퇴실"}</ColumnActive>
    </div>
  );
}
