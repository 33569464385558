import { useState } from "react";
import CustomModal from "../../../components/CustomModal";
import CustomRadioGroup from "../../../components/CustomRadioGroup";
import Icon from "../../../types/iconsax";
import formatPhoneNumber from "../../../utils/formatPhoneNumber";
import { useUpdateMemberPermissionMutation } from "../../../requests/company/useUpdateCompanyMemberPermission";
import { useOverlay } from "@toss/use-overlay";
import CompanyDeleteMemberModal from "./CompanyDeleteMemberModal";
import toast from "react-hot-toast";
import CustomToast from "../../../components/CustomToast";
import { twMerge } from "tailwind-merge";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  memberData: {
    id: string;
    name: string;
    phoneNumber: string;
    permission: 1 | 2 | 3;
    userId: string;
    companyId: string;
    companyName: string;
  };
  hasMaster: boolean;
};

export default function CompanyModifyPermissionModal({ isOpen, onClose, memberData, hasMaster }: Props) {
  const { id: memberId, name, phoneNumber, permission, userId, companyId, companyName } = memberData;

  const [selected, setSelected] = useState(permission || 1);

  const { mutateAsync: updateMemberPermission } = useUpdateMemberPermissionMutation();

  const overlay = useOverlay();

  if (!isOpen) return null;

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title="권한 변경"
      exit={true}
      primaryButtonText="변경하기"
      onPrimaryButtonClick={() => {
        toast.promise(updateMemberPermission({ companyId, memberId, permission: selected }), {
          success: () => {
            onClose();
            return <CustomToast iconType="check" title={`'${name || "-"}'의 권한이 변경되었습니다.`} />;
          },
          error: () => {
            onClose();
            return <CustomToast iconType="error" title="권한 변경 중 에러가 발생하였습니다." />;
          },
          loading: <CustomToast iconType="loading" title="Loading" />,
        });
      }}
      primaryButtonDisable={permission === Number(selected)}
      neutralButtonText="취소"
      onNeutralButtonClick={onClose}
      errorButtonText="내보내기"
      errorButtonIcon={<Icon.Trash size={20} />}
      onErrorButtonClick={() => {
        overlay.open(({ isOpen, close }) => (
          <CompanyDeleteMemberModal isOpen={isOpen} onClose={close} memberData={memberData} />
        ));
        onClose();
      }}
    >
      <div className="flex w-[488px] flex-col gap-3 p-6 pt-3">
        <div className="flex w-full items-center gap-4 rounded-xl border border-gray-200 px-6 py-4">
          <div className="flex size-10 items-center justify-center rounded-full bg-indigo-100 text-indigo-400">
            <Icon.Profile variant="Bold" size={20} />
          </div>
          <div className="flex flex-col">
            <span className={twMerge("text-sm font-medium text-gray-900", name.trim() === "" && "text-gray-400")}>
              {name.trim() || "이름 없는 구성원"}
            </span>
            <span className="text-sm font-normal text-gray-500">{formatPhoneNumber(phoneNumber.trim() || "-")}</span>
          </div>
          <div className="flex flex-1">
            <span className="flex-1 text-right text-sm font-medium text-gray-900">
              {userId ? "가입 회원" : "미가입"}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <span className="text-base font-semibold text-gray-700">{`'${companyName}' 에서 ${name}님의 권한`}</span>
          <CustomRadioGroup
            data={[
              { name: "최고관리자", value: "3", disabled: hasMaster },
              { name: "관리멤버", value: "2" },
              { name: "일반멤버", value: "1" },
            ]}
            getValue={selected.toString()}
            setState={(value) => setSelected(Number(value) as 1 | 2 | 3)}
            className="flex gap-10 p-3"
          />
        </div>
      </div>
    </CustomModal>
  );
}
