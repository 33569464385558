import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { survey_delete, survey_detail_key } from "../../../lib/api/queries/keys";
import { survey_detail } from "../../../lib/api/queries/urls";
import { ISurvey } from "../../../lib/types/Imodels";
import { ISurveyResult } from "./types";
import SurveyResultUI from "./_view/presenter";
import { useToast } from "@chakra-ui/react";
import { success, unAuthUser } from "../../../lib/theme/toast";
import useUserAuth from "../../../components/hooks/useUserAuth";
import FullPageSpinner from "../../../components/Spinner";
import GetAuthorized from "../../../components/Authrized";
import { AxiosError } from "axios";

export default function SurveyResultPage() {
  const toast = useToast();
  const navigate = useNavigate();
  const { surveyId } = useParams();
  const auth = useUserAuth("survey");

  const { data } = useQuery(
    [survey_detail_key, surveyId],
    () =>
      fetchClient
        .post<BaseResponse<ISurvey>>(survey_detail, {
          survey_id: surveyId,
        })
        .then((res) => res.data.data),
    { enabled: auth === "read" || auth === "edit" },
  );

  const mutation = useMutation(() =>
    fetchClient
      .post(survey_delete, { survey_id: surveyId })
      .then((res) => {
        toast({
          ...success,
          title: "설문조사 삭제 완료",
          description: `설문조사가 삭제되었습니다. 설문 조사 현황 페이지로 이동합니다.`,
        });

        navigate("/survey/search", { replace: true });
        return res.data.data;
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) return toast({ ...unAuthUser });
      }),
  );

  const onDeleteSurvey = () => {
    if (mutation.isLoading) return;
    mutation.mutate();
  };

  if (auth === undefined) return <FullPageSpinner />;
  if (auth === "no_auth") return <GetAuthorized />;
  const props: ISurveyResult = { data, onDeleteSurvey };

  return <SurveyResultUI {...props} />;
}
