import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getMessaging } from "@firebase/messaging";
import { getFirestore } from "@firebase/firestore";
import { getToken } from "firebase/messaging";
import { IUser } from "../types/Imodels";
import { BaseResponse } from "../api/queries/commons/types";
import { http } from "../http";

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_DOMAIN}`,
  projectId: `${process.env.REACT_APP_FIREBASE_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGE_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT}`,
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);

export const LoginUser = (id: string, pw: string) => {
  return signInWithEmailAndPassword(auth, id, pw);
};

const isSupport = () => "Notification" in window && "serviceWorker" in navigator && "PushManager" in window;

export const messaging = isSupport() ? getMessaging(firebaseApp) : null;

export const updateFCMToken = async () => {
  if (!messaging) return;
  const fcmToken = await getToken(messaging, { vapidKey: `${process.env.REACT_APP_FIREBASE_VAPIDKEY}` });
  await http.post<unknown, BaseResponse<IUser>>("/building/fcm_token/verify", { fcm_token: fcmToken });
  return fcmToken;
};
