import { Button } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MainContainer, MainActionBox } from "../../../components/styled/webApp";
import { useUploadFile } from "../../../lib/api/queries/commons";
import { useMutatePublicRoom } from "../commons/queries";
import { IBasicInfo, IUseMutationPublicRoom } from "../commons/types";
import Basicframe, { RoomPublicContext } from "../component/basicframe";
import { FacilityInfo } from "./_view/InfoItem";
import { toast } from "../../../lib/utils/toast";
import useUserAuth from "../../../components/hooks/useUserAuth";
import GetAuthorized from "../../../components/Authrized";

const PublicFacilityDetails = () => {
  const location = useLocation();
  const { room_info: data } = useContext(RoomPublicContext);
  const { updatePublicRoom } = useMutatePublicRoom();
  const [images, setImages] = useState<File[] | null>(null);
  const [fileArr, setFileArr] = useState<string[]>([]);
  const [InfoObj, setInfoObj] = useState<IBasicInfo>({
    name: "",
    location: "",
    dong: "",
    floor: "",
  });

  const auth = useUserAuth("room_public");

  useEffect(() => {
    if (!data) return;
    const info: IBasicInfo = {
      name: data.name,
      location: data.detail_address,
      dong: data.dong,
      floor: data.floor,
    };

    if (data.phone_number) info.number = data.phone_number;
    if (data.description) info.description = data.description;
    setInfoObj(info);
    setFileArr(data.pictures || []);
  }, [data, location.pathname]);

  // const mutation = useMutation((urlArr: string[]) =>
  //   fetchClient
  //     .post(public_update, {
  //       room_public_id: data?._id,
  //       pictures: urlArr,
  //       name: InfoObj.name,
  //       dong: InfoObj.dong,
  //       floor: InfoObj.floor,
  //       detail_address: InfoObj.location,
  //       phone_number: InfoObj.number,
  //       description: InfoObj.description,
  //     })
  //     .then(() => {
  //       setImages(null);
  //       queryClient.invalidateQueries([public_detail_key, kor]); //연관된 키 많음. 이것만 인벨리테이트
  //       toast({
  //         ...success,
  //         title: "시설 정보 변경",
  //         description: `해당 시설 정보가 변경되었습니다.`,
  //       });
  //       useCreateLog({
  //         screen_name: "FacilityDetailScreen",
  //         key: "UpdateFacility",
  //         parameter: String(data?._id),
  //         data,
  //       });
  //     })
  //     .catch((err) =>
  //       useCreateLog({
  //         screen_name: "FacilityDetailScreen",
  //         key: "UpdateFacility",
  //         parameter: String(data?._id),
  //         data: err.toJSON(),
  //       })
  //     )
  // );

  const onBeforeUpdate = async () => {
    if ((!images || images.length === 0) && fileArr.length === 0)
      return toast.warning({
        title: "알림",
        description: "대표 사진은 필수 입력 사항입니다.",
      });

    if (!InfoObj.dong || !InfoObj.floor || !InfoObj.name)
      return toast.warning({
        title: "안내",
        description: "시설 수정을 위해 시설명, 시설 위치는 필수로 입력해주세요.",
      });

    const newObj: IUseMutationPublicRoom = {
      room_public_id: String(data?._id),
      name: InfoObj.name,
      dong: InfoObj.dong,
      floor: InfoObj.floor,
      detail_address: InfoObj.location,
      phone_number: InfoObj.number,
      description: InfoObj.description,
    };

    try {
      if (images && images.length > 0) {
        const url = await useUploadFile(images);
        // setFileUrl(newArr.data.data?.[0]);
        // mutation.mutate([...url.data.data, ...fileArr]);
        newObj.pictures = [...url.data.data, ...fileArr];
        await updatePublicRoom.mutateAsync(newObj);
      } else {
        // mutation.mutate(fileArr);
        newObj.pictures = fileArr;
        await updatePublicRoom.mutateAsync(newObj);
      }
    } catch (err) {
      return toast.warning({
        title: "시설 정보 수정 오류",
        description: "시설 수정에 일시적으로 실패했습니다. 잠시 후 다시 시도해주세요.",
      });
    }

    setImages(null);
    toast.success({
      title: "시설 정보 변경",
      description: `해당 시설 정보가 변경되었습니다.`,
    });
  };

  if (auth === "no_auth") return <GetAuthorized />;

  return (
    <MainContainer>
      <MainActionBox>
        <FacilityInfo
          data={data}
          InfoObj={InfoObj}
          setInfoObj={setInfoObj}
          images={images || []}
          setImages={setImages}
          fileArr={fileArr}
          setfileArr={setFileArr}
          auth="edit"
        />
        <Button variant="m_blue" fontSize="1.25rem" onClick={onBeforeUpdate}>
          저장
        </Button>
      </MainActionBox>
    </MainContainer>
  );
};

export default function PublicFacilityFrame() {
  return (
    <Basicframe>
      <PublicFacilityDetails />
    </Basicframe>
  );
}
