import { useMutation } from "@tanstack/react-query";
import { httpV2 } from "../../lib/httpV2";
import { queryClient } from "../..";

type Request = {
  companyId: string;
  memberId: string;
  permission: 1 | 2 | 3;
};

export const useUpdateMemberPermissionMutation = () => {
  return useMutation({
    mutationFn: ({ companyId, memberId, permission }: Request) => {
      return httpV2.patch(`/companies/${companyId}/members/${memberId}`, { permission });
    },
    onSuccess: (_, { companyId }) => {
      queryClient.invalidateQueries([`/companies/${companyId}`]);
    },
  });
};
