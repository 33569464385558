import { Box, Button, Flex, Select, Text } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import SmallInputs from "../../../components/Input/shortText";
import * as C from "../../../components/styled/index";
import { IResevationType } from "./types";
import { CalendarRange } from "../../../components/datePicker";
import PublicRoomTable from "./tableItem";
import useUserAuth from "../../../components/hooks/useUserAuth";
import CheckLocationBox from "../components/checkBox/locationBox";
import ReservationInfo from "../timeline/_view/reservationInfo";
import { useFetchReservedDetail } from "../commons/queries";
import { useEffect, useState } from "react";
import { ISchedule } from "../../../lib/types/Imodels";

export default function PublicReservationUI({
  dongArr,
  floorArr,
  onUpdateParams,
  searchParams,
  ...props
}: IResevationType) {
  const auth = useUserAuth("room_public");
  const checklocation = { dongArr, floorArr };

  const ids = sessionStorage.getItem("meeting_info");
  const [isModalOpen, setIsModalOpen] = useState(ids === null ? false : true);
  const { data } = useFetchReservedDetail(String(ids || ""), isModalOpen);

  const onClickCloseDetails = () => {
    setIsModalOpen(false);
    sessionStorage.removeItem("meeting_info");
  };

  useEffect(() => {
    if (ids === null) return;

    setIsModalOpen(true);
  }, [ids]);

  return (
    <>
      <div>
        {auth === "edit" ? (
          <Box p="10px 0" borderBottom="1px solid #d1d2db">
            <Text>전체 예약 내역을 조회하고 예약 내용 확인 후 확정/취소할 수 있어요.</Text>
            <Text>
              관리자의 확인이 필요한 회의실은 예약 확정해주셔야 신청한 이용자가 이용할 수 있습니다. 과거의 내역은 기간
              필터를 과거로 설정하여 확인해주세요.
            </Text>
          </Box>
        ) : (
          <Text p="10px 0" borderBottom="1px solid #d1d2db">
            해당 기능은 열람만 가능합니다. 수정 권한 필요 시 최고관리자에게 문의해주세요.
          </Text>
        )}
        <Box p="28px 0">
          <Flex pb="20px">
            <CheckLocationBox {...checklocation} />
            <C.SelectBox>
              <Text variant="default">예약상태</Text>
              <Select
                variant="default"
                w="180px"
                value={searchParams.get("status") || ""}
                onChange={(e) => onUpdateParams("status", e.target.value)}
              >
                <option value="">전체</option>
                <option value="pending">예약 신청</option>
                <option value="approved">예약 확정</option>
                <option value="rejected">예약 취소(관리자)</option>
                <option value="canceled">예약 취소(이용자)</option>
              </Select>
            </C.SelectBox>
            <Box>
              <Text variant="default">기간(예약일 기준)</Text>
              <C.DateWithIconBox>
                <DatePicker
                  locale={ko}
                  selectsRange={true}
                  startDate={props.dateRange[0]}
                  endDate={props.dateRange[1]}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => props.setDateRange(date)}
                  isClearable={true}
                  customInput={<CalendarRange value={String(props.dateRange)} />}
                />
              </C.DateWithIconBox>
            </Box>
          </Flex>
          <Flex alignItems="flex-end">
            <Box>
              <Text variant="default">검색</Text>
              <SmallInputs
                value={searchParams.get("search") || ""}
                onChange={(e) => onUpdateParams("search", e.target.value)}
                placeholder="검색할 회의실명을 입력해주세요."
                onKeyDown={(e) => e.key === "Enter" && props.onClickSubmit()}
              />
            </Box>
            <Button onClick={props.onClickSubmit} variant="basic">
              검색
            </Button>
          </Flex>
        </Box>
      </div>
      <Box>
        <C.TableText pt="8px">
          총 <C.TableCount>{props.total || 0}개</C.TableCount>의 예약 내역이 조회되었습니다.
        </C.TableText>
        <C.ListTableBox>
          <C.ListTableHeader>
            <div style={{ width: "20%" }}>신청일</div>
            <div>시설유형</div>
            <div>시설명</div>
            <div style={{ width: "20%" }}>예약일시</div>
            <div style={{ width: "20%" }}>입주사명(호실)</div>
            <div>상태</div>
          </C.ListTableHeader>
          {props.data?.map((el: ISchedule) => (
            <PublicRoomTable key={String(el._id)} el={el} refetch={props.refetch} setIsModalOpen={setIsModalOpen} />
          ))}
        </C.ListTableBox>
        {data && ids === String(data._id) && (
          <ReservationInfo
            title="예약 정보"
            isOpen={isModalOpen}
            onClose={onClickCloseDetails}
            data={data}
            refetch={props.refetch}
            auth={auth ? auth : "no_auth"}
          />
        )}
      </Box>
    </>
  );
}
