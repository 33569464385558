import { useDisclosure, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { delete_account, register_account } from "../../../lib/api/queries/urls";
import { success, unAuthUser, warning } from "../../../lib/theme/toast";
import { IAccount } from "../../../lib/types/Imodels";
import AdministrationBankUI from "./presenter";
import FullPageSpinner from "../../../components/Spinner";
import GetAuthorized from "../../../components/Authrized";
import useAccessBank from "./access";
import { useAccountList } from "../commons/queries";

export default function AdministrationBank() {
  const toast = useToast();
  const auth = useAccessBank(["management_fee", "rent_fee"]);
  const { isOpen, onOpen, onClose } = useDisclosure(); //계좌 등록 모달
  const { isOpen: isDel, onToggle } = useDisclosure(); //계좌 삭제 모달
  const [bankId, setBankId] = useState("");
  const [account, setAccount] = useState("");
  const [owner, setOwner] = useState("");
  const [checkedArr, setCheckedArr] = useState<string[]>([]);
  //데이터 관련
  const { data, refetch } = useAccountList();

  const onCheckedAll = (checked: boolean) => {
    if (checked) {
      const allCheckedArr: string[] = [];
      data?.forEach((el) => allCheckedArr.push(String(el._id)));
      setCheckedArr(allCheckedArr);
    } else {
      setCheckedArr([]);
    }
  };
  const onChangeChecked = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedArr([...checkedArr, id]);
    } else {
      const newArr = checkedArr.filter((checked) => checked !== id);
      setCheckedArr(newArr);
    }
    if (checkedArr.length === data?.length) {
      return (checked = true);
    }
  };

  const onReset = () => {
    setBankId("");
    setAccount("");
    setOwner("");
    onClose();
  };

  const mutation = useMutation(() =>
    fetchClient
      .post<BaseResponse<IAccount>>(register_account, {
        bank_id: bankId,
        account_number: account,
        account_name: owner,
      })
      .then(() => {
        toast({
          ...success,
          title: "납부 계좌 등록 완료",
          description: "납부 계좌가 등록되었습니다.",
        });
        onReset();
        return refetch();
      })
      .catch((err) => {
        if (err.response?.status === 401) return toast({ ...unAuthUser });
      }),
  );

  const onRegisterAccount = () => {
    if (mutation.isLoading) return;
    if (!bankId || !account || !owner)
      return toast({
        ...warning,
        title: "계좌 등록 오류",
        description: "계좌 등록을 위해 빈 칸을 모두 입력해주세요.",
      });

    mutation.mutate();
  };

  const onDeleteAccount = () => {
    fetchClient
      .post<BaseResponse<string>>(delete_account, {
        account_ids: checkedArr,
      })
      .then(() => {
        toast({
          ...success,
          title: "납부 계좌 삭제 완료",
          description: "납부 계좌가 삭제되었습니다.",
        });
        setCheckedArr([]);
        return refetch();
      })
      .catch((err) => {
        if (err.response?.status === 401) return toast({ ...unAuthUser });
      });
  };

  if (auth === undefined) return <FullPageSpinner />;
  if (auth === "no_auth") return <GetAuthorized />;
  return (
    <AdministrationBankUI
      onToggle={onToggle}
      isDel={isDel}
      data={data}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      bankId={bankId}
      onRegisterAccount={onRegisterAccount}
      setBankId={setBankId}
      setAccount={setAccount}
      setOwner={setOwner}
      onReset={onReset}
      checkedArr={checkedArr}
      onCheckedAll={onCheckedAll}
      onChangeChecked={onChangeChecked}
      onDeleteAccount={onDeleteAccount}
    />
  );
}
