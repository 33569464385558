import styled from "@emotion/styled";
import { forwardRef, HTMLProps, Ref } from "react";
import { DownIcon } from "../styled";
import { colors } from "../styled/common";
import { MouseShadowBox } from "../styled/webApp";

const CalendarInput = styled.div`
  width: 224px;
  background-color: ${colors.whiteColor};
  border-radius: 0px;
  border: 1px solid ${colors.gray3};
  padding: 10px 12px;
  color: ${colors.gray4};
  line-height: 1;
  cursor: pointer;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  /* -moz-padding-before: 11px;
  -ms-padding-before: 11px;
  -moz-padding-after: 11px;
  -ms-padding-after: 11px; */
  input {
    appearance: none;
    background-color: inherit;
    width: 100%;
    :read-only {
      svg {
        display: none;
      }
    }
  }
`;

//일정 범위로 선택
export const CalendarRange = forwardRef(
  (
    { value, defaultValue, ...props }: HTMLProps<HTMLInputElement>,
    ref: Ref<HTMLInputElement>
  ) => {
    const { style, ...rest } = props;

    return (
      <CalendarInput style={style}>
        <input
          ref={ref}
          value={value ? value : defaultValue ? defaultValue : "기간 선택"}
          // onClick={(e) => console.log(e)}
          {...rest}
        />
        <DownIcon
          style={{ right: "4%", display: props.disabled ? "none" : "block" }}
        />
      </CalendarInput>
    );
  }
);

const ShadowCalendar = styled(MouseShadowBox)`
  width: 15rem;
  svg {
    color: ${colors.gray4};
    right: 8%;
  }
`;

export const ShadowDateRange = forwardRef(
  (
    { value, ...props }: HTMLProps<HTMLInputElement>,
    ref: Ref<HTMLInputElement>
  ) => {
    return (
      <ShadowCalendar>
        <input
          style={{ flex: 1 }}
          ref={ref}
          // style={ShadowCalendar}
          // dateRange={dateRange}
          value={value !== "" ? value : "기간 선택"}
          // onClick={(e) => console.log(e)}
          // onChange={(date) => console.log(date)}
          {...props}
        />
        <DownIcon />
      </ShadowCalendar>
    );
  }
);

const DateInput = styled.div`
  width: 5.5rem;
  border-radius: 0px;
  z-index: 1;
  cursor: pointer;
  color: ${colors.gray4};
  input {
    cursor: pointer;
    appearance: none;
    background-color: inherit;
  }
`;

//일정 범위로 선택
export const GrayBackGroundRange = forwardRef(
  (
    { value, defaultValue, ...props }: HTMLProps<HTMLInputElement>,
    ref: Ref<HTMLInputElement>
  ) => {
    const { style, ...rest } = props;
    return (
      <DateInput style={style}>
        <input
          ref={ref}
          value={value ? value : defaultValue ? defaultValue : "기간 선택"}
          // onClick={(e) => console.log(e)}
          {...rest}
        />
        {/* <DownIcon style={{ right: "4%" }} /> */}
      </DateInput>
    );
  }
);
