import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FC, MouseEvent, PropsWithChildren, ReactNode } from "react";
import styled from "@emotion/styled";
import { colors } from "../styled/common";
import { breakPoints } from "../styled/media";

export interface IMenuTab {
  isChecked: boolean;
  path: string;
  section: string;
}

export interface ITabs {
  isActive: boolean;
  tab: string;
}

interface IFrame extends PropsWithChildren {
  menuTabs: IMenuTab[];
  fixed?: ReactNode;
  tabs: ITabs[];
  onClickMovePage: (e: MouseEvent<HTMLLIElement>) => void;
}

//basic frame css
const MainTabMarker = styled(Flex)`
  justify-content: space-between;
  height: 3rem;
  @media ${breakPoints.mobile} {
    /* height: 3.5rem; */
    /* padding: 0.5rem; */
    /* padding-bottom: 0; */
    margin-top: 16px;
  }
`;

const MainBox = styled.div`
  width: 100%;
  min-height: calc(100% - 3rem);
  /* height: calc(100% - 3rem); */
  background-color: ${colors.whiteColor};
  border-radius: 0 0.75rem 0.75rem 0.75rem;
  @media ${breakPoints.mobile} {
    border-radius: 0;
  }
`;
/* @media ${breakPoints.mobile} {
    min-height: calc(100% - 3.5rem);
} */

const SelectMenu = styled.ul`
  width: 100%;
  padding: 0 1.5rem;
  display: flex;
  li {
    list-style: none;
  }
`;

const Selected = styled.li<{ active: boolean }>`
  margin-right: 0.75rem;
  padding: 1.25rem 0;
  color: ${({ active }) => !active && colors.gray3};
  font-weight: ${({ active }) => (active ? 700 : 400)};
  position: relative;
  cursor: pointer;
  ::after {
    content: "";
    ${({ active }) =>
      active &&
      `position: absolute;
    bottom: -1.5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${colors.gray5}`};
  }
`;

const TabOnBox = styled.div<{ checked: boolean }>`
  width: 8.5rem;
  padding: 0.75rem 0;
  text-align: center;
  border-radius: 1rem 1rem 0 0;
  margin-right: 4px;
  font-weight: 500;
  cursor: pointer;
  background-color: ${({ checked }) =>
    checked ? colors.mainBlue : colors.whiteColor};
  color: ${({ checked }) => (checked ? colors.whiteColor : colors.gray4)};
  ${({ checked }) =>
    !checked && `box-shadow : inset 0px -4px 8px rgba(0, 0, 0, 0.1);`};
  @media ${breakPoints.mobile} {
    width: 7.5rem;
  }
`;

const CommonMenuTabframe: FC<IFrame> = ({ children, menuTabs, ...props }) => {
  const navigate = useNavigate();

  return (
    <>
      <MainTabMarker>
        <Flex>
          {menuTabs.map((el) => (
            <TabOnBox
              key={el.section}
              checked={el.isChecked}
              onClick={() => navigate(el.path)}
            >
              {el.section}
            </TabOnBox>
          ))}
        </Flex>
        {props.fixed && props.fixed}
        {/* <TabOnBox checked={true} onClick={() => navigate(`/point/history`)}>
          포인트관리
        </TabOnBox> */}
      </MainTabMarker>
      <MainBox>
        <SelectMenu>
          {props.tabs.map((el) => (
            <Selected
              key={el.tab}
              id={el.tab}
              onClick={props.onClickMovePage}
              active={el.isActive}
            >
              {el.tab}
            </Selected>
          ))}
        </SelectMenu>
        <>{children}</>
      </MainBox>
    </>
  );
};

export default CommonMenuTabframe;
