import { useNavigate } from "react-router";
import { Helmet } from "react-helmet-async";
import { ReactElement, useEffect } from "react";
import { MediaInfo, MediaInfoBox } from "../components/styled/info";

export default function NotFound(): ReactElement | null {
  const navigate = useNavigate();

  useEffect(() => {
    const redirect = setTimeout(() => {
      navigate("/dashboard");
    }, 3000);
    return () => clearTimeout(redirect);
  }, []);

  return (
    <>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <MediaInfoBox style={{ height: "100vh", background: "#F4F4F5" }}>
        <MediaInfo>
          잘못된 접근입니다.
          <br />
          잠시 후 대시보드 페이지로 이동합니다.
        </MediaInfo>
      </MediaInfoBox>
    </>
  );
}
