import { Button, Checkbox, Flex, Select, Text, useDisclosure, useToast } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import MoreContentInputs from "../../../../components/Input/contents";
import SelectCheckedBox from "../../../../components/selectBox/checkedOption";
import SelectButton from "../../../../components/selectBox/checkedOption/buttonItems";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import * as C from "../../../../components/styled/index";
import * as S from "../styled";
import { INewSurvey } from "../types";
import moment from "moment";
import CancleModal from "../../../../components/modal/CancelModal";
import { useMutation } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { survey_delete, survey_list_key } from "../../../../lib/api/queries/keys";
import { queryClient } from "../../../..";
import { success, warning, WarningTime } from "../../../../lib/theme/toast";
import { limitContent, limitTitle } from "../../../../lib/utils/format";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { MemoizedQuestion } from "./questionItems";
import { ChangeEvent, useCallback } from "react";
import { ISurveyAnswer, ISurveyQuestion } from "../../../../lib/types/Imodels";
import { useRoomType } from "../../../../lib/utils";

export default function NewSurveyPageUI(props: INewSurvey) {
  const toast = useToast();
  const navigate = useNavigate();
  const { survey_id } = useParams();
  const room_type = useRoomType();

  const { isOpen, onOpen, onClose } = useDisclosure(); //등록 확인 모달
  const { isOpen: isDelete, onOpen: onDelete, onClose: Delete } = useDisclosure(); //삭제 확인 모달
  const real_start_date = props.startDate
    ? new Date(moment(props.startDate).add(-9, "hour").format("YYYY-MM-DD HH:mm"))
    : null;
  const real_end_date = props.endDate
    ? new Date(moment(props.endDate).add(-9, "hour").format("YYYY-MM-DD HH:mm"))
    : null;

  const onSetStartDate = (date: Date | null) => {
    if (!date) return;

    if (typeof props.endDate === "string") {
      //여기는 수정
      if ((real_end_date || new Date()) <= date)
        return toast({
          ...WarningTime,
          description: "시작일은 설문 종료일보다 이전으로 설정해주세요.",
        });
    } else {
      if (!props.endDate) return props.setStartDate(date);
      if (date > props.endDate)
        return toast({
          ...WarningTime,
          description: "시작일은 설문 종료일보다 이전으로 설정해주세요.",
        });
    }
    // if (props.endDate && date) {
    //   console.log(date, new Date(props.endDate));
    //   if (date > new Date(props.endDate))
    //     return toast({
    //       ...warning,
    //       title: "시간 설정 오류",
    //       description: "시작일은 설문 종료일보다 이전으로 설정해주세요.",
    //     });
    // }
    props.setStartDate(date);
  };

  const onSetEndDate = (date: Date | null) => {
    if (!date) return;

    if (typeof props.startDate === "string") {
      //여기는 수정
      if ((real_start_date || new Date()) >= date)
        return toast({
          ...WarningTime,
          description: "설문 종료 일자는 설문 시작일 이후로 설정해주세요.",
        });
    } else {
      if (!props.startDate) return props.setEndDate(date);
      if (date <= props.startDate) {
        toast({
          ...WarningTime,
          description: "설문 종료 일자는 설문 시작일 이후로 설정해주세요.",
        });
        return props.setEndDate(props.startDate);
      }
    }
    props.setEndDate(date);
  };

  const mutation = useMutation(() =>
    fetchClient
      .post(survey_delete, { survey_id })
      .then((res) => {
        queryClient.invalidateQueries([survey_list_key, 1]);
        toast({
          ...success,
          title: "설문조사 삭제 완료",
          description: `설문조사가 삭제되었습니다. 설문 조사 현황 페이지로 이동합니다.`,
        });
        navigate("/survey/search", { replace: true });
        return res.data.data;
      })
      .catch((error) => console.log(error.message)),
  );

  const onDeleteSurvey = () => {
    if (mutation.isLoading) return;
    mutation.mutate();
  };

  const onClickBeforeRegister = () => {
    if (!props.surveyBody || !props.surveyTitle || !props.target || !props.startDate || !props.endDate)
      return toast({
        ...warning,
        title: "설문조사 등록 오류",
        description: "설문 등록을 위해 모든 항목을 기입해주세요.",
      });

    if (room_type.length > 1 && props.checkedItems.length === 0)
      return toast({
        ...warning,
        title: "설문조사 등록 오류",
        description: "설문 등록을 위해 모든 항목을 기입해주세요.",
      });

    // if (
    //   props.isEdit &&
    //   !props.isDirect &&
    //   new Date(props.startDate) <= new Date()
    // ) {
    //   return toast({
    //     ...warning,
    //     title: "알림",
    //     description:
    //       "설문 조사 수정은 설문 시작 시간 이전에만 수정이 가능합니다.",
    //   });
    // } UI상으로 이전 시간은 어짜피 선택 안될것

    const newArr = [...props.inputs];
    const isQuestionNotEmpty = newArr.every((el) => el.question !== "");
    const noAssay = newArr.filter((el) => el.question_type !== "essay_question");
    const isAnswerNotEmpty = noAssay.every((el) => el.answers.every((ele) => ele.answer !== "") === true);
    // console.log(props.inputs, isQuestionNotEmpty, isAnswerNotEmpty);
    if (!isQuestionNotEmpty || !isAnswerNotEmpty)
      return toast({
        ...warning,
        title: "알림",
        description: "설문 등록을 위해 모든 항목을 기입해야합니다. 질문과 응답을 다시 한번 확인해주세요.",
      });

    // if (
    //   props.inputs.filter(
    //     (el) =>
    //       (el.question_type === "multiple_choice" &&
    //         el.answer_num === undefined) === true
    //   ).length !== 0
    // ) {
    //   return toast({
    //     ...warning,
    //     title: "알림",
    //     description: "복수 선택을 위한 최소 응답 수를 입력해주세요.",
    //   });
    // }
    onOpen();
  };

  const onClickProcess = () => {
    onClose();
    props.isEdit ? props.onEditSurvey() : props.onRegisterSurvey();
  };

  //1차 수정본 더 좋은 방식 찾기
  const onDeleteQuestion = useCallback(
    (idx: string) => {
      if (props.inputs.length === 1)
        return toast({
          ...warning,
          title: "질문 삭제 실패",
          description: "최소 1개 이상의 질문을 등록해야합니다.",
        });

      // const newArr = QuestionArr.filter((el) => el.props.id !== idx);
      // setQuestionArr(newArr);
      const newInput = props.inputs.filter((el) => el._id !== idx);
      props.setInputs(newInput);
    },
    [props.inputs],
  );

  const onChangeAnswers = useCallback(
    (str: ISurveyAnswer[], ele: ISurveyQuestion) => {
      const newObj = { ...ele };
      newObj.answers = str;
      const newArr = props.inputs.map((el) => (el._id === newObj._id ? newObj : el));
      props.setInputs(newArr);
    },
    [props.inputs],
  );

  const onChangeRequired = useCallback(
    (str: boolean, ele: ISurveyQuestion) => {
      const newObj = { ...ele };
      newObj.is_required = str;
      const newArr = props.inputs.map((el) => (el._id === newObj._id ? newObj : el));
      props.setInputs(newArr);
    },
    [props.inputs],
  );

  const onChangeValues = useCallback(
    (str: string, ele: ISurveyQuestion, _id: "question" | "question_type" | "image") => {
      const newObj = { ...ele };
      newObj[_id] = str;
      const newArr = props.inputs.map((el) => (el._id === newObj._id ? newObj : el));
      props.setInputs(newArr);
    },
    [props.inputs],
  );

  const onStartDirectSurvey = (e: ChangeEvent<HTMLInputElement>) => {
    props.setIsDirect((prev) => !prev);
    props.setStartDate(e.target.checked ? new Date() : props.isEdit ? props.data?.start_time || null : null);
  };

  return (
    <C.Wrapper>
      <Text variant="static">
        <C.LeftIcons onClick={() => navigate(-1)} />
        설문조사 {props.isEdit ? "수정" : "등록"}
      </Text>
      <C.WrapperBody>
        <div>
          <Text>대상 설정에 따라 입주자의 설문조사 참여 여부가 결정됩니다.</Text>
          <Text>일정은 시작일시와 종료일시로 구분하여 관리되며 등록된 일정에 맞춰 설문조사가 공개됩니다.</Text>
          <Text>설문조사에 필요한 정보를 모두 입력해야 등록이 가능합니다.</Text>
          <Text color="#FB4667">
            설문조사가 시작된 이후에는 등록한 설문조사의 수정/삭제가 불가합니다. 설문시작 전에 반드시 확인해주세요.
          </Text>
        </div>
        <S.SurveyBox>
          <C.NewPost>
            <C.PostTitle>설문대상</C.PostTitle>
            <Flex zIndex={5}>
              {room_type.length > 1 && (
                <SelectCheckedBox
                  checkedItems={props.checkedItems}
                  maxItems={room_type.filter((el) => el.label !== "owner").map((el) => el.value)}
                  onCheckedAll={props.onCheckedAll}
                  default="전체"
                  text={props.checkedItems.length === 0 ? "설문대상 선택" : props.checkedItems}
                >
                  {room_type
                    .filter((v) => v.label !== "owner")
                    .map((el) => (
                      <SelectButton
                        key={el.id}
                        checkedItems={props.checkedItems}
                        onChangeChecked={props.onChangeChecked}
                        value={el.value}
                        label={el.label}
                      />
                    ))}
                </SelectCheckedBox>
              )}
              <Select
                w="180px"
                variant="default"
                value={props.target}
                onChange={(e) => props.setTarget(e.target.value)}
                ml={room_type.length <= 1 ? 0 : "12px"}
              >
                <option value="all">전체</option>
                <option value="master">입주사 대표</option>
                {room_type.some((obj) => obj.label === "owner") && <option value="owner">구분소유자</option>}
              </Select>
            </Flex>
          </C.NewPost>
          <C.NewPost>
            <C.PostTitle>설문기간</C.PostTitle>
            <S.SurveyDate>
              <C.DateWithIconBox style={{ zIndex: 1 }}>
                <DatePicker
                  locale={ko}
                  selectsStart
                  showTimeSelect
                  minDate={new Date()}
                  disabled={props.isDirect}
                  onChange={onSetStartDate}
                  endDate={typeof props.endDate === "string" ? real_end_date : props.endDate}
                  selected={typeof props.startDate === "string" ? real_start_date : props.startDate}
                  minTime={
                    new Date().getDate() ===
                    (props.startDate ? moment.utc(props.startDate).date() : new Date().getDate())
                      ? new Date()
                      : new Date(new Date().setHours(0, 0, 0))
                  }
                  maxTime={new Date(new Date().setHours(23, 59, 59))}
                  customInput={
                    <S.SurveyTextBox isDirect={props.isDirect}>
                      <Text>
                        {props.startDate ? (
                          <>
                            {(typeof props.startDate === "string"
                              ? moment.utc(props.startDate)
                              : moment(props.startDate)
                            ).format("YYYY년 MM월 DD일 HH:mm")}
                          </>
                        ) : (
                          <>{props.isDirect ? moment().format("YYYY년 MM월 DD일 HH:mm") : "시작일 선택"}</>
                        )}
                      </Text>
                    </S.SurveyTextBox>
                  }
                />
              </C.DateWithIconBox>
              <S.SurveyTextBox isDirect={props.isDirect}>
                <Text p="0 8px" fontSize="20px">
                  ~
                </Text>
              </S.SurveyTextBox>
              <C.DateWithIconBox style={{ zIndex: 1 }}>
                <DatePicker
                  locale={ko}
                  selectsEnd
                  showTimeSelect
                  onChange={onSetEndDate}
                  selected={typeof props.endDate === "string" ? real_end_date : props.endDate}
                  startDate={typeof props.startDate === "string" ? real_start_date : props.startDate}
                  minDate={real_start_date || new Date()}
                  minTime={
                    new Date().getDate() === new Date(props.endDate ?? new Date().getTime()).getDate()
                      ? new Date()
                      : new Date(new Date().setHours(0, 0, 0))
                  }
                  maxTime={new Date(new Date().setHours(23, 59, 59))}
                  customInput={
                    <S.SurveyTextBox>
                      <Text>
                        {props.endDate ? (
                          <>
                            {(typeof props.endDate === "string"
                              ? moment.utc(props.endDate)
                              : moment(props.endDate)
                            ).format("YYYY년 MM월 DD일 HH:mm")}
                          </>
                        ) : (
                          <>종료일 선택</>
                        )}
                      </Text>
                    </S.SurveyTextBox>
                  }
                />
              </C.DateWithIconBox>
              <FiChevronDown fontSize="large" />
            </S.SurveyDate>
            <Checkbox ml="4" variant="default" value={JSON.stringify(props.isDirect)} onChange={onStartDirectSurvey}>
              {props.isEdit ? "수정" : "등록"} 즉시 설문 공개
            </Checkbox>
          </C.NewPost>
          <C.NewPost>
            <C.PostTitle>제목</C.PostTitle>
            <MoreContentInputs
              maxLength={100} //알림창 필요하면 101자
              onChange={(e) => {
                const isLimit = limitTitle(e.target.value);
                if (!isLimit) return alert("제목은 최대 100자까지 입력할 수 있습니다.");
                props.setSurveyTitle(e.target.value);
              }}
              value={props.surveyTitle}
              placeholder="제목을 입력해주세요"
            />
          </C.NewPost>
          <C.NewPost>
            <C.PostTitle>설문내용</C.PostTitle>
            <Flex w="100%" flexDirection="column">
              <C.BasicTextArea
                h="96px"
                rows={2}
                maxLength={3000}
                value={props.surveyBody}
                placeholder={
                  "설문조사 내용을 입력하세요. \n해당 설문조사에 대한 내용을 간략하게 정리하여 등록하면 입주자의 설문조사 참여율을 좀 더 높일 수 있습니다."
                }
                onChange={(e) => {
                  const isLimit = limitContent(e.target.value);
                  if (!isLimit) return alert("설문 내용은 최대 3000자까지 입력할 수 있습니다.");
                  props.setSurveyBody(e.target.value);
                }}
              />
              <input type="file" ref={props.fileRef} onChange={props.onGetFileUrl} style={{ display: "none" }} />
              {props.imgBase !== "" && (
                <S.SurveyImage>
                  <img src={props.imgBase} alt="설문 이미지" />
                  <C.DeleteIcon onClick={props.onDeleteImg} />
                </S.SurveyImage>
              )}
              <div style={{ marginTop: props.imgBase ? 0 : "8px" }}>
                <Button variant="basic" onClick={() => props.fileRef.current?.click()}>
                  사진선택
                </Button>
              </div>
            </Flex>
          </C.NewPost>
          {props.inputs.map((el, idx) => (
            <MemoizedQuestion
              key={el._id}
              num={idx + 1}
              el={el}
              isEdit={props.isEdit}
              inputs={props.inputs}
              setInputs={props.setInputs}
              onChangeValues={onChangeValues}
              onChangeRequired={onChangeRequired}
              onChangeAnswers={onChangeAnswers}
              onDeleteQuestion={onDeleteQuestion}
            />
          ))}
          {/* {props.QuestionArr.map((el) => el)} */}
        </S.SurveyBox>
      </C.WrapperBody>
      <Flex justifyContent="space-between">
        <Button ml="0" onClick={props.onAddQuestion} variant="blueLine">
          질문 추가
        </Button>
        <div>
          {props.isEdit && (
            <Button onClick={onDelete} variant="delete">
              삭제
            </Button>
          )}
          <Button variant="bgBlue" onClick={onClickBeforeRegister}>
            {props.isEdit ? "수정하기" : "등록하기"}
          </Button>
        </div>
        <ConfirmModal
          isOpen={isOpen}
          onClose={onClose}
          onClickCancle={onClose}
          title={props.isEdit ? "설문조사 수정" : "설문조사 등록"}
          close={props.isEdit ? "수정하기" : "등록하기"}
          onClickSave={onClickProcess}
        >
          <Text>설문을 {props.isEdit ? "수정" : "등록"}할까요?</Text>
          <Text>설문이 시작되면 수정 및 삭제가 불가능합니다.</Text>
        </ConfirmModal>
      </Flex>
      <CancleModal isOpen={isDelete} onClose={Delete} onClickCancel={Delete} onClickSave={onDeleteSurvey}>
        <Text>설문을 삭제할까요?</Text>
        <Text>삭제된 데이터는 복구할 수 없습니다.</Text>
      </CancleModal>
    </C.Wrapper>
  );
}
