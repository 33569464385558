import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Select,
} from "@chakra-ui/react";
import * as C from "../../../../components/styled/index";
import * as N from "./styled";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import MoreContentInputs from "../../../../components/Input/contents";
import { INoticeUI } from "../types/type";
import { CustomDateRange } from "./calendar";
import QuillEditor from "./editor";
import { Controller, useForm } from "react-hook-form";
import SelectCheckedBox from "../../../../components/selectBox/checkedOption";
import SelectButton from "../../../../components/selectBox/checkedOption/buttonItems";
import { useRoomType } from "../../../../lib/utils";
import { useRef } from "react";
import UploadFile from "./UploadFile";

export default function NewNotificationUI(props: INoticeUI) {
  const methods = useForm({ mode: "onChange" });
  // console.log(props.isEdit, methods.formState.errors);
  const room_type = useRoomType();
  const calendarRef = useRef<DatePicker>(null);

  return (
    <>
      <Text variant="static">
        <C.LeftIcons onClick={props.onOpen} />
        {`공지/일정 게시글 ${props.isEdit ? "수정" : "등록"}`}
      </Text>
      <Modal variant="default" isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>되돌아가기</ModalHeader>
          <ModalBody>
            <Text>지금까지 작성하신 내용은 저장되지 않습니다.</Text>
            <Text>공지사항 관리 페이지로 이동할까요?</Text>
            <ModalFooter>
              <Button onClick={props.onClose} variant="cancel">
                취소
              </Button>
              <Button onClick={props.onMoveSchedule} variant="bgBlue">
                확인
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
      <form
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
        onSubmit={methods.handleSubmit(props.isEdit ? props.onUpdate : props.onSubmit)}
      >
        <C.NewPostBox>
          <C.NewPost>
            <C.PostTitle>공지 구분</C.PostTitle>
            <C.SelectBox>
              <Select
                variant="default"
                name={props.isEdit ? props.data?.notice_type : ""}
                value={props.notiType}
                onChange={(e) => props.setNotiType(e.target.value)}
              >
                <option value="" disabled>
                  유형 선택
                </option>
                {props.category?.map((el) => (
                  <option key={String(el._id)} value={el.title}>
                    {el.title}
                  </option>
                ))}
              </Select>
            </C.SelectBox>
            {room_type.length > 1 && (
              <SelectCheckedBox
                checkedItems={props.checkedItems}
                onCheckedAll={props.onCheckedAll}
                default="전체"
                text={props.checkedItems.length === 0 ? "공지대상 선택" : props.checkedItems}
                data={props.data?.notice_to}
                category={props.category}
              >
                {room_type?.map((el) => (
                  <SelectButton
                    key={el.id}
                    checkedItems={props.checkedItems}
                    data={props.data?.notice_to}
                    onChangeChecked={props.onChangeChecked}
                    value={el.value}
                    label={el.label}
                  />
                ))}
              </SelectCheckedBox>
            )}
            <N.ChooseRangeCalender isschedule={props.isschedule}>
              <CheckboxGroup value={props.isschedule} onChange={(e: string[]) => props.setSchedule(e)}>
                <Controller
                  control={methods.control}
                  name="is_important"
                  render={({ field }) => (
                    <Checkbox {...field} value="is_important" variant="default" m="0px 20px">
                      상단 고정글
                    </Checkbox>
                  )}
                />
                <Controller
                  control={methods.control}
                  name="is_scheduled"
                  render={({ field: { onChange } }) => (
                    <Flex>
                      <Checkbox
                        value="is_scheduled"
                        variant="default"
                        mr={2}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          calendarRef.current?.setOpen(true);
                        }}
                      />
                      <Flex align="center">
                        <Text>일정등록</Text>
                        <DatePicker
                          locale={ko}
                          minDate={new Date()}
                          selectsRange={true}
                          startDate={props.dateRange[0]}
                          endDate={props.dateRange[1]}
                          dateFormat="yyyy-MM-dd"
                          onChange={(update: [Date | null, Date | null]) => props.setDateRange(update)}
                          isClearable={true}
                          ref={calendarRef}
                          customInput={
                            <CustomDateRange
                              value={JSON.stringify(props.dateRange)}
                              isschedule={props.isschedule}
                              onChange={onChange}
                            />
                          }
                        />
                      </Flex>
                    </Flex>
                  )}
                />
              </CheckboxGroup>
            </N.ChooseRangeCalender>
          </C.NewPost>
          <C.NewPost>
            <C.PostTitle>제목</C.PostTitle>
            <Controller
              name="title"
              control={methods.control}
              // rules={{ required: props.isEdit ? false : true }}
              defaultValue={props.isEdit ? props.data?.title : ""}
              render={({ field: { onChange } }) => (
                <MoreContentInputs
                  maxLength={30}
                  onChange={(e) => {
                    if (e.target.value.length > 30) return alert("제목은 최대 30자까지 입력할 수 있습니다.");
                    onChange(e.target.value);
                  }}
                  defaultValue={props.isEdit ? props.data?.title : ""}
                  placeholder="제목을 입력해주세요"
                />
              )}
            />
          </C.NewPost>
          {/* <N.PickerContainer radio={props.radio}>
            <C.PostTitle>게시일자</C.PostTitle>
            <RadioGroup
              onChange={(e) => props.onClickReserve(e)}
              defaultValue={props.radio}
            >
              <Stack w={"100%"} direction="row" spacing={3}>
                <Radio value="1">등록 즉시 게시</Radio>
                <Radio value="2">게시 예약</Radio>
                <DatePicker
                  locale={ko}
                  minDate={new Date()}
                  selected={props.startDate}
                  onChange={(date: null) => props.setStartDate(date)}
                  showTimeSelect
                  // excludeTimes -> 제외시간 설정
                  dateFormat="yyyy-MM-dd HH:mm"
                  customInput={
                    <CustomWithTime
                      value={props.startDate}
                      radio={props.radio}
                      onClick={onClick}
                      onChange={onChange}
                    />
                  }
                  {...rest}
                />
              </Stack>
            </RadioGroup>
          </N.PickerContainer> */}
          <C.NewPost>
            <C.PostTitle>게시내용</C.PostTitle>
            <N.QuillBox>
              <QuillEditor methods={methods} data={props.data?.body} isEdit={props.isEdit} />
            </N.QuillBox>
          </C.NewPost>
          <C.NewPost>
            <C.PostTitle>첨부파일</C.PostTitle>
            <UploadFile
              isEdit={props.isEdit}
              files={props.files}
              setFiles={props.setFiles}
              fileArr={props.fileArr}
              setFileArr={props.setFileArr}
            />
          </C.NewPost>
          <C.Footerbutton>
            <Button type="submit" variant="bgBlue">
              {props.isEdit ? "수정" : "등록"}하기
            </Button>
          </C.Footerbutton>
        </C.NewPostBox>
      </form>
    </>
  );
}
