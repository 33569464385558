import { Dispatch, SetStateAction } from "react";
import { IUsage } from "./types";

export const format_method = (str: string) => {
  const method =
    str === "by_room" ? "호실별" : str === "by_use" ? "사용량별" : "면적별";
  return method;
};

export const decimal_point = /^(\d*)\.?(\d{1,2})?$/g;
export const usage_obj = (str: string) => `usage_${str}`;
export const total_price = (str: string) => `total_price_${str}`;

interface IChangedUsage {
  count: number; // e.target.value -> 총 사용량
  type: string; // 계산 방식
  usage: IUsage[]; // 수정된 요금 배열
  tenantArea?: number; //입주사 차지하는 총 면적
  setUsage: Dispatch<SetStateAction<IUsage[]>>;
}

// usage, price 모두 hooks로 만들기
// export const useCalculatePrice = (type:string) => {
//       switch (type) {
//       case "by_room":
//       case:
//       2번_계산식()
//       case:
//       3번_계산식()
//       default:
//     }
// }
export const onChangeUsage = (props: IChangedUsage) => {
  const { count, usage, type, tenantArea, setUsage } = props;

  switch (type) {
    case "by_area":
      usage.forEach((el) => {
        if (el.area && tenantArea) {
          const percent = Number.parseFloat(
            ((el.area * 100) / tenantArea).toFixed(4)
          );
          el.count = (count * (percent / 100)).toFixed(4);
          el.percent = percent;
        }
      }); // 사용량이 바뀌어도 기존 면적은 바뀌지않음 = setDifference X
      break;
    case "by_room":
      {
        const calc = (count / usage.length).toFixed(4);
        setUsage(usage.map((el) => ({ ...el, ...{ count: calc } })));
      }
      break; // 사용량이 바뀌어도 호실 갯수는 바뀌지않음 = setDifference X
    default:
      return;
  }
};

interface IChangedPrice extends IChangedUsage {
  setDifference: Dispatch<SetStateAction<number>>; // 차액 계산
}

export const onChangePrice = (props: IChangedPrice) => {
  const { count, usage, type, tenantArea, setUsage, setDifference } = props;

  let difference = 0;
  const getDifference = (price: number) => (difference = difference + price);

  switch (type) {
    case "by_area":
      usage.forEach((el) => {
        if (el.area && tenantArea) {
          const percent = el.percent
            ? el.percent
            : Number.parseFloat(((el.area * 100) / tenantArea).toFixed(4));
          // console.log((count * (percent / 100)).toFixed(4));
          const new_price = Number.parseFloat(
            (count * (percent / 100)).toFixed(4)
          ); //백엔드로 소수점 4자리 보냄
          // const new_price = Math.ceil(count * (percent / 100));
          el.price = new_price;
          el.percent = percent;
          getDifference(Math.ceil(count * (percent / 100)));
        }
      });
      break;
    case "by_room":
      {
        const calc = count / usage.length; //소수점 처리 필요
        setUsage(
          usage.map((el) => {
            getDifference(Math.ceil(calc));
            return { ...el, ...{ price: Number.parseFloat(calc.toFixed(4)) } };
          })
        );
      }
      break;
    case "by_use":
      {
        const result = usage.map((el) => {
          if (el.percentage) {
            const new_price = count * (el.percentage / 100);
            // const new_price = Math.ceil(count * (el.percentage / 100));
            el.price = Number.parseFloat(new_price.toFixed(4));
            getDifference(Math.ceil(new_price));
          }
          return el;
        });
        setUsage(result);
      }
      break;
    default:
      return;
  }
  setDifference(difference);
};
