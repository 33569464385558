import * as C from "../../../components/styled";
import { getDate, getTime } from "../../../lib/utils/format";
import { IResevationTable } from "./types";
import moment from "moment";
import { format_user_status } from "../../../lib/utils/dataFormat";

export default function PublicRoomTable(props: IResevationTable) {
  const onOpenMeetingInfo = () => {
    if (!props.el) return;
    sessionStorage.setItem("meeting_info", String(props.el._id));
    props.setIsModalOpen(true);
  };

  return (
    <C.ListTableBody onClick={onOpenMeetingInfo}>
      <div style={{ width: "20%" }}>{moment.utc(props.el?.createdAt).format("YYYY-MM-DD HH:mm")}</div>
      <div>{props.el?.room_public_id?.room_public_type}</div>
      <div>{props.el?.room_public_id?.name}</div>
      <div style={{ width: "20%" }}>
        {getDate(props.el?.start_date)} {getTime(props.el?.start_date)}-{getTime(props.el?.end_date)}
      </div>
      <C.FixedTitle style={{ textAlign: "center", width: "20%" }}>
        {props.el?.tenant_id?.xperp_name || ""}
        {props.el?.tenant_id?.room_ids.length === 0 || !props.el?.tenant_id?.room_ids
          ? ""
          : `(${props.el?.tenant_id?.room_ids[0].dong}동 ${props.el?.tenant_id.room_ids[0].ho}호)`}
      </C.FixedTitle>
      <div>{format_user_status(props.el?.check_status)}</div>
    </C.ListTableBody>
  );
}
