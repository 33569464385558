import { Checkbox, useDisclosure } from "@chakra-ui/react";
import moment from "moment";
import * as S from "../../../../components/styled/index";
import { IMeetingRoomItem } from "../../commons/types";
import EditMeetingRoomModal from "../../components/detailModal";

export default function MeetingRoomItem(prop: IMeetingRoomItem) {
  const { target, setTargetId, ...props } = prop;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const onClickOpenInfo = () => {
    setTargetId(String(props.el._id));
    onOpen();
  };

  return (
    <>
      <div
        className="cursor-pointer border-b border-gray-400 transition-all duration-75 hover:bg-blue-50 hover:opacity-75"
        onClick={onClickOpenInfo}
      >
        <S.ListCheckBox style={{ width: "10%" }} onClick={(e) => e.stopPropagation()}>
          <Checkbox
            variant="default"
            isChecked={props.checkedItems?.includes(`${props.el._id}`) ? true : false}
            onChange={(e) => {
              props.onChangeChecked(e.currentTarget.checked, `${props.el._id}`);
            }}
            // isDisabled={props.el.schedule_ids?.length === 0 ? false : true}
          ></Checkbox>
        </S.ListCheckBox>
        <div className="w-1/5">{props.el.dong}동</div>
        <div className="w-1/5">{props.el.name}</div>
        <div className="w-1/5">{props.el.people_num}명</div>
        <div className="w-1/5">{props.el.is_approval_required ? "관리자 확인 후 확정" : "예약신청과 동시에 확정"}</div>
        <div className="w-1/5">{moment.utc(props.el.createdAt).format("YYYY-MM-DD")}</div>
      </div>
      {target && isOpen && <EditMeetingRoomModal onClose={onClose} data={target} isOpen={isOpen} />}
    </>
  );
}
