import { useState, useEffect, ChangeEvent } from "react";
import { Button, Modal, ModalBody, ModalContent, ModalOverlay, useToast } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { breakPoints } from "../../../../components/styled/media";
import GrayRadiusTextArea from "../../../../components/textarea";
import { colors } from "../../../../components/styled/common";
import { MdError } from "@react-icons/all-files/md/MdError";
import { WarnMessageBox } from "../../../../components/styled";
import { ShadowBox } from "../../../../components/styled/webApp";
import GrayColorSelect from "../../../../components/select";
import { fetchClient } from "../../../../lib/api/axios";
import { complain_category_list, complaint_detail_key } from "../../../../lib/api/queries/keys";
import { IComplainList } from "../../../../lib/types/Imodels";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useUpdateComment } from "../../commons/queries";
import { useParams } from "react-router-dom";
import { complaint_progress } from "../../../../lib/api/queries/urls";
import { queryClient } from "../../../..";
import { success, warning } from "../../../../lib/theme/toast";

interface isActiveOption {
  isActiveOption: boolean;
}

const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ModalTitle = styled.p`
  color: #111827;
  font-family: Noto Sans KR;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const SubTitle = styled.span`
  color: #6b7280;
  font-family: Noto Sans CJK KR;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 12px;
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
`;

const SubmitButton = styled(Button)`
  display: flex;
  max-width: 214px;
  max-height: 100px;
  min-height: 42px;
  height: auto;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 1;
  border-radius: 8px;
  background-color: #2563eb;
  opacity: ${(props: isActiveOption) => (props.isActiveOption ? "1" : "0.4")};
  color: #ffffff;

  &:hover {
    background-color: #2563eb;
    box-shadow: none;
  }
  :hover[disabled] {
    background-color: #2563eb;
    box-shadow: none;
  }
`;

const CategoryContainer = styled.div`
  margin-bottom: 16px;
`;

const ComplaintWarnBox = styled(WarnMessageBox)`
  font-size: 1rem;
  align-items: flex-start;
  svg {
    margin: 4px 4px 0 0;
  }
  div {
    word-break: keep-all;
    width: calc(100% - 1.5rem);
  }
  @media ${breakPoints.mobile} {
    svg {
      margin: 3px 4px 0 0;
    }
  }
`;

const Divsion1 = styled.div`
  width: 100%;
  height: 7.5rem;
  border-radius: 0.5rem;
  resize: none;
  margin: 0;
`;

// 커스텀 카테고리
const CustomCategoryModal = (props: {
  onClose: () => void;
  isOpen: boolean;
  modalType: boolean;
  data: string;
  type: string;
  selectedMainCategory?: {
    mainCategory: string;
    subCategory: string;
  };
}) => {
  /// 처리하기
  const tempUser = { type: "tenant" };

  const { createComment } = useUpdateComment();
  const { complaintId } = useParams();
  const toast = useToast();

  const [changeState, setChangeState] = useState(props.data);
  const [categoryList, setCategoryList] = useState([]);
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories, setSubCategories] = useState<string[]>();
  const [selectedMainCategory, setSelectedMainCategory] = useState("");
  const [selectedSubCateogry, setSelectedSubCategory] = useState("");
  const [comment, setComment] = useState(
    `불편사항 처리가 완료되었습니다. \n추가 문의 사항이 있으시면 언제든지 연락주세요. 더 나은 서비스를 제공하기 위해 노력하겠습니다. 감사합니다.\n처리 내역: `,
  );

  // 함수명 변경
  const subCategoryListInput = (selected: string) => {
    // 전체 카테고리 리스트
    categoryList.map((item: IComplainList) => {
      if (item.name === selected) {
        setSubCategories(item.sub_category);
      }
    });
  };

  useEffect(() => {
    if (props.selectedMainCategory) {
      if (props.selectedMainCategory.mainCategory) {
        setSelectedMainCategory(props.selectedMainCategory.mainCategory);
      }

      if (props.selectedMainCategory?.subCategory) {
        setSelectedSubCategory(props.selectedMainCategory?.subCategory);
      }
    }
  }, [props.selectedMainCategory]);

  useEffect(() => {
    if (categoryList.length > 0) {
      if (selectedMainCategory) {
        subCategoryListInput(selectedMainCategory);
      }
    }
  }, [categoryList]);

  useQuery([], () =>
    fetchClient.get(complain_category_list).then((res) => {
      setCategoryList(res.data.data.category);
      const tempCategoryList = res.data.data.category.map((item: { name: string }) => {
        return item.name;
      });
      setMainCategories(tempCategoryList);
    }),
  );

  const onCloseDrawer = () => {
    props.onClose && props.onClose();
    return;
  };

  /** 1차 카테고리 */
  const onChangeSelectCategory = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedMainCategory(e.target.value);
    setSelectedSubCategory(""); // 선택한 서브 카테고리 초기화
    subCategoryListInput(e.target.value);
  };

  /** 2차 카테고리 */
  const onChangeSelectSubCateogry = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedSubCategory(e.target.value);
  };

  const onCheckBeforeChageStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "waiting") return;

    setChangeState(e.target.value);
  };

  const mutation = useMutation(
    () =>
      fetchClient
        .post(complaint_progress, {
          complaint_id: complaintId,
          progress_type: changeState,
          complainCategory: {
            mainCategory: selectedMainCategory,
            subCategory: selectedSubCateogry,
          },
        })
        .then((res) => res.data.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([complaint_detail_key, complaintId]);

        const toastMessage = {
          title: "처리 상태 변경",
          description:
            changeState === "completed"
              ? props.type === "building"
                ? "민원 상태가 ‘처리 완료’로 변경되었습니다."
                : "처리상태와 함께 답글을 접수자에게 전달하였습니다."
              : "민원 상태가 ‘처리 중’으로 변경되었습니다.",
        };

        if (categoryList.length > 0) {
          if (changeState === props.data) {
            toastMessage.title = "카테고리 변경";
            toastMessage.description = "관리센터 내부 카테고리가 변경되었습니다.";
          }
        }

        toast({
          ...success,
          title: toastMessage.title,
          description: toastMessage.description,
        });
        props.onClose();
      },
      onError: () => {
        toast({
          ...warning,
          title: "처리 상태 변경",
          description:
            "일시적으로 처리 상태 변경에 실패했습니다. 새로고침 후에도 동일한 오류가 반복될 경우 오피스너 담당자에게 문의해주세요.",
        });
      },
    },
  );

  /** 유효성 검사 | 성공 : true, 실패 : false */
  const saveValidate = () => {
    if (
      changeState === props.data &&
      selectedMainCategory === props.selectedMainCategory?.mainCategory &&
      selectedSubCateogry === props.selectedMainCategory?.subCategory &&
      categoryList.length > 0
    ) {
      return false;
    }

    return true;
  };

  // 저장 버튼 클릭
  const onSaveClick = () => {
    if (mutation.isLoading || !complaintId) return;

    // 커스텀 카테고리 사용 안하는 건물
    if (changeState === props.data && categoryList.length === 0) return props.onClose();
    if (!saveValidate()) {
      return props.onClose();
    }

    if (changeState === "completed" && comment && props.type === "tenant") {
      const obj = {
        complaint_id: complaintId,
        body: comment,
      };

      createComment.mutateAsync(obj).then(() => {
        mutation.mutate();
      });
    } else {
      mutation.mutate();
    }

    props.onClose();
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={onCloseDrawer}
      variant="default"
      closeOnOverlayClick={false} // modaloverlay click해도 모달 안닫히게하는 옵션
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW="488px" maxH="672px" style={{ transform: "translate(-50%, -50%)" }}>
        <ModalBody>
          <ModalHead>
            <ModalTitle>관리센터 카테고리 설정</ModalTitle>
            <span style={{ color: "#2563EB" }}>* 필수</span>
          </ModalHead>
          <div>
            <CategoryContainer>
              {/* <SubTitle>관리팀 내부에 기록할 민원 카테고리를 선택해주세요.</SubTitle> */}
              <div style={{ display: "flex" }}>
                <SubTitle>관리팀 내부에 기록할 민원 카테고리를 선택해주세요.</SubTitle>
              </div>
              <ShadowBox isModal={true} isMore={true} style={{ padding: "0 0 1rem 0" }}>
                <div>
                  <GrayColorSelect
                    w="100%"
                    value={selectedMainCategory}
                    _onChange={onChangeSelectCategory}
                    _style={{ textAlign: "left", backgroundPositionX: "95%" }}
                  >
                    <option value="">1차 카테고리 선택</option>
                    {mainCategories.map((el: string, index: number) => (
                      <option key={String(index)} value={el}>
                        {el}
                      </option>
                    ))}
                  </GrayColorSelect>
                </div>
              </ShadowBox>
              <ShadowBox isModal={true} isMore={true} style={{ padding: "0 0 1rem 0" }}>
                <div>
                  <GrayColorSelect
                    w="100%"
                    value={selectedSubCateogry}
                    _onChange={onChangeSelectSubCateogry}
                    _style={{ textAlign: "left", backgroundPositionX: "95%" }}
                    disabled={selectedMainCategory === ""}
                  >
                    <option value="-">2차 카테고리 선택</option>
                    {subCategories?.map((el, index) => (
                      <option key={String(index)} value={el}>
                        {el}
                      </option>
                    ))}
                  </GrayColorSelect>
                </div>
              </ShadowBox>
            </CategoryContainer>
            {props.modalType ? (
              <div style={{ height: "184px" }}></div>
            ) : (
              <div style={{ minHeight: "214px" }}>
                <div style={{ display: "flex" }}>
                  <SubTitle>처리상태를 선택해주세요.</SubTitle>
                  <span style={{ color: "#2563EB" }}>*</span>
                </div>
                <ShadowBox isModal={true} isMore={true} style={{ padding: "0 0 1rem 0" }}>
                  <div>
                    <GrayColorSelect
                      w="100%"
                      value={changeState}
                      _onChange={onCheckBeforeChageStatus}
                      _style={{ textAlign: "left", backgroundPositionX: "95%" }}
                    >
                      <option disabled value="waiting">
                        처리대기
                      </option>
                      <option value="proceeding">처리중</option>
                      <option value="completed">처리완료</option>
                    </GrayColorSelect>
                  </div>
                </ShadowBox>
                {changeState === "proceeding" ? (
                  <Divsion1>
                    <ComplaintWarnBox>
                      <MdError />
                      <div>‘처리 중’ 상태는 접수자에게 알림이 가지 않습니다.</div>
                    </ComplaintWarnBox>
                  </Divsion1>
                ) : (
                  changeState === "completed" &&
                  tempUser.type === "tenant" && (
                    <div style={{ marginBottom: "18px" }}>
                      <h4 style={{ color: colors.gray4, fontWeight: 700, marginBottom: "12px" }}>
                        처리상태와 함께 접수자에게 전달할 답글을 입력해주세요.
                      </h4>
                      <GrayRadiusTextArea
                        _style={{ minHeight: "10rem", color: "#6B7280", border: "1px solid #D1D5DB" }}
                        value={comment}
                        placeholder="처리상태와 함께 접수자에게 전달할 답글을 입력해주세요."
                        isLimited={true}
                        num={3000}
                        _onChange={(e) => {
                          if (e.target.value.length > 3000) return;
                          setComment(e.target.value);
                        }}
                      />
                    </div>
                  )
                )}
              </div>
            )}
          </div>
          <BottomContainer>
            <Button onClick={() => props.onClose()} w="100%" variant="m_gray" minW="142px">
              취소
            </Button>
            <SubmitButton
              w="100%"
              minW="142px"
              isActiveOption={changeState !== ""}
              isDisabled={changeState === ""}
              onClick={onSaveClick}
            >
              선택하기
            </SubmitButton>
          </BottomContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomCategoryModal;
