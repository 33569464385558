import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchClient } from "../../../lib/api/axios";
import {
  booking_item,
  booking_item_list,
  public_detail,
  public_update,
  schedule_approved,
  update_booking_item,
} from "../../../lib/api/queries/urls";
import {
  booking_item_key,
  booking_item_list_key,
  booking_user_list_key,
  public_detail_key,
} from "../../../lib/api/queries/keys";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { IReservationProduct, IRoomPublic, ISchedule } from "../../../lib/types/Imodels";
import { IUseMutationBookingItem, IUseMutationPublicRoom } from "./types";
import { useParams } from "react-router-dom";
import useUserAuth from "../../../components/hooks/useUserAuth";

export const useFetchPublic = (kor: string | undefined) =>
  useQuery(
    [public_detail_key, kor],
    () =>
      fetchClient
        .post<BaseResponse<IRoomPublic>>(public_detail, {
          room_public_type: kor,
        })
        .then((res) => res.data.data),
    { enabled: !!kor },
  );

export const useFetchItemList = (publicId: string) => {
  const auth = useUserAuth("room_public");

  const fetchItemlist = useQuery(
    [booking_item_list_key],
    () =>
      fetchClient
        .get<BaseResponse<IReservationProduct[]>>(booking_item_list, {
          params: {
            room_public_id: publicId,
          },
        })
        .then((res) => res.data.data),
    { enabled: auth === "read" || auth === "edit" },
  );

  return { fetchItemlist };
};

export const useMutatePublicRoom = () => {
  const queryClient = useQueryClient();
  const { publicType } = useParams();
  // const kor = format_uri(true, public_type || "");
  const kor = decodeURIComponent(publicType || "");

  const updatePublicRoom = useMutation((updateObj: IUseMutationPublicRoom) =>
    fetchClient
      .post(public_update, updateObj)
      .then(() => {
        queryClient.invalidateQueries([public_detail_key, kor]); //연관된 키 있어서 확인 후, 일단 이것만 인벨리테이트
      })
      .catch((err) => console.error(err)),
  );

  return { updatePublicRoom };
};

export const useFetchPublicItem = (item_id: string | undefined) =>
  useQuery(
    [booking_item_key, item_id],
    () =>
      fetchClient
        .get<BaseResponse<IReservationProduct>>(booking_item, {
          params: {
            reservation_product_id: item_id,
          },
        })
        .then((res) => res.data.data),
    { enabled: !!item_id },
  );

export const useMutatePublicItem = (isDetail: boolean) => {
  const queryClient = useQueryClient();

  const updateMutation = useMutation(
    (updateObj: IUseMutationBookingItem) => fetchClient.post(update_booking_item, updateObj),
    {
      onSuccess: () => {
        isDetail
          ? queryClient.invalidateQueries([booking_item_key])
          : queryClient.invalidateQueries([booking_item_list_key]);
      },
      onError: (err) => console.log(err),
    },
  );

  return { updateMutation };
};

type IApprovedLog = {
  _id: string;
  date?: string;
  check_status: string;
  rejected_reason?: string;
};

export const useMutateStatus = () => {
  const queryClient = useQueryClient();

  const onChangedStatus = useMutation((obj: IApprovedLog) =>
    fetchClient
      .post<BaseResponse<ISchedule>>(schedule_approved, {
        schedule_id: obj._id,
        check_status: obj.check_status,
        rejected_reason: obj.rejected_reason,
      })
      .then((res) => {
        queryClient.invalidateQueries([booking_user_list_key]);
        return res.data.data;
      })
      .catch((err) => {
        console.error(err);
      }),
  );

  return { onChangedStatus };
};
