import {
  Flex,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { IRoomPublic, ISchedule } from "../../../../lib/types/Imodels";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { fetchClient } from "../../../../lib/api/axios";
import { useMutation } from "@tanstack/react-query";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { public_reserve } from "../../../../lib/api/queries/urls";
import { queryClient } from "../../../..";
import { public_detail_key, public_list_key } from "../../../../lib/api/queries/keys";
import moment from "moment";
import { success, WarningTime } from "../../../../lib/theme/toast";
import SingleRow from "../../../../components/modal/table/singleRow";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import { AxiosError } from "axios";
import { createSearchParams, useNavigate } from "react-router-dom";
import { colors } from "../../../../components/styled/common";
import { DateWithIconBox, SelectDateBox, DownIcon } from "../../../../components/styled";

interface IModal {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
  data: IRoomPublic | undefined;
}

export const TableContent = styled(ModalContent)`
  width: 584px;
  max-width: fit-content;
  margin-top: 7.75rem;
`;

const LimitInfo = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid ${colors.gray2};
  span {
    font-weight: 700;
  }
`;

export default function LimitModal(props: IModal) {
  const toast = useToast();
  const navigate = useNavigate();
  const [end, setEnd] = useState<Date | null>(null);
  const [start, setStart] = useState<Date | null>(null);
  const { isOpen, onClose, onOpen } = useDisclosure(); //예약 제한 안내 모달

  const mutation = useMutation(
    () =>
      fetchClient.post<BaseResponse<ISchedule>>(public_reserve, {
        room_public_id: props.data?._id,
        start_date: `${moment(start).format("YYYY-MM-DDTHH:mm")}:00Z`,
        // start_date: "2023-02-23T15:15:00Z",
        end_date: end
          ? `${moment(end).format("YYYY-MM-DDTHH:mm")}:00Z`
          : `${moment(new Date(new Date().setFullYear(new Date().getFullYear() + 100))).format(
              "YYYY-MM-DDTHH:mm",
            )}:00Z`,
        // end_date: "2023-02-23T15:20:00Z",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([public_detail_key, props.data?._id]);
        queryClient.invalidateQueries([public_list_key, moment(start).format("YYYY-MM-DD")]);
        toast({
          ...success,
          title: "공용시설 예약 제한",
          description: "공용시설 예약 제한이 완료되었습니다.",
        });
        setStart(null);
        setEnd(null);
        props.onClose();
      },
      onError: (err: AxiosError) => {
        err.response?.status === 400 && onOpen();
      },
    },
  );

  const onSetStartDate = (date: Date | null) => {
    const prev = moment.utc(end);
    const seleted = moment.utc(date || new Date());

    if (end !== null) {
      if (seleted.isAfter(prev) || prev.format("YYYY-MM-DD HH:mm") === seleted.format("YYYY-MM-DD HH:mm"))
        return toast({
          ...WarningTime,
          description: "시작시간은 종료시간보다 이후거나 같을 수 없습니다.",
        });
    }

    setStart(date);
  };

  const onSetEndDate = (date: Date | null) => {
    const prev = moment.utc(start);
    const seleted = moment.utc(date || new Date());

    if (start !== null) {
      if (seleted.isBefore(prev) || prev.format("YYYY-MM-DD HH:mm") === seleted.format("YYYY-MM-DD HH:mm"))
        return toast({
          ...WarningTime,
          description: "종료시간은 시작시간보다 이전이거나 같을 수 없습니다.",
        });
    }

    setEnd(date);
  };

  const onMoveToList = () => {
    navigate({
      pathname: "/meetingRoom/reservation",
      search: `?${createSearchParams({
        page: String(1),
        status: "pending",
        search: props.data?.name || "",
        start_date: moment(start).format("YYYY-MM-DD"),
        end_date: moment(end).format("YYYY-MM-DD"),
      })}`,
    });
  };

  const onSetLimit = () => {
    start !== null && setStart(null);
    end !== null && setEnd(null);
    if (!props.data?.admin_end_date) props.setToggle(false);
    props.onClose();
  };

  return (
    <Modal variant="contents" isOpen={props.isOpen} onClose={onSetLimit} blockScrollOnMount={false}>
      <ModalOverlay />
      <TableContent>
        <ModalHeader>예약 제한</ModalHeader>
        <ModalBody>
          <LimitInfo>
            예약 제한 기간 내에는 입주자의 예약이 불가능하며,{" "}
            <span>
              제한 전 이미 확정된 예약건은 이용 제한할 수 없으므로 먼저 확정된 예약건을 반려하신 후에 설정이 가능합니다.
            </span>
          </LimitInfo>
          <SingleRow title="제한 시설">
            {props.data?.dong}동 {props.data?.floor}층{" / "}
            {props.data?.room_public_type} / {props.data?.name}
          </SingleRow>
          <SingleRow title="제한 기간">
            <Flex p="8px 0">
              <DateWithIconBox>
                <DatePicker
                  id="start"
                  locale={ko}
                  dateFormat="yyyy-MM-dd HH:mm"
                  minDate={new Date()}
                  minTime={
                    new Date().getDate() === new Date(start ?? new Date().getTime()).getDate()
                      ? new Date()
                      : new Date(new Date().setHours(0, 0, 0))
                  }
                  maxTime={new Date(new Date().setHours(23, 59, 59))}
                  selected={start}
                  showTimeSelect
                  onChange={(e) => onSetStartDate(e)}
                  customInput={
                    <SelectDateBox>
                      {start ? moment(start).format("YYYY-MM-DD HH:mm") : "시작일시"}
                      <DownIcon style={{ right: "10%" }} />
                    </SelectDateBox>
                  }
                />
              </DateWithIconBox>
              <DateWithIconBox>
                <DatePicker
                  id="end"
                  locale={ko}
                  dateFormat="yyyy-MM-dd HH:mm"
                  minDate={start || new Date()}
                  selected={end}
                  minTime={
                    new Date().getDate() === new Date(end ?? new Date().getTime()).getDate()
                      ? new Date(start ?? new Date())
                      : new Date(new Date().setHours(0, 0, 0))
                  }
                  maxTime={new Date(new Date().setHours(23, 59, 59))}
                  showTimeSelect
                  onChange={(e) => onSetEndDate(e)}
                  customInput={
                    <SelectDateBox>
                      {end ? moment(end).format("YYYY-MM-DD HH:mm") : "종료일시"}
                      <DownIcon style={{ right: "10%" }} />
                    </SelectDateBox>
                  }
                />
              </DateWithIconBox>
            </Flex>
          </SingleRow>
        </ModalBody>
        <ModalFooter>
          <Button variant="cancel" onClick={onSetLimit}>
            취소
          </Button>
          <Button
            variant="bgBlue"
            isDisabled={start !== null ? false : true}
            // _disabled={{
            //   border: "1px solid #D1D5DB",
            //   backgroundColor: "#D1D5DB",
            //   cursor: "auto",
            // }}
            onClick={() => {
              if (mutation.isLoading) return;
              mutation.mutate();
            }}
          >
            예약 제한하기
          </Button>
          <ConfirmModal
            title="예약 제한 기간 내 예약건"
            isOpen={isOpen}
            onClose={onClose}
            close="예약건 확인하기"
            onClickCancle={onClose}
            blockScrollOnMount={false}
            onClickSave={onMoveToList}
            styleConfig={{ marginTop: "0", borderTop: "none" }}
          >
            <LimitInfo>
              선택하신 제한 기간 중 이미 예약된 내역이 존재합니다.
              <br />
              <span>해당 예약건에 대해서는 자동취소가 불가하니, </span>
              예약내역에서 예약 반려 후 다시 시도해주세요.
            </LimitInfo>
          </ConfirmModal>
        </ModalFooter>
      </TableContent>
    </Modal>
  );
}
