import { KeyboardEvent } from "react";

export const onPreventInput = (
  e: KeyboardEvent<HTMLInputElement>,
  number?: number
) => {
  if (e.currentTarget.value.length > (number || 19)) {
    if (e.code.includes("Digit") || e.code.includes("Key"))
      return e.preventDefault();
  }

  if (e.key === "ArrowDown" || e.key === "ArrowUp") e.preventDefault();
};

export const onPreventEnter = (e: KeyboardEvent<HTMLFormElement>) => {
  e.key === "Enter" && e.preventDefault();
};
