import { ReactElement } from "react";
import { IoAlertCircleOutline } from "@react-icons/all-files/io5/IoAlertCircleOutline";
import { Outlet } from "react-router";
import { MediaInfo, MediaInfoBox } from "../../components/styled/info";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function MobileRoute(): ReactElement | null {
  const navigate = useNavigate();

  //path가 complaints인 경우
  if (!window.location.pathname.includes("complaints")) {
    return (
      <>
        <MediaInfoBox className="md:hidden">
          <MediaInfo>
            <IoAlertCircleOutline color="#2563eb" />
            <p>해당 페이지는 모바일 버전을 제공하지 않습니다.</p>
            <p>PC에서 접속해주세요.</p>
            <div>
              <Button variant="m_blue" mt="2rem" onClick={() => navigate(-1)}>
                민원 관리 페이지로 돌아가기
              </Button>
            </div>
          </MediaInfo>
        </MediaInfoBox>
        <Outlet />
      </>
    );
  }
  return <Outlet />;
}
