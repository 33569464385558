import styled from "@emotion/styled";
import { Box, Text, Flex } from "@chakra-ui/react";
import { colors } from "../../../../components/styled/common";

export const ShowBox = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  background-color: ${colors.whiteColor};
  border: 1px solid ${colors.gray5};
`;

export const TimelineBox = styled(Box)`
  width: 100%;
  .rct-scroll {
    width: 100%;
  }
  .react-calendar-timeline {
    border: 1px solid ${colors.gray5};
  }
  .react-calendar-timeline .rct-scroll {
    overflow-x: hidden !important;
  }
  .react-calendar-timeline .rct-dateHeader {
    border-bottom: none;
    border-left: none;
    background-color: ${colors.whiteColor};
  }
  .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    padding: 0;
  }
  .react-calendar-timeline .rct-calendar-header {
    border: none;
  }
  .react-calendar-timeline .rct-sidebar {
    border-right: none;
  }
  .react-calendar-timeline .rct-header-root {
    border-bottom: 1px solid ${colors.gray5};
  }
  .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd,
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background-color: transparent;
  }
  .rct-item {
    overflow: hidden;
  }
`;

export const TextStyle = styled(Flex)`
  align-items: center;
  padding-right: 4px;
  span {
    font-size: 12px;
    padding: 0px 4px;
  }
`;

export const TimelineRooms = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: ${colors.whiteColor};
  > div {
    border-right: 1px solid ${colors.gray5};
    text-align: center;
  }
`;

export const TimelineHeader = styled(TimelineRooms)`
  > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const AutoBox = styled.div`
  padding: 8px;
  background-color: ${colors.gray1};
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;

export const AutoText = styled(Text)`
  color: ${colors.gray4};
  font-size: 15px;
  font-weight: 500;
  padding: 0 4px 0 8px;
`;

export const TextGuide = styled.textarea`
  min-height: 200px;
  resize: none;
  width: 100%;
  padding: 12px 0;
`;

//예약 정보 보는 팝업
export const BoldText = styled(Box)`
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;

export const WithBox = styled.div`
  width: 70%;
  display: flex;
  padding: 8px 0;
`;

export const WithFlex = styled(WithBox)`
  flex-direction: column;
  padding: 0;
`;

export const StatusInfo = styled(WithFlex)<{ r: Date | undefined }>`
  padding: 16px 0;
  ${({ r }) => r && `color:#FB4667`};
`;

export const ApprovedBox = styled.div`
  padding-top: 16px;
  color: ${colors.gray4};
`;

export const SelectStatus = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 12px;
  }
`;
