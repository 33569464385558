import { useDisclosure } from "@chakra-ui/react";
import { IDeparts } from "../../../../lib/types/Imodels";
import { GrayInfoBox } from "../../../../components/styled/webApp";
import DepartmentSetModal from "./department";

interface IDepartmentItem {
  el: IDeparts;
}

export default function DepartmentItem(props: IDepartmentItem) {
  const { isOpen, onOpen, onClose } = useDisclosure(); //권한 등록 모달

  return (
    <GrayInfoBox onClick={onOpen}>
      <div>{props.el.name}</div>
      <div className="edit">수정</div>
      {isOpen && (
        <DepartmentSetModal
          isOpen={isOpen}
          onClose={onClose}
          isEdit={true}
          el={props.el}
        />
      )}
    </GrayInfoBox>
  );
}
