import { Button } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import { MainContainer } from "../../../components/styled/webApp";

interface IInfo {
  desc: string;
  btn: string;
}

const NewWrapper = styled(MainContainer)`
  display: flex;
  height: 60vh;
  justify-content: center;
  align-items: center;
`;

const BoldText = styled.span<{ b: boolean }>`
  ${({ b }) => b && `font-weight: 500; text-decoration:underline;`};
`;

export default function CreateInfo({ desc, btn }: IInfo) {
  const navigate = useNavigate();
  const { publicId, publicType } = useParams();

  return (
    <NewWrapper>
      <div style={{ textAlign: "center" }}>
        <div>예약 서비스를 {desc} 먼저 등록해주세요.</div>
        <div style={{ color: "#9ca3af" }}>
          예약 서비스 절차 : <BoldText b={btn === "시설"}>신규 시설 등록</BoldText>
          {" > "}
          <BoldText b={btn !== "시설"}>예약상품 만들기</BoldText>
          {" > "}예약관리
        </div>
        <Button
          mt="2rem"
          w="20rem"
          variant="m_blue"
          fontSize="1.25rem"
          onClick={() =>
            // navigate(`/time-type/${publicType}/${btn === "시설" ? "create" : `booking/${publicId}/create`}`)
            navigate(`/facility/${publicType}/${btn === "시설" ? "create" : `booking/${publicId}/create`}`)
          }
        >
          신규 {btn} 등록하기
        </Button>
      </div>
    </NewWrapper>
  );
}
