import { Button, Flex, Select, Text, useDisclosure } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../../App";
import ContentInputs from "../../../components/Input/middleContents";
import * as C from "../../../components/styled/index";
import * as R from "../commons/styled";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import CancelModal from "../../../components/modal/CancelModal";
import UploadCSVModal from "./UploadModal";
import MaintenanceBox from "../maintenance/detail/details";
import { IBillView } from "../commons/types";
import PaginationUI from "../../../components/pagination";
import RentalDetailItem from "../rent/detail/rentDetailItem";

interface IMaintenanceView extends IBillView {
  total?: number;
  page?: number;
  currentPage?: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export default function BillLetterView({ data, ...props }: IMaintenanceView) {
  const navigate = useNavigate();
  const location = useLocation();
  const { buildInfo } = useContext(GlobalContext);
  const { rentalId, billId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure(); //납부 고지서 삭제
  const { isOpen: isFile, onOpen: onFile, onClose: offFile } = useDisclosure(); //납부 고지서 파일 수정
  const maintenance = location.pathname.includes("maintenance-fee"); // 관리비인지 아닌지

  return (
    <>
      <div className="flex min-h-full w-full flex-col px-6 py-10">
        <Text variant="static">
          <C.LeftIcons onClick={() => navigate(maintenance ? "/maintenance-fee" : `/rent-fee`)} />
          {maintenance ? "관리비" : "임대료"} 납부고지서
        </Text>
        <C.DetailViewHeader>
          <C.DetailTitleBox>
            <Text>{moment(data?.date).format("YY년 MM월")} 납부고지서</Text>
          </C.DetailTitleBox>
        </C.DetailViewHeader>
        <div className="flex px-4 py-5 text-gray-400">
          <p>
            최종 편집자
            <C.InfoBoxText> {data?.user_name}</C.InfoBoxText>
          </p>
          <p>
            고지상태
            <C.InfoBoxText>{data?.is_open ? " 고지완료" : " 업로드완료"}</C.InfoBoxText>
          </p>
          <p>
            최근 업데이트
            <span className="ml-4 text-gray-500">
              {data?.updated_date ? moment.utc(data.updated_date).format(" YYYY-MM-DD HH:mm") : ""}
            </span>
          </p>
        </div>
        <div className="flex flex-col items-start py-4 font-medium text-gray-400">
          <div className="flex items-end">
            {props.isDirect && (
              <C.SelectBox>
                <Text variant="default">납부상태</Text>
                <Select value={props.status} variant="default" onChange={(e) => props.setStatus(e.target.value)}>
                  <option value="">전체</option>
                  <option value="checking">확인 중</option>
                  <option value="completed">납부 완료</option>
                </Select>
              </C.SelectBox>
            )}
            <C.SelectBox>
              <Text variant="default">동</Text>
              <Select value={props.dong} variant="default" onChange={(e) => props.setDong(e.target.value)}>
                <option value="">전체</option>
                {buildInfo?.dong_floor.map((el) => (
                  <option key={uuidv4()} value={el.dong}>
                    {el.dong}동
                  </option>
                ))}
              </Select>
            </C.SelectBox>
            <C.SelectBox>
              <Text variant="default">검색</Text>
              <Select
                variant="default"
                value={props.inputs?.id || ""}
                onChange={(e) =>
                  props.setInputs &&
                  props.setInputs({
                    ...props.inputs,
                    id: e.target.value,
                    value: "",
                  })
                }
              >
                {buildInfo?.management_fee_upload_type === "direct" && <option value="search_tenant">입주사명</option>}
                <option value="search_ho">호실번호</option>
              </Select>
            </C.SelectBox>
            <C.SelectBox style={{ width: "fit-content" }}>
              <ContentInputs
                w="320px"
                onChange={(e) =>
                  props.setInputs &&
                  props.setInputs({
                    ...props.inputs,
                    id: props.inputs?.id || "search_tenant",
                    value: e.target.value,
                  })
                }
                value={props.inputs?.value || ""}
                placeholder={`${
                  props.inputs?.id === "search_tenant"
                    ? "검색하실 입주사명을 입력해주세요"
                    : "검색하실 호실번호를 입력해주세요"
                }`}
                onKeyDown={(e) => e.key === "Enter" && props.onRefetch()}
              />
              <Button variant="basic" onClick={props.onRefetch}>
                검색
              </Button>
            </C.SelectBox>
          </div>
        </div>
        <C.TableTopBox>
          <span>
            총 <C.TableCount>{rentalId ? data?.rent_fee_details?.length || 0 : props.total}</C.TableCount>건
          </span>
        </C.TableTopBox>
        {maintenance ? (
          <>
            <MaintenanceBox data={data} isDirect={props.isDirect} />
            <PaginationUI page={props.currentPage || 1} allPage={props.page || 1} setPage={props.setCurrentPage} />
          </>
        ) : (
          <div style={{ display: "contents" }}>
            <R.TableHeader>
              <R.TableBox w={(data?.rent_fee_details?.length || 0) > 999}>
                <R.TrangleGraySquare>
                  <div />
                </R.TrangleGraySquare>
              </R.TableBox>
              <R.HeaderSquare w="17%">입주사명</R.HeaderSquare>
              <R.HeaderSquare w="25%">호실</R.HeaderSquare>
              <R.SkySquare w="20%">임대료</R.SkySquare>
              <R.SkySquare w="15%">납부상태</R.SkySquare>
              <div style={{ width: "20%", background: "#fff" }} />
            </R.TableHeader>
            {data?.rent_fee_details?.map((el, idx) => (
              <RentalDetailItem key={uuidv4()} el={el} idx={idx} length={data?.rent_fee_details?.length || 0} />
            ))}
          </div>
        )}
      </div>
      <CancelModal
        isOpen={isOpen}
        onClose={onClose}
        onClickCancel={onClose}
        onClickSave={() => {
          props.onDeleteBill();
          onClose();
        }}
      >
        <Text>납부고지서를 삭제할까요?</Text>
        <Text>삭제된 데이터는 복구할 수 없습니다.</Text>
      </CancelModal>
      {props.auth === "edit" && (
        <R.ActionBox>
          <Button variant="delete" m="0" onClick={onOpen}>
            삭제
          </Button>
          <UploadCSVModal isEdit={true} isOpen={isFile} onClose={offFile} data={data} />
          <Button
            m="0 12px"
            variant="blueLine"
            onClick={() => {
              if (maintenance) {
                //관리비인지 임대료인지
                if (!props.isDirect) return onFile(); //직접 올리는지 파일인지
                navigate(`/maintenance-fee/${billId}/edit`);
              } else {
                navigate(`/rent-fee/${rentalId}/edit`);
              }
            }}
          >
            수정
          </Button>
        </R.ActionBox>
      )}
    </>
  );
}
