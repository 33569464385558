import { HTMLAttributes } from "react";

export type Props = object & HTMLAttributes<HTMLOrSVGElement>;

export default function IconCake(props: Props) {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6666 15.167H1.33325C1.05992 15.167 0.833252 14.9403 0.833252 14.667C0.833252 14.3937 1.05992 14.167 1.33325 14.167H14.6666C14.9399 14.167 15.1666 14.3937 15.1666 14.667C15.1666 14.9403 14.9399 15.167 14.6666 15.167Z"
        fill="#374151"
      />
      <path
        d="M13.9266 15.167C13.6532 15.167 13.4266 14.9403 13.4266 14.667V8.66699C13.4266 7.84033 12.6532 7.16699 11.7066 7.16699H4.29324C3.34658 7.16699 2.57324 7.84033 2.57324 8.66699V14.667C2.57324 14.9403 2.34658 15.167 2.07324 15.167C1.79991 15.167 1.57324 14.9403 1.57324 14.667V8.66699C1.57324 7.28699 2.79324 6.16699 4.29324 6.16699H11.6999C13.1999 6.16699 14.4199 7.28699 14.4199 8.66699V14.667C14.4266 14.9403 14.1999 15.167 13.9266 15.167Z"
        fill="#374151"
      />
      <path
        d="M12.2935 7.16634C12.0201 7.16634 11.7935 6.93967 11.7935 6.66634V4.77967C11.7935 4.25967 11.2935 3.83301 10.6868 3.83301H5.32012C4.70679 3.83301 4.21345 4.25967 4.21345 4.77967V6.66634C4.21345 6.93967 3.98679 7.16634 3.71345 7.16634C3.44012 7.16634 3.20679 6.93967 3.20679 6.66634V4.77967C3.20679 3.70634 4.15345 2.83301 5.31345 2.83301H10.6801C11.8401 2.83301 12.7868 3.70634 12.7868 4.77967V6.66634C12.7935 6.93967 12.5668 7.16634 12.2935 7.16634Z"
        fill="#374151"
      />
      <path
        d="M11.5533 11.8335C10.78 11.8335 10.1533 11.2068 10.1533 10.4335V10.2268C10.1533 10.0068 9.9733 9.82681 9.7533 9.82681C9.5333 9.82681 9.3533 10.0068 9.3533 10.2268V10.4335C9.3533 11.2068 8.72663 11.8335 7.9533 11.8335C7.17997 11.8335 6.5533 11.2068 6.5533 10.4335V10.2268C6.5533 10.0068 6.3733 9.82681 6.1533 9.82681C5.9333 9.82681 5.7533 10.0068 5.7533 10.2268V10.4335C5.7533 11.2068 5.12663 11.8335 4.3533 11.8335C3.57997 11.8335 2.9533 11.2068 2.9533 10.4335V10.2135C2.9533 10.0001 2.77997 9.82014 2.55997 9.81348H2.3133C2.03997 9.80681 1.8133 9.58014 1.81997 9.30681C1.82663 9.03348 2.04663 8.81348 2.31997 8.81348H2.32663H2.5733C3.3333 8.82681 3.9533 9.45348 3.9533 10.2135V10.4335C3.9533 10.6535 4.1333 10.8335 4.3533 10.8335C4.5733 10.8335 4.7533 10.6535 4.7533 10.4335V10.2268C4.7533 9.45348 5.37997 8.82681 6.1533 8.82681C6.92663 8.82681 7.5533 9.45348 7.5533 10.2268V10.4335C7.5533 10.6535 7.7333 10.8335 7.9533 10.8335C8.1733 10.8335 8.3533 10.6535 8.3533 10.4335V10.2268C8.3533 9.45348 8.97997 8.82681 9.7533 8.82681C10.5266 8.82681 11.1533 9.45348 11.1533 10.2268V10.4335C11.1533 10.6535 11.3333 10.8335 11.5533 10.8335C11.7733 10.8335 11.9533 10.6535 11.9533 10.4335V10.2268C11.9533 9.45348 12.58 8.82681 13.3533 8.82681H13.6533C13.9266 8.82681 14.1533 9.05348 14.1533 9.32681C14.1533 9.60014 13.9266 9.82681 13.6533 9.82681H13.3533C13.1333 9.82681 12.9533 10.0068 12.9533 10.2268V10.4335C12.9533 11.2068 12.3266 11.8335 11.5533 11.8335Z"
        fill="#374151"
      />
      <path
        d="M5.33325 3.83333C5.05992 3.83333 4.83325 3.60667 4.83325 3.33333V2C4.83325 1.72667 5.05992 1.5 5.33325 1.5C5.60659 1.5 5.83325 1.72667 5.83325 2V3.33333C5.83325 3.60667 5.60659 3.83333 5.33325 3.83333Z"
        fill="#374151"
      />
      <path
        d="M10.6667 3.83333C10.3934 3.83333 10.1667 3.60667 10.1667 3.33333V2C10.1667 1.72667 10.3934 1.5 10.6667 1.5C10.9401 1.5 11.1667 1.72667 11.1667 2V3.33333C11.1667 3.60667 10.9401 3.83333 10.6667 3.83333Z"
        fill="#374151"
      />
      <path
        d="M8 3.83301C7.72667 3.83301 7.5 3.60634 7.5 3.33301V1.33301C7.5 1.05967 7.72667 0.833008 8 0.833008C8.27333 0.833008 8.5 1.05967 8.5 1.33301V3.33301C8.5 3.60634 8.27333 3.83301 8 3.83301Z"
        fill="#374151"
      />
    </svg>
  );
}
