import { DrawerBody, Flex, useDisclosure } from "@chakra-ui/react";
import styled from "@emotion/styled";
import moment from "moment";
import CommonDrawerModal from "../../../../components/Drawer";
import { colors, m_color } from "../../../../components/styled/common";
import { CommonTableWrap, DetailsInfo } from "../../../../components/styled/webApp";
import { ITemperatureHistory } from "../../../../lib/types/Imodels";

const DescripDetail = styled(DetailsInfo)<{ isCompleted: boolean }>`
  flex-direction: column;
  .progress {
    color: ${({ isCompleted }) => (isCompleted ? colors.mainBlue : m_color.danger4)};
    font-weight: 700;
  }
  span {
    color: ${({ isCompleted }) => (isCompleted ? colors.gray4 : colors.gray3)};
  }
`;

function TemperatureHistoryItem({ el }: { el: ITemperatureHistory }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <CommonTableWrap key={String(el._id)} onClick={onOpen}>
      <div>{el.request_type === "hot" ? "더워요" : "추워요"}</div>
      <div style={{ width: "20%" }}>
        {el.dong}동 {el.floor}층 {el.location}
      </div>
      <div>{el.user_name}</div>
      <div>{moment.utc(el.createdAt).format("YYYY-MM-DD HH:mm")}</div>
      <div style={{ width: "35%" }}>{el.progress_type === "completed" ? "처리완료" : "미처리"}</div>
      {isOpen && (
        <CommonDrawerModal title="접수내용" isOpen={isOpen} onClose={onClose}>
          <DrawerBody>
            <DescripDetail isCompleted={el.progress_type === "completed"}>
              <Flex justifyContent="space-between">
                <div className="progress">{el.progress_type === "completed" ? "처리완료" : "미처리"}</div>
                {el.progress_date && <div>{moment.utc(el.progress_date).format("YYYY-MM-DD HH:mm")}</div>}
              </Flex>
              <span
                style={{
                  paddingTop:
                    el.answer || moment().format("L") === moment.utc(el.createdAt).format("L") ? "0.75rem" : 0,
                }}
              >
                {el.answer
                  ? el.answer
                  : moment().format("L") === moment.utc(el.createdAt).format("L")
                    ? "[실시간현황]에서 온도 업데이트를 진행해주세요."
                    : ""}
              </span>
            </DescripDetail>
            <DetailsInfo>
              <h6>접수일시</h6>
              <div>{moment.utc(el.createdAt).format("YYYY-MM-DD HH:mm")}</div>
            </DetailsInfo>
            <DetailsInfo>
              <h6>요청</h6>
              <div>{el.request_type === "hot" ? "더워요" : "추워요"}</div>
            </DetailsInfo>
            <DetailsInfo>
              <h6>위치</h6>
              <div>
                {el.dong}동 {el.floor}층 / {el.location}
              </div>
            </DetailsInfo>
            <DetailsInfo>
              <h6>접수자</h6>
              <div>{el.user_name}</div>
            </DetailsInfo>
            <DetailsInfo>
              <h6>연락처</h6>
              <div>{el.user_phone_number}</div>
            </DetailsInfo>
            <DetailsInfo>
              <h6>소속</h6>
              <div>
                {el.companyInfos && el.companyInfos.length > 0
                  ? `${el.companyInfos[0].name}${el.companyInfos.length > 1 ? ` 외 ${el.companyInfos.length - 1}` : ""}(${el.companyInfos[0].room_ids?.[0].dong}동 ${el.companyInfos[0].room_ids?.[0].ho}호)`
                  : "미등록"}
              </div>
            </DetailsInfo>
          </DrawerBody>
        </CommonDrawerModal>
      )}
    </CommonTableWrap>
  );
}

export default TemperatureHistoryItem;
