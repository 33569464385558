import { Box, Flex, Text } from "@chakra-ui/react";
import { PropsWithChildren, MouseEventHandler } from "react";
import useMeansure, { IAnalytics } from "../hooks/useMeansure";
import useUserAuth from "../hooks/useUserAuth";
import { WrapperHeader } from "../styled";
import { IoMdSettings } from "@react-icons/all-files/io/IoMdSettings";
import styled from "@emotion/styled";
import useAccessBank from "../../pages/maintenance-fee/bank/access";

interface IHeaderType extends PropsWithChildren {
  title: string; //타이틀
  isIcon: boolean; //타이틀 옆 아이콘
  path?: string; //이동할 주소
  obj?: IAnalytics;
  authKey?: string;
  authKeys?: string[]; //둘 중 하나 무조건 있어야함
  isFixed?: JSX.Element;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const SetIcon = styled.div`
  margin-left: 8px;
  border-radius: 4px;
  background-color: #f4f4f5;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6b7280;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
`;

//반응형 작업되면 안쓸 것 같음
const MainHeaderPart: React.FC<IHeaderType> = ({ children, ...props }) => {
  // if(props.authKey)
  const auth = props.authKey ? useUserAuth(props.authKey) : useAccessBank(props.authKeys || []);
  const { onClickAnalytics } = useMeansure();

  return (
    <WrapperHeader>
      <Flex alignItems="center">
        <Text variant="bolder">{props.title}</Text>
        {props.isIcon && (
          <SetIcon onClick={props.onClick}>
            <IoMdSettings
              onClick={onClickAnalytics(
                props.obj || {
                  category: "",
                  action: "",
                },
                props.path || "/dashboard",
              )}
            />
          </SetIcon>
        )}
      </Flex>
      <Box pt="20px">
        {auth !== "edit" ? "해당 기능은 열람만 가능합니다. 수정 권한 필요 시 최고관리자에게 문의해주세요. " : children}
        <Box>{props.isFixed && props.isFixed}</Box>
      </Box>
    </WrapperHeader>
  );
};

export default MainHeaderPart;
