import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../components/styled/common";
import { MainContainer, ShadowBox, UpdateIconBox, MainActionBox } from "../../../components/styled/webApp";
import { UpdateInfoUL } from "../commons/styled";
import useUserAuth from "../../../components/hooks/useUserAuth";

const ButtonSection = styled.div`
  border-top: 1px solid ${colors.gray2};
  padding-top: 1.75rem;
`;

const MoveToBox = styled(UpdateIconBox)`
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 500;
`;

export default function PointSettingPage() {
  const navigate = useNavigate();

  const auth = useUserAuth("room_public");
  const isEdit = auth === "edit";

  return (
    <MainContainer>
      <MainActionBox>
        <ShadowBox>
          <div>
            <h4 style={{ paddingBottom: "0" }}>매월 1일에 자동지급되는 포인트를 설정해주세요.</h4>
            <UpdateInfoUL>
              <li>사용기한 : 매월 1일부터 말일까지 사용</li>
              <li>매월 1일 자정 12시 자동소멸됩니다.</li>
            </UpdateInfoUL>
          </div>
          {isEdit ? (
            <ButtonSection>
              <MoveToBox onClick={() => navigate("/points/setting/manage")}>
                설정하러가기
                <FiChevronRight />
              </MoveToBox>
            </ButtonSection>
          ) : (
            <div className=" mt-5 flex w-full flex-col items-center gap-0.5 text-lg">
              <div className="flex text-base font-semibold text-gray-600">해당 기능은 열람만 가능합니다.</div>
              <div className="flex text-base font-semibold text-gray-600">
                수정 권한 필요 시 최고관리자에게 문의해주세요.
              </div>
            </div>
          )}
        </ShadowBox>
      </MainActionBox>
    </MainContainer>
  );
}
