import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSignInMutation } from "../../requests/sign-in";
import { Spinner, Checkbox } from "@chakra-ui/react";
import { FiInfo } from "@react-icons/all-files/fi/FiInfo";

const schema = yup
  .object()
  .shape({
    id: yup.string().matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, "아이디는 이메일 형식으로 정확히 입력해주세요."),
    pw: yup
      .string()
      .min(8, "비밀번호는 최소 8자 이상입니다.")
      .max(16, "비밀번호는 최대 16자 이하입니다.")
      .matches(/[0-9]/, "0-9사이 숫자가 1개 이상 필요합니다.")
      .matches(/[a-z]/, "영문 소문자가 1자 이상 필요합니다.")
      .matches(/[^\w]/, "특수문자가 1자 이상 필요합니다."),
  })
  .required();

export interface ILoginValues {
  id: string;
  pw: string;
  isChecked: boolean;
}

export default function MainPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const email = localStorage.getItem("user");
  const mutation = useSignInMutation();

  return (
    <div className="flex h-screen w-full bg-primary">
      <div className="relative flex w-[40%] flex-col justify-between overflow-y-hidden px-4 pb-4 pt-[120px] font-bold text-white max-md:hidden">
        <div className="pl-8">
          <h1 className="text-5xl font-bold leading-relaxed">오피스너</h1>
          <p className="flex gap-1 text-base">
            <span>서비스 문의</span>
            <span className="font-medium text-gray-200">service@officener.com</span>
          </p>
        </div>
        <div className="z-10 w-full bg-[#2563ebbd] p-6 text-sm font-medium leading-normal">
          <p className="font-bold">(주)두꺼비세상</p>
          <p>사업자등록번호 204-86-40665 | 대표 유광연</p>
          <p>주소 서울특별시 구로구 디지털로 300 지밸리비즈플라자 15층</p>
        </div>
        <img src="/webBg.png" alt="배경 이미지" className="absolute bottom-0 left-0 max-h-screen w-full" />
      </div>
      <div className="flex w-[60%] flex-col items-center justify-center bg-white text-gray-400 max-md:w-full max-md:px-4">
        <div className="w-[428px] max-md:w-full">
          <div className="w-full border-b border-b-gray-200 pb-4">
            <h3 className="text-4xl font-bold leading-normal text-black">로그인</h3>
            <p>관리센터 통합 어드민입니다.</p>
          </div>
          <div className="py-6">
            <form onSubmit={handleSubmit((data) => mutation.mutate(data))}>
              <p className="pb-3 font-bold text-gray-500">아이디</p>
              <div className="mb-6 text-base font-medium text-gray-600">
                <input
                  className="mb-1 w-full rounded border border-gray-400 px-6 py-4 transition-all placeholder:text-gray-300 focus:border-blue-600"
                  autoComplete="off"
                  defaultValue={email || ""}
                  placeholder="이메일 형식으로 입력해주세요."
                  {...register("id")}
                />
                {errors.id && (
                  <p className="flex items-center gap-2 text-sm text-red-400">
                    <FiInfo />
                    <span>{errors.id.message}</span>
                  </p>
                )}
              </div>
              <p className="pb-3 font-bold text-gray-500">비밀번호</p>
              <div className="mb-6 text-base font-medium text-gray-600">
                <input
                  className="mb-1 w-full rounded border border-gray-400 px-6 py-4 transition-all placeholder:text-gray-300 focus:border-blue-600"
                  autoComplete="off"
                  type="password"
                  placeholder="비밀번호를 입력해주세요."
                  {...register("pw")}
                />
                {errors.pw && (
                  <p className="flex items-center gap-2 text-sm text-red-400">
                    <FiInfo />
                    <span>{errors.pw.message}</span>
                  </p>
                )}
              </div>
              <button
                disabled={mutation.isLoading}
                className="h-[64px] w-full bg-primary text-center font-bold text-white transition-all hover:opacity-80 disabled:opacity-80"
              >
                {mutation.isLoading ? <Spinner /> : "로그인"}
              </button>
              <div className="flex items-center gap-2 pt-2">
                <Checkbox variant="default" {...register("isChecked")}>
                  아이디 기억
                </Checkbox>
              </div>
            </form>
          </div>
          <div className="w-full bg-gray-50 p-4 text-sm">
            <p>해당 사이트는 지정된 사용자만 접속할 수 있습니다.</p>
            <p>아이디, 비밀번호를 분실한 경우 최고관리자에게 문의해주세요.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
