import { useQuery } from "@tanstack/react-query";
import { IBuilding, IRoomPublicList } from "../../../types/Imodels";
import { fetchClient } from "../../axios";
import {
  building_floor_list_key,
  building_key,
  registered_list_key,
} from "../keys";
import {
  building,
  building_floor_list,
  registered_facility_list,
  upload_images,
} from "../urls";
import { BaseResponse } from "./types";

//건물 상세 조회 -> 일단은 온보딩에서만 사용됨
export const useBuilding = () => {
  return useQuery(
    ["only_onboard", building_key],
    () =>
      fetchClient
        .post<BaseResponse<IBuilding>>(building)
        .then((res) => res.data.data),
    { staleTime: 1800000 }
  );
};

export const useBuildingFloor = () => {
  // 계정 통합본 쓰면 건물별로 키값 다르게 해야함
  return useQuery(
    [building_floor_list_key],
    () =>
      fetchClient
        .post<BaseResponse<string[]>>(building_floor_list)
        .then((res) => res.data.data),
    { staleTime: Infinity }
  );
};

// 대체
// export const getAllFloors = (data: IBuilding | undefined) => {
//   const newArr: string[] = [];
//   if (!data) return newArr;

//   // 더 효율적인 방법 고민해볼 것
//   data.dong_floor.forEach((el) => {
//     el.floor.forEach((floor) => {
//       if (newArr.includes(floor)) return;
//       newArr.push(floor);
//     });
//   });

//   return newArr.sort((a, b) => (+a - +b ? 1 : -1));
// };

export const useUploadFile = (files: File[]) => {
  const formdata = new FormData();
  files.map((el) => formdata.append("files", el));

  return fetchClient.post<BaseResponse<string[]>>(upload_images, formdata);
};

export const useFacilityList = () => {
  // const auth = useUserAuth("room_public"); 권한 넣어야함

  const { data } = useQuery([registered_list_key], () =>
    fetchClient
      .get<BaseResponse<IRoomPublicList[]>>(registered_facility_list)
      .then((res) => res.data.data)
  );

  return { data };
};
