import * as C from "../../components/styled/index";
import { Button, Text } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../lib/api/axios";
import { meal_planner_list } from "../../lib/api/queries/urls";
import { meal_planner_list_key } from "../../lib/api/queries/keys";
import PaginationUI from "../../components/pagination";
import { usePagination } from "@ajna/pagination";
import { BaseResponse } from "../../lib/api/queries/commons/types";
import { IMeal } from "../../lib/types/Imodels";
import MealTableItem from "./mealTableItem";
import CompactHeader, { BodyHeader } from "../../components/Main/CompactHeader";
import useUserAuth from "../../components/hooks/useUserAuth";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { GlobalContext } from "../../App";
import GetAuthorized from "../../components/Authrized";

export default function MealPlanner() {
  const navigate = useNavigate();
  const auth = useUserAuth("notice");
  const { buildInfo } = useContext(GlobalContext);
  const hasFunc = buildInfo?.services.CAFETERIA?.enabled;
  const [year, setYear] = useState(new Date());
  const [allPage, setAllPage] = useState<number>(1);
  const { currentPage, setCurrentPage } = usePagination({
    initialState: { currentPage: 1 },
  });

  const isEdit = auth === "edit";

  const { data } = useQuery(
    [meal_planner_list_key, currentPage, moment.utc(year).format("YYYY-MM-01")],
    () =>
      fetchClient
        .get<BaseResponse<IMeal[]>>(meal_planner_list, {
          params: {
            date: moment(year).format("YYYY-MM-01"),
            limit: 10,
            page: currentPage,
          },
        })
        .then((res) => {
          setAllPage(res.data.total_page_num || 1);
          return res.data.data;
        }),
    { enabled: hasFunc && (auth === "read" || auth === "edit") },
  );

  if (auth === "no_auth") return <GetAuthorized />;

  return (
    <CompactHeader title="구내식당 식단표">
      <BodyHeader>
        <C.DateWithIconBox>
          <DatePicker
            locale={ko}
            selected={year}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            onChange={(date: Date) => setYear(date)}
            customInput={
              <C.InnerTextBox>
                <Text>{moment.utc(year).format("YYYY년 MM월")}</Text>
                <FiChevronDown />
              </C.InnerTextBox>
            }
          />
        </C.DateWithIconBox>
        {isEdit && (
          <Button variant="bgBlue" style={{ margin: 0 }} onClick={() => navigate("/cafeteria/new")}>
            식단표 등록
          </Button>
        )}
      </BodyHeader>
      <C.ListTableBox>
        <C.ListTableHeader w="25%">
          <div style={{ width: "30%" }}>식단 날짜</div>
          <div style={{ width: "45%" }}>제목</div>
          <div>등록일</div>
        </C.ListTableHeader>
        {data?.map((el) => <MealTableItem key={String(el._id)} el={el} />)}
      </C.ListTableBox>
      <PaginationUI page={currentPage} allPage={allPage} setPage={setCurrentPage} />
    </CompactHeader>
  );
}
