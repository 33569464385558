import { Link, Outlet, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import useUserAuth from "../../components/hooks/useUserAuth";
import GetAuthorized from "../../components/Authrized";

export default function TemperatureLayout() {
  const { pathname } = useLocation();
  const auth = useUserAuth("complaint");

  if (auth === "no_auth") return <GetAuthorized />;

  return (
    <div className="w-full">
      <div className="flex w-[8.5rem] items-center justify-center rounded-t-2xl bg-blue-600 py-3 text-white">
        온도피드백
      </div>
      <div className="flex gap-3 p-6">
        <Link
          to={"/temperature"}
          className={twMerge("leading-6 text-gray-400", pathname === "/temperature" && "font-bold text-gray-900")}
        >
          실시간현황
        </Link>
        <Link
          to={"/temperature/history"}
          className={twMerge(
            "leading-6 text-gray-400",
            pathname === "/temperature/history" && "font-bold text-gray-900",
          )}
        >
          접수내역
        </Link>
      </div>
      <Outlet />
    </div>
  );
}
