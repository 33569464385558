import { useBuildingInfoQuery } from "../requests/building";
import Icon from "../types/iconsax";

export const useServices = () => {
  const { data } = useBuildingInfoQuery();

  const SERVICE_OBJECTS = [
    {
      id: "DASHBOARD",
      iconElement: <Icon.Category size={16} />,
      name: "대시보드",
      href: "dashboard",
      pathname: "/dashboard",
      isAvailable: true,
    },
    {
      id: "BUILDING_SETTING",
      iconElement: <Icon.Setting2 size={16} />,
      name: "건물 기본 설정",
      href: "settings",
      pathname: "/settings/info",
      isAvailable: true,
    },
    {
      id: "ROOMS_MANAGEMENT",
      iconElement: <Icon.BoxSearch size={16} />,
      name: "호실 관리",
      href: "rooms",
      pathname: "/rooms",
      isAvailable: true,
    },
    {
      id: "COMPANY_MANAGEMENT",
      iconElement: <Icon.Briefcase size={16} />,
      name: "입주사 관리",
      href: "companies",
      pathname: "/companies",
      isAvailable: true,
    },
    {
      id: "FACILITY_RESERVATION",
      iconElement: <Icon.People size={16} />,
      name: "회원관리",
      href: "members",
      // pathname: "/members",
      pathname: "/members/requests?owner=true&master=true",
      isAvailable: true,
    },
    {
      id: "NOTICE",
      iconElement: <Icon.Reserve size={16} />,
      name: "공지사항",
      href: "notice",
      pathname: "/notice",
      isAvailable: true,
    },
    {
      id: "RESOURCES",
      iconElement: <Icon.Note1 size={16} />,
      name: "자료실",
      href: "resources",
      pathname: "/resources",
      isAvailable: true,
    },
    {
      id: "COMPLAINTS",
      iconElement: <Icon.Note size={16} />,
      name: "민원",
      href: "complaints",
      pathname: "/complaints",
      isAvailable: data?.data.services.COMPLAINT?.enabled,
    },
    {
      id: "TEMPERATURE_FEEDBACK",
      iconElement: <Icon.Volume size={16} />,
      name: "온도피드백",
      href: "temperature",
      pathname: "/temperature",
      isAvailable: data?.data.services.TEMPERATURE_FEEDBACK?.enabled,
    },
    {
      id: "SURVEY",
      iconElement: <Icon.TaskSquare size={16} />,
      name: "설문조사",
      href: "survey",
      pathname: "/survey",
      isAvailable: data?.data.services.SURVEY?.enabled,
    },
    {
      id: "VOTES",
      iconElement: <Icon.LikeDislike size={16} />,
      name: "투표",
      href: "votes",
      pathname: "/votes",
      isAvailable: data?.data.services.VOTES?.enabled,
    },
    {
      id: "CAFETERIA",
      iconElement: <Icon.Coffee size={16} />,
      name: "구내식당",
      href: "cafeteria",
      pathname: "/cafeteria",
      isAvailable: data?.data.services.CAFETERIA?.enabled,
    },
    {
      id: "PARKING",
      iconElement: <Icon.Car size={16} />,
      name: "주차",
      href: "parking",
      pathname: "/parking",
      isAvailable: data?.data.services.REGULAR_PARKING?.enabled,
    },
    {
      id: "MAINTENANCE",
      iconElement: <Icon.ReceiptText size={16} />,
      name: "관리비",
      href: "maintenance-fee",
      pathname: "/maintenance-fee",
      isAvailable: data?.data.services.MAINTENANCE_FEE?.enabled,
    },
    {
      id: "BILLING",
      iconElement: <Icon.ReceiptText size={16} />,
      name: "임대료",
      href: "rent-fee",
      pathname: "/rent-fee",
      isAvailable: data?.data.services.RENT_FEE?.enabled,
    },
    {
      id: "MEETING_ROOM",
      iconElement: <Icon.CalendarTick size={16} />,
      name: "회의실",
      href: "meeting-rooms",
      pathname: "/meeting-rooms",
      isAvailable: data?.data.services.MEETING_ROOM?.enabled,
    },
    {
      id: "POINT_MANAGEMENT",
      iconElement: <Icon.BuyCrypto size={16} />,
      name: "포인트 관리",
      href: "points",
      pathname: "/points",
      isAvailable: data?.data.services.MEETING_ROOM?.options?.USE_POINT,
    },
    {
      id: "COMMON_FACILITY",
      iconElement: <Icon.CalendarTick size={16} />,
      name: "공용시설 예약",
      href: "facility",
      // 1은 임시 값
      pathname: "/facility/1",
    },
  ];

  // SERVICE_OBJECTS 에서 isAvailable이 true인게 array에서 위로 가게끔 정렬
  const sortedServiceObjects = SERVICE_OBJECTS.sort((a, b) =>
    a.isAvailable === b.isAvailable ? 0 : a.isAvailable ? -1 : 1,
  );

  return sortedServiceObjects;
};
