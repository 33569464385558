import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  UseModalProps,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import useUserAuth from "../../components/hooks/useUserAuth";
import SingleRow from "../../components/modal/table/singleRow";
import * as C from "../../components/styled/index";
import { fetchClient } from "../../lib/api/axios";
import { building, update_car_info } from "../../lib/api/queries/urls";
import { success, warning } from "../../lib/theme/toast";
import { IBuilding } from "../../lib/types/Imodels";
import { BaseResponse } from "../../lib/api/queries/commons/types";

type IInfo = UseModalProps;

export default function RegularModal({ ...props }: IInfo) {
  const toast = useToast();
  const auth = useUserAuth("car");
  const [description, setDescription] = useState("");

  const { data } = useQuery(["parking_information"], () =>
    fetchClient.post<BaseResponse<IBuilding>>(building).then((res) => res.data.data),
  );

  useEffect(() => {
    if (!data) return;
    setDescription(data.parking_description || "");
  }, [data]);

  const mutation = useMutation(() =>
    fetchClient
      .post(update_car_info, {
        parking_description: description,
      })
      .then((res) => {
        toast({
          ...success,
          title: "정기 주차 안내 문구 수정 완료",
          description: "정기 주차 안내 문구가 정상적으로 수정되었습니다.",
        });
        props.onClose();
        return res.data.data;
      })
      .catch((err: AxiosError) =>
        toast({
          ...warning,
          title: err.response?.status === 401 ? "관리자 권한 오류" : "정기 주차 안내 문구 수정 오류",
          description:
            err.response?.status === 401
              ? "정기 주차 안내를 변경하기 위한 권한이 없습니다. 권한 필요 시 최고관리자에게 문의해주세요."
              : "정기 주차 안내 문구 수정에 실패했습니다. 새로고침 후 다시 등록해주세요.",
        }),
      ),
  );

  return (
    <Modal size="lg" variant="contents" isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent minW="864px" mt="7.75rem">
        <ModalHeader>정기 주차 안내</ModalHeader>
        <ModalBody style={{ padding: "0px 24px" }}>
          <SingleRow w="20%" title="이용 약관">
            <C.BasicTextArea
              h="280px"
              readOnly={auth !== "edit"}
              value={description || ""}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="금액 안내, 이용 방법, 해지 방법을 포함하여 입주사가 정기주차 이용 시 알아야하는 모든 이용약관을 입력해주세요."
            />
          </SingleRow>
        </ModalBody>
        <ModalFooter>
          {auth === "edit" ? (
            <>
              <Button variant="cancel" onClick={props.onClose}>
                취소
              </Button>
              <Button
                variant="bgBlue"
                onClick={() => {
                  if (mutation.isLoading) return;
                  mutation.mutate();
                }}
              >
                저장하기
              </Button>
            </>
          ) : (
            <Button variant="cancel" onClick={props.onClose}>
              확인
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
