import { Button, Flex, Text } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { ChangeEvent, useRef } from "react";
import styled from "@emotion/styled";
import { ICreateMealInFile } from "../types";
import { FileFormat } from "../../../lib/utils/format";
import { CloseIconWithImg } from "../../../components/styled";
import { toast } from "../../../lib/utils/toast";

const FileText = styled.div`
  background-color: #fff;
  border: 1px solid #9ca3af;
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  min-height: 88px;
  width: 100%;
  margin-right: 12px;
`;

const FileList = styled.div`
  margin-bottom: 4px;
  margin-right: 12px;
  position: relative;
  img {
    width: 64px;
    height: 64px;
  }
`;

const DeleteIcon = styled(CloseIconWithImg)`
  border-radius: 100%;
`;

export default function UploadImages(props: ICreateMealInFile) {
  //여기 파일 업로드 API 적용되면 반영
  const fileRef = useRef<HTMLInputElement>(null);

  const onSelectFile = () => {
    if (props.origin.length + (props.files || []).length >= 5) return toast.underFiveImage({});

    fileRef.current?.click();
  };

  const onClickFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.files;
    if (value === null) return;
    if ((props.files || []).length + props.origin.length + value.length > 5) return toast.underFiveImage({});

    if (
      Array.from(value || []).filter((el) => !FileFormat.includes(el.type.split("/")[1]) || el.size > 100 * 1024 * 1024)
        .length !== 0
    )
      return toast.warning({
        title: "파일 업로드 오류",
        description: "첨부 파일은 이미지 형식으로 100MB 이하의 파일만 가능합니다. 확인 후 다시 시도해주세요.",
      });

    props.setFiles([...(props.files || []), ...Array.from(value)]);
    e.target.value = "";
  };

  const onDeleteState = (idx: number) => {
    if (props.files === null) return;
    const newArr = props.files.filter((_, index) => index !== idx);
    props.setFiles(newArr);
  };

  const onDelFiles = (idx: number) => {
    const newArr = props.origin.filter((_, index) => index !== idx);
    props.setOrigin(newArr);
  };

  return (
    <Flex w="100%" flexDirection="column">
      <Text mb="2">파일 1개당 최대 첨부용량 100MB, 파일 업로드 최대 5개 가능</Text>
      <Flex w="100%" alignItems="flex-start">
        <FileText>
          {props.isEdit &&
            props.origin.length !== 0 &&
            props.origin.map((el, idx) => (
              <FileList key={uuidv4()}>
                <img src={el} />
                <DeleteIcon onClick={() => onDelFiles(idx)} />
              </FileList>
            ))}
          {props.files &&
            props.files.map((el, idx) => (
              <FileList key={uuidv4()}>
                <img src={URL.createObjectURL(el)} alt={el.name} />
                <DeleteIcon onClick={() => onDeleteState(idx)} />
              </FileList>
            ))}
          {/* {Array.from(props.files).map((el, idx) => (
            <FileList key={uuidv4()}>
              {el.name}
              <DeleteIcon onClick={() => onDelFiles(idx)} />
            </FileList>
          ))} */}
        </FileText>
        <Button onClick={onSelectFile} variant="basic">
          파일 선택
        </Button>
      </Flex>
      <input type="file" ref={fileRef} multiple={true} style={{ display: "none" }} onChange={onClickFiles} />
    </Flex>
  );
}
