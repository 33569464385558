import { twMerge } from "tailwind-merge";
import CustomBadge from "../../../../components/CustomBadge";
import Icon from "../../../../types/solar-icon";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/button/Button";
import CustomTooltip from "../../../../components/CustomTooltip";

// 테스트용 데이터
interface Tenant {
  id: number;
  name: string;
  tenant_type: string;
  dong_ho: string;
  officener_join_status: string;
  userType: "owner" | "master" | "normal";
}

const testTenantArr: Tenant[] = [
  {
    id: 0,
    name: "주식회사 리버블",
    tenant_type: "사무",
    dong_ho: "1동 101호 외 3",
    officener_join_status: "승인완료 2024.04.01 10:00",
    userType: "master",
  },
  {
    id: 1,
    name: "주식회사 리버블",
    tenant_type: "상가",
    dong_ho: "2동 101호 외 3",
    officener_join_status: "승인대기중 2024.04.01 10:00",
    userType: "normal",
  },
];

const lastData = testTenantArr.length - 1;

export default function MemberDetailCompanyPage() {
  const navigate = useNavigate();

  return (
    <div className="mx-6 my-5 flex flex-col items-start rounded-xl border border-gray-200">
      {testTenantArr.map((item, index) => (
        <div
          key={item.id}
          className={twMerge(
            "flex w-full items-center hover:bg-gray-50",
            lastData !== index && "border-b border-gray-200",
          )}
        >
          <div className="flex flex-1 items-center gap-4 px-6 py-4">
            <span
              className={twMerge(
                "flex h-10 w-10 items-center justify-center rounded-3xl bg-blue-50",
                item.officener_join_status.includes("승인대기중") && "opacity-40",
              )}
            >
              <Icon.Case iconStyle="Bold" size={20} color="#3B82F6" />
            </span>
            <span className="text-sm font-medium text-gray-900">{item.name}</span>
          </div>
          <div className="flex flex-1 flex-col px-6 py-4">
            <span className="text-sm font-medium text-gray-900">{item.dong_ho}</span>
            <span className="text-sm text-gray-500">{item.tenant_type}</span>
          </div>
          <div className="flex flex-1 px-6 py-4">
            <span
              className={twMerge(
                "text-sm text-gray-900",
                item.officener_join_status.includes("승인대기중") && "text-red-500",
              )}
            >
              {item.officener_join_status}
            </span>
          </div>
          <div
            className={twMerge(
              "flex w-32 px-6 py-4",
              item.officener_join_status.includes("승인대기중") && "opacity-40",
            )}
          >
            <CustomBadge type={item.userType} />
          </div>
          {item.officener_join_status.includes("승인대기중") ? (
            <div className="flex flex-col px-6 py-4">
              <div className="inline-flex h-9 w-[7.5rem] divide-x divide-gray-200 rounded-md shadow-sm">
                <div className="flex flex-1">
                  <span className="text-medium flex flex-1 items-center justify-center rounded-s-md border border-r-0 border-gray-200 bg-white p-2 text-sm text-gray-700">
                    승인대기중
                  </span>
                </div>
                <div className="flex">
                  <button className="flex flex-1 items-center justify-center rounded-e-md border border-l-0 border-gray-200 bg-white p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-600 active:bg-gray-200 active:text-gray-600">
                    <Icon.AltArrowDown size={20} />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex px-6 py-4">
              <div className="inline-flex h-9 w-[7.5rem] divide-x divide-gray-200 rounded-md shadow-sm">
                <CustomTooltip message="입주사 정보보기">
                  <button className="flex flex-1 items-center justify-center rounded-s-md border border-r-0 border-gray-200 px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-600 active:bg-gray-200 active:text-gray-700">
                    <Icon.Magnifer size={20} />
                  </button>
                </CustomTooltip>
                <CustomTooltip message="입주사에서 내보내기">
                  <button className="flex flex-1 items-center justify-center rounded-e-md border border-l-0 border-gray-200 px-4 py-2 text-gray-500 hover:bg-red-50 hover:text-red-500 active:bg-red-100 active:text-red-600">
                    <Icon.Exit size={20} />
                  </button>
                </CustomTooltip>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
