import { GrayInfoBox, ShadowBox } from "../../../../components/styled/webApp";
import GrayRadiusTextArea from "../../../../components/textarea";
import { Dispatch, SetStateAction, useContext } from "react";
import GrayDropdown from "../../../../components/select/gray";
import { usefetchPermissionList, usefetchTeamList } from "../commons/queries";
import { IUserDepartment } from "../commons/types";
import { GlobalContext } from "../../../../App";

// extends UseModalProps
interface IDepartComponent {
  isSingle: boolean;
  data: IUserDepartment;
  setData: Dispatch<SetStateAction<IUserDepartment>>;
  auth?: string;
}

function DesignationDepartmentAuth(props: IDepartComponent) {
  const { isSingle, data, setData } = props;

  const { user } = useContext(GlobalContext);
  // const [isOpen, setIsOpen] = useState(false);
  const { data: teamList } = usefetchTeamList(true);
  const { data: authList } = usefetchPermissionList(
    user?.user_type === "master"
  );
  const isMaster =
    data.auth !==
    String(authList?.find((el) => el.name === "마스터")?._id || "");

  // const onClickPushTeamArr = (value: string) => {
  //   const newArr = [...data.team];

  //   if (newArr.includes(value)) {
  //     const popArr = newArr.filter((el) => el !== value);
  //     return setData({ ...data, ...{ team: popArr } });
  //   }
  //   newArr.push(value);
  //   setData({ ...data, ...{ team: newArr } });
  // };

  // useEffect(() => {
  //   if (!authList) return;

  //   console.log(
  //     data,
  //     data.auth ===
  //       String(authList?.find((el) => el.name === "마스터")?._id || "")
  //   );
  // }, [authList]);

  return (
    <ShadowBox
      isModal={true}
      isMore={true}
      style={isSingle ? {} : { padding: 0 }}
    >
      {isMaster && (
        <>
          <div>
            <h4 style={{ paddingBottom: 0 }}>
              소속 부서를 선택해주세요. {!isSingle && <span>*</span>}
            </h4>
            <span>
              소속을 선택하시면 해당 부서의 주요 알림을 받을 수 있습니다.
            </span>
            <GrayDropdown
              w="100%"
              value={data.team}
              _onChange={(e) =>
                setData({ ...data, ...{ team: e.target.value } })
              }
            >
              <option value="" disabled>
                소속 선택
              </option>
              {teamList?.map((el) => (
                <option key={String(el._id)} value={String(el._id)}>
                  {el.name}
                </option>
              ))}
            </GrayDropdown>
            {/* select 메뉴 어떻게 갈건지 아직 미정 -> 컴포넌트 만든다면 Menu 폴더로 */}
            {/* <Menu variant="gray" isOpen={isOpen}>
              <MenuButton
                mt="0.75rem"
                type="button"
                onClick={() => setIsOpen((prev) => !prev)}
              >
                {data.team === ""
                  ? "전체"
                  : teamList?.find((el) => String(el._id) === data.team)?.name}
                <FiChevronDown fontSize="1rem" />
              </MenuButton>
              <MenuList w="28rem">
                <MenuOptionGroup type="radio">
                  {teamList?.map((el) => (
                    <MenuItem key={String(el._id)}>
                      <Radio
                        variant="default"
                        value={String(el._id)}
                        onChange={(e) => {
                          if (isOpen) setIsOpen(false);
                          setData({ ...data, ...{ team: e.target.value } });
                        }}
                        isChecked={data.team.includes(String(el._id))}
                      >
                        {el.name}
                      </Radio>
                    </MenuItem>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu> */}
          </div>
          <div>
            <h4>
              {authList
                ? "접근 가능한 권한을 선택해주세요. "
                : "권한 수정은 최고관리자만 가능합니다. "}
              {!isSingle && <span>*</span>}
            </h4>
            {authList ? (
              <GrayDropdown
                w="100%"
                value={data.auth}
                _onChange={(e) =>
                  setData({ ...data, ...{ auth: e.target.value } })
                }
              >
                <option value="" disabled>
                  권한 선택
                </option>
                {authList?.map((el) => {
                  if (el.name === "마스터") return;
                  return (
                    <option key={String(el._id)} value={String(el._id)}>
                      {el.name}
                    </option>
                  );
                })}
              </GrayDropdown>
            ) : (
              <GrayInfoBox style={{ padding: "0.75rem 1.5rem" }}>
                {props.auth}
              </GrayInfoBox>
            )}
          </div>
        </>
      )}
      <div style={isSingle ? {} : { paddingBottom: "1rem" }}>
        <h4>직무, 직위 등을 입력해주세요.</h4>
        <GrayRadiusTextArea
          value={data.description}
          placeholder="직무, 직위 등을 입력해주세요."
          _onChange={(e) =>
            setData({ ...data, ...{ description: e.target.value } })
          }
          isLimited={true}
        />
      </div>
    </ShadowBox>
  );
}

export default DesignationDepartmentAuth;
