import { useDisclosure, UseDisclosureProps, useToast } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { queryClient } from "../../../..";
import ResponsiveConfirm from "../../../../components/modal/responsive/confirm";
import EditModalNoHeader from "../../../../components/modal/responsive/edit";
import { fetchClient } from "../../../../lib/api/axios";
import { tenant_point_key } from "../../../../lib/api/queries/keys";
import { individual_point_recharge } from "../../../../lib/api/queries/urls";
import { warning } from "../../../../lib/theme/toast";
import { MonthlyPointBox, PointInputBox, UpdateInfoUL } from "../../commons/styled";

interface IMonthlyPoint extends UseDisclosureProps {
  id: string;
}

export default function UpdateMonthlyPoint(props: IMonthlyPoint) {
  const toast = useToast();
  const [amount, setAmount] = useState<number>();
  const { isOpen, onOpen, onClose } = useDisclosure(); //충전

  const onCloseModal = () => {
    props.onClose && props.onClose();
    return;
  };

  const mutation = useMutation(() =>
    fetchClient
      .post(individual_point_recharge, {
        tenant_id: props.id,
        amount,
      })
      .then(() => {
        toast({
          ...warning,
          title: "알림",
          description: `자동지급 포인트를 ${amount}P로 설정했습니다.`,
        });
        queryClient.invalidateQueries([tenant_point_key]);
        onClose();
        onCloseModal();
      })
      .catch((err) => console.log(err)),
  );

  const onClickSaveRegularPoint = () => {
    if (mutation.isLoading) return;
    mutation.mutate();
  };

  return (
    <EditModalNoHeader
      isOpen={props.isOpen || false}
      onClose={onCloseModal}
      onClickUpdate={() => {
        if (amount === undefined)
          return toast({
            ...warning,
            title: "알림",
            description: "자동 지급 포인트를 정확히 설정해주세요.",
          });
        onOpen();
      }}
    >
      <MonthlyPointBox>
        <h6>매월 1일에 자동지급되는 포인트를 설정해주세요.</h6>
        <UpdateInfoUL>
          <li>사용기한 : 매월 1일부터 말일까지 사용</li>
          <li>매월 1일 자정 12시 자동소멸됩니다.</li>
        </UpdateInfoUL>
        <PointInputBox>
          매월
          <input
            placeholder="1000"
            value={amount === undefined ? "" : String(amount)}
            onChange={(e) => {
              if (Number.isNaN(Number(e.target.value))) return;
              setAmount(Number(e.target.value));
            }}
          />
          P
        </PointInputBox>
      </MonthlyPointBox>
      {isOpen && (
        <ResponsiveConfirm
          title="지급 포인트 수정"
          isOpen={isOpen}
          before="취소"
          next="수정하기"
          onClose={onClose}
          onAction={onClickSaveRegularPoint}
        >
          <Text>매월 자동 지급 포인트를 {amount}P로 수정할까요?</Text>
        </ResponsiveConfirm>
      )}
    </EditModalNoHeader>
  );
}
