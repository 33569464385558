import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Flex, UseModalProps } from "@chakra-ui/react";
import { ShadowBox } from "../../../../components/styled/webApp";
import EditModalNoHeader from "../../../../components/modal/responsive/edit";
import { StaticInput } from "../../../../components/Input/responsive";
import { WarnMessageBox } from "../../../../components/styled";
import { FiInfo } from "@react-icons/all-files/fi/FiInfo";
import { IUser } from "../../../../lib/types/Imodels";
import { useMutationUserInfo } from "../commons/queries";
import { toast } from "../../../../lib/utils/toast";

interface IUserInfo extends UseModalProps {
  data: IUser;
}

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .matches(/^[a-zA-Zㄱ-힣]*$/, "이름을 정확히 입력해주세요.")
      .min(1, "이름을 정확히 입력해주세요."),
    contact: yup
      .string()
      .matches(/^[0-9]+$/, "연락 가능한 번호를 '-'없이 입력해주세요.")
      .min(8, "연락 가능한 번호를 '-'없이 입력해주세요.")
      .max(12, "연락 가능한 번호를 '-'없이 입력해주세요."),
  })
  .required();

function EditUserInfoInModal(rest: IUserInfo) {
  const { isOpen, onClose, ...props } = rest;

  const { editUser } = useMutationUserInfo();

  const {
    handleSubmit,
    formState: { errors },
    register,
    // watch,
  } = useForm<FieldValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onChangeUserInfo: SubmitHandler<FieldValues> = async (data) => {
    const formData = new FormData();
    formData.append("user_id", String(props.data._id));
    // formData.append("user_id", "6446089af7e83039edcea67b");
    formData.append("name", data.name);
    formData.append("phone_number", data.contact);

    try {
      await editUser.mutateAsync(formData);

      onClose();
      toast.success({
        title: "관리자 정보 변경",
        description: "관리자 정보가 변경되었습니다.",
      });
    } catch (err) {
      if (editUser.error?.response?.status === 504)
        return toast.warning({
          title: "관리자 정보 변경",
          description: "존재하지 않는 관리자입니다.",
        });

      toast.warning({
        title: "관리자 정보 변경",
        description:
          "일시적으로 관리자 수정에 실패했습니다. 새로고침 후에도 동일한 오류가 반복될 경우 오피스너 담당자에게 문의해주세요.",
      });
    }
  };

  return (
    <EditModalNoHeader
      isOpen={isOpen}
      onClose={onClose}
      value="변경"
      onClickUpdate={handleSubmit(onChangeUserInfo)}
      // handleSubmit(onSubmit)
      scrollBehavior="inside"
    >
      <ShadowBox isModal={true} isMore={true}>
        <div>
          <h4>이름을 입력해주세요.</h4>
          <StaticInput
            defaultValue={props.data.name}
            autoComplete="off"
            placeholder="관리자 이름을 입력해주세요."
            // _onChange={() => console.log("dd")}
            {...register("name")}
          />
          {errors.name && (
            <WarnMessageBox>
              <FiInfo />
              <>{errors.name.message}</>
            </WarnMessageBox>
          )}
        </div>
        <div>
          <h4>변경할 연락처를 입력해주세요.</h4>
          <Flex>
            <StaticInput
              type="tel"
              autoComplete="off"
              defaultValue={props.data.phone_number}
              placeholder="변경할 연락처를 숫자로 입력해주세요."
              // _onChange={() => console.log("dd")}
              {...register("contact")}
            />
            {/* <Button ml={2} variant="m_primary">
            인증번호
          </Button> */}
          </Flex>
          {errors.contact && (
            <WarnMessageBox>
              <FiInfo />
              <>{errors.contact.message}</>
            </WarnMessageBox>
          )}
        </div>
        {/* <StaticInput
          type="tel"
          autoComplete="off"
          placeholder="변경할 연락처를 숫자로 입력해주세요."
          style={{ marginTop: "12px" }}
          {...register("contact")}
        /> */}
      </ShadowBox>
    </EditModalNoHeader>
  );
}

export default EditUserInfoInModal;
