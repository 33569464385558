import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import * as S from "../styled";
import { IOpenSection } from "../types";
import DaumPostcode, { Address } from "react-daum-postcode";
import styled from "@emotion/styled";
import { useRef, useState } from "react";
import { colors } from "../../../components/styled/common";
import { useFormContext } from "react-hook-form";
import { onPreventInput } from "../../../components/hooks/useInputEvent";

const ModalBox = styled(ModalContent)`
  padding: 8px;
  @media (max-width: 540px) {
    //여기 수정 필요, css 방식 변경
    width: 90%;
    .post-code > div {
      height: 300px !important;
    }
    #__daum__layer_1 {
      min-width: 200px !important;
    }
    #__daum__layer_1 iframe {
      min-width: 200px !important;
    }
    #__daum__layer_1 iframe #document iframe {
      min-width: 200px !important;
    }
  }
`;

const DetailInput = styled.div`
  padding: 12px 8px;
  border-top: 1px solid ${colors.gray2};
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  input {
    width: 100%;
    border: 1px solid ${colors.gray3};
    color: #696f79;
    padding: 8px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
  }
`;

const ButtonBox = styled.div`
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Style = {
  minWidth: "200px",
};

export default function OwnerInfo(props: IOpenSection) {
  const { register, setValue, getValues } = useFormContext();

  const inputRef = useRef<HTMLInputElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure(); //postcode open

  const [address, setAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const [isComplete, setIsComplete] = useState(false); //추후에 등록할때 isComplete true여야함

  const onClickSetAddress = () => {
    setAddress("");
    setAddressDetail("");
  };

  const onClickApply = () => {
    onClose();
    if (address) {
      setIsComplete(true);
      setValue("owner.address", address);
      setValue("owner.address_detail", addressDetail);
    }
  };

  return (
    <S.ToggleBox>
      <S.ToggleTitle onClick={() => props.setIsOpen((prev) => !prev)}>
        4. 소유자 현황
        <S.ToggleIcon
          src="/arrow.png"
          alt="화살표 아이콘"
          isOpen={props.isOpen}
        />
      </S.ToggleTitle>
      <S.ToggleContent
        id="owner"
        isHeight={(document.getElementById("owner")?.scrollHeight || 0) + 105}
        isOpen={props.isOpen}
      >
        <S.InputBox>
          <S.InputName>
            성명 <span>*</span>
          </S.InputName>
          <input {...register("owner.name")} />
          {/* <TextInput isRequired={true} data="ownerName" /> */}
        </S.InputBox>
        <S.NumberBox>
          <S.InputName>
            전화번호 <span>*</span>
          </S.InputName>
          <input
            type="number"
            style={{ marginRight: "4px" }}
            onKeyDown={(e) => onPreventInput(e, 3)}
            onWheel={(e) => e.currentTarget.blur()}
            {...register("owner.first")}
          />
          -
          <input
            type="number"
            style={{ margin: "0 4px" }}
            onKeyDown={(e) => onPreventInput(e, 3)}
            onWheel={(e) => e.currentTarget.blur()}
            // value={}
            {...register("owner.second")}
          />
          {/* <NumberFormInput data="ownerSecond" isRequired={true} /> */}
          -
          <input
            type="number"
            style={{ margin: "0 4px" }}
            onKeyDown={(e) => onPreventInput(e, 3)}
            onWheel={(e) => e.currentTarget.blur()}
            // value={}
            {...register("owner.third")}
          />
        </S.NumberBox>
        <S.InputBox>
          <S.InputName>주소</S.InputName>
          <S.MoreInfoButton
            style={{ marginBottom: "0px" }}
            type="button"
            onClick={() => {
              onClickSetAddress();
              onOpen();
            }}
          >
            주소 검색
          </S.MoreInfoButton>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalBox>
              <div className="post-code">
                <DaumPostcode
                  style={Style}
                  autoClose={false}
                  onComplete={(data: Address) => {
                    setAddress(data.address);
                    inputRef.current?.focus();
                  }}
                />
              </div>
              <DetailInput>
                <div style={{ marginBottom: "8px" }}>
                  <S.InputName>기본 주소</S.InputName>
                  <input readOnly value={address || ""} />
                </div>
                <div>
                  <S.InputName>상세 주소</S.InputName>
                  <input
                    ref={inputRef}
                    placeholder="상세 주소 입력"
                    onChange={(e) => setAddressDetail(e.target.value)}
                  />
                </div>
              </DetailInput>
              <ButtonBox>
                <Button variant="bgBlue" type="button" onClick={onClickApply}>
                  주소 입력
                </Button>
                <Button
                  variant="cancel"
                  ml="2"
                  type="button"
                  onClick={() => {
                    onClickSetAddress();
                    onClose();
                  }}
                >
                  취소
                </Button>
              </ButtonBox>
            </ModalBox>
          </Modal>
        </S.InputBox>
        <S.InputBox style={{ display: isComplete ? "block" : "none" }}>
          <input
            readOnly
            style={{ marginBottom: "8px" }}
            value={getValues("owner.address") || ""}
          />
          <input
            value={getValues("owner.address_detail") || ""}
            placeholder="상세 주소 입력"
            {...register("owner.address_detail")}
          />
        </S.InputBox>
        {/* <S.NumberBox>
          <S.InputName>
            임대 기간 <span>*</span>
          </S.InputName>
          <NumberFormInput
            style={{ margin: "0 4px 0 0" }}
            data="period"
            isRequired={true}
          />
          년
        </S.NumberBox> */}
      </S.ToggleContent>
    </S.ToggleBox>
  );
}
