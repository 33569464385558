import styled from "@emotion/styled";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
} from "@chakra-ui/react";
import { colors } from "../styled/common";
import { CSSProperties, FC, ReactNode } from "react";
import { IoClose } from "@react-icons/all-files/io5/IoClose";

const DrawerIcon = styled(IoClose)`
  color: ${colors.gray3};
  cursor: pointer;
`;

interface ICommonDrawerModal extends DrawerProps {
  title: string;
  footer?: ReactNode;
  styled?: CSSProperties;
  maxW?: string;
}

const CommonDrawerModal: FC<ICommonDrawerModal> = ({ children, ...props }) => {
  return (
    <Drawer
      size="sm"
      variant="default"
      isOpen={props.isOpen}
      placement="right"
      onClose={props.onClose}
      closeOnEsc={false}
      closeOnOverlayClick={
        props.closeOnOverlayClick !== undefined
          ? props.closeOnOverlayClick
          : false
      }
      blockScrollOnMount={false}
    >
      <DrawerOverlay />
      <DrawerContent maxW={props.maxW}>
        <DrawerHeader style={props.styled}>
          {props.title} <DrawerIcon onClick={props.onClose} />
        </DrawerHeader>
        {children}
      </DrawerContent>
    </Drawer>
  );
};

export default CommonDrawerModal;
