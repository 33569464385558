import { Button } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { colors } from "../../../components/styled/common";

export const SavedDetails = styled.div`
  width: 100%;
  font-size: 0.875rem;
  display: flex;
  padding: 0.75rem 0;
  border-bottom: 1px solid ${colors.gray1};
  .section {
    font-weight: 500;
    width: 5.5rem;
  }
  .holidays {
    max-width: calc(100% - 5.5rem);
  }
`;

//대표 사진 업로드
export const RepresentBox = styled.div`
  position: relative;
  margin: 0px 4px 4px 0;
  color: ${colors.gray4};
`;

export const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 5rem;
  width: 5rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${colors.gray2};
  input {
    display: none;
  }
`;

export const RepreImage = styled.img<{ isLoading: boolean }>`
  width: ${({ isLoading }) => (isLoading ? "0" : "5rem")};
  height: ${({ isLoading }) => (isLoading ? "0" : "5rem")};
  object-fit: fill;
`;

export const FirstMark = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 4px 0;
  background-color: rgba(17, 24, 39, 0.5);
  color: ${colors.whiteColor};
  width: 100%;
`;

export const CountNum = styled.div`
  text-align: right;
  font-size: 0.75rem;
  color: ${colors.gray4};
  span {
    color: ${colors.gray5};
  }
`;

export const ActiveButton = styled(Button)<{ clicked: string }>`
  padding: 0.75rem 1.25rem;
  font-weight: 400;
  margin-right: 0.75rem;
  ${({ clicked }) =>
    clicked !== "true" &&
    `color:${colors.gray4}; background-color:${colors.gray1}`};
`;

export const TimeRange = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  background-color: ${colors.gray1};
  padding: 0 1rem;
  ${({ isDisabled }) => isDisabled && `opacity:0.7; cursor:not-allowed`};
`;

export const HeaderInDrawerBody = styled.h6`
  /* font-size: 0.875rem; */
  font-weight: 500;
  color: ${colors.gray5};
`;

export const HeaderInfo = styled.div`
  width: 100%;
  padding: 0.5rem 0 1rem 0;
`;
