import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IconArrow from "../icons/IconArrow";
import { twMerge } from "tailwind-merge";

type Props = {
  currentPage: number;
  setCurrentPage?: (number: number) => void;
  itemCount: number;
  showPageAmount: number;
  buttonTheme?: "default" | "circle";
};

export default function Pagination({
  currentPage,
  setCurrentPage,
  itemCount,
  showPageAmount,
  buttonTheme = "default",
}: Props) {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(search);
      params.set(name, value);
      return params.toString();
    },
    [search],
  );

  const buttonClass = {
    default: "rounded-lg",
    circle: "rounded-full",
  }[buttonTheme];

  const pageArray = Array.from({ length: showPageAmount }, (_, i) => {
    return Math.ceil(currentPage / showPageAmount - 1) * showPageAmount + i + 1;
  });
  const totalPage = Math.ceil(itemCount / showPageAmount);

  return (
    <div className="flex justify-center gap-1">
      <button
        disabled={!(pageArray[0] > 1)}
        className={twMerge(
          "flex h-9 w-9 items-center justify-center border hover:border-gray-200 hover:bg-gray-50 disabled:bg-gray-50 disabled:opacity-30",
          buttonClass,
        )}
        onClick={() => {
          setCurrentPage && setCurrentPage(pageArray[0] - 10);
          if (!setCurrentPage) {
            navigate(pathname + "?" + createQueryString("page", (pageArray[0] - 10).toString()));
          }
        }}
      >
        <IconArrow className="h-5 w-5 rotate-180" />
      </button>
      <div className="flex items-center gap-1">
        {pageArray.map((page) => {
          if (page > totalPage) return;
          return (
            <button
              key={page}
              className={twMerge(
                "h-9 w-9 border text-sm font-semibold text-gray-600 hover:border-gray-200 hover:bg-gray-50",
                currentPage === page && "bg-primary-500 border border-blue-100 bg-blue-50 font-bold text-blue-600",
                buttonClass,
              )}
              onClick={() => {
                setCurrentPage && setCurrentPage(page);
                if (!setCurrentPage) {
                  navigate(pathname + "?" + createQueryString("page", page.toString()));
                }
              }}
            >
              {page}
            </button>
          );
        })}
      </div>
      <button
        disabled={!(pageArray[pageArray.length - 1] < totalPage)}
        className={twMerge(
          "flex h-9 w-9 items-center justify-center border hover:border-gray-200 hover:bg-gray-50 disabled:bg-gray-50 disabled:opacity-30",
          buttonClass,
        )}
        onClick={() => {
          setCurrentPage && setCurrentPage(pageArray[pageArray.length - 1] + 1);
          if (!setCurrentPage) {
            navigate(pathname + "?" + createQueryString("page", (pageArray[pageArray.length - 1] + 1).toString()));
          }
        }}
      >
        <IconArrow className="h-5 w-5" />
      </button>
    </div>
  );
}
