import styled from "@emotion/styled";
import { ImageArr, WarnMessageBox } from "../../../components/styled";
import { colors } from "../../../components/styled/common";
import { breakPoints } from "../../../components/styled/media";
import {
  DetailHeaderTitle,
  DetailsInfo,
  FlexAlignBox,
  StepShadowBox,
  UpdateIconBox,
} from "../../../components/styled/webApp";

export const LocationWithHeader = styled(FlexAlignBox)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
  @media ${breakPoints.phones} {
    margin-bottom: 1.25rem;
  }
`;

export const RequestDetailTitle = styled(DetailHeaderTitle)`
  width: calc(100% - 17.5rem);
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;

export const LocationDetail = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${colors.mainBlue};
  span {
    word-break: keep-all;
  }
  svg {
    margin: 4px 4px 0 0;
  }
  @media ${breakPoints.desktop} {
    span {
      width: calc(100% - 1.25rem);
    }
  }
`;

export const HeaderButtonBox = styled.div`
  @media ${breakPoints.mobile} {
    display: none;
  }
`;

export const RequestActionBox = styled(StepShadowBox)`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-bottom: 0;
  color: ${colors.gray4};
  > div {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    :first-of-type {
      border-bottom: 1px solid ${colors.gray1};
    }
  }
`;

export const ActionClickBox = styled(UpdateIconBox)`
  font-size: 1rem;
  font-weight: 500;
  svg {
    font-size: 1.25rem;
    margin-left: 4px;
  }
`;

export const FlexibleText = styled.textarea<{ h: number }>`
  width: 100%;
  resize: none;
  border: none;
  min-height: 96px;
  height: ${({ h }) => `${h}px`};
  @media ${breakPoints.mobile} {
    min-height: 72px;
  }
`;

export const CommentHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5;
  padding-bottom: 1.5rem;
  @media ${breakPoints.mobile} {
    font-size: 1.2rem;
  }
`;

export const CommentListBox = styled.div`
  min-height: calc(100vh - 780px);
  .comment-box:last-of-type {
    border-bottom: none;
  }
  @media ${breakPoints.tablet} {
    min-height: calc(100vh - 764px);
  }
`;

export const WriteCommentBox = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.gray2};
  @media ${breakPoints.mobile} {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
  }
`;

export const EditStatusBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.gray2};
  font-size: 1rem;
  padding: 8px 1rem;
  color: ${colors.gray4};
  background-color: ${colors.gray1};
  svg {
    cursor: pointer;
    font-size: 1.25rem;
  }
`;

const AdminTextArea = styled.textarea`
  font-size: 16px;
  resize: none;
  padding: 12px;
  color: ${colors.gray4};
  width: 100%;
  border: 1px solid ${colors.gray3};
  border-radius: 8px;
  ::placeholder {
    color: ${colors.gray2};
  }
`;

export const AdminTextAreaBox = styled.div`
  font-size: 16px;
  padding: 1.25rem 1rem;
  color: ${colors.gray4};
  width: 100%;
  background-color: #fff;
  border-radius: 0 0 1rem 1rem;
  display: flex;
  align-items: flex-end;
  svg {
    cursor: pointer;
    font-size: 2rem;
    color: ${colors.gray3};
  }
  textarea {
    width: 100%;
    resize: none;
    margin: 2px 0;
    padding: 0 1rem 0 1rem;
  }
  input {
    display: none;
  }
  textarea::placeholder {
    color: ${colors.gray2};
  }
  @media ${breakPoints.mobile} {
    padding: 1.5rem 1rem;
    svg {
      font-size: 1.75rem;
    }
  }
`;

export const AdminMemoArea = styled(AdminTextArea)`
  padding: 0;
  border: none;
  border-radius: 0;
  height: calc(100% - 2rem);
`;

export const RegisteredComment = styled.div`
  color: ${colors.gray4};
  padding: 1.25rem 0;
  border-bottom: 1px solid ${colors.gray2};
  svg {
    font-size: 1.25rem;
  }
  textarea {
    font-size: 16px;
    margin-top: 8px;
    width: 100%;
    resize: none;
  }
  :first-of-type {
    padding: 0 0 1.25rem 0;
  }
  @media ${breakPoints.mobile} {
    padding: 1.2rem 0;
    :first-of-type {
      padding: 0 0 1.2rem 0;
    }
  }
`;

export const AdminCommentImgBox = styled(ImageArr)<{ length: string }>`
  padding: ${({ length }) => (length === "true" ? "0 1rem 1rem 1rem" : 0)};
  @media ${breakPoints.mobile} {
    padding: ${({ length }) =>
      length === "true" ? " 0 0.5rem 0.5rem 0.5rem" : 0};
  }
`;

export const DetailHistory = styled(DetailsInfo)`
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.5rem 0;
  > div {
    font-size: 0.875rem;
    font-weight: 400;
    padding-top: 4px;
  }
  :first-of-type {
    padding-top: 0;
  }
  :last-of-type {
    margin-bottom: 2rem;
  }
`;

export const ComplaintWarnBox = styled(WarnMessageBox)`
  font-size: 1rem;
  margin-top: 12px;
  align-items: flex-start;
  svg {
    margin: 4px 4px 0 0;
  }
  div {
    word-break: keep-all;
    width: calc(100% - 1.5rem);
  }
  @media ${breakPoints.mobile} {
    svg {
      margin: 3px 4px 0 0;
    }
  }
`;
