import { Field, Radio, RadioGroup } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import { Dispatch, SetStateAction } from "react";

type Props = {
  data: { name: string; value: string; disabled?: boolean }[];
  getValue: string;
  setState: Dispatch<SetStateAction<string>>;
} & React.HTMLAttributes<HTMLDivElement>;

export default function CustomRadioGroup({ data, getValue, setState, className }: Props) {
  return (
    <RadioGroup className={className} value={getValue} onChange={setState} aria-label="Server size">
      {data.map((item) => (
        <Field key={item.name} disabled={item.disabled}>
          <Radio value={item.value} className={"border-0 ring-0"}>
            {({ checked, disabled }) => (
              <div className={twMerge("flex cursor-pointer items-center gap-2", disabled && "cursor-default")}>
                <span
                  className={twMerge(
                    "flex size-6 items-center justify-center rounded-full border border-gray-300",
                    checked ? "bg-blue-600" : "bg-white",
                    disabled && "bg-gray-100",
                  )}
                >
                  {checked && <span className="size-3 rounded-full bg-white" />}
                </span>

                <span className="text-base font-normal text-gray-700">{item.name}</span>
              </div>
            )}
          </Radio>
        </Field>
      ))}
    </RadioGroup>
  );
}
