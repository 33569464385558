import { useMutation, useQuery } from "@tanstack/react-query";
import { BaseResponse } from "../lib/api/queries/commons/types";
import { http } from "../lib/http";
import { httpV2 } from "../lib/httpV2";
import { queryClient } from "..";

type Params = {
  page?: number;
};

type MemberResponse = {
  _id: string;
  name: string;
  email: string;
  phoneNumber: string;
  userType: "master" | "manager" | "normal";
  createdAt: string;
  status: boolean;
};

export const useMembersQuery = ({ page = 1 }: Params) => {
  return useQuery({
    queryKey: ["/testData2", page],
    queryFn: async () =>
      http.get<BaseResponse<MemberResponse[]>>("http://localhost:3000/testdata.json", {
        page,
      }),
  });
};

type RequestMemberRequest = {
  buildingId: string;
  owner?: string;
  master?: string;
  normal?: string;
  searchText?: string;
  page?: number;
  limit?: number;
};

type CertificationRequestResponse = {
  _id: string;
  type: string;
  details: {
    status: string;
    action_date: Date;
    _id: string;
  };
  createdAt: Date;
  buildingId: string;
  name: string;
  email: string;
  phoneNumber: string;
  buildingName: string;
};

type OwnerRequestResponse = {
  type: "owner";
  ownerRoomInfo: {
    _id: string;
    dong: string;
    ho: string;
  };
} & CertificationRequestResponse;

type CompanyRequestResponse = {
  type: "company";
  companyRoomInfos: Array<{
    _id: string;
    dong: string;
    ho: string;
  }>;
  authType: "master" | "normal";
  companyId: string;
  companyName: string;
  companyType: "office" | "store" | "residential" | "etc";
} & CertificationRequestResponse;

type RequestMemberResponse = {
  totalCount: number;
  certificationCount: number;
  certificationRequests: Array<OwnerRequestResponse | CompanyRequestResponse>;
};

export const useRequestMemberQuery = (
  { buildingId, owner, master, normal, searchText, page = 1, limit = 10 }: RequestMemberRequest,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ["/certifications-request", buildingId, owner, master, normal, searchText, page, limit],
    queryFn: async () =>
      httpV2.get<RequestMemberResponse>("/certifications-request", {
        buildingId,
        owner,
        master,
        normal,
        searchText,
        page,
        limit,
      }),
    enabled,
  });
};

type UpdateRequest = {
  certifications: Array<{
    certificationId: string;
    status: "approved" | "rejected";
  }>;
};

type UpdateResponse = {
  certificationId: string;
  status: number;
  message: string;
}[];

export const useUpdateRequestMemberMutation = () => {
  return useMutation({
    mutationFn: (certifications: UpdateRequest) => {
      return httpV2.patch<UpdateRequest, UpdateResponse>("/certifications-request", certifications);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["/certifications-request"]);
    },
  });
};
