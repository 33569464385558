import { HTMLAttributes } from "react";

export type Props = object & HTMLAttributes<HTMLOrSVGElement>;

export default function IconSearch(props: Props) {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16667 3.125C5.82995 3.125 3.125 5.82995 3.125 9.16667C3.125 12.5034 5.82995 15.2083 9.16667 15.2083C12.5034 15.2083 15.2083 12.5034 15.2083 9.16667C15.2083 5.82995 12.5034 3.125 9.16667 3.125ZM1.875 9.16667C1.875 5.13959 5.13959 1.875 9.16667 1.875C13.1937 1.875 16.4583 5.13959 16.4583 9.16667C16.4583 13.1937 13.1937 16.4583 9.16667 16.4583C5.13959 16.4583 1.875 13.1937 1.875 9.16667Z"
        fill="#D1D5DB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7246 13.7246C13.9686 13.4805 14.3644 13.4805 14.6084 13.7246L17.9418 17.0579C18.1859 17.302 18.1859 17.6977 17.9418 17.9418C17.6977 18.1859 17.302 18.1859 17.0579 17.9418L13.7246 14.6084C13.4805 14.3644 13.4805 13.9686 13.7246 13.7246Z"
        fill="#D1D5DB"
      />
    </svg>
  );
}
