import { Dispatch, SetStateAction } from "react";
import * as S from "../../../components/styled";
import "react-datepicker/dist/react-datepicker.css";
import { IRegularCar } from "../../../lib/types/Imodels";
import moment from "moment";
import { format_progress } from "../../../lib/utils/dataFormat";
import { twMerge } from "tailwind-merge";

export default function VehicleItem(props: { el: IRegularCar; setIsModalOpen: Dispatch<SetStateAction<boolean>> }) {
  const onOpenRegisterCard = () => {
    if (!props.el) return;

    props.setIsModalOpen(true);
    sessionStorage.setItem("car_register", String(props.el._id));
  };

  return (
    <div
      className="cursor-pointer border-b border-gray-400 transition-all duration-75 hover:bg-blue-50 hover:opacity-75"
      onClick={onOpenRegisterCard}
    >
      <S.FixedTitle style={{ width: "25%", textAlign: "center" }}>{props.el.tenant_name}</S.FixedTitle>
      <div className="w-1/5">{props.el.car_number}</div>
      <div style={{ width: "25%" }}>{moment.utc(props.el.registered_date).format("YYYY. MM. DD. HH:mm")}</div>
      <div className={twMerge("w-1/5", props.el.progress_type === "approval_requested" && "text-blue-600")}>
        {format_progress(props.el.progress_type)}
      </div>
      <div className="w-1/5">{props.el.is_paid ? "유료" : "무료"}</div>
    </div>
  );
}
