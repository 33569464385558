import { AiOutlineDelete } from "@react-icons/all-files/ai/AiOutlineDelete";
import { MdEdit } from "@react-icons/all-files/md/MdEdit";
import { MinimunBox } from "../styled/webApp";
import { MouseEvent, Dispatch, SetStateAction, useContext } from "react";
import styled from "@emotion/styled";
import { breakPoints } from "../styled/media";
import { keyframes } from "@emotion/react";
import { colors } from "../styled/common";
import { GlobalContext } from "../../App";

interface IActionBottomSheet {
  onEditAction: (e: MouseEvent<HTMLLIElement>) => void;
  isOpen: boolean;
  // idx: number;
  // refs: ForwardedRef<HTMLDivElement>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onDeleteAction: (e: MouseEvent<HTMLLIElement>) => void;
}

// Bottomsheet 추가 컴포넌트 만들 예정, css 임시 적용
export const HideSheet = styled.div<{ isOpen: boolean }>`
  position: relative;
  @media ${breakPoints.mobile} {
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    width: 100vw;
    background: rgba(0, 0, 0, 0.1);
    height: ${({ isOpen }) => (isOpen ? "100vh" : 0)};
    position: fixed;
    top: 0;
    left: 0;
    transition: opacity 0.5s ease;
  }
`;

export const silde_to_up = keyframes`
  from {
    bottom: -11rem;
    min-height: 0;
  }
  to {
    bottom: 0px;
    min-height: 8rem;
  }
`;

export const BottomSheetBox = styled(MinimunBox)`
  font-size: 16px;
  li {
    padding: 0.5rem;
  }
  @media ${breakPoints.mobile} {
    li {
      padding: 1rem;
    }
    li:first-of-type {
      border-radius: 1.25rem 1.25rem 4px 4px;
    }
    li:last-of-type {
      border-radius: 4px 4px 1.25rem 1.25rem;
    }
    width: 100%; //bottomsheet css
    border-radius: 1.25rem 1.25rem 0 0;
    position: fixed;
    bottom: 0;
    top: auto;
    left: 0;
    animation: ${silde_to_up} 0.5s;
  }
  @media ${breakPoints.phones} {
    font-size: 14px;
    li {
      padding: 0.75rem;
      svg {
        font-size: 1.25rem;
      }
    }
  }
`;

export const BottomUList = styled.ul<{ AppToken: string | null }>`
  .cancle {
    display: none;
  }
  @media ${breakPoints.mobile} {
    .cancle {
      display: block;
      text-align: center;
      border: 1px solid ${colors.gray2};
      margin: 8px;
      width: calc(100% - 16px);
    }
  }
  @media ${breakPoints.phones} {
    .cancle {
      display: block;
      text-align: center;
      border: 1px solid ${colors.gray2};
      margin: 4px;
      width: calc(100% - 8px);
    }
  }
  ${({ AppToken }) => AppToken && `padding-bottom:34px`}
`;

export default function ActionBottomSheet(props: IActionBottomSheet) {
  const { AppToken } = useContext(GlobalContext);
  const onCloseModal = (e: MouseEvent) => {
    e.stopPropagation();
    props.setIsOpen(false);
  };

  return (
    <>
      {/* <MdMoreHoriz /> */}
      <HideSheet isOpen={props.isOpen} onClick={onCloseModal} />
      <BottomSheetBox isOpen={props.isOpen}>
        <BottomUList AppToken={AppToken ? AppToken : null}>
          <li onClick={props.onEditAction}>
            <MdEdit />
            수정
          </li>
          <li onClick={props.onDeleteAction}>
            <AiOutlineDelete />
            삭제
          </li>
          <li
            className="cancle"
            onClick={onCloseModal}
            style={{ borderRadius: "4px" }}
          >
            취소
          </li>
        </BottomUList>
      </BottomSheetBox>
    </>
  );
}
