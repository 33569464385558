import { useToast } from "@chakra-ui/react";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import { useVoteEarlyEndMutation } from "../../../requests/vote";

type Props = {
  title: string;
  voteId: string;
  isOpen: boolean;
  onClose: () => void;
};

export default function VoteEarlyEndModal({ isOpen, onClose, title, voteId }: Props) {
  const toast = useToast();
  const { mutateAsync } = useVoteEarlyEndMutation();

  return (
    <ConfirmModal
      title={"투표 마감"}
      isOpen={isOpen}
      onClose={onClose}
      width={488}
      onClickSave={() => {
        toast.promise(mutateAsync(voteId), {
          success: { position: "top", title: "투표가 조기 마감되었습니다." },
          error: { position: "top", title: "투표 조기 마감에 실패했습니다." },
          loading: { position: "top", title: "투표를 조기 마감 중입니다." },
        });
        onClose();
      }}
    >
      <div className="flex flex-col gap-3">
        <p className="text-gray-700">
          현재 모든 대상자가 서명을 완료한 상태로, 투표를 조기 마감할 수 있어요. 해당 투표건을 조기 마감 처리할까요?
        </p>
        <p className="rounded-[10px] bg-gray-50 p-3 text-gray-700">{title}</p>
        <p className="text-red-500">* 투표 마감 후에는 다시 ‘진행상태’로 되돌릴 수 없어요.</p>
      </div>
    </ConfirmModal>
  );
}
