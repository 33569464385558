import { InputProps } from "@chakra-ui/react";
import { InputStyle } from "../styled";

export const ContentInputs: React.FC<InputProps> = ({ ...props }) => {
  return (
      <InputStyle
        maxW="496px"
        variant="unstyled"
        autoComplete="off"
        {...props}
      />
  );
};

export default ContentInputs;
