//chakra default
export const btn = {
  padding: "0.5rem 0.75rem",
  borderRadius: 0,
  fontWeight: 500,
  height: "auto",
  lineHeight: "1.5",
};

export const m_btn = {
  height: "auto",
  fontSize: "1rem",
  fontWeight: 500,
  padding: "0.75rem 1.25rem",
  borderRadius: "0.5rem",
  lineHeight: "1.5",
  svg: {
    fontSize: "1.25rem",
    marginRight: "0.5rem",
  },
};

// 버튼 하나만 단독으로, 다른 텍스트, 컴포넌트들과 함께
export const styled_btn = {
  fontSize: "15px",
  margin: 0,
};

export const tag = {
  borderRadius: "2px",
  fontSize: "12px",
  fontWeight: 600,
};

export const text = {
  fontSize: "1rem",
  lineHeight: "1.5",
};
