import { Button, Text, useDisclosure } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import * as C from "../../../components/styled/index";
import { CalendarRange } from "../../../components/datePicker";
import MoreContentInputs from "../../../components/Input/contents";
import UploadImages from "./UploadImage";
import { useContext, useEffect, useState } from "react";
import { limitContent, onLimitTextNum } from "../../../lib/utils/format";
import { useMutation } from "@tanstack/react-query";
import { fetchClient } from "../../../lib/api/axios";
import { create_meal_plan, update_meal_plan } from "../../../lib/api/queries/urls";
import moment from "moment";
import { useUploadFile } from "../../../lib/api/queries/commons";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import { BaseMutation } from "../../../lib/api/queries/commons/types";
import { IMeal } from "../../../lib/types/Imodels";
import useUserAuth from "../../../components/hooks/useUserAuth";
import FullPageSpinner from "../../../components/Spinner";
import NotFound from "../../not-found";
import GetAuthorized from "../../../components/Authrized";
import { AxiosError } from "axios";
import { toast } from "../../../lib/utils/toast";
import { GlobalContext } from "../../../App";

type IMutate = {
  isEdit: boolean;
  pictures: string[];
  cafeteria_id?: string;
};

const CreateMealPlan = ({ isEdit, data }: BaseMutation<IMeal | void>) => {
  const navigate = useNavigate();
  const { plannerId } = useParams();
  const auth = useUserAuth("notice");
  const { buildInfo } = useContext(GlobalContext);
  const hasFunc = buildInfo?.services.CAFETERIA?.enabled;
  const [range, setRange] = useState<[Date | null, Date | null]>([null, null]);
  const [title, setTitle] = useState("");
  const [files, setFiles] = useState<File[] | null>(null);
  const [origin, setOrigin] = useState<string[]>([]);
  const [contents, setContents] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!isEdit || !data) return;
    setRange([new Date(data.start_date), new Date(data.end_date)]);
    setTitle(data.title);
    setContents(data.body);
    setOrigin(data.pictures);
  }, [isEdit, data]);

  const mutation = useMutation((newObj: IMutate) => {
    const { isEdit, ...rest } = newObj;
    return fetchClient
      .post(isEdit ? update_meal_plan : create_meal_plan, {
        title,
        body: contents,
        start_date: range[0] ? moment(range[0]).format("YYYY-MM-DD") : "",
        end_date: range[1] ? moment(range[1]).format("YYYY-MM-DD") : "",
        ...rest,
      })
      .then(() => {
        toast.success({
          title: `식단표 ${isEdit ? "수정" : "등록"} 완료`,
          description: `식단표가 성공적으로 ${isEdit ? "수정" : "등록"}되었습니다. 식단표 조회 페이지로 이동합니다.`,
        });
        navigate("/cafeteria");
      })
      .catch((err: AxiosError) => console.error(err));
  });
  const onCreateMeal = () => {
    if (!range[0] || !range[1] || !title)
      return toast.warning({
        title: "알림",
        description: "식단표 등록을 위해 제목과 날짜는 필수 입력 사항입니다.",
      });

    if (mutation.isLoading) return;
    const newObj: IMutate = { isEdit, pictures: origin || [] };
    if (isEdit) newObj.cafeteria_id = plannerId;
    if (files && files.length !== 0) {
      useUploadFile(files)
        .then((res) => {
          newObj.pictures = [...(origin || []), ...res.data.data];
          mutation.mutate(newObj);
        })
        .catch(() =>
          toast.warning({
            title: "알림",
            description:
              "일시적으로 식단표 등록에 실패했습니다. 새로고침 후에도 동일한 오류가 반복될 경우 오피스너 담당자에게 문의해주세요.",
          }),
        );
    } else {
      mutation.mutate(newObj);
    }
  };

  if (hasFunc === undefined || !auth) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;
  if (auth !== "edit") return <GetAuthorized />;
  return (
    <C.Wrapper>
      <Text variant="static">
        <C.LeftIcons onClick={() => navigate(-1)} />
        식단표 {isEdit ? "수정" : "등록"}
      </Text>
      <C.NewPostBox>
        <C.NewPost>
          <C.PostTitle>날짜</C.PostTitle>
          <C.DateWithIconBox>
            <DatePicker
              locale={ko}
              selectsRange={true}
              startDate={range[0]}
              endDate={range[1]}
              dateFormat="yyyy-MM-dd"
              onChange={(date) => setRange(date)}
              customInput={<CalendarRange value={String(range)} />}
            />
          </C.DateWithIconBox>
        </C.NewPost>
      </C.NewPostBox>
      <C.NewPostBox>
        <C.NewPost>
          <C.PostTitle>제목</C.PostTitle>
          <MoreContentInputs
            style={{ margin: "0" }}
            value={title || ""}
            placeholder="제목을 50자 이내로 입력해주세요."
            onChange={(e) => onLimitTextNum(e, setTitle)}
          />
        </C.NewPost>
      </C.NewPostBox>
      <C.NewPostBox>
        <C.NewPost>
          <C.PostTitle>이미지</C.PostTitle>
          <UploadImages isEdit={isEdit} files={files} setFiles={setFiles} origin={origin} setOrigin={setOrigin} />
        </C.NewPost>
      </C.NewPostBox>
      <C.NewPostBox>
        <C.NewPost>
          <C.PostTitle>내용</C.PostTitle>
          <C.BasicTextArea
            h="94px"
            value={contents || ""}
            placeholder="원산지 또는 입주자에게 추가로 안내해야 할 내용을 입력해주세요."
            onChange={(e) => {
              const isLimit = limitContent(e.target.value);
              if (!isLimit) return;
              setContents(e.target.value);
            }}
          />
        </C.NewPost>
      </C.NewPostBox>
      <C.Footerbutton>
        <Button variant="bgBlue" onClick={onOpen}>
          {isEdit ? "수정" : "등록"}하기
        </Button>
        <ConfirmModal
          close="등록하기"
          isOpen={isOpen}
          onClose={onClose}
          onClickCancle={onClose}
          onClickSave={onCreateMeal}
          title={`식단표 ${isEdit ? "수정" : "등록"}`}
        >
          <Text>해당 식단표를 {isEdit ? "수정" : "등록"}할까요?</Text>
          <Text>
            {isEdit
              ? "수정된 내용으로 입주자 앱에 다시 전달됩니다."
              : "식단표를 등록하면 입주자 앱에도 함께 등록됩니다."}
          </Text>
        </ConfirmModal>
      </C.Footerbutton>
    </C.Wrapper>
  );
};

export default CreateMealPlan;
