import {
  Text,
  Box,
  Tag,
  Flex,
  AccordionItem,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Button,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import * as C from "../../../../components/styled";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { ISurveyResult } from "../types";
import { format_survey, format_survey_to, format_type } from "../../../../lib/utils/dataFormat";
import moment from "moment";
import { prepare } from "../../../../lib/theme/toast";
import CancleModal from "../../../../components/modal/CancelModal";
import { useEffect, useState } from "react";
import useUserAuth from "../../../../components/hooks/useUserAuth";
import { colors } from "../../../../components/styled/common";
import { FlexibleTextArea } from "../../../../components/styled/webApp";
import { useRoomType } from "../../../../lib/utils";

const SurveyBody = styled(FlexibleTextArea)<{ isImage?: string }>`
  width: ${({ isImage }) => (isImage ? "calc(100% - 100px)" : "100%")};
`;

const SurveyImage = styled.div`
  margin: 4px 0;
  width: 88px;
  height: 88px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
`;

const ResultBox = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const QuestionBox = styled(Flex)`
  width: 100%;
  background-color: ${colors.gray1};
  align-items: center;
  border: 1px solid ${colors.gray3};
  padding: 8px 12px;
`;

const AnswerBox = styled.div<{ isBestQuestion?: boolean }>`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${colors.gray3};
  align-items: center;
  border-top: none;
  padding: 8px 12px;
  width: 100%;
  ${({ isBestQuestion }) => isBestQuestion && `color:#2563EB`};
`;

const EssayAnswer = styled(Accordion)`
  border-color: #fff;
  border: 1px solid ${colors.gray3};
  border-top: none;
  button {
    padding: 8px 12px;
  }
  button:hover {
    background: none;
  }
  .chakra-accordion__panel {
    padding: 6px 12px;
  }
`;

export default function SurveyResultUI(props: ISurveyResult) {
  const toast = useToast();
  const navigate = useNavigate();
  const auth = useUserAuth("survey");
  const room_type = useRoomType();
  const { isOpen, onOpen, onClose } = useDisclosure(); //설문 삭제 확인 모달
  const [isMaxIndex, setIsMaxIndex] = useState<{ maxCount: number }[]>([]);
  const survey_body = document.querySelector("#survey_body");

  useEffect(() => {
    if (!props.data || props.data?.questions_answers?.length === 0) return;
    props.data?.questions_answers?.map((el) => {
      const countList: number[] = [];
      el.answers.map((answer) => {
        countList.push(answer.participated_num === 0 ? -1 : answer.participated_num);
      });
      // console.log(countList);
      setIsMaxIndex((prev) => [
        ...prev,
        {
          maxCount: el.question_type === "essay_question" ? -1 : Math.max(...countList),
        },
      ]);
    });
  }, [props.data]);

  return (
    <C.Wrapper>
      <Text variant="static">
        <C.LeftIcons onClick={() => navigate(-1)} />
        설문 내용
      </Text>
      <C.DetailViewHeader>
        <C.DetailTitleBox>
          <div>
            <Tag ml="0" variant={props.data?.progress_type === "completed" ? "done" : "schedule"}>
              {format_survey(props.data?.progress_type || "")}
            </Tag>
          </div>
          <Text>{props.data?.title}</Text>
        </C.DetailTitleBox>
        {props.data?.progress_type === "completed" && (
          <C.TitleAlign>
            <Button onClick={() => toast({ ...prepare })} variant="blueLine">
              참여리스트 다운로드
            </Button>
            {auth === "edit" && (
              <>
                <Button variant="delete" onClick={onOpen}>
                  삭제
                </Button>
                <CancleModal
                  isOpen={isOpen}
                  onClose={onClose}
                  onClickCancel={onClose}
                  onClickSave={props.onDeleteSurvey}
                >
                  <Text>설문을 삭제할까요?</Text>
                  <Text>삭제된 데이터는 복구할 수 없습니다.</Text>
                </CancleModal>
              </>
            )}
          </C.TitleAlign>
        )}
      </C.DetailViewHeader>
      <C.DetailInfoBox>
        {room_type.length > 1 && (
          <Text>
            설문대상{" "}
            <C.InfoBoxText>
              {props.data?.survey_tenant_to.length === room_type.length
                ? "전체"
                : format_type(props.data?.survey_tenant_to)}
              / {format_survey_to(props.data?.survey_person_to || "")}
            </C.InfoBoxText>
          </Text>
        )}
        <Text>
          상태 <C.InfoBoxText>{format_survey(props.data?.progress_type || "")}</C.InfoBoxText>
        </Text>
        <Text>
          설문기간
          {props.data && (
            <C.InfoBoxText>
              {moment.utc(props.data.start_time).format(" YYYY-MM-DD HH:mm")} ~{" "}
              {moment.utc(props.data.end_time).format(" YYYY-MM-DD HH:mm")}
            </C.InfoBoxText>
          )}
        </Text>
        <Text>
          작성자
          <C.InfoBoxText> {props.data?.user_name}</C.InfoBoxText>
        </Text>
        <Text>
          등록일
          {props.data && <C.InfoBoxText>{moment.utc(props.data?.createdAt).format(" YYYY-MM-DD HH:mm")}</C.InfoBoxText>}
        </Text>
        <Text>
          참여
          <C.InfoBoxText> {props.data?.count}</C.InfoBoxText>
        </Text>
      </C.DetailInfoBox>
      <C.DetailBody>
        <Flex justifyContent="space-between">
          <SurveyBody
            id="survey_body"
            isImage={props.data?.image}
            readOnly
            h={survey_body?.scrollHeight || 96}
            value={props.data?.body || ""}
          />
          {props.data?.image && (
            <SurveyImage>
              <img
                onClick={() => window.open(`${props.data?.image}`, "_blank")}
                src={props.data.image}
                alt="설문 이미지"
              />
            </SurveyImage>
          )}
        </Flex>
      </C.DetailBody>
      {props.data?.questions_answers?.map((ele, index) => (
        <ResultBox key={ele.question + String(index)}>
          <QuestionBox justifyContent="space-between">
            질문 {index + 1} : {ele.question}
            {ele.image && (
              <img
                onClick={() => window.open(`${ele.image}`, "_blank")}
                width="80px"
                height="80px"
                src={ele.image}
                alt="질문 이미지"
              />
            )}
          </QuestionBox>
          {ele.question_type !== "essay_question" ? (
            <>
              {ele.answers.map((el, idx) => (
                <Flex w="100%" key={el.answer + String(idx)}>
                  <AnswerBox>
                    • {el.answer}
                    {el.image && (
                      <img
                        onClick={() => window.open(`${el.image}`, "_blank")}
                        width="80px"
                        height="80px"
                        src={el.image}
                        alt="질문 이미지"
                      />
                    )}
                  </AnswerBox>
                  <AnswerBox isBestQuestion={isMaxIndex[index]?.maxCount === el.participated_num}>
                    {props.data?.count === 0 ? 0 : Math.round((el.participated_num / (props.data?.count || 0)) * 100)}%
                    (총 {props.data?.count}표 중 {el.participated_num}표)
                  </AnswerBox>
                </Flex>
              ))}
            </>
          ) : (
            <EssayAnswer allowToggle>
              <AccordionItem border="none">
                <AccordionButton
                  _expanded={{
                    color: ele.answers.length === 0 ? colors.gray5 : colors.mainBlue,
                    borderBottom: ele.answers.length === 0 ? "none" : "1px solid #9ca3af",
                  }}
                  cursor={ele.answers.length > 0 ? "pointer" : "auto"}
                >
                  <Box flex="1" textAlign="left">
                    • 답변 총 {ele.answers.length}개
                  </Box>
                  {ele.answers.length > 0 && <AccordionIcon />}
                </AccordionButton>
                {ele.answers.length > 0 && (
                  <AccordionPanel>
                    {ele.answers.map((el, index) => (
                      <Box p="6px 0" key={index}>
                        • {el.answer}
                      </Box>
                    ))}
                  </AccordionPanel>
                )}
              </AccordionItem>
            </EssayAnswer>
          )}
        </ResultBox>
      ))}
    </C.Wrapper>
  );
}
