import styled from "@emotion/styled";
import { QuestionMark } from "../../components/styled";
import { CSSProperties } from "react";
import { breakPoints } from "../styled/media";
import { colors } from "../styled/common";

const HoverBox = styled.div<{ isHide?: boolean }>`
  position: relative;
  :hover {
    div {
      display: block;
    }
  }
  @media ${breakPoints.desktop} {
    ${({ isHide }) => isHide && `display: none`};
  }
`;

const HoverBalloon = styled.div<{ right?: string }>`
  display: none;
  text-align: center;
  position: absolute;
  /* top: -55px;
  right: 0%;
  width: 328px; */
  top: -3.375rem;
  right: -12rem;
  width: 28rem;
  padding: 0.5rem;
  background-color: ${colors.gray1};
  border-radius: 28px;
  color: ${colors.gray4};
  font-weight: 400;
  :after {
    content: "";
    position: absolute;
    bottom: -20%;
    right: ${({ right }) => (right ? right : "43%")};
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: ${colors.gray1};
    border-bottom: 0;
    margin-left: -10px;
    margin-top: -10px;
  }
`;

const SmallQuestionMark = styled(QuestionMark)`
  font-size: large;
  margin-left: 4px;
  cursor: pointer;
`;

interface IQuestionMark {
  description: string;
  styled?: CSSProperties;
  after?: string;
  isHide?: boolean;
  svg?: CSSProperties;
}

export default function QuestionMarkHover({ description, styled, after, isHide, svg }: IQuestionMark) {
  return (
    <HoverBox isHide={isHide}>
      <HoverBalloon right={after} style={styled}>
        {description}
      </HoverBalloon>
      <SmallQuestionMark style={svg} />
    </HoverBox>
  );
}
