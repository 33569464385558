import { useState, useContext, ChangeEvent, useEffect } from "react";
import SubframePart from "../../../components/Main/subframePart";
import { MainActionBox, Required, ShadowBox } from "../../../components/styled/webApp";
import GrayDropdown from "../../../components/select/gray";
import GrayRadiusInput from "../../../components/Input/responsive";
import styled from "@emotion/styled";
import OwnerAddModal from "../../company/settings/units/ownerAddModal";
import { useDisclosure } from "@chakra-ui/react";
import { useRoomType } from "../../../lib/utils";
import { GlobalContext } from "../../../App";
import { fetchClient } from "../../../lib/api/axios";
import { AxiosError } from "axios";
import { IRoom } from "../../../lib/types/Imodels";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { register_room } from "../../../lib/api/queries/urls";
import { useNavigate } from "react-router-dom";
import { toast } from "../../../lib/utils/toast";

const SubmitButton = styled.button<{ validation: boolean }>`
  background-color: ${(props) => (props.validation ? "#2563EB" : "#9CB7F5")};
  color: #ffffff;
  width: 100%;
  height: 56px;
  border-radius: 10px;
  color: #fff;
  font-family: "Noto Sans CJK KR";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const AddOwnerContainer = styled.div`
  padding-top: 8px;
`;

const Line = styled.div`
  border-top: 1px solid #f4f4f5;
  margin-bottom: 8px;
`;

const AddOwnerButton = styled.button`
  color: #2563eb;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  float: right;
`;

const ExplainP = styled.p`
  color: #696f79;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const AccentSpan = styled.span`
  color: #f36151;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const SubTitle = styled.p`
  color: #111827;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
`;

const OwnerContents = styled.div<{ lastChild: boolean }>`
  width: 100%;
  height: 64px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  background: #fff;
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => (props.lastChild ? "0px" : "12px")};
`;

const OwnerName = styled.div`
  color: #111827;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
`;

const OwnerPhoneNumber = styled.div`
  color: #9ca3af;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
`;

interface owners {
  name: string;
  phone_number: string;
}

// 호실 생성
export default function RoomCreate() {
  const room_type_arr = useRoomType();
  const { buildInfo: data } = useContext(GlobalContext);
  const navigate = useNavigate();

  // 소유자가 필요없는 데 있기 때문에 삭제한 배열
  // useRoomType은 사용하는 곳이 많아 함부로 삭제하지 않음
  const roomTypeArr = room_type_arr.filter((v) => v.label !== "owner");

  const [types, setTypes] = useState<string>("");
  const [dong, setDong] = useState<string>("");
  const [floor, setFloor] = useState<string>("");
  const [ho, setHo] = useState<string>("");
  const [area, setArea] = useState<string>("");
  const [submitValidation, setSubmitValidation] = useState<boolean>(false);
  const [owners, setOwners] = useState<owners[]>([]);

  const { isOpen: isOwnerAdd, onOpen: onCustomCateogry, onClose: offOwnerAdd } = useDisclosure();

  // 유효성 검사
  const submitValidationCheck = () => {
    if (types.length < 1) return false;
    if (dong.length < 1) return false;
    if (floor.length < 1) return false;
    if (ho.length < 1) return false;
    if (area.length < 1) return false;

    return true;
  };

  useEffect(() => {
    setSubmitValidation(submitValidationCheck());
  }, [types, dong, floor, ho, area]);

  // 호실 추가 api
  const createRoomClick = () => {
    const room_type = room_type_arr.length <= 1 ? "office" : types;

    fetchClient
      .post<BaseResponse<IRoom>>(register_room, {
        room_type,
        dong,
        ho,
        floor,
        area: Number.parseFloat(area),
        owners: owners,
      })
      .then((res) => {
        toast.success({
          title: "신규 호실 등록 완료",
          description: `${res.data.data.dong}동 ${res.data.data.ho}호 호실 등록이 완료되었습니다.`,
        });
        navigate(-1);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) return toast.unAuthUser();
        if (err.response?.status === 400)
          return toast.warning({
            title: "호실 등록 오류",
            description: `기존에 존재하는 호실 정보입니다.`,
          });
      });
  };

  const addModalClick = () => {
    onCustomCateogry();
  };

  const ownerCancelClick = (index: number) => {
    const tempOwners = [...owners];
    tempOwners.splice(index, 1);
    setOwners(tempOwners);
  };

  const dongChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDong(e.target.value);
    setFloor("");
  };

  const floorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFloor(e.target.value);
  };

  const hoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHo(e.target.value);
  };

  // 호실용도 수정 이벤트
  const typeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setTypes(e.target.value);
  };

  // 면적 수정 이벤트
  const areaChange = (e: ChangeEvent<HTMLInputElement>) => {
    // 숫자 + . 만 가능한 유효성 검사 로직 추가하기
    setArea(e.target.value);
  };

  return (
    <SubframePart name={"신규 호실 추가"}>
      <MainActionBox>
        <Required>* 필수입력</Required>
        <ShadowBox>
          <div>
            <h4>
              호실의 정보를 입력해주세요. (동/층/호) <span>*</span>
            </h4>
            <div style={{ display: "flex", gap: "8px", marginBottom: "12px" }}>
              <GrayDropdown w="100%" value={dong} _onChange={dongChange}>
                <option value="" disabled>
                  동
                </option>
                {data?.dong_floor.map((el) => (
                  <option key={el._id} value={el.dong}>
                    {el.dong}
                  </option>
                ))}
              </GrayDropdown>
              <GrayDropdown w="100%" value={floor} _onChange={floorChange}>
                <option value="" disabled>
                  층
                </option>
                {data?.dong_floor
                  .filter((el) => el.dong === dong)?.[0]
                  ?.floor.map((el) => (
                    <option key={el} value={el}>
                      {el}층
                    </option>
                  ))}
              </GrayDropdown>
              <GrayRadiusInput
                _style={{ width: "8rem" }}
                // disabled={true}
                value={ho}
                placeholder="호"
                _onChange={hoChange}
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <h4>
                호실용도를 선택해주세요. <span>*</span>
              </h4>
              <GrayDropdown
                w="100%"
                value={types}
                // disabled={data.auth === "마스터"}
                _onChange={typeChange}
              >
                <option value="" disabled>
                  호실용도
                </option>
                {roomTypeArr.map((el) => (
                  <option value={el.label} key={el.id}>
                    {el.value}
                  </option>
                ))}
              </GrayDropdown>
            </div>
            <h4>
              전용 면적을 입력해주세요. (단위 ㎡) <span>*</span>
            </h4>
            <GrayRadiusInput
              _style={{ width: "100%", marginBottom: "4px" }}
              // disabled={true}
              value={area}
              _onChange={areaChange}
            />
          </div>
        </ShadowBox>
        {data?.building_type === "knowledge_industry_center" && (
          <ShadowBox>
            <SubTitle>소유자</SubTitle>
            <div style={{ marginBottom: "16px" }}>
              <ExplainP>∙오피스너 가입 초대를 위해서 소유자 정보를 함께 입력해주세요.</ExplainP>
              <div>
                ∙<AccentSpan>등록된 소유자 연락처로 가입 초대 문자가 전송됩니다.</AccentSpan>
              </div>
            </div>
            {owners && owners.length > 0 && (
              <div style={{ marginBottom: "16px" }}>
                {owners.map((item, index) => {
                  return (
                    <OwnerContents key={index} lastChild={index === owners.length - 1}>
                      <div style={{ display: "flex", gap: "8px" }}>
                        <OwnerName>{item.name}</OwnerName>
                        <OwnerPhoneNumber>{item.phone_number}</OwnerPhoneNumber>
                      </div>
                      <div>
                        <img
                          src={"/cancel_icon.svg"}
                          alt={"cancel"}
                          onClick={() => ownerCancelClick(index)}
                          style={{ height: "22px", cursor: "pointer" }}
                        />
                      </div>
                    </OwnerContents>
                  );
                })}
              </div>
            )}
            <AddOwnerContainer>
              <Line />
              <AddOwnerButton onClick={addModalClick}>+ 소유자 등록</AddOwnerButton>
            </AddOwnerContainer>
          </ShadowBox>
        )}
        <SubmitButton onClick={createRoomClick} validation={submitValidation} disabled={!submitValidation}>
          호실 추가하기
        </SubmitButton>
      </MainActionBox>
      {isOwnerAdd && <OwnerAddModal ownerList={owners} setOwnerList={setOwners} isOpen={true} onClose={offOwnerAdd} />}
    </SubframePart>
  );
}
