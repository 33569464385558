import { useNavigate } from "react-router-dom";
import { useState } from "react";
import GrayColorSelect from "../../components/select";
import FullPageSpinner from "../../components/Spinner";
import { useGetFaqList } from "./queries";
import Button from "../../components/button/Button";
import IconWhitePlus from "../../components/icons/IconWhitePlus";
import moment from "moment";
import useUserAuth from "../../components/hooks/useUserAuth";
import GetAuthorized from "../../components/Authrized";

export const faqs = ["공용시설", "사무공간", "주차", "이용정책", "기타"]; //고정 항목

export default function ResourceListPage() {
  const navigate = useNavigate();

  const [categories, setCategories] = useState("");
  const { data } = useGetFaqList(categories);

  const auth = useUserAuth("notice");
  const isEdit = auth === "edit";

  if (!data) return <FullPageSpinner />;
  if (auth === "no_auth") return <GetAuthorized />;

  return (
    <div className="w-full px-6 py-10">
      <div className="border-b border-gray-300 pb-5">
        <h2 className="text-2xl font-semibold leading-7 text-gray-900">자료실</h2>
      </div>
      <div className="flex w-full justify-between py-5">
        <GrayColorSelect
          value={categories}
          _style={{ textAlign: "left" }}
          _onChange={(e) => setCategories(e.target.value)}
        >
          <option value="">전체</option>
          {faqs.map((el) => (
            <option key={el}>{el}</option>
          ))}
        </GrayColorSelect>
        {isEdit && (
          <Button
            iconElement={<IconWhitePlus className="h-5 w-5" />}
            designSchema={"primary"}
            buttonText={"새로운 자료 등록"}
            size={"md"}
            onClick={() => navigate("/resources/create")}
          />
        )}
      </div>
      <div className="w-full text-base">
        <div className="flex w-full items-center justify-between gap-2 border-y border-gray-500 px-4 py-3">
          <div className="w-2/5">카테고리</div>
          <div className="w-3/5">제목</div>
          <div className="w-2/5">업데이트일시</div>
        </div>
        {data.map((el) => (
          <div
            key={String(el._id)}
            className="flex w-full cursor-pointer items-center justify-between gap-2 overflow-hidden text-ellipsis whitespace-nowrap border-b border-gray-400 bg-white px-4 py-3 text-left transition-all duration-75 hover:bg-blue-50 hover:opacity-75"
            onClick={() => navigate(`/resources/${el._id}`)}
          >
            <div className="w-2/5">{el.category}</div>

            <div className="inline-block w-3/5 overflow-hidden text-ellipsis ">{el.question}</div>
            <div className="w-2/5">{moment.utc(el.updatedAt).format("YYYY-MM-DD HH:mm")}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
