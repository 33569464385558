import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { fetchClient } from "../../../lib/api/axios";
import { register_card, register_card_list } from "../../../lib/api/queries/urls";
import { register_card_detail_key, register_card_key } from "../../../lib/api/queries/keys";
import { usePagination } from "@ajna/pagination";
import PaginationUI from "../../../components/pagination";
import { BaseResponse, IsearchInput } from "../../../lib/api/queries/commons/types";
import { ICard, ICardList } from "../../../lib/types/Imodels";
import useUserAuth from "../../../components/hooks/useUserAuth";
import { AxiosError } from "axios";
import styled from "@emotion/styled";
import RegistrationListUI from "./_view/presenter";
import TenantCardModal from "./_view/TanantCard";
import { IRegistListType } from "../../company/commons/type";
import { toast } from "../../../lib/utils/toast";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
`;

export default function CompanyCardPage() {
  const auth = useUserAuth("tenant");

  const { currentPage, setCurrentPage } = usePagination({
    initialState: { currentPage: 1 },
  });
  const [allPage, setAllPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [isReset, setIsReset] = useState(false);
  const [types, setTypes] = useState("");
  const [dong, setDong] = useState("");
  const [status, setStatus] = useState("");
  const [inputs, setInputs] = useState<IsearchInput>({
    id: "search_ho",
    value: "",
  });

  const { data, refetch } = useQuery(
    [register_card_key, currentPage, isReset],
    () =>
      fetchClient
        .get<BaseResponse<ICardList[]>>(register_card_list, {
          params: {
            limit: 10,
            page: currentPage,
            room_type: types,
            registration_type: status,
            dong,
            search_ho: inputs.id === "search_ho" ? inputs.value : undefined,
            search_name: inputs.id === "search_name" ? inputs.value : undefined,
          },
        })
        .then((res) => {
          setAllPage(res.data.total_page_num || 0);
          setTotal(res.data.total_num);
          return res.data.data;
        }),
    {
      // enabled: false,
      enabled: auth === "read" || auth === "edit",
    },
  );

  const onShowResult = () => {
    currentPage === 1 ? refetch() : setCurrentPage(1);
  };

  const onClickRefresh = () => {
    setTypes("");
    setStatus("");
    setDong("");
    setInputs({ id: "search_ho", value: "" });
    currentPage === 1 ? setIsReset((prev) => !prev) : setCurrentPage(1);
  };

  const ids = sessionStorage.getItem("access_card");
  const [isModalOpen, setIsModalOpen] = useState(ids ? true : false);

  const { data: card } = useQuery(
    [register_card_detail_key, ids],
    () =>
      fetchClient
        .post<BaseResponse<ICard>>(register_card, {
          resident_card_id: ids,
        })
        .then((res) => {
          return res.data.data;
        }),
    {
      enabled: isModalOpen,
      retry: 0,
      onError: (err: AxiosError) => {
        setIsModalOpen(false);
        sessionStorage.removeItem("access_card");
        if (err.response?.status === 504)
          toast.warning({
            title: "알림",
            description: "정보가 삭제되었거나 확인할 수 없습니다.",
          });
        return undefined;
      },
    },
  );

  useEffect(() => {
    if (ids === null) return;

    setIsModalOpen(true);
  }, [ids]);

  const props: IRegistListType = {
    types,
    setTypes,
    dong,
    setDong,
    status,
    setStatus,
    inputs,
    setInputs,
    onShowResult,
    onClickRefresh,
    data,
    total,
    setIsModalOpen,
  };

  return (
    <div className="flex min-h-screen w-full flex-col p-6">
      <RegistrationListUI {...props} />
      <PaginationUI page={currentPage} setPage={setCurrentPage} allPage={allPage} />
      {card && ids === String(card._id) && (
        <ModalWrapper style={{ padding: 0 }}>
          <TenantCardModal
            isOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
              sessionStorage.removeItem("access_card");
            }}
            data={card}
          />
        </ModalWrapper>
      )}
    </div>
  );
}
