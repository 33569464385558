import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as S from "../styled";
import { breakPoints } from "../../../components/styled/media";
import { colors, form } from "../../../components/styled/common";
import { FaRegCheckCircle } from "@react-icons/all-files/fa/FaRegCheckCircle";

const FormBox = styled.div`
  width: 540px;
  min-height: 100vh;
  padding: 40px 20px;
  background-color: ${colors.gray1};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
  @media ${breakPoints.phones} {
    width: 100%;
    line-height: 1.3;
    font-size: 14px;
  }
`;

const FormTitle = styled.div`
  width: 100%;
  padding-top: 20px;
  font-size: ${form.fontSize.title};
  font-weight: 500;
  svg {
    font-size: 40px;
    margin-bottom: 8px;
    color: ${colors.mainBlue};
  }
  @media ${breakPoints.phones} {
    padding-top: 0px;
    svg {
      font-size: 32px;
    }
  }
`;

const FormBody = styled.div`
  background-color: ${colors.whiteColor};
  border-radius: 8px;
  padding: 28px 20px 60px;
  margin-top: 20px;
  margin-bottom: 16px;
  font-weight: 500;
  width: 100%;
  > p {
    padding-bottom: 20px;
  }
  @media (max-width: 540px) {
    margin-top: 16px;
    padding: 24px 20px 48px;
    > p {
      padding-bottom: 16px;
    }
  }
  @media ${breakPoints.phones} {
    padding: 24px 16px 48px;
  }
`;

const OrderBox = styled.div`
  width: 100%;
  min-height: 76px;
  display: flex;
  font-size: ${form.fontSize.fs16};
  font-weight: 700;
  position: relative;
  z-index: 10;
  &::after {
    content: "";
    position: absolute;
    top: 20px;
    left: 12px;
    width: 1px;
    height: 108px;
    background-color: ${colors.skyBlue1};
    z-index: 1;
  }
  &:last-child::after {
    display: none;
  }
  @media (max-width: 540px) {
    min-height: 68px;
  }
`;

const OrderNum = styled.div`
  min-width: 28px;
  height: 28px;
  padding: 0 8px;
  margin-right: 8px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.skyBlue1};
  color: ${colors.mainBlue};
  @media ${breakPoints.phones} {
    height: 24px;
    min-width: 24px;
  }
`;

const StepDesc = styled.p`
  color: ${colors.gray4};
  font-size: ${form.fontSize.fs14};
  font-weight: 400;
  padding-bottom: 32px;
  @media (max-width: 540px) {
    padding-bottom: 24px;
  }
`;

const OrderTitle = styled.p`
  padding: 2px 0;
  @media ${breakPoints.phones} {
    padding: 2px 0 4px;
  }
`;

export default function RegisterFormResult() {
  const navigate = useNavigate();

  return (
    <S.FormWrapper>
      <FormBox>
        <FormTitle>
          <FaRegCheckCircle />
          <p>
            입주카드가
            <br />
            제출되었습니다.
          </p>
        </FormTitle>
        <FormBody>
          <p>카드 제출 후에는 이렇게 진행됩니다!</p>
          <OrderBox>
            <OrderNum>1</OrderNum>
            <div>
              <OrderTitle>관리센터의 입주카드 확인 절차</OrderTitle>
            </div>
          </OrderBox>
          <OrderBox>
            <OrderNum>2</OrderNum>
            <div>
              <OrderTitle>대표 연락처로 오피스너 초대</OrderTitle>
              <StepDesc>
                오피스너는 입주자 전용 앱으로 공지, 일정 관리, 시설 예약 등의
                편리한 오피스 서비스를 이용할 수 있습니다.
              </StepDesc>
            </div>
          </OrderBox>
          <OrderBox>
            <OrderNum>3</OrderNum>
            <div>
              <OrderTitle>가입 초대 문자 내 입주코드로 앱 가입</OrderTitle>
              <StepDesc>
                초대 문자를 받으셨다면,
                <br />
                ‘오피스너’ 앱을 다운로드 후 가입해주세요. 입주코드는 초대
                문자에서 확인하세요! <br />
                <span style={{ color: "#FB4667" }}>
                  * 관리자의 가입 승인 절차가 있을 수 있습니다.
                </span>
              </StepDesc>
            </div>
          </OrderBox>
        </FormBody>
        <S.SubmitButton onClick={() => navigate(`/forms`)}>
          처음으로 돌아가기
        </S.SubmitButton>
      </FormBox>
    </S.FormWrapper>
  );
}
