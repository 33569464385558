import { useContext } from "react";
import { GlobalContext } from "../../App";

const useUserAuth = (key: string) => {
  const { user } = useContext(GlobalContext);
  if (!user || !user.auth_id) return undefined;

  const newObj = user.auth_id.details.filter((el) => el.key === key)[0] || {
    value: "no_auth",
  };
  const isAuth = newObj.value;

  if (!isAuth) return undefined; //새로고침이나 데이터 받아올 시간동안 보여질 것
  return isAuth;
};

export default useUserAuth;
