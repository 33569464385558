import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Select,
  UseModalProps,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import * as C from "../../../components/styled/index";
import * as N from "./styled";
import moment from "moment";
import { AxiosError } from "axios";
import { queryClient } from "../../..";
import { ChangeEvent, useRef, useState } from "react";
import { ContentBox, DoubleColumn, TitleBox } from "../../company/units/styled";
import { RowBox } from "../../../components/modal/table/singleRow";
import SmallInputs from "../../../components/Input/shortText";
import { CalendarRange } from "../../../components/datePicker";
import SearchInTenantList, { ISelectTenant } from "../../../components/modal/search/Tenant";
import { onLimitNum, onLimitTextNum } from "../../../lib/utils/format";
import { success, unAuthUser, warning } from "../../../lib/theme/toast";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import { useMutation } from "@tanstack/react-query";
import { create_car, upload_images } from "../../../lib/api/queries/urls";
import { fetchClient } from "../../../lib/api/axios";
import { parking_history_keys } from "../../../lib/api/queries/keys";

type IStatus = {
  payment: string;
  date: Date | null;
};

export default function VehicleRegistration(props: UseModalProps) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure(); //입주자 검색
  const { isOpen: isReg, onOpen: onReg, onClose: offReg } = useDisclosure(); //차량 등록 확인
  const [selected, setSelected] = useState<ISelectTenant>();
  const [name, setName] = useState("");
  const [num, setNum] = useState("");
  const [type, setType] = useState("");
  const [vehicle, setVehicle] = useState("");
  const fileRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const [status, setStatus] = useState<IStatus>({ payment: "", date: null });

  const onChangeContract = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target.files;

    if (target === null) return;
    if (
      Array.from(target).filter((el) => ["png", "jpeg", "jpg", "gif", "pdf"].includes(el.type.split("/")[1])).length ===
      0
    )
      return toast({
        ...warning,
        title: "파일 업로드 오류",
        description: `첨부 서류 양식은 GIF, JPG, JPEG, PNG, PDF 형식만 가능합니다. 확인 후 다시 시도해주세요.`,
      });

    const newArr = [...Array.from(files || []), ...Array.from(target)];
    setFiles(newArr.map((el) => el));

    e.target.value = "";
  };

  const mutation = useMutation((newArr: string[]) =>
    fetchClient
      .post(create_car, {
        tenant_id: selected?._id,
        user_name: name,
        user_phone_number: num,
        car_model: type,
        car_number: vehicle.replaceAll(" ", ""),
        payment_method: "person",
        car_registration_files: newArr,
        is_paid: status.payment,
        start_date: status.date === null ? "" : `${moment(status.date).format("YYYY-MM-DDT00:00:00")}Z`,
      })
      .then((res) => {
        toast({
          ...success,
          title: "정기 주차 등록 완료",
          description: "정기 주차 차량이 등록되었습니다.",
        });
        queryClient.invalidateQueries([parking_history_keys]);
        offReg();
        props.onClose();
        return res.data.data;
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400)
          return toast({
            ...warning,
            title: "신규 차량 등록 오류",
            description:
              "이미 등록되어있는 차량입니다. 같은 차량의 신규 등록은 기존 차량 해지 이후 등록이 가능합니다. 차량 번호를 확인 후 다시 등록해주세요.",
          });
        if (err.response?.status === 401) return toast({ ...unAuthUser });
        toast({
          ...warning,
          title: "신규 차량 등록 오류",
          description: "신규 차량 등록에 실패했습니다. 정보를 다시 확인 후 등록해주세요.",
        });
      }),
  );

  const onBeforeCreate = () => {
    if (!name || !num || !vehicle || !status.date || !status.payment)
      return toast({
        ...warning,
        title: "알림",
        description:
          "신규 차량 등록을 위해 차주 이름, 차주 연락처, 차량 번호, 유/무료 여부, 서비스 시작일자는 필수 입력 사항입니다.",
      });

    onReg();
  };

  const upload = useMutation((data: FormData) =>
    fetchClient
      .post(upload_images, data)
      .then((res) => mutation.mutate(res.data.data))
      .catch((err) => console.log(err)),
  );

  const onCreateNewOne = () => {
    if (mutation.isLoading) return;

    const formdata = new FormData();
    files !== null && files.map((el) => formdata.append("files", el));

    if (files === null || files.length === 0) return mutation.mutate([]);
    upload.mutate(formdata);
  };

  return (
    <Modal size="xl" variant="contents" isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent minW="912px">
        <ModalHeader>신규 차량 등록</ModalHeader>
        <ModalBody>
          <div>
            <Text variant="title">1. 차주 정보</Text>
            <DoubleColumn>
              <TitleBox>입주사명</TitleBox>
              <RowBox>
                <SmallInputs
                  w="280px"
                  isReadOnly
                  placeholder="입주사 검색에서 선택해주세요."
                  value={selected ? selected.name : ""}
                />
                <Button variant="basic" onClick={onOpen}>
                  입주사 검색
                </Button>
              </RowBox>
            </DoubleColumn>
            {isOpen && <SearchInTenantList isOpen={isOpen} onClose={onClose} setSelected={setSelected} />}
            <DoubleColumn>
              <TitleBox>차주이름</TitleBox>
              <ContentBox>
                <SmallInputs
                  value={name || ""}
                  placeholder="차주 이름을 입력해주세요."
                  onChange={(e) => onLimitTextNum(e, setName)}
                />
              </ContentBox>
            </DoubleColumn>
            <DoubleColumn>
              <TitleBox>차주 연락처</TitleBox>
              <ContentBox>
                <SmallInputs
                  value={num || ""}
                  placeholder="연락처를 숫자로 입력해주세요."
                  onChange={(e) => onLimitNum(e, setNum)}
                />
              </ContentBox>
            </DoubleColumn>
          </div>
          <C.IntervalBox>
            <Text variant="title">2. 차량 정보</Text>
            <DoubleColumn>
              <TitleBox>차종</TitleBox>
              <ContentBox>
                <SmallInputs
                  value={type || ""}
                  placeholder="차종을 입력해주세요."
                  onChange={(e) => onLimitTextNum(e, setType)}
                />
              </ContentBox>
              <TitleBox>차량번호</TitleBox>
              <ContentBox>
                <SmallInputs
                  value={vehicle || ""}
                  placeholder="차량 번호를 입력해주세요."
                  onChange={(e) => onLimitTextNum(e, setVehicle)}
                />
              </ContentBox>
            </DoubleColumn>
            <div className="flex min-h-[56px] items-center border border-gray-200 py-1 text-gray-400">
              <TitleBox>차량등록증/이면계약서</TitleBox>
              <div className="flex w-full items-start">
                <N.FileTextBox value={files} style={{ display: "flex" }}>
                  {files === null || files.length === 0 ? (
                    <div className="flex cursor-pointer items-center py-1 pr-2">
                      파일 선택에서 차량 등록증/이면 계약서를 추가해주세요.
                    </div>
                  ) : (
                    <>
                      {Array.from(files).map((el, index) => (
                        <div
                          className="flex cursor-pointer items-center py-1 pr-2"
                          key={index}
                          onClick={() => setFiles(files.filter((_, idx) => idx !== index))}
                        >
                          {el.name}
                          <N.FileText />
                        </div>
                      ))}
                    </>
                  )}
                </N.FileTextBox>
                <input
                  type="file"
                  ref={fileRef}
                  multiple={true}
                  style={{ display: "none" }}
                  onChange={onChangeContract}
                />
                <Button variant="basic" onClick={() => fileRef.current?.click()}>
                  파일 선택
                </Button>
              </div>
            </div>
          </C.IntervalBox>
          <div className="mt-8">
            <p className="text-lg font-bold">3. 등록 상태</p>
            <DoubleColumn>
              <TitleBox>결제 수단</TitleBox>
              <ContentBox>개인이 직접 결제</ContentBox>
              <TitleBox>유/무료 여부</TitleBox>
              <ContentBox>
                <C.SelectBox>
                  <Select
                    id="payment"
                    variant="default"
                    onChange={(e) => setStatus({ ...status, [e.target.id]: e.target.value })}
                  >
                    <option value="">유/무료 선택</option>
                    <option value="true">유료</option>
                    <option value="false">무료</option>
                  </Select>
                </C.SelectBox>
              </ContentBox>
            </DoubleColumn>
            <DoubleColumn>
              <TitleBox>서비스 시작 일자</TitleBox>
              <N.CalendarBoxInParking>
                <DatePicker
                  locale={ko}
                  selected={status.date}
                  dateFormat="yyyy. MM. dd"
                  onChange={(date: Date | null) => setStatus({ ...status, date })}
                  customInput={
                    <CalendarRange defaultValue="시작일 선택" style={{ width: "184px" }} value={String(status.date)} />
                  }
                />
              </N.CalendarBoxInParking>
            </DoubleColumn>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="cancel" onClick={props.onClose}>
            취소
          </Button>
          <Button variant="bgBlue" onClick={onBeforeCreate}>
            정기 주차 차량 등록하기
          </Button>
          <ConfirmModal
            isOpen={isReg}
            close="등록하기"
            title="차량 등록"
            onClose={onClose}
            onClickCancle={offReg}
            blockScrollOnMount={false}
            onClickSave={onCreateNewOne}
          >
            <Text>해당 차량을 정기 주차 차량으로 등록합니다.</Text>
            <Text>등록하시겠습니까?</Text>
          </ConfirmModal>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
