import { Dispatch, SetStateAction, useState } from "react";
import { DeleteIcon, ImageArr, ShowImages } from "../../components/styled";
import SkeletonImage from "../Skeleton/images";

interface IMultipleView {
  auth: string;
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  fileUrlArr: string[];
  setFileUrlArr: Dispatch<SetStateAction<string[]>>;
}

export default function MultipleImageView(props: IMultipleView) {
  const [loading, setLoading] = useState(true); //이미지 로딩 대기

  const newArr = [...props.fileUrlArr, ...(props.files || [])];

  const onDeleteFiles = (idx: number, el: File | string) => {
    if (typeof el === "string") {
      const stringArr = props.fileUrlArr.filter((_, index) => index !== idx);
      props.setFileUrlArr(stringArr.length === 0 ? [] : stringArr);
    } else {
      if (props.files === null) return;
      const temp = newArr.filter((_, index) => index !== idx);
      const setArr: any = temp.filter((el) => typeof el !== "string");

      props.setFiles(setArr.length === 0 ? [] : setArr);
    }
  };

  return (
    <ImageArr>
      {newArr.map((el, index) => (
        <div key={index}>
          {loading && <SkeletonImage />}
          {props.auth === "edit" ? (
            <>
              <ShowImages
                // isLoading={loading}
                alt="이미지"
                src={typeof el !== "string" ? URL.createObjectURL(el) : el}
                onLoad={() => setLoading(false)}
              />
              <DeleteIcon onClick={() => onDeleteFiles(index, el)} />
            </>
          ) : (
            <a target="_blank" rel="noreferrer" href={typeof el !== "string" ? URL.createObjectURL(el) : el}>
              <ShowImages
                // isLoading={loading}
                alt="이미지"
                src={typeof el !== "string" ? URL.createObjectURL(el) : el}
                onLoad={() => setLoading(false)}
              />
            </a>
          )}
        </div>
      ))}
    </ImageArr>
  );
}
