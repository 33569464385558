import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { IMeal } from "../../../../lib/types/Imodels";
import { detail_meal_planner } from "../../../../lib/api/queries/urls";
import { detail_meal_planner_key } from "../../../../lib/api/queries/keys";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import CreateMealPlan from "../../new/page";
import useUserAuth from "../../../../components/hooks/useUserAuth";

export default function EditMealPlanner() {
  const navigate = useNavigate();
  const auth = useUserAuth("notice");
  const { plannerId } = useParams();

  const { data, isError } = useQuery(
    [detail_meal_planner_key, plannerId],
    () =>
      fetchClient
        .post<BaseResponse<IMeal>>(detail_meal_planner, {
          cafeteria_id: plannerId,
        })
        .then((res) => res.data.data)
        .catch(() => navigate(-1)),
    { enabled: auth === "edit" },
  );

  if (isError) return <></>;
  return <CreateMealPlan isEdit={true} data={data} />;
}
