import * as S from "./styled";
import { CircleDeleteIcon, InputWithTextBox } from "../../../../components/styled/webApp";
import { TimeRange } from "../../commons/styled";
import { Flex } from "@chakra-ui/react";
import { allTime } from "../../../../lib/utils/dataFormat";
import { ChangeEvent, Dispatch, memo, SetStateAction } from "react";
import { HiCheck } from "@react-icons/all-files/hi/HiCheck";
import { ITimeSet } from "../[publicId]/create/page";
import moment from "moment";
import { slots } from "../../commons/func";
import { IPeopleTime } from "../../../../lib/types/Imodels";
import GrayColorSelect from "../../../../components/select";
import { format_number } from "../../../../lib/utils/format";
import { toast } from "../../../../lib/utils/toast";

interface ITimeSlot {
  // time: string[];
  el: ITimeSet;
  timeArr: ITimeSet[];
  setTimeArr: Dispatch<SetStateAction<ITimeSet[]>>;
  interval: string;
}

//숫자만 1000명 제한 입력, 필요하면 format
const isValidNum = (e: ChangeEvent<HTMLInputElement>) => {
  // if (!isTrue) return;
  const valid = /^[0-9]+$/;
  const empty = /^$/;
  const str = e.target.value;

  if (!valid.test(str) && !empty.test(str)) return false;
  if (Number(str) > 1000) return false;
  return true;
};

const IntervalTimeSlot = ({ timeArr, el, setTimeArr, interval }: ITimeSlot) => {
  const optionTimeArr = interval === "30m" ? slots("00:00", "23:30") : allTime.slice(0, allTime.length - 1);

  const onChangeTime = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!interval)
      return toast.timeWarning({
        description: "시간 설정을 하기 전에 예약 받는 시간의 간격을 먼저 설정해주세요.",
      });

    const str = e.target.value;
    const newArr = { ...el };
    newArr.individual = false;
    newArr.time = [];

    let firstIdx = 0;
    let lastIdx = 0;
    let tempTimeArr: string[] = [];

    if (e.target.id === "end") {
      newArr.end = str;
      if (str === el.start) {
        newArr.time = [{ time: `2000-01-01T${str}Z`, people_num: newArr.people_num || 0 }];
        return setTimeArr(timeArr.map((ele) => (ele._id === newArr._id ? newArr : ele)));
      }

      firstIdx = allTime.findIndex((hour) => hour === newArr.start);
      lastIdx = allTime.findIndex((hour) => hour === str);
      tempTimeArr = interval === "1h" ? allTime.slice(firstIdx, lastIdx + 1) : slots(el.start, str);

      // tempTimeArr.forEach((ele) => {
      //   newArr.time.push({ time: ele, people_num: el.people_num || 0 });
      // });
      // setTimeArr(timeArr.map((ele) => (ele.id === newArr.id ? newArr : ele)));
    } else {
      newArr.start = str;
      if (str === el.end) {
        newArr.time = [{ time: `2000-01-01T${str}Z`, people_num: newArr.people_num || 0 }];
        return setTimeArr(timeArr.map((ele) => (ele._id === newArr._id ? newArr : ele)));
      }

      firstIdx = allTime.findIndex((hour) => hour === str);
      lastIdx = allTime.findIndex((hour) => hour === newArr.end);

      tempTimeArr = interval === "1h" ? allTime.slice(firstIdx, lastIdx + 1) : slots(str, el.end);
      // setTimeArr(timeArr.map((ele) => (ele.id === newArr.id ? newArr : ele)));
    }

    tempTimeArr.forEach((ele) => {
      newArr.time.push({
        time: `2000-01-01T${ele}Z`,
        people_num: el.people_num || 0,
      });
    });
    setTimeArr(timeArr.map((ele) => (ele._id === newArr._id ? newArr : ele)));
  };

  return (
    <div style={{ marginBottom: "0.5rem" }}>
      <TimeRange isDisabled={interval === "1d"} style={{ justifyContent: "space-evenly", position: "relative" }}>
        <S.SelectTime>
          시작
          <GrayColorSelect id="start" value={el.start} disabled={interval === "1d"} _onChange={(e) => onChangeTime(e)}>
            {optionTimeArr.map((time) => (
              <option key={time}>{time}</option>
            ))}
          </GrayColorSelect>
        </S.SelectTime>
        <span>-</span>
        <S.SelectTime>
          마지막
          <GrayColorSelect id="end" value={el.end} disabled={interval === "1d"} _onChange={(e) => onChangeTime(e)}>
            {optionTimeArr.map((el) => (
              <option key={el}>{el}</option>
            ))}
          </GrayColorSelect>
        </S.SelectTime>
        {timeArr[0]._id !== el._id && (
          <CircleDeleteIcon
            onClick={() => {
              const newArr = [...timeArr];
              setTimeArr(newArr.filter((ele) => ele._id !== el._id));
            }}
          />
        )}
      </TimeRange>
      <Flex mt="8px" justifyContent="space-between">
        <InputWithTextBox>
          총
          <input
            placeholder="10"
            value={el.people_num || 0}
            onChange={(e) => {
              const newArr = { ...el };
              // const isValid = isValidNum(e);
              // if (!isValid) return;
              const value = format_number(e.target.value);
              if (value === "false") return;
              newArr.people_num = Number(e.target.value);
              newArr.max_num = Number(e.target.value);
              if (interval === "1d") {
                newArr.time = [
                  {
                    time: `2000-01-01T00:00Z`,
                    people_num: Number(e.target.value),
                  },
                ];
              } else {
                newArr.time.forEach((el) => (el.people_num = Number(e.target.value)));
              }
              // console.log(newArr);
              setTimeArr(timeArr.map((ele) => (ele._id === el._id ? newArr : ele)));
            }}
          />
          명
        </InputWithTextBox>
        <S.HourActiveBox
          isDay={interval === "1d"}
          isActive={el.individual === undefined ? false : el.individual}
          onClick={() => {
            if (interval === "1d") return;
            const startTime = moment(`2000-01-01 ${el.start}`);
            const endTime = moment(`2000-01-01 ${el.end}`);
            if (el.start === el.end || startTime > endTime) return;
            const newArr = { ...el };
            newArr.individual = !el.individual;
            const newTimeSet: IPeopleTime[] = [];
            newArr.time.forEach((el) => {
              newTimeSet.push({
                // time: el.time.length > 5 ? getTimeOnString(el.time) : el.time,
                time: el.time,
                people_num: el.people_num || 0,
              });
            });
            newArr.time = newTimeSet;
            setTimeArr(timeArr.map((ele) => (ele._id === el._id ? newArr : ele)));
          }}
        >
          <HiCheck />
          시간별 설정
        </S.HourActiveBox>
      </Flex>
      {el.individual && (
        <S.SetUserBox isActive={true}>
          <S.MaximumBox>
            <span>{interval === "1h" ? "시간" : "30분"} 당 최대</span>
            <input
              type="text"
              value={el.max_num || 0}
              onChange={(e) => {
                if (!el.people_num) return alert("예약 가능한 총 인원을 먼저 입력해주세요.");
                const isValid = isValidNum(e);
                if (!isValid) return;
                // return toast({
                //   ...warning,
                //   title: "예약 인원 설정 오류",
                //   description: "총 예약 가능한 인원을 먼저 입력해주세요.",
                // });
                if (Number(e.target.value) > el.people_num)
                  return alert("개별 설정 인원은 총 인원보다 클 수 없습니다.");
                const newArr = { ...el };
                newArr.max_num = Number(e.target.value);
                newArr.time.forEach((ele) => (ele.people_num = Number(e.target.value)));
                setTimeArr(timeArr.map((ele) => (ele._id === el._id ? newArr : ele)));
              }}
            />
            <span>명</span>
          </S.MaximumBox>
          {el.time.map((ele, idx) => (
            <S.HourLimitBox key={ele.time}>
              {/* <span>{getTimeOnString(ele.time)}</span> */}
              <span>{moment.utc(ele.time).format("HH:mm")}</span>
              <input
                type="text"
                value={ele.people_num}
                onChange={(e) => {
                  if (!el.people_num) return alert("예약 가능한 총 인원을 먼저 입력해주세요.");
                  const isValid = isValidNum(e);
                  if (!isValid) return;
                  if (Number(e.target.value) > el.people_num)
                    return alert("개별 설정 인원은 총 인원보다 클 수 없습니다.");
                  const newObj = { ...el };
                  newObj.time[idx].people_num = Number(e.target.value);
                  setTimeArr(timeArr.map((obj) => (obj._id === newObj._id ? newObj : obj)));
                }}
              />
              <span>명</span>
            </S.HourLimitBox>
          ))}
        </S.SetUserBox>
      )}
    </div>
  );
};

export const MemoizedIntervalTimeSlot = memo(IntervalTimeSlot);
