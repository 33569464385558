import { useContext } from "react";
import { GlobalContext } from "../../../../App";
import { Button, Modal, ModalBody, ModalOverlay, ModalContent } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { fetchClient } from "../../../../lib/api/axios";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { send_message_to_owner_many } from "../../../../lib/api/queries/urls";
import { toast } from "../../../../lib/utils/toast";

const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const ModalTitle = styled.p`
  color: #111827;
  font-family: Noto Sans KR;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  justify-content: center;
`;

const SubmitButton = styled(Button)`
  display: flex;
  max-width: 128px;
  max-height: 48px;
  min-height: 48px;
  height: auto;
  padding: 20px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 1;
  border-radius: 8px;
  background-color: #2563eb;
  color: #ffffff;

  &:hover {
    background-color: #2563eb;
    box-shadow: none;
  }
  :hover[disabled] {
    background-color: #2563eb;
    box-shadow: none;
  }
`;

const CancelButton = styled.button`
  display: flex;
  max-width: 128px;
  width: 128px;
  max-height: 48px;
  min-height: 48px;
  height: auto;
  padding: 20px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 1;
  border-radius: 8px;
  color: #6b7280;
  display: flex;
  background: #f4f4f5;
`;

const ContentsP = styled.p`
  color: #111827;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

interface modalProps {
  onClose: () => void;
  isOpen: boolean;
}

const SendToOwnerModal = (props: modalProps) => {
  const { buildInfo: data } = useContext(GlobalContext);

  const useMessageToOwner = () => {
    return fetchClient.post<BaseResponse<string>>(send_message_to_owner_many);
  };

  const sendSmsClick = () => {
    useMessageToOwner().then(() => {
      toast.success({
        title: "가입 초대 문자 전송",
        description: "일괄 가입 초대 SMS가 전송되었습니다.",
      });
      props.onClose();
    });
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} variant="default" isCentered>
      <ModalOverlay />
      <ModalContent maxW="312px" maxH="216px">
        <ModalBody>
          <ModalHead>
            <ModalTitle>소유자 일괄 가입요청 보내기</ModalTitle>
          </ModalHead>
          <div style={{ marginBottom: "36px" }}>
            <ContentsP>미가입 상태의 소유자 전체에게 가입 초대 문자가 전송됩니다.</ContentsP>
          </div>
          <BottomContainer>
            <CancelButton onClick={() => props.onClose()}>취소</CancelButton>
            <SubmitButton w="100%" minW="128px" onClick={sendSmsClick}>
              전송하기
            </SubmitButton>
          </BottomContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SendToOwnerModal;
