import Input from "../../../components/Input/Input";
import CustomModal from "../../../components/CustomModal";
import { useForm } from "react-hook-form";
import { useUpdateCompanyMutation } from "../../../requests/company/useUpdateCompanyMutation";
import toast from "react-hot-toast";
import CustomToast from "../../../components/CustomToast";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  companyData: {
    companyId: string;
    name?: string;
    companyRegistrationNumber?: string;
    taxEmail?: string;
    fax?: string;
    ceoName?: string;
    phoneNumber?: string;
  };
};

type FormData = {
  name: string;
  companyRegistrationNumber: string;
  taxEmail: string;
  fax: string;
  ceoName: string;
  phoneNumber: string;
};

export default function CompanyInfoModifyModal({ isOpen, onClose, companyData }: Props) {
  const { companyId, name, companyRegistrationNumber, taxEmail, fax, ceoName, phoneNumber } = companyData;

  const { mutateAsync: updateCompany } = useUpdateCompanyMutation();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty, errors },
  } = useForm<FormData>({
    mode: "onChange",
  });

  const onSubmit = (data: FormData) => {
    toast.promise(updateCompany({ companyId, ...data }), {
      success: () => {
        onClose();
        return (
          <CustomToast
            iconType="check"
            title="입주사 정보 수정"
            description={`'${data.name}'의 정보가 변경되었습니다.`}
          />
        );
      },
      error: () => {
        onClose();
        return <CustomToast iconType="error" title="정보 수정 중 에러가 발생하였습니다." />;
      },
      loading: <CustomToast iconType="loading" title="Loading" />,
    });
  };

  if (!isOpen) return null;

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title="회사 정보 수정"
      required={true}
      exit={true}
      onSubmit={handleSubmit(onSubmit)}
      primaryButtonText="저장하기"
      primaryButtonDisable={(watch("name") ?? "").trim() === "" || Object.keys(errors).length !== 0}
      neutralButtonText="취소"
      onNeutralButtonClick={onClose}
    >
      <div className="flex w-[788px] flex-col gap-5 px-6 pb-4 pt-3">
        <div className="flex w-full flex-col gap-2">
          <div className="flex gap-1 font-semibold">
            <span className="text-gray-700">회사명</span>
            <span className="text-blue-600">*</span>
          </div>
          <div className="flex flex-col gap-1">
            <Input
              themeSize="md"
              placeholder="회사명을 입력해주세요"
              defaultValue={name?.trim()}
              {...register("name", {
                required: "회사명은 필수 입력 사항입니다.",
              })}
              onBlur={(e) => setValue("name", e.target.value.trim())}
              required
            />
            {errors.name && <p className="text-xs leading-normal text-red-500">{errors.name.message}</p>}
          </div>
        </div>

        <div className="flex w-full gap-5">
          <div className="flex w-1/2 flex-col gap-2">
            <div className="flex gap-1 font-semibold">
              <span className="text-gray-700">회사 대표자의 이름</span>
            </div>
            <Input
              themeSize="md"
              placeholder="대표자의 이름을 입력해주세요"
              defaultValue={ceoName?.trim()}
              {...register("ceoName")}
              onBlur={(e) => setValue("ceoName", e.target.value.trim())}
            />
          </div>
          <div className="flex w-1/2 flex-col gap-2">
            <div className="flex gap-1 font-semibold">
              <span className="text-gray-700">회사 대표자의 연락처</span>
            </div>
            <div className="flex flex-col gap-1">
              <Input
                themeSize="md"
                {...register("phoneNumber", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "연락처는 숫자만 입력해주세요.",
                  },
                })}
                type="number"
                placeholder="대표 연락처를 입력해주세요"
                defaultValue={phoneNumber?.trim()}
                onBlur={(e) => setValue("phoneNumber", e.target.value.trim())}
              />
              {errors.phoneNumber && (
                <p className="text-xs leading-normal text-red-500">{errors.phoneNumber.message}</p>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full gap-5">
          <div className="flex w-1/2 flex-col gap-2">
            <div className="flex gap-1 font-semibold">
              <span className="text-gray-700">세금계산서용 이메일</span>
            </div>
            <div className="flex flex-col gap-1">
              <Input
                themeSize="md"
                placeholder="세금계산서용 이메일을 입력해주세요"
                defaultValue={taxEmail?.trim()}
                {...register("taxEmail", {
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                    message: "이메일 형식으로 정확히 입력해주세요.",
                  },
                })}
                type="email"
                onBlur={(e) => setValue("taxEmail", e.target.value.trim())}
              />
              {errors.taxEmail && <p className="text-xs leading-normal text-red-500">{errors.taxEmail.message}</p>}
            </div>
          </div>
          <div className="flex w-1/2 flex-col gap-2">
            <div className="font-semibold">
              <span className="text-gray-700">사업자(주민)번호</span>
            </div>
            <div className="flex flex-col gap-1">
              <Input
                themeSize="md"
                {...register("companyRegistrationNumber", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "사업자번호는 숫자만 입력해주세요.",
                  },
                })}
                type="number"
                placeholder="사업자번호를 입력해주세요"
                defaultValue={companyRegistrationNumber?.trim()}
                onBlur={(e) => setValue("companyRegistrationNumber", e.target.value.trim())}
              />
              {errors.companyRegistrationNumber && (
                <p className="text-xs leading-normal text-red-500">{errors.companyRegistrationNumber.message}</p>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full gap-5">
          <div className="flex w-1/2 flex-col gap-2">
            <div className="font-semibold">
              <span className="text-gray-700">팩스번호</span>
            </div>
            <div className="flex flex-col gap-1">
              <Input
                themeSize="md"
                {...register("fax", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "팩스번호는 숫자만 입력해주세요.",
                  },
                })}
                type="number"
                placeholder="팩스번호를 입력해주세요"
                defaultValue={fax?.trim()}
                onBlur={(e) => setValue("fax", e.target.value.trim())}
              />
              {errors.fax && <p className="text-xs leading-normal text-red-500">{errors.fax.message}</p>}
            </div>
          </div>
          <div className="flex w-1/2 flex-col gap-2" />
        </div>
      </div>
    </CustomModal>
  );
}
