import reactHotToast from "react-hot-toast";
import CustomToast from "../../components/CustomToast";
import IconClose from "../../components/icons/IconClose";

export const toast = {
  success: ({ title = "성공", description = "처리 결과 성공" }) =>
    reactHotToast((t) => (
      <CustomToast
        iconType="check"
        title={title}
        description={description}
        sideButton={
          <button onClick={() => reactHotToast.dismiss(t.id)}>
            <IconClose className="size-5" />
          </button>
        }
      />
    )),
  warning: ({ title = "실패", description = "처리 결과 실패" }) =>
    reactHotToast((t) => (
      <CustomToast
        iconType="warning"
        title={title}
        description={description}
        sideButton={
          <button onClick={() => reactHotToast.dismiss(t.id)}>
            <IconClose className="size-5" />
          </button>
        }
      />
    )),
  prepare: () =>
    toast.warning({
      title: "알림",
      description: "해당 기능은 준비중입니다.",
    }),
  checkFileValidate: () =>
    toast.warning({
      title: "파일 등록 실패",
      description:
        "파일은 GIF, JPEG, JPG, PNG 형식으로 1장, 20MB 이하합니다. 파일 형식 확인 후 동일한 에러가 반복될 경우에는 오피스너 담당자에게 문의해주세요.",
    }),
  underFiveImage: ({
    description = "첨부 가능한 파일 갯수는 5개, 한 파일당 크기는 100MB 이하여야합니다. 첨부 파일 형식은 GIF, JPG, JPEG, PNG 형식만 가능합니다. 파일 확인 후 동일한 에러가 반복될 경우에는 오피스너 담당자에게 문의해주세요.",
  }) =>
    toast.warning({
      title: "파일 등록 실패",
      description: description,
    }),
  unAuthorized: () =>
    toast.warning({
      title: "알림",
      description: "오피스너 관리자 웹사이트 이용을 위해 관리자 계정으로 로그인을 해주세요.",
    }),
  unAuthUser: () =>
    toast.warning({
      title: "관리자 권한 오류",
      description: "해당 작업에 대한 권한이 없습니다. 관리자 권한 변경이 필요하다면 최고관리자에게 문의해주세요.",
    }),
  timeWarning: ({ description = "종료시간은 시작시간 이후로 설정해주세요." }) =>
    toast.warning({
      title: "시간 설정 오류",
      description: description,
    }),
  operationTimeWarning: ({ description = "운영시간과 예약 인원을 정확히 설정해주세요." }) =>
    toast.warning({
      title: "운영 시간 설정 안내",
      description: description,
    }),
  createCardWarning: () =>
    toast.warning({
      title: "입주카드 등록 오류",
      description: "일시적 오류로 등록에 실패했습니다. 다시 제출해주세요.",
    }),
};
