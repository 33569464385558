import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { UseModalProps } from "@chakra-ui/react";
import { StaticInput } from "../../../../components/Input/responsive";
import EditModalNoHeader from "../../../../components/modal/responsive/edit";
import { CustomSpins, WarnMessageBox } from "../../../../components/styled";
import { FiInfo } from "@react-icons/all-files/fi/FiInfo";
import { ShadowBox } from "../../../../components/styled/webApp";
import { auth, messaging } from "../../../../lib/firebase";
import { EmailAuthProvider, reauthenticateWithCredential, signOut, updatePassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { deleteToken } from "firebase/messaging";
import { FirebaseError } from "firebase/app";
import { IUser } from "../../../../lib/types/Imodels";
import { useMutation } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { password_update } from "../../../../lib/api/queries/urls";
import { useState } from "react";
import { toast } from "../../../../lib/utils/toast";

interface IPassword extends UseModalProps {
  isNeed: boolean;
  data: IUser;
}

const schema = yup
  .object()
  .shape({
    password: yup
      .string()
      .min(8, "비밀번호는 최소 8자 이상입니다.")
      .max(16, "비밀번호는 최대 16자 이하입니다.")
      .matches(/[0-9]/, "0-9사이 숫자가 1개 이상 필요합니다.")
      .matches(/[a-z]/, "영문 소문자가 1자 이상 필요합니다.")
      .matches(/[^\w]/, "특수문자가 1자 이상 필요합니다."),
    confirm: yup.string().oneOf([yup.ref("password"), null], "비밀번호가 일치하지 않습니다."),
  })
  .required();

export default function ChangedPassword(props: IPassword) {
  const { isOpen, onClose, ...rest } = props;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // firebase 확인 거치는 동안 스핀 돌아감

  const {
    handleSubmit,
    formState: { errors },
    register,
    // watch,
  } = useForm<FieldValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const mutation = useMutation((str: string) =>
    fetchClient
      .post<BaseResponse<IUser>>(password_update, {
        user_id: props.data._id,
        password: str,
      })
      .then((res) => res),
  );

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    console.log(data);
    setIsLoading(true);
    if (rest.isNeed) {
      const user = auth.currentUser;
      //관리자 본인 직접변경 -> firebase
      if (user !== null) {
        const credential = EmailAuthProvider.credential(user.email || "", data.prev);

        try {
          await reauthenticateWithCredential(user, credential);
          try {
            await updatePassword(user, data.password);

            toast.success({
              title: "관리자 비밀번호 변경 완료",
              description: `비밀번호가 변경되었습니다. 변경된 비밀번호로 재로그인해주세요.`,
            });
            setIsLoading(false);
            onClose();
            props.onClose();
            signOut(auth);
            messaging !== null && deleteToken(messaging);
            navigate("/");
          } catch (err) {
            setIsLoading(false);
            toast.warning({
              title: "관리자 비밀번호 변경 실패",
              description: `새로고침 후 다시 시도해주세요.`,
            });
          }
        } catch (err) {
          setIsLoading(false);
          if (err instanceof FirebaseError) {
            if (err.code === "MISSING_PASSWORD") {
              return toast.warning({
                title: "관리자 비밀번호 변경 실패",
                description: "현재 비밀 번호를 입력 후 새로운 비밀 번호를 설정해주세요.",
              });
            }
            if (err.code === "auth/wrong-password") {
              return toast.warning({
                title: "관리자 비밀번호 변경 실패",
                description: `현재 비밀번호를 확인 후 다시 시도해주세요.`,
              });
            }
          }
        }
      }
    } else {
      // 최고관리자 변경 -> api
      try {
        await mutation.mutateAsync(data.password);
        setIsLoading(false);
        onClose();
        toast.success({
          title: "관리자 비밀번호 변경",
          description: `해당 관리자의 비밀번호가 변경되었습니다.`,
        });
      } catch (err) {
        setIsLoading(false);
        toast.warning({
          title: "관리자 비밀번호 변경",
          description:
            "비밀번호 수정에 일시적으로 실패했습니다. 동일한 오류가 계속해서 반복될 경우 오피스너 담당자에게 문의해주세요.",
        });
      }
    }
  };

  return (
    <EditModalNoHeader value="변경" isOpen={isOpen} onClose={onClose} onClickUpdate={handleSubmit(onSubmit)}>
      {isLoading ? (
        <CustomSpins thickness="2px" speed="0.65s" size="md" style={{ top: "32%", left: "48%" }} />
      ) : (
        <form autoComplete="off">
          <ShadowBox isModal={true} isMore={true}>
            {rest.isNeed && (
              <div>
                <h4>현재 비밀번호를 입력해주세요.</h4>
                <StaticInput
                  type="password"
                  autoComplete="off"
                  placeholder="현재 비밀번호를 입력해주세요."
                  // _onChange={() => console.log("dd")}
                  {...register("prev")}
                />
              </div>
            )}
            <div>
              <h4>변경하실 새 비밀번호를 입력해주세요.</h4>
              <StaticInput
                type="password"
                autoComplete="off"
                placeholder="8자 이상의 영문, 숫자, 특수문자의 조합"
                // name='password'
                // onChange={(e) => console.log("")}
                {...register("password")}
              />
              {errors.password && (
                <WarnMessageBox>
                  <FiInfo />
                  <>{errors.password.message}</>
                </WarnMessageBox>
              )}
              <StaticInput
                type="password"
                autoComplete="off"
                style={{ marginTop: "12px" }}
                placeholder="새 비밀번호와 같은 비밀번호를 입력하세요."
                {...register("confirm")}
                // onChange={(e) => setValue("confirm", e.target.value)}
              />
              {errors.confirm && (
                <WarnMessageBox>
                  <FiInfo />
                  <>{errors.confirm.message}</>
                </WarnMessageBox>
              )}
            </div>
          </ShadowBox>
        </form>
      )}
    </EditModalNoHeader>
  );
}
