export default function BlueCheck() {
    return (
<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_16943_8507)">
<path d="M7.50091 13.9996L4.58424 11.0829C4.25924 10.7579 3.74258 10.7579 3.41758 11.0829C3.09258 11.4079 3.09258 11.9246 3.41758 12.2496L6.90924 15.7413C7.23424 16.0663 7.75924 16.0663 8.08424 15.7413L16.9176 6.91628C17.2426 6.59128 17.2426 6.07461 16.9176 5.74961C16.5926 5.42461 16.0759 5.42461 15.7509 5.74961L7.50091 13.9996Z" fill="#2563EB"/>
</g>
<defs>
<clipPath id="clip0_16943_8507">
<rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
    )
}