import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UseModalProps,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { queryClient } from "../../../..";
import GrayRadiusInput from "../../../../components/Input/responsive";
import { ShadowBox } from "../../../../components/styled/webApp";
import { fetchClient } from "../../../../lib/api/axios";
import { department_list_key } from "../../../../lib/api/queries/keys";
import { create_department, delete_department, update_department } from "../../../../lib/api/queries/urls";
import { IDeparts } from "../../../../lib/types/Imodels";
import { onLimitNum, onLimitText } from "../../../../lib/utils/format";
import { toast } from "../../../../lib/utils/toast";

interface ISetPermission extends UseModalProps {
  isEdit: boolean;
  el?: IDeparts;
}

type IDepartmentParams = {
  department_id?: string;
  name: string;
  phone_number: string;
};

const BodyInModal = styled(ModalBody)`
  padding: 0;
  font-size: 1rem;
`;

const FooterInModal = styled(ModalFooter)<{ isedit: string }>`
  padding: ${({ isedit }) => (isedit === "true" ? "1rem 7rem" : "1rem 1.5rem 1.5rem 1.5rem")};
  ${({ isedit }) => isedit === "true" && `border-top:1px solid #D1D5DB`};
`;

function DepartmentInModal({ isEdit, ...props }: ISetPermission) {
  // const { isOpen: isDel, onOpen: onDel, onClose: offDel } = useDisclosure(); 관리자 삭제
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");

  useEffect(() => {
    if (!props.el) return;

    setName(props.el.name);
    props.el.phone_number && setNumber(props.el.phone_number);
  }, [props.el]);

  const mutation = useMutation((data: IDepartmentParams) =>
    fetchClient
      .post(isEdit ? update_department : create_department, data)
      .then(() => {
        toast.success({
          title: `${isEdit ? "소속 수정" : "신규 소속 생성"}`,
          description: `${isEdit ? "소속 정보가 수정" : "새로운 소속이 추가"}되었습니다.`,
        });
        props.onClose();
        return queryClient.invalidateQueries([department_list_key]);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400)
          return toast.warning({
            title: "소속 설정",
            description: "소속 설정 시 이름은 필수 입력 항목입니다.",
          });
        if (err.response?.status === 402)
          return toast.warning({
            title: isEdit ? "소속 수정" : "소속 등록",
            description: "같은 소속이 이미 존재합니다. 소속명을 수정한 후 다시 시도해주세요.",
          });
      }),
  );

  const delMutation = useMutation(() =>
    fetchClient
      .post(delete_department, { department_id: props.el?._id })
      .then(() => {
        toast.success({
          title: "소속 삭제",
          description: `${props.el?.name}이 성공적으로 삭제되었습니다.`,
        });
        props.onClose();
        return queryClient.invalidateQueries([department_list_key]);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 402)
          return toast.warning({
            title: "소속 삭제",
            description: `${props.el?.name}에 소속된 관리자가 있어 삭제할 수 없습니다. 확인 후 다시 시도해주세요.`,
          });
      }),
  );

  const onDeleteDepartment = () => {
    if (delMutation.isLoading) return;
    delMutation.mutate();
  };

  const onSaveDepart = () => {
    if (mutation.isLoading) return;

    const newObj: IDepartmentParams = { name, phone_number: number };
    if (isEdit) newObj.department_id = String(props.el?._id);
    if (!name && !isEdit)
      return toast.warning({
        title: "소속 추가",
        description: "소속 추가시 이름은 필수 입력 항목입니다.",
      });

    mutation.mutate(newObj);
  };

  return (
    <>
      <Modal
        size="lg"
        variant="responsive"
        isOpen={props.isOpen}
        onClose={props.onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent style={{ padding: 0 }}>
          {!isEdit && <ModalHeader style={{ padding: "24px" }}>신규 소속</ModalHeader>}
          <BodyInModal>
            <ShadowBox isModal={true} isMore={true} style={{ padding: isEdit ? "" : "0 28px 28px 28px" }}>
              <div style={{ paddingBottom: "0.75rem" }}>
                <h4>소속명을 입력해주세요.</h4>
                <GrayRadiusInput
                  placeholder="소속명을 입력해주세요."
                  isLimited={true}
                  value={name}
                  _onChange={(e) => {
                    if (e.target.value.length > 30) return;
                    onLimitText(e, setName);
                  }}
                  // {...register("prev")}
                />
              </div>
              <div>
                <h4>소속의 대표 연락처를 입력해주세요.</h4>
                <GrayRadiusInput
                  placeholder="‘-’ 없이 입력해주세요."
                  value={number}
                  // _onChange={(e) => {
                  //   if (
                  //     isNaN(Number(e.target.value)) ||
                  //     e.target.value.length > 13
                  //   )
                  //     return;
                  //   setNumber(e.target.value);
                  // }}
                  _onChange={(e) => onLimitNum(e, setNumber)}
                  // {...register("prev")}
                />
              </div>
              {isEdit && (
                <div>
                  <Text variant="text_red" textDecoration="underline" cursor="pointer" onClick={onDeleteDepartment}>
                    소속 삭제
                  </Text>
                </div>
              )}
            </ShadowBox>
          </BodyInModal>
          <FooterInModal isedit={String(isEdit)}>
            <Button w="100%" mr="0.5rem" variant="m_gray" onClick={props.onClose}>
              취소
            </Button>
            <Button w="100%" variant="m_blue" onClick={onSaveDepart}>
              저장하기
            </Button>
          </FooterInModal>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DepartmentInModal;
