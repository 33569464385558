import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const loading = keyframes`
  0% {
    transform: translateX(0);
  }
  50%,100% {
    transform: translateX(80px);
  }
`;

const SkeletonBox = styled.div<{ size?: string }>`
  width: ${({ size }) => (size ? size : "80px")};
  height: ${({ size }) => (size ? size : "80px")};
  background-color: #ddd;
  position: relative;
  overflow: hidden;
  .skeleton-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: 100%;
    background: linear-gradient(to right, #ddd, #c9c9c9, #ddd);
    animation: ${loading} 1.3s infinite linear;
  }
`;

export default function SkeletonImage({ size }: { size?: string }) {
  return (
    <SkeletonBox size={size}>
      <div className="skeleton-item" />
    </SkeletonBox>
  );
}
