import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import { warning } from "../../../lib/theme/toast";

interface IControl extends ModalProps {
  checkedItems: string[];
  onSave: () => void;
  isError: boolean;
  isChecked: string;
  desc: string;
}

const ControlTemperture = ({ children, ...props }: IControl) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure(); //온도 조절 확인 모달

  const onCheckBeforeOpen = () => {
    if (props.isChecked === "direct" && !props.desc)
      return toast({
        ...warning,
        title: "알림",
        description: "사용자들에게 전송될 답변을 정확히 입력했는 지 확인해주세요.",
      });
    onOpen();
  };

  return (
    <>
      <Modal size="xl" variant="contents" isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <ModalContent mt="5.75rem">
          <ModalHeader>온도 업데이트</ModalHeader>
          <ModalBody style={{ paddingTop: "20px" }}>{children}</ModalBody>
          <ModalFooter>
            <Button onClick={props.onClose} variant="cancel">
              취소
            </Button>
            <Button onClick={onCheckBeforeOpen} variant="bgBlue">
              보내기
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ConfirmModal
        isOpen={props.isOpen ? isOpen : props.isOpen}
        close="보내기"
        title="온도 업데이트"
        onClose={onClose}
        onClickCancle={onClose}
        blockScrollOnMount={false}
        onClickSave={props.onSave}
        styleConfig={{ marginTop: "20px" }}
      >
        <Text>온도 조정한 위치를 다시 한 번 확인해주세요.</Text>
        <ul style={{ padding: "16px 0 16px 24px" }}>
          {props.checkedItems.map((el) => (
            <li key={el}>{el}</li>
          ))}
        </ul>
        <Text>보내기를 누르면 해당 층의 온도 조절을 요청한 사용자들에게 변경 사항이 전송됩니다.</Text>
      </ConfirmModal>
    </>
  );
};

export default ControlTemperture;
