import { Flex, Td, Tr, useToast } from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ContentInputs from "../../../../components/Input/middleContents";
import { fetchClient } from "../../../../lib/api/axios";
import * as N from "./styled";

interface Classification {
  el: string;
  idx: number;
  onChangeTitle: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default function DropItems(props: Classification) {
  return (
    <Tr>
      <Td>{props.idx}</Td>
      <Td>
        <Flex align="center">
          <ContentInputs
            onChange={props.onChangeTitle}
            defaultValue={`${props.el}`}
          />
          <N.ImageSize src="/commons/upper.png" alt="위 클릭 아이콘" />
          <N.ImageSize src="/commons/down.png" alt="위 클릭 아이콘" />
          <N.ImageSize src="/commons/delete.png" alt="위 클릭 아이콘" />
        </Flex>
      </Td>
    </Tr>
  );
}
