import styled from "@emotion/styled";
import * as C from "../../commons/styled";
import { Flex } from "@chakra-ui/react";
import { ChangeEvent, Dispatch, FC, PropsWithChildren, SetStateAction, useRef, useState } from "react";
import { checkFileValidation } from "../../../../lib/utils/format";
import SkeletonImage from "../../../../components/Skeleton/images";
import { DeleteIcon } from "../../../../components/styled";
import { ShadowBox } from "../../../../components/styled/webApp";
import { toast } from "../../../../lib/utils/toast";

const UploadDesc = styled.p`
  font-size: 0.875rem;
  padding-top: 8px;
`;

export interface IDefaultImages extends PropsWithChildren {
  setImages: Dispatch<SetStateAction<File[] | null>>;
  images: File[];
  fileArr: string[];
  setfileArr: Dispatch<SetStateAction<string[]>>;
  auth: string;
}

const SetDetailImage: FC<IDefaultImages> = ({ children, ...props }) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(true); //이미지 로딩 대기
  const newArr = [...props.fileArr, ...props.images];

  const onDeleteFiles = (idx: number, el: File | string) => {
    if (typeof el === "string") {
      const stringArr = props.fileArr.filter((_, index) => index !== idx);
      props.setfileArr(stringArr.length === 0 ? [] : stringArr);
    } else {
      const temp = newArr.filter((_, index) => index !== idx);
      const setArr: any = temp.filter((el) => typeof el !== "string");

      props.setImages(setArr.length === 0 ? null : setArr);
    }
  };
  // const [images, setImages] = useState<File[] | null>(null);
  // const [arr, setArr] = useState<string[]>([]);

  const onChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    if (files.length > 5 || !Array.from(files).every((el) => checkFileValidation(el)))
      return toast.underFiveImage({
        description:
          "첨부 가능한 사진 갯수는 5개, 한 파일당 크기는 20MB 이하여야합니다. 첨부 파일 형식은 GIF, JPG, JPEG, PNG 형식만 가능합니다. 파일 확인 후 동일한 에러가 반복될 경우에는 오피스너 담당자에게 문의해주세요.",
      });

    const result = [...props.images, ...props.fileArr, ...Array.from(files)];
    // console.log(result);
    if (result.length > 5)
      return toast.underFiveImage({
        description: "첨부 가능한 사진 갯수는 5개입니다. 추가한 사진을 먼저 확인해주세요.",
      });

    props.setImages(files ? [...props.images, ...Array.from(files)] : []);
    e.target.value = "";
  };

  return (
    <>
      <ShadowBox>
        <div style={{ paddingBottom: 0 }}>
          <h4>
            대표 사진을 추가해주세요. <span>*</span>
          </h4>
          <Flex style={{ fontSize: "0.875rem" }}>
            {newArr.length !== 0 && (
              <Flex w={newArr.length === 5 ? "100%" : "auto"}>
                {newArr.map((el, index) => (
                  <C.RepresentBox key={typeof el === "string" ? el : el.name}>
                    {loading && <SkeletonImage />}
                    {props.auth === "edit" ? (
                      <>
                        <C.RepreImage
                          isLoading={loading}
                          alt="대표 사진"
                          src={typeof el !== "string" ? URL.createObjectURL(el) : el}
                          onLoad={() => setLoading(false)}
                        />
                        <DeleteIcon onClick={() => onDeleteFiles(index, el)} />
                      </>
                    ) : (
                      <a target="_blank" rel="noreferrer" href={typeof el !== "string" ? URL.createObjectURL(el) : el}>
                        <C.RepreImage
                          isLoading={loading}
                          alt="대표 사진"
                          src={typeof el !== "string" ? URL.createObjectURL(el) : el}
                          onLoad={() => setLoading(false)}
                        />
                      </a>
                    )}
                    {index === 0 && <C.FirstMark>대표</C.FirstMark>}
                  </C.RepresentBox>
                ))}
              </Flex>
            )}
            {newArr.length < 5 && (
              <C.UploadBox onClick={() => fileRef.current?.click()}>
                <span>+</span>
                <p>사진추가</p>
                <input type="file" ref={fileRef} multiple={true} onChange={onChangeImage} />
              </C.UploadBox>
            )}
          </Flex>
          <UploadDesc>*한 장당 최대 20MB, 최대 5장</UploadDesc>
        </div>
      </ShadowBox>
      {children}
    </>
  );
};

export default SetDetailImage;
