import { Box, Button, Flex, Select, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { usePagination } from "@ajna/pagination";
import { useEffect, useState } from "react";
import ContentInputs from "../../components/Input/middleContents";
import PaginationUI from "../../components/pagination";
import * as C from "../../components/styled/index";
import { fetchClient } from "../../lib/api/axios";
import { BaseResponse } from "../../lib/api/queries/commons/types";
import { survey_list_key } from "../../lib/api/queries/keys";
import { survey_list } from "../../lib/api/queries/urls";
import { ISurvey } from "../../lib/types/Imodels";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import MainHeaderPart from "../../components/Main/HeaderPart";
import useUserAuth from "../../components/hooks/useUserAuth";
import FullPageSpinner from "../../components/Spinner";
import GetAuthorized from "../../components/Authrized";
import moment from "moment";
import { format_survey, format_survey_to, format_type } from "../../lib/utils/dataFormat";
import { useRoomType } from "../../lib/utils";

export default function SurveyPage() {
  const params = useLocation();
  const navigate = useNavigate();
  const auth = useUserAuth("survey");
  const { currentPage, setCurrentPage } = usePagination({
    initialState: { currentPage: 1 },
  });
  const [allPage, setAllPage] = useState<number>(0);
  const [progress_type, setProgressType] = useState("all");
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const room_type = useRoomType();

  const onClickRegisterSurvey = () => {
    navigate("/survey/new");
  };

  const { data, refetch } = useQuery(
    [survey_list_key, currentPage],
    () =>
      fetchClient
        .get<BaseResponse<ISurvey[]>>(survey_list, {
          params: {
            limit: 10,
            page: currentPage,
            progress_type: searchParams.get("progress") || "all",
            search: searchParams.get("search") || "",
          },
        })
        .then((res) => {
          setAllPage(res.data.total_page_num || 0);
          return res.data;
        })
        .catch((err) => console.log(err.message)),
    { enabled: auth === "edit" || auth === "read", keepPreviousData: true },
  );

  const onApplySearch = () => {
    setCurrentPage(1);
    setSearchParams({
      page: String(1),
      search,
      progress: progress_type,
    });
  };

  useEffect(() => {
    if (!auth || auth === "no_auth") return;
    refetch();
    if (params.pathname === "/survey/search" && !searchParams.has("page")) {
      setProgressType("all");
      return setSearch("");
    }
    setProgressType(searchParams.get("progress") || "all");
    setSearch(searchParams.get("search") || "");
  }, [searchParams, auth]);

  if (!auth) return <FullPageSpinner />;
  if (auth === "no_auth") return <GetAuthorized />;
  return (
    <C.Wrapper>
      <MainHeaderPart isIcon={false} title="설문조사" authKey="survey">
        <Text>
          설문조사는 우리 오피스를 위해 입주자의 의견을 수렴하고 결정안을 도출하는 과정에서 사용할 수 있는 기능입니다.
        </Text>
        <Text>설문조사 대상와 기간을 지정하여 설문조사를 진행할 수 있습니다.</Text>
        <Button m="20px 0 0 0" variant="bgBlue" onClick={onClickRegisterSurvey}>
          설문조사 등록
        </Button>
      </MainHeaderPart>
      <C.WrapperBody>
        <Flex>
          <C.SelectBox>
            <Text variant="default">상태</Text>
            <Select variant="default" value={progress_type} onChange={(e) => setProgressType(e.target.value)}>
              <option value="all">전체</option>
              <option value="waiting">대기</option>
              <option value="proceeding">진행</option>
              <option value="completed">종료</option>
            </Select>
          </C.SelectBox>
          <Box w="420px">
            <Text variant="default">검색</Text>
            <Flex>
              <ContentInputs
                placeholder="검색어를 입력해주세요"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && onApplySearch()}
              />
              <Button variant="basic" onClick={onApplySearch}>
                검색
              </Button>
            </Flex>
          </Box>
        </Flex>
        <C.TableListBox>
          <C.TableText>
            총 <C.TableCount>{data?.total_num}개</C.TableCount>의 설문조사가 조회되었습니다.
          </C.TableText>
          <C.ListTableBox>
            <C.ListTableHeader>
              <div style={{ width: "30%" }}>제목</div>
              <div style={{ width: "30%" }}>설문조사 기간</div>
              <div>설문대상</div>
              <div>상태</div>
              <div>설문참여</div>
            </C.ListTableHeader>
            {data?.data.map((el) => (
              <div
                key={String(el._id)}
                className="flex w-full cursor-pointer items-center border-b border-gray-400 transition-all duration-75 hover:bg-blue-50 hover:opacity-75"
                onClick={() => {
                  if (el?.progress_type === "waiting") {
                    navigate(`/survey/${el._id}/edit`);
                  } else {
                    navigate(`/survey/${el._id}`);
                  }
                }}
              >
                <div className="inline-block w-2/6 overflow-hidden text-ellipsis text-nowrap text-left">
                  {el?.title}
                </div>
                <div className="w-2/6">
                  {moment.utc(el?.start_time).format("YYYY-MM-DD HH:mm")} ~{" "}
                  {moment.utc(el?.end_time).format("YYYY-MM-DD HH:mm")}
                </div>
                <div className="inline-block w-1/6 overflow-hidden text-ellipsis text-nowrap text-center">
                  {el?.survey_tenant_to.length === room_type.filter((v) => v.label !== "owner").length
                    ? "전체"
                    : el?.survey_tenant_to.length !== 0
                      ? format_type(el?.survey_tenant_to)
                      : ""}
                  {el?.survey_tenant_to.length !== 0 ? " / " : ""}
                  {format_survey_to(el?.survey_person_to)}
                </div>
                <div className="w-1/6">{format_survey(el?.progress_type)}</div>
                <div className="w-1/6">{el?.count}</div>
              </div>
            ))}
          </C.ListTableBox>
        </C.TableListBox>
        <PaginationUI page={currentPage} allPage={allPage} setPage={setCurrentPage} />
      </C.WrapperBody>
    </C.Wrapper>
  );
}
