import * as C from "../../commons/styled";
import * as S from "./styled";
import { Checkbox, Flex, Radio, RadioGroup } from "@chakra-ui/react";
import { allTime } from "../../../../lib/utils/dataFormat";
import { v4 as uuidv4 } from "uuid";
import { IOpenModal } from "../../commons/types";
import { Dispatch, SetStateAction } from "react";
import { ITimeSet } from "../[publicId]/create/page";
import { MemoizedIntervalTimeSlot } from "./timeslot";
import styled from "@emotion/styled";
import { MediaSelect } from "../../../../components/select";
import { breakPoints } from "../../../../components/styled/media";
import { AddMoreButton, ShadowBox } from "../../../../components/styled/webApp";

const PlanSelect = styled(MediaSelect)`
  width: 10rem;
  text-align: left;
  background: #f4f4f5 url("/commons/select.svg") no-repeat 85% center;
  @media ${breakPoints.phones} {
    width: 9rem;
  }
`;

export const OperationPlan = ({ isModal }: IOpenModal) => {
  // 운영 계획 지금 안함
  return (
    <>
      <ShadowBox isModal={isModal}>
        <div>
          <h4>운영 계획을 설정해주세요.</h4>
          <PlanSelect>
            <option>2023-01-13</option>
          </PlanSelect>
          <span>-</span>
          <PlanSelect style={{ marginLeft: "0.5rem" }}>
            {allTime.map((el) => (
              <option key={el}>{el}</option>
            ))}
          </PlanSelect>
          <Flex pt="0.75rem">
            <Checkbox variant="default">종료일 없음(상시운영)</Checkbox>
          </Flex>
        </div>
      </ShadowBox>
    </>
  );
};

interface IBookingOperationTime extends IOpenModal {
  interval: string;
  setInterval: Dispatch<SetStateAction<string>>;
  isEveryDay: string;
  setIsEveryDay: Dispatch<SetStateAction<string>>;
  weekDayArr: ITimeSet[];
  setWeekDayArr: Dispatch<SetStateAction<ITimeSet[]>>;
  weeklyArr: ITimeSet[];
  setWeeklyArr: Dispatch<SetStateAction<ITimeSet[]>>;
}

export const BookingTime = ({
  isModal,
  weekDayArr: timeArr,
  setWeekDayArr: setTimeArr,
  weeklyArr,
  setWeeklyArr,
  ...props
}: IBookingOperationTime) => {
  const timeSetId = uuidv4();
  // const [timeArr, setTimeArr] = useState<ITimeSet[]>([
  //   { id: timeSetId, start: "00:00", end: "00:00", time: [] },
  // ]);
  // const [weeklyArr, setWeeklyArr] = useState<ITimeSet[]>([]);

  const onClickInterval = (value: string) => {
    props.setInterval(value);
    setTimeArr([{ _id: timeSetId, start: "00:00", end: "00:00", time: [] }]);
    if (props.isEveryDay === "false") {
      setWeeklyArr([{ _id: uuidv4(), start: "00:00", end: "00:00", time: [] }]);
    }
  };

  return (
    <>
      <ShadowBox isModal={isModal}>
        <div>
          <h4>예약 받는 시간 간격을 알려주세요. {!isModal && <span>*</span>}</h4>
          <Flex>
            <C.ActiveButton
              variant="m_primary"
              clicked={String(props.interval === "30m")}
              style={{ minWidth: "7rem" }}
              onClick={() => onClickInterval("30m")}
            >
              30분 단위
            </C.ActiveButton>
            <C.ActiveButton
              variant="m_primary"
              clicked={String(props.interval === "1h")}
              style={{ minWidth: "7rem" }}
              onClick={() => onClickInterval("1h")}
            >
              1시간 단위
            </C.ActiveButton>
            <C.ActiveButton
              variant="m_primary"
              clicked={String(props.interval === "1d")}
              style={{ minWidth: "7rem" }}
              onClick={() => onClickInterval("1d")}
            >
              1일 단위
            </C.ActiveButton>
          </Flex>
        </div>
      </ShadowBox>
      <ShadowBox isModal={isModal}>
        <div>
          <h4>예약 운영 시간을 알려주세요. {!isModal && <span>*</span>}</h4>
          <RadioGroup
            style={{ fontWeight: 400 }}
            value={props.isEveryDay}
            onChange={(e) => {
              if (e === props.isEveryDay) return;
              props.setIsEveryDay(e);
              setTimeArr([{ _id: timeSetId, start: "00:00", end: "00:00", time: [] }]);
              e === "false"
                ? setWeeklyArr([
                    {
                      _id: timeSetId,
                      start: "00:00",
                      end: "00:00",
                      time: [],
                    },
                  ])
                : setWeeklyArr([]);
            }}
          >
            <Radio mr="2.5rem" value="true" variant="default">
              매일 같아요
            </Radio>
            <Radio value="false" variant="default">
              평일/주말 달라요
            </Radio>
          </RadioGroup>
          <S.SelectTimeSection>
            <div className="section">{props.isEveryDay === "true" ? "운영시간" : "평일(월 ~ 금)"}</div>
            <div style={{ width: "calc(100% - 7.5rem)" }}>
              {timeArr.map((el) => (
                <MemoizedIntervalTimeSlot
                  key={el._id}
                  el={el}
                  timeArr={timeArr}
                  setTimeArr={setTimeArr}
                  interval={props.interval}
                />
              ))}
              {props.interval !== "1d" && (
                <AddMoreButton
                  onClick={() => {
                    const newArr = [...timeArr];
                    newArr.push({
                      _id: uuidv4(),
                      start: "00:00",
                      end: "00:00",
                      time: [],
                    });
                    setTimeArr(newArr);
                  }}
                >
                  + 시간추가
                </AddMoreButton>
              )}
            </div>
          </S.SelectTimeSection>
          {weeklyArr.length !== 0 && (
            <S.SelectTimeSection>
              <div className="section">주말(토,일)</div>
              <div style={{ width: "calc(100% - 7.5rem)" }}>
                {weeklyArr.map((el) => (
                  <MemoizedIntervalTimeSlot
                    key={el._id}
                    el={el}
                    timeArr={weeklyArr}
                    setTimeArr={setWeeklyArr}
                    interval={props.interval}
                  />
                ))}
                {props.interval !== "1d" && (
                  <AddMoreButton
                    onClick={() => {
                      const newArr = [...weeklyArr];
                      newArr.push({
                        _id: uuidv4(),
                        start: "00:00",
                        end: "00:00",
                        time: [],
                      });
                      setWeeklyArr(newArr);
                    }}
                  >
                    + 시간추가
                  </AddMoreButton>
                )}
              </div>
            </S.SelectTimeSection>
          )}
        </div>
      </ShadowBox>
    </>
  );
};
