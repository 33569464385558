import {
  Button,
  DrawerBody,
  useDisclosure,
  UseModalProps,
} from "@chakra-ui/react";
import { usefetchPermissionList } from "../commons/queries";
import CommonDrawerModal from "../../../../components/Drawer";
import AuthItem from "./AuthItem";
import { DetailBoxInDrawer } from "../../../../components/styled/webApp";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import NewAuth from "./Auth";

export default function AuthDrawer(props: UseModalProps) {
  // const { user } = useContext(GlobalContext); 권한 설정 바뀌면 필요 지금은 drawer 오픈하면 마스터임
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data } = usefetchPermissionList(true);

  return (
    <CommonDrawerModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="권한 설정"
    >
      <DrawerBody>
        <DetailBoxInDrawer>
          <div className="header">
            <h4>신규 권한 추가</h4>
          </div>
          <Button variant="m_primary" onClick={onOpen}>
            <FiPlus />
            권한추가
          </Button>
        </DetailBoxInDrawer>
        {isOpen && <NewAuth isEdit={false} isOpen={isOpen} onClose={onClose} />}
        <DetailBoxInDrawer>
          <div className="header">
            <h4>전체 권한 ({data?.length || 0})</h4>
          </div>
          {data?.map((el) => (
            <AuthItem key={String(el._id)} el={el} />
          ))}
        </DetailBoxInDrawer>
      </DrawerBody>
    </CommonDrawerModal>
  );
}
