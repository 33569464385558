import styled from "@emotion/styled";
import { BottomSheetBox } from "../../Menu/bottomSheet";
import { colors } from "../../styled/common";
import { breakPoints } from "../../styled/media";

export const FlexBox = styled.div<{ isHide?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media ${breakPoints.desktop} {
    ${({ isHide }) => isHide && `display:none`};
  }
`;

export const NavBox = styled(FlexBox)<{ isMenu: boolean }>`
  font-size: 16px;
  font-weight: 500;
  color: ${({ isMenu }) => (isMenu ? colors.mainBlue : colors.gray5)};
  @media ${breakPoints.mobile} {
    div,
    .arrow {
      display: none;
    }
    svg {
      width: 1.75rem;
    }
  }
`;

export const CustomerCenter = styled.div`
  padding: 7px 17px;
  background-color: ${colors.gray1}; //당장은 안씀
  border-radius: 20px;
  color: #45464b;
  font-weight: bold;
  cursor: pointer;
`;

interface HeaderStyle {
  path?: boolean;
  dots?: boolean;
}

export const LampIconBox = styled.div`
  text-align: center;
  margin-right: 8px;
  cursor: pointer;
  position: relative;
  @media ${breakPoints.mobile} {
    margin-right: 1.5rem;
    svg {
      width: 1.75rem;
    }
  }
`;

export const NewAlertIcon = styled.div`
  //todos => true : 새로운 소식이 없다.
  display: ${(props: HeaderStyle) => (props.dots ? "block" : "none")};
  position: absolute;
  top: 5px;
  right: 2px;
  width: 0.625rem;
  height: 0.625rem;
  background-color: ${colors.orange1};
  border: 1.2px solid ${colors.whiteColor};
  border-radius: 100%;
  @media ${breakPoints.mobile} {
    top: 8px;
    right: 0.5px;
  }
`;

export const BottomSheetListBox = styled(BottomSheetBox)`
  width: 10rem;
  top: 3rem;
`;
