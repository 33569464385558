import { useParams } from "react-router-dom";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import useUserAuth from "../../../../components/hooks/useUserAuth";
import FullPageSpinner from "../../../../components/Spinner";
import GetAuthorized from "../../../../components/Authrized";
import { GlobalContext } from "../../../../App";
import NotFound from "../../../not-found";
import { manage_fee_list } from "../../../../lib/api/queries/urls";
import { IRentalList } from "../../../../lib/types/Imodels";
import CreateManagementBill from "../../maintenance/new/management";
import { manage_tenant_list_key } from "../../../../lib/api/queries/keys";

export default function MaintenanceFeeEditPage() {
  const { buildInfo } = useContext(GlobalContext);
  const { billId } = useParams();
  const auth = useUserAuth("management_fee");
  const hasFunc = buildInfo?.services.MAINTENANCE_FEE?.enabled;

  const { data, isFetching: isEditFetching } = useQuery(
    [manage_tenant_list_key, billId],
    () =>
      fetchClient
        .get<BaseResponse<IRentalList>>(manage_fee_list, {
          params: {
            management_fee_id: billId,
            upload_type: "direct",
            payment_status: "",
            dong: "",
            search_ho: "",
            search_tenant: "",
          },
        })
        .then((res) => res.data.data),
    { enabled: auth === "edit" },
  );

  if (!buildInfo || isEditFetching) return <FullPageSpinner />;
  if (buildInfo.management_fee_upload_type === "file" || !hasFunc) return <NotFound />; //관리비 타입이 있다는 건 이 기능을 쓴다는 것
  if (auth !== "edit") return <GetAuthorized />;

  return <CreateManagementBill isEdit={true} room={undefined} data={data} saved={undefined} />;
}
