import toast from "react-hot-toast";
import IconClose from "../../components/icons/IconClose";

type ToastType = {
  iconType?: "check" | "info" | "error" | "warning";
  title?: string;
  description?: string;
  sideButton?: JSX.Element;
};

export const success = {
  position: "top",
  title: "성공",
  description: `처리 결과 성공`,
  status: "success",
  duration: 4500,
  isClosable: true,
} as const;

export const success2 = (toastId: string): ToastType => ({
  iconType: "check",
  title: "성공",
  description: `처리 결과 성공`,
  sideButton: (
    <button onClick={() => toast.dismiss(toastId)}>
      <IconClose className="size-5" />
    </button>
  ),
});

export const warning = {
  position: "top",
  title: "실패",
  description: `처리 결과 실패`,
  status: "warning",
  duration: 4000,
  isClosable: true,
} as const;

export const warning2 = (toastId: string): ToastType => ({
  iconType: "warning",
  title: "실패",
  description: `처리 결과 실패`,
  sideButton: (
    <button onClick={() => toast.dismiss(toastId)}>
      <IconClose className="size-5" />
    </button>
  ),
});

export const prepare = (toastId: string) => ({
  ...warning2(toastId),
  title: "알림",
  description: "해당 기능은 준비중입니다.",
  status: "warning",
  isClosable: true,
});

export const checkFileValidate = {
  position: "top",
  title: "파일 등록 실패",
  description:
    "파일은 GIF, JPEG, JPG, PNG 형식으로 1장, 20MB 이하합니다. 파일 형식 확인 후 동일한 에러가 반복될 경우에는 오피스너 담당자에게 문의해주세요.",
  duration: 4000,
  status: "warning",
  isClosable: true,
} as const;

export const underFiveImage = (toastId: string) => ({
  ...warning2(toastId),
  title: "파일 등록 실패",
  description:
    "첨부 가능한 파일 갯수는 5개, 한 파일당 크기는 100MB 이하여야합니다. 첨부 파일 형식은 GIF, JPG, JPEG, PNG 형식만 가능합니다. 파일 확인 후 동일한 에러가 반복될 경우에는 오피스너 담당자에게 문의해주세요.",
});

export const unAuthorized = (toastId: string) => ({
  ...warning2(toastId),
  title: "알림",
  description: "오피스너 관리자 웹사이트 이용을 위해 관리자 계정으로 로그인을 해주세요.",
});

export const unAuthUser = (toastId: string) => ({
  ...warning2(toastId),
  title: "관리자 권한 오류",
  description: "해당 작업에 대한 권한이 없습니다. 관리자 권한 변경이 필요하다면 최고관리자에게 문의해주세요.",
});

export const WarningTime = {
  ...warning,
  title: "시간 설정 오류",
  description: "종료시간은 시작시간 이후로 설정해주세요.",
};
