import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { IBuilding } from "../../../lib/types/Imodels";
import { ITimeSet } from "../booking/[publicId]/create/page";
import { IOperateInfo } from "./types";

//공용시설 외에도 쓰이면 공통으로 옮길 예정
export const weeklyArr = ["첫번째", "두번째", "세번째", "네번째", "다섯번째"];

//요일
export const dayArr = ["월", "화", "수", "목", "금", "토", "일"];

//공휴일
export const holidayArr = [
  "신정",
  ["설날-1", "설날 당일", "설날+1"],
  "삼일절",
  "석가탄신일",
  "어린이날",
  "현충일",
  "광복절",
  "개천절",
  "한글날",
  ["추석-1", "추석 당일", "추석+1"],
  "성탄절",
];

export const onPushItems = (str: string, state: string[], setState: Dispatch<SetStateAction<string[]>>) => {
  const newArr = [...state];
  if (newArr.includes(str)) {
    setState(newArr.filter((ele) => ele !== str));
  } else {
    newArr.push(str);
    setState(newArr);
  }
};

export const onFormatDays = (newArr: string[], str: string) => {
  const setArr = [...newArr];

  if (str === "주") {
    const weekArr = setArr.filter((el) => el.includes("째"));
    const result = weekArr.map((el) => {
      return el === "첫번째" ? 0 : el === "두번째" ? 1 : el === "세번째" ? 2 : el === "네번째" ? 3 : 4;
    });
    return result;
  } else if (str === "요일") {
    const newArr = setArr.filter((el) => el.includes("요일"));
    const result = newArr.map((el) => {
      return el === "일요일"
        ? 0
        : el === "월요일"
          ? 1
          : el === "화요일"
            ? 2
            : el === "수요일"
              ? 3
              : el === "목요일"
                ? 4
                : el === "금요일"
                  ? 5
                  : 6;
    });
    // result.sort()
    return result;
  } else {
    const newArr = setArr.filter((el) => !el.includes("째") && !el.includes("요일") && !el.includes("yes"));
    return newArr.map((el) => {
      return el === "추석 당일" ? "추석" : el === "설날 당일" ? "설날" : el;
    });
  }
};

//30분 간격 체크
const toMinutes = (str: string) =>
  str.split(":").reduce((h, m) => {
    const hours = Number(h);
    const mimnutes = Number(m);
    const result = String(hours * 60 + +mimnutes);
    return result;
  });

const toString = (min: number) => (Math.floor(min / 60) + ":" + (min % 60)).replace(/\b\d\b/, "0$&");

export const slots = (startStr: string, endStr: string) => {
  const start = toMinutes(startStr);
  const end = toMinutes(endStr);
  return Array.from({ length: Math.floor((Number(end) - Number(start)) / 30) + 1 }, (_, i) => {
    const result = toString(Number(start) + i * 30);
    return result.split(":")[1] === "0" ? `${result.split(":")[0]}:00` : toString(Number(start) + i * 30);
  });
};

export const tenant_type = [
  //추후에 이름 달라지면 호실용도로, 용어 통일 필요
  { id: "0", label: "office", value: "오피스" },
  { id: "1", label: "store", value: "상가" },
  { id: "2", label: "residential", value: "주거" },
];

export const useTenantType = (data: IBuilding | undefined) => {
  if (!data) return [];
  if (data.building_type === "knowledge_industry_center") return tenant_type;
  if (data.building_type === "rental_building")
    return [
      { id: "0", label: "office", value: "오피스" },
      { id: "1", label: "store", value: "상가" },
    ];

  return [{ id: "0", label: "office", value: "오피스" }];
};

export const format_payment_method = (str: string) => {
  switch (str) {
    case "point":
      return "포인트";
    case "direct":
      return "현장결제";
    case "free":
      return "무료이용";
    default:
      return "";
  }
};

export const format_payment_status = (str: string | undefined) => {
  switch (str) {
    case "completed":
      return "결제완료";
    case "canceled":
      return "결제취소";
    case "pending":
      return "결제대기";
    default:
      return "";
  }
};

export const getTimeOnString = (time: string | undefined) => {
  //등록한 시간만 보여주도록
  if (!time) return "00:00";

  const date = String(time).slice(11, -8);
  return `${date}`;
};

//validation function
export const isCheckedOperateTime = (timeObj: IOperateInfo) => {
  if (!timeObj.end_time_holiday || !timeObj.start_time_holiday) return "시간 설정 오류";
  if (
    (timeObj.end_time_holiday === "00:00" && timeObj.start_time_holiday === "00:00") ||
    (timeObj.end_time_weekday === "00:00" && timeObj.start_time_weekday === "00:00")
  )
    return "24시간 운영 오류";

  return "통과";
};

export const isCheckedClosedDay = (holidays: string[]) => {
  if (holidays.length === 0 || (holidays.includes("yes") && holidays.length === 1)) return "휴무일 선택 오류";
  if (!holidays.includes("none") && (!onFormatDays(holidays, "주").length || !onFormatDays(holidays, "요일").length))
    return "휴무일 설정 오류";

  return "통과";
};

export const isCheckedItemOperation = (weekDayArr: ITimeSet[], weeklyArr: ITimeSet[]) => {
  const concatArr = weekDayArr.concat(weeklyArr);

  for (let i = 0; i < concatArr.length; i++) {
    if (concatArr[i].time.length === 0) return "운영 시간 설정 안내";
    if (!concatArr[i].people_num) return "예약 인원 설정 안내";
    const startTime = moment(`2000-01-01 ${concatArr[i].start}`);
    const endTime = moment(`2000-01-01 ${concatArr[i].end}`);
    if (startTime > endTime) return "시간 설정 안내";
  }

  for (let i = 0; i < weekDayArr.length; i++) {
    if (i + 1 === weekDayArr.length) return;
    const nextStart = new Date(`2000-01-01 ${weekDayArr[i + 1].start}`).getTime();
    const nextEnd = new Date(`2000-01-01 ${weekDayArr[i + 1].end}`).getTime();
    const startTime = new Date(`2000-01-01 ${weekDayArr[i].start}`).getTime();
    const endTime = new Date(`2000-01-01 ${weekDayArr[i].end}`).getTime();

    return startTime <= nextEnd && nextStart <= endTime ? "운영 시간 겹침" : "통과";
  }

  if (weeklyArr.length !== 0) {
    for (let i = 0; i < weeklyArr.length; i++) {
      if (i + 1 === weeklyArr.length) return;
      const nextStart = new Date(`2000-01-01 ${weeklyArr[i + 1].start}`).getTime();
      const nextEnd = new Date(`2000-01-01 ${weeklyArr[i + 1].end}`).getTime();
      const startTime = new Date(`2000-01-01 ${weeklyArr[i].start}`).getTime();
      const endTime = new Date(`2000-01-01 ${weeklyArr[i].end}`).getTime();

      return startTime <= nextEnd && nextStart <= endTime ? "운영 시간 겹침" : "통과";
    }
  }

  return "통과";
};

// if (
//   weekDayArr.some((el) => {
//     const startTime = moment(`2000-01-01 ${el.start}`);
//     const endTime = moment(`2000-01-01 ${el.end}`);
//     return startTime > endTime;
//   })
// )
//   return toast({
//     ...warning,
//     title: "운영 시간 설정 오류",
//     description:
//       "운영시간의 시작 시간은 마지막 시간보다 이전으로 설정해주세요.",
//   });

// if (isEveryDay === "false") {
//   if (weeklyArr.some((el) => el.time.length === 0))
//     return toast({
//       ...warning,
//       title: "운영 시간 설정 오류",
//       description: "운영시간과 예약 인원을 정확히 설정해주세요.",
//     });
//   if (weeklyArr.some((el) => !el.people_num))
//     return toast({
//       ...warning,
//       title: "예약 인원 설정 오류",
//       description: "예약 인원은 1명 이상으로 설정해주세요.",
//     });
//   if (
//     weeklyArr.some((el) => {
//       const startTime = moment(`2000-01-01 ${el.start}`);
//       const endTime = moment(`2000-01-01 ${el.end}`);
//       return startTime > endTime;
//     })
//   )
//     return toast({
//       ...warning,
//       title: "운영 시간 설정 오류",
//       description:
//         "운영시간의 시작 시간은 마지막 시간보다 이전으로 설정해주세요.",
//     });
// }
