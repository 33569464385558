import styled from "@emotion/styled";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import { colors } from "../../styled/common";
import { CSSProperties, FC } from "react";

const ContentWrap = styled(ModalContent)`
  padding: 0;
  min-height: 16rem;
  max-width: 31.5rem;
  .chakra-modal__body {
    font-size: 1rem;
    padding-top: 0;
  }
`;

const CustomFooter = styled(ModalFooter)`
  display: flex;
  width: 100%;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${colors.gray2};
`;

interface IEditModal extends ModalProps {
  onClickUpdate: () => void;
  value?: string;
  _style?: CSSProperties;
}

const EditModalNoHeader: FC<IEditModal> = ({ children, ...rest }) => {
  // style => 원래 헤더 없이 shadow 박스와 동일한 UI 컨포넌트를 수정하기 위한 팝업
  // 소속, 비밀번호 변경 등 컨포넌트 박스 없이 수정되는 일부분들을 위한 팝업으로 사용되기 위해 전체 틀 style 조정용
  const { _style, ...props } = rest;

  return (
    <Modal
      size="md"
      variant="responsive"
      isOpen={props.isOpen}
      onClose={props.onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount={false}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ContentWrap>
        {/* <ModalHeader>{props.title}</ModalHeader> */}
        <ModalBody style={_style}>{children}</ModalBody>
        <CustomFooter>
          <Button mr="0.5rem" w="8rem" variant="m_gray" onClick={props.onClose}>
            취소
          </Button>
          <Button
            w="8rem"
            type="submit"
            variant="m_blue"
            onClick={props.onClickUpdate}
          >
            {props.value ? props.value : "저장"}
          </Button>
        </CustomFooter>
      </ContentWrap>
    </Modal>
  );
};

export default EditModalNoHeader;
