import { Popover, PopoverBody, PopoverContent, PopoverTrigger, useDisclosure, useToast } from "@chakra-ui/react";
import Button from "../../components/button/Button";
import IconMail from "../../components/icons/IconMail";
import IconSearch from "../../components/icons/IconSearch";
import { VoteDetailResponse, useVoteDeleteSignaturesMutation, useVoteFileDownloadMutation } from "../../requests/vote";
import { useParams } from "react-router-dom";
import IconKebab from "../../components/icons/IconKebab";
import { useState, useEffect } from "react";
import CancelModal from "../../components/modal/CancelModal";
import { debounce } from "lodash";
import IconCircleCheck from "../../components/icons/IconCircleCheck";
import VoteDelegateModal from "./modal/VoteDelegateModal";
import { twMerge } from "tailwind-merge";
import VoteAddMemberModal from "./modal/VoteAddMemberModal";
import VoteNotifyResend from "./modal/VoteNotifyResend";
import ItemReturnInfo from "../../../archive/document/borrow/component/return";
import useUserAuth from "../../components/hooks/useUserAuth";

// Tab values constant
const TAB_VALUES = {
  ALL: "전체",
  SIGNED: "서명완료",
  UNSIGNED: "미완료",
} as const;

type TabValues = (typeof TAB_VALUES)[keyof typeof TAB_VALUES];

export default function VoteMemberTable({ signatures }: VoteDetailResponse["data"]) {
  const toast = useToast();
  const { voteId } = useParams() as { voteId: string };
  const [tab, setTab] = useState<TabValues>(TAB_VALUES.ALL);

  const auth = useUserAuth("vote");
  const isEdit = auth === "edit";

  //Modal Array
  const { isOpen: isResendModalOpen, onOpen: onResendModalOpen, onClose: onResendModalClose } = useDisclosure();
  const { isOpen: isDeleteSignOpen, onOpen: onDeleteSignOpen, onClose: onDeleteSignClose } = useDisclosure();
  const { isOpen: isDelegateOpen, onOpen: onDelegateOpen, onClose: onDelegateClose } = useDisclosure();
  const { isOpen: isAddMemberOpen, onOpen: onAddMemberOpen, onClose: onAddMemberClose } = useDisclosure();

  const [selectedMember, setSelectedMember] = useState<VoteDetailResponse["data"]["signatures"][number] | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  const { mutateAsync: deleteSign } = useVoteDeleteSignaturesMutation();
  const { mutateAsync: fileDownload } = useVoteFileDownloadMutation();

  useEffect(() => {
    const debouncedSearch = debounce((term) => setSearchTerm(term), 300);
    debouncedSearch(searchTerm);
    return () => debouncedSearch.cancel();
  }, [searchTerm]);

  const unsignedMembersCount = signatures.filter((signature) =>
    signature.delegate.name ? !signature.delegate.delegate_status : !signature.signatureStatus,
  ).length;

  const filteredSignatures = signatures.filter((signature) => {
    const searchTermMatch =
      signature.name.toLowerCase().includes(searchTerm.toLowerCase()) || signature.phone_number.includes(searchTerm);
    switch (tab) {
      case TAB_VALUES.ALL:
        return searchTermMatch;
      case TAB_VALUES.SIGNED:
        return signature.delegate.name
          ? signature.delegate.delegate_status && searchTermMatch
          : signature.signatureStatus && searchTermMatch;
      case TAB_VALUES.UNSIGNED:
        return signature.delegate.name
          ? !signature.delegate.delegate_status && searchTermMatch
          : !signature.signatureStatus && searchTermMatch;
      default:
        return false;
    }
  });

  const handleCancelSignature = async () => {
    if (!selectedMember) return;
    toast.promise(deleteSign({ voteId, signaturesId: selectedMember._id }), {
      success: { position: "top", title: `${selectedMember.name}님을 투표에서 삭제하였습니다` },
      error: { position: "top", title: `${selectedMember.name}님을 투표에서 삭제시키는 도중 문제가 발생했습니다.` },
      loading: { position: "top", title: `${selectedMember.name}님을 투표에서 삭제중입니다.` },
    });
    onDeleteSignClose();
  };

  return (
    <>
      <div className="flex w-full flex-col gap-5">
        <div className="flex flex-wrap justify-between gap-y-4 bg-gray-50 px-6 py-2.5">
          <div className="flex h-9 items-center gap-1 rounded-lg border border-gray-200 bg-gray-100 p-1">
            {Object.values(TAB_VALUES).map((tabOption) => (
              <button
                key={tabOption}
                onClick={() => setTab(tabOption)}
                className={twMerge(
                  "h-7 whitespace-nowrap rounded-lg px-[30px] text-sm text-gray-700 transition-all hover:bg-gray-50 active:bg-white",
                  tab === tabOption ? "bg-white font-medium shadow" : "",
                )}
              >
                {tabOption}{" "}
                {tabOption === TAB_VALUES.ALL
                  ? signatures.length
                  : tabOption === TAB_VALUES.SIGNED
                    ? signatures.filter((signature) =>
                        signature.delegate.name ? signature.delegate.delegate_status : signature.signatureStatus,
                      ).length
                    : unsignedMembersCount}
              </button>
            ))}
          </div>
          <div className="flex items-center gap-3">
            <div className="relative">
              <IconSearch className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 transform" />
              <input
                className={`h-9 w-[444px] max-w-[444px] rounded-lg border border-gray-300 py-2 pl-11 pr-3 transition-all placeholder:text-gray-300 focus:border-blue-600`}
                placeholder="검색어를 입력하세요"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {isEdit && (
              <>
                <div className="h-8 w-[1px] bg-gray-200" />
                {/* <Button
              size="sm"
              iconElement={<IconPlus />}
              designSchema="secondaryBlue"
              buttonText={"투표 대상 추가"}
              onClick={onAddMemberOpen}
            /> */}
                <Button
                  disabled={unsignedMembersCount === 0}
                  size={"sm"}
                  designSchema={"primary"}
                  iconElement={<IconMail />}
                  buttonText={"투표 알림 재전송"}
                  onClick={() => {
                    setSelectedMember(null);
                    onResendModalOpen();
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className="relative w-full flex-1 px-6">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="border-b border-gray-200">
              <tr>
                <th className="min-w-[220px] px-2 py-3 text-left text-sm font-bold text-gray-900">
                  참여자 (연락처) / 수임자 정보
                </th>
                <th className="w-[200px] px-2 py-3 text-left text-sm font-bold text-gray-900">구분</th>
                <th className="w-[200px] px-2 py-3 text-left text-sm font-bold text-gray-900">호실</th>
                <th className="w-[100px] px-2 py-3 text-left text-sm font-bold text-gray-900">서명여부</th>
                <th className="w-[230px] px-2 py-3 text-left text-sm font-bold text-gray-900">설정</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {filteredSignatures.map((item, index) => {
                const isStatus = item.delegate?.name ? item.delegate.delegate_status : item.signatureStatus;
                return (
                  <tr key={index} className="h-10 whitespace-nowrap border-b">
                    <td className="px-2 text-sm">
                      <p className="flex h-full items-center gap-3 font-normal">
                        <span>
                          {item.name} ({item.phone_number})
                        </span>
                        {item.delegate?.name && <span>/</span>}
                        {item.delegate?.name && (
                          <span>
                            <b className="text-sm font-normal text-green-500">수임자 </b>
                            {item.delegate.name} ({item.delegate.phone_number})
                          </span>
                        )}
                      </p>
                    </td>
                    <td className="px-2 text-sm">{item.type === "room_owner" ? "소유자" : "소유자"}</td>
                    <td className="px-2 text-sm">
                      {item.room_ids.length > 1 ? `${item.room_ids[0].dong}동 ${item.room_ids[0].ho}호` : "호실없음"}
                      {item.room_ids.length < 1 ? `` : ` 외 ${item.room_ids.length - 1}`}
                    </td>
                    <td className="px-2 text-sm">
                      {isStatus ? <IconCircleCheck /> : <IconCircleCheck className="fill-gray-300" />}
                    </td>
                    <td className="px-2 text-sm">
                      <Popover placement="bottom-start">
                        <PopoverTrigger>
                          <button>
                            <IconKebab />
                          </button>
                        </PopoverTrigger>
                        <PopoverContent width={200}>
                          <PopoverBody
                            padding={1}
                            className="flex flex-col rounded-lg border border-gray-300 p-1 text-gray-500 shadow"
                          >
                            {isEdit && (
                              <>
                                <button
                                  disabled={isStatus}
                                  onClick={() => {
                                    setSelectedMember(item);
                                    onResendModalOpen();
                                  }}
                                  className="rounded-md px-2.5 py-3 text-left transition-all hover:bg-gray-100 disabled:opacity-40"
                                >
                                  투표 알림 재전송
                                </button>
                                <button
                                  onClick={() => {
                                    setSelectedMember(item);
                                    onDelegateOpen();
                                  }}
                                  disabled={item.signatureStatus}
                                  className="rounded-md px-2.5 py-3 text-left transition-all hover:bg-gray-100 disabled:opacity-40"
                                >
                                  투표 위임
                                </button>
                              </>
                            )}
                            <button
                              onClick={() => {
                                const fileUrl = item.delegate.name ? item.delegate.file_url : item.file_url;
                                if (!fileUrl) return;
                                toast.promise(
                                  fileDownload({
                                    fileUrl: fileUrl,
                                    fileName: `${item.delegate.name ? item.delegate.name : item.name}님의 서명결의서`,
                                  }),
                                  {
                                    success: { position: "top", title: `서명 파일을 다운로드했습니다.` },
                                    error: { position: "top", title: "서명 파일 다운로드에 실패했습니다." },
                                    loading: { position: "top", title: "서명 파일을 다운로드 중입니다." },
                                  },
                                );
                              }}
                              disabled={!item.signatureStatus}
                              className="rounded-md px-2.5 py-3 text-left transition-all hover:bg-gray-100 disabled:opacity-40"
                            >
                              파일 다운로드
                            </button>
                            {isEdit && (
                              <button
                                onClick={() => {
                                  setSelectedMember(item);
                                  onDeleteSignOpen();
                                }}
                                disabled={item.delegate.name ? item.delegate.delegate_status : item.signatureStatus}
                                className="rounded-md px-2.5 py-3 text-left transition-all hover:bg-gray-100 disabled:opacity-40"
                              >
                                삭제
                              </button>
                            )}
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {filteredSignatures.length === 0 && (
            <div className="absolute w-full py-5 text-center">검색 결과가 존재하지 않습니다.</div>
          )}
        </div>
      </div>
      <CancelModal
        title={`삭제`}
        width={488}
        isOpen={isDeleteSignOpen}
        onClose={onDeleteSignClose}
        onClickSave={handleCancelSignature}
      >
        <p className="text-gray-700">투표 대상리스트에서 삭제할까요?</p>
        <p className="my-3 rounded-xl bg-gray-50 p-3 text-gray-700">
          {selectedMember?.name} ({selectedMember?.phone_number})
        </p>
        <ul className="list-disc pl-5 text-red-500">
          <li>삭제된 대산의 투표권은 결과 집계되지 않습니다.</li>
          <li>삭제 후에는 서명상태와 상관없이 복구할 수 없습니다.</li>
          <li>삭제된 대상은 더 이상 투표를 진행할 수 없습니다.</li>
        </ul>
      </CancelModal>
      <VoteDelegateModal
        voteId={voteId}
        signaturesId={selectedMember ? selectedMember._id : ""}
        isDelegateOpen={isDelegateOpen}
        onDelegateClose={onDelegateClose}
      />
      <VoteAddMemberModal isOpen={isAddMemberOpen} onClose={onAddMemberClose} />
      <VoteNotifyResend
        isOpen={isResendModalOpen}
        onClose={onResendModalClose}
        voteId={voteId}
        selectMember={selectedMember ? selectedMember : undefined}
        unsignedCount={unsignedMembersCount}
      />
    </>
  );
}
