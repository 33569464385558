import { InputProps } from "@chakra-ui/react";
import { InputStyle } from "../styled";

export const SmallInputs: React.FC<InputProps> = ({ ...props }) => {
  return (
    <InputStyle
      minW="250px"
      w="auto"
      variant="unstyled"
      style={props.style}
      autoComplete="off"
      {...props}
      // onChange={(e) => {
      //   noSpaceFunc(e);
      //   console.log(e.target.value, e.target.value.replaceAll(" ", ""));
      // }}
    />
  );
};

export default SmallInputs;
