import { Fragment } from "react";

const steps = [{ title: "투표대상 선택" }, { title: "투표기간 설정" }, { title: "투표발송" }];

type Props = {
  activeStep: number;
};

export default function VoteStepper({ activeStep }: Props) {
  return (
    <div className="mx-auto flex w-[728px] items-center justify-center gap-2 py-5">
      {steps.map((step, index) => {
        return (
          <Fragment key={index}>
            <div className="flex items-center gap-2">
              <p
                className={`flex h-6 w-6 items-center justify-center rounded-full border text-[10px] font-bold transition-colors duration-500 
            ${
              activeStep === index + 1
                ? "border-primary text-primary"
                : activeStep > index + 1
                  ? "border-blue-200 text-blue-200"
                  : "border-gray-400 text-gray-400"
            }`}
              >
                0{index + 1}
              </p>
              <p
                className={`text-sm font-medium transition-colors duration-500  ${
                  activeStep === index + 1 ? "text-primary" : activeStep > index + 1 ? "text-blue-200" : "text-gray-400"
                }`}
              >
                {step.title}
              </p>
            </div>
            {steps.length > index + 1 && (
              <div
                className={`h-[1px] flex-1 transition-colors duration-1000 ease-in-out ${activeStep > index + 1 ? "bg-blue-200" : "bg-gray-400"}`}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
}
