import { NewPostBox } from "../../../components/styled";
import { Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const SurveyBox = styled(NewPostBox)`
  margin: 20px 0;
  border-top: 1px solid #d1d5db;
  font-weight: 400;
`;

export const SurveyDate = styled.div`
  width: 424px;
  border: 1px solid #9ca3af;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }
`;

export const SurveyTextBox = styled.div<{ isDirect?: boolean }>`
  color: #6b7280;
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  ${({ isDirect }) => isDirect && `cursor:not-allowed; opacity:0.7`};
`;

export const CloseBox = styled(Flex)`
  align-items: center;
  padding: 0 44px 0 12px;
`;

export const SurveyImage = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
  width: 88px;
  height: 88px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
`;

//questionItems
export const CategoryBox = styled(Flex)`
  align-items: center;
  padding: 4px 0;
  p {
    font-weight: 500;
    color: #111827;
  }
`;

//answerItems
export const AnswerCategory = styled(Flex)`
  align-items: flex;
  padding: 4px 0;
  p {
    font-weight: 500;
    color: #111827;
  }
`;
