import { Button, DrawerBody, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState, useContext } from "react";
import moment from "moment";
import * as D from "../style";
import ReplyListItem from "./ReplyListItem";
import {
  ContourWrapper,
  DetailTitleBox,
  GrayContour,
  HeaderArrowBox,
  HeaderIconBox,
  WrapperGround,
  WriterInfo,
  DetailBodyBox,
  FlexibleTextArea,
} from "../../../../components/styled/webApp";
import { ImageArr, InfoBoxText, ShowImages } from "../../../../components/styled/index";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { FaHistory } from "@react-icons/all-files/fa/FaHistory";
import { HiLocationMarker } from "@react-icons/all-files/hi/HiLocationMarker";
import { MdEdit } from "@react-icons/all-files/md/MdEdit";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import ReplyItem from "./replyItem";
import RespCancelModal from "../../../../components/modal/responsive/cancel";
import { IRequestDetailUI } from "../../commons/types";
import { IComment, IRequestHistory } from "../../../../lib/types/Imodels";
import CommonDrawerModal from "../../../../components/Drawer";
import ChangedTeamInDetail from "./selectTeam";
import ChangedStatusInModal from "./selectStatus";
import { format_history_type, format_process } from "../../commons/format";
import { useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { complaint_history_key } from "../../../../lib/api/queries/keys";
import { complaint_history } from "../../../../lib/api/queries/urls";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import StandardMenuSheet from "../../../../components/Menu";
import CustomCategoryModal from "./customCategoryModal";
import { GlobalContext } from "../../../../App";
import styled from "@emotion/styled";

const ActionClickBox = styled.div`
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  width: calc(100% - 173px);

  svg {
    font-size: 1.25rem;
    margin-left: 4px;
    color: #2563eb;
  }
`;

export default function RequestDetailComponent(rest: IRequestDetailUI) {
  const { auth, data, ...props } = rest;

  // db 연결 후 삭제
  const { buildInfo } = useContext(GlobalContext);

  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false); //답글 수정 여부
  const [comment, setComment] = useState<IComment>(); //특정 답글 선택
  const [commnetHeight, setCommnetHeight] = useState(72); // 전체 답글 높이 팝업
  // console.log(document.querySelector("#writer")?.clientHeight);  높이 스테이트 처리 필요
  const [complaintHeight, setComplaintHeight] = useState<number>();
  const [modalType, setModalType] = useState<boolean>(false);
  const [customCategoryOption, setCustomCategoryOption] = useState<boolean>(false);

  const { isOpen, onOpen, onClose } = useDisclosure(); // 삭제 여부
  const { isOpen: isMemo, onOpen: onMemo, onClose: offMemo } = useDisclosure(); // 관리자 메모
  const { isOpen: isHist, onOpen: onHist, onClose: offHist } = useDisclosure(); // 히스토리
  const { isOpen: status, onOpen: onStat, onClose: offStat } = useDisclosure(); //처리 상태 변경 모달
  const { isOpen: isCustomCateogry, onOpen: onCustomCateogry, onClose: offCustomCateogry } = useDisclosure(); // 하위 카테고리 모달

  const modalRef = useRef<HTMLDivElement[] | null[]>([]);
  const isWriterWasMaster = data.writer_type === "building";
  const complaint_body = document.querySelector("#complaint_body");

  useEffect(() => {
    if (buildInfo?.options?.length) {
      for (let i = 0; i < buildInfo?.options?.length; i++) {
        if (buildInfo?.options[i].name === "민원 관리") {
          setCustomCategoryOption(buildInfo?.options[i].enable);
          break;
        }
      }
    }
  }, []);

  const onCloseOpenedModal = () => {
    if (props.isOpen) props.setIsOpen(false);
    Array.from(modalRef.current).forEach((el) => {
      if (!el) return;
      if (el.id === "true") el.click();
      // if (el !== e.currentTarget && el.style.display === "block") {
      //   return (el.style.display = "none");
      // }
    });
  };

  const onOpenMemoDrawer = () => {
    onMemo();
  };

  const onOpenHistoryDrawer = () => {
    onHist();
  };

  const { data: history } = useQuery(
    [complaint_history_key],
    () =>
      fetchClient
        .get<BaseResponse<IRequestHistory[]>>(complaint_history, {
          params: { complaint_id: data._id },
        })
        .then((res) => res.data.data),
    { enabled: isHist },
  );

  useEffect(() => {
    if (!data) return;

    if (data && complaint_body === null) return setComplaintHeight((data.body.split("\n").length + 1) * 24 * 1.5);
    setComplaintHeight(complaint_body?.scrollHeight);
  }, [data, complaint_body]);

  const onChangeCommentState = (idx: number) => {
    if (!data) return;
    // if (isEdit) return toast({ ...warning, title:"댓글 " });
    setIsEdit(true);
    const temp = [...data.comment];
    setComment(temp[idx]);
  };

  const phoneClick = (phoneNumber?: string) => {
    const isMobile = window.navigator.userAgent.includes("Mobi");

    if (isMobile) {
      window.location.href = `tel:${phoneNumber}`;
    }
  };

  /** 클릭한 값에 따라 모달 타입 변경  */
  const modalTypeClick = (paramType: boolean) => {
    if (paramType) {
      setModalType(true);
      onCustomCateogry();
    } else {
      setModalType(false);
      onCustomCateogry();
    }
  };

  const historyFormat = (paramCategory: string) => {
    const tempArray = paramCategory.split("/");
    const convertArray = [];

    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].length > 0) {
        convertArray.push(tempArray[i]);
      } else {
        convertArray.push("-");
      }
    }

    return convertArray.join("/");
  };

  return (
    <WrapperGround onClick={onCloseOpenedModal}>
      <ContourWrapper location={isWriterWasMaster ? "only" : ""}>
        <HeaderArrowBox>
          <FiChevronLeft onClick={() => navigate(-1)} />
          <HeaderIconBox>
            <div className="mobile">
              <img src="/memo.png" alt="메모" onClick={onOpenMemoDrawer} />
              {isOpen && (
                <RespCancelModal
                  title="민원을 삭제할까요?"
                  isOpen={isOpen}
                  next="삭제"
                  onClose={onClose}
                  onAction={props.onDeleteRequest}
                >
                  <Text>삭제된 데이터는 복구할 수 없습니다.</Text>
                  <Text>정말로 삭제하시겠습니까?</Text>
                </RespCancelModal>
              )}
              <FaHistory style={{ margin: "0 12px" }} onClick={onOpenHistoryDrawer} />
            </div>
            {isWriterWasMaster && auth === "edit" && (
              <StandardMenuSheet
                isOpen={props.isOpen}
                setIsOpen={props.setIsOpen}
                onEditAction={props.onClickRequestAction}
                onDeleteAction={onOpen}
              />
            )}
          </HeaderIconBox>
        </HeaderArrowBox>
        {isOpen && (
          <RespCancelModal
            title="민원을 삭제할까요?"
            isOpen={isOpen}
            next="삭제"
            onClose={onClose}
            onAction={props.onDeleteRequest}
          >
            <Text>삭제된 데이터는 복구할 수 없습니다.</Text>
            <Text>정말로 삭제하시겠습니까?</Text>
          </RespCancelModal>
        )}
        <D.LocationWithHeader>
          <D.RequestDetailTitle>
            <D.LocationDetail>
              <HiLocationMarker />
              <span>
                {data.dong ? `${data.dong}동 ` : ""}
                {data.floor ? `${data.floor}층 ` : ""}
                {data.location || ""}
              </span>
            </D.LocationDetail>
            <DetailTitleBox>
              {data.complaint_type}
              {data.complaint_sub_type && ` | ${data.complaint_sub_type}`}
            </DetailTitleBox>
          </D.RequestDetailTitle>
          <D.HeaderButtonBox>
            <Button variant="m_gray" mr={1} onClick={onOpenMemoDrawer}>
              <MdEdit /> 관리자 메모
            </Button>
            <Button variant="m_gray" onClick={onOpenHistoryDrawer}>
              <FaHistory /> 히스토리
            </Button>
          </D.HeaderButtonBox>
          {isMemo && (
            <CommonDrawerModal isOpen={isMemo} onClose={offMemo} title="관리자 메모">
              <DrawerBody>
                <D.AdminMemoArea
                  readOnly={auth !== "edit"}
                  onChange={(e) => props.onSaveMemo(e.target.value)}
                  defaultValue={props.adminMemo}
                  placeholder={
                    auth !== "edit"
                      ? `해당 기능은 열람만 가능합니다. \n수정 권한 필요 시 최고관리자에게 문의해주세요.`
                      : `관리자와 함께 공유할 메모사항이 있다면 작성해보세요. \n메모사항은 입주자에게 보이지 않아요!`
                  }
                />
              </DrawerBody>
            </CommonDrawerModal>
          )}
          {isHist && (
            <CommonDrawerModal isOpen={isHist} onClose={offHist} title="히스토리">
              <DrawerBody>
                {history?.map((el) => (
                  <D.DetailHistory key={String(el._id)}>
                    <h6>
                      {el.history_type === "change_type_0_0" ||
                      el.history_type === "change_type_1_1" ||
                      el.history_type === "change_type_2_2"
                        ? `카테고리 변경 (${el.complainCategory === "/" ? "-/-" : historyFormat(el.complainCategory)})`
                        : el.complainCategory === "" || el.complainCategory === undefined
                          ? format_history_type(el.history_type)
                          : `카테고리 변경 (${
                              el.complainCategory === "/" ? "-/-" : historyFormat(el.complainCategory)
                            })`}{" "}
                      {el.content && <>({el.content})</>}
                    </h6>
                    <div>
                      {moment.utc(el.createdAt).format("YY.MM.DD HH:mm ")}
                      <span style={{ marginLeft: "12px" }}>{el.user_name}</span>
                    </div>
                  </D.DetailHistory>
                ))}
              </DrawerBody>
            </CommonDrawerModal>
          )}
        </D.LocationWithHeader>
        <D.RequestActionBox>
          {customCategoryOption && (
            <div>
              <span style={{ width: "92px", minWidth: "92px" }}>민원 카테고리</span>
              {/* <D.ActionClickBox onClick={onStat}> */}
              <ActionClickBox onClick={() => modalTypeClick(true)}>
                <div
                  style={{
                    whiteSpace: "nowrap" /* 줄 바꿈 금지 */,
                    overflow: "hidden" /* 넘친 부분 감추기 */,
                    textOverflow: "ellipsis" /* 생략 부분에 ... 표시 */,
                    width: "100%",
                    color: "#2563EB",
                    textAlign: "right",
                  }}
                >
                  {data.complaintCategory
                    ? `${data.complaintCategory.mainCategory === "" ? "-" : data.complaintCategory.mainCategory}/${
                        data.complaintCategory.subCategory === "" ? "-" : data.complaintCategory.subCategory
                      }`
                    : "-/-"}
                </div>
                <FiChevronDown />
              </ActionClickBox>
              {status && (
                <ChangedStatusInModal
                  isOpen={status}
                  onClose={offStat}
                  type={data.writer_type}
                  data={data.progress_type}
                />
              )}
            </div>
          )}
          <div>
            상태
            {auth === "edit" ? (
              <D.ActionClickBox onClick={() => (customCategoryOption ? modalTypeClick(false) : onStat())}>
                {format_process(data.progress_type)}
                <FiChevronDown />
              </D.ActionClickBox>
            ) : (
              <div className="text-base font-medium text-blue-600">{format_process(data.progress_type)}</div>
            )}
            {status && (
              <ChangedStatusInModal
                isOpen={status}
                onClose={offStat}
                type={data.writer_type}
                data={data.progress_type}
              />
            )}
          </div>
          <div style={{ position: "relative" }}>
            담당
            {auth === "edit" ? (
              <ChangedTeamInDetail
                data={{
                  _id: String(data.department_id || ""),
                  name: data.department_name || "",
                }}
              />
            ) : (
              <div className="text-base font-medium text-blue-600">{data.department_name || "담당팀없음"}</div>
            )}
          </div>
        </D.RequestActionBox>
        <WriterInfo>
          {data.dong_ho && (
            <Flex>
              <Text>호실</Text>
              <InfoBoxText>
                {`${data.dong_ho.split(" ")[0]}동 ${data.dong_ho.split(" ")[1]}호 `}
                {data.tenant_xperp_name}
                {data.tenant_name ? `(${data.tenant_name})` : ""}
              </InfoBoxText>
            </Flex>
          )}
          {data.writer_type === "building" ? (
            <>
              {
                <Flex>
                  <Text>접수자</Text>
                  <InfoBoxText>
                    {data.user_name || ""} {data.user_phone_number || ""}
                  </InfoBoxText>
                </Flex>
              }
              {data.filed_user_name && (
                <Flex>
                  <Text>작성자</Text>
                  <InfoBoxText>
                    <InfoBoxText>
                      {data.filed_user_name || ""}
                      {data.filed_user_phone_number ? (
                        <span onClick={() => phoneClick(data.filed_user_phone_number)}>
                          {data.filed_user_phone_number}
                        </span>
                      ) : (
                        ""
                      )}
                    </InfoBoxText>
                  </InfoBoxText>
                </Flex>
              )}
            </>
          ) : (
            <Flex>
              <Text>신청자</Text>
              <InfoBoxText>
                <InfoBoxText>
                  {data.user_name || ""}
                  {data.user_phone_number ? (
                    <span onClick={() => phoneClick(data.user_phone_number)}>{data.user_phone_number}</span>
                  ) : (
                    ""
                  )}
                </InfoBoxText>
              </InfoBoxText>
            </Flex>
          )}
          <Flex>
            <Text>등록일시</Text>
            <InfoBoxText>{moment.utc(data.createdAt).format("YY-MM-DD HH:mm")}</InfoBoxText>
          </Flex>
        </WriterInfo>
        <DetailBodyBox>
          <FlexibleTextArea
            readOnly
            id="complaint_body"
            value={data.body}
            h={complaintHeight || 96} //(엔터키 기준 자른 배열 길이 * 폰트크기 * line-height) + 여유라인 1줄
          />
          {data.images.length !== 0 && (
            <ImageArr>
              {data.images.map((el, idx) => (
                <div key={el}>
                  <ShowImages
                    src={el}
                    // onLoad={() => setIsLoading(false)}
                    alt={`관련 이미지 ${idx + 1}`}
                    onClick={() => window.open(el, "_blank")}
                  />
                </div>
              ))}
            </ImageArr>
          )}
        </DetailBodyBox>
      </ContourWrapper>
      <>
        <GrayContour />
        <ContourWrapper location="center">
          <D.CommentHeader>
            <Text>답글 {data.comment ? data.comment.length : 0}</Text>
          </D.CommentHeader>
          <D.CommentListBox>
            {data.comment?.map((el, idx) => (
              <ReplyListItem
                key={el._id + el.body}
                refs={(el) => (modalRef.current[idx] = el)}
                data={el}
                idx={idx}
                editId={comment?._id}
                onChangeCommentState={onChangeCommentState}
              />
            ))}
          </D.CommentListBox>
        </ContourWrapper>
        <ContourWrapper location="last" style={{ padding: "0", minHeight: `${commnetHeight}px` }}>
          <D.WriteCommentBox>
            {auth === "edit" && (
              <ReplyItem
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                data={comment}
                commnetHeight={commnetHeight}
                setCommnetHeight={setCommnetHeight}
              />
            )}
          </D.WriteCommentBox>
        </ContourWrapper>
      </>
      {isCustomCateogry && (
        <CustomCategoryModal
          isOpen={isCustomCateogry}
          onClose={offCustomCateogry}
          modalType={modalType}
          type={data.writer_type}
          data={data.progress_type}
          selectedMainCategory={data.complaintCategory}
        />
      )}
    </WrapperGround>
  );
}
