import { Button, Flex, Table, Tr } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { colors } from "../../../../components/styled/common";

export const TableContainer = styled(Table)`
  width: 594px;
  margin-top: 44px;
  border: 1px solid ${colors.gray4};
  td:first-of-type,
  th:first-of-type {
    width: 15%;
    text-align: center;
    border-right: 1px solid ${colors.gray2};
  }
  th {
    height: 56px;
    font-weight: 400;
    border-bottom: 1px solid ${colors.gray2};
    font-size: 16px;
    color: #6b7280;
    padding: 16px 8px;
  }
  td {
    border-bottom: 1px solid ${colors.gray2};
    text-align: center;
    color: #6b7280;
    padding: 8px;
  }
`;

export const TableCustom = styled(Tr)`
  td {
    height: 56px;
    border-bottom: 1px solid ${colors.gray4};
  }
  td:last-of-type {
    text-align: left;
  }
`;

export const ImageSize = styled.img`
  width: 28px;
  height: 28px;
  margin: 0px 4px;
`;

export const FlexContainer = styled(Flex)`
  width: 594px;
  justify-content: space-between;
  padding-top: 20px;
`;

export const AddType = styled(Button)`
  font-weight: 500;
  margin-left: 0;
`;
