import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  UseModalProps,
  useToast,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import styled from "@emotion/styled";
import { queryClient } from "../../..";
import { GlobalContext } from "../../../App";
import { useCallback, useContext, useState } from "react";
import { success, unAuthUser, warning } from "../../../lib/theme/toast";
import * as C from "../../../components/styled/index";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { limitTitle } from "../../../lib/utils/format";
import { building_key } from "../../../lib/api/queries/keys";
import SingleRow from "../../../components/modal/table/singleRow";
import MoreContentInputs from "../../../components/Input/contents";
import { IBuilding, ICollection } from "../../../lib/types/Imodels";
import { management_office_update } from "../../../lib/api/queries/urls";
import { MemoizedContract } from "../../control/default/Collection";
import ConfirmModal from "../../../components/modal/ConfirmModal";

const DescriptBox = styled.div`
  padding: 8px 0;
  width: 100%;
`;

type keys = "name" | "phone_number" | "description";
export default function ManagementModal(props: UseModalProps) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure(); //취소 확인
  const { user, buildInfo } = useContext(GlobalContext);
  const [office, setOffice] = useState(buildInfo?.management_office_description || "");
  const newID = uuidv4();
  const newArr = buildInfo?.employee_contact_list;
  const initial: ICollection = {
    _id: newID,
    name: "",
    phone_number: "",
    description: "",
  };
  const [collection, setCollection] = useState<ICollection[]>(!newArr || newArr.length === 0 ? [initial] : newArr);

  const onChangeObj = useCallback(
    (el: ICollection, id: keys, value: string) => {
      const newOBj = { ...el };
      newOBj[id] = value;

      setCollection(collection.map((ele) => (ele._id === newOBj._id ? newOBj : ele)));
    },
    [collection],
  );

  const onUpdateManagement = () => {
    // console.log(collection);
    if (user?.user_type !== "master") return toast({ ...unAuthUser });
    const submit: {
      name: string;
      phone_number: string;
      description: string;
    }[] = []; //1. 전체 데이터 확인
    const err: string[] = []; //2-1. 하나라도 빈칸이 있을 경우 등록 X
    const required: string[] = []; //2-2. 빈칸 확인해서 데이터 제외하고 전송

    collection.forEach((el) => {
      const { _id, ...rest } = el;
      const empty = Object.keys(rest).filter((key) => rest[key as keyof typeof rest] === "");
      switch (empty.length) {
        case 3:
          return required.push(_id);
        case 0:
          return submit.push(rest);
        default:
          return err.push(_id);
      }
    });

    if (err.length !== 0)
      return toast({
        ...warning,
        title: "알림",
        description:
          "비상연락망 등록을 위해 담당자명 / 연락처 / 안내문구를 모두 입력해주세요. 모든 항목이 입력된 비상연락망만 등록됩니다.",
      });

    fetchClient
      .post<BaseResponse<IBuilding>>(management_office_update, {
        management_office_description: office,
        employee_contact_list: required.length === collection.length ? [] : submit,
      })
      .then((res) => {
        queryClient.invalidateQueries([building_key]);
        toast({
          ...success,
          title: "관리사무소 정보 수정 완료",
          description: `관리사무소 정보가 수정되었습니다.`,
        });
        props.onClose();
        return res.data.data;
      })
      .catch((res) => console.error(res));
  };

  const onAddNewContract = () => {
    const newArr = [...collection];
    newArr.push({ ...initial, _id: uuidv4() });

    setCollection(newArr);
  };

  return (
    <Modal
      variant="contents"
      isOpen={props.isOpen}
      onClose={props.onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <C.TableContent>
        <ModalHeader>관리센터 비상연락망</ModalHeader>
        <ModalBody w="864px">
          <Text variant="subTitle" pb="8px">
            관리센터 정보
          </Text>
          <div style={{ borderTop: "1px solid #6b7280" }}>
            <SingleRow w="20%" title="위치 / 운영시간">
              <DescriptBox>
                <MoreContentInputs
                  defaultValue={office || ""}
                  style={{ marginRight: "0" }}
                  isReadOnly={user?.user_type !== "master"}
                  placeholder="관리센터 위치와 운영시간을 최대 100자 이내로 입력해주세요."
                  onChange={(e) => {
                    const isLimit = limitTitle(e.target.value);
                    isLimit && setOffice(e.target.value);
                  }}
                />
              </DescriptBox>
            </SingleRow>
            {collection.map((el, index) => (
              <MemoizedContract
                key={el._id}
                el={el}
                idx={index + 1}
                auth={user?.user_type}
                onChangeObj={onChangeObj}
                data={collection}
                setCollection={setCollection}
              />
            ))}
          </div>
        </ModalBody>
        <ConfirmModal
          title="되돌아가기"
          onClickCancle={onClose}
          close="확인"
          onClose={onClose}
          isOpen={isOpen}
          onClickSave={() => {
            onClose();
            props.onClose();
          }}
          blockScrollOnMount={false}
        >
          <Text>지금까지 작성하신 내용은 저장되지 않습니다.</Text>
          <Text>오피스 설정 페이지로 이동할까요?</Text>
        </ConfirmModal>
        {user?.user_type === "master" ? (
          <ModalFooter display="flex" justifyContent="space-between">
            <Button variant="blueLine" ml="0" onClick={onAddNewContract}>
              비상연락망 추가
            </Button>
            <div>
              <Button variant="cancel" onClick={onOpen}>
                취소
              </Button>
              <Button variant="bgBlue" onClick={onUpdateManagement}>
                저장하기
              </Button>
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter display="flex" justifyContent="flex-end">
            <Button variant="cancel" ml="0" onClick={props.onClose}>
              확인
            </Button>
          </ModalFooter>
        )}
      </C.TableContent>
    </Modal>
  );
}
