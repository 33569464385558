//공통 로그인
export const userVerify = "building/token/verify";
export const login_key = "fetchLoginUser";
export const password_update_key = "building/profile/update_password";
export const user_detail_key = "building/profile";
export const user_list_key = "profile/list_by_department";

//건물 관련 key
export const building_key = "building";
export const building_update_key = "building/building/update";
export const building_list_key = "building_name/list";
export const building_floor_list_key = "building/floor";
export const building_floor_location_key = "dong_floor_location";
export const category_list_key = "category/list";

// 건물 FAQ
export const faq_list_key = "faq/list";

//관리자 소속 관련 endpoints
export const department_list_key = "department/list";
export const department_detail_key = "building/department";

//관리자 권한 관련 endpoints
export const permission_list_key = "building/auth/list";
export const permission_detail_key = "building/auth";

//입주사 관련 keys
export const tenant_detail_key = "/building/tenant";
export const tenant_delete_key = "building/tenant/delete";
export const tenant_update_key = "building/tenant/update";

//입주 카드 관련 keys
export const register_card_key = "building/resident_card/list";
export const register_card_detail_key = "building/resident_card";

//호실 관련 keys
export const empty_room_list_key = "room/empty"; //빈 호실 조회
export const company_list_key = "building/room/is_tanant";
export const room_list_or_detail = "building/room"; // +detail, +list
export const register_room_key = "building/room/create";
export const room_update_key = "building/room/update";
export const room_delete_key = "building/room/delete";

//공지 관련 keys
export const notice_list_key = "building/notice/list";
export const notice_detail_key = "building/notice";
export const notice_detail_update_key = "building/notice/update";
export const notice_category_key = "building/notice_category/list";
export const notice_viewer_key = "notice/reading_tenant";

//공용시설 관련 keys
export const schedule_list_key = "building/schedule/reserve/list";
export const schedule_detail_key = "building/schedule";
export const public_category_key = "building/room_public_category/list";
export const public_create_key = "building/room_public/create";
export const public_list_key = "building/room_public/list";
export const public_all_list_key = "all/building/room_public";
export const public_reserve = "public_reserve";
export const public_detail_key = "building/room_public";

//공용시설 예약 상품 관련 keys
export const booking_item_list_key = "reservation_product/list";
export const booking_item_key = "building/reservation_product";
export const booking_user_list_key =
  "schedule/reserve_reservation_product/list";

//포인트 관련 keys
export const tenant_point_list_key = "point/list";
export const tenant_point_history_key = "point/list_by_tenant";
export const tenant_point_key = "point/by_tenant";
export const regular_point_list_key = "tenant/rooms/point";

//좌석 예약 keys
export const seat_list_key = "building/seat/list";
export const seat_schedule_key = "/building/seat_schedule";
export const seat_schedule_list_key = "building/seat_schedule/list";

//관리비 관련 keys
export const manage_fee_key = "building/management_fee/list";
export const manage_fee_list_key = "building/management_fee_detail/list";
export const bank_list_key = "building/bank/list";
export const account_list_key = "building/account/list";
export const saved_fixed_fee_key = "management_fee_detail/save";

//민원 관련 keys
export const complaint_list_key = "building/complaint/list";
export const complaint_count_key = "building/complaint/count";
export const complaint_detail_key = "building/complaint";
export const company_search_key = "building/tenant/list";
export const complaint_history_key = "complaint/history_list";
export const complain_category_list = "building/complaint/complains_category_list"; // 민원 커스텀 카테고리 리스트 조회

//설문조사 관련 keys
export const survey_list_key = "building/survey/list";
export const survey_detail_key = "building/survey";
export const survey_delete = "building/survey/delete";

//주차 관련 keys
export const parking_history_keys = "building/car/list";
export const parking_detail_key = "building/car";

//알림 관련 keys
export const notification_group_key = "building/notification/group";
export const dashboard_group_key = "building/dashboard";

// **************** 임대건물용, 기본은 지산 기준 ********************************

// 임대료, 관리비 keys
export const manage_tenant_list_key = "room/tenants";
export const rental_list_key = "building/rent_fee/list";
export const detail_rental_key = "rent_fee";
export const tenant_rental_list_key = "tenant/rooms";
// export const create_manage_fee_direct = "room/tenants"

//온도 조절 관련 keys
export const temperature_list_key = "building/temperature/table";
export const temperature_history_key = "/building/temperature/list";

// 식단표 관련 keys
export const meal_planner_list_key = "cafeteria/list";
export const detail_meal_planner_key = "detail/cafeteria";

// **************** 공통 사용되는 key ********************************
export const registered_list_key = "room_public/category/list";
