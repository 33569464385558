import { Text, useDisclosure, useToast, Box } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { success, warning } from "../../../../lib/theme/toast";
import { fileImageValidate, limitContent } from "../../../../lib/utils/format";
import { IComplaintCommentType } from "../../commons/types";
import * as D from "../style";
import { useMutation } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { complaint_comment, complaint_comment_update } from "../../../../lib/api/queries/urls";
import { useParams } from "react-router-dom";
import { queryClient } from "../../../..";
import { complaint_detail_key } from "../../../../lib/api/queries/keys";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { IComment } from "../../../../lib/types/Imodels";
import { useUploadFile } from "../../../../lib/api/queries/commons";
import { DeleteIcon } from "../../../../components/styled";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import { BiUpArrowCircle } from "@react-icons/all-files/bi/BiUpArrowCircle";
import { IoClose } from "@react-icons/all-files/io5/IoClose";
import { AxiosError } from "axios";
import ResponsiveConfirm from "../../../../components/modal/responsive/confirm";

interface IMutationObject {
  complaint_id?: string;
  comment_id?: string;
  body: string;
  images: string[];
}

export default function ReplyItem(props: IComplaintCommentType) {
  const toast = useToast();
  const { complaintId } = useParams();
  const [files, setFiles] = useState<File[] | null>(null);
  const [imageArr, setImageArr] = useState<string[]>([]);
  const [body, setBody] = useState("");

  const textRef = useRef<HTMLTextAreaElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure(); //댓글 등록 모달

  useEffect(() => {
    if (!props.isEdit || !props.data) return;

    setBody(props.data.body);
    setImageArr(props.data?.images);
  }, [props.isEdit, props.data]);

  const onChangeComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const isLimit = limitContent(e.target.value);
    if (!isLimit) return;
    setBody(e.target.value);

    if (textRef.current === null) return;
    const count = e.target.value.split("\n").length;
    const contents = textRef.current?.scrollHeight;
    const defaultHeight = textRef.current?.clientHeight;

    if (count * 24 === contents && contents > defaultHeight) {
      if (props.commnetHeight !== 48 + contents) props.setCommnetHeight(48 + contents); //좋은 방법 확인 필요

      return (textRef.current.style.height = contents + "px");
    } else if (count * 24 < defaultHeight) {
      textRef.current.style.height = "0px";
      if (props.commnetHeight !== 48 + e.target.scrollHeight) props.setCommnetHeight(48 + e.target.scrollHeight);

      return (textRef.current.style.height = e.target.scrollHeight + "px");
    } else if (count * 24 === defaultHeight && defaultHeight < contents) {
      if (props.commnetHeight !== 48 + contents) props.setCommnetHeight(48 + contents);

      return (textRef.current.style.height = contents + "px");
    }
  };

  const onOpenComment = () => {
    if (body === "")
      return toast({
        ...warning,
        title: "답글 등록 오류",
        description: "답글을 등록하려면 내용은 필수 입력값입니다.",
      });
    onOpen();
  };

  const onClickUpload = () => {
    if ([...imageArr, ...(files || [])].length === 5) {
      return toast({
        ...warning,
        title: "파일 등록 실패",
        description: "첨부 가능한 파일 갯수는 5개입니다. 등록된 이미지 삭제 후 등록해주세요.",
      });
    }
    fileRef.current?.click();
  };

  const onRemoveComment = () => {
    setFiles(null);
    setImageArr([]);
    setBody("");
    textRef.current && (textRef.current.style.height = "24px");
    props.setCommnetHeight(72);
    if (props.isEdit && props.setIsEdit) props.setIsEdit(false);
  };

  const mutation = useMutation(
    (obj: IMutationObject) =>
      fetchClient
        .post<BaseResponse<IComment>>(props.isEdit ? complaint_comment_update : complaint_comment, obj)
        .then((res) => res.data.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([complaint_detail_key, complaintId]);
        toast({
          ...success,
          title: `${props.isEdit ? "수정" : "등록"}`,
          description: `민원에 대한 답글이 ${props.isEdit ? "수정" : "등록"}되었습니다.`,
        });
        onRemoveComment();
        onClose();
      },
      onError: (err: AxiosError) => {
        console.error(err);
      },
    },
  );

  const onMutateComment = () => {
    const obj: IMutationObject = {
      body,
      images: [],
    };

    props.isEdit ? (obj.comment_id = props.data?._id) : (obj.complaint_id = complaintId);

    if (files && files.length !== 0) {
      //새로 등록한 파일이 있다면
      useUploadFile(Array.from(files)).then((res) => {
        const newObj = props.isEdit ? { images: [...imageArr, ...res.data.data] } : { images: res.data.data };
        return mutation.mutate({ ...obj, ...newObj });
      });
    } else {
      mutation.mutate(props.isEdit ? { ...obj, ...{ images: imageArr } } : obj);
    }
  };

  const onloadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file === null) return; //input file

    const isVaild = fileImageValidate(file);
    if (!isVaild)
      return toast({
        ...warning,
        title: "파일 등록 실패",
        description:
          "첨부 가능한 파일 갯수는 5개, 한 파일당 크기는 10MB 이하여야합니다. 첨부 파일 형식은 GIF, JPG, JPEG, PNG 형식만 가능합니다. 파일 확인 후 동일한 에러가 반복될 경우에는 오피스너 담당자에게 문의해주세요.",
      });

    const over = file.length + [...imageArr, ...(files || [])].length;
    if (over > 5) {
      return toast({
        ...warning,
        title: "파일 등록 실패",
        description: "첨부 가능한 파일 갯수는 5개입니다. 등록한 이미지의 갯수를 먼저 확인해주세요.",
      });
    }

    setFiles([...(files || []), ...Array.from(file).map((el) => el)]);
    e.target.value = "";
  };

  const onDeleteFiles = (idx: number) => {
    if (files === null) return;

    const newArr = Array.from(files).filter((_, index) => index !== idx);
    setFiles(newArr);
  };

  const onUpdateFiles = (idx: number) => {
    const newArr = imageArr.filter((_, index) => index !== idx);
    setImageArr(newArr);
  };

  return (
    <>
      <D.AdminCommentImgBox id="writer" length={String(imageArr.length !== 0 || (files || []).length !== 0)}>
        {imageArr.map((el, idx) => (
          <Box key={el}>
            <img src={el} alt="이미지" />
            <DeleteIcon onClick={() => onUpdateFiles(idx)} />
          </Box>
        ))}
        {(files || []).map((el, idx) => (
          <Box key={el.name}>
            <img src={URL.createObjectURL(el)} alt="이미지" />
            <DeleteIcon onClick={() => onDeleteFiles(idx)} />
          </Box>
        ))}
      </D.AdminCommentImgBox>
      {props.isEdit && (
        <D.EditStatusBox>
          답글 수정중입니다. <IoClose onClick={onRemoveComment} />
        </D.EditStatusBox>
      )}
      <D.AdminTextAreaBox>
        <FiPlus onClick={onClickUpload} />
        <input type="file" multiple={true} ref={fileRef} onChange={onloadFile} />
        <textarea
          maxLength={3000}
          rows={1}
          value={body}
          ref={textRef}
          onChange={onChangeComment}
          placeholder="민원에 대한 답글을 작성해주세요."
        />
        <BiUpArrowCircle onClick={onOpenComment} />
        {isOpen && (
          <ResponsiveConfirm
            isOpen={isOpen}
            title={props.isEdit ? "답글 수정" : "답글 등록"}
            next="등록"
            before="취소"
            onClose={onClose}
            onAction={onMutateComment}
          >
            <Text>답글을 {props.isEdit ? "수정" : "등록"}하시겠습니까?</Text>
            <Text>해당 입주자에게 답글 알림이 전달됩니다.</Text>
          </ResponsiveConfirm>
        )}
      </D.AdminTextAreaBox>
    </>
  );
}
