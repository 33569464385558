import { UseModalProps, Checkbox, Text } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { queryClient } from "../../../..";
import GrayRadiusInput from "../../../../components/Input/responsive";
import ModalSaveWithContents from "../../../../components/modal/responsive/content";
import SingleRow, { RowBox } from "../../../../components/modal/table/singleRow";
import { ShadowBox } from "../../../../components/styled/webApp";
import { fetchClient } from "../../../../lib/api/axios";
import { permission_list_key } from "../../../../lib/api/queries/keys";
import { create_permission, delete_permission, update_permission } from "../../../../lib/api/queries/urls";
import { IAuth, IAuthDetail } from "../../../../lib/types/Imodels";
import { format_permission } from "../../../../lib/utils/dataFormat";
import { showPermission } from "../commons/func";
import { toast } from "../../../../lib/utils/toast";

interface ISetPermission extends UseModalProps {
  isEdit?: boolean;
  el?: IAuth;
}

type IAuthParams = {
  auth_id?: string;
  name: string;
  details: IAuthDetail[];
};

export default function AuthInModal({ isEdit, ...props }: ISetPermission) {
  // const { isOpen: isDel, onOpen: onDel, onClose: offDel } = useDisclosure(); 관리자 삭제
  const [name, setName] = useState("");
  const functional = showPermission();
  const [details, setDetails] = useState<IAuthDetail[]>(
    functional,
    // !isModel || isModel === "knowledge_industry_center"
    //   ? authArr
    //   : [...authArr, ...[{ key: "rent_fee", value: "read" }]]
  );

  useEffect(() => {
    if (!isEdit || !props.el) return;
    setName(props.el.name);
    setDetails(props.el.details);
  }, [isEdit, props.el]);

  const onSetPermission = (str: IAuthDetail, value?: string) => {
    const newObj = { ...str };
    if (value === "read") {
      newObj.value === "no_auth" ? (newObj.value = "read") : (newObj.value = "no_auth");
    } else {
      newObj.value === "edit" ? (newObj.value = "read") : (newObj.value = "edit");
    }

    const newArr = details.map((el) => (el.key === str.key ? newObj : el));
    setDetails(newArr);
  };

  const mutation = useMutation((data: IAuthParams) =>
    fetchClient
      .post(isEdit ? update_permission : create_permission, data)
      .then(() => {
        toast.success({
          title: `권한 ${isEdit ? "수정" : "생성"} 완료`,
          description: `${isEdit ? "해당 권한이 수정" : "신규 권한이 생성"}되었습니다. ${
            isEdit ? "" : "지금부터 해당 권한 선택이 가능합니다."
          }`,
        });
        // onClose();
        props.onClose();
        return queryClient.invalidateQueries([permission_list_key]);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400)
          return toast.warning({
            title: "권한 설정 오류",
            description: "권한 설정 시 이름은 필수 입력 항목입니다.",
          });
        if (err.response?.status === 402)
          return toast.warning({
            title: isEdit ? "권한 수정 오류" : "권한 생성 오류",
            description: "같은 권한이 이미 존재합니다. 권한을 수정한 후 다시 시도해주세요.",
          });
      }),
  );

  const delMutation = useMutation(() =>
    fetchClient
      .post(delete_permission, {
        auth_id: props.el?._id,
      })
      .then(() => {
        toast.success({
          title: "권한 삭제 완료",
          description: "해당 권한이 성공적으로 삭제되었습니다.",
        });
        // offDel();
        props.onClose();
        return queryClient.invalidateQueries([permission_list_key]);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 402)
          return toast.warning({
            title: "권한 삭제 오류",
            description: "해당 권한을 가진 관리자가 존재합니다. 삭제할 권한을 확인 후 다시 시도해주세요.",
          });
      }),
  );

  const onDeletePermission = () => {
    if (delMutation.isLoading) return;
    delMutation.mutate();
  };

  const onSavePermission = () => {
    const newObj: IAuthParams = { name, details };
    if (isEdit) newObj.auth_id = String(props.el?._id);
    if (mutation.isLoading) return;
    if (!name && !isEdit)
      return toast.warning({
        title: "권한 생성 오류",
        description: "권한 생성 시 권한 이름은 필수 입력 항목입니다.",
      });

    mutation.mutate(newObj);
  };

  const onClickCloseAuth = () => {
    if (isEdit) {
      if (!props.el) return;
      setName(props.el.name);
      setDetails(props.el.details);
    } else {
      setName("");
      setDetails(
        functional,
        // !isModel || isModel === "knowledge_industry_center"
        //   ? authArr
        //   : [...authArr, ...[{ key: "rent_fee", value: "read" }]]
      );
    }
    props.onClose();
  };

  return (
    <ModalSaveWithContents
      isOpen={props.isOpen}
      onClose={onClickCloseAuth}
      title={isEdit ? "권한 상세 정보" : "신규 권한"}
      next="저장하기"
      before="취소"
      onAction={onSavePermission}
      scrollBehavior="inside"
    >
      <ShadowBox isModal={true} isMore={true} style={{ padding: "0 0 1rem 0" }}>
        <div style={isEdit ? {} : { paddingBottom: "12px" }}>
          <h4>권한명을 입력해주세요.</h4>
          <GrayRadiusInput
            placeholder="권한명을 입력해주세요."
            readOnly={isEdit ? true : false}
            isLimited={isEdit ? false : true}
            value={name}
            _onChange={(e) => setName(e.target.value)}
            // {...register("prev")}
          />
        </div>
        <div>
          <h4 style={{ borderBottom: "1px solid #6b7280" }}>권한을 선택해주세요.</h4>
          {details.map((el) => (
            <SingleRow w="50%" key={el.key} title={format_permission(el.key)}>
              <RowBox>
                <Checkbox
                  w="100%"
                  variant="default"
                  isChecked={el.value === "edit"}
                  onChange={() => onSetPermission(el, "edit")}
                >
                  수정가능
                </Checkbox>
                <Checkbox
                  w="100%"
                  variant="default"
                  onChange={() => onSetPermission(el, "read")}
                  isChecked={el.value === "read" || el.value === "edit"}
                >
                  열람가능
                </Checkbox>
              </RowBox>
            </SingleRow>
          ))}
        </div>
        {isEdit && (
          <div>
            <Text variant="text_red" textDecoration="underline" cursor="pointer" onClick={onDeletePermission}>
              권한 삭제
            </Text>
          </div>
        )}
      </ShadowBox>
    </ModalSaveWithContents>
  );
}
