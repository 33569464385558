import { useForm } from "react-hook-form";
import { useSendSlackMessageMutation } from "../../../requests/useSendSlackMessageMutation";
import CustomToast from "../../../components/CustomToast";
import CustomModal from "../../../components/CustomModal";
import toast from "react-hot-toast";
import Input from "../../../components/Input/Input";
import IconPhone from "../../../components/icons/IconPhone";
import { useState } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  userName: string;
  email: string;
};

export default function WascoRequestModal({ isOpen, onClose, name, userName, email }: Props) {
  const { mutateAsync: sendSlackMessage } = useSendSlackMessageMutation();

  const onSubmit = (data: { phoneNumber: string }) => {
    toast.promise(
      sendSlackMessage({
        text: `오피스너 관리자 페이지
${name} 건물에서 WASCO 무료 컨설팅 요청을 하였습니다.
담당자: ${userName}
이메일: ${email}
연락처: ${data.phoneNumber}`,
      }),
      {
        success: () => {
          onClose();
          return (
            <CustomToast
              iconType="check"
              title="수도 절감 무료 컨설팅 신청 완료"
              description="신청해주셔서 감사합니다. 곧바로 유선으로 안내드리겠습니다."
            />
          );
        },
        error: () => {
          onClose();
          return <CustomToast iconType="error" title="무료 컨설팅 신청 중 에러가 발생하였습니다." />;
        },
        loading: <CustomToast iconType="loading" title="Loading" />,
      },
    );
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      phoneNumber: "",
    },
  });

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title="수도세 절감 무료 컨설팅"
      exit={true}
      primaryButtonText="무료 컨설팅 신청"
      onSubmit={handleSubmit(onSubmit)}
      neutralButtonText="취소"
      onNeutralButtonClick={onClose}
      primaryButtonDisable={!!errors.phoneNumber}
    >
      <div className="flex w-[488px] flex-col gap-3 p-6 pt-3">
        <div className="font-base flex gap-1 font-semibold leading-6">
          <span className="text-gray-700">연락처</span>
          <span className="text-blue-500">*</span>
        </div>
        <span className="text-base font-normal leading-6 text-gray-500">
          연락처를 남겨주시면, 저희 오피스너 컨설턴트팀이 유선으로 안내드리겠습니다.
        </span>

        <div className="flex flex-col gap-1">
          <Input
            themeSize="md"
            placeholder="연락처를 입력해주세요"
            iconElement={<IconPhone />}
            type="number"
            {...register("phoneNumber", {
              required: "연락처는 필수 입력 사항입니다.",
              pattern: {
                value: /^[0-9]+$/,
                message: "숫자만 입력해주세요.",
              },
            })}
            onBlur={(e) => setValue("phoneNumber", e.target.value.trim())}
          />
          {errors.phoneNumber && <p className="text-xs leading-normal text-red-500">{errors.phoneNumber.message}</p>}
        </div>
      </div>
    </CustomModal>
  );
}
