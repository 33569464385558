import { Flex, Radio, RadioGroup } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { ICreateCard } from "..";
import { onPreventInput } from "../../../components/hooks/useInputEvent";
import { UploadFile } from "../component/File";
import * as S from "../styled";
import { IOpenSection } from "../types";

const RadioBox = styled(Flex)`
  span {
    font-size: 14px;
  }
`;

const Bullet = styled.ul`
  padding-left: 8px;
  li {
    list-style: inside;
  }
`;

export default function RegularCar(props: IOpenSection) {
  const { control, register, getValues } = useFormContext<ICreateCard>();
  const car_files = useFieldArray({ control, name: "car.files" });

  return (
    <S.ToggleBox>
      <S.ToggleTitle onClick={() => props.setIsOpen((prev) => !prev)}>
        6. 정기 주차등록 (호실별 1대 한정)
        <S.ToggleIcon src="/arrow.png" alt="화살표 아이콘" isOpen={props.isOpen} />
      </S.ToggleTitle>
      <S.ToggleContent
        id="status"
        isHeight={(document.getElementById("status")?.scrollHeight || 0) + 100}
        isOpen={props.isOpen}
      >
        <S.InfoText>
          입주자는 1대까지 한해 무료 정기주차 차량으로 등록할 수 있습니다. 임시차량등록, 추가 정기주차등록, 차량 변경은
          관리사무소로 문의바랍니다.
        </S.InfoText>
        <S.InputBox>
          <S.InputName>
            차량 등록 여부 <span>*</span>
          </S.InputName>
          <Controller
            control={control}
            name="is_car"
            render={({ field: { onChange, value } }) => (
              <RadioGroup value={String(value)} onChange={onChange}>
                <RadioBox>
                  <Radio w="50%" value="true" mr="2">
                    예
                  </Radio>
                  <Radio w="50%" value="false" mr="2">
                    아니오
                  </Radio>
                </RadioBox>
              </RadioGroup>
            )}
          />
        </S.InputBox>
        <S.InputBox>
          <S.InputName>성명 {getValues("is_car") && <span>*</span>}</S.InputName>
          <input {...register("car.name")} />
        </S.InputBox>
        <S.InputBox>
          <S.InputName>차량번호 {getValues("is_car") && <span>*</span>}</S.InputName>
          <input {...register("car.number")} />
        </S.InputBox>
        <S.InputBox>
          <S.InputName>차종 {getValues("is_car") && <span>*</span>}</S.InputName>
          <input {...register("car.type")} />
        </S.InputBox>
        <S.NumberBox>
          <S.InputName>연락처 {getValues("is_car") && <span>*</span>}</S.InputName>
          <input
            type="number"
            style={{ marginRight: "4px" }}
            onKeyDown={(e) => onPreventInput(e, 3)}
            onWheel={(e) => e.currentTarget.blur()}
            {...register("car.first")}
          />
          -
          <input
            type="number"
            style={{ marginRight: "4px" }}
            onKeyDown={(e) => onPreventInput(e, 3)}
            onWheel={(e) => e.currentTarget.blur()}
            {...register("car.second")}
          />
          -
          <input
            type="number"
            style={{ marginRight: "4px" }}
            onKeyDown={(e) => onPreventInput(e, 3)}
            onWheel={(e) => e.currentTarget.blur()}
            {...register("car.third")}
          />
        </S.NumberBox>
        <UploadFile
          title="차량등록증 (차량 소유자가 법인 또는 리스, 렌탈일 경우 이면계약서
            첨부)"
          required={getValues("is_car") || false}
          contract={car_files}
        />
      </S.ToggleContent>
    </S.ToggleBox>
  );
}
