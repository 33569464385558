import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import {
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { PropsWithChildren } from "react";

interface ISelectBox extends PropsWithChildren {
  default: string;
  text: string[] | string | undefined;
  checkedItems: string[];
  // setCheckedItems: Dispatch<SetStateAction<string[]>>;
  onCheckedAll: (checked: boolean) => void;
  typeArr?: string[];
}

const ListStyle = styled(MenuItem)`
  color: #6b7280;
  border-bottom: 1px solid #f4f4f5;
  padding: 8px 12px;
  text-align: left;
`;

const CrossMenu = styled(MenuButton)`
  -webkit-padding-before: 9px;
  -moz-padding-after: 9px;
  -moz-padding-before: 9px;
  -ms-padding-after: 9px;
  -ms-padding-before: 9px;
`;

const ButtonContents = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectConditionBox: React.FC<ISelectBox> = ({ children, ...props }) => {
  return (
    <>
      <Menu variant="contents" closeOnSelect={false}>
        <CrossMenu type="button">
          <ButtonContents>
            {typeof props.text === "string"
              ? props.text
              : props.text?.length === props.typeArr?.length
              ? "전체"
              : `선택 : ${props.text?.length}`}
            <FiChevronDown fontSize="large" />
          </ButtonContents>
        </CrossMenu>
        <MenuList height={"224px"} overflowY={"scroll"}>
          <MenuOptionGroup type="checkbox" scale="100px">
            <ListStyle value={`${props.default}`}>
              <Checkbox
                variant="default"
                isChecked={
                  props.checkedItems?.length === 0 ||
                  props.checkedItems?.length !== props.typeArr?.length
                    ? false
                    : true
                }
                onChange={(e) => props.onCheckedAll(e.target.checked)}
                w={"100%"}
                value={JSON.stringify(props.checkedItems)}
              >
                {props.default}
              </Checkbox>
            </ListStyle>
            {children}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </>
  );
};

export default SelectConditionBox;
