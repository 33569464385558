export default function BlueEamil() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.37868 5.52126C2.5 6.37554 2.5 7.75047 2.5 10.5003C2.5 13.2502 2.5 14.6251 3.37868 15.4794C4.25736 16.3337 5.67157 16.3337 8.5 16.3337H11.5C14.3284 16.3337 15.7426 16.3337 16.6213 15.4794C17.5 14.6251 17.5 13.2502 17.5 10.5003C17.5 7.75047 17.5 6.37554 16.6213 5.52126C15.7426 4.66699 14.3284 4.66699 11.5 4.66699H8.5C5.67157 4.66699 4.25736 4.66699 3.37868 5.52126ZM14.9321 7.23356C15.131 7.46558 15.0988 7.81042 14.8601 8.00378L13.2127 9.33845C12.548 9.87705 12.0092 10.3136 11.5336 10.611C11.0382 10.9207 10.5558 11.1164 10 11.1164C9.44419 11.1164 8.96176 10.9207 8.46638 10.611C7.99084 10.3136 7.45203 9.87706 6.78727 9.33846L5.1399 8.00378C4.90124 7.81042 4.869 7.46558 5.06788 7.23356C5.26676 7.00153 5.62145 6.97018 5.8601 7.16354L7.47928 8.47537C8.179 9.04227 8.6648 9.43459 9.07494 9.69104C9.47196 9.9393 9.7412 10.0226 10 10.0226C10.2588 10.0226 10.528 9.9393 10.9251 9.69104C11.3352 9.43459 11.821 9.04227 12.5207 8.47537L14.1399 7.16354C14.3786 6.97018 14.7332 7.00153 14.9321 7.23356Z"
        fill="#2563EB"
      />
    </svg>
  );
}
