import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { CSSProperties } from "react";
import { TableContent } from "../../styled";

interface Modaltype extends ModalProps {
  title: string;
  style?: CSSProperties;
  blockScrollOnMount?: boolean;
  element?: JSX.Element;
}

const Header = styled(ModalHeader)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

//재사용 -> 확인버튼만 있는 모달창
const InfoModal: React.FC<Modaltype> = ({ children, ...props }) => {
  return (
    <Modal
      variant="contents"
      isOpen={props.isOpen}
      onClose={props.onClose}
      blockScrollOnMount={props.blockScrollOnMount}
    >
      <ModalOverlay />
      <TableContent style={props.style}>
        <Header>
          <div>{props.title}</div>
          {props.element && <div>{props.element}</div>}
        </Header>
        <ModalBody>{children}</ModalBody>
        <ModalFooter justifyContent="flex-end">
          <Button variant="cancel" onClick={props.onClose}>
            확인
          </Button>
        </ModalFooter>
      </TableContent>
    </Modal>
  );
};

export default InfoModal;
