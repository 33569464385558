import { IUser } from "../../../lib/types/Imodels";
import styled from "@emotion/styled";
import { colors } from "../../../components/styled/common";
import { FixedTitle } from "../../../components/styled";
import { useDisclosure } from "@chakra-ui/react";
import UserInfoInDrawer from "./details/userInfo";
import { useContext } from "react";
import { GlobalContext } from "../../../App";
import { usefetchUserDetails } from "./commons/queries";

const ShowUserItem = styled.div<{ isMaster: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  border-bottom: 1px solid ${colors.gray1};
  cursor: ${({ isMaster }) => (isMaster ? "pointer" : `auto`)};
  > div {
    width: 15%;
    text-align: left;
  }
`;

export default function UserInfoInToggle({ el }: { el: IUser }) {
  const { user } = useContext(GlobalContext);
  const { isOpen, onOpen, onClose } = useDisclosure(); //관리자 상세 drawer
  const { data, isError } = usefetchUserDetails(isOpen, String(el._id));

  // const { data } = useQuery(
  //   [user_detail_key, el._id],
  //   () =>
  //     fetchClient
  //       .post<BaseResponse<IUser>>(user_detail, {
  //         user_id: el._id,
  //       })
  //       .then((res) => res.data.data)
  //       .catch((err: AxiosError) => {
  // toast({
  //   ...warning,
  //   title: "알림",
  //   description:
  //     err.response?.status === 504
  //       ? "이미 삭제된 관리자 계정입니다. 새로고침 후에도 동일한 오류 발생시 오피스너 담당자에게 연락주세요."
  //       : "일시적인 오류로 관리자 정보 불러오기에 실패했습니다. 잠시 후 다시 시도해주세요.",
  // });
  //         onClose();
  //         return undefined;
  //       }),
  //   {
  //     enabled: isOpen,
  //     retry: 0,
  //   }
  // );

  if (isError && isOpen) onClose();
  return (
    <ShowUserItem
      isMaster={user?.user_type === "master"}
      onClick={(e) => {
        e.stopPropagation();
        user?.user_type === "master" && onOpen();
      }}
    >
      <FixedTitle>{el.user_name}</FixedTitle>
      <div>{el.phone_number || ""}</div>
      <div>{el.auth_name}</div>
      <FixedTitle>{el.department_name ? el.department_name : "-"}</FixedTitle>
      <FixedTitle style={{ width: "40%" }}>{el.description || ""}</FixedTitle>
      {isOpen && data && (
        <UserInfoInDrawer isOpen={isOpen} onClose={onClose} data={data} />
      )}
    </ShowUserItem>
  );
}
