import styled from "@emotion/styled";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { ChangeEvent, CSSProperties, FC, PropsWithChildren } from "react";
import { colors } from "../styled/common";

interface IRadiusSelect extends PropsWithChildren {
  id?: string;
  value?: string;
  disabled?: boolean;
  defaultValue?: string;
  w?: string;
  _style?: CSSProperties;
  _onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const SelectBox = styled.div<{ w?: string }>`
  width: ${({ w }) => (w ? w : "7.5rem")};
  position: relative;
  cursor: pointer;
  svg {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    font-size: 1rem;
  }
`;

const Select = styled.select`
  -webkit-appearance: none; /* turn off drop-down arrow*/
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  padding: 0.75rem 1.25rem;
  border-radius: 0.5rem;
  background: ${colors.gray1};
  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

//모바일친화 select -> 컴포넌트화 변경 여부 미정
const GrayDropdown: FC<IRadiusSelect> = ({ ...props }) => {
  // console.log(props);
  const { _style, _onChange, children, w, ...rest } = props;

  return (
    <SelectBox w={w} style={_style}>
      <Select onChange={_onChange} {...rest}>
        {children}
      </Select>
      <FiChevronDown />
    </SelectBox>
  );
};

export default GrayDropdown;
