import NewSurveyPage from "../../new/page";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { fetchClient } from "../../../../lib/api/axios";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { survey_detail_key } from "../../../../lib/api/queries/keys";
import { survey_detail } from "../../../../lib/api/queries/urls";
import { ISurvey } from "../../../../lib/types/Imodels";
import useUserAuth from "../../../../components/hooks/useUserAuth";

export default function SurveyEditPage() {
  const { surveyId } = useParams();
  const auth = useUserAuth("survey");
  const { data } = useQuery(
    [survey_detail_key, surveyId],
    () =>
      fetchClient
        .post<BaseResponse<ISurvey>>(survey_detail, {
          survey_id: surveyId,
        })
        .then((res) => res.data.data),
    { enabled: auth === "edit" },
  );

  return <NewSurveyPage isEdit={true} data={data} />;
}
