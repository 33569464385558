import { MainActionBox, Required } from "../../../../components/styled/webApp";
import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import SubframePart from "../../../../components/Main/subframePart";
import { FacilityInfo } from "../_view/InfoItem";
import { ClosedDays, OperationTime } from "../operation/OperationItem";
import { RestrictUser } from "../restrict/_view/restrictItem";
import { IBasicInfo, IOperateInfo } from "../../commons/types";
import { useMutation } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { public_create } from "../../../../lib/api/queries/urls";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { useUploadFile } from "../../../../lib/api/queries/commons";
import { onFormatDays } from "../../commons/func";
import ShadowArrowStepBox from "../../../../components/step";
import ResponsiveConfirm from "../../../../components/modal/responsive/confirm";
import { toast } from "../../../../lib/utils/toast";

export default function CreateNewFacility() {
  const navigate = useNavigate();
  const { publicType } = useParams();
  const [isActive, setIsActive] = useState(0);
  const facilityTabArr = ["기본정보", "운영시간", "이용조건"];
  const { onOpen, isOpen, onClose } = useDisclosure(); //되돌아가기
  const { onOpen: onLast, isOpen: isLast, onClose: offLast } = useDisclosure(); //최종 등록
  const [images, setImages] = useState<File[] | null>(null);
  const [fileArr, setFileArr] = useState<string[]>([]);
  const [InfoObj, setInfoObj] = useState<IBasicInfo>({
    name: "",
    location: "",
    dong: "",
    floor: "",
  });
  const [timeObj, setTimeObj] = useState<IOperateInfo>({
    start_time_weekday: "00:00",
    end_time_weekday: "00:00",
  });
  const [holidays, setHolidays] = useState<string[]>([]); //휴무 문자열 조합
  const [isMonthly, setIsMonthly] = useState(true); //매주, 매달
  const dayOff = { holidays, setHolidays, isMonthly, setIsMonthly };
  const [isTenantArr, setIsTenantArr] = useState<string[]>([]); //입주사 유형

  const onClickNextStep = (el: string) => {
    if (el === "기본정보") {
      if (!images || images.length === 0)
        return toast.warning({
          title: "안내",
          description: "시설 대표 사진은 필수로 등록해주세요.",
        });

      if (!InfoObj.dong || !InfoObj.floor || !InfoObj.name)
        return toast.warning({
          title: "안내",
          description: "시설 등록을 위해 필수 입력 사항을 모두 입력해주세요.",
        });

      setIsActive(1);
    } else {
      //평일 기본 00:00 으로 해뒀기때문에 ""은 없음
      if (!timeObj.end_time_holiday || !timeObj.start_time_holiday)
        return toast.warning({
          title: "알림",
          description: "평일 혹은 주말 운영 시간을 정확히 설정해주세요.",
        });
      if (
        (timeObj.end_time_holiday === "00:00" && timeObj.start_time_holiday === "00:00") ||
        (timeObj.end_time_weekday === "00:00" && timeObj.start_time_weekday === "00:00")
      )
        return toast.warning({
          title: "알림",
          description: "운영 시간을 24시간으로 하려면 종료 시간을 24:00으로 선택해주세요.",
        });
      if (holidays.length === 0 || (holidays.includes("yes") && holidays.length === 1))
        return toast.warning({
          title: "알림",
          description: "휴무일을 선택해주세요.",
        });
      if (
        !holidays.includes("none") &&
        (!onFormatDays(holidays, "주").length || !onFormatDays(holidays, "요일").length)
      )
        return toast.warning({
          title: "알림",
          description: "휴무일을 모두 설정했는 지 다시 한번 확인해주세요.",
        });
      setIsActive(2);
    }
  };

  const kor = decodeURIComponent(publicType || "");
  const mutation = useMutation((urlArr: string[]) =>
    fetchClient
      .post(public_create, {
        room_public_type: kor,
        pictures: urlArr,
        name: InfoObj.name,
        dong: InfoObj.dong,
        floor: InfoObj.floor,
        detail_address: InfoObj.location,
        phone_number: InfoObj.number,
        description: InfoObj.description || "",
        // is_approval_required: true,
        start_time_weekday: `2000-01-01T${timeObj.start_time_weekday}:00Z`,
        end_time_weekday:
          timeObj.end_time_holiday === "24:00" ? "2000-01-01T23:59:59Z" : `2000-01-01T${timeObj.end_time_weekday}:00Z`,
        start_time_holiday: timeObj.start_time_holiday === "none" ? "" : `2000-01-01T${timeObj.start_time_holiday}Z`,
        end_time_holiday:
          timeObj.end_time_holiday === "none"
            ? ""
            : timeObj.end_time_holiday === "24:00"
              ? "2000-01-01T23:59:59Z"
              : `2000-01-01T${timeObj.end_time_holiday}Z`,
        regular_holiday_weeks: holidays.includes("none") ? [] : onFormatDays(holidays, "주"),
        regular_holiday_days: holidays.includes("none") ? [] : onFormatDays(holidays, "요일"),
        holidays: holidays.includes("none") ? [] : onFormatDays(holidays, "공휴일"),
        // people_num: num,
        available_rooms: isTenantArr,
        // options: optionArr.length === 0 ? [] : optionArr,
      })
      .then((res) => {
        toast.success({
          title: "시설 정보 등록 완료",
          description: "해당 시설 정보가 등록되었습니다. 시설 정보 화면으로 이동합니다.",
        });

        offLast();
        // navigate(`/time-type/${publicType}`);
        navigate(`/facility/${publicType}`);
        return res.data.data;
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400)
          return toast.warning({
            title: "시설 정보 등록 오류",
            description: "해당 시설과 같은 이름의 공용시설이 이미 존재합니다. 확인 후 다시 등록해주세요.",
          });
        if (err.response?.status === 401) return toast.unAuthUser();
      }),
  );

  const onClickBeforeCreate = async () => {
    if (images === null) return;
    if (isTenantArr.length === 0)
      return toast.warning({
        title: "알림",
        description: "이용 가능한 호실 용도를 선택해주세요.",
      });

    try {
      const url = await useUploadFile(images);
      // setFileUrl(newArr.data.data?.[0]);
      mutation.mutate(url.data.data);
    } catch (err) {
      toast.warning({
        title: "시설 정보 등록 오류",
        description: "시설 등록이 일시적으로 실패했습니다. 잠시 후 다시 시도해주세요.",
      });
    }
  };

  return (
    <SubframePart isModal={true} name="신규 시설 등록">
      <MainActionBox style={{ paddingBottom: "0.75rem" }}>
        <ShadowArrowStepBox step={facilityTabArr} isActive={isActive} />
        <Required>* 필수입력</Required>
        {isActive === 0 ? (
          <>
            <FacilityInfo
              InfoObj={InfoObj}
              setInfoObj={setInfoObj}
              images={images || []}
              setImages={setImages}
              fileArr={fileArr}
              setfileArr={setFileArr}
              auth="edit"
            />
            <Button variant="m_blue" fontSize="1.25rem" onClick={() => onClickNextStep("기본정보")}>
              다음
            </Button>
          </>
        ) : isActive === 1 ? (
          <>
            <OperationTime timeObj={timeObj} setTimeObj={setTimeObj} />
            <ClosedDays {...dayOff} />
            <Flex>
              <Button w="100%" mr="1rem" variant="m_gray" fontSize="1.25rem" onClick={onOpen}>
                이전
              </Button>
              <Button w="100%" variant="m_blue" fontSize="1.25rem" onClick={() => onClickNextStep("운영시간")}>
                다음
              </Button>
            </Flex>
          </>
        ) : (
          <RestrictUser isTenantArr={isTenantArr} setIsTenantArr={setIsTenantArr} />
        )}
        <ResponsiveConfirm
          next="이동"
          before="취소"
          title={isOpen ? "이전 단계 이동" : "신규 시설 등록"}
          isOpen={isOpen || isLast}
          onClose={isOpen ? onClose : offLast}
          onAction={() => {
            if (isOpen) {
              if (isActive === 1) {
                setTimeObj({
                  start_time_weekday: "",
                  end_time_weekday: "",
                });
                setHolidays([]);
                setIsActive(0);
              } else {
                setIsTenantArr([]);
                setIsActive(1);
              }
              onClose();
            } else {
              onClickBeforeCreate();
            }
          }}
        >
          {isOpen ? (
            <>
              이전 단계로 이동할까요? <br />
              이전 단계로 이동 시 입력한
              {isActive === 1 ? " 운영시간" : " 이용조건"} 정보는 모두 사라집니다.
            </>
          ) : (
            <>작성하신 정보로 시설을 등록할까요?</>
          )}
        </ResponsiveConfirm>
        {isActive === 2 && (
          <Flex>
            <Button w="100%" mr="1rem" variant="m_gray" fontSize="1.25rem" onClick={onOpen}>
              이전
            </Button>
            <Button w="100%" fontSize="1.25rem" variant="m_blue" onClick={onLast}>
              등록완료
            </Button>
          </Flex>
        )}
      </MainActionBox>
    </SubframePart>
  );
}
