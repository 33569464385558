import { useDisclosure, Checkbox, Select } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import * as C from "../../../components/styled/index";
import { CalendarRange } from "../../../components/datePicker";
import ContentInputs from "../../../components/Input/middleContents";
import { SearchType } from "./types";
import { useRoomType } from "../../../lib/utils";
import SelectCheckedBox from "../../../components/selectBox/checkedOption";
import SelectButton from "../../../components/selectBox/checkedOption/buttonItems";
import NoticeTableItems from "./NoticeTableItems";
import { useFetchCategory } from "../queries";
import MainHeaderPart from "../../../components/Main/HeaderPart";
import { Dispatch, SetStateAction, ChangeEvent } from "react";
import { INotice } from "../../../lib/types/Imodels";
import NoticeDeleteModal from "./NoticeDeleteModal";
import Button from "../../../components/button/Button";

type Props = {
  data: void | INotice[] | undefined;
  dateRange: (Date | null)[];
  setDateRange: Dispatch<SetStateAction<(Date | null)[]>>;
  checkedItems: string[];
  onCheckedAll: (checked: boolean) => void;
  onChangeChecked: (checked: boolean, id: string) => void;
  onClickSearch: () => void;
  onChangeSearch: (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
  notices: string[];
  onNoticesAll: (checked: boolean) => void;
  onChangeNotices: (checked: boolean, id: string) => void;
  searchArr: SearchType;
  auth: string;
  total: number;
  currentPage: number;
  onCreateNotice: () => void;
};

export default function NoticePageUI(props: Props) {
  const room_type = useRoomType();
  const { isOpen, onOpen, onClose } = useDisclosure(); //삭제 관련 모달
  const { data: category } = useFetchCategory();

  return (
    <>
      <MainHeaderPart isIcon={false} title="공지•일정 관리" authKey="notice">
        <p>
          공지사항 게시물은 오피스너 서비스를 통해 입주자에게 노출됩니다. 게시물 수정과 삭제는 게시글 상세에서 확인하고
          처리할 수 있습니다.
        </p>
        <p>
          중요글로 설정된 게시글은 게시판 상단에 고정 노출됩니다. 새로 작성하는 게시글 노출을 고려하여 중요글은 3~5개를
          권장합니다.
        </p>
        <button className="mt-5 rounded bg-primary px-3 py-2 font-medium text-white" onClick={props.onCreateNotice}>
          공지/일정 등록
        </button>
      </MainHeaderPart>
      <div className="pt-6">
        <div className="flex flex-wrap gap-2">
          <div>
            <p className="leading-relaxed">유형</p>
            <Select
              variant="default"
              name="전체"
              id="category"
              onChange={props.onChangeSearch}
              value={props.searchArr.category}
            >
              <option value="">전체</option>
              {category?.map((el) => (
                <option key={String(el._id)} value={el.title}>
                  {el.title}
                </option>
              ))}
            </Select>
          </div>
          <div>
            <p className="leading-relaxed">공지대상(복수선택가능)</p>
            <SelectCheckedBox
              checkedItems={props.checkedItems}
              onCheckedAll={props.onCheckedAll}
              default="전체"
              text={props.checkedItems.length === 4 || props.checkedItems.length === 0 ? "전체" : props.checkedItems}
            >
              {room_type.map((el) => (
                <SelectButton
                  key={el.id}
                  checkedItems={props.checkedItems}
                  onChangeChecked={props.onChangeChecked}
                  value={el.value}
                  label={el.label}
                />
              ))}
            </SelectCheckedBox>
          </div>
          <div>
            <p className="leading-relaxed">일정 유무</p>
            <Select variant="default" name="전체" id="schedule" defaultValue={""} onChange={props.onChangeSearch}>
              <option value="">전체</option>
              <option value="true">유</option>
              <option value="false">무</option>
            </Select>
          </div>
          <div>
            <p className="leading-relaxed">기간(등록일 기준)</p>
            <C.DateWithIconBox>
              <DatePicker
                locale={ko}
                selectsRange={true}
                startDate={props.dateRange[0]}
                endDate={props.dateRange[1]}
                dateFormat="yyyy-MM-dd"
                onChange={(update: (Date | null)[]) => props.setDateRange(update)}
                isClearable={true}
                customInput={<CalendarRange value={String(props.dateRange)} />}
              />
            </C.DateWithIconBox>
          </div>
        </div>
        <div className="flex items-end py-5">
          <div className="mr-2">
            <p className="leading-loose">검색</p>
            <Select
              variant="default"
              name="전체"
              defaultValue="제목+내용"
              // onChange={(e) => props.setNotiType(e.target.value)}
            >
              <option value="제목+내용">제목+내용</option>
            </Select>
          </div>
          <ContentInputs
            id="text"
            onChange={props.onChangeSearch}
            placeholder="검색어를 입력해주세요"
            onKeyDown={(e) => e.key === "Enter" && props.onClickSearch()}
          />
          <Button onClick={props.onClickSearch} designSchema={"secondaryGray"} buttonText={"검색"} size={"md"} />
        </div>
        <div>
          <div className="flex w-full items-center justify-between pb-2">
            <p>
              총 <span className="font-medium text-primary">{`${props.total || 0}`}개</span>의 게시물이 조회되었습니다.
            </p>
            {props.auth === "edit" && (
              <Button
                designSchema={"warning"}
                onClick={onOpen}
                disabled={props.notices.length === 0 ? true : false}
                buttonText={"삭제"}
                size={"md"}
              />
            )}
          </div>
          <C.ListTableBox>
            <C.ListTableHeader>
              <C.ListCheckBox style={{ width: "5%" }}>
                <Checkbox
                  variant="default"
                  onChange={(e) => props.onNoticesAll(e.target.checked)}
                  isChecked={props.notices.length === 0 || props.notices.length !== props.data?.length ? false : true}
                />
              </C.ListCheckBox>
              <div style={{ width: "10%" }}>유형</div>
              <div style={{ width: "10%" }}>공지대상</div>
              <div style={{ width: "35%" }}>제목</div>
              <div style={{ width: "10%" }}>일정유무</div>
              <div>작성자</div>
              <div>등록일시</div>
            </C.ListTableHeader>
            {props.data?.map((el) => (
              <NoticeTableItems
                key={String(el._id)}
                el={el}
                notices={props.notices}
                onChangeNotices={props.onChangeNotices}
              />
            ))}
          </C.ListTableBox>
          <NoticeDeleteModal isOpen={isOpen} onClose={onClose} noticeId={props.notices} />
        </div>
      </div>
    </>
  );
}
