import * as S from "../styled";
import { IOpenSection } from "../types";
import { UploadFile } from "../component/File";
import { useFormContext, useFieldArray } from "react-hook-form";
import { ICreateCard } from "..";

export default function AttachedDocs(props: IOpenSection) {
  const { control } = useFormContext<ICreateCard>();
  const contract = useFieldArray({
    control,
    name: "sales_or_lease_contract_files",
  });
  const business = useFieldArray({
    control,
    name: "business_registration_files",
  });

  return (
    <S.ToggleBox>
      <S.ToggleTitle onClick={() => props.setIsOpen((prev) => !prev)}>
        5. 첨부서류
        <S.ToggleIcon src="/arrow.png" alt="화살표 아이콘" isOpen={props.isOpen} />
      </S.ToggleTitle>
      <S.ToggleContent id="docs" isHeight={document.getElementById("docs")?.scrollHeight} isOpen={props.isOpen}>
        <UploadFile title="① 분양계약서 또는 임대차계약서" required={true} contract={contract} />
        <UploadFile title="② 사업자등록증사본, 신분증" required={true} contract={business} />
        {/* <UploadFile contract={carDocs} setContract={setCarDocs}>
          <S.DocsStyle>
            ③ 차량등록증(차량 소유자가 법인 또는 리스, 렌탈일 경우 이면계약서
            첨부)
          </S.DocsStyle>
        </UploadFile> */}
      </S.ToggleContent>
    </S.ToggleBox>
  );
}
