import { VoteDetailResponse } from "../../requests/vote";

export default function VoteResult(rest: VoteDetailResponse["data"]) {
  const { result = [], signatures, agendas, totalArea } = rest;

  //투표 완료한 숫자
  const voteDoneSignatures = signatures.filter((signature) =>
    signature.delegate.name ? signature.delegate.delegate_status : signature.signatureStatus,
  );

  const totalJoinArea = voteDoneSignatures.reduce((acc, signature) => {
    const signatureArea = signature.room_ids.reduce((acc, room) => (acc || 0) + (room.area || 0), 0);
    return acc + signatureArea;
  }, 0);

  const participationRate = (voteDoneSignatures.length / signatures.length) * 100;

  return (
    <div>
      <div className={"flex gap-2"}>
        <div className="flex flex-1 flex-col gap-3 rounded-lg border border-gray-200 bg-gray-50 p-5">
          <h5 className="text-sm font-semibold text-primary">투표 참여율</h5>
          <p className="text-xl font-bold">{participationRate ? participationRate.toFixed(2) : 0}%</p>
          <p className="text-sm font-medium text-gray-500">
            전체 인원수 {signatures.length}명 중 {voteDoneSignatures.length}명 참여
          </p>
        </div>
        <div className="flex flex-1 flex-col gap-3 rounded-lg border border-gray-200 bg-gray-50 p-5">
          <h5 className="text-sm font-semibold text-primary">투표 참여자 지분율</h5>
          <p className="text-xl font-bold">{totalJoinArea ? ((totalJoinArea / totalArea) * 100).toFixed(2) : 0}%</p>
          <div className="gap flex flex-col gap-1">
            <p className="text-sm font-semibold text-gray-900">
              전체 면적 {totalArea.toFixed(2)}㎡ 중 {totalJoinArea.toFixed(2)}㎡ 참여
            </p>
            <p className="text-sm font-medium text-gray-500">
              (지분율은 소수점 셋째 자리에서 반올림하여 소수점 둘째 자리까지 표시됩니다.)
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <p className="font-semibold text-gray-500">안건 별 결과</p>
        <table className="mt-5 w-full border-black text-left">
          <thead>
            <tr className="border-t">
              <th className="border-r"></th>
              <th className="border-r px-4 py-2">안건</th>
              <th className="border-r px-4 py-2">구분소유권</th>
              <th className="px-4 py-2">지분율</th>
            </tr>
          </thead>
          <tbody>
            {agendas.map((item, index) => {
              const agree = result[index]?.agree?.counts || 0;
              const disagree = result[index]?.disagree?.counts || 0;
              const invalid = result[index]?.invalid?.counts || 0;

              const agreeArea = result[index]?.agree?.area || 0;
              const disagreeArea = result[index]?.disagree?.area || 0;
              const invalidArea = result[index]?.invalid?.area || 0;

              return (
                <tr key={index} className="">
                  <td className="border border-l-0 px-4 py-2">
                    <span>{index + 1}</span>
                  </td>
                  <td className="border border-l-0 px-4 py-2">
                    <span>{item.title}</span>
                  </td>
                  <td className="border border-l-0 px-4 py-2 text-gray-300">
                    <ul className="list-disc pl-5">
                      <li>
                        찬성 : {agree}표 ({((agree / signatures.length) * 100).toFixed(2) || 0}
                        %)
                      </li>
                      <li>
                        반대 : {disagree}표 ({((disagree / signatures.length) * 100).toFixed(2) || 0}
                        %)
                      </li>
                      <li>
                        무효 : {invalid}표 ({((invalid / signatures.length) * 100).toFixed(2) || 0}
                        %)
                      </li>
                    </ul>
                  </td>
                  <td className="border border-x-0 px-4 py-2 text-gray-300">
                    <ul className="list-disc pl-5">
                      <li>
                        찬성 : {agreeArea}㎡ ({((agreeArea / totalArea) * 100).toFixed(2) || 0}
                        %)
                      </li>
                      <li>
                        반대 : {disagreeArea}㎡ ({((disagreeArea / totalArea) * 100).toFixed(2) || 0}
                        %)
                      </li>
                      <li>
                        무효 : {invalidArea}㎡ ({((invalidArea / totalArea) * 100).toFixed(2) || 0}
                        %)
                      </li>
                    </ul>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
