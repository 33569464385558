import { usePagination } from "@ajna/pagination";
import { ChangeEvent, useState } from "react";
import PaginationUI from "../../components/pagination";
import { useRoomType } from "../../lib/utils";
import { useNoticeSearch } from "./queries";
import NoticePageUI from "./units/presenter";
import { SearchType } from "./units/types";
import GetAuthorized from "../../components/Authrized";
import FullPageSpinner from "../../components/Spinner";
import useUserAuth from "../../components/hooks/useUserAuth";
import { useNavigate } from "react-router-dom";

export default function NoticePage() {
  const navigate = useNavigate();
  const auth = useUserAuth("notice");
  const room_type = useRoomType();

  const [allPage, setAllPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [dateRange, setDateRange] = useState<(Date | null)[]>([null, null]);
  const [startDate, endDate] = dateRange;
  const { currentPage, setCurrentPage } = usePagination({
    initialState: { currentPage: 1 },
  });
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  const onCheckedAll = (checked: boolean) => {
    if (checked) {
      const allCheckedArr: string[] = [];
      room_type?.forEach((el) => allCheckedArr.push(el.label));
      setCheckedItems(allCheckedArr);
    } else {
      setCheckedItems([]);
    }
  };

  const onChangeChecked = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      const newArr = checkedItems.filter((el) => el !== id);
      setCheckedItems(newArr);
    }
    if (checkedItems.length === room_type.length || 0) {
      return (checked = true);
    }
  };

  const [notices, setNotices] = useState<string[]>([]);
  const [searchArr, setSearchArr] = useState<SearchType>({
    category: "",
    schedule: "",
    status: "",
    text: "",
  });

  const onChangeSearch = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setSearchArr({
      ...searchArr,
      [e.target.id]: e.target.value,
    });
  };

  const { data, refetch } = useNoticeSearch(
    checkedItems,
    searchArr,
    startDate,
    endDate,
    currentPage,
    setAllPage,
    setTotal,
  );

  const onNoticesAll = (checked: boolean) => {
    if (checked) {
      const allCheckedArr: string[] = [];
      data?.forEach((el) => allCheckedArr.push(String(el._id)));
      setNotices(allCheckedArr);
    } else {
      setNotices([]);
    }
  };

  const onChangeNotices = (checked: boolean, id: string) => {
    if (checked) {
      setNotices([...notices, id]);
    } else {
      const newArr = notices.filter((el) => el !== id);
      setNotices(newArr);
    }
    if (notices.length === data?.length || 0) {
      return (checked = true);
    }
  };

  const onClickSearch = () => {
    if (currentPage !== 1) return setCurrentPage(1);
    refetch();
  };

  if (auth === undefined) return <FullPageSpinner />;
  if (auth === "no_auth") return <GetAuthorized />;

  return (
    <div className="flex min-h-full w-full flex-col rounded-2xl bg-white px-6 py-10 max-md:rounded-none ">
      <NoticePageUI
        data={data}
        dateRange={dateRange}
        setDateRange={setDateRange}
        checkedItems={checkedItems}
        onCheckedAll={onCheckedAll}
        onChangeChecked={onChangeChecked}
        onChangeSearch={onChangeSearch}
        onClickSearch={onClickSearch}
        notices={notices}
        onNoticesAll={onNoticesAll}
        onChangeNotices={onChangeNotices}
        searchArr={searchArr}
        total={total}
        currentPage={currentPage}
        auth={auth}
        onCreateNotice={() => navigate("/notice/new")}
      />
      <PaginationUI page={currentPage} allPage={allPage} setPage={setCurrentPage} />
    </div>
  );
}
