import { useNavigate } from "react-router-dom";

export type IAnalytics = {
  category: string;
  action: string;
};

export default function useMeansure() {
  const navigate = useNavigate();

  const onClickAnalytics = (obj: IAnalytics, page: string) => () => {
    navigate(page);
  };

  return { onClickAnalytics };
}
