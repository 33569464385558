import { Button, Tbody, Td, Text, Th, Tr, useToast } from "@chakra-ui/react";
import { uuidv4 } from "@firebase/util";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as C from "../../../components/styled/index";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { warning } from "../../../lib/theme/toast";
import { ICategory } from "../../../lib/types/Imodels";
import DropItems from "./units/dropItems";
import * as N from "./units/styled";

export default function NoticeClassification() {
  const navigate = useNavigate();

  const [result, setResult] = useState<string[]>();
  useEffect(() => {
    let data: ICategory[] = [];
    const newResult = async () => {
      await fetchClient
        .get<BaseResponse<ICategory[]>>("/building/notice_category/list")
        .then((res) => (data = res.data.data));

      const newArr = data.map((el) => el.title);
      // console.log(newArr);
      setResult(newArr);
    };
    newResult();
  }, []);

  const onClickAdd = () => {
    if (result !== undefined) {
      const newArr = [...result];
      newArr.push("");
      setResult(newArr);
    }
  };

  const toast = useToast();
  const [title, setTitle] = useState("");
  const onSendAdd = async () => {
    //
    if (title === "" || title === undefined) {
      return toast({
        ...warning,
        title: "저장 실패",
        description: "변경 사항을 다시 확인해주세요",
      });
    }
    await fetchClient
      .post("/building/notice_category/create", {
        title,
      })
      .then((res) => res.data.data)
      .catch((err) => err.message);
  };

  const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target.value);
    // const newRe
    //공지는 API 수정 후 작업 필요
    setTitle(e.target.value);
  };

  return (
    <C.Wrapper>
      <Text variant="static">
        <C.LeftIcons onClick={() => navigate(-1)} />
        공지/일정 게시글 분류
      </Text>
      <C.WrapperBody>
        <Text>공지,일정 게시글을 분류하여 등록할 수 있습니다.</Text>
        <Text>
          분류명과 순서 등 정보를 추가하거나 수정한 경우 저장하기를 해야
          적용됩니다.
        </Text>
        <N.TableContainer>
          <Tbody>
            <Tr>
              <Th>순서</Th>
              <Th>유형이름</Th>
            </Tr>
            {result?.map((el, idx) => (
              <>
                <DropItems
                  onChangeTitle={onChangeTitle}
                  el={el}
                  idx={idx + 1}
                  key={uuidv4()}
                />
              </>
            ))}
            <N.TableCustom>
              <Td>{(result?.length || 0) + 1}</Td>
              <Td textAlign="left">기타</Td>
            </N.TableCustom>
          </Tbody>
        </N.TableContainer>
        <N.FlexContainer>
          <N.AddType variant="blueLine" onClick={onClickAdd}>
            유형 추가
          </N.AddType>
          <Button variant="bgBlue" onClick={onSendAdd}>
            저장하기
          </Button>
        </N.FlexContainer>
      </C.WrapperBody>
    </C.Wrapper>
  );
}
