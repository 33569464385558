import { Box, Flex, ModalFooter, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { colors } from "../../../components/styled/common";

export const InputForm = styled.input`
  appearance: none;
  background-color: #fff;
  margin-right: 8px;
  border-radius: 0;
  border: 1px solid ${colors.gray3};
  padding: 8px 12px;
  ::placeholder {
    color: ${colors.gray2};
  }
  :disabled {
    border: none;
    padding: 0;
    -webkit-text-fill-color: ${colors.gray4};
  }
`;

export const TableTopBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 8px;
`;

export const sortBox = styled(Flex)`
  align-items: center;
  cursor: pointer;
  margin-right: 8px;
  color: ${colors.gray4};
  border-radius: 4px;
  background-color: ${colors.gray1};
  padding: 4px 8px;
  img {
    margin-right: 2px;
    width: 18px;
    height: 18px;
  }
`;

export const BalloonBox = styled.div`
  @media (max-width: 1500px) {
    .balloon {
      display: none;
    }
    &:hover .balloon {
      display: block;
    }
  }
`;

export const Balloon = styled.div`
  position: absolute;
  top: -55px;
  left: -60px;
  background-color: ${colors.gray1};
  border-radius: 28px;
  padding: 8px 12px;
  color: ${colors.gray4};
  font-weight: 400;
  :after {
    content: "";
    position: absolute;
    bottom: -20%;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: ${colors.gray1};
    border-bottom: 0;
    margin-left: -10px;
    margin-top: -10px;
  }
`;

export const UploadModalBox = styled(Box)`
  border-top: 1px solid ${colors.gray2};
  margin-top: 20px;
  padding-top: 12px;
`;

export const UploadBox = styled(Flex)`
  padding-top: 8px;
`;

export const NoticeBox = styled.ul`
  color: ${colors.red1};
  font-weight: 500;
  padding: 24px 24px 0 24px;
  li {
    list-style: disc;
  }
`;

export const SelectGroup = styled.select`
  -webkit-appearance: none;
  border: 1px solid ${colors.gray3};
  background-color: #ffffff;
  color: ${colors.gray3};
  width: 172px;
  border-radius: 0;
  -webkit-padding-before: 8px;
  padding: 7px 12px;
`;

export const AutoBox = styled.div`
  padding: 8px;
  background-color: ${colors.gray1};
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  :hover {
    div {
      display: block;
    }
  }
`;

export const AutoText = styled(Text)`
  font-size: 15px;
  font-weight: 500;
  padding: 0 4px 0 8px;
`;

//엑셀 아이콘
export const ExcelIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

//table CSS
export const DoubleColumn = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.gray2};
  min-height: 56px;
  color: ${colors.gray4};
  padding: 4px 0;
  > div {
    display: flex;
    align-items: center;
  }
`;

export const TitleBox = styled.div`
  width: 20%;
  min-width: 168px;
  font-weight: 500;
  color: ${colors.gray5};
`;

export const ContentBox = styled.div`
  width: 100%;
  min-width: 258px;
  flex-wrap: wrap;
  img {
    width: 44px;
    height: 44px;
  }
`;
//

export const HeaderTitle = styled.div`
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  vertical-align: middle;
`;

//등록 후 가입 유도 모달
export const StyledFooter = styled(ModalFooter)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 24px 32px;
`;
