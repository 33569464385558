import { Button, Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { fetchClient } from "../../../../lib/api/axios";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { IRoom } from "../../../../lib/types/Imodels";
import { checkout_owner_in_room } from "../../../../lib/api/queries/urls";
import { queryClient } from "../../../..";
import { room_list_or_detail } from "../../../../lib/api/queries/keys";
import { useParams } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import { toast } from "../../../../lib/utils/toast";

const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ModalTitle = styled.p`
  color: #111827;
  font-family: Noto Sans KR;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

const CancelButton = styled(Button)`
  display: flex;
  width: 128px;
  height: 48px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 1;
  border-radius: 8px;
  background-color: #f4f4f5;
  color: #6b7280;
`;

const SubmitButton = styled(Button)`
  display: flex;
  width: 128px;
  height: 48px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 1;
  border-radius: 8px;
  background-color: #f36151;
  color: #ffffff;

  &:hover {
    background-color: #f36151;
    box-shadow: none;
  }
  :hover[disabled] {
    background-color: #f36151;
    box-shadow: none;
  }
`;

const ContentsSpan = styled.span`
  color: #111827;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

interface owners {
  name: string;
  phone_number: string;
}

export default function ownerCheckoutModal(props: {
  onClose: () => void;
  isOpen: boolean;
  _id: string;
  name: string;
  setOwners: Dispatch<SetStateAction<owners[]>>;
}) {
  const { room_id } = useParams();

  const ownerCheckout = () => {
    fetchClient
      .patch<BaseResponse<IRoom>>(checkout_owner_in_room, {
        room_id: room_id,
        _id: props._id,
      })
      .then((res) => {
        console.log(props.name);
        toast.success({
          title: "소유자 퇴실 완료",
          description: `${props.name}님이 퇴실 처리되었습니다..`,
        });
        queryClient.invalidateQueries([room_list_or_detail]);
        props.onClose();
        if (res.data.data === null) {
          props.setOwners([]);
        } else {
          if (res.data.data.owners !== undefined) {
            if (res.data.data.owners.length > 0) {
              props.setOwners(res.data.data.owners);
            }
          }
        }
        return res.data.data;
      });
  };

  const onSaveClick = () => {
    ownerCheckout();
  };

  const onCloseDrawer = () => {
    props.onClose && props.onClose();
    return;
  };

  return (
    <Modal isOpen={props.isOpen} onClose={onCloseDrawer} variant="default" isCentered>
      <ModalOverlay />
      <ModalContent style={{ width: "312px" }}>
        <ModalBody>
          <ModalHead>
            <ModalTitle>소유자 퇴실 처리</ModalTitle>
          </ModalHead>
          <div style={{ marginBottom: "48px" }}>
            <div>
              <ContentsSpan>퇴실 처리시 해당 소유자가 더 이상 해당 호실</ContentsSpan>
            </div>
            <div>
              <ContentsSpan>로 이용할 수 없게 됩니다.</ContentsSpan>
            </div>
          </div>
          <BottomContainer>
            <CancelButton onClick={() => props.onClose()} w="128px" variant="m_gray" minW="128px">
              취소
            </CancelButton>
            <SubmitButton w="100%" minW="128px" onClick={onSaveClick}>
              퇴실
            </SubmitButton>
          </BottomContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
