import * as R from "../../commons/styled";
import { IRentalList } from "../../../../lib/types/Imodels";
import { ChangeEvent, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { fetchClient } from "../../../../lib/api/axios";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { update_rental_stauts } from "../../../../lib/api/queries/urls";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { warning } from "../../../../lib/theme/toast";
import { queryClient } from "../../../..";
import { detail_rental_key } from "../../../../lib/api/queries/keys";
import useUserAuth from "../../../../components/hooks/useUserAuth";
import { IDetailFeeTable } from "../../commons/types";

export default function RentalDetailItem({ el, ...props }: IDetailFeeTable) {
  const toast = useToast();
  const { rentalId } = useParams();
  const [status, setStatus] = useState(el.payment_status);
  const auth = useUserAuth("rent_fee");

  const onChangeStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    setStatus(value);
    fetchClient
      .post<BaseResponse<IRentalList>>(update_rental_stauts, {
        rentalId,
        rent_fee_details: [
          {
            _id: el._id,
            payment_status: value,
          },
        ],
      })
      .then(() => {
        queryClient.invalidateQueries([detail_rental_key]);
        alert(`관리비 납부 상태를 ${value === "checking" ? "확인 중으" : "납부완료"}로 변경하였습니다.`);
      })
      .catch(() => {
        toast({
          ...warning,
          title: "알림",
          description: "상태변경에 일시적으로 실패했습니다. 잠시 후 다시 시도해주세요.",
        });
        setStatus(value === "checking" ? "completed" : "checking");
      });
  };

  return (
    <R.TableBodyBox>
      <R.TableBox w={(props.length || 0) > 999} style={{ textAlign: "center" }}>
        {props.idx + 1}
      </R.TableBox>
      <R.Square w="17%">{el.tenant_name}</R.Square>
      <R.Square w="25%">
        {el.dong_ho.map((ele) => (
          <span key={uuidv4()}>
            {ele.dong}동 {ele.ho}호
          </span>
        ))}
      </R.Square>
      <R.SkySquare w="20%" style={{ textAlign: "right" }}>
        {el.price ? Number(el.price).toLocaleString("kr") : ""}
      </R.SkySquare>
      <R.SkySquare p={true} w="15%">
        {auth === "edit" ? (
          <R.RentalSelect
            color={status !== "completed" ? "#6b7280" : "#2563eb"}
            onChange={onChangeStatus}
            value={status || ""}
          >
            <option value="" disabled>
              상태 선택
            </option>
            <option value="checking">확인 중</option>
            <option value="completed">납부완료</option>
          </R.RentalSelect>
        ) : (
          <div style={{ textAlign: "center" }}>{status === "checking" ? "확인 중" : "납부 완료"}</div>
        )}
      </R.SkySquare>
      <div style={{ width: "20%" }} />
    </R.TableBodyBox>
  );
}
