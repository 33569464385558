import { Button, Modal, ModalContent, ModalOverlay, Text, useDisclosure, useToast } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import * as C from "../../../components/styled/index";
import * as R from "../../maintenance-fee/commons/styled";
import { useNavigate, useParams } from "react-router-dom";
import { BaseMutation, BaseResponse } from "../../../lib/api/queries/commons/types";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useCallback, useContext, useEffect, useState } from "react";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import CancleModal from "../../../components/modal/CancelModal";
import { fetchClient } from "../../../lib/api/axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IRentalList, ITenantFeeDetail } from "../../../lib/types/Imodels";
import { create_rental, tenant_rental_list, update_rental } from "../../../lib/api/queries/urls";
import { MemoizedItem } from "../../maintenance-fee/rent/new/TenantRentItem";
import { warning } from "../../../lib/theme/toast";
import { AxiosError } from "axios";
import { useDeleteBill } from "../../maintenance-fee/commons/queries";
import useUserAuth from "../../../components/hooks/useUserAuth";
import FullPageSpinner from "../../../components/Spinner";
import GetAuthorized from "../../../components/Authrized";
import NotFound from "../../not-found";
import { tenant_rental_list_key } from "../../../lib/api/queries/keys";
import { GlobalContext } from "../../../App";

export default function RentFeeCreatePage(props: BaseMutation<IRentalList>) {
  const toast = useToast();
  const navigate = useNavigate();
  const { rentalId } = useParams();
  const { buildInfo } = useContext(GlobalContext);
  const auth = useUserAuth("rent_fee");
  const hasFunc = buildInfo?.services.RENT_FEE?.enabled;
  const [month, setMonth] = useState(new Date());
  const [details, setDetails] = useState<ITenantFeeDetail[]>();
  const { isOpen, onOpen, onClose } = useDisclosure(); //납부 고지서 등록
  const { isOpen: isBack, onOpen: goBack, onClose: dont } = useDisclosure(); //되돌아가기
  const { isOpen: isDel, onOpen: onDel, onClose: offDel } = useDisclosure(); // 삭제

  const { data } = useQuery(
    [tenant_rental_list_key],
    () =>
      fetchClient.get<BaseResponse<ITenantFeeDetail[]>>(tenant_rental_list).then((res) => {
        setDetails(res.data.data);
        return res.data.data;
      }),
    { enabled: props.isEdit ? false : true },
  );

  const registered = (err: AxiosError) => {
    if (err.response?.status === 400)
      return toast({
        ...warning,
        title: "알림",
        description: "해당 년월에 이미 임대료가 등록되어있습니다. 다시 확인 후 등록해주세요.",
      });
    onOpen();
    toast({
      ...warning,
      title: "알림",
      description: "일시적인 오류로 실패했습니다. 잠시 후 다시 시도해주세요.",
    });
  };

  const createMutate = useMutation((newArr: ITenantFeeDetail[]) =>
    fetchClient
      .post(create_rental, {
        date: moment(month).format("YYYY-MM-01"),
        rent_fee_details: newArr,
      })
      .then(() => {
        alert("임대료 납부 고지서를 등록했습니다. 확인을 누르면 임대료 현황 페이지로 이동합니다.");
        navigate("/rent-fee", { replace: true });
      })
      .catch(registered),
  );

  const updateMutate = useMutation((newArr: ITenantFeeDetail[]) => {
    const rentfeeArr = newArr.map((el) => {
      const { _id, ...rest } = el;
      return rest;
    });

    return fetchClient
      .post(update_rental, {
        rent_fee_id: rentalId,
        date: moment(month).format("YYYY-MM-01"),
        rent_fee_details: rentfeeArr,
      })
      .then(() => {
        alert("임대료 납부 고지서를 수정했습니다. 확인을 누르면 해당 임대료 상세 페이지로 이동합니다.");
        navigate(`/rent-fee/${rentalId}`);
      })
      .catch(registered);
  });

  useEffect(() => {
    if (!props.isEdit) return;

    props.data?.date && setMonth(new Date(props.data?.date));
    setDetails(props.data?.rent_fee_details);
  }, [props.isEdit, props.data]);

  const onChange = useCallback((el: ITenantFeeDetail, value: string) => {
    el.price = Number(value || 0);
  }, []);

  const onSaveBillLetter = () => {
    const newArr = details?.map((el) => {
      const { price, ...rest } = el;
      if (!price || price === -1) {
        return rest;
      } else {
        return el;
      }
    });
    props.isEdit ? updateMutate.mutate(newArr || []) : createMutate.mutate(newArr || []);
    onClose();
  };

  const onDeleteBill = () => {
    useDeleteBill(rentalId || "")
      .then(() => {
        alert("임대료 납부 고지서를 삭제했습니다. 확인을 누르면 해당 임대료 현황 페이지로 이동합니다.");
        navigate("/rent-fee", { replace: true });
      })
      .catch(() => alert("일시적인 오류로 납부 고지서 삭제에 실패했습니다. 잠시 후 다시 시도해주세요."));
  };

  if (hasFunc === undefined || !auth) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;
  if (auth !== "edit") return <GetAuthorized />;
  return (
    <>
      <R.RentWarpper auth={auth}>
        <Text variant="static">
          <C.LeftIcons onClick={goBack} />
          임대료 납부고지서 {props.isEdit ? "수정" : "생성"}
        </Text>
        <ConfirmModal
          close="확인"
          title="되돌아가기"
          onClose={dont}
          isOpen={isBack}
          onClickCancle={dont}
          onClickSave={() => navigate(-1)}
          blockScrollOnMount={false}
        >
          <Text>지금까지 작성하신 내용은 저장되지 않습니다.</Text>
          <Text>이전 페이지로 이동할까요?</Text>
        </ConfirmModal>
        <R.CreatebillInfo>
          <li>납부 요청 보내기까지 완료해야 입주자에게 납부 고지가 됩니다.</li>
          <li>
            입주사 관리 - 입주사 상세 페이지에 저장된 임대료로 표시되며, 고지서 내에서 임대료를 수정한 경우에는 해당
            월에만 일시적으로 적용됩니다.
          </li>
        </R.CreatebillInfo>
        <C.NewPostBox style={{ borderTop: "1px solid #d1d5db" }}>
          <C.NewPost>
            <C.PostTitle>부과년월</C.PostTitle>
            <C.DateWithIconBox style={{ width: "180px" }}>
              <DatePicker
                locale={ko}
                selected={month}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                onChange={(date: Date) => setMonth(date)}
                customInput={
                  <R.MonthInput>
                    <Text>{moment(month).format("YYYY년 MM월")}</Text>
                    <FiChevronDown />
                  </R.MonthInput>
                }
              />
            </C.DateWithIconBox>
          </C.NewPost>
        </C.NewPostBox>
        <R.BillContainer>
          <Text fontWeight="500">임대료</Text>
        </R.BillContainer>
        <div style={{ display: "contents" }}>
          <R.TableHeader>
            <R.TableBox w={(props.isEdit ? props.data?.rent_fee_details.length || 0 : data?.length || 0) > 999}>
              <R.TrangleGraySquare>
                <div />
              </R.TrangleGraySquare>
            </R.TableBox>
            <R.HeaderSquare w="20%">입주사명</R.HeaderSquare>
            <R.HeaderSquare w="25%">호실</R.HeaderSquare>
            <R.SkySquare w="20%">임대료</R.SkySquare>
            <div style={{ width: "30%", background: "#fff" }} />
          </R.TableHeader>
          {/* {newArr?.map((el) => el)} */}
          {details?.map((el, idx) => (
            <MemoizedItem
              el={el}
              idx={idx}
              key={uuidv4()}
              length={props.isEdit ? props.data?.rent_fee_details.length : data?.length}
              onChange={onChange}
            />
          ))}
        </div>
      </R.RentWarpper>
      <CancleModal isOpen={isDel} onClose={offDel} onClickCancel={offDel} onClickSave={onDeleteBill}>
        <Text>임시 저장된 납부고지서를 삭제할까요?</Text>
        <Text>삭제된 데이터는 복구할 수 없습니다.</Text>
      </CancleModal>
      <R.ActionBox>
        {props.isEdit ? (
          <Button variant="delete" m="0" onClick={onDel}>
            삭제
          </Button> //수정일때는 항상 보여지고 활성화해야되니까 isEdit이랑 조건 같이
        ) : (
          <div />
        )}
        <div>
          {props.isEdit && props.data?.updated_date && (
            <>
              최근 업데이트
              {moment.utc(props.data.updated_date).format(" YYYY-MM-DD HH:mm")}
            </>
          )}
          <Button variant="bgBlue" onClick={onOpen}>
            {props.isEdit ? "수정" : "저장"}하기
          </Button>
        </div>
        {(props.isEdit && updateMutate.isLoading) || createMutate.isLoading ? (
          <Modal variant="contents" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent position="relative">
              <C.CustomSpin thickness="4px" speed="0.65s" size="xl" />
            </ModalContent>
          </Modal>
        ) : (
          <ConfirmModal
            close={props.isEdit ? "수정하기" : "저장하기"}
            isOpen={isOpen}
            onClose={onClose}
            onClickCancle={onClose}
            onClickSave={onSaveBillLetter}
            title={props.isEdit ? "경고" : "임대료 납부고지서 저장하기"}
          >
            {props.isEdit ? (
              <>
                <Text>
                  이미 납부고지가 된 고지서를 수정하는 경우에는 기존 납부고지서가 모두 삭제되어 입주자 앱에서도 확인이
                  불가합니다.
                </Text>
                <Text>납부 고지서 수정 후 보내기까지 완료해주세요.</Text>
              </>
            ) : (
              <>
                <Text>임대 항목을 모두 확인하셨나요?</Text>
                <Text>임대료 등록 후 납부 요청 보내기까지 완료하셔야 입주자에게 납부 고지가 됩니다.</Text>
              </>
            )}
          </ConfirmModal>
        )}
      </R.ActionBox>
    </>
  );
}
