import { useToast } from "@chakra-ui/react";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import { VoteDetailResponse, useVoteRemindMutation } from "../../../requests/vote";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  voteId: string;
  selectMember?: VoteDetailResponse["data"]["signatures"][number];
  unsignedCount: number;
};

export default function VoteNotifyResend({ isOpen, onClose, voteId, selectMember, unsignedCount }: Props) {
  const { mutateAsync: remindMutateAsync } = useVoteRemindMutation();
  const toast = useToast();

  const name = selectMember?.delegate.name ? selectMember.delegate.name : selectMember?.name;

  return (
    <ConfirmModal
      width={400}
      title={"투표 알림 재전송" + (selectMember ? "" : "(일괄)")}
      isOpen={isOpen}
      onClose={onClose}
      close="확인"
      onClickSave={() => {
        toast.promise(remindMutateAsync({ voteId, signaturesId: selectMember?._id }), {
          success: { position: "top", title: "투표 알림이 재전송되었습니다." },
          error: { position: "top", title: "투표 알림 재전송에 실패하였습니다" },
          loading: { position: "top", title: "투표 알림 재전송 중입니다." },
        });
        onClose();
      }}
    >
      {selectMember ? (
        <p>{name}님에게 투표 알림(알림톡)을 재전송할까요?</p>
      ) : (
        <p>아직 서명하지 않은 참여자 {unsignedCount}명에게 투표알림(알림톡)을 전송할까요?</p>
      )}
    </ConfirmModal>
  );
}
