import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseModalProps,
  useDisclosure,
  Box,
  useToast,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { GlobalContext } from "../../../App";
import ContentInputs from "../../../components/Input/middleContents";
import DaumPostcode, { Address } from "react-daum-postcode";
import * as C from "../../../components/styled/index";
import { useRef, ChangeEvent, useContext, useState } from "react";
import SingleRow from "../../../components/modal/table/singleRow";
import MoreContentInputs from "../../../components/Input/contents";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { IBuilding } from "../../../lib/types/Imodels";
import { building_update } from "../../../lib/api/queries/urls";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import { checkFileValidate, success, warning } from "../../../lib/theme/toast";
import { fileImageValidate, limitContent } from "../../../lib/utils/format";
import Resizer from "react-image-file-resizer";
import { queryClient } from "../../..";
import { building_key } from "../../../lib/api/queries/keys";
import { PreviewImages, PreviewPicture } from "../../../components/preview/Images";

interface ISpotPoint {
  lat: number;
  lon: number;
}

const DescriptBox = styled.div`
  padding: 8px 0;
  width: 100%;
  flex-direction: column;
  input {
    display: none;
  }
  img {
    width: 88px;
    height: 88px;
    object-fit: fill;
  }
`;

export default function OfficeInfoModal(props: UseModalProps) {
  const { kakao } = window;

  const toast = useToast();
  const { user, buildInfo } = useContext(GlobalContext);

  const [name, setName] = useState(buildInfo?.name || "");
  const [address, setAddress] = useState(buildInfo?.address || "");
  const [logo, setLogo] = useState<File | null>(null);
  const [origin, setOrigin] = useState<string[] | undefined>(buildInfo?.logo_image);
  const [desc, setDesc] = useState(buildInfo?.building_description || "");
  const [originArr, setOriginArr] = useState<string[]>(buildInfo?.building_picture || []);
  const [images, setImages] = useState<File[] | null>([]);
  const point = buildInfo && buildInfo.location;
  const [location, setLocation] = useState<ISpotPoint | undefined>(
    point && point.coordinates[0] !== null && point.coordinates[1] !== null
      ? { lat: point.coordinates[1], lon: point.coordinates[0] }
      : undefined,
  );

  const { isOpen, onOpen, onClose } = useDisclosure(); //postcode open
  const { isOpen: isBack, onOpen: onBack, onClose: offBack } = useDisclosure(); //취소 확인
  const fileRef = useRef<HTMLInputElement[] | null[]>([]);
  const isMaster = user?.user_type === "master";

  const handleComplete = (data: Address) => {
    // if (loading) return console.log("not loaded yet");
    setAddress(data.address);
    // console.log(loading, kakao.maps.services);
    const geocoder = new kakao.maps.services.Geocoder();

    const callback = (result: any, status: string) => {
      if (status === kakao.maps.services.Status.OK) setLocation({ lat: result[0].y, lon: result[0].x });
    };

    geocoder.addressSearch(data.address, callback);
    onClose();
  };

  const onUpdateInfo = () => {
    if (!isMaster) return;
    if (!name || !address)
      return toast({
        ...warning,
        title: "건물 정보 수정 오류",
        description: "건물 정보 수정을 위해 건물명과 주소는 필수 입력 사항입니다.",
      });

    const formdata = new FormData();

    formdata.append("building_id", String(buildInfo?._id));
    formdata.append("name", name);
    formdata.append("address", address);
    formdata.append("building_description", desc);
    formdata.append("original_logo_image", logo !== null ? "[]" : JSON.stringify(origin));
    logo !== null && formdata.append("logo_image", logo);
    images && images.map((el) => formdata.append("building_picture", el));
    formdata.append("original_building_picture", JSON.stringify(originArr));
    location?.lat && formdata.append("latitude", String(location.lat));
    location?.lon && formdata.append("longitude", String(location.lon));

    fetchClient
      .post<BaseResponse<IBuilding>>(building_update, formdata)
      .then(() => {
        props.onClose();
        queryClient.invalidateQueries([building_key]);
        return toast({
          ...success,
          title: "건물 기본 정보 수정 완료",
          description: `건물 기본 정보가 수정되었습니다.`,
        });
      })
      .catch((res) => console.error(res));
  };

  const multipleInput = () => {
    const newLength = images?.length || 0;
    if (newLength + originArr.length >= 5)
      return toast({
        ...checkFileValidate,
        description:
          "파일은 5장까지 업로드 가능합니다. 새로운 파일을 추가하려면 기존에 업로드한 파일을 먼저 삭제해주세요.",
      });

    fileRef?.current[1]?.click();
  };

  const onResizeFile = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "PNG",
        100,
        0,
        (uri) => {
          // console.log(uri);
          resolve(uri);
        },
        "file",
        300,
        300,
      );
    });

  const UploadLogo = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === "logo") {
      const file = e.target.files?.[0];
      // console.log(e.target.files);
      if (file === undefined) return;
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = async () => {
        const w = img.width;
        const h = img.height;

        if (w > 500 && h > 500) {
          if (!file.type.includes("png"))
            return toast({
              ...warning,
              title: "로고 등록 오류",
              description: "로고 이미지는 PNG 형식만 업로드 가능합니다.",
            });
          try {
            const image: any = await onResizeFile(file);
            setLogo(image);
          } catch (err) {
            console.log(err);
          }
        } else {
          toast({
            ...warning,
            title: "로고 파일 사이즈 오류",
            description: "로고 파일의 크기는 가로 500px, 세로 500px 이상이여야합니다.",
          });
        }
      };
    } else {
      const file = e.target.files;

      if (file === null) return;
      const isValid = fileImageValidate(file);
      if (!isValid || file.length + originArr.length > 5)
        return toast({
          ...checkFileValidate,
          description:
            "파일은 GIF, JPEG, JPG, PNG 형식으로 5장까지만 등록 가능합니다. 파일 형식 확인 후 동일한 에러가 반복될 경우에는 오피스너 담당자에게 문의해주세요.",
        });

      images === null ? setImages(Array.from(file)) : setImages([...images, ...Array.from(file)]);
    }

    e.target.value = "";
  };

  return (
    <Modal
      variant="contents"
      isOpen={props.isOpen}
      onClose={props.onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <C.TableContent>
        <ModalHeader>기본 정보</ModalHeader>
        <ModalBody w="864px">
          <Text variant="subTitle" pb="8px">
            건물 기본 정보
          </Text>
          <div style={{ borderTop: "1px solid #696F79" }}>
            <SingleRow w="20%" title="건물명">
              <MoreContentInputs
                value={name || ""}
                isReadOnly={!isMaster}
                style={{ marginRight: "0" }}
                placeholder="건물명을 설정해주세요."
                onChange={(e) => {
                  if (e.target.value.length > 12) return alert("건물명은 최대 12글자까지 입력 가능합니다.");
                  setName(e.target.value);
                }}
              />
            </SingleRow>
            <SingleRow w="20%" title="건물 주소">
              <Box w="100%">
                <ContentInputs
                  isReadOnly
                  value={address || ""}
                  style={{ padding: "9px 12px" }}
                  placeholder="주소 검색 버튼을 눌러 건물 주소를 검색해주세요."
                />
                {isMaster && (
                  <Box>
                    <Button ml="4px" variant="basic" onClick={onOpen}>
                      주소검색
                    </Button>
                    <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
                      <ModalOverlay />
                      <ModalContent>
                        <DaumPostcode onComplete={handleComplete} />
                      </ModalContent>
                    </Modal>
                  </Box>
                )}
              </Box>
            </SingleRow>
            <SingleRow w="20%" title="로고">
              <DescriptBox style={{ alignItems: "flex-start", padding: 0 }}>
                <PreviewPicture
                  files={logo}
                  setFiles={setLogo}
                  fileArr={origin || []}
                  setFileArr={setOrigin}
                  auth={isMaster ? "edit" : "no_auth"}
                />
                <input
                  id="logo"
                  type="file"
                  multiple={false}
                  ref={(el) => (fileRef.current[0] = el)}
                  onChange={(e) => UploadLogo(e)}
                />
                <Button
                  variant="basic"
                  isDisabled={isMaster ? false : true}
                  onClick={() => fileRef?.current[0]?.click()}
                >
                  사진선택
                </Button>
              </DescriptBox>
            </SingleRow>
            <SingleRow w="20%" title="건물 설명">
              <DescriptBox style={{ alignItems: "flex-start", padding: 0 }}>
                <C.BasicTextArea
                  h="200px"
                  value={desc || ""}
                  readOnly={!isMaster}
                  onChange={(e) => {
                    const isLimit = limitContent(e.target.value);
                    isLimit && setDesc(e.target.value);
                  }}
                  style={{ marginBottom: "8px" }}
                  placeholder="건물 설명을 입력해주세요.(최대 3000자까지 입력 가능합니다)"
                />
                <input
                  id="desc"
                  type="file"
                  multiple={true}
                  ref={(el) => (fileRef.current[1] = el)}
                  onChange={(e) => UploadLogo(e)}
                />
                <PreviewImages
                  files={images}
                  setFiles={setImages}
                  fileArr={originArr}
                  setFileArr={setOriginArr}
                  auth={isMaster ? "edit" : "no_auth"}
                />
                {isMaster && (
                  <Button variant="basic" onClick={multipleInput}>
                    사진선택
                  </Button>
                )}
              </DescriptBox>
            </SingleRow>
          </div>
          <ConfirmModal
            title="되돌아가기"
            onClickCancle={offBack}
            close="확인"
            onClose={offBack}
            isOpen={isBack}
            onClickSave={() => {
              offBack();
              props.onClose();
            }}
            blockScrollOnMount={false}
          >
            <Text>지금까지 작성하신 내용은 저장되지 않습니다.</Text>
            <Text>건물 설정 페이지로 이동할까요?</Text>
          </ConfirmModal>
        </ModalBody>
        <ModalFooter>
          {isMaster ? (
            <>
              <Button variant="cancel" onClick={onBack}>
                취소
              </Button>
              <Button variant="bgBlue" onClick={onUpdateInfo}>
                저장하기
              </Button>
            </>
          ) : (
            <Button variant="cancel" onClick={props.onClose}>
              확인
            </Button>
          )}
        </ModalFooter>
      </C.TableContent>
    </Modal>
  );
}
