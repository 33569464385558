import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import useUserAuth from "../../../components/hooks/useUserAuth";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import {
  category_list_key,
  complaint_detail_key,
} from "../../../lib/api/queries/keys";
import {
  category_list,
  complaint_comment,
  complaint_detail,
} from "../../../lib/api/queries/urls";
import { warning } from "../../../lib/theme/toast";
import {
  IComment,
  IReqComplaint,
  ITempCategory,
} from "../../../lib/types/Imodels";
import { ICreateComment } from "./types";

export const useFetchComplaint = (Id: string | undefined) => {
  const auth = useUserAuth("complaint");
  return useQuery(
    [complaint_detail_key, Id],
    () =>
      fetchClient
        .post<BaseResponse<IReqComplaint>>(complaint_detail, {
          complaint_id: Id,
        })
        .then((res) => res.data.data),
    {
      enabled: auth === "edit" || auth === "read",
    }
  );
};

enum ICategory {
  notice_type_admin,
  notice_type,
  room_public_type,
  complaint_type,
  complaint_location_type,
}

type EnumCategory = keyof typeof ICategory;

export const useFetchCategories = (category: EnumCategory) =>
  //여기 민원에서만 쓰는지 전체 카테고리 다 적용될 지, DB에 적용 후 바뀌어야할 필요성 있음
  useQuery(
    [category_list_key, category],
    () =>
      fetchClient
        .get<BaseResponse<ITempCategory[]>>(category_list, {
          params: { category },
        })
        .then((res) => res.data.data),
    {
      staleTime: Infinity,
    }
  );

export const useUpdateComment = () => {
  const toast = useToast();

  const createComment = useMutation(
    (obj: ICreateComment) =>
      fetchClient.post<BaseResponse<IComment>>(complaint_comment, obj),
    {
      onError: () => {
        toast({
          ...warning,
          title: "처리 상태 변경", //댓글 등록시에도 쓰면 멘트 바꿔야함
          description:
            "일시적으로 처리 상태 변경에 실패했습니다. 새로고침 후에도 동일한 오류가 반복될 경우 오피스너 담당자에게 문의해주세요.",
        });
      },
    }
  );

  return { createComment };
};
