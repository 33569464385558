import styled from "@emotion/styled";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { colors, form } from "../../../components/styled/common";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { IBuildingList } from "../../../lib/types/Imodels";
import * as S from "../styled";
import { v4 as uuidv4 } from "uuid";
import { building_list_key } from "../../../lib/api/queries/keys";
import { Helmet } from "react-helmet-async";
import { DownIcon } from "../../../components/styled";

const WrapperBody = styled(S.FormBox)`
  padding: 32px 20px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: url("https://officener.s3.ap-northeast-2.amazonaws.com/body_files/1670907747321_onboardImage.png")
    no-repeat 8% bottom;
  background-size: 208%;
  font-size: 16px;
  @media (max-width: 321px) {
    font-size: 14px;
    padding: 30px 16px;
  }
`;

const BodyContent = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #696f79;
  height: calc(100% - 3em);
`;

const SubmitButton = styled(S.SubmitButton)`
  position: absolute;
  bottom: -2.5em;
  :disabled {
    background-color: ${colors.gray2};
  }
  @media (max-width: 321px) {
    font-size: 16px;
    bottom: -3em;
  }
`;

const RegisterCard = styled.h2`
  font-size: 1.25em;
  color: ${colors.mainBlue};
  font-weight: 400;
  font-family: "Godo M", sans-serif;
`;

const BodyTitle = styled.div`
  font-size: 2em;
  color: ${colors.gray5};
  font-weight: 500;
  line-height: 1.5;
  padding: 12px 0;
  @media (max-width: 321px) {
    padding: 8px 0;
    line-height: 1.3;
  }
`;

const BodyDesc = styled.div`
  font-weight: 500;
  font-size: ${form.fontSize.fs14};
  line-height: 1.5;
  padding-bottom: 12px;
`;

const SelectBox = styled.div`
  position: relative;
  cursor: pointer;
  color: ${colors.gray4};
`;

const SelectBuilding = styled.select`
  padding: 12px;
  appearance: none;
  width: 100%;
  border-radius: 12px;
  background-color: ${colors.gray1};
`;

const SelectDownIcon = styled(DownIcon)`
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translateY(-50%);
  font-size: 20px;
`;

export default function BuildingBeforeRegisterCard() {
  let vh = 0;
  const navigate = useNavigate();
  const [value, setValue] = useState("");

  const { data, status } = useQuery(
    [building_list_key],
    () =>
      axios
        .get<BaseResponse<IBuildingList[]>>(
          `${process.env.REACT_APP_SERVER_URL}/building/building/name_list`
        )
        .then((res) => res.data.data),
    { cacheTime: 1800000 }
  );

  useEffect(() => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, [window.innerHeight]);

  return (
    <>
      <div>
        <Helmet>
          <title>오피스너 입주카드</title>
          <meta property="og:title" content="오피스너 입주 카드" />
          <meta
            property="og:description"
            content="여기를 눌러 오피스너를 통해 입주카드를 등록해주세요."
          />
          <meta name="twitter:title" content="오피스너 입주카드" />
        </Helmet>
      </div>
      <S.FormWrapper>
        <WrapperBody>
          <BodyContent>
            <RegisterCard>오피스너 입주카드</RegisterCard>
            <BodyTitle>입주하시는 건물을 선택해주세요.</BodyTitle>
            <BodyDesc>
              <p>호실별 한번만 작성하시면 됩니다.</p>
              <p>입주 시 대표자만 작성해주세요.</p>
            </BodyDesc>
            {status !== "success" && (
              <BodyDesc>
                건물 정보를 불러오는 중입니다. 새로고침 후에도 건물 선택 창에서
                리스트가 보이지 않을 경우 오피스너 담당자에게 연락주세요.
              </BodyDesc>
            )}
            <SelectBox>
              <SelectBuilding
                onChange={(e) => setValue(e.target.value)}
                value={value}
              >
                <option value="" disabled>
                  건물 선택
                </option>
                {data?.map((el) => (
                  <option key={uuidv4()} value={String(el._id)}>
                    {el.name}
                  </option>
                ))}
              </SelectBuilding>
              <SelectDownIcon />
            </SelectBox>
            <SubmitButton
              disabled={value === "" ? true : false}
              onClick={() =>
                navigate({
                  pathname: "/forms/select",
                  search: `?${createSearchParams({ id: value })}`,
                })
              }
            >
              입주카드 작성하러가기
            </SubmitButton>
          </BodyContent>
        </WrapperBody>
      </S.FormWrapper>
    </>
  );
}
