import { Link } from "react-router-dom";
import VoteSendModal from "./modal/VoteSendModal";
import { Tooltip, useDisclosure, useToast } from "@chakra-ui/react";
import IconArrow from "../../components/icons/IconArrow";
import IconAgenda from "../../components/icons/IconAgenda";
import IconPlus from "../../components/icons/IconPlus";
import { useForm, useFieldArray } from "react-hook-form";
import { useDeleteVoteMutation, useUpdateVoteMutation } from "../../requests/vote";
import IconSend from "../../components/icons/IconSend";
import { useEffect } from "react";
import { resetVoteStore, setVoteInput } from "../../hooks/use-vote-store";
import CancelModal from "../../components/modal/CancelModal";
import IconTrash from "../../components/icons/IconTrash";
import Button from "../../components/button/Button";

type Props = {
  voteId: string;
  title?: string;
  description?: string;
  agendas: { title: string }[];
  senderName?: string;
  senderContact?: string;
  updatedAt: Date;
};

type Inputs = {
  title: string;
  description: string;
  agendas: { title: string }[];
  senderName: string;
  senderContact: string;
};

export default function VoteCreate({
  voteId,
  title,
  description,
  agendas,
  senderName,
  senderContact,
  updatedAt,
}: Props) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();
  const { mutateAsync: updateVote, isLoading: isUpdateVoteLoading } = useUpdateVoteMutation();
  const { mutateAsync: deleteVote, isLoading: isDeleteVoteLoading } = useDeleteVoteMutation();

  const {
    register,
    control,
    handleSubmit,
    setFocus,
    reset,
    getValues,
    formState: { isDirty, isValid },
  } = useForm<Inputs>({
    defaultValues: {
      title: title || "",
      description: description || "",
      agendas: agendas.length ? agendas.map((agenda) => ({ title: agenda.title })) : [{ title: "" }],
      senderName: senderName || "",
      senderContact: senderContact || "",
    },
  });

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: "agendas",
    rules: { maxLength: 10 },
  });

  const onVoteSave = async ({ auto }: { auto: boolean } = { auto: false }) => {
    if (!isDirty) return;
    const values = getValues();
    toast.promise(updateVote({ voteId, ...values }), {
      success: { position: "top", title: auto ? "자동 저장되었습니다" : "저장되었습니다." },
      error: { position: "top", title: auto ? "자동 저장에 실패했습니다." : "저장에 실패했습니다." },
      loading: { position: "top", title: auto ? "자동 저장 중입니다." : "저장 중입니다." },
    });
    reset(values);
  };

  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      onVoteSave({ auto: true });
    }, 30000);
    return () => clearInterval(autoSaveInterval);
  }, [handleSubmit, onVoteSave]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.nativeEvent.isComposing || event.keyCode === 229) return;
    if (event.key === "Enter") {
      event.preventDefault();
      if (fields.length >= 10) return;
      insert(index + 1, { title: "" });
      setFocus(`agendas.${index + 1}.title`);
    } else if (event.key === "Backspace" && event.currentTarget.value === "") {
      event.preventDefault();
      if (index === 0) return;
      setFocus(`agendas.${index - 1}.title`);
      remove(index);
    }
  };

  const formattedUpdateTime = `${updatedAt.getUTCHours() < 12 ? "오전" : "오후"} ${updatedAt.getUTCHours() % 12 || 12}:${updatedAt.getUTCMinutes().toString().padStart(2, "0")} 임시저장됨`;

  return (
    <>
      <div className="flex h-[72px] items-center justify-between border-b border-gray-200 px-6">
        <div className="flex items-center gap-3 text-gray-500">
          <Link
            to={"/votes"}
            className="rounded-lg px-2 py-4 font-medium text-gray-500 transition-all hover:bg-gray-200"
          >
            투표
          </Link>
          <IconArrow />
          <p className="font-medium text-gray-500">새로운 투표 생성</p>
        </div>
        <div className="flex items-center gap-7">
          <p className="text-sm font-medium text-gray-500">{formattedUpdateTime}</p>
          <div className="flex items-center gap-3">
            <button
              onClick={() => onVoteSave()}
              className={`h-12 rounded-lg border border-primary px-6 text-primary hover:bg-blue-50 active:bg-blue-100 disabled:cursor-not-allowed disabled:opacity-60`}
              disabled={!isDirty}
            >
              저장
            </button>
            <div className="h-12 w-[1px] bg-gray-200" />
            <button
              onClick={onDeleteModalOpen}
              className="h-12 rounded-lg bg-red-100 px-6 transition-all hover:bg-red-200 active:bg-red-300"
            >
              <span className="font-medium text-red-500">삭제</span>
            </button>
            <Tooltip
              label="투표에 필요한 모든 항목을 입력해주세요."
              rounded={"md"}
              hasArrow={true}
              placement="bottom-end"
              paddingY={2}
              isDisabled={isValid}
            >
              <div>
                <Button
                  disabled={!isValid}
                  iconElement={<IconSend />}
                  designSchema={"primary"}
                  buttonText={"발송하기"}
                  size={"md"}
                  onClick={async () => {
                    resetVoteStore();
                    await onVoteSave();
                    const values = getValues();
                    setVoteInput(values);
                    onOpen();
                  }}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 border-r border-gray-200 bg-white px-6 py-8">
          <p className="text-xl font-bold text-gray-900">투표지 편집</p>
          <p className="mt-1.5 font-medium text-gray-900">
            투표 대상자에게 보여지는 투표지가 될 예정입니다. 템플릿에 들어가는 모든 항목을 입력해주세요.
          </p>
          <div className="mt-8 flex flex-col gap-4">
            <p className="font-semibold text-gray-500">투표 제목과 내용</p>
            <input
              {...register("title", { maxLength: 30, required: true })}
              maxLength={30}
              className="w-full rounded-lg border border-gray-200 p-3 transition-all placeholder:text-gray-300 focus:border-blue-600"
              placeholder="투표 제목 (최대 30자) - 예시 : 제1차 정기 관리단 집회 서면 결의서"
            />
            <textarea
              {...register("description", { maxLength: 200, required: true })}
              maxLength={200}
              className="h-[120px] w-full resize-none rounded-lg border border-gray-200 p-3 transition-all placeholder:text-gray-300 focus:border-blue-600"
              placeholder="투표 내용 (최대 200자) - 예시 : 위 동호수의 구분소유자로써, 0000년 0월 00일 개최하는 오피스너 제1차 정기 관리단 집회관련 관리규약 제10조에 의거 아래와 같이 본 서면으로 정기 관리단 집회 참석과 의결권을 행사합니다."
            />
          </div>
          <div className="mt-10 flex flex-col gap-4">
            <p className="font-semibold text-gray-500">안건 리스트 (최대 10개)</p>
            {fields.map((field, index) => (
              <label key={field.id} className="relative flex items-center">
                <IconAgenda className="absolute left-4 top-1/2 -translate-y-1/2 transform" />
                <p className="absolute left-12 top-1/2 -translate-y-1/2 transform text-gray-500">제{index + 1}호</p>
                <input
                  {...register(`agendas.${index}.title`, { maxLength: 100, required: true })}
                  maxLength={100}
                  onKeyDown={(event) => handleKeyDown(event, index)}
                  className="w-full rounded-lg border border-gray-200 bg-gray-50 py-3 pl-[100px] shadow transition-all placeholder:text-gray-300 focus:border-blue-600"
                  placeholder="안건명 (최대 100자)"
                  required
                />
                <button
                  type="button"
                  onClick={() => {
                    if (fields.length === 1) return;
                    remove(index);
                  }}
                  className="absolute right-4 top-1/2 -translate-y-1/2 transform"
                  tabIndex={-1}
                >
                  <IconTrash disabled={fields.length === 1} />
                </button>
              </label>
            ))}
            {fields.length < 10 && (
              <button
                type="button"
                onClick={() => append({ title: "" })}
                className="flex h-12 w-fit items-center gap-2 rounded-lg border border-primary px-6 transition-all hover:bg-blue-50 active:bg-blue-100"
                tabIndex={-1}
              >
                <IconPlus />
                <span className="font-medium text-primary">안건 추가</span>
              </button>
            )}
          </div>
          <div className="mt-10 flex flex-col gap-4">
            <p className="font-semibold text-gray-500">투표 하단 (투표 요청자 / 연락처)</p>
            <input
              {...register("senderName", { maxLength: 20, required: true })}
              maxLength={20}
              className="w-full rounded-lg border border-gray-200 p-3 transition-all placeholder:text-gray-300 focus:border-blue-600"
              placeholder="투표 요청자 (최대 20자) - 예시 : 오피스너 타워 관리위원회"
            />
            <input
              {...register("senderContact", { maxLength: 40, required: true })}
              maxLength={40}
              className="w-full rounded-lg border border-gray-200 p-3 transition-all placeholder:text-gray-300 focus:border-blue-600"
              placeholder="연락처 (최대 40자) - 예시 : 오피스너 빌딩 선거관리 위원회 02-1234-1234"
            />
          </div>
        </div>
        <div className="px-6 py-7">
          <h5 className="mb-4 font-bold text-gray-500">투표지 예시: </h5>
          <img src="/images/vote-create-template.png" className="w-full" alt="예시 이미지" />
        </div>
      </div>
      <VoteSendModal voteId={voteId} isOpen={isOpen} onClose={onClose} />
      <CancelModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        onClickCancel={onDeleteModalClose}
        onClickSave={() => {
          onClose();
          if (isDeleteVoteLoading) return;
          toast.promise(deleteVote(voteId), {
            success: { position: "top", title: "투표가 삭제되었습니다." },
            error: { position: "top", title: "투표 삭제에 실패했습니다." },
            loading: { position: "top", title: "투표를 삭제 중입니다." },
          });
        }}
      >
        <p>정말로 투표를 삭제하시겠습니까?</p>
      </CancelModal>
    </>
  );
}
