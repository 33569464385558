export default function IconProhibition() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00065 0.833313C4.04261 0.833313 0.833984 4.04194 0.833984 7.99998C0.833984 11.958 4.04261 15.1666 8.00065 15.1666C11.9587 15.1666 15.1673 11.958 15.1673 7.99998C15.1673 4.04194 11.9587 0.833313 8.00065 0.833313ZM1.83398 7.99998C1.83398 4.59422 4.5949 1.83331 8.00065 1.83331C9.52366 1.83331 10.9177 2.38543 11.9936 3.30042C11.9891 3.30455 11.9848 3.30877 11.9804 3.31309L3.31378 11.9796C3.30943 11.984 3.30518 11.9884 3.30103 11.9928C2.38607 10.917 1.83398 9.52295 1.83398 7.99998ZM4.00765 12.6995C5.08353 13.6145 6.47761 14.1666 8.00065 14.1666C11.4064 14.1666 14.1673 11.4057 14.1673 7.99998C14.1673 6.47698 13.6152 5.08293 12.7002 4.00706C12.6961 4.0115 12.6919 4.01588 12.6875 4.02021L4.02088 12.6867C4.01653 12.6911 4.01212 12.6953 4.00765 12.6995Z"
        fill="#EF4444"
      />
    </svg>
  );
}
