import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/button/Button";
import Icon from "../../../types/iconsax";
import { twMerge } from "tailwind-merge";
import formatPhoneNumber from "../../../utils/formatPhoneNumber";
import { useOverlay } from "@toss/use-overlay";
import { useCompanyDetailQuery } from "../../../requests/company";
import CompanyInfoModifyModal from "../../../views/company/modal/CompanyInfoModifyModal";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import CompanyCheckoutModal from "../../../views/company/modal/CompanyCheckoutModal";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import CustomToast from "../../../components/CustomToast";
import { useEffect } from "react";
import FullPageSpinner from "../../../components/Spinner";
import useUserAuth from "../../../components/hooks/useUserAuth";
import GetAuthorized from "../../../components/Authrized";

export default function CompanyDetailLayout() {
  const { companyId } = useParams();
  const { pathname } = useLocation();

  const overlay = useOverlay();

  const auth = useUserAuth("tenant");
  const isEdit = auth === "edit";

  const { data, isLoading } = useCompanyDetailQuery(companyId as string);

  const masterMemberCnt = data?.data.members?.filter((v) => v.permission === 3).length || 0;

  const navigate = useNavigate();

  // 입주기간에 따른 입주상태 판별 x
  // const getMoveInStatus = () => {
  //   if (!data?.data.rentStartDate || !data?.data.rentEndDate) return false;
  //   return (
  //     dayjs(new Date()).format("YYYY/MM/DD") >= dayjs(data?.data.rentStartDate).format("YYYY/MM/DD") &&
  //     dayjs(new Date()).format("YYYY/MM/DD") <= dayjs(data?.data.rentEndDate).format("YYYY/MM/DD")
  //   );
  // };

  const handleCheckOutButtonClick = async () => {
    if (!companyId) return;

    overlay.open(({ isOpen, close }) => (
      <CompanyCheckoutModal
        isOpen={isOpen}
        onClose={close}
        companyId={companyId}
        companyName={data?.data.name || "-"}
      />
    ));
  };

  const handleModifyButtonClick = async () => {
    if (!companyId) return;

    overlay.open(({ isOpen, close }) => (
      <CompanyInfoModifyModal
        isOpen={isOpen}
        onClose={close}
        companyData={{
          companyId,
          name: data?.data.name,
          ceoName: data?.data.ceoName,
          phoneNumber: data?.data.phoneNumber,
          taxEmail: data?.data.email,
          companyRegistrationNumber: data?.data.tenantRegistrationNumber,
          fax: data?.data.fax,
        }}
      />
    ));
  };

  // 입주사 정보가 비어졌을 때 에러 토스트
  useEffect(() => {
    if (Array.isArray(data?.data)) {
      const redirect = setTimeout(() => {
        navigate("/companies");
      }, 1500);
      toast(<CustomToast title="입주사 정보를 불러오는 중 에러가 발생하였습니다." iconType="error" />);
      return () => clearTimeout(redirect);
    }
  }, [data]);

  if (isLoading) return <FullPageSpinner />;
  if (auth === "no_auth") return <GetAuthorized />;

  return (
    <main className="flex h-full flex-col">
      <CustomBreadcrumb
        data={[
          { label: "입주사 관리", path: ["/companies"] },
          { label: data?.data.name ?? "", path: [`/companies/${companyId}/info`, `/companies/${companyId}/members`] },
        ]}
      />
      <div className="flex justify-between px-6 pb-10 pt-5">
        <div className="flex gap-4">
          <div className="flex h-[62px] w-[62px] items-center justify-center rounded-2xl bg-blue-500">
            <Icon.Briefcase color="white" variant="Bold" size={24} />
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-xl font-bold leading-normal">{data?.data.name}</h3>
            <p className="text-sm font-bold leading-5 text-green-500">현재 입주중이에요</p>
          </div>
        </div>
        {isEdit && (
          <Button
            designSchema={"error"}
            buttonText={"퇴실처리"}
            size={"sm"}
            iconElement={<Icon.Trash variant="Outline" size={16} />}
            onClick={handleCheckOutButtonClick}
            className="w-[88px]"
          />
        )}
      </div>

      <div className="flex gap-8 border-b border-gray-200 px-6">
        <Link
          className={twMerge(
            "flex items-center gap-2 border-b-2 border-white px-1 pb-4 transition-all",
            pathname === `/companies/${companyId}/info` && " border-gray-700",
          )}
          to={`/companies/${companyId}/info`}
          replace
        >
          <span
            className={twMerge(
              "text-sm font-medium leading-5 text-gray-400",
              pathname === `/companies/${companyId}/info` && "text-gray-700",
            )}
          >
            입주정보
          </span>
          <span
            className={twMerge(
              "rounded-xl bg-gray-100 px-2.5 py-0.5 text-xs leading-4 text-gray-400",
              pathname === `/companies/${companyId}/info` && "bg-gray-700 text-white",
            )}
          >
            {data?.data.roomInfo?.length ?? 0}
          </span>
        </Link>
        <Link
          className={twMerge(
            "flex items-center gap-2 border-b-2 border-white px-1 pb-4 transition-all",
            pathname === `/companies/${companyId}/members` && "border-gray-700",
          )}
          to={`/companies/${companyId}/members`}
          replace
        >
          <span
            className={twMerge(
              "text-sm font-medium leading-5 text-gray-400",
              pathname === `/companies/${companyId}/members` && "text-gray-700",
            )}
          >
            구성원
          </span>
          <span
            className={twMerge(
              "rounded-xl bg-gray-100 px-2.5 py-0.5 text-xs leading-4 text-gray-400",
              pathname === `/companies/${companyId}/members` && "bg-gray-700 text-white",
            )}
          >
            {data?.data.members?.filter((v) => v.phoneNumber).length
              ? data?.data.members.length - (masterMemberCnt > 1 ? masterMemberCnt - 1 : 0)
              : 0}
          </span>
        </Link>
      </div>
      <div className="flex flex-1 divide-x divide-gray-200">
        <div className="flex-1">
          <Outlet />
        </div>
        <div className="w-[353px]">
          <div className="flex justify-between p-5">
            <h5 className="text-sm font-semibold leading-6">회사 정보</h5>
            {isEdit && (
              <p
                className="cursor-pointer text-sm font-semibold leading-6 text-gray-400 transition-all hover:text-gray-500"
                onClick={handleModifyButtonClick}
              >
                수정
              </p>
            )}
          </div>
          <div className="mt-2 flex flex-col gap-5 px-5">
            <div className="flex items-center gap-2">
              <Icon.Briefcase color="#374151" size={20} />
              <p className="text-base font-medium leading-snug text-gray-900">회사명</p>
              <p className="text-base font-medium leading-snug text-gray-900">{data?.data.name}</p>
            </div>
            <div className="flex items-center gap-2">
              <Icon.Crown color="#374151" size={20} />
              <p className="text-base font-medium leading-snug text-gray-900">대표자</p>
              <p
                className={twMerge(
                  "text-base font-medium leading-snug text-gray-900",
                  !data?.data.ceoName?.trim() && "text-gray-300",
                )}
              >
                {data?.data.ceoName?.trim() || "미등록"}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Icon.Call color="#374151" size={20} />
              <p className="text-base font-medium leading-snug text-gray-900">대표자 연락처</p>
              <p
                className={twMerge(
                  "text-base font-medium leading-snug text-gray-900",
                  !data?.data.phoneNumber?.trim() && "text-gray-300",
                )}
              >
                {formatPhoneNumber(data?.data.phoneNumber?.trim() || "미등록")}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Icon.Sms color="#374151" size={20} />
              <p className="text-base font-medium leading-snug text-gray-900">세금계산서</p>
              <p
                className={twMerge(
                  "text-base font-medium leading-snug text-gray-900",
                  !data?.data.email?.trim() && "text-gray-300",
                )}
              >
                {data?.data.email?.trim() || "미등록"}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Icon.DocumentText color="#374151" size={20} />
              <p className="text-base font-medium leading-snug text-gray-900">사업자번호</p>
              <p
                className={twMerge(
                  "text-base font-medium leading-snug text-gray-900",
                  !data?.data.tenantRegistrationNumber?.trim() && "text-gray-300",
                )}
              >
                {data?.data.tenantRegistrationNumber?.trim() || "미등록"}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Icon.Printer color="#374151" size={20} />
              <p className="text-base font-medium leading-snug text-gray-900">팩스번호</p>
              <p
                className={twMerge(
                  "text-base font-medium leading-snug text-gray-900",
                  !data?.data.fax?.trim() && "text-gray-300",
                )}
              >
                {data?.data.fax?.trim() || "미등록"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
