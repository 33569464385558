import { HTMLAttributes } from "react";

export type Props = object & HTMLAttributes<HTMLOrSVGElement>;

export default function IconUser({ className, ...props }: Props) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle className={className} cx="12" cy="6" r="4" fill="#9CA3AF" />
      <ellipse className={className} cx="12" cy="17" rx="7" ry="4" fill="#9CA3AF" />
    </svg>
  );
}
