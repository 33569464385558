import { useMutation } from "@tanstack/react-query";
import { httpV2 } from "../../lib/httpV2";
import { queryClient } from "../..";

type Request = {
  companyId: string;
  name: string;
  phoneNumber: string;
  permission: 1 | 2 | 3;
};

export const useCreateCompanyMemberMutation = () => {
  return useMutation({
    mutationFn: ({ companyId, ...request }: Request) => {
      return httpV2.post<Omit<Request, "companyId">, unknown>(`/companies/${companyId}/members`, request);
    },
    onSuccess: (_, { companyId }) => {
      queryClient.invalidateQueries([`/companies/${companyId}`]);
    },
  });
};
