import { Button, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, UseModalProps } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useState } from "react";
import { IoClose } from "@react-icons/all-files/io5/IoClose";
import { colors } from "../../../../components/styled/common";
import PaginationArrowOnly from "../../../../components/pagination/arrow";
import { INotice } from "../../../../lib/types/Imodels";
import { useViewerList } from "../../queries";
import { format_tenant_type } from "../../../../lib/utils/dataFormat";

const Header = styled(ModalHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  svg {
    color: ${colors.gray3};
    font-size: 1.75rem;
    cursor: pointer;
  }
`;

const BodyHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ViewerListBox = styled.div`
  margin-top: 8px;
  border: 1px solid ${colors.gray2};
  width: 100%;
  color: ${colors.gray4};
  height: 30.15rem;
  cursor: default;
`;

const Viewer = styled.div<{ c: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ c }) => (c === "even" ? colors.gray1 : "#fff")};
  padding: 0.75rem;
  span {
    min-width: 4.5rem;
  }
`;

interface IViewModal extends UseModalProps {
  _id: INotice | undefined;
}

export default function ViewerModal(props: IViewModal) {
  const [isRead, setIsRead] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const { data } = useViewerList(String(props._id), true, currentPage);

  return (
    <Modal
      size="lg"
      variant="responsive"
      isOpen={props.isOpen}
      onClose={props.onClose}
      // scrollBehavior={props.scrollBehavior}
      // blockScrollOnMount={props.blockScrollOnMount}
    >
      <ModalOverlay />
      <ModalContent>
        <Header>
          읽은 입주사 <IoClose onClick={props.onClose} />
        </Header>
        <ModalBody style={{ padding: "24px 0 2.75rem", fontSize: "1rem" }}>
          <BodyHeader>
            <div>
              <Button
                variant="m_primary_line"
                opacity={!isRead ? 0.4 : 1}
                onClick={() => {
                  setIsRead(true);
                  setCurrentPage(1);
                }}
              >
                읽음({data?.reading.total_num || 0})
              </Button>
              <Button
                ml="12px"
                opacity={isRead ? 0.4 : 1}
                variant="m_primary_line"
                onClick={() => {
                  setIsRead(false);
                  setCurrentPage(1);
                }}
              >
                안읽음({data?.unreading.total_num || 0})
              </Button>
            </div>
            <PaginationArrowOnly
              allPage={isRead ? data?.reading.total_page_num || 1 : data?.unreading.total_page_num || 1}
              page={currentPage}
              setPage={setCurrentPage}
            />
          </BodyHeader>
          <ViewerListBox>
            {data &&
              (isRead ? data.reading : data.unreading).data.map((el, idx) => (
                <Viewer c={idx % 2 === 0 ? "even" : "odd"} key={String(idx)}>
                  <span>{format_tenant_type(el.tenant_type)}</span>
                  <p> {el.name}</p>
                </Viewer>
              ))}
          </ViewerListBox>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
