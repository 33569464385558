import moment from "moment";
import { SidebarTitle } from "../pages/meeting-rooms/timeline/_view/timeline";

export const testgroups = [
  { id: "1", title: <SidebarTitle /> },
  { id: "2", title: <SidebarTitle /> },
  { id: "3", title: <SidebarTitle /> },
  { id: "4", title: <SidebarTitle /> },
];

export const items = [
  {
    id: "1",
    group: "3",
    title: "item 1",
    start_time: moment().add(-5, "hours"),
    end_time: moment().add(1, "hour"),
  },
  {
    id: "2",
    group: "2",
    title: "item 2",
    start_time: moment().add(-0.5, "hour"),
    end_time: moment().add(3, "hour"),
    // className: "need-approval",
  },
  {
    id: "3",
    group: "3", //그룹의 id
    title: "item 3",
    start_time: moment().add(-3, "hour"),
    end_time: moment().add(5, "hour"),
    // className:
  },
];
