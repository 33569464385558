import axios, { InternalAxiosRequestConfig } from "axios";
import { auth } from "../../firebase";

const SERVER_URL = `${process.env.REACT_APP_SERVER_URL}`;

export const instance = axios.create({
  baseURL: `${SERVER_URL}`,
  withCredentials: false,
});

export const getAuthToken = async () => {
  try {
    // 앱에서 토큰 가져오면 여기 단계 뛰어넘어도 됨
    return await auth.currentUser?.getIdToken();
  } catch (err) {
    console.log("getAuthToken error", err);
  }
};

export const interceptors = async (config: InternalAxiosRequestConfig<unknown>) => {
  const headers = config?.headers;
  if (!headers) {
    throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
  }

  const token = await getAuthToken();
  if (token) {
    headers.token = token;
  }
  return config;
};

export const fetchClient = (() => {
  instance.interceptors.request.use(interceptors);
  return instance;
})();
