import { Flex } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useContext } from "react";
import { IBasicInfo, IItemBasicInfo } from "../../commons/types";
import { GlobalContext } from "../../../../App";
import SetDetailImage, { IDefaultImages } from "../../component/details/AddImages";
import { IRoomPublic } from "../../../../lib/types/Imodels";
import DetailImage, { IDefaultImage } from "../../component/details/AddPicture";
import GrayRadiusInput from "../../../../components/Input/responsive";
import GrayRadiusTextArea from "../../../../components/textarea";
import GrayColorSelect from "../../../../components/select";
import { LastShadowBox } from "../../../../components/styled/webApp";

interface IFacilityInfo extends IDefaultImages {
  setInfoObj: Dispatch<SetStateAction<IBasicInfo>>;
  InfoObj: IBasicInfo;
  data?: IRoomPublic;
}

export const FacilityInfo = ({ InfoObj, setInfoObj, ...props }: IFacilityInfo) => {
  const { buildInfo } = useContext(GlobalContext);

  const onChangeValue = (str: string, keys: "name" | "number") => {
    if (keys === "number") {
      const valid = /^[0-9]+$/;
      const empty = /^$/;
      if (!valid.test(str) && !empty.test(str)) return alert("대표 번호는 숫자로만 입력 가능합니다.");
    }
    if (str.length > 30) return alert("시설명은 30자 이내로 입력 가능합니다.");
    const newObj = { ...InfoObj };
    newObj[keys] = str;
    setInfoObj(newObj);
  };

  const onChangeAddress = (keys: "dong" | "floor" | "location", str: string) => {
    const newObj = { ...InfoObj };
    if (keys === "dong" && str === "") {
      newObj.floor = "";
      setInfoObj(newObj);
    } else if (keys === "location") {
      if (!InfoObj.dong || !InfoObj.floor) return alert("동, 층을 먼저 선택 후 주소를 입력해주세요.");
    }
    newObj[keys] = str;
    setInfoObj(newObj);
  };

  return (
    <SetDetailImage {...props}>
      <LastShadowBox isMore={true}>
        <div>
          <h4>
            시설명을 입력해주세요. <span>*</span>
          </h4>
          <GrayRadiusInput
            placeholder="회의실"
            value={InfoObj.name || ""}
            _onChange={(e) => onChangeValue(e.target.value, "name")}
          />
        </div>
        <div>
          <h4>
            시설의 주소 및 건물 내 시설의 위치를 입력해주세요. <span>*</span>
          </h4>
          <Flex>
            <GrayColorSelect
              value={InfoObj.dong}
              _style={{ textAlign: "left" }}
              _onChange={(e) => onChangeAddress("dong", e.target.value)}
            >
              <option value="">동 선택</option>
              {buildInfo?.dong_floor.map((el) => (
                <option key={String(el._id)} value={el.dong}>
                  {el.dong}동
                </option>
              ))}
            </GrayColorSelect>
            <GrayColorSelect
              value={InfoObj.floor}
              _style={{ textAlign: "left" }}
              _onChange={(e) => onChangeAddress("floor", e.target.value)}
            >
              <option value="">층 선택</option>
              {buildInfo?.dong_floor
                .find((el) => el.dong === InfoObj.dong)
                ?.floor.map((ele) => (
                  <option key={ele} value={ele}>
                    {ele}층
                  </option>
                ))}
            </GrayColorSelect>
            <GrayRadiusInput
              value={InfoObj.location}
              placeholder="동, 층을 제외한 나머지 주소"
              _style={{ width: "calc(100% - 13rem)" }}
              _onChange={(e) => onChangeAddress("location", e.target.value)}
            />
          </Flex>
        </div>
        <div>
          <h4>입주사 대표 연락처를 입력해주세요.</h4>
          <GrayRadiusInput
            placeholder="숫자만 입력 가능"
            value={InfoObj.number ? InfoObj.number : ""}
            _onChange={(e) => onChangeValue(e.target.value, "number")}
          />
        </div>
        <div>
          <h4>시설 소개를 입력해주세요.</h4>
          <GrayRadiusTextArea
            rows={1}
            value={InfoObj.description || ""}
            placeholder="시설에 대한 소개를 입력해주세요."
            _onChange={(e) => {
              if (e.target.value.length > 300) return alert("시설에 대한 소개글은 300자까지만 입력 가능합니다.");
              const newObj = { ...InfoObj };
              newObj.description = e.target.value;
              setInfoObj(newObj);
            }}
          />
        </div>
      </LastShadowBox>
    </SetDetailImage>
  );
};

interface IItemInfo extends IDefaultImage {
  setItemInfo: Dispatch<SetStateAction<IItemBasicInfo>>;
  itemInfo: IItemBasicInfo;
  // data?: IRoomPublic;
}

export const ItemInfo = (props: IItemInfo) => {
  const interval = { paddingBottom: "12px" };

  return (
    <DetailImage {...props}>
      <LastShadowBox>
        <div style={interval}>
          <h4>
            예약 상품명을 입력해주세요. <span>*</span>
          </h4>
          <GrayRadiusInput
            isLimited={true}
            placeholder="예시) 1일권"
            value={props.itemInfo.name}
            _onChange={(e) => {
              if (e.target.value.length > 30) return;
              props.setItemInfo({ ...props.itemInfo, name: e.target.value });
            }}
          />
        </div>
        <div style={interval}>
          <h4>
            예약 상품을 소개하는 글을 적어주세요. <span>*</span>
          </h4>
          <GrayRadiusTextArea
            rows={1}
            isLimited={true}
            placeholder="예시) 입주자 전용 상품입니다."
            value={props.itemInfo.introduction_description}
            _onChange={(e) => {
              if (e.target.value.length > 1000) return;
              props.setItemInfo({
                ...props.itemInfo,
                introduction_description: e.target.value,
              });
            }}
          />
        </div>
        <div>
          <h4>예약 ・ 방문 안내 사항을 적어주세요.</h4>
          <GrayRadiusTextArea
            rows={1}
            isLimited={true}
            placeholder="예시) 당일 취소는 불가능합니다."
            value={props.itemInfo.reservation_and_visit_description || ""}
            _onChange={(e) => {
              if (e.target.value.length > 1000) return;
              props.setItemInfo({
                ...props.itemInfo,
                reservation_and_visit_description: e.target.value,
              });
            }}
          />
        </div>
      </LastShadowBox>
    </DetailImage>
  );
};
