import GrayColorSelect from "../../../components/select";
import { DateWithIconBox } from "../../../components/styled";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { GrayBackGroundRange } from "../../../components/datePicker";
import {
  DateInputWrap,
  FlexAlignBox,
  ItemSetBox,
  MainContainer,
  MouseShadowBox,
  TableHeaderWrapper,
  TableWrapper,
} from "../../../components/styled/webApp";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../App";
import { useBuildingFloor } from "../../../lib/api/queries/commons";
import { useQuery } from "@tanstack/react-query";
import { temperature_history } from "../../../lib/api/queries/urls";
import { temperature_history_key } from "../../../lib/api/queries/keys";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { ITemperatureHistory } from "../../../lib/types/Imodels";
import moment from "moment";
import { useTemperatureLocation } from "../queries";
import PaginationUI from "../../../components/pagination";
import { usePagination } from "@ajna/pagination";
import TemperatureHistoryItem from "./_view/temperatureItem";
import ExcelFileDownload from "../../complaints/_view/download";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";

export default function TemperatureHistory() {
  const { buildInfo } = useContext(GlobalContext);
  const { data: floors } = useBuildingFloor();
  const { data: location } = useTemperatureLocation();
  const locations = new Set(location?.map((el) => el.location));

  const [dong, setDong] = useState("");
  const [floor, setFloor] = useState("");
  const [area, setArea] = useState("");
  const today = [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
  const [range, setRange] = useState<string[]>(today);
  const [allPage, setAllpage] = useState(1);
  const { currentPage, setCurrentPage } = usePagination({
    initialState: { currentPage: 1 },
  });

  const start_date = range[0] ? range[0] : moment().add(-1, "year").format("YYYY-MM-DD");
  const end_date = range[1] ? range[1] : moment().format("YYYY-MM-DD");

  const { data } = useQuery(
    [
      temperature_history_key,
      range[0],
      range[1] ? range[1] : moment().format("L"),
      dong,
      floor,
      area,
      String(currentPage),
    ],
    () =>
      fetchClient
        .get<BaseResponse<ITemperatureHistory[]>>(temperature_history, {
          params: {
            limit: 10,
            page: currentPage,
            dong,
            floor,
            location: area,
            start_date,
            end_date,
            // search
          },
        })
        .then((res) => {
          setAllpage(res.data.total_page_num || 1);
          return res.data.data;
        }),
  );

  return (
    <MainContainer>
      <ItemSetBox>
        <FlexAlignBox>
          {/* <DateWithIconBox>
            <DatePicker
              locale={ko}
              selectsRange={true}
              startDate={range[0] ? new Date(range[0]) : null}
              endDate={range[1] ? new Date(range[1]) : null}
              dateFormat="yyyy. MM. dd"
              isClearable={false}
              onChange={(date) => {
                setRange(
                  date.map((el) => (el ? moment(el).format("YYYY-MM-DD") : el))
                );
                if (date[0] && date[1] && currentPage !== 1) setCurrentPage(1);
              }}
              customInput={<ShadowDateRange value={JSON.stringify(range)} />}
            />
          </DateWithIconBox> */}
          <DateInputWrap
            style={{
              background: "#fff",
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            <DateWithIconBox>
              <DatePicker
                locale={ko}
                selected={range[0] ? new Date(range[0]) : new Date()}
                minDate={
                  new Date(
                    range[0] === moment().format("YYYY-MM-DD")
                      ? moment().add(-1, "year").format("YYYY-MM-DD")
                      : (range[1] ? moment(range[1]) : moment()).add(-1, "year").format("YYYY-MM-DD"),
                  )
                }
                maxDate={range[1] && moment().diff(moment(range[1])) > 0 ? new Date(range[1]) : new Date()}
                dateFormat="yyyy. MM. dd"
                onChange={(date) => {
                  setRange([moment(date).format("YYYY-MM-DD"), range[1]]);
                }}
                // onChange={onChangeEndDate}
                customInput={
                  <GrayBackGroundRange
                    style={{ margin: "0 8px 0 0" }}
                    // style={{ background: "inherit" }}
                    value={range[0]}
                  />
                }
              />
            </DateWithIconBox>
            <div>~</div>
            <DateWithIconBox>
              <DatePicker
                locale={ko}
                selected={range[1] ? new Date(range[1]) : new Date()}
                minDate={new Date(range[0] ? range[0] : moment().add(-1, "year").format("YYYY-MM-DD"))}
                maxDate={new Date()}
                dateFormat="yyyy. MM. dd"
                onChange={(date) => setRange([range[0], moment(date).format("YYYY-MM-DD")])}
                // onChange={onChangeEndDate}
                customInput={
                  <GrayBackGroundRange
                    style={{ margin: "0 24px 0 8px" }}
                    // style={{ background: "inherit" }}
                    value={"2022-01-10"}
                  />
                }
              />
            </DateWithIconBox>
            <FiChevronDown />
          </DateInputWrap>
          <MouseShadowBox
            style={{ margin: "0 12px 0 4px" }}
            onClick={() => {
              if (currentPage !== 1) setCurrentPage(1);
              setRange(today);
            }}
          >
            오늘
          </MouseShadowBox>
          {/* <GrayRadiusWithIcon
          _style={{ minWidth: "17.5rem" }}
          // value={search}
          placeholder="접수자명, 전화번호로 검색"
          _onChange={(e) => console.log(e)}
          _onKeyUp={(e) => e.key === "Enter" && refetch()}
          _onClick={() => refetch()}
        /> */}
        </FlexAlignBox>
        <ExcelFileDownload
          isToast={data?.length === 0}
          dateRange={`${start_date} ~ ${end_date}`}
          {...{ dong, floor, location: area }}
        />
      </ItemSetBox>
      <FlexAlignBox style={{ paddingBottom: "1rem" }}>
        <GrayColorSelect
          value={dong}
          _style={{ textAlign: "left" }}
          _onChange={(e) => {
            if (currentPage !== 1) setCurrentPage(1);
            setDong(e.target.value);
          }}
        >
          <option value="">동</option>
          {buildInfo?.dong_floor?.map((el) => (
            <option key={String(el._id)} value={el.dong}>
              {el.dong}
            </option>
          ))}
        </GrayColorSelect>
        <GrayColorSelect
          value={floor}
          _style={{ textAlign: "left" }}
          _onChange={(e) => {
            if (currentPage !== 1) setCurrentPage(1);
            setFloor(e.target.value);
          }}
        >
          <option value="">층</option>
          {floors?.map((ele) => (
            <option key={ele} value={ele}>
              {ele}층
            </option>
          ))}
        </GrayColorSelect>
        <GrayColorSelect
          w="11rem"
          value={area}
          _style={{ textAlign: "left" }}
          _onChange={(e) => {
            if (currentPage !== 1) setCurrentPage(1);
            setArea(e.target.value);
          }}
        >
          <option value="">구역</option>
          {Array.from(locations).map((el) => (
            <option key={el}>{el}</option>
          ))}
        </GrayColorSelect>
      </FlexAlignBox>
      <TableWrapper>
        <TableHeaderWrapper>
          <div>요청</div>
          <div style={{ width: "20%" }}>위치</div>
          <div>접수자명</div>
          <div>접수일시</div>
          <div style={{ width: "35%" }}>처리상태</div>
        </TableHeaderWrapper>
        {data?.map((el) => <TemperatureHistoryItem key={String(el._id)} el={el} />)}
      </TableWrapper>
      <PaginationUI page={currentPage} setPage={setCurrentPage} allPage={allPage} />
    </MainContainer>
  );
}
