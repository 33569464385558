import { Box, useDisclosure } from "@chakra-ui/react";
import Basicframe, { RoomPublicContext } from "../../component/basicframe";
import { RestrictUser } from "./_view/restrictItem";
import EditModalNoHeader from "../../../../components/modal/responsive/edit";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { useContext, useEffect, useState } from "react";
import { useMutatePublicRoom } from "../../commons/queries";
import {
  MainContainer,
  UpdateIconBox,
  MainActionBox,
  ShadowBoxInDetail,
  BoxInDetail,
} from "../../../../components/styled/webApp";
import { tenant_type } from "../../commons/func";
import { useRoomType } from "../../../../lib/utils";
import { toast } from "../../../../lib/utils/toast";

const FacilityRestrict = () => {
  const room_type = useRoomType();
  const { room_info: data } = useContext(RoomPublicContext);
  const { updatePublicRoom } = useMutatePublicRoom();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [isTenantArr, setIsTenantArr] = useState<string[]>([]);

  useEffect(() => {
    if (!data) return;

    setIsTenantArr(data.available_rooms);
    return () => {
      setIsTenantArr([]);
    };
  }, [data]);

  const onBeforeUpdate = async () => {
    if (updatePublicRoom.isLoading) return;
    if (isTenantArr.length === 0)
      return toast.warning({
        title: "알림",
        description: "이용 가능한 호실용도를 먼저 선택해주세요.",
      });

    try {
      await updatePublicRoom.mutateAsync({
        room_public_id: String(data?._id),
        available_rooms: isTenantArr,
      });

      toast.success({
        title: "시설 정보 변경",
        description: `해당 시설 정보가 변경되었습니다.`,
      });
    } catch (err) {
      return toast.warning({
        title: "시설 정보 수정 오류",
        description: "시설 수정에 일시적으로 실패했습니다. 잠시 후 다시 시도해주세요.",
      });
    }
    // mutation.mutate();
  };

  return (
    <MainContainer>
      <MainActionBox>
        <ShadowBoxInDetail>
          <BoxInDetail>
            <h4>이용 가능한 호실용도</h4>
            {room_type.length > 1 && (
              <UpdateIconBox onClick={onOpen}>
                수정하기 <FiChevronRight />
              </UpdateIconBox>
            )}
          </BoxInDetail>
          <Box fontWeight={500}>
            {data?.available_rooms.map((el, idx) => {
              const index = tenant_type.findIndex((type) => type.label === el);
              return idx + 1 === data.available_rooms.length
                ? tenant_type[index].value
                : `${tenant_type[index].value}, `;
            })}
          </Box>
        </ShadowBoxInDetail>
        <EditModalNoHeader
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setIsTenantArr(data?.available_rooms || []);
          }}
          onClickUpdate={onBeforeUpdate}
        >
          <RestrictUser isModal={true} isTenantArr={isTenantArr} setIsTenantArr={setIsTenantArr} />
        </EditModalNoHeader>
      </MainActionBox>
    </MainContainer>
  );
};

export default function FixedFacilityRestrict() {
  return (
    <Basicframe>
      <FacilityRestrict />
    </Basicframe>
  );
}
