import { Box, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { colors } from "../../../../components/styled/common";
import { RoomInput } from "../../../meeting-rooms/setting/_view/styled";
import { NoticeBox } from "../../units/styled";

export const TableCount = styled(Flex)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
`;

export const SelectContainer = styled(Box)`
  width: 180px;
  margin-right: 8px;
`;

export const NoticeList = styled(NoticeBox)`
  padding: 24px;
`;

export const UploadFileBox = styled.div`
  border-top: 1px solid ${colors.gray2};
  padding-top: 20px;
  width: 100%;
  display: flex;
`;

export const OwnerBox = styled.div`
  width: calc(864px - 30%);
  display: flex;
  justify-content: space-between;
`;

export const ChangedOwner = styled.div`
  margin-top: 24px;
  border-top: 1px solid ${colors.gray2};
`;

export const SendChangedOwner = styled.div`
  margin-top: 24px;
  padding-top: 18px;
  border-top: 1px solid ${colors.gray2};
  p {
    color: ${colors.mainBlue};
  }
`;

export const SpinBox = styled.div`
  width: 100%;
  min-height: 232px;
  position: relative;
`;

export const AreaRatioInput = styled(RoomInput)`
  :disabled {
    background-color: #f4f4f5;
  }
`;
