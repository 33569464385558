import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { Button, Checkbox, Menu, MenuButton, MenuDivider, MenuItem, MenuList, MenuOptionGroup } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { PropsWithChildren, useState } from "react";
import { ICategory } from "../../../lib/types/Imodels";
import { useRoomType } from "../../../lib/utils";

interface ISelectBox extends PropsWithChildren {
  default: string;
  text: string[] | string | undefined;
  checkedItems: string[];
  // setCheckedItems: Dispatch<SetStateAction<string[]>>;
  maxItems?: string[];
  onCheckedAll: (checked: boolean) => void;
  category?: void | ICategory[] | undefined;
  value?: string[];
  data?: string[];
}

const ListStyle = styled(MenuItem)`
  color: #6b7280;
  border-bottom: 1px solid #f4f4f5;
  padding: 8px 12px;
  text-align: left;
`;

const ButtonContents = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectCheckedBox: React.FC<ISelectBox> = ({ children, ...props }) => {
  const room_type = useRoomType();
  const [status, setStatus] = useState(false);

  return (
    <>
      <Menu variant="default" closeOnSelect={false} isOpen={status}>
        <MenuButton type="button" onClick={() => setStatus((prev) => !prev)}>
          <ButtonContents>
            {typeof props.text === "string"
              ? props.text
              : (
                    props.maxItems
                      ? props.text?.length === props.maxItems.length
                      : props.text?.length === room_type?.length
                  )
                ? "전체"
                : `${props.text?.length}개 선택됨`}
            <FiChevronDown fontSize="18px" />
          </ButtonContents>
        </MenuButton>
        <MenuList id="selectBox">
          <MenuOptionGroup type="checkbox">
            <ListStyle value={`${props.default}`}>
              <Checkbox
                variant="default"
                isChecked={
                  props.checkedItems?.length === 0 ||
                  (props.maxItems
                    ? props.checkedItems?.length !== props.maxItems.length
                    : props.checkedItems?.length !== room_type?.length)
                    ? false
                    : true
                }
                onChange={(e) => props.onCheckedAll(e.target.checked)}
                w={"100%"}
                value={JSON.stringify(props.checkedItems)}
              >
                {props.default}
              </Checkbox>
            </ListStyle>
            {children}
            <MenuDivider m={0} borderColor="#9CA3AF" />
            <Button margin="4px" type="button" variant="bgBlue" onClick={() => setStatus(false)}>
              선택완료
            </Button>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </>
  );
};

export default SelectCheckedBox;
