import { MinimunBox, MiniStandard } from "../styled/webApp";
import { MouseEvent, Dispatch, SetStateAction } from "react";
import { MdMoreHoriz } from "@react-icons/all-files/md/MdMoreHoriz";
import { MdEdit } from "@react-icons/all-files/md/MdEdit";
import { AiOutlineDelete } from "@react-icons/all-files/ai/AiOutlineDelete";

interface IStandardMenu {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onEditAction: (e: MouseEvent<HTMLLIElement>) => void;
  onDeleteAction: () => void;
  //  => map과 함께
  // id?: string;
  // refs?: ForwardedRef<HTMLDivElement>;
  // onClickSheet : () => void;
}

export default function StandardMenuSheet(props: IStandardMenu) {
  return (
    <MiniStandard
      isOpen={props.isOpen}
      onClick={() => props.setIsOpen((prev) => !prev)}
      // onClick={props.onClickSheet}
    >
      <MdMoreHoriz />
      <MinimunBox isOpen={props.isOpen}>
        <ul>
          <li onClick={props.onEditAction}>
            <MdEdit />
            수정
          </li>
          <li onClick={props.onDeleteAction}>
            <AiOutlineDelete />
            삭제
          </li>
        </ul>
      </MinimunBox>
    </MiniStandard>
  );
}
