import { HTMLAttributes } from "react";

export type Props = object & HTMLAttributes<HTMLOrSVGElement>;

export default function IconArrow(props: Props) {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.94357 5.34529C8.21311 5.12966 8.60642 5.17336 8.82205 5.4429L12.1554 9.60957C12.338 9.83783 12.338 10.1622 12.1554 10.3904L8.82205 14.5571C8.60642 14.8266 8.21311 14.8703 7.94357 14.6547C7.67403 14.4391 7.63033 14.0458 7.84596 13.7762L10.867 10L7.84596 6.22377C7.63033 5.95423 7.67403 5.56092 7.94357 5.34529Z"
        fill="#6B7280"
      />
    </svg>
  );
}
