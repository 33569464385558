import { useNavigate, useParams } from "react-router-dom";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { delete_rental, detail_rental } from "../../../lib/api/queries/urls";
import { detail_rental_key } from "../../../lib/api/queries/keys";
import { fetchClient } from "../../../lib/api/axios";
import { IRentalList } from "../../../lib/types/Imodels";
import useUserAuth from "../../../components/hooks/useUserAuth";
import BillLetterView from "../../maintenance-fee/component/DetailBillLetter";
import { usePagination } from "@ajna/pagination";
import FullPageSpinner from "../../../components/Spinner";
import NotFound from "../../not-found";
import GetAuthorized from "../../../components/Authrized";
import { GlobalContext } from "../../../App";

export default function RentFeeDetailPage() {
  const navigate = useNavigate();
  const { rentalId } = useParams();
  const auth = useUserAuth("rent_fee");
  const { buildInfo } = useContext(GlobalContext);
  const hasFunc = buildInfo?.services.RENT_FEE?.enabled;
  const [dong, setDong] = useState("");
  const [status, setStatus] = useState("");
  const [inputs, setInputs] = useState({ id: "search_ho", value: "" });

  const { currentPage, setCurrentPage } = usePagination({
    initialState: { currentPage: 1 },
  });

  const { data, refetch } = useQuery(
    [detail_rental_key],
    () =>
      fetchClient
        .post<BaseResponse<IRentalList>>(detail_rental, {
          rent_fee_id: rentalId,
          payment_status: status,
          dong,
          [inputs.id]: inputs.value,
        })
        .then((res) => res.data.data),
    { enabled: hasFunc },
  );

  const onRefetch = () => {
    refetch();
  };

  const mutation = useMutation(() =>
    fetchClient
      .post<BaseResponse<string>>(delete_rental, { rent_fee_id: rentalId })
      .then(() => {
        alert("임대료 납부 고지서를 삭제했습니다. 확인을 누르면 임대료 현황 페이지로 이동합니다.");
        navigate("/rent-fee", { replace: true });
      })
      .catch(() => alert("일시적인 오류로 납부 고지서 삭제에 실패했습니다. 잠시 후 다시 시도해주세요.")),
  );

  const onDeleteBill = () => {
    if (mutation.isLoading) return;
    mutation.mutate();
  };

  if (hasFunc === undefined || !auth) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;
  if (auth === "no_auth") return <GetAuthorized />;
  return (
    <BillLetterView
      auth={auth}
      data={data}
      isDirect={true}
      inputs={inputs}
      setInputs={setInputs}
      dong={dong}
      setDong={setDong}
      status={status}
      setStatus={setStatus}
      onRefetch={onRefetch}
      onDeleteBill={onDeleteBill}
      setCurrentPage={setCurrentPage}
    />
  );
}
