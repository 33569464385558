import { Button, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { faqs } from "../page";
import GrayRadiusInput from "../../../components/Input/responsive";
import SubframePart from "../../../components/Main/subframePart";
import MultipleImageView from "../../../components/preview";
import { MainActionBox, ShadowBox, LastShadowBox } from "../../../components/styled/webApp";
import GrayRadiusTextArea from "../../../components/textarea";
import { onLimitTextNum } from "../../../lib/utils/format";
import { fetchClient } from "../../../lib/api/axios";
import { create_faq } from "../../../lib/api/queries/urls";
import { useUploadFile } from "../../../lib/api/queries/commons";
import { toast } from "../../../lib/utils/toast";

type IMutate = {
  images: string[];
  faq_id?: string;
};

const SelectButton = styled(Button)`
  min-width: 7rem;
  margin: 0 12px 12px 0;
  :nth-of-type(4) {
    margin: 0 12px 0 0;
  }
  :last-of-type {
    margin: 0 12px 0 0;
  }
`;

const fileImageValidate = (files: FileList | null) => {
  if (files === null) return false;

  // TODO: PDF 대응으로 잠시 비활성화
  // if (Array.from(files).filter((el) => el.size > 30 * 1024 * 1024).length !== 0) return false;
  // if (files.length > 5) return false;

  const newArr = Array.from(files).map((el) => el.name.split(".")[el.name.split(".").length - 1]);
  if (newArr.filter((el) => !["jpeg", "jpg", "png"].includes(el.toLowerCase())).length !== 0) {
    return false;
  }

  return true;
};

export default function ResourceCreatePage() {
  const navigate = useNavigate();

  const [select, setSelect] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const fileRef = useRef<HTMLInputElement>(null);
  const [fileArr, setFileArr] = useState<File[]>([]);
  const [urlArr, setUrlArr] = useState<string[]>([]);

  const onChangeFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files === null) return;
    const newFileArr = [...fileArr, ...Array.from(files)];
    // if ([...urlArr, ...newFileArr].length >= 5)
    //   return toast({
    //     ...underFiveImage,
    //     description: "첨부 가능한 파일 갯수는 5개, 한 파일당 크기는 30MB 이하여야합니다.",
    //   });

    const valid = fileImageValidate(files);
    if (!valid) return toast.underFiveImage({ description: "첨부 파일 형식은 JPG, JPEG, PNG 형식만 가능합니다." });
    setFileArr(newFileArr);
    e.target.value = "";
  };

  const mutation = useMutation((obj: IMutate) =>
    fetchClient
      .post(create_faq, {
        category: select,
        question: title,
        answer: body,
        ...obj,
      })
      .then((res) => {
        toast.success({
          title: "시설안내 등록",
          description: "시설안내가 등록되었습니다.",
        });
        navigate("/resources");
        return res.data.data;
      })
      .catch(() => {
        toast.warning({
          title: "시설안내 등록",
          description: "일시적으로 시설안내 등록에 실패했습니다. 필수 항목을 입력했는 지 확인 후 다시 시도해주세요.",
        });
      }),
  );

  const onComfirmFaq = () => {
    if (mutation.isLoading) return;
    if (!title || !select || !body)
      return toast.warning({
        title: "필수 항목 입력",
        description: "필수 항목을 입력했는 지 확인 후 다시 시도해주세요.",
      });

    const newObj: IMutate = { images: urlArr };

    if (fileArr && fileArr.length !== 0) {
      useUploadFile(fileArr)
        .then((res) => {
          newObj.images = [...urlArr, ...res.data.data];
          mutation.mutate(newObj);
        })
        .catch(() =>
          toast.warning({
            title: "알림",
            description: "일시적으로 파일 등록에 실패했습니다. 잠시 후 다시 시도해주세요.",
          }),
        );
    } else {
      mutation.mutate(newObj);
    }
  };

  const onCancleCreateFAQ = () => {
    return false;
  };

  return (
    <SubframePart isWarnMessage={onCancleCreateFAQ} name="새로운 자료 등록">
      <MainActionBox>
        <ShadowBox>
          <div>
            <h4>
              카테고리를 선택해주세요 (중복선택 불가) <span>*</span>
            </h4>
          </div>
          <Flex w="100%" flexWrap="wrap">
            {faqs.map((el) => (
              <SelectButton
                key={el}
                value={el}
                variant={select === el ? "m_primary" : "m_gray"}
                onClick={(e) => setSelect(e.currentTarget.value)}
              >
                {el}
              </SelectButton>
            ))}
          </Flex>
        </ShadowBox>
        <LastShadowBox isMore={true}>
          <div>
            <h4>
              제목을 입력해주세요. <span>*</span>
            </h4>
            <GrayRadiusInput
              placeholder="제목을 입력해주세요."
              value={title || ""}
              isLimited={true}
              num={50}
              _onChange={(e) => onLimitTextNum(e, setTitle)}
            />
          </div>
          <div>
            <h4>
              상세 내용을 입력해주세요.<span>*</span>
            </h4>
            <GrayRadiusTextArea
              _style={{ height: "16rem" }}
              placeholder="상세 내용을 입력해주세요."
              value={body || ""}
              _onChange={(e) => {
                if (e.target.value.length > 900) return;
                setBody(e.target.value);
              }}
              isLimited={true}
              num={900}
            />
          </div>
          <div>
            <h4>파일을 등록해주세요.</h4>
            <Button
              variant="m_primary_line"
              onClick={() => {
                fileRef.current?.click();
              }}
            >
              <FiPlus />
              파일 첨부
            </Button>
            <MultipleImageView
              fileUrlArr={urlArr}
              setFileUrlArr={setUrlArr}
              files={fileArr}
              setFiles={setFileArr}
              auth="edit"
            />
            <input type="file" multiple={true} ref={fileRef} onChange={onChangeFiles} style={{ display: "none" }} />
          </div>
        </LastShadowBox>

        <Button variant="m_blue" onClick={onComfirmFaq}>
          등록하기
        </Button>
      </MainActionBox>
    </SubframePart>
  );
}
