import { useQuery } from "@tanstack/react-query";
import { http } from "../lib/http";
import { v4 as uuidv4 } from "uuid";

type Response = {
  data: {
    count: number;
    dong: string;
    ho: string;
    user_name: string;
    user_phone_number: string;
    room_ids: string[];
  }[];
};

export const useGetOwnersQuery = () => {
  return useQuery({
    queryKey: ["/building/owner_list"],
    queryFn: async () => {
      const response = await http.get<Response>("/building/owner_list");
      const uuidArray = response.data.map((item) => ({
        ...item,
        _id: uuidv4(),
      }));
      return { data: uuidArray };
    },
  });
};
