import { CloseButton, Spinner } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { CSSProperties, Dispatch, FC, SetStateAction, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { colors } from "../../components/styled/common";
import { breakPoints } from "../../components/styled/media";

interface IAgreeModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  style?: CSSProperties;
  isLoading: boolean;
  scroll: number;
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props: IOpen) => (props.isOpen ? "10" : "-10")};
`;

const ManageInfo = styled.div`
  font-size: 16px;
  color: ${colors.gray5};
  @media ${breakPoints.phones} {
    font-size: 14px;
  }
`;

const AgreementBold = styled.div`
  font-size: 20px;
  color: ${colors.gray5};
  @media ${breakPoints.phones} {
    font-size: 18px;
  }
`;

const AgreeInfo = styled.ul<{ w?: boolean }>`
  padding: 8px 0 8px 16px;
  li {
    ${({ w }) => w && `padding-bottom: 4px; list-style:decimal`};
  }
`;

interface IOpen {
  isOpen: boolean;
}

const BackgroundWrapper = styled.div`
  width: 540px;
  padding: 46px 20px;
  background-color: rgb(60, 60, 67, 0.5);
  flex-direction: column;
  align-items: center;
  display: ${(props: IOpen) => (props.isOpen ? "flex" : "none")};
`;

const ModalWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  height: calc(var(--vh, 1vh) * 100 - 100px);
  overflow: hidden;
  padding: 16px 16px 24px 16px;
  background-color: #fff;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  color: ${colors.gray4};
  line-height: 1.5;
`;

const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ContentBox = styled.div`
  height: calc(100% - 78px);
  width: 100%;
  overflow-y: scroll;
  margin-bottom: 12px;
  padding-right: 8px;
`;

const CloseModal = styled(CloseButton)`
  :hover {
    background-color: #fff;
  }
`;

const ButtonBox = styled.div`
  width: 100%;
  background-color: #fff;
`;

const NextStepButton = styled.button`
  width: 100%;
  font-size: 20px;
  color: #fff;
  border-radius: 12px;
  background-color: ${colors.mainBlue};
  padding: 12px;
  @media ${breakPoints.phones} {
    font-size: 18px;
  }
`;

const AgreementModal: FC<IAgreeModalProps> = (props) => {
  let vh = 100;
  const location = useLocation();
  const building_id = location.search.split("=")?.[1];

  useEffect(() => {
    // console.log(scrollBottom);
    // setScrollBottom(document.documentElement.scrollTop);
    window.scrollTo({
      top: props.isOpen ? 0 : props.scroll,
      left: 0,
      behavior: "smooth",
    });
  }, [props.isOpen]);

  useEffect(() => {
    // 기기 사이즈별 모달 높이
    vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, [window.innerHeight]);

  return (
    <Wrapper isOpen={props.isOpen}>
      <BackgroundWrapper isOpen={props.isOpen}>
        <ModalWrapper>
          <ContentBox>
            <ModalHeader onClick={() => props.setIsOpen(false)}>
              <CloseModal />
            </ModalHeader>
            <div style={{ position: "relative" }}>
              <AgreementBold>
                입주 카드 제출 전<br />
                다음 내용을 꼭 읽어주세요!
              </AgreementBold>
              {building_id === "642520bd006df0e5cfe1fb2a" ? ( //오산 전용
                <>
                  <ManageInfo style={{ padding: "12px 0" }}>개인정보 수집•이용 및 제공 동의서</ManageInfo>
                  『오산현대테라타워CMC 지식산업센터』 관리단은 이 관리규약이 정하는 관리단 운영의 필요한 사항을
                  수행하기 위한 개인정보를 「개인정보보호법」 제15조,제17조,제23조,제24조에 따라 구분소유권자 및
                  점유자의 동의하에 다음과 같이 개인정보를 수집.이용 또는 제3자에게 제공하고 있습니다.
                  <AgreeInfo w={true}>
                    <li>개인정보 수집 및 이용 목적 : 당 지식산업센터 관리단 운영에 필요한 관리규약으로 정한 사항 등</li>
                    <li>개인정보 처리 및 보유 기간 : 해당 호실을 소유.점유하는 기간까지</li>
                    <li>
                      개인정보의 제3자 제공 관한 사항 <br />□ 제공받는자 : 한국전력공사, 한국지역난방공사, 경찰서, 법원,
                      지방자치단체, 소방서, 세무서외 관공서 등 <br />□ 제공받는자의 이용 목적 : 관리비 등의 체납자에
                      대한 조치, 관공서의 정당한 공무집행에 대한 협조 등
                    </li>
                    <li>
                      개인정보 이용 항목 <br />□ 성명, 생년월일, 주민등록번호, 주소, 연락처, 영상 등
                    </li>
                    <li>
                      수집된 개인정보의 파기에 관한 사항 <br />□ 당 지식산업센터 관리단에서 정한 절차에 따라 관리비 등의
                      정산을 완료, 전출한 경우 지체없이 파기해야한다.
                    </li>
                    <li>
                      비동의시 불이익 내용 <br />□ 당 지식산업센터 관리단에서 개인정보를 수집하고 있으나, 이에 동의하지
                      않을 수 있으며, 동의하지 않을 경우 규약으로 정한 구분소유권자등의 권리가 제한될 수 있으며,
                      비상시(차량파손, 급배수 누출 또는 화재 등) 적시에 필요 한 조치 등을 받지 못하는 피해를 해당
                      구분소유권자가 받을 수 있습니다.
                    </li>
                  </AgreeInfo>
                </>
              ) : (
                <>
                  <p style={{ padding: "12px 0" }}>
                    본 카드에 작성하신 내용은 건물 관리규약이 정하는 업무지원센터 운영의 필요한 사항(관리비 등의 체납에
                    대한 조치 등)을 수행하기 위한 개인정보를 「개인정보보호법」 제15조, 제17조, 제23조, 제24조에 따라
                    구분소유권자 및 점유자의 동의하에 다음과 같이 개인정보를 수집, 이용 또는 제3자에게 제공하는것에
                    대하여 동의합니다.
                  </p>
                  <ManageInfo>관리비 관리 안내</ManageInfo>
                  <ul style={{ padding: "8px 0 8px 16px" }}>
                    <li>
                      관리비는 각종 법정요금 및 공과금, 시설관리비, 인건비 등이 포함되어 관리비를 미납할 경우 건물
                      전체의 관리운영에 어려움을 초래할 뿐만 아니라 성실하게 관리비를 납부해 주시는 입주자에게 피해를
                      주는 것입니다. <br />
                      공공요금(전기, 수도, 지역냉 · 난방 등)이 대부분을 차지하고 있어 공동생활을 함에 있어 어느
                      비용보다도 우선적으로 처리되어야 합니다. <br />
                      <span style={{ color: "#FB4667" }}>
                        미납관리비가 3개월 이상 연체 시, 관리규약 및 관련법규에 의거하여 단전, 단수 및 주차 차량 말소가
                        가능하며, 소유주는 임차보증금으로 상계 처리 할 수가 있습니다.
                      </span>
                    </li>
                    <li>
                      입주 시 생활안내수칙을 반드시 숙지하여 지켜야 합니다.(퇴실시 시설물 및 비품(카드키 등) 훼손시
                      변상조치)
                    </li>
                  </ul>
                  {/* <ManageInfo>주차장 이용자 준수사항</ManageInfo>
                  <ul style={{ padding: "8px 0 8px 16px" }}>
                    <li>
                      주차장 내 표지 및 유도표시와 신호를 따르며 위험 상황 발생 시 정차할 수 있도록 서행해야
                      한다.(10km/h 이내)
                      <span>유도선을 무시하고 역행하는 행위를 해서는 안 되며 경적을 사용해서는 아니 된다.</span>
                      <span>주차는 표시된 1개의 주차구역에 정돈 주차한다. </span>
                    </li>
                    <li>주차차량에 도난 등의 우려가 있는 현금, 유가증권, 귀중품을 두어서는 아니 된다.</li>
                    <li>
                      주차장 내 차량사고, 기물 및 시설물 파손, 훼손 등 사고가 발생하거나 목격한 경우 업무지원센터에
                      신고해야 한다.
                    </li>
                    <li>
                      장애인 전용주차 공간에 일반인 주차 시 관계기관 고발 및 견인 조치(견인료 차주 부담)하니 주차를
                      금해야 한다.
                    </li>
                  </ul> */}
                  <ManageInfo>주차장 이용자 금지사항</ManageInfo>
                  <ul style={{ padding: "8px 0 8px 16px" }}>
                    <li>주차장 내의 시설물 및 공동 사용물을 훼손, 오염, 파손 및 변경하는 행위</li>
                    <li>위험물, 유해 악취물, 소음발생의 염려가 있거나 유해한 물품을 반입 및 보관하는 행위</li>
                    <li>차량의 수리, 급유 및 세차 등을 하는 행위</li>
                    <li>주차장 내에 물건(상품)을 적재하는 행위</li>
                    <li>기타 주차장 관리에 해를 주거나 부적합한 행위</li>
                  </ul>
                </>
              )}
            </div>
          </ContentBox>
          <ButtonBox>
            <NextStepButton disabled={props.isLoading} type="submit">
              {props.isLoading ? <Spinner /> : <>모두 동의하고 제출하기</>}
            </NextStepButton>
          </ButtonBox>
        </ModalWrapper>
      </BackgroundWrapper>
    </Wrapper>
  );
};

export default AgreementModal;
