import { Button, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../App";
import { useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { IUser } from "../../../lib/types/Imodels";
import { user_list } from "../../../lib/api/queries/urls";
import { user_list_key } from "../../../lib/api/queries/keys";
import CommonMenuTabframe from "../../../components/Main/MenuTabFrame";
import { ItemSetBox, ItemSettingBox, MainContainer } from "../../../components/styled/webApp";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import styled from "@emotion/styled";
import { colors } from "../../../components/styled/common";
import CreateUser from "../../control/manager/create";
import AuthDrawer from "../../control/manager/permission";
import DepartmentDrawer from "../../control/manager/teams";
import GrayShadowSelect from "../../../components/select/shadow";
import UserInfoInToggle from "../../control/manager/userItem";
import { usefetchTeamList } from "../../control/manager/commons/queries";

const ButtonAlignInBox = styled(ItemSettingBox)`
  button {
    min-width: 6.5rem;
    margin-right: 0.75rem;
    svg {
      margin-right: 12px;
    }
  }
  button:last-of-type {
    margin-right: 0;
  }
`;

const ToggleTable = styled.div`
  color: ${colors.gray4};
  border-top: 1px solid ${colors.gray5};
  > div:last-of-type {
    border-bottom: 1px solid ${colors.gray2};
  }
`;

const TabIcons = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${colors.gray4};
  cursor: pointer;
  img {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 4px;
  }
`;

const ShowUserList = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${colors.gray2};
  font-weight: 500;
  color: ${colors.gray5};
  span {
    color: ${colors.gray3};
    margin-left: 12px;
  }
`;

export default function ManagerSettings() {
  const { user: master } = useContext(GlobalContext);
  const { onOpen, isOpen, onClose } = useDisclosure(); //관리자 추가
  const { onOpen: onDepart, isOpen: isDepart, onClose: offDepart } = useDisclosure(); //관리자 권한
  const { onOpen: onAuth, isOpen: isAuth, onClose: offAuth } = useDisclosure(); //관리자 권한
  const { data: team } = usefetchTeamList(true);
  const [department, setDepartment] = useState("");

  const { data } = useQuery([user_list_key, department], () =>
    fetchClient
      .get<BaseResponse<IUser[]>>(user_list, {
        params: { department_id: department },
      })
      .then((res) => res.data.data),
  );

  return (
    <div className="w-full px-6 py-5">
      <ItemSetBox className="flex w-full items-center justify-between pb-4">
        <GrayShadowSelect value={department} _onChange={(e) => setDepartment(e.target.value)}>
          <option value="">전체</option>
          {team?.map((el) => (
            <option key={String(el._id)} value={String(el._id)}>
              {el.name}
            </option>
          ))}
        </GrayShadowSelect>
        {master?.user_type === "master" && (
          <ButtonAlignInBox>
            <Button variant="m_blue" w="10rem" onClick={onOpen}>
              <FiPlus /> 관리자 추가
            </Button>
            {isOpen && <CreateUser isOpen={isOpen} onClose={onClose} />}
            <Button variant="m_primary" onClick={onDepart}>
              소속 설정
            </Button>
            {isDepart && <DepartmentDrawer isOpen={isDepart} onClose={offDepart} />}
            <Button variant="m_primary" onClick={onAuth}>
              권한 설정
            </Button>
            {isAuth && <AuthDrawer isOpen={isAuth} onClose={offAuth} />}
          </ButtonAlignInBox>
        )}
      </ItemSetBox>
      <ToggleTable>
        <ShowUserList>
          {team && team.find((el) => String(el._id) === department) ? (
            <>
              {team.find((el) => String(el._id) === department)?.name} ({data?.length || 0})
              {<span>{team.find((el) => String(el._id) === department)?.phone_number}</span>}
            </>
          ) : (
            `전체 (${data?.length || 0})`
          )}
        </ShowUserList>
        {data && data.map((el) => <UserInfoInToggle key={String(el._id)} el={el} />)}
      </ToggleTable>
    </div>
  );
}
