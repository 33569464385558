import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../App";
import { useNavigate } from "react-router-dom";
import { Box, Text, useDisclosure, useToast } from "@chakra-ui/react";
import * as C from "../../../components/styled/index";
import * as D from "../../control/default/styled";
import OfficeInfoModal from "../_view/OfficeInfo";
import ManagementModal from "../_view/managementOffice";
import { warning } from "../../../lib/theme/toast";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import FullPageSpinner from "../../../components/Spinner";

export default function OfficeSettings() {
  const { kakao } = window;
  const toast = useToast();
  const navigate = useNavigate();
  const { buildInfo } = useContext(GlobalContext);
  const { isOpen: isInfo, onOpen: onInfo, onClose: offInfo } = useDisclosure(); //기본 정보 open
  const { isOpen: isContact, onOpen: onContact, onClose: offContact } = useDisclosure(); //비상 연락망 open
  const [loading, setLoading] = useState(false);
  const [kakaoScript, setKaKaoSctipt] = useState<HTMLScriptElement | null>(null);

  const onLoadBuild = () => {
    toast({
      ...warning,
      title: "알림",
      description: "건물 정보를 불러오는 중입니다. 잠시 후 다시 시도해주세요.",
    });
  };

  useEffect(() => {
    if (kakaoScript) return;
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_API_KEY}&libraries=services&autoload=false`;
    document.head.appendChild(script);
    script.onload = () => setKaKaoSctipt(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!kakaoScript) return;
    kakao.maps.load(() => {
      console.log(kakao);
      setLoading(false);
    });
  }, [kakaoScript]);

  if (loading) return <FullPageSpinner />; //kakao map script 로드 전
  return (
    <div className="flex min-h-full w-full flex-col rounded-2xl bg-white px-6 py-2">
      <div className="py-5">
        <D.TitleWithIcon>
          <Text variant="subTitle">건물 소개</Text>
          <D.HoverPreviewBox>
            <C.QuestionMark />
            <D.PreviewBox className="preview">
              <D.PreviewExample>
                <h4>| 건물 정보 예시</h4>
                <p>건물 정보를 모두 입력하시면 앱에서는 아래 예시와 같이 표시됩니다.</p>
                <div>
                  <D.AppImages alt="미리보기 예시" src="/inApp/preview_1.png" />
                  <D.AppImages alt="미리보기 예시" src="/inApp/preview_2.png" />
                  <D.AppImages alt="미리보기 예시" src="/inApp/preview_3.png" />
                </div>
              </D.PreviewExample>
            </D.PreviewBox>
          </D.HoverPreviewBox>
        </D.TitleWithIcon>
        <p>입주사에게 제공되는 기본 건물 정보입니다. 수정 사항 반영을 위해 정보 입력 후 저장 버튼을 눌러주세요.</p>
      </div>
      <D.OfficeItem onClick={() => (buildInfo ? onInfo() : onLoadBuild())} style={{ borderTop: "1px solid #6b7280" }}>
        <p>기본 정보</p>
        <D.OpenItem saved={true}>
          {/* 등록 완료 */}
          <FiChevronRight fontSize="16px" />
        </D.OpenItem>
      </D.OfficeItem>
      <D.OfficeItem onClick={() => (buildInfo ? onContact() : onLoadBuild())}>
        <p>관리센터 비상연락망</p>
        <D.OpenItem saved={true}>
          {/* 등록 완료 */}
          <FiChevronRight fontSize="16px" />
        </D.OpenItem>
      </D.OfficeItem>

      {isInfo && <OfficeInfoModal isOpen={isInfo} onClose={offInfo} />}
      {isContact && <ManagementModal isOpen={isContact} onClose={offContact} />}
    </div>
  );
}
