import moment from "moment";
import styled from "@emotion/styled";
import { useState } from "react";
import { Button, Text, Flex, useDisclosure, DrawerFooter, DrawerBody } from "@chakra-ui/react";
import { colors } from "../../../../../components/styled/common";
import { CommonTableWrap, DetailsInfo } from "../../../../../components/styled/webApp";
import CommonDrawerModal from "../../../../../components/Drawer";
import ResponsiveConfirm from "../../../../../components/modal/responsive/confirm";
import RespCancelModal from "../../../../../components/modal/responsive/cancel";
import { FiChevronUp } from "@react-icons/all-files/fi/FiChevronUp";
import { format_user_status } from "../../../../../lib/utils/dataFormat";
import { useMutateStatus } from "../../../commons/queries";
import { useFetchReservedDetail } from "../../../../meeting-rooms/commons/queries";
import { IReservedItemUser } from "../../../../../lib/types/Imodels";
import { AxiosError } from "axios";
import { FixedTitle } from "../../../../../components/styled";
import GrayRadiusTextArea from "../../../../../components/textarea";
import { toast } from "../../../../../lib/utils/toast";

const StatusColor = styled.div<{ el: string }>`
  color: ${({ el }) =>
    el === "pending"
      ? colors.orange1
      : el === "approved"
        ? colors.green1
        : el === "rejected"
          ? colors.gray3
          : colors.gray5};
`;

const MoreRequest = styled(DetailsInfo)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  h6 {
    padding-bottom: 0.5rem;
  }
`;

export const BookingStatus = styled.div<{ el: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 700;
  color: ${({ el }) =>
    el === "pending"
      ? colors.orange1
      : el === "approved"
        ? colors.green1
        : el === "rejected" //여기에 이용 취소도 있어야함
          ? colors.red1
          : colors.gray5};
`;

interface IToggle {
  isOpen: boolean;
  reason?: boolean;
  h?: number;
}

export const ToggleStatus = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: ${(props: IToggle) => (props.reason ? "space-evenly" : "flex-end")};
  height: ${(props: IToggle) =>
    !props.isOpen ? 0 : props.h && props.reason ? `${props.h / 16 + 2}rem` : props.h ? `${props.h / 16}rem` : "2rem"};
  overflow: hidden;
  transition: height 1s ease;
`;

export const Completed = styled.div`
  color: ${colors.gray3};
  padding-bottom: 0.875rem;
  font-size: 0.875rem;
  word-break: break-word;
`;

export const UpArrowIcon = styled(FiChevronUp)<{ isopen: string }>`
  margin-left: 4px;
  width: 20px;
  height: 20px;
  transform: ${({ isopen }) => isopen === "true" && "rotateZ(-180deg)"};
  transition: transform 0.3s ease;
`;

const ReservationTable = styled(CommonTableWrap)`
  > div {
    width: 20%;
  }
`;

export default function UserListItem({ el }: { el: IReservedItemUser }) {
  const [onToggle, setOnToggle] = useState(false);
  const { onChangedStatus } = useMutateStatus();
  const [reason, setReason] = useState<string>();
  const { isOpen, onOpen, onClose } = useDisclosure(); //예약 상세 정보 모달
  const { data } = useFetchReservedDetail(String(el._id), isOpen);
  const { isOpen: isCheck, onOpen: onCheck, onClose: offCheck } = useDisclosure(); //예약 확정 혹은 이용 완료
  const { isOpen: isReject, onOpen: onReject, onClose: offReject } = useDisclosure(); //예약 취소 확인

  const onCloseDrawer = () => {
    if (onToggle) setOnToggle(false);
    onClose();
  };

  const onChangeStatus = async (status: string) => {
    try {
      await onChangedStatus.mutateAsync({
        _id: String(data?._id),
        check_status: status,
      });

      toast.success({
        title: "예약 상태 변경 완료",
        description: `예약 상태 변경이 완료되었습니다.`,
      });
      offCheck();
      onCloseDrawer();
    } catch (err) {
      if (err instanceof AxiosError && err.response?.status === 401) return toast.unAuthUser();
    }
  };

  const onRejectItem = async () => {
    if (!reason)
      return toast.warning({
        title: "알림",
        description: "예약 취소에 대한 사유를 입력해주세요.",
      });

    try {
      await onChangedStatus.mutateAsync({
        _id: String(data?._id),
        check_status: "rejected",
        rejected_reason: reason,
      });

      toast.success({
        title: "예약 상태 변경 완료",
        description: `예약 상태 변경이 완료되었습니다.`,
      });
      offReject();
      setReason(undefined);
      onCloseDrawer();
    } catch (err) {
      if (err instanceof AxiosError && err.response?.status === 401) return toast.unAuthUser();
    }
  };

  return (
    <>
      <ReservationTable onClick={onOpen}>
        <StatusColor el={el.check_status}>{format_user_status(el.check_status)}</StatusColor>
        <FixedTitle>
          {el.user_name} / {el.tenant_name}
        </FixedTitle>
        <FixedTitle>{el.reservation_product_name}</FixedTitle>
        <div>{moment.utc(el.start_date).format("YYYY-MM-DD HH:mm")}</div>
        <div>{moment.utc(el.applied_date).format("YYYY-MM-DD HH:mm")}</div>
      </ReservationTable>
      {data && (
        <CommonDrawerModal title="예약 정보" isOpen={isOpen} onClose={onCloseDrawer}>
          <DrawerBody>
            <DetailsInfo>
              <h6>신청일시</h6>
              <div>{moment.utc(data.createdAt).format("YYYY-MM-DD HH:mm")}</div>
            </DetailsInfo>
            <DetailsInfo>
              <h6>상품명</h6>
              <div className="items">{data.reservation_product_id.name}</div>
            </DetailsInfo>
            <DetailsInfo>
              <h6>이용기간</h6>
              <div>{moment.utc(data.start_date).format("YYYY-MM-DD HH:mm")}</div>
            </DetailsInfo>
            <DetailsInfo>
              <h6>예약자</h6>
              <div>{data.user_id.name}</div>
            </DetailsInfo>
            <DetailsInfo>
              <h6>연락처</h6>
              <div>{data.user_id.phone_number}</div>
            </DetailsInfo>
            <DetailsInfo>
              <h6>소속</h6>
              <div style={{ width: "calc(100% - 2.5rem)", textAlign: "right" }}>
                {data.tenant_id.name}({data.tenant_id.room_ids ? `${data.tenant_id.room_ids[0].dong}동 ` : ""}
                {data.tenant_id.room_ids ? `${data.tenant_id.room_ids[0].ho}호` : ""})
              </div>
            </DetailsInfo>
            <DetailsInfo>
              <h6>예약상태</h6>
              <div>
                {data.check_status === "rejected" || data.check_status === "completed" ? (
                  <>
                    <BookingStatus
                      el={data.check_status || ""}
                      style={{ cursor: "pointer" }}
                      onClick={() => setOnToggle((prev) => !prev)}
                    >
                      {data.check_status === "rejected"
                        ? `관리자취소 (${moment.utc(data.rejected_date).format("YY-MM-DD HH:mm")})`
                        : "이용완료"}
                      <UpArrowIcon isopen={String(onToggle)} />
                    </BookingStatus>
                    {(data.check_status === "rejected" || data.approved_date) && (
                      <ToggleStatus
                        isOpen={onToggle}
                        h={document.querySelector("#status")?.clientHeight}
                        reason={data.approved_date ? true : false}
                      >
                        <BookingStatus id="status" el={data.check_status || ""}>
                          {data.rejected_reason || ""}
                        </BookingStatus>
                        {data.approved_date && (
                          <div>예약 확정 ({moment.utc(data.approved_date).format("YYYY-MM-DD HH:mm")})</div>
                        )}
                      </ToggleStatus>
                    )}
                  </>
                ) : (
                  <BookingStatus el={data.check_status || ""}>
                    {format_user_status(data.check_status)}
                    {data.approved_date && <>({moment.utc(data.approved_date).format("YYYY-MM-DD HH:mm")})</>}
                  </BookingStatus>
                )}
              </div>
            </DetailsInfo>
            {data.reservation_product_id.is_approval_required && (
              <MoreRequest>
                <h6>추가 요청사항</h6>
                <div>{data.additional_request || "없음"}</div>
              </MoreRequest>
            )}
          </DrawerBody>
          {((data.check_status === "pending" && moment() < moment(data.end_date)) ||
            data.check_status === "approved") && (
            <DrawerFooter>
              <Flex w="100%">
                <Button variant="m_light_danger" w="100%" mr="0.5rem" onClick={onReject}>
                  예약취소
                </Button>
                {data.check_status === "pending" && (
                  <Button variant="m_blue" w="100%" onClick={onCheck}>
                    예약확정
                  </Button>
                )}
              </Flex>
            </DrawerFooter>
          )}
        </CommonDrawerModal>
      )}
      {isCheck && (
        <ResponsiveConfirm
          title="예약확정"
          isOpen={isCheck}
          before="취소"
          next="예약확정"
          onClose={offCheck}
          onAction={() => onChangeStatus("approved")}
        >
          <Text fontSize="1rem">해당 예약건을 ‘예약확정’ 하시나요?</Text>
          <Text fontSize="1rem">예약확정 후에는 결제가 이루어집니다.</Text>
        </ResponsiveConfirm>
      )}
      {isReject && (
        <RespCancelModal
          title="예약취소"
          isOpen={isReject}
          next="예약취소"
          onClose={offReject}
          onAction={onRejectItem}
          styled={{ maxWidth: "30rem" }}
        >
          <div style={{ fontSize: "1rem" }}>
            <Text>해당 예약건을 ‘예약취소(반려)’ 하시나요?</Text>
            <Text>예약취소 사유를 입력해주세요. 예약취소 후에는 재승인 할 수 없습니다.</Text>
            <div style={{ padding: "1.25rem 0 1rem" }}>
              <GrayRadiusTextArea
                rows={1}
                isLimited={true}
                value={reason || ""}
                placeholder="예약을 취소하는 사유를 입력해주세요."
                _onChange={(e) => {
                  if (e.target.value.length > 1000) return;
                  setReason(e.target.value);
                }}
              />
            </div>
          </div>
        </RespCancelModal>
      )}
    </>
  );
}
