const Category = {
  menu: "Menu",
  sidebar: "Sidebar",
  notification: "Notification",
  dashBoard: "DashBoard",
  notice: "Notice",
  company: "Company",
  complaint: "Complaint",
  public: "PublicFacility",
  meeting: "MeetingRoom",
  manage: "Administrative Expenses",
  survey: "survey",
  parking: "parking",
  setting: "settings",
  additional: "Additional",
};

const Action = {
  auth: {
    login: "click Login Button", // login API
    logOut: "click Logout", //logout API
    profile: "click Own Profile", // my profile page
  },
  dashBoard: {
    enter: "click dashBoard", // dashboard-page
    approval: "authorize Account", //move for approval account
    autoApproval: "query Automatic Approval", //move for approval account
    registerCard: "authorize Registration Card", //move for approval account
    public: "approve public facility", //move for approval public room
    meeting: "approve meeting room", //move for meeting room
    complaint: "check Complaint", //move for confirm complaints
    temperature: "check Temperature feedback",
  },
  notice: {
    enter: "click Notice", //notice page
    new: "register Notice", //register notice page
    detail: "click Notice Detail", //notice detail page
  },
  company: {
    enter: "click Company", //company page
    card: "click Tanant Card List", //tenant Card page
    setting: "click Vacancy Management",
  },
  complaints: {
    enter: "click Complaint", // complaint page
    detail: "click Complaint Detail",
  },
  temperature: { enter: "click Control Temperature" },
  public: {
    enter: "click Public Facility", // public facility page
    reservation: "click Reservation", // public facility page
    setting: "manage Public Facility",
  },
  manage: {
    enter: "click Administrative Expenses", // Administrative Expenses page
    list: "click Administrative Expenses List", // Administrative Expenses page
    bank: "click Bank List",
    rental: "click Rent Expenses",
  },
  survey: {
    enter: "click Survey", // survey page
    new: "register Survey",
    detail: "click Survey Detail",
  },
  parking: {
    enter: "click Regular Parking", // parking page
    new: "register Parking",
    detail: "click Parking Detail",
    info: "click Parking Info",
  },
  document: {
    enter: "click Apply for Forms",
    application: "click Application Form",
    borrow: "click Borrow Things",
  },
  setting: {
    enter: "click Settings", // setting page
    building: "set Building",
    faq: "set Frequently Asked Questions",
    vacancy: "set Vacancy Management",
    public: "manage Public Facility",
    user: "manage User",
    new: "create User",
    permission: "permission User",
  },
  additional: { meal: "click Meal Planner" },
};

const GAEvents = { Category, Action };

export default GAEvents;
