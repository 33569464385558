import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useParams, useNavigate } from "react-router-dom";
import { useRoomDeleteMutation } from "../../../../requests/room";

const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ModalTitle = styled.p`
  color: #111827;
  font-family: Noto Sans KR;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
`;

const DeleteButton = styled.button`
  display: flex;
  width: 128px;
  height: 48px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 1;
  border-radius: 8px;
  background-color: #f36151;
  color: #ffffff;
`;

const CancelButton = styled.button`
  display: flex;
  width: 128px;
  height: 48px;
  padding: 20px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  color: #6b7280;
  background: #f4f4f5;
  color: #6b7280;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const WarningSpan = styled.span`
  color: #f36151;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const HilightSpan = styled.span`
  color: #f36151;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const ContentsText = styled.p`
  color: #111827;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: pre-wrap;
`;

export default function DeleteRoomModal(props: { onClose: () => void; isOpen: boolean }) {
  const { room_id } = useParams();
  const mutation = useRoomDeleteMutation();
  const navigate = useNavigate();

  const onCloseDrawer = () => {
    props.onClose && props.onClose();
    return;
  };

  // 호실 소유주 추가 api 호출
  const deleteRoom = () => {
    if (mutation.isLoading) return;
    if (room_id) {
      mutation.mutateAsync(room_id).then(() => {
        navigate("/rooms");
      });
    }
  };

  return (
    <Modal isOpen={props.isOpen} onClose={onCloseDrawer} variant="default" isCentered>
      <ModalOverlay />
      <ModalContent maxW="312px" maxH="279px">
        <ModalBody>
          <ModalHead>
            <ModalTitle>호실 삭제</ModalTitle>
          </ModalHead>
          <div style={{ marginBottom: "24px" }}>
            <div style={{ marginBottom: "21px" }}>
              <ContentsText>삭제된 데이터는 복구할 수 없습니다.</ContentsText>
              <ContentsText>정말로 삭제하시겠습니까?</ContentsText>
            </div>
            <div>
              <HilightSpan>주의</HilightSpan>
              <WarningSpan>소유자 또한 일괄 퇴실 처리되어 이용이 제한됩니다.</WarningSpan>
            </div>
          </div>
          <BottomContainer>
            <CancelButton onClick={props.onClose}>취소</CancelButton>
            <DeleteButton onClick={deleteRoom}>호실 삭제</DeleteButton>
          </BottomContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
