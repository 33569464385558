import styled from "@emotion/styled";
import { CSSProperties, FC, PropsWithChildren } from "react";
import { DoubleColumn } from "../../../pages/company/units/styled";

interface ITable extends PropsWithChildren {
  title: string;
  w?: string;
  s?: CSSProperties;
}

const ColumnTitle = styled.div<{ w?: string }>`
  width: ${({ w }) => (w ? w : "30%")};
  font-weight: 500;
  color: #111827;
`;

export const RowBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

//칼럼 안에 두 줄로 나올 경우
export const WithBox = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: column;
`;

export const WrapFlex = styled(WithBox)`
  width: calc(100% - 30%);
  flex-wrap: wrap;
`;

const SingleRow: FC<ITable> = ({ children, ...props }) => {
  return (
    <DoubleColumn>
      <ColumnTitle w={props.w} style={props.s}>
        {props.title}
      </ColumnTitle>
      {children}
    </DoubleColumn>
  );
};

export default SingleRow;
