export default function formatRoomType(status: "office" | "store" | "residential" | "etc") {
  const statusMap: Record<string, string> = {
    office: "사무용",
    store: "상가용",
    residential: "주거용",
    etc: "기타",
  };

  return statusMap[status] || status;
}
