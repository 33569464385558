import styled from "@emotion/styled";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { ChangeEvent, CSSProperties, FC, PropsWithChildren } from "react";
import { colors } from "../styled/common";

interface IRadiusSelect extends PropsWithChildren {
  id?: string;
  value?: string;
  disabled?: boolean;
  defaultValue?: string;
  w?: string;
  _style?: CSSProperties;
  _onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export const ShadowBox = styled.div<{ w?: string }>`
  width: ${({ w }) => (w ? w : "7.5rem")};
  color: ${colors.gray4};
  margin-right: 0.5rem;
  border-radius: 0.5rem;
  text-align: left;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    right: 12%;
    transform: translateY(-50%);
    font-size: 1rem;
  }
`;

const Select = styled.select<{ w?: string }>`
  -webkit-appearance: none; /* turn off drop-down arrow*/
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border-radius: 0.5rem;
  padding: 0.75rem;
  cursor: pointer;
  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

//모바일친화 select
const GrayShadowSelect: FC<IRadiusSelect> = ({ ...props }) => {
  // console.log(props);
  const { _style, _onChange, children, w, ...rest } = props;

  return (
    <ShadowBox w={w} style={_style}>
      <Select onChange={_onChange} {...rest}>
        {children}
      </Select>
      <FiChevronDown />
    </ShadowBox>
  );
};

export default GrayShadowSelect;
