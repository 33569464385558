import { history_type } from "../../../lib/types/Imodels";

//민원 처리 상태 변환
export const format_process = (status: string) => {
  switch (status) {
    case "waiting":
      return "처리대기";
    case "proceeding":
      return "처리중";
    case "completed":
      return "처리완료";
  }
};

export const format_history_type = (status: history_type) => {
  switch (status) {
    case "first_view":
      return "최초 열람";
    case "assign_department":
      return "담당팀 배정";
    case "answer_create":
      return "답글 작성";
    case "answer_update":
      return "답글 수정";
    case "answer_delete":
      return "답글 삭제";
    case "change_type_0_1":
      return "상태 변경 (처리대기 → 처리중)";
    case "change_type_0_2":
      return "상태 변경 (처리대기 → 처리완료)";
    case "change_type_2_1":
      return "상태 변경 (처리완료 → 처리중)";
    case "change_type_2_0":
      return "상태 변경 (처리완료 → 처리대기)"; //처리 대기로 돌아가는 건 없을 것
    case "change_type_1_0":
      return "상태 변경 (처리중 → 처리대기)"; //처리 대기로 돌아가는 건 없을 것
    case "change_type_1_2":
      return "상태 변경 (처리중 → 처리완료)";
  }
};
