import { Box, Button, Flex, Select, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { GlobalContext } from "../../../../App";
import ContentInputs from "../../../../components/Input/middleContents";
import * as S from "../../../../components/styled";
import { IRegistListType } from "../../commons/type";
import CardListItems from "./CardTableItems";
import { useRoomType } from "../../../../lib/utils";

export default function RegistrationListUI(props: IRegistListType) {
  const { buildInfo } = useContext(GlobalContext);
  const room_type = useRoomType();

  return (
    <div>
      <p>
        입주 예정자가 작성한 입주카드를 확인하실 수 있습니다. 입주 예정자가 맞다면, 확인 후에 입주사 등록을
        완료해주세요.
      </p>
      <p>입주사 등록 시 오피스너 앱 초대 SMS가 전송되며 [입주사 관리] 메뉴에서 상세 정보를 확인하실 수 있습니다.</p>
      <Flex>
        {room_type.length > 1 && (
          <S.SelectBox>
            <Text variant="default">호실용도</Text>
            <Select variant="default" onChange={(e) => props.setTypes(e.target.value)} value={props.types}>
              <option value="">전체</option>
              {room_type.map((el) => (
                <option value={el.label} key={el.id}>
                  {el.value}
                </option>
              ))}
            </Select>
          </S.SelectBox>
        )}
        <S.SelectBox>
          <Text variant="default">동</Text>
          <Select variant="default" onChange={(e) => props.setDong(e.target.value)} value={props.dong}>
            <option value="">전체</option>
            {buildInfo?.dong_floor.map((el) => (
              <option key={el._id} value={el.dong}>
                {el.dong}동
              </option>
            ))}
          </Select>
        </S.SelectBox>
        <S.SelectBox>
          <Text variant="default">입주사 등록 상태</Text>
          <Select variant="default" onChange={(e) => props.setStatus(e.target.value)} value={props.status}>
            <option value="">전체</option>
            <option value="pending">대기</option>
            <option value="completed">완료</option>
          </Select>
        </S.SelectBox>
      </Flex>
      <S.SearchBox>
        <S.SelectBox>
          <Text variant="default">검색</Text>
          <Select
            onChange={(e) =>
              props.setInputs({
                ...props.inputs,
                id: e.target.value,
                value: "",
              })
            }
            variant="default"
            value={props.inputs.id}
          >
            <option value="search_name">입주사명</option>
            <option value="search_ho">호실번호</option>
          </Select>
        </S.SelectBox>
        <ContentInputs
          onChange={(e) =>
            props.setInputs({
              ...props.inputs,
              id: props.inputs.id,
              value: e.target.value,
            })
          }
          value={props.inputs.value}
          type={props.inputs.id === "search_name" ? "text" : "number"}
          placeholder={
            props.inputs.id === "search_name" ? "검색하실 입주사명을 입력해주세요" : "검색하실 호실번호를 입력해주세요"
          }
          onKeyDown={(e) => e.key === "Enter" && props.onShowResult()}
        />
        <Button variant="basic" onClick={props.onShowResult}>
          검색
        </Button>
        <S.SearchReset variant="basic" onClick={props.onClickRefresh}>
          초기화
        </S.SearchReset>
      </S.SearchBox>
      <Box pt="16px">
        <S.TableTopBox>
          <span>
            총 <S.TableCount>{props.total || 0}개</S.TableCount>의 입주카드가 조회되었습니다.
          </span>
        </S.TableTopBox>
        <S.ListTableBox>
          <S.ListTableHeader w={room_type.length > 1 ? "25%" : "20%"}>
            <div>입주사명</div>
            {room_type.length > 1 && <div>호실용도</div>}
            <div>동/호</div>
            <div>제출일시</div>
            <div>입주사 등록상태</div>
          </S.ListTableHeader>
          {props.data &&
            props.data.map((el) => (
              <CardListItems el={el} key={String(el._id)} setIsModalOpen={props.setIsModalOpen} />
            ))}
        </S.ListTableBox>
      </Box>
    </div>
  );
}
