import { Outlet } from "react-router-dom";
import CustomHeading from "../../components/CustomHeading";
import useUserAuth from "../../components/hooks/useUserAuth";
import GetAuthorized from "../../components/Authrized";

export default function MeetingRoomLayout() {
  const auth = useUserAuth("room_public");

  if (auth === "no_auth") return <GetAuthorized />;

  return (
    <div className="w-full">
      <div className="border-b border-gray-200">
        <CustomHeading
          headings={[
            { text: "예약내역", path: "/meeting-rooms" },
            { text: "예약현황", path: "/meeting-rooms/timeline" },
            { text: "회의실 설정", path: "/meeting-rooms/setting" },
          ]}
          sizeTheme="md"
        />
      </div>
      <Outlet />
    </div>
  );
}
