import { twMerge } from "tailwind-merge";
import { Link, useLocation } from "react-router-dom";

type Props = {
  headings:
    | {
        text: string;
        path: string;
      }[]
    | string;
  sizeTheme: "md" | "lg";
} & React.HTMLAttributes<HTMLDivElement>;

export default function CustomHeading({ headings, sizeTheme, className }: Props) {
  const { pathname } = useLocation();

  const sizeClasses = {
    md: "text-xl leading-6",
    lg: "text-2xl leading-9",
  }[sizeTheme];

  const headingClass = twMerge("flex items-center gap-6 p-6 font-bold text-gray-900", sizeClasses, className);

  return (
    <div className={headingClass}>
      {Array.isArray(headings) ? (
        headings.map((item) => (
          <Link
            key={item.path}
            to={item.path}
            className={twMerge("text-gray-400", pathname === item.path && "text-gray-900")}
          >
            {item.text}
          </Link>
        ))
      ) : (
        <p>{headings}</p>
      )}
    </div>
  );
}
