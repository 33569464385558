import styled from "@emotion/styled";
import { colors } from "../../../components/styled/common";
import { InputWithTextBox } from "../../../components/styled/webApp";

//포인트에서만 사용되었지만, 공통으로 옮길 수 있으면 옮길것
export const MonthlyPointBox = styled.div`
  width: 100%;
  padding: 1.75rem;
  color: ${colors.gray4};
  h6 {
    font-weight: 500;
    color: ${colors.gray5};
    padding-bottom: 4px;
  }
`;

export const UpdateInfoUL = styled.ul`
  margin: 4px 0 1rem;
  li {
    font-size: 0.875rem;
    margin-left: 1rem;
  }
`;

export const PointInputBox = styled(InputWithTextBox)`
  width: 11.5rem;
  input {
    width: 100%;
    text-align: right;
    margin-right: 4px;
    ::placeholder {
      color: ${colors.gray2};
    }
  }
`;

export const ColumnActive = styled.div<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? colors.mainBlue : colors.red1)};
`;
