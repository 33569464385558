import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { public_detail_key, schedule_detail_key } from "../../../lib/api/queries/keys";
import { public_delete, public_detail, schedule_detail_list } from "../../../lib/api/queries/urls";
import { success, unAuthUser } from "../../../lib/theme/toast";
import { IRoomPublic, ISchedule } from "../../../lib/types/Imodels";

//공용시설 상세 조회
export const usePublicDetail = (id: string, isOpen: boolean) => {
  return useQuery(
    [public_detail_key, id],
    () =>
      fetchClient
        .post<BaseResponse<IRoomPublic>>(public_detail, {
          room_public_id: id,
        })
        .then((res) => res.data.data)
        .catch(() => undefined),
    { enabled: isOpen },
  );
};

export const fetchDetailReserved = (id: string) =>
  fetchClient
    .post<BaseResponse<ISchedule>>(schedule_detail_list, {
      schedule_id: id,
    })
    .then((res) => res.data.data);

export const useFetchReservedDetail = (id: string, isOpen: boolean) =>
  useQuery([schedule_detail_key], () => fetchDetailReserved(id), {
    enabled: isOpen,
  });

export const useDeleteMeetingRoom = () => {
  const toast = useToast();

  const mutation = useMutation(
    (ids: string[]) =>
      fetchClient.post<BaseResponse<string>>(public_delete, {
        room_public_ids: ids,
      }),
    {
      retry: 0,
      onError: (err: AxiosError) => {
        if (err.response?.status === 401) toast({ ...unAuthUser });
      },
      onSuccess: (res) => {
        if (res.data.data) {
          if (res?.data.data.length > 0) {
            toast({
              ...unAuthUser,
              title: "회의실 삭제 불가",
              description: "기존 예약이 남아있는 회의실이 있습니다. 예약 취소 처리 후 다시 시도해주세요.",
            });
          }
        } else {
          toast({
            ...success,
            title: "회의실 삭제 완료",
            description: `해당 회의실이 삭제되었습니다.`,
          });
        }
      },
    },
  );
  return { mutation };
};
