import styled from "@emotion/styled";
import { ChangeEvent, CSSProperties, FC, KeyboardEventHandler } from "react";
import { CountNum } from "../../../pages/facility/commons/styled";
import { colors } from "../../styled/common";

interface IRadiusInput {
  id?: string;
  value?: string;
  readOnly?: boolean;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string;
  _style?: CSSProperties;
  _onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isLimited?: boolean;
  num?: number;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement> | undefined;
}

export const StaticInput = styled.input`
  appearance: none;
  width: 100%;
  border: 1px solid ${colors.gray3};
  padding: 0.75rem;
  border-radius: 0.5rem;
  color: ${colors.gray4};
  ::placeholder {
    color: ${colors.gray2};
  }
  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background-color: ${colors.gray1};
    border: 1px solid ${colors.gray2};
  }
`;

const GrayRadiusInput: FC<IRadiusInput> = ({ ...props }) => {
  const { _style, _onChange, isLimited, num, ...rest } = props;

  return (
    <>
      <StaticInput
        style={_style}
        autoComplete="off"
        onChange={_onChange}
        {...rest}
      />
      {isLimited && (
        <CountNum style={{ paddingTop: "4px" }}>
          <span>{props.value ? props.value.length : 0}자</span> /{" "}
          {num ? num : 30}자
        </CountNum>
      )}
    </>
  );
};

export default GrayRadiusInput;
