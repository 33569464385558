import { HTMLAttributes } from "react";

export type Props = object & HTMLAttributes<HTMLOrSVGElement>;

export default function IconCalendar(props: Props) {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.16732 1.6665C5.16732 1.39036 4.94346 1.1665 4.66732 1.1665C4.39118 1.1665 4.16732 1.39036 4.16732 1.6665V2.71934C3.20776 2.79618 2.57783 2.98475 2.11503 3.44755C1.65223 3.91035 1.46366 4.54028 1.38682 5.49984H14.6145C14.5376 4.54028 14.3491 3.91035 13.8863 3.44755C13.4235 2.98475 12.7935 2.79618 11.834 2.71934V1.6665C11.834 1.39036 11.6101 1.1665 11.334 1.1665C11.0578 1.1665 10.834 1.39036 10.834 1.6665V2.67511C10.3905 2.6665 9.89334 2.6665 9.33398 2.6665H6.66732C6.10796 2.6665 5.61083 2.6665 5.16732 2.67511V1.6665Z"
        fill="#6B7280"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33398 7.99984C1.33398 7.44048 1.33398 6.94335 1.34259 6.49984H14.6587C14.6673 6.94335 14.6673 7.44048 14.6673 7.99984V9.33317C14.6673 11.8473 14.6673 13.1044 13.8863 13.8855C13.1052 14.6665 11.8481 14.6665 9.33398 14.6665H6.66732C4.15316 14.6665 2.89608 14.6665 2.11503 13.8855C1.33398 13.1044 1.33398 11.8473 1.33398 9.33317V7.99984ZM11.334 9.33317C11.7022 9.33317 12.0007 9.03469 12.0007 8.6665C12.0007 8.29831 11.7022 7.99984 11.334 7.99984C10.9658 7.99984 10.6673 8.29831 10.6673 8.6665C10.6673 9.03469 10.9658 9.33317 11.334 9.33317ZM11.334 11.9998C11.7022 11.9998 12.0007 11.7014 12.0007 11.3332C12.0007 10.965 11.7022 10.6665 11.334 10.6665C10.9658 10.6665 10.6673 10.965 10.6673 11.3332C10.6673 11.7014 10.9658 11.9998 11.334 11.9998ZM8.66732 8.6665C8.66732 9.03469 8.36884 9.33317 8.00065 9.33317C7.63246 9.33317 7.33398 9.03469 7.33398 8.6665C7.33398 8.29831 7.63246 7.99984 8.00065 7.99984C8.36884 7.99984 8.66732 8.29831 8.66732 8.6665ZM8.66732 11.3332C8.66732 11.7014 8.36884 11.9998 8.00065 11.9998C7.63246 11.9998 7.33398 11.7014 7.33398 11.3332C7.33398 10.965 7.63246 10.6665 8.00065 10.6665C8.36884 10.6665 8.66732 10.965 8.66732 11.3332ZM4.66732 9.33317C5.03551 9.33317 5.33398 9.03469 5.33398 8.6665C5.33398 8.29831 5.03551 7.99984 4.66732 7.99984C4.29913 7.99984 4.00065 8.29831 4.00065 8.6665C4.00065 9.03469 4.29913 9.33317 4.66732 9.33317ZM4.66732 11.9998C5.03551 11.9998 5.33398 11.7014 5.33398 11.3332C5.33398 10.965 5.03551 10.6665 4.66732 10.6665C4.29913 10.6665 4.00065 10.965 4.00065 11.3332C4.00065 11.7014 4.29913 11.9998 4.66732 11.9998Z"
        fill="#6B7280"
      />
    </svg>
  );
}
