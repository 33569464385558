import { useNavigate, useParams } from "react-router-dom";
import CustomChip from "../../../../components/CustomChip";
import Icon from "../../../../types/iconsax";
import { useCompanyDetailQuery } from "../../../../requests/company";
import { useOverlay } from "@toss/use-overlay";
import { ChangeEvent, useContext, useRef } from "react";
import CompanyModifyTermModal from "../../../../views/company/modal/CompanyModifyTermModal";
import CompanyModifyRoomsModal from "../../../../views/company/modal/CompanyModifyRoomsModal";
import CompanyModifyTypeModal from "../../../../views/company/modal/CompanyModifyTypeModal";
import dayjs from "dayjs";
import { GlobalContext } from "../../../../App";
import axios from "axios";
import { twMerge } from "tailwind-merge";
import formatMoveInType from "../../../../utils/formatMoveInType";
import useUserAuth from "../../../../components/hooks/useUserAuth";
import toast from "react-hot-toast";
import CustomToast from "../../../../components/CustomToast";
import { useUpdateCompanyMutation } from "../../../../requests/company/useUpdateCompanyMutation";
import { uploadAllFiles } from "../../commons";
import Button from "../../../../components/button/Button";
import IconPlus from "../../../../components/icons/IconPlus";

export default function CompanyDetailInfoPage() {
  const { companyId } = useParams() as { companyId: string };
  const { buildInfo } = useContext(GlobalContext);
  const { data } = useCompanyDetailQuery(companyId);
  const { mutateAsync: updateCompany } = useUpdateCompanyMutation();
  const overlay = useOverlay();

  const navigate = useNavigate();

  const auth = useUserAuth("tenant");
  const isEdit = auth === "edit";

  const contractInputRef = useRef<HTMLInputElement>(null);
  const businessInputRef = useRef<HTMLInputElement>(null);

  const handleModifyRoomsClick = async () => {
    overlay.open(({ isOpen, close }) => (
      <CompanyModifyRoomsModal
        isOpen={isOpen}
        onClose={close}
        buildingId={buildInfo?._id?.toString() ?? ""}
        companyId={companyId}
        name={data?.data.name ?? ""}
        rooms={data?.data.roomInfo ?? []}
        type={data?.data.tenantType}
      />
    ));
  };

  const handleModifyTermButtonClick = async () => {
    overlay.open(({ isOpen, close }) => (
      <CompanyModifyTermModal
        isOpen={isOpen}
        onClose={close}
        companyId={companyId}
        name={data?.data.name ?? ""}
        rentStartDate={data?.data.rentStartDate}
        rentEndDate={data?.data.rentEndDate}
      />
    ));
  };

  const handleModifyTypeButtonClick = async () => {
    overlay.open(({ isOpen, close }) => (
      <CompanyModifyTypeModal isOpen={isOpen} onClose={close} companyId={companyId} name={data?.data.name ?? ""} />
    ));
  };

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, targetFields: "contract" | "business") => {
    const files = e.target.files;

    if (files === null) return;

    const fileData = {
      contract: data?.data.salesOrLeaseContractFiles,
      business: data?.data.businessRegistrationFiles,
    }[targetFields];

    // 파일 개수 제한
    if ((fileData?.length ?? 0) + files.length > 5) {
      return toast(
        <CustomToast iconType="warning" title="파일 업로드" description="파일은 5개까지만 업로드하실 수 있습니다." />,
      );
    }

    // 파일 용량 제한
    if (Array.from(files || []).filter((el) => el.size > 5 * 1024 * 1024).length !== 0) {
      return toast(
        <CustomToast iconType="warning" title="파일 업로드" description="파일 1개당 5MB 이하만 첨부 가능합니다." />,
      );
    }

    const uploadedFiles = await uploadAllFiles(Array.from(files).map((el) => ({ directory: el, name: el.name })));

    const targetFieldsMap = {
      contract: {
        salesOrLeaseContractFiles: [...(fileData ?? []), ...(uploadedFiles ?? [])],
      },
      business: { businessRegistrationFiles: [...(fileData ?? []), ...(uploadedFiles ?? [])] },
    }[targetFields];

    updateCompany({ companyId, ...targetFieldsMap }).then(() => {
      e.target.value = "";
    });
  };

  return (
    <div className="mb-[60px] flex flex-1 flex-col divide-y divide-gray-100 border-b border-gray-100 px-6">
      <div className="flex py-3">
        <p className="w-[160px] font-medium leading-6 text-gray-500">동/호</p>
        <div className="flex flex-1 flex-wrap gap-2">
          {data?.data.roomInfo?.map((item) => (
            <CustomChip
              key={`${item._id}_chip`}
              lastIconElement={<Icon.ExportSquare size={12} />}
              label={`${item.dong}동 ${item.ho}호`}
              size="sm"
              onClick={() => {
                navigate(`/rooms/modify/${item._id}`);
              }}
            />
          ))}
        </div>
        {isEdit && (
          <p
            className="cursor-pointer text-sm font-medium leading-6 text-blue-500 transition-all hover:text-blue-600"
            onClick={handleModifyRoomsClick}
          >
            수정
          </p>
        )}
      </div>
      <div className="flex py-3">
        <p className="w-[160px] font-medium leading-6 text-gray-500">입주기간</p>
        {data?.data.rentStartDate || data?.data.rentEndDate ? (
          <span className="flex flex-1 gap-1">
            <p className={twMerge("leading-6 text-gray-900", !data?.data.rentStartDate && "text-gray-300")}>
              {data?.data.rentStartDate ? dayjs(data?.data.rentStartDate).format("YYYY-MM-DD") : "미등록"}
            </p>
            ~
            <p className={twMerge("leading-6 text-gray-900", !data?.data.rentEndDate && "text-gray-300")}>
              {data?.data.rentEndDate ? dayjs(data?.data.rentEndDate).format("YYYY-MM-DD") : "미등록"}
            </p>
          </span>
        ) : (
          <p className="flex-1 text-base leading-6 text-gray-300">미등록</p>
        )}
        {isEdit && (
          <p
            className="cursor-pointer text-sm font-medium leading-6 text-blue-500 transition-all hover:text-blue-600"
            onClick={handleModifyTermButtonClick}
          >
            수정
          </p>
        )}
      </div>
      <div className="flex py-3">
        <p className="w-[160px] font-medium leading-6 text-gray-500">입주형태</p>
        <p className={twMerge("flex-1 leading-6 text-gray-900", !data?.data.moveInType && "text-gray-300")}>
          {data?.data.moveInType ? formatMoveInType(data?.data.moveInType) : "미등록"}
        </p>
        {isEdit && (
          <p
            className="cursor-pointer text-sm font-medium leading-6 text-blue-500 transition-all hover:text-blue-600"
            onClick={handleModifyTypeButtonClick}
          >
            수정
          </p>
        )}
      </div>
      <div className="flex py-3">
        <p className="w-[160px] font-medium leading-6 text-gray-500">사업자등록증</p>
        <div className="flex flex-1 flex-col divide-y divide-gray-200 rounded-md border border-gray-200">
          {data?.data.businessRegistrationFiles.map((item, index) => (
            <div
              key={item.id}
              className="flex items-center justify-between p-3 transition-all hover:bg-gray-50"
              onClick={() => {
                item.directory &&
                  window.open(item.directory, "_blank", "location='no',directories='no',toolbar='no',menubar='no'");
              }}
            >
              <span className="flex gap-2">
                <Icon.Paperclip size={20} color="#9CA3AF" />
                <p className="text-sm font-medium leading-5 text-gray-900">{item.name ?? `첨부파일${index}`}</p>
              </span>
              <span className="flex gap-4">
                <Icon.ImportCurve
                  className="cursor-pointer"
                  color="#6B7280"
                  variant="Outline"
                  size={20}
                  onClick={async (e) => {
                    e.stopPropagation();

                    const data = await axios.get(item.directory, { responseType: "blob" }).then((res) => res.data);
                    const blob = new Blob([data]);
                    const blobUrl = URL.createObjectURL(blob);

                    const link = document.createElement("a");
                    link.href = blobUrl;
                    link.download = item.name;
                    link.click();
                  }}
                />

                {isEdit && (
                  <>
                    <p className="text-sm font-medium text-gray-300">|</p>
                    <Icon.Trash
                      className="cursor-pointer"
                      color="#6B7280"
                      variant="Outline"
                      size={20}
                      onClick={(e) => {
                        e.stopPropagation();

                        updateCompany({
                          companyId,
                          businessRegistrationFiles: data?.data.businessRegistrationFiles?.filter(
                            (el) => el.id !== item.id,
                          ),
                        });
                      }}
                    />
                  </>
                )}
              </span>
            </div>
          ))}

          {isEdit && (
            <div className="flex w-full items-center justify-between px-3 py-2">
              <Button
                designSchema="ghostBlue"
                iconElement={<IconPlus className="h-4 w-4" />}
                size="sm"
                buttonText="파일 업로드"
                onClick={() => businessInputRef.current?.click()}
                type="button"
              />
              <input
                type="file"
                multiple={true}
                ref={businessInputRef}
                className="hidden"
                onChange={(e) => uploadFile(e, "business")}
              />
              <span className="flex items-center text-sm leading-5 text-gray-400">파일 당 최대 5 MB / 5개</span>
            </div>
          )}
        </div>
      </div>
      <div className="flex py-3">
        <p className="w-[160px] font-medium leading-6 text-gray-500">계약서</p>
        <div className="flex flex-1 flex-col divide-y divide-gray-200 rounded-md border border-gray-200">
          {data?.data.salesOrLeaseContractFiles.map((item, index) => (
            <div
              key={item.id}
              className="flex items-center justify-between p-3 transition-all hover:bg-gray-50"
              onClick={() => {
                item.directory &&
                  window.open(item.directory, "_blank", "location='no',directories='no',toolbar='no',menubar='no'");
              }}
            >
              <span className="flex gap-2">
                <Icon.Paperclip size={20} color="#9CA3AF" />
                <p className="text-sm font-medium leading-5 text-gray-900">{item.name ?? `첨부파일${index}`}</p>
              </span>
              <span className="flex gap-4">
                <Icon.ImportCurve
                  className="cursor-pointer"
                  color="#6B7280"
                  variant="Outline"
                  size={20}
                  onClick={async (e) => {
                    e.stopPropagation();

                    const data = await axios.get(item.directory, { responseType: "blob" }).then((res) => res.data);
                    const blob = new Blob([data]);
                    const blobUrl = URL.createObjectURL(blob);

                    const link = document.createElement("a");
                    link.href = blobUrl;
                    link.download = item.name;
                    link.click();
                  }}
                />
                {isEdit && (
                  <>
                    <p className="text-sm font-medium text-gray-300">|</p>
                    <Icon.Trash
                      className="cursor-pointer"
                      color="#6B7280"
                      variant="Outline"
                      size={20}
                      onClick={(e) => {
                        e.stopPropagation();

                        updateCompany({
                          companyId,
                          salesOrLeaseContractFiles: data?.data.salesOrLeaseContractFiles?.filter(
                            (el) => el.id !== item.id,
                          ),
                        });
                      }}
                    />
                  </>
                )}
              </span>
            </div>
          ))}
          {isEdit && (
            <div className="flex w-full items-center justify-between px-3 py-2">
              <Button
                designSchema="ghostBlue"
                iconElement={<IconPlus className="h-4 w-4" />}
                size="sm"
                buttonText="파일 업로드"
                onClick={() => contractInputRef.current?.click()}
                type="button"
              />
              <input
                type="file"
                multiple={true}
                ref={contractInputRef}
                className="hidden"
                onChange={(e) => uploadFile(e, "contract")}
              />
              <span className="flex items-center text-sm leading-5 text-gray-400">파일 당 최대 5 MB / 5개</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
