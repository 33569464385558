import { Box, Button, Flex, ModalContent, ModalFooter, Spinner, Tag, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { colors } from "./common";
import { IoClose } from "@react-icons/all-files/io5/IoClose";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { BsFillQuestionCircleFill } from "@react-icons/all-files/bs/BsFillQuestionCircleFill";
import { breakPoints } from "./media";

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.whiteColor};
  border-radius: 1rem;
  @media ${breakPoints.mobile} {
    padding: 0;
    border-radius: 0;
    font-size: 14px;
  }
`;

export const WrapperHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.gray2};
  padding-bottom: 28px;
`;

export const HeaderInfo = styled.div`
  padding: 20px 0;
`;

export const WrapperBody = styled.div`
  padding-top: 24px;
`;

//초기화 버튼 -> 많이 쓰이면 theme으로 만들기
export const SearchReset = styled(Button)`
  border: 2px solid ${colors.gray1};
  margin-left: 8px;
  color: ${colors.gray4};
  background-color: ${colors.gray1};
`;

//표 상단 멘트 + 삭제 버튼 div
export const TableTopBox = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
`;

//표상단 기능 없을때
export const TableListBox = styled(Box)`
  padding-top: 36px;
  @media ${breakPoints.mobile} {
    //민원
    padding-top: 12px;
  }
`;

export const TableText = styled(Text)`
  font-weight: 400;
  padding-bottom: 8px;
  font-size: 1rem;
`;

export const TableCount = styled.span`
  font-weight: 500;
  color: ${colors.mainBlue};
`;

//표 tag
export const ListTableBox = styled.div`
  width: 100%;
  font-size: 1rem;
  border-top: 1px solid ${colors.gray4};
  > div {
    width: 100%;
    display: flex;
    min-height: 48px;
    text-align: center;
    align-items: center;
    padding: 10px 0;
  }
`;

export const ListTableHeader = styled.div<{ w?: string }>`
  background-color: ${colors.gray1};
  border-bottom: 1px solid ${colors.gray4};
  > div {
    width: ${({ w }) => (w ? w : "15%")};
  }
`;

export const ListTableBody = styled.div<{ w?: string }>`
  border-bottom: 1px solid ${colors.gray3};
  &:hover {
    #tag {
      background-color: #eff6ff;
    }
  }
  > div {
    width: ${({ w }) => (w ? w : "15%")};
  }
`;

export const ListCheckBox = styled.div<{ w?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FixedTitle = styled.div`
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: none;
  text-align: left;
  position: relative;
`;

export const NTag = styled.span<{ isTrue: string }>`
  background-color: ${colors.whiteColor};
  ${({ isTrue }) => isTrue === "true" && `position:absolute; top:50%; right:0; transform:translate(0%, -50%)`};
`;

export const LeftIcons = styled(FiChevronLeft)`
  font-size: 2rem;
  cursor: pointer;
`;

export const SearchBox = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 20px 0;
`;

export const TableContent = styled(ModalContent)`
  width: 100%;
  max-width: fit-content;
  margin-top: 5.75rem;
`;

export const SelectBox = styled(Box)`
  width: 180px;
  margin-right: 8px;
`;

export const GuideLink = styled.a`
  text-decoration: underline;
  color: #23c0f1;
  padding: 0px 4px;
`;

export const TagArr = styled(Tag)`
  margin-top: 8px;
  margin-right: 8px;
  border-radius: 4px;
  padding: 6px 12px;
  color: ${colors.gray4};
  background-color: ${colors.gray1};
`;

export const TagCloseIcon = styled(IoClose)`
  margin-left: 8px;
  cursor: pointer;
`;

//관리자 메뉴 하단 저장버튼 공간
export const WrapperFooter = styled(Box)`
  margin-top: 24px;
  padding-top: 20px;
  width: 100%;
  text-align: right;
  border-top: 1px solid ${colors.gray2};
`;

//폼 양식 오류 메세지
export const WarnMessageBox = styled.div`
  padding: 0 0 0 4px;
  font-size: 12px;
  color: ${colors.red1};
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`;

export const CustomSpins = styled(Spinner)`
  position: absolute;
  transform: translate(-50%, -50%);
  border-bottom-color: ${colors.gray2};
  border-left-color: ${colors.gray2};
  color: ${colors.mainBlue};
`;

//데이터 처리하는 동안 spin
export const CustomSpin = styled(CustomSpins)`
  top: 50%;
  left: 50%;
  @media ${breakPoints.mobile} {
    top: 45%;
    left: 45%;
  }
`;

//등록,수정시 간략한 안내 멘트
export const SubTitleBox = styled(Text)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  span {
    font-size: 16px;
    font-weight: 400;
    color: ${colors.gray4};
  }
`;

//동, 호, 명
export const WordBox = styled.div`
  padding: 10px 12px;
  color: ${colors.gray4};
  border: 1px solid ${colors.gray3};
  border-left: none;
  margin-right: 8px;
`;

//게시글 등록 css
export const NewPostBox = styled.div`
  color: ${colors.gray4};
  font-weight: 500;
`;

//글자만 있는 table
export const WordItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.gray2};
  padding: 16px 0;
  /* min-height: 56px; */
  color: ${colors.gray4};
  font-weight: 500;
`;

//input과 함께 있는 table
export const NewPost = styled.div`
  padding: 8px 0 8px 16px;
  border-bottom: 1px solid ${colors.gray2};
  display: flex;
  align-items: center;
`;

export const PostTitle = styled.div`
  min-width: 104px;
  font-weight: 500;
  color: ${colors.gray5};
`;

export const BasicTextArea = styled.textarea<{ h: string }>`
  width: 100%;
  resize: none;
  min-height: ${({ h }) => h};
  border: 1px solid ${colors.gray3};
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
  ::placeholder {
    color: ${colors.gray2};
  }
  &:focus {
    border-color: ${colors.gray3};
  }
  &:hover {
    border-color: ${colors.gray3};
  }
`;

export const Footerbutton = styled.div`
  width: 100%;
  text-align: right;
  padding-top: 20px;
`;

//Modal interval
export const IntervalBox = styled.div`
  margin-top: 32px;
`;

//상세페이지 없는 테이블
export const WholeTableList = styled.div<{ w?: string }>`
  background-color: ${colors.whiteColor};
  border-bottom: 1px solid ${colors.gray3};
  > div {
    width: ${({ w }) => (w ? w : "15%")};
  }
`;

//물음표 아이콘
export const QuestionMark = styled(BsFillQuestionCircleFill)`
  color: ${colors.gray2};
  font-size: 24px;
`;

//상세 페이지 Header
const CommonStyle = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.gray2};
`;

export const DetailViewHeader = styled(CommonStyle)`
  padding: 20px 16px 8px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-weight: 500;
`;

export const DetailTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  > p {
    font-size: 24px;
    padding-top: 4px;
  }
`;

export const TitleAlign = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 24px;
`;

export const DetailInfoBox = styled(CommonStyle)`
  padding: 20px 16px;
  display: flex;
  color: ${colors.gray4};
`;

export const InfoBoxText = styled.span`
  margin-right: 1rem;
  color: ${colors.gray5};
`;

export const DetailBody = styled(CommonStyle)`
  padding: 24px 16px;
  font-size: 20px;
`;

export const FlexModalFooter = styled(ModalFooter)<{ e?: string }>`
  display: flex;
  justify-content: ${({ e }) => (e ? "space-between" : "flex-end")};
`;

//날짜 아이콘이랑 같이 보여주고 선택하는 박스
export const DateWithIconBox = styled.div`
  display: flex;
  z-index: 100;
  .react-datepicker__input-container {
    height: 100%;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__year-wrapper {
    max-width: 208px;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${colors.mainBlue};
  }
  .react-datepicker__close-icon {
    ::after {
      background-color: ${colors.mainBlue};
    }
  }
`;

export const InnerTextBox = styled.div`
  padding: 8px 12px;
  background-color: ${colors.gray1};
  color: ${colors.gray4};
  width: fit-content;
  position: relative;
  border: 1px solid ${colors.gray1};
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  svg {
    margin-left: 4px;
    font-size: 20px;
  }
`;

//날짜 선택(customInput) 회색 네모 박스 넓이는 커스텀되게
export const SelectDateBox = styled.div<{ w?: string; p?: string }>`
  width: ${({ w }) => (w ? w : "180px")};
  margin-right: 8px;
  color: ${colors.gray4};
  border: 1px solid ${colors.gray3};
  background-color: ${colors.whiteColor};
  cursor: pointer;
  padding: ${({ p }) => (p ? p : "12px")};
  ${({ p }) =>
    p &&
    `
    -moz-padding-before: 11px;
    -ms-padding-before: 11px;
    -moz-padding-after: 11px;
    -ms-padding-after: 11px;
  `}
`;

export const DownIcon = styled(FiChevronDown)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: ${colors.gray4};
  right: 7%;
  font-size: 20px;
`;

//사진 삭제 CloseIcon CSS
export const CloseIconWithImg = styled(IoClose)`
  position: absolute;
  top: 8%;
  right: 16%;
  background-color: ${colors.red1};
  border-radius: 4px;
  font-size: 16px;
  padding: 2px;
  color: ${colors.whiteColor};
  cursor: pointer;
`;

//이미지 정렬 공통적으로 정리 필요
export const ImageArr = styled(Flex)`
  width: 100%;
  > div {
    width: 88px;
    margin: 0.75rem 0.75rem 0 0;
    position: relative;
  }
  @media ${breakPoints.mobile} {
    div {
      width: 20%;
    }
  }
`;

export const ShowImages = styled.img`
  width: 100%;
  height: 100%;
  max-height: 88px;
  object-fit: fill;
`;

// 배경 없는 삭제 아이콘
export const OnDeleteImage = styled(IoClose)`
  color: ${colors.red1};
  position: absolute;
  cursor: pointer;
  top: 3px;
  right: 15px;
`;

export const DeleteIcon = styled(OnDeleteImage)`
  top: 4px;
  right: 4px;
`;
