import { twMerge } from "tailwind-merge";
import Icon from "../types/solar-icon";

type Props = {
  type: "owner" | "master" | "normal";
};

export default function CustomBadge({ type }: Props) {
  const iconElement = {
    owner: <></>,
    master: <Icon.CrownMinimalistic iconStyle="Bold" size={12} color="#6366F1" />,
    normal: <></>,
  }[type];

  const mappingType = {
    owner: "owner",
    master: "normal",
    normal: "normal",
  }[type];

  const themeClasses: Record<string, string> = {
    owner: "border-orange-300 bg-orange-50 text-orange-500",
    normal: "border-indigo-300 bg-indigo-50 text-indigo-500",
    // normal: "border-sky-300 bg-sky-50 text-sky-500",
  };

  const label: Record<string, string> = {
    owner: "소유자",
    normal: "입주자",
  };

  return (
    <div
      className={twMerge(
        `flex h-6 items-center justify-center gap-1 rounded-xl border px-2 text-center`,
        themeClasses[mappingType],
      )}
    >
      <span className="pt-0.5 text-xs font-medium">{label[mappingType] || "알 수 없음"}</span>
      {iconElement}
    </div>
  );
}
