import DatePicker from "react-datepicker";
import { forwardRef, useState } from "react";
import Icon from "../types/iconsax";
import { twMerge } from "tailwind-merge";

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  setDateRange: (update: [Date | null, Date | null]) => void;
};

type CustomDatePickerProps = {
  value?: string;
  onClick?: () => void;
  open?: boolean;
};

export default function CustomDatePicker({ startDate, endDate, setDateRange }: Props) {
  const [open, setOpen] = useState(false);

  const CustomDatePicker = forwardRef<HTMLDivElement, CustomDatePickerProps>(({ value, onClick }, ref) => (
    <div
      ref={ref}
      onClick={onClick}
      className={twMerge(
        "relative flex h-12 w-full flex-grow cursor-pointer items-center justify-between rounded-lg border border-gray-300 px-4 text-gray-500 hover:bg-gray-50 hover:text-gray-600",
        open && "border-gray-400 text-gray-600 outline-none outline-2 outline-offset-0 outline-gray-200",
        value && "border-gray-400 text-gray-700",
      )}
    >
      <span className="flex items-center text-base font-medium leading-5">{value || "캘린더"}</span>
      <Icon.CalendarCircle variant="Bold" color="#6B7280" size={24} />
    </div>
  ));

  return (
    <div className="flex flex-1 flex-col">
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
        dateFormat="yyyy-MM-dd"
        customInput={<CustomDatePicker />}
        onCalendarOpen={() => setOpen(true)}
        onCalendarClose={() => setOpen(false)}
      />
    </div>
  );
}
