import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import useUserAuth from "../../../../components/hooks/useUserAuth";
import { fetchClient } from "../../../../lib/api/axios";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { notice_detail_key } from "../../../../lib/api/queries/keys";
import { notice_detail } from "../../../../lib/api/queries/urls";
import { INotice } from "../../../../lib/types/Imodels";
import NewNotification from "../../new/page";

export default function EditNotification() {
  const { noticeId } = useParams();
  console.log(noticeId, "notice");
  const auth = useUserAuth("complaint");
  const { data } = useQuery(
    [notice_detail_key],
    () =>
      fetchClient
        .post<BaseResponse<INotice>>(notice_detail, {
          notice_id: noticeId,
        })
        .then((res) => res.data.data),
    {
      enabled: auth === "edit",
    },
  );

  return <NewNotification isEdit={true} data={data} />;
}
