import { useContext } from "react";
import { GlobalContext } from "../../../App";
import { Flex, useDisclosure, UseModalProps } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { colors } from "../../styled/common";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { ReactComponent as User } from "../assets/user.svg";
import { ReactComponent as Logout } from "../assets/logout.svg";
import { AiOutlineInfoCircle } from "@react-icons/all-files/ai/AiOutlineInfoCircle";
import UserInfoInDrawer from "../../../pages/control/manager/details/userInfo";
import { signOut } from "firebase/auth";
import { auth, messaging } from "../../../lib/firebase";
import { deleteToken } from "firebase/messaging";
import { useNavigate } from "react-router-dom";
import { toast } from "../../../lib/utils/toast";

const PageWrapper = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: fixed;
  width: 100vw;
  height: calc(100vh - 56px);
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 1;
`;

const UserBox = styled.div`
  width: 100%;
  height: 21rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.mainBlue};
  padding: 2rem 1rem 1rem 1rem;
  color: ${colors.whiteColor};
`;

const PageTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
`;

const UserInfoBox = styled.div`
  padding: 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const ProfileImage = styled.div`
  width: 5.75rem;
  height: 5.75rem;
  background-color: #d9d9d9;
  overflow: hidden;
  border-radius: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 1rem 0;
  font-size: 16px;
  font-weight: 500;
  p {
    font-size: 1.5rem;
    margin-right: 4px;
  }
`;

const UserActionBox = styled.div`
  font-weight: 400;
  color: ${colors.gray5};
`;

const UserAction = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${colors.gray1};
  div {
    align-items: center;
    svg {
      margin-right: 8px;
    }
  }
  svg {
    width: 1.25rem;
    height: 1.25rem;
    color: ${colors.gray3};
  }
`;

export default function UserPageForApp(props: UseModalProps) {
  const { ReactNativeWebView } = window;

  const navigate = useNavigate();
  const { user, setUser, buildInfo, setBuildInfo, AppToken } = useContext(GlobalContext);

  const { isOpen, onOpen, onClose } = useDisclosure(); //내 정보

  const onClickLogout = () => {
    if (AppToken) {
      //토큰 있을때(앱)
      if (ReactNativeWebView) {
        //로그아웃 메세지 전달
        ReactNativeWebView.postMessage(JSON.stringify({ message: "signout" }));
      }
    } else {
      // console.log("pass");
      signOut(auth)
        .then(() => {
          messaging !== null && deleteToken(messaging);
          setUser && setUser(undefined);
          setBuildInfo && setBuildInfo(undefined);
          toast.warning({
            title: "알림",
            description: "로그아웃되었습니다. 로그인 페이지로 이동합니다.",
          });

          navigate("/");
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <PageWrapper isOpen={props.isOpen}>
      <UserBox>
        <PageTitle>마이페이지</PageTitle>
        <UserInfoBox>
          <ProfileImage>
            {user && (user.profile_picture.length || 0) > 0 && (
              <img src={user.profile_picture[0]} alt="프로필 이미지" />
            )}
          </ProfileImage>
          <UserInfo>
            <p>{user?.name}</p>
            <span>
              | {user?.department_id ? user.department_id.name : user?.user_type === "master" ? "마스터" : "-"}
            </span>
          </UserInfo>
          <p>{buildInfo?.name}</p>
        </UserInfoBox>
      </UserBox>
      <UserActionBox>
        <UserAction onClick={onOpen}>
          <Flex>
            <User fill={colors.gray3} /> <p>내 정보</p>
          </Flex>
          <FiChevronRight />
        </UserAction>
        <UserAction
          onClick={() =>
            window.open("https://www.notion.so/bohome-livable/NEW-4f52610d4d7e4f258364ed4e2a230e0e?pvs=4", "_blank")
          }
        >
          <Flex>
            <AiOutlineInfoCircle /> <p>오피스너 메뉴얼</p>
          </Flex>
          <FiChevronRight />
        </UserAction>
        <UserAction onClick={onClickLogout}>
          <Flex>
            <Logout fill={colors.gray3} /> <p>로그아웃</p>
          </Flex>
        </UserAction>
      </UserActionBox>
      {isOpen && user && <UserInfoInDrawer isOpen={isOpen} onClose={onClose} data={user} />}
    </PageWrapper>
  );
}
