import CustomModal from "../../../components/CustomModal";
import { useState } from "react";
import { useUpdateCompanyMutation } from "../../../requests/company/useUpdateCompanyMutation";
import CustomRadioGroup from "../../../components/CustomRadioGroup";
import toast from "react-hot-toast";
import CustomToast from "../../../components/CustomToast";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  companyId: string;
  name: string;
  type?: "monthly_rent" | "own_house" | "etc";
};

export default function CompanyModifyTypeModal({ isOpen, onClose, companyId, name, type }: Props) {
  const [moveInType, setMoveInType] = useState((type as string) ?? "monthly_rent");

  const { mutateAsync: updateCompany } = useUpdateCompanyMutation();

  if (!isOpen) return null;

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title="회사 정보 수정"
      exit={true}
      primaryButtonText="저장하기"
      onPrimaryButtonClick={() => {
        toast.promise(updateCompany({ companyId, moveInType }), {
          success: () => {
            onClose();
            return (
              <CustomToast
                iconType="check"
                title="입주사 정보 수정"
                description={`'${name}'의 정보가 변경되었습니다.`}
              />
            );
          },
          error: () => {
            onClose();
            return <CustomToast iconType="error" title="정보 수정 중 에러가 발생하였습니다." />;
          },
          loading: <CustomToast iconType="loading" title="Loading" />,
        });
      }}
      primaryButtonDisable={moveInType === type}
      neutralButtonText="취소"
      onNeutralButtonClick={onClose}
    >
      <div className="flex w-[488px] flex-col gap-5 p-6 pt-3">
        <div className="flex flex-col gap-2">
          <div className="flex gap-1 font-semibold">
            <span className="text-gray-700">입주 형태</span>
            <span className="text-blue-600">*</span>
          </div>
          <CustomRadioGroup
            data={[
              { name: "월세", value: "monthly_rent" },
              { name: "소유", value: "own_house" },
              { name: "기타", value: "etc" },
            ]}
            getValue={moveInType}
            setState={setMoveInType}
            className={"flex items-center gap-10 p-3"}
          />
        </div>
      </div>
    </CustomModal>
  );
}
