import { SavedDetails } from "../../../../commons/styled";
import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import SubframePart from "../../../../../../components/Main/subframePart";
import { useCallback, useEffect, useState } from "react";
import { ItemInfo } from "../../../../[publicType]/_view/InfoItem";
import EditModalNoHeader from "../../../../../../components/modal/responsive/edit";
import { BookingTime } from "../../../components/bookingTime";
import {
  MemoizedMoreRequest,
  MemoizedPaymentInfo,
  MemoizedPermission,
  PaymentMethod,
} from "../../../components/Payments";
import { IPaymentMethod } from "../../../../../../lib/types/Imodels";
import { ITimeSet } from "../../create/page";
import { IItemBasicInfo, IMutateTime } from "../../../../commons/types";
import { useFetchPublicItem, useMutatePublicItem } from "../../../../commons/queries";
import { useParams } from "react-router-dom";
import SavedCloseDays from "../../../../[publicType]/operation/savedDays";
import { format_payment_method, getTimeOnString, isCheckedItemOperation } from "../../../../commons/func";
import moment from "moment";
import FullPageSpinner from "../../../../../../components/Spinner";
import { v4 as uuidv4 } from "uuid";
import {
  MainActionBox,
  UpdateIconBox,
  ShadowBoxInDetail,
  BoxInDetail,
} from "../../../../../../components/styled/webApp";
import ShadowStepBox from "../../../../../../components/step/field";
import { toast } from "../../../../../../lib/utils/toast";

// const DefaultDateBox = styled.div`
//   padding-bottom: 0.75rem;
//   border-bottom: 1px solid ${colors.gray2};
//   font-weight: 500;
// `;

export default function BookingItemDetails() {
  const { itemsId } = useParams();
  const { data } = useFetchPublicItem(itemsId);
  const [isActive, setIsActive] = useState(0);
  const detailTabArr = ["기본정보", "예약 ・ 결제"];
  const { updateMutation } = useMutatePublicItem(true);
  const { onOpen, isOpen, onClose } = useDisclosure(); //기본 운영
  const { onOpen: onNeed, isOpen: isNeed, onClose: offNeed } = useDisclosure(); //확정 방식
  const { onOpen: onPay, isOpen: isPay, onClose: offPay } = useDisclosure(); //결제 수단
  const { onOpen: onInfo, isOpen: isInfo, onClose: offInfo } = useDisclosure(); //결제 안내 사항
  const { onOpen: onMore, isOpen: isMore, onClose: offMore } = useDisclosure(); //추가 요청 사항
  //기본 정보 관련
  const [fileArr, setFileArr] = useState<string>("");
  const [itemInfo, setItemInfo] = useState<IItemBasicInfo>({
    name: "",
    introduction_description: "",
  });
  //예약관련
  const [interval, setInterval] = useState("");
  const [isEveryDay, setIsEveryDay] = useState("true");
  const [weekDayArr, setWeekDayArr] = useState<ITimeSet[]>([]);
  const [weeklyArr, setWeeklyArr] = useState<ITimeSet[]>([]);
  const timeArr = { weekDayArr, setWeekDayArr, weeklyArr, setWeeklyArr };

  const onUpdateOperateTime = async () => {
    const isValid = isCheckedItemOperation(weekDayArr, weeklyArr);
    if (isValid === "운영 시간 설정 안내")
      // return toast.timeWarning({
      return toast.warning({
        title: "운영 시간 설정 안내",
        description: "운영시간과 예약 인원을 정확히 설정해주세요.",
      });
    if (isValid === "시간 설정 안내")
      // return toast.timeWarning({
      return toast.warning({
        title: "운영 시간 설정 안내",
        description: "운영시간의 시작 시간은 마지막 시간보다 이전으로 설정해주세요.",
      });
    if (isValid === "예약 인원 설정 안내")
      // return toast.timeWarning({
      return toast.warning({
        title: "운영 시간 설정 안내",
        description: "예약 인원은 1명 이상으로 설정해주세요.",
      });
    if (isValid === "운영 시간 겹침")
      // return toast.timeWarning({
      return toast.warning({
        title: "운영 시간 설정 안내",
        description: "운영 시간은 겹칠 수 없습니다.",
      });

    const newObj: IMutateTime = { weekend: [] };
    weekDayArr.forEach((el) =>
      el.time.forEach((ele) =>
        newObj.weekend.push({
          time: ele.time,
          people_num: ele.people_num,
        }),
      ),
    );
    if (isEveryDay === "false") {
      newObj.weekly = [];
      weeklyArr.forEach((el) =>
        el.time.forEach((ele) =>
          newObj.weekly?.push({
            time: ele.time,
            people_num: ele.people_num,
          }),
        ),
      );
    }

    try {
      await updateMutation.mutateAsync({
        reservation_product_id: String(data?._id),
        time_unit: interval,
        is_same_weekdays_and_weekends: JSON.parse(isEveryDay),
        people_per_time_slot_weekday: newObj.weekend,
        people_per_time_slot_weekend: isEveryDay === "false" ? newObj.weekly : newObj.weekend,
      });
      onClose();
      toast.success({
        title: "예약 상품 수정 완료",
        description: `해당 예약 상품의 정보가 변경되었습니다.`,
      });
    } catch (err) {
      toast.warning({
        title: "예약 상품 수정 실패",
        description: `일시적으로 예약 상품의 정보를 변경하는데 실패했습니다. 잠시 후 다시 시도해주세요.`,
      });
    }
  };

  //예약 확정 방식 관련
  const [isPermission, setIsPermission] = useState<boolean>();
  const onUpdatePermission = async () => {
    if (updateMutation.isLoading) return;

    try {
      await updateMutation.mutateAsync({
        reservation_product_id: String(data?._id),
        is_approval_required: isPermission,
      });

      offNeed();
      toast.success({
        title: "예약 상품 수정 완료",
        description: `예약 확정 방식을 '${
          isPermission ? "관리자 확인 후 확정" : "예약신청과 동시에 확정"
        }'으로 변경했습니다.`,
      });
    } catch (err) {
      return toast.warning({
        title: "예약 상품 수정 실패",
        description: `예약 확정 방식 변경에 실패했습니다. 새로 고침 후 다시 시도해주세요.`,
      });
    }
  };
  const onUpdateNeedPermission = useCallback(
    (status: boolean) => {
      if (status === isPermission) return;
      setIsPermission((prev) => !prev);
    },
    [isPermission],
  );
  //예약 결제 관련
  const [isFree, setIsFree] = useState(false);
  const [method, setMethod] = useState<IPaymentMethod[]>([{ payment_method: "point", price: "", _id: uuidv4() }]);
  const onUpdateIsFree = useCallback((isFree: boolean) => setIsFree(isFree), [isFree]);
  const onUpdateMethod = async () => {
    if (updateMutation.isLoading) return;
    if (!isFree && method.some((el) => !Number(el.price)))
      return toast.warning({
        title: "결제 수단 설정",
        description: "무료 이용 시설은 무료 이용을 선택, 유료 시설은 결제 금액을 정확히 입력해주세요.",
      });

    try {
      await updateMutation.mutateAsync({
        reservation_product_id: String(data?._id),
        payment_method_and_price: isFree
          ? [{ payment_method: "free", price: "" }]
          : method.map((el) => ({
              payment_method: "point",
              price: el.price,
            })),
      });

      offPay();
      toast.success({
        title: "예약 상품 수정 완료",
        description: `결제수단이 변경되었습니다.`,
      });
    } catch (err) {
      return toast.warning({
        title: "예약 상품 수정 실패",
        description: `결제수단 변경에 실패했습니다. 새로 고침 후 다시 시도해주세요.`,
      });
    }
  };

  const onChangeToInitialMethod = () => {
    if (!data) return;
    offPay();

    data.payment_method_and_price[0].payment_method === "free"
      ? (setIsFree(true), setMethod([{ payment_method: "free", price: "" }]))
      : (setIsFree(false), setMethod(data.payment_method_and_price));
  };

  //결제 안내사항
  const [paymentInfo, setPaymentInfo] = useState("");
  const onUpdatePaymentInfo = async () => {
    if (updateMutation.isLoading) return;

    try {
      await updateMutation.mutateAsync({
        reservation_product_id: String(data?._id),
        payment_description: paymentInfo,
      });

      offInfo();
      toast.success({
        title: "예약 상품 수정 완료",
        description: "결제 안내사항이 변경되었습니다.",
      });
    } catch (err) {
      return toast.warning({
        title: "예약 상품 수정 실패",
        description: `결제 안내사항 변경에 실패했습니다. 새로 고침 후 다시 시도해주세요.`,
      });
    }
  };
  //요청사항
  const [isMoreRequest, setIsMoreRequest] = useState<boolean>();
  const onUpdateRequest = async () => {
    if (updateMutation.isLoading) return;

    try {
      await updateMutation.mutateAsync({
        reservation_product_id: String(data?._id),
        is_additional_request: isMoreRequest,
      });

      offMore();
      toast.success({
        title: "예약 상품 수정 완료",
        description: `지금부터 추가 요청 사항을 받을 수 ${isMoreRequest ? "있" : "없"}습니다.`,
      });
    } catch (err) {
      return toast.warning({
        title: "예약 상품 수정 실패",
        description: `요청 사항 변경에 실패했습니다. 새로 고침 후 다시 시도해주세요.`,
      });
    }
  };
  const onUpdateMoreRequest = useCallback(
    (status: boolean) => {
      if (status === isMoreRequest) return;
      setIsMoreRequest((prev) => !prev);
    },
    [isMoreRequest],
  );

  const onChangedInitialTime = () => {
    if (!data) return;
    setInterval(data.time_unit || "");
    data.is_same_weekdays_and_weekends !== undefined && setIsEveryDay(String(data.is_same_weekdays_and_weekends));

    if (data.people_per_time_slot_weekday.length === 1 || data.time_unit === "1d") {
      //시작과 끝시간이 같거나 예약 단위가 하루인 경우
      setWeekDayArr([
        {
          _id: uuidv4(),
          start: getTimeOnString(data.people_per_time_slot_weekday[0].time),
          end: getTimeOnString(data.people_per_time_slot_weekday[0].time),
          people_num: data.people_per_time_slot_weekday[0].people_num,
          max_num: data.people_per_time_slot_weekday[0].people_num,
          time: data.people_per_time_slot_weekday,
        },
      ]);
    } else {
      const idxArr = data.people_per_time_slot_weekday.map((el, idx) => {
        if (data.people_per_time_slot_weekday.length === idx + 1) return -1;
        const difference = moment(el.time).diff(moment(data.people_per_time_slot_weekday[idx + 1].time)) / (1000 * 60);

        return (difference < -60 && data.time_unit === "1h") || (difference <= -60 && data.time_unit === "30m")
          ? idx
          : -1;
      });
      const newArr: ITimeSet[] = [];
      const newIdx = idxArr.filter((el) => el !== -1);
      if (newIdx.length === 0) {
        setWeekDayArr([
          {
            _id: uuidv4(),
            start: getTimeOnString(data.people_per_time_slot_weekday[0].time),
            end: getTimeOnString(data.people_per_time_slot_weekday[data.people_per_time_slot_weekday.length - 1].time),
            people_num: Math.max(...data.people_per_time_slot_weekday.map((el) => el.people_num)),
            max_num: Math.max(...data.people_per_time_slot_weekday.map((el) => el.people_num)),
            time: data.people_per_time_slot_weekday,
          },
        ]);
      } else {
        const staArr = [...data.people_per_time_slot_weekday];
        newIdx.reduce((acc, cur) => {
          const spliceArr = staArr.slice(acc, cur + 1);
          newArr.push({
            _id: spliceArr[0]._id,
            start: getTimeOnString(spliceArr[0].time),
            end: getTimeOnString(spliceArr[spliceArr.length - 1].time),
            time: spliceArr,
            people_num: Math.max(...spliceArr.map((el) => el.people_num)),
            max_num: Math.max(...spliceArr.map((el) => el.people_num)),
          });
          if (cur === newIdx[newIdx.length - 1]) {
            const lastArr = staArr.slice(cur + 1);
            newArr.push({
              _id: lastArr[0]._id,
              start: getTimeOnString(lastArr[0].time),
              end: getTimeOnString(lastArr[lastArr.length - 1].time),
              time: lastArr,
              people_num: Math.max(...lastArr.map((el) => el.people_num)),
              max_num: Math.max(...lastArr.map((el) => el.people_num)),
            }); //마지막 남은 시간
          }
          return cur + 1;
        }, 0);
        // console.log(newArr);
        setWeekDayArr(newArr);
      }
    }

    if (data.is_same_weekdays_and_weekends !== false) return setWeeklyArr([]); //여기 아래로 주말 스테이트 설정
    if (data.people_per_time_slot_weekend.length === 1 || data.time_unit === "1d") {
      //시작과 끝시간이 같거나 예약 단위가 하루인 경우
      setWeeklyArr([
        {
          _id: uuidv4(),
          start: getTimeOnString(data.people_per_time_slot_weekend[0].time),
          end: getTimeOnString(data.people_per_time_slot_weekend[0].time),
          people_num: data.people_per_time_slot_weekend[0].people_num,
          max_num: data.people_per_time_slot_weekend[0].people_num,
          time: data.people_per_time_slot_weekend,
        },
      ]);
    } else {
      const idxArr = data.people_per_time_slot_weekend.map((el, idx) => {
        if (data.people_per_time_slot_weekend.length === idx + 1) return -1;
        const difference = moment(el.time).diff(moment(data.people_per_time_slot_weekend[idx + 1].time)) / (1000 * 60);
        return (difference < -60 && data.time_unit === "1h") || (difference <= -60 && data.time_unit === "30m")
          ? idx
          : -1;
      });

      const newArr: ITimeSet[] = [];
      const newIdx = idxArr.filter((el) => el !== -1);
      if (newIdx.length === 0) {
        //시간차 없는 배열
        setWeeklyArr([
          {
            _id: uuidv4(),
            start: getTimeOnString(data.people_per_time_slot_weekend[0].time),
            end: getTimeOnString(data.people_per_time_slot_weekend[data.people_per_time_slot_weekend.length - 1].time),
            people_num: Math.max(...data.people_per_time_slot_weekend.map((el) => el.people_num)),
            max_num: Math.max(...data.people_per_time_slot_weekend.map((el) => el.people_num)),
            time: data.people_per_time_slot_weekend,
          },
        ]);
      } else {
        const staArr = [...data.people_per_time_slot_weekend];
        newIdx.reduce((acc, cur) => {
          const spliceArr = staArr.slice(acc, cur + 1);
          newArr.push({
            _id: spliceArr[0]._id,
            start: getTimeOnString(spliceArr[0].time),
            end: getTimeOnString(spliceArr[spliceArr.length - 1].time),
            time: spliceArr,
            people_num: Math.max(...spliceArr.map((el) => el.people_num)),
            max_num: Math.max(...spliceArr.map((el) => el.people_num)),
          });
          if (cur === newIdx[newIdx.length - 1]) {
            const lastArr = staArr.slice(cur + 1);
            newArr.push({
              _id: lastArr[0]._id,
              start: getTimeOnString(lastArr[0].time),
              end: getTimeOnString(lastArr[lastArr.length - 1].time),
              time: lastArr,
              people_num: Math.max(...lastArr.map((el) => el.people_num)),
              max_num: Math.max(...lastArr.map((el) => el.people_num)),
            }); //마지막 남은 시간
          }
          return cur + 1;
        }, 0);

        setWeeklyArr(newArr);
      }
    }
  };

  const onResetBasicInfo = () => {
    if (!data) return;

    const info: IItemBasicInfo = {
      name: data.name,
      introduction_description: data.introduction_description,
      reservation_and_visit_description: data.reservation_and_visit_description,
    };
    setItemInfo(info);
    data.picture && setFileArr(data.picture);
  };

  useEffect(() => {
    if (!data) return;

    //기본 정보
    onResetBasicInfo();
    //확정 방식
    setIsPermission(data.is_approval_required);
    //결제 방식
    data.payment_method_and_price[0].payment_method === "free"
      ? (setIsFree(true), setMethod([{ _id: uuidv4(), payment_method: "free", price: "" }]))
      : setMethod(data.payment_method_and_price);
    //결제 안내 사항
    setPaymentInfo(data.payment_description);
    //추가 요청 사항
    setIsMoreRequest(data.is_additional_request);
    //운영시간 세팅
    onChangedInitialTime();
  }, [data]);

  const onClickToSaveInfo = async () => {
    if (!itemInfo.name || !itemInfo.introduction_description)
      return toast.warning({
        title: "예약 상품 수정 실패",
        description: "예약 상품 수정을 위해 필수 항목을 모두 입력해주세요.",
      });

    try {
      await updateMutation.mutateAsync({
        reservation_product_id: String(data?._id),
        name: itemInfo.name,
        introduction_description: itemInfo.introduction_description,
        reservation_and_visit_description: itemInfo.reservation_and_visit_description,
        picture: fileArr,
      });

      toast.success({
        title: "예약 상품 수정 완료",
        description: `해당 예약 상품의 정보가 변경되었습니다.`,
      });
    } catch (err) {
      toast.warning({
        title: "예약 상품 수정 실패",
        description: `일시적으로 예약 상품의 정보를 변경하는데 실패했습니다. 잠시 후 다시 시도해주세요.`,
      });
    }
  };

  const isOpenWarnModal = () => {
    if (!data) return false;
    for (const keys in itemInfo) {
      if (data[keys as keyof typeof data] !== itemInfo[keys as keyof typeof itemInfo]) return true;
    }

    return false;
  };

  const imgProps = {
    itemInfo,
    setItemInfo,
    fileUrl: fileArr,
    setFileUrl: setFileArr,
    auth: "edit",
  };

  if (!data) return <FullPageSpinner />;
  return (
    <SubframePart isModal={false} name={data.name || ""} isWarnMessage={isOpenWarnModal}>
      <MainActionBox>
        <ShadowStepBox
          isActive={isActive}
          step={detailTabArr}
          styled={{ width: "15.5rem" }}
          onClickStep={(el, idx) => {
            if (el === "기본정보") onResetBasicInfo();
            setIsActive(idx);
          }}
        />
        {isActive === 0 ? (
          <>
            <ItemInfo {...imgProps} />
            <Button variant="m_blue" fontSize="1.25rem" onClick={onClickToSaveInfo}>
              저장
            </Button>
          </>
        ) : (
          <>
            <ShadowBoxInDetail>
              <BoxInDetail>
                <h4>기본 운영</h4>
                <UpdateIconBox onClick={onOpen}>
                  수정하기 <FiChevronRight />
                </UpdateIconBox>
              </BoxInDetail>
              {/* <DefaultDateBox>2023.04.17(월) ~</DefaultDateBox> */}
              <SavedDetails>
                <div className="section">{data.is_same_weekdays_and_weekends === false ? "평일" : "운영시간"}</div>
                <div>
                  {moment.utc(data.people_per_time_slot_weekday[0].time).format("HH:mm ")}-
                  {moment
                    .utc(data.people_per_time_slot_weekday[data.people_per_time_slot_weekday.length - 1].time)
                    .format(" HH:mm")}
                  <span style={{ marginLeft: "1.5rem" }}>
                    총 {Math.max(...(data?.people_per_time_slot_weekday.map((el) => el.people_num) || [0]))}명
                  </span>
                </div>
              </SavedDetails>
              {data.is_same_weekdays_and_weekends === false && (
                <SavedDetails>
                  <div className="section">주말</div>
                  <div>
                    {moment.utc(data.people_per_time_slot_weekend[0].time).format("HH:mm ")}-
                    {moment
                      .utc(data.people_per_time_slot_weekend[data.people_per_time_slot_weekend.length - 1].time)
                      .format(" HH:mm")}
                    <span style={{ marginLeft: "1.5rem" }}>
                      총 {Math.max(...(data?.people_per_time_slot_weekend.map((el) => el.people_num) || [0]))}명
                    </span>
                  </div>
                </SavedDetails>
              )}
              <EditModalNoHeader
                isOpen={isOpen}
                onClose={() => {
                  onChangedInitialTime();
                  onClose();
                }}
                onClickUpdate={onUpdateOperateTime}
              >
                <BookingTime
                  isModal={true}
                  isEveryDay={isEveryDay}
                  setIsEveryDay={setIsEveryDay}
                  interval={interval}
                  // onClickNextStep={onClickNextStep}
                  setInterval={setInterval}
                  {...timeArr}
                />
              </EditModalNoHeader>
            </ShadowBoxInDetail>
            <SavedCloseDays isPublic={false} data={data} />
            <ShadowBoxInDetail>
              <BoxInDetail>
                <h4>예약 확정 방식</h4>
                <UpdateIconBox onClick={onNeed}>
                  수정하기 <FiChevronRight />
                </UpdateIconBox>
              </BoxInDetail>
              <Box fontWeight={500}>
                {data?.is_approval_required ? "관리자 확인 후 확정" : "예약신청과 동시에 확정"}
              </Box>
              <EditModalNoHeader
                isOpen={isNeed}
                onClose={() => {
                  setIsPermission(data.is_approval_required);
                  offNeed();
                }}
                onClickUpdate={onUpdatePermission}
              >
                <MemoizedPermission isModal={true} isActive={isPermission} onUpdateActive={onUpdateNeedPermission} />
              </EditModalNoHeader>
            </ShadowBoxInDetail>
            <ShadowBoxInDetail>
              <BoxInDetail>
                <h4>결제수단</h4>
                <UpdateIconBox onClick={onPay}>
                  수정하기 <FiChevronRight />
                </UpdateIconBox>
              </BoxInDetail>
              {data?.payment_method_and_price.map((el) => (
                <SavedDetails key={el._id}>
                  <div className="section">{format_payment_method(el.payment_method)}</div>
                  {el.price && (
                    <div>
                      {Number(el.price).toLocaleString("kr")}
                      {format_payment_method(el.payment_method)}
                    </div>
                  )}
                </SavedDetails>
              ))}
              <EditModalNoHeader isOpen={isPay} onClose={onChangeToInitialMethod} onClickUpdate={onUpdateMethod}>
                <PaymentMethod
                  isModal={true}
                  setMethod={setMethod}
                  method={method}
                  onUpdateIsFree={onUpdateIsFree}
                  isFree={isFree}
                />
              </EditModalNoHeader>
            </ShadowBoxInDetail>
            <ShadowBoxInDetail>
              <BoxInDetail>
                <h4>결제 안내사항</h4>
                <UpdateIconBox onClick={onInfo}>
                  수정하기 <FiChevronRight />
                </UpdateIconBox>
              </BoxInDetail>
              <Box fontWeight={500}>
                {data?.payment_description ? data?.payment_description : "결제 안내 사항 없음"}
              </Box>
              <EditModalNoHeader
                isOpen={isInfo}
                onClose={() => {
                  offInfo();
                  setPaymentInfo(data.payment_description);
                }}
                onClickUpdate={onUpdatePaymentInfo}
              >
                <MemoizedPaymentInfo isModal={true} paymentInfo={paymentInfo} setPaymentInfo={setPaymentInfo} />
              </EditModalNoHeader>
            </ShadowBoxInDetail>
            <ShadowBoxInDetail>
              <BoxInDetail>
                <h4>추가 요청사항</h4>
                <UpdateIconBox onClick={onMore}>
                  수정하기 <FiChevronRight />
                </UpdateIconBox>
              </BoxInDetail>
              <Box fontWeight={500}>
                추가요청사항
                {data?.is_additional_request ? " 받음" : " 받지 않음"}
              </Box>
              <EditModalNoHeader
                isOpen={isMore}
                onClose={() => {
                  offMore();
                  setIsMoreRequest(data.is_additional_request);
                }}
                onClickUpdate={onUpdateRequest}
              >
                <MemoizedMoreRequest isModal={true} isActive={isMoreRequest} onUpdateActive={onUpdateMoreRequest} />
              </EditModalNoHeader>
            </ShadowBoxInDetail>
          </>
        )}
      </MainActionBox>
    </SubframePart>
  );
}
