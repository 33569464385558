import { ChangeEvent } from "react";
import { format_number } from "../../../lib/utils/format";

export const onChangeNumbers = (e: ChangeEvent<HTMLInputElement>) => {
  const value = format_number(e.target.value);
  if (value.length > 9) return "false";

  return value;
  // onChange(e.target.value);
};
