import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { CloseIconWithImg } from "../../../../components/styled";
import { colors } from "../../../../components/styled/common";

interface IProps {
  radio?: string;
  isschedule?: string[];
}

export const PickerContainer = styled.div`
  padding: ${(props: IProps) =>
    props.radio === "2" ? "8px 0 8px 16px" : "16px 16px"};
  border-bottom: 1px solid ${colors.gray2};
  display: flex;
  align-items: center;
  .react-datepicker-wrapper {
    width: auto;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__header {
    background-color: ${colors.whiteColor};
  }
  .react-datepicker__header--time {
    padding: 20px;
  }
  .react-datepicker__time-list {
    height: 208px;
  }
  .react-datepicker__day--selected,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${colors.mainBlue};
  }
  .react-datepicker-popper {
    display: ${(props: IProps) => props.radio === "1" && "none"};
  }
`;

export const ReservedButton = styled.div`
  display: ${(props: IProps) => (props.radio === "2" ? "block" : "none")};
  background-color: ${colors.whiteColor};
  border-radius: 2px;
  border: 1px solid ${colors.gray3};
  padding: 8px 12px;
  color: ${colors.mainBlue};
`;

export const ChooseRangeCalender = styled(Box)`
  display: flex;
  align-items: center;
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__header--time {
    padding: 20px;
  }
  .react-datepicker__time-list {
    height: 208px;
  }
  .react-datepicker__day--selected,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${colors.mainBlue};
  }
  .react-datepicker__tab-loop {
    display: block !important;
    opacity: ${(props: IProps) =>
      props.isschedule?.includes("is_scheduled") ? "1" : "0"};
  }
  .react-datepicker__tab-loop,
  .react-datepicker__close-icon {
    display: ${(props: IProps) =>
      props.isschedule?.includes("is_scheduled") && "block"};
  }
  .react-datepicker__close-icon::after {
    background-color: ${colors.mainBlue};
    display: ${(props: IProps) =>
      props.isschedule?.includes("is_scheduled") ? "table-cell" : "none"};
  }
  .react-datepicker-popper[data-placement^="bottom"] {
    margin: 0 0 0 10px;
  }
`;

export const ScheduleButton = styled.div`
  width: 240px;
  display: ${(props: IProps) =>
    props.isschedule?.includes("is_scheduled") ? "block" : "none"};
  background-color: ${colors.whiteColor};
  border-radius: 2px;
  border: 1px solid ${colors.gray3};
  padding: 8px 12px;
  margin-left: 12px;
  color: ${colors.mainBlue};
  input {
    width: 100%;
  }
`;

export const QuillBox = styled(Box)`
  width: 100%;
  height: 350px;
  overflow: hidden;
  .quill .ql-editing {
    left: 40% !important;
    top: 0px !important;
  }
`;

export const FileText = styled(Box)`
  background-color: ${colors.whiteColor};
  border: 1px solid ${colors.gray3};
  padding: 12px;
  min-height: 88px;
  width: 100%;
  margin-right: 12px;
  font-weight: 400;
  p {
    color: ${colors.gray2};
  }
`;

export const FileList = styled.div`
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  width: fit-content;
`;

export const DeleteIcon = styled(CloseIconWithImg)`
  position: static;
  border-radius: 100%;
  padding: 2px;
  margin-left: 8px;
`;
