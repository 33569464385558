import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { IRentalList } from "../../../../lib/types/Imodels";
import { detail_rental } from "../../../../lib/api/queries/urls";
import { detail_rental_key } from "../../../../lib/api/queries/keys";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import CreateRentalBill from "../../create/page";
import useUserAuth from "../../../../components/hooks/useUserAuth";

export default function RentFeeEditPage() {
  const { rentalId } = useParams();
  const auth = useUserAuth("rent_fee");

  const { data } = useQuery(
    [detail_rental_key, rentalId],
    () =>
      fetchClient
        .post<BaseResponse<IRentalList>>(detail_rental, {
          rent_fee_id: rentalId,
          payment_status: "",
          dong: "",
          search_ho: "",
          search_tenant: "",
        })
        .then((res) => res.data.data),
    { enabled: auth === "edit" },
  );

  return <CreateRentalBill isEdit={true} data={data} />;
}
