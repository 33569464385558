import { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Outlet } from "react-router";
import { GlobalContext } from "../../App";
import Layout from "../../components/layouts";
import SpinnerWithoutLayout from "../../components/Spinner/layout";
import { auth, messaging } from "../firebase";
import { login_key, userVerify } from "../api/queries/keys";
import { fetchClient } from "../api/axios";
import { BaseResponse } from "../api/queries/commons/types";
import { IUser } from "../types/Imodels";
import { useQuery } from "@tanstack/react-query";
import { signOut } from "firebase/auth";
import axios, { AxiosError } from "axios";
import { deleteToken } from "firebase/messaging";
import { useServices } from "../../hooks/use-servies";
import { useLocation } from "react-router-dom";
import Icon from "../../types/solar-icon";
import Input from "../../components/Input/Input";
import Button from "../../components/button/Button";
import { useDebounce } from "../../hooks/operators/use-debounce";
import { useBuildingInfoQuery } from "../../requests/building";
import { httpV2 } from "../httpV2";
import { toast } from "../utils/toast";

export default function ProtectRoute() {
  const navigate = useNavigate();
  const { user: UserData, setUser, AppToken } = useContext(GlobalContext);
  const [user, loading] = useAuthState(auth);
  const [inputText, setInputText] = useState("");

  const buildings = useBuildingInfoQuery();
  const currentPath = useLocation().pathname.split("/")[1];
  const services = useServices();

  const { data } = useQuery(
    [login_key, "User"],
    () =>
      fetchClient
        .post<BaseResponse<IUser>>(userVerify)
        .then((el) => {
          if (el.data.data.company_type !== "building") {
            toast.unAuthorized();
            signOut(auth);
          }
          setUser && setUser(el.data.data);
          return el.data.data;
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 504) {
            if (UserData) setUser && setUser(undefined);
            messaging !== null && deleteToken(messaging);
            signOut(auth);
            navigate("/");
            return undefined;
          }
        }),
    {
      enabled: user ? true : AppToken ? true : false,
    },
  );

  useEffect(() => {
    if (loading) return;
    auth.onAuthStateChanged((user) => {
      if (user === null) {
        if (AppToken) return navigate("/complaints", { replace: true });
        navigate("/");
      }
    });
  }, [loading]);

  const handleTextChange = useDebounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputText(e.target.value);
    },
    300,
    [inputText, setInputText],
  );

  if (!services.find((service) => service.href === currentPath)?.isAvailable) {
    return (
      <Layout>
        <div className="flex h-full w-full flex-col bg-gray-100">
          <div className="flex w-full items-center justify-center gap-1.5 bg-blue-500 px-6 py-3">
            <Icon.Lightbulb iconStyle={"Bold"} color="white" />
            <p className="text-base font-semibold leading-6 text-white">도입 후 사용할 수 있는 기능이에요.</p>
          </div>
          <div className="flex flex-1 items-center justify-center px-4 shadow">
            <div className=" w-[800px] rounded-2xl bg-white px-6 py-5 max-lg:w-full">
              <p className="text-xl font-bold leading-8 text-gray-900">계약 후 이용할 수 있는 기능이에요.</p>
              <div className="my-5 flex flex-col gap-0.5">
                <p className="text-base font-semibold leading-6 text-gray-800">해당 기능이 더 궁금하시다면?</p>
                <p className="text-base leading-6 text-gray-800">
                  지금 오피스너 담당자와 상담을 통해 빠르게 알아보세요.
                </p>
              </div>
              <div className="flex flex-col gap-3">
                <label className="font-semibold text-gray-500">담당자 연락처</label>
                <form className="flex gap-3">
                  <Input
                    onChange={handleTextChange}
                    placeholder="연락 받으실 전화번호를 입력해주세요"
                    type="tel"
                    iconElement={<Icon.PhoneCalling iconStyle="Bold" size={24} width={24} height={24} />}
                    themeSize="md"
                  />
                  <Button
                    designSchema="secondaryBlue"
                    buttonText="도입 상담 요청하기"
                    size="md"
                    onClick={() => {
                      httpV2.post("/webhook/slack", {
                        text: `${buildings.data?.data.name} 건물에서 도입 상담 요청을 하였습니다. (${inputText})\n문의 상품 = ${currentPath}`,
                      });
                    }}
                    type="button"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  if (loading || !data) return <SpinnerWithoutLayout />;
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}
