import { useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../lib/api/axios";
import { BaseResponse } from "../../lib/api/queries/commons/types";
import { IFAQ } from "../../lib/types/Imodels";
import { faq_list } from "../../lib/api/queries/urls";
import { faq_list_key } from "../../lib/api/queries/keys";
import { useState } from "react";

export const useGetFaqList = (category: string, faq_id?: string) => {
  const [faqObj, setFaq] = useState<IFAQ>();

  const { data } = useQuery([faq_list_key, category], () =>
    fetchClient.get<BaseResponse<IFAQ[]>>(faq_list, { params: { category } }).then((res) => {
      if (faq_id) setFaq(res.data.data.find((el) => String(el._id) === faq_id));
      return res.data.data;
    }),
  );

  return { data, faqObj };
};
