import { useMutation } from "@tanstack/react-query";
import { httpV2 } from "../../lib/httpV2";
import { queryClient } from "../..";

type Request = {
  companyId: string;
  name?: string;
  taxEmail?: string;
  fax?: string;
  ceoName?: string;
  phoneNumber?: string;
  moveInType?: string;
  rentStartDate?: Date;
  rentEndDate?: Date;
  rooms?: string[];
  isActivated?: boolean;
  salesOrLeaseContractFiles?: {
    directory: string;
    id?: string;
    name: string;
  }[];
  businessRegistrationFiles?: {
    directory: string;
    id?: string;
    name: string;
  }[];
};

export const useUpdateCompanyMutation = () => {
  return useMutation({
    mutationFn: ({ companyId, ...request }: Request) => {
      return httpV2.patch(`/companies/${companyId}`, request);
    },
    onSuccess: (_, { companyId }) => {
      queryClient.invalidateQueries([`/companies/${companyId}`]);
    },
  });
};
