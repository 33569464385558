import { Box, Button, Checkbox, Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
import moment from "moment";
import "moment/locale/ko";
import * as T from "./_view/styled";
import { ItemSetBox, MainContainer, MouseShadowBox, NoHistoryInData } from "../../components/styled/webApp";
import { DateWithIconBox } from "../../components/styled/index";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { ChangeEvent, useRef, useState, MouseEvent, useEffect } from "react";
import ControlTemperture from "./_view/ControlModal";
import { limitContent } from "../../lib/utils/format";
import FloorListItem from "./_view/FloorListItem";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../lib/api/axios";
import { temperature_list_key } from "../../lib/api/queries/keys";
import { temperature_list, temperature_update } from "../../lib/api/queries/urls";
import { IAccTemperature, INewTemperature } from "../../lib/types/Imodels";
import { success, warning } from "../../lib/theme/toast";
import { queryClient } from "../..";
import useUserAuth from "../../components/hooks/useUserAuth";
import { HiCheck } from "@react-icons/all-files/hi/HiCheck";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { useTemperatureLocation } from "./queries";

interface IControlTemperature {
  message: string;
  data1: INewTemperature[];
  data2: IAccTemperature[];
}

export default function ControlTemperature() {
  const toast = useToast();
  const auth = useUserAuth("complaint");
  const [today, setToday] = useState(new Date());
  const [isAdd, setAdd] = useState(true);
  const [isChecked, setIsChecked] = useState("update");
  const [desc, setDesc] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure(); //온도 조절 모달
  const textRef = useRef<HTMLTextAreaElement>(null);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [reqArr, setReqArr] = useState<string[]>([]); //새로운 신청있는 층만 모은 배열 -> checkItems와 비교
  const dayArr = ["일", "월", "화", "수", "목", "금", "토"];
  const isToday = moment(today).format("L") === moment().format("L");
  const { data: floor } = useTemperatureLocation();

  const { data } = useQuery([temperature_list_key, today], () =>
    fetchClient
      .post<IControlTemperature>(temperature_list, {
        date: `${moment(today).format("YYYY-MM-DD")}`,
        // date: "2023-03-17",
      })
      .then((res) => res.data),
  );

  useEffect(() => {
    if (!data || !floor) return;
    const dataArr: string[] = [];

    if (!isToday) {
      setReqArr([]);
      return setAdd(false);
    }

    data.data1.map((el) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { request_type, ...rest } = el._id;
      const obj = floor.filter((el) => JSON.stringify(el) === JSON.stringify(rest)) || [];
      obj.forEach((el) => {
        if (dataArr.includes(`${el.dong}동 ${el.floor}층 / ${el.location}`)) return;
        dataArr.push(`${el.dong}동 ${el.floor}층 / ${el.location}`);
      });
      // for (const keys in rest) {
      //   if (el._id[keys as keyof typeof el._id] === "") newArr.push(keys);
      // }
      // if (!dataArr.includes(el._id.floor)) dataArr.push(el._id.floor);
    });
    // console.log("pass", dataArr);
    setAdd(true);
    setReqArr(dataArr);
  }, [data, floor]);

  const onChangedate = (e: MouseEvent<SVGElement>) => {
    e.stopPropagation();
    const target =
      e.currentTarget.id === "prev"
        ? moment(today).add(-1, "day").format("YYYY-MM-DD")
        : moment(today).add(+1, "day").format("YYYY-MM-DD");

    setToday(new Date(target));
  };

  const onClickToChangeToday = () => {
    if (isToday) return;
    setToday(new Date());
  };

  const onChangeAnswers = (str: string) => {
    if (str !== "direct") {
      setDesc("");
    } else {
      if (textRef.current) textRef.current.style.display = "block";
      textRef.current?.focus();
    }
    setIsChecked(str);
  };

  const format_content = (str: string) => {
    switch (str) {
      case "update":
        return "관리센터에서 상태를 업데이트 했습니다.";
      case "up":
        return "관리센터에서 실내 온도를 높였습니다.";
      case "down":
        return "관리센터에서 실내 온도를 낮췄습니다.";
      default:
        return desc;
    }
  };

  const onCheckedAll = (checked: boolean) => {
    if (checked) {
      const allCheckedArr: string[] = [];
      reqArr.forEach((el) => allCheckedArr.push(el));
      setCheckedItems(allCheckedArr);
    } else {
      setCheckedItems([]);
    }
  };

  const onChangeChecked = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      const newArr = checkedItems.filter((el) => el !== id);
      if (newArr !== undefined) setCheckedItems(newArr);
    }
    if (checkedItems.length === (reqArr.length || 0)) {
      return (checked = true);
    }
  };

  const onChangeGuide = (e: ChangeEvent<HTMLTextAreaElement>) => {
    // if (isChecked !== "direct") setIsChecked("direct");
    const isLimit = limitContent(e.target.value);
    if (!isLimit) return;
    setDesc(e.target.value);

    if (textRef.current === null) return;
    textRef.current.style.height = "24px";
    // const count = e.target.value.split("\n").length;
    const contents = textRef.current?.scrollHeight;
    const defaultHeight = textRef.current?.clientHeight;
    // console.log("컨텐츠 높이 : ",count * 24,"스크롤 :",contents,"textarea 높이 : ",defaultHeight);
    // console.log(textRef.current?.clientWidth, e.target.value.length * 16);

    if (contents >= defaultHeight) textRef.current.style.height = contents + "px";
  };

  const onClickClose = () => {
    setIsChecked("update");
    setDesc("");
    onClose();
  };

  const mutation = useMutation((obj: { dong: string; floor: string; location: string }[]) =>
    fetchClient
      .post<IControlTemperature>(temperature_update, {
        dong_floor_location: obj,
        answer: format_content(isChecked),
      })
      .then(() => {
        queryClient.invalidateQueries([temperature_list_key]);
        toast({
          ...success,
          title: "온도 조절 업데이트 완료",
          description: "온도 조절 업데이트가 완료되었습니다.",
        });

        setCheckedItems([]);
        return onClickClose();
      })
      .catch(() => {
        toast({
          ...warning,
          title: "알림",
          description:
            "일시적으로 온도 조절 업데이트에 실패했습니다. 새로고침 후에도 동일한 오류가 반복될 경우 오피스너 담당자에게 문의해주세요.",
        });
      }),
  );

  const onSaveUpdate = () => {
    if (mutation.isLoading)
      return toast({
        ...warning,
        title: "알림",
        description: "온도 조절 업데이트가 진행중입니다. 잠시만 기다려주세요.",
      });

    const separate = checkedItems.map((el) => {
      const dong = el.split("동 ");
      const floor = dong[1].split("층");
      return {
        dong: dong[0],
        floor: floor[0],
        location: floor[1].split(" / ")[floor[1].split(" / ").length - 1],
      };
    });
    mutation.mutate(separate);
  };

  return (
    <MainContainer>
      <ItemSetBox>
        <Flex>
          <DateWithIconBox>
            <DatePicker
              locale={ko}
              dateFormat="yyyy-MM-dd"
              selected={today}
              onChange={(date: Date) => setToday(date)}
              customInput={
                <T.DateAlignBox>
                  <FiChevronLeft id="prev" onClick={onChangedate} />
                  <Text flex={1} textAlign="center">
                    {moment.utc(today).format("MM.DD")} ({dayArr[today.getDay()]})
                  </Text>
                  {!isToday && <FiChevronRight id="next" onClick={onChangedate} />}
                </T.DateAlignBox>
              }
            />
          </DateWithIconBox>
          <MouseShadowBox onClick={onClickToChangeToday}>오늘</MouseShadowBox>
          <T.NewTemperatureList isAdd={isAdd}>
            <Checkbox variant="default" isChecked={isAdd} onChange={() => setAdd((prev) => !prev)}>
              신규 요청만 보기
            </Checkbox>
          </T.NewTemperatureList>
        </Flex>
        {isToday && auth === "edit" && (
          <Button
            onClick={onOpen}
            variant="m_blue"
            _disabled={{
              opacity: 0.4,
              cursor: "not-allowed",
              _hover: { background: "#2563eb" },
            }}
            isDisabled={checkedItems.length === 0}
          >
            <HiCheck fontSize="x-large" style={{ marginRight: "8px" }} /> 온도 업데이트
          </Button>
        )}
        {isOpen && (
          <ControlTemperture
            isOpen={isOpen}
            onSave={onSaveUpdate}
            onClose={onClickClose}
            isError={mutation.isError}
            checkedItems={checkedItems}
            isChecked={isChecked}
            desc={desc}
          >
            <p>온도 업데이트 시 사용자들에게 제공될 답변을 선택해주세요.</p>
            <T.SelectBox>
              <T.AnswerList isChecked={isChecked === "update"} onClick={() => onChangeAnswers("update")}>
                관리센터에서 상태를 업데이트 했습니다.
                {isChecked === "update" && <HiCheck />}
              </T.AnswerList>
              <T.AnswerList isChecked={isChecked === "up"} onClick={() => onChangeAnswers("up")}>
                관리센터에서 실내 온도를 높였습니다.
                {isChecked === "up" && <HiCheck />}
              </T.AnswerList>
              <T.AnswerList isChecked={isChecked === "down"} onClick={() => onChangeAnswers("down")}>
                관리센터에서 실내 온도를 낮췄습니다.
                {isChecked === "down" && <HiCheck />}
              </T.AnswerList>
              <T.AnswerList isChecked={isChecked === "direct"} onClick={() => onChangeAnswers("direct")}>
                직접 입력 :
                <T.WriteBox>
                  <textarea
                    rows={1}
                    ref={textRef}
                    autoFocus={false}
                    value={desc || ""}
                    onChange={onChangeGuide}
                    style={{ display: "none" }}
                  />
                </T.WriteBox>
                {isChecked === "direct" && <HiCheck />}
              </T.AnswerList>
            </T.SelectBox>
          </ControlTemperture>
        )}
      </ItemSetBox>
      <T.FloorTable>
        <Box p="24px" style={{ width: "10%" }}>
          {moment(today).format("L") === moment.utc(new Date()).format("L") && (
            <Checkbox
              variant="default"
              onChange={(e) => onCheckedAll(e.target.checked)}
              isChecked={
                reqArr.length === 0 && checkedItems.length === 0 ? false : reqArr.length === checkedItems.length
              }
            />
          )}
        </Box>
        <Box p="24px" style={{ width: "20%" }}>
          위치
        </Box>
        <Box flexDirection="column">
          <Box p="8px 0">요청건수</Box>
          <Flex w="100%">
            <T.SubHeader>더워요 (신규 / 누적)</T.SubHeader>
            <T.SubHeader>추워요 (신규 / 누적)</T.SubHeader>
          </Flex>
        </Box>
        <Box flexDirection="column">
          <Box p="8px 0">최근 업데이트</Box>
          <Flex w="100%">
            <T.SubHeader>답변</T.SubHeader>
            <T.SubHeader>시간</T.SubHeader>
          </Flex>
        </Box>
      </T.FloorTable>
      {isAdd && reqArr.length === 0 && <NoHistoryInData>현재 요청받은 내역이 없습니다.</NoHistoryInData>}
      {(isAdd ? reqArr : floor?.map((el) => `${el.dong}동 ${el.floor}층 / ${el.location}`))?.map((el) => (
        <FloorListItem
          el={el}
          key={el}
          checkedItems={checkedItems}
          date={moment(today).format("L")}
          onChangeChecked={onChangeChecked}
          newArr={
            data?.data1.filter((ele) => `${ele._id.dong}동 ${ele._id.floor}층 / ${ele._id.location}` === el) || []
          }
          accArr={
            data?.data2.filter((ele) => `${ele._id.dong}동 ${ele._id.floor}층 / ${ele._id.location}` === el) || []
          }
        />
      ))}

      {/* {(newData ? floor?.filter((el) => newData.includes(el)) : floor)?.map(
    (el) => (
      <FloorListItem
        el={el}
        key={el}
        checkedItems={checkedItems}
        date={moment(today).format("L")}
        onChangeChecked={onChangeChecked}
        newArr={data?.data1.filter((ele) => ele._id.floor === el) || []}
        accArr={data?.data2.filter((ele) => ele._id.floor === el) || []}
      />
    )
  )} */}
    </MainContainer>
  );
}
