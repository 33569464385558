import { MdLock } from "@react-icons/all-files/md/MdLock";

export default function GetAuthorized() {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-center text-xl">
      <div className="mb-8 flex w-full items-center justify-center text-center text-5xl text-gray-300">
        <MdLock />
      </div>
      <div className="flex">해당 기능은 접근 권한이 필요합니다.</div>
      <div className="flex">최고관리자에게 문의해주세요.</div>
    </div>
  );
}
