import { CSSProperties, Dispatch, SetStateAction, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import styled from "@emotion/styled";
import { Box, Flex } from "@chakra-ui/react";
import { DeleteIcon } from "../styled";
import SkeletonImage from "../Skeleton/images";

const ImageBox = styled.div`
  position: relative;
  margin: 0px 12px 8px 0;
`;

const ShowImages = styled.img<{ isLoading?: boolean }>`
  width: ${({ isLoading }) => (isLoading ? "0" : "40px")};
  height: ${({ isLoading }) => (isLoading ? "0" : "40px")};
  object-fit: fill;
`;

interface IPreview {
  fileArr: string[];
  alt?: string;
  auth: string;
  styled?: CSSProperties;
}

interface IOnePreview extends IPreview {
  files: File | null;
  setFiles: Dispatch<SetStateAction<File | null>>;
  setFileArr: Dispatch<SetStateAction<string[] | undefined>>;
}

export const PreviewPicture = (props: IOnePreview) => {
  return (
    <Box position="relative" pb={props.files ? "2" : "0"}>
      {props.files ? (
        <>
          <ShowImages
            src={URL.createObjectURL(props.files)}
            alt={props.alt || "이미지"}
          />
          <DeleteIcon onClick={() => props.setFiles(null)} />
        </>
      ) : (
        <>
          {props.fileArr.length !== 0 && (
            <>
              {props.auth === "edit" ? (
                <>
                  <ShowImages
                    src={props.fileArr[0]}
                    alt={props.alt || "이미지"}
                  />
                  <DeleteIcon onClick={() => props.setFileArr([])} />
                </>
              ) : (
                <a href={props.fileArr[0]} target="_blank" rel="noreferrer">
                  <ShowImages
                    src={props.fileArr[0]}
                    alt={props.alt || "이미지"}
                  />
                </a>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

interface IMultipleView extends IPreview {
  files: File[] | null;
  setFiles: Dispatch<SetStateAction<File[] | null>>;
  setFileArr: Dispatch<SetStateAction<string[]>>;
}

//등록, 수정 이미지 미리보기
export const PreviewImages = (props: IMultipleView) => {
  const [loading, setLoading] = useState(true); //이미지 로딩 대기
  const newArr = [...props.fileArr, ...(props.files || [])];

  const onDeleteFiles = (idx: number, el: File | string) => {
    if (typeof el === "string") {
      const stringArr = props.fileArr.filter((_, index) => index !== idx);
      props.setFileArr(stringArr.length === 0 ? [] : stringArr);
    } else {
      if (props.files === null) return;
      const temp = newArr.filter((_, index) => index !== idx);
      const setArr: any = temp.filter((el) => typeof el !== "string");

      props.setFiles(setArr.length === 0 ? null : setArr);
    }
  };
  // consol.log(loading);
  return (
    <Flex w="100%">
      {newArr?.map((el, index) => (
        <ImageBox
          key={typeof el === "string" ? String(index) : `${index + el.size}`}
          style={{ ...props.styled }}
        >
          {loading && <SkeletonImage />}
          {props.auth === "edit" ? (
            <>
              <ShowImages
                isLoading={loading}
                alt={props.alt || "이미지"}
                src={typeof el !== "string" ? URL.createObjectURL(el) : el}
                onLoad={() => setLoading(false)}
              />
              <DeleteIcon onClick={() => onDeleteFiles(index, el)} />
            </>
          ) : (
            <a
              target="_blank"
              rel="noreferrer"
              href={typeof el !== "string" ? URL.createObjectURL(el) : el}
            >
              <ShowImages
                isLoading={loading}
                alt={props.alt || "이미지"}
                src={typeof el !== "string" ? URL.createObjectURL(el) : el}
                onLoad={() => setLoading(false)}
              />
            </a>
          )}
        </ImageBox>
      ))}
    </Flex>
  );
};
