import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomBadge from "../../../components/CustomBadge";
import Input from "../../../components/Input/Input";
import Pagination from "../../../components/custom/Pagination";
import IconSearch from "../../../components/icons/IconSearch";
import { useCallback, useContext, useRef, useState } from "react";
import { useRequestMemberQuery, useUpdateRequestMemberMutation } from "../../../requests/member";
import toast, { useToaster } from "react-hot-toast";
import CustomToast from "../../../components/CustomToast";
import { CustomMultipleListBox } from "../../../components/CustomListBox";
import { useDebounce } from "../../../hooks/operators/use-debounce";
import Icon from "../../../types/solar-icon";
import { GlobalContext } from "../../../App";
import formatPhoneNumber from "../../../utils/formatPhoneNumber";
import formatDate from "../../../utils/formatDate";
import { getSearchRoomText } from "../../../utils/getSearchRoom";
import useUserAuth from "../../../components/hooks/useUserAuth";

const Status = [
  { name: "소유자", value: "owner" },
  { name: "입주자 (최고관리자)", value: "master" },
  { name: "입주자", value: "normal" },
];

export default function MemberRequestPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { buildInfo } = useContext(GlobalContext);

  const auth = useUserAuth("tenant");
  const isEdit = auth === "edit";

  const params = new URLSearchParams(search);

  const searchParamsObject = {
    buildingId: buildInfo?._id.toString() || "",
    owner: searchParams.get("owner") || "",
    master: searchParams.get("master") || "",
    normal: searchParams.get("normal") || "",
    searchText: searchParams.get("searchText") || "",
    page: Number(searchParams.get("page")) || 1,
    limit: Number(searchParams.get("limit")) || 10,
  };

  const { data } = useRequestMemberQuery(searchParamsObject, !!buildInfo?._id);
  const { mutateAsync: updateRequestMember } = useUpdateRequestMemberMutation();

  const createQueryString = useCallback(
    (key: string, value: string, defaultValue = "") => {
      params.set(key, value);
      if (value === defaultValue) params.delete(key);
      params.delete("page");
      return params.toString();
    },
    [search],
  );

  const createQueryArray = useCallback(
    (value: string[]) => {
      const deleteParamsArr = ["page", "owner", "master", "normal"];
      deleteParamsArr.map((v) => params.delete(v));
      value
        .filter((k) => k)
        .map((v) => {
          params.set(v, "true");
        });
      return params.toString();
    },
    [search],
  );

  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleTextChange = useDebounce(
    (text) => {
      navigate(pathname + "?" + createQueryString("searchText", text, ""));
    },
    300,
    [search],
  );

  return (
    <div className="mb-[60px] w-full">
      <div className="flex h-[60px] items-center justify-between border-b border-gray-200 bg-gray-50 px-6">
        <div className="flex w-[10.5rem] gap-3">
          <CustomMultipleListBox
            data={Status}
            getValue={() =>
              [
                searchParamsObject.owner && "owner",
                searchParamsObject.master && "master",
                searchParamsObject.normal && "normal",
              ].filter((v) => v)
            }
            onChange={(value) => navigate(pathname + "?" + createQueryArray(value))}
            label="신청권한"
          />
        </div>

        <div className="w-[27.75rem]">
          <Input
            ref={searchInputRef}
            themeSize="sm"
            placeholder="이름, 연락처, 입주사명, 호실"
            defaultValue={searchParamsObject.searchText}
            iconElement={<IconSearch className="h-5 w-5" />}
            onChange={(e) => handleTextChange(e.target.value)}
            showDeleteButton={true}
            onDelete={() => {
              if (searchInputRef.current) searchInputRef.current.value = "";
              navigate(pathname + "?" + createQueryString("searchText", ""));
            }}
          />
        </div>
      </div>

      <div className="w-full px-6">
        <table className="w-full table-fixed">
          <thead>
            <tr className="border-b border-gray-200">
              <th className="w-1/5 px-2 py-3 text-left leading-normal">이름</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">연락처</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">신청권한</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">입주사</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">동/호</th>
              <th className="w-1/5 px-2 py-3 text-left leading-normal">신청일시</th>
              {isEdit && <th className="w-1/5 px-2 py-3 text-left leading-normal">승인/반려</th>}
            </tr>
          </thead>
          <tbody>
            {data?.data.certificationRequests.map((item) => {
              return (
                <tr
                  key={item._id}
                  className={
                    "border-y border-gray-200"
                    // transition-all duration-75 cursor-pointer hover:bg-blue-50 hover:opacity-75
                  }
                >
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">{item.name || "이름 없는 구성원"}</td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">
                    {formatPhoneNumber(item.phoneNumber)}
                  </td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">
                    <div className="flex flex-wrap">
                      <CustomBadge type={item.type === "owner" ? "owner" : item.authType} />
                    </div>
                  </td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">
                    {"companyName" in item ? item.companyName : "-"}
                  </td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">
                    {item.type === "owner"
                      ? `${item.ownerRoomInfo.dong}동 ${item.ownerRoomInfo.ho}호`
                      : getSearchRoomText(item.companyRoomInfos, searchParamsObject.searchText) +
                        (item.companyRoomInfos.length > 1 ? ` 외 ${item.companyRoomInfos.length - 1}` : "")}
                  </td>
                  <td className="px-2 py-3 text-sm leading-normal text-gray-900">{formatDate(item.createdAt)}</td>
                  {isEdit && (
                    <td className="px-1 py-1 text-sm leading-normal text-gray-700">
                      <div className="flex h-8 w-[7.5rem] overflow-hidden rounded-md border border-gray-300 shadow-sm">
                        <button
                          className="flex flex-1 items-center justify-center gap-2.5 border-r border-gray-200 bg-white p-2 hover:bg-gray-100 hover:text-gray-800 active:bg-gray-200 active:text-gray-800"
                          onClick={(e) => {
                            e.stopPropagation();

                            toast.promise(
                              updateRequestMember({
                                certifications: [{ certificationId: item._id, status: "approved" }],
                              }),
                              {
                                success: (res) => {
                                  if (res.result.length > 0) {
                                    if (res.result[0].status === 404)
                                      return (
                                        <CustomToast
                                          iconType="error"
                                          title={`${item.name}님이 이미 취소한 상태로 승인 또는 반려할 수 없습니다.`}
                                        />
                                      );
                                    if (res.result[0].status === 405)
                                      return (
                                        <CustomToast
                                          iconType="warning"
                                          title={`해당 입주사에 이미 등록된 최고관리자가 있습니다.`}
                                          descriptionElement={
                                            <span
                                              className="cursor-pointer text-sm font-semibold text-white underline"
                                              onClick={() => {
                                                if ("companyId" in item) {
                                                  navigate(`/companies/${item.companyId}/members`);
                                                  toast.dismiss();
                                                }
                                              }}
                                            >
                                              확인하러 가기
                                            </span>
                                          }
                                        />
                                      );
                                    if (res.result[0].status !== 200)
                                      return <CustomToast iconType="error" title="승인 중 에러가 발생하였습니다." />;
                                  }

                                  return (
                                    <CustomToast
                                      iconType="check"
                                      title={`${item.name}님을 건물의 ${item.type === "owner" ? "소유자" : "입주자"}로 승인했습니다.`}
                                    />
                                  );
                                },
                                error: <CustomToast iconType="error" title="승인 중 에러가 발생하였습니다." />,
                                loading: <CustomToast iconType="loading" title="Loading" />,
                              },
                            );
                          }}
                        >
                          승인
                        </button>
                        <button
                          className="flex flex-1 items-center justify-center gap-2.5 bg-white p-2 hover:bg-gray-100 hover:text-gray-800 active:bg-gray-200 active:text-gray-800"
                          onClick={(e) => {
                            e.stopPropagation();

                            toast.promise(
                              updateRequestMember({
                                certifications: [{ certificationId: item._id, status: "rejected" }],
                              }),
                              {
                                success: (res) => {
                                  if (res.result.length > 0) {
                                    if (res.result[0].status === 404)
                                      return (
                                        <CustomToast
                                          iconType="error"
                                          title={`${item.name}님이 이미 취소한 상태로 승인 또는 반려할 수 없습니다.`}
                                        />
                                      );
                                    if (res.result[0].status !== 200)
                                      return <CustomToast iconType="error" title="반려 중 에러가 발생하였습니다." />;
                                  }
                                  return (
                                    <CustomToast
                                      iconType="warning"
                                      title={`${item.name}님의 인증신청을 반려했습니다.`}
                                      className="w-[23.375rem]"
                                    />
                                  );
                                },
                                error: <CustomToast iconType="error" title="반려 중 에러가 발생하였습니다." />,
                                loading: <CustomToast iconType="loading" title="Loading" />,
                              },
                            );
                          }}
                        >
                          반려
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        {data?.data.certificationRequests.length === 0 ? (
          <div className="flex h-[25rem] flex-col items-center justify-center gap-4 text-gray-700">
            <Icon.DangerCircle size={40} />
            {searchParamsObject.searchText === "" &&
            searchParamsObject.owner === "" &&
            searchParamsObject.master === "" &&
            searchParamsObject.normal === "" ? (
              <span className="text-xl font-medium leading-normal">현재 인증 승인을 신청한 건이 없습니다.</span>
            ) : (
              <>
                <span className="text-xl font-medium leading-normal">검색 결과가 없습니다.</span>
                <span className="font-medium leading-normal text-gray-400">
                  다른 검색어를 시도해 보거나 검색 필터를 삭제하세요.
                </span>
              </>
            )}
          </div>
        ) : (
          <div className="mt-3 flex items-center justify-end gap-3">
            <p className="text-base leading-normal text-gray-500">총 {data?.data.totalCount || 0}개의 결과</p>
            <Pagination
              currentPage={searchParamsObject.page}
              itemCount={data?.data.totalCount || 0}
              showPageAmount={10}
            />
          </div>
        )}
      </div>
    </div>
  );
}
