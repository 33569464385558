import { ModalBody } from "@chakra-ui/react";
import { setCheckWarn, setEndDate, useCheckWarn, useEndDate, useVoteStore } from "../../../hooks/use-vote-store";
import DatePicker from "react-datepicker";
import { forwardRef } from "react";
import IconArrow from "../../../components/icons/IconArrow";
import IconCheckbox from "../../../components/icons/IconCheckbox";
import { useSendVoteMutation } from "../../../requests/vote";
import FullPageSpinner from "../../../components/Spinner";

export default function VotePeriodSet() {
  const data = useVoteStore.getState();
  const checkWarn = useCheckWarn();
  const endDate = useEndDate();

  console.info(data);

  const voteSendMutation = useSendVoteMutation();

  const EndTimeInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <button className="flex w-[230px] items-center justify-between bg-gray-100 p-3" onClick={onClick} ref={ref}>
      <span>{endDate ? value : "투표 마감 일시"}</span>
      <IconArrow />
    </button>
  ));

  const oneDayFromNow = new Date();
  oneDayFromNow.setDate(oneDayFromNow.getDate() + 1);

  return (
    <>
      <ModalBody className="flex" padding={0}>
        {voteSendMutation.isLoading && <FullPageSpinner />}
        <div className="flex h-full w-[634px] flex-col border-r border-gray-200 p-6">
          <p className="font-medium text-gray-600">투표 마감시간을 설정해주세요.</p>
          <div className="mt-4 flex h-12 items-center gap-3 text-[#696F79]">
            <div className="flex w-[230px] items-center bg-gray-100 p-3">투표 발송 직후</div>
            <p>-</p>
            <DatePicker
              selected={endDate || oneDayFromNow}
              onChange={(date) => setEndDate(date)}
              customInput={<EndTimeInput />}
              minDate={oneDayFromNow}
            />
          </div>
          <ul className="mt-5 list-disc pl-6 font-medium text-gray-500">
            <li>투표는 발송 직후부터 참여가 가능하며, 설정된 마감기한까지 참여하실 수 있습니다.</li>
            <li>마감기한은 투표 시작 이후 연장만 가능합니다.</li>
          </ul>
        </div>

        <div className="flex-1 p-6">
          <p className="mb-3 font-semibold text-gray-500">투표 발송 요약</p>
          {endDate ? (
            <div>
              <div className="flex flex-col gap-3">
                <p className="flex font-medium">
                  <span className="w-[90px] text-gray-600">투표 제목</span>
                  <span className="text-gray-300">{data.voteInput.title}</span>
                </p>
                <p className="flex font-medium">
                  <span className="w-[90px] text-gray-600">투표 요청자</span>
                  <span className="text-gray-300">{data.voteInput.senderName}</span>
                </p>
                <p className="flex font-medium">
                  <span className="w-[90px] text-gray-600">추가 연락망</span>
                  <span className="text-gray-300">{data.voteInput.senderContact}</span>
                </p>
                <p className="flex font-medium">
                  <span className="w-[90px] text-gray-600">안건 개수</span>
                  <span className="text-gray-300">{data.voteInput.agendas.length}개</span>
                </p>
              </div>
              <p className="mt-6 font-medium text-gray-400">
                본 투표는 소유자 {data.selectOwners.length}명에게 투표가 발송되며, 발송 직후부터
              </p>
              <p className="font-medium text-gray-400">{endDate.toLocaleDateString()}까지 투표에 참여할 수 있습니다.</p>
              <p className="font-medium text-red-500">
                {`발송 후에는 투표 내용을 수정할 수 없으며, "투표 취소", "대상자 취소"만 가능합니다.`}
              </p>
              <button
                onClick={() => setCheckWarn(!checkWarn)}
                className="mt-4 flex items-center gap-3 rounded-lg bg-blue-50 p-3"
              >
                <IconCheckbox isChecked={checkWarn} />
                <span className="font-bold text-primary">모두 확인하고 투표를 발송합니다.</span>
              </button>
            </div>
          ) : (
            <p className="mt-4 font-medium text-gray-300">투표 마감시간을 먼저 설정해주세요</p>
          )}
        </div>
      </ModalBody>
    </>
  );
}
