import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import CustomHeading from "../../components/CustomHeading";

export default function SettingLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const PAGES = [
    {
      title: "기본설정",
      href: "/settings/info",
    },
    {
      title: "관리팀 설정",
      href: "/settings/manager",
    },
  ];

  return (
    <div>
      <CustomHeading headings="설정" sizeTheme="md" />
      <div className="flex h-12 gap-6 border-b border-gray-200 px-6">
        {PAGES.map((page, index) => (
          <button
            key={index}
            className={twMerge(
              "text-base font-semibold text-gray-400",
              page.href === pathname && "border-b-2 border-gray-900 text-black",
            )}
            onClick={() => navigate(page.href)}
          >
            {page.title}
          </button>
        ))}
      </div>
      <Outlet />
    </div>
  );
}
