import { HTMLAttributes } from "react";

export type Props = object & HTMLAttributes<HTMLOrSVGElement>;

export default function IconSpinner(props: Props) {
  return (
    <svg {...props} width="1428" height="1420" viewBox="0 0 1428 1420" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_839_58"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="245"
        y="241"
        width="938"
        height="938"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M714 1179C973.022 1179 1183 969.022 1183 710C1183 450.978 973.022 241 714 241C454.978 241 245 450.978 245 710C245 969.022 454.978 1179 714 1179ZM714 988C867.535 988 992 863.535 992 710C992 556.465 867.535 432 714 432C560.465 432 436 556.465 436 710C436 863.535 560.465 988 714 988Z"
          fill="#CB202D"
        />
      </mask>
      <g mask="url(#mask0_839_58)">
        <rect
          x="1234.91"
          y="1229.09"
          width="1040"
          height="1040"
          transform="rotate(179.9 1234.91 1229.09)"
          fill="url(#paint0_angular_839_58)"
        />
        <circle cx="712.848" cy="336.502" r="96.5" transform="rotate(179.9 712.848 336.502)" fill="white" />
      </g>
      <defs>
        <radialGradient
          id="paint0_angular_839_58"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1754.91 1749.09) rotate(90) scale(520)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}
