import { useVoteCancelMutation } from "../../../requests/vote";
import CancelModal from "../../../components/modal/CancelModal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  voteId: string;
};

export default function VoteCancelModal({ isOpen, onClose, voteId }: Props) {
  const mutation = useVoteCancelMutation();

  return (
    <CancelModal
      title={"투표 취소"}
      isOpen={isOpen}
      onClose={onClose}
      confirmButtonText="투표 취소"
      onClickSave={() => {
        mutation.mutate(voteId);
        onClose();
      }}
    >
      지금 투표를 취소하면 더 이상 참여자가 투표를 진행할 수 없으며, 취소된 투표는 재진행이 불가합니다. (결과 미공개)
    </CancelModal>
  );
}
