import { ChangeEvent, FC, useRef } from "react";
import * as S from "../../styled";
import { IContractFile } from "../../types";
import { useToast } from "@chakra-ui/react";
import { warning } from "../../../../lib/theme/toast";

export const UploadFile: FC<IContractFile> = (prop) => {
  const { title, required, contract } = prop;
  const { fields, append, remove } = contract;

  const toast = useToast();
  const fileRef = useRef<HTMLInputElement>(null);

  const onChangeContract = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files === null) return;
    if (
      Array.from(files).filter((el) => !["png", "jpeg", "jpg", "gif", "pdf"].includes(el.type.split("/")[1])).length !==
      0
    )
      return toast({
        ...warning,
        title: "파일 업로드 오류",
        description: `첨부 서류 양식은 GIF, JPG, JPEG, PNG, PDF 형식만 가능합니다. 확인 후 다시 시도해주세요.`,
      });

    Array.from(files).map((el) => append({ directory: el, name: el.name }));
    // props.setContract(Array.from(files).map((el) => el));

    e.target.value = "";
  };

  const onRemoveContract = (index: number) => {
    remove(index);
    // if (props.contract === undefined) return;
    // const newArr = Array.from(props.contract).filter((_, idx) => index !== idx);

    // props.setContract(newArr.length === 0 ? undefined : newArr);
  };

  return (
    <S.InputBox>
      <input type="file" multiple={true} ref={fileRef} style={{ display: "none" }} onChange={onChangeContract} />
      <S.DocsStyle>
        {title} {required && <span>*</span>}
      </S.DocsStyle>
      <S.MoreInfoButton onClick={() => fileRef.current?.click()} type="button">
        파일 첨부
      </S.MoreInfoButton>
      <S.SelectedRoomBox>
        {fields.map((el, index) => (
          <S.SelectedRoom key={el.id}>
            <S.EllipsisFile>{el.name}</S.EllipsisFile>
            <S.CancleButton onClick={() => onRemoveContract(index)} />
          </S.SelectedRoom>
        ))}
      </S.SelectedRoomBox>
    </S.InputBox>
  );
};
