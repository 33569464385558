import { Outlet } from "react-router-dom";
import CustomHeading from "../../components/CustomHeading";
import useUserAuth from "../../components/hooks/useUserAuth";
import GetAuthorized from "../../components/Authrized";

export default function MaintenanceFeeLayout() {
  const auth = useUserAuth("management_fee");

  if (auth === "no_auth") return <GetAuthorized />;

  return (
    <div className="w-full">
      <div className="border-b border-gray-200">
        <CustomHeading
          headings={[
            { text: "관리비", path: "/maintenance-fee" },
            { text: "수납은행", path: "/maintenance-fee/bank" },
          ]}
          sizeTheme="md"
        />
      </div>
      <Outlet />
    </div>
  );
}
