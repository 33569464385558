import styled from "@emotion/styled";
import {
  ChangeEvent,
  CSSProperties,
  FC,
  MouseEventHandler,
  PropsWithChildren,
} from "react";
import { colors } from "../styled/common";

interface IRadiusSelect extends PropsWithChildren {
  id?: string;
  value?: string;
  disabled?: boolean;
  defaultValue?: string;
  w?: string;
  _style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLSelectElement> | undefined;
  _onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  className?: string;
}

export const MediaSelect = styled.select<{ w?: string }>`
  -webkit-appearance: none; /* turn off drop-down arrow*/
  -moz-appearance: none;
  appearance: none;
  width: ${({ w }) => (w ? w : "7.5rem")};
  padding: 0.75rem;
  margin-right: 0.5rem;
  border-radius: 0.5rem;
  text-align: center;
  color: ${colors.gray4};
  cursor: pointer;
  background: ${colors.gray1} url("/commons/select.svg") no-repeat 85% center;
  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

//모바일친화 select
const GrayColorSelect: FC<IRadiusSelect> = ({ ...props }) => {
  // console.log(props);
  const { _style, _onChange, children, ...rest } = props;

  return (
    <MediaSelect style={_style} onChange={_onChange} {...rest}>
      {children}
    </MediaSelect>
  );
};

export default GrayColorSelect;
