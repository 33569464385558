import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FC, MouseEvent, PropsWithChildren, createContext } from "react";
import FullPageSpinner from "../../../components/Spinner";
import CreateInfo from "./createInfo";
import { useFetchPublic } from "../commons/queries";
import { IRoomPublic } from "../../../lib/types/Imodels";
import CommonMenuTabframe from "../../../components/Main/MenuTabFrame";

// const facility_format = ["details", "operation", "restricted"];
// const reserved_format = ["user", "status", "items"];
// const reserved_format = ["user", "items"];

interface IContextTimeFixed {
  room_info?: IRoomPublic | undefined;
}

export const RoomPublicContext = createContext<IContextTimeFixed>({});

const Basicframe: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { publicType } = useParams();
  // const kor = format_uri(true, public_type || "");
  const kor = decodeURIComponent(publicType || "");
  const uri = encodeURIComponent(publicType || "");
  const isReserved = location.pathname.includes("booking"); // 시설, 예약 탭
  const { data, isFetching } = useFetchPublic(kor);
  console.log(kor, uri);
  const menuArr = [
    {
      section: "시설정보",
      // path: `/time-type/${uri}`,
      path: `/facility/${uri}`,
      isChecked: !isReserved,
    },
    {
      section: "예약",
      // path: `/time-type/${uri}/booking/${data?._id || "user"}`,
      path: `/facility/${uri}/booking/${data?._id || "user"}`,
      isChecked: isReserved,
    },
  ];

  const tabs = isReserved
    ? [
        {
          tab: "예약자관리",
          // isActive: location.pathname === `/time-type/${uri}/booking/${data?._id || "user"}`,
          isActive: location.pathname === `/facility/${uri}/booking/${data?._id || "user"}`,
        },
        { tab: "예약상품", isActive: location.pathname.includes("items") },
      ]
    : [
        {
          tab: "기본정보",
          // isActive: location.pathname === `/time-type/${uri}`,
          isActive: location.pathname === `/facility/${uri}`,
        },
        { tab: "운영시간", isActive: location.pathname.includes("operation") },
        { tab: "이용제한", isActive: location.pathname.includes("restricted") },
      ];

  const onMovePath = (e: MouseEvent<HTMLLIElement>) => {
    const origin = location.pathname.includes("booking")
      ? // ? `/time-type/${uri}/booking/${data?._id || "user"}`
        `/facility/${uri}/booking/${data?._id || "user"}`
      : // : `/time-type/${uri}`;
        `/facility/${uri}`;

    switch (e.currentTarget.id) {
      case "운영시간":
        return navigate(`${origin}/operation`);
      case "이용제한":
        return navigate(`${origin}/restricted`);
      // case "status":
      //   return navigate(`${origin}/status`);
      case "예약상품":
        return navigate(`${origin}/items`);
      default:
        return navigate(`${origin}`);
    }
  };

  // const clonedElement = Children.map(children, (child) => {
  //   if (isValidElement(child)) return renderItem(child, { data });
  // });

  if (isFetching) return <FullPageSpinner />;
  return (
    <RoomPublicContext.Provider value={{ room_info: data }}>
      <CommonMenuTabframe menuTabs={menuArr} tabs={tabs} onClickMovePage={onMovePath}>
        {data ? (
          <>
            {!isReserved ? (
              <>{children}</> //시설 관련 정보 보여주기
            ) : (
              <>
                {data.reservation_product_ids && data.reservation_product_ids.length !== 0 ? (
                  <>{children}</>
                ) : (
                  <CreateInfo btn="예약상품" desc="사용할 상품을" />
                )}
              </>
            )}
          </>
        ) : (
          <CreateInfo btn="시설" desc="이용하기 전 시설을" />
        )}
      </CommonMenuTabframe>
    </RoomPublicContext.Provider>
  );
};

export default Basicframe;
