import { AxiosError } from "axios";
import { queryClient } from "..";
import { notice_list_key } from "../lib/api/queries/keys";
import { success, warning } from "../lib/theme/toast";
import { useToast } from "@chakra-ui/react";
import { http } from "../lib/http";
import { useMutation } from "@tanstack/react-query";

export const useDeleteNoticeMutation = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: (noticeID: string[]) => {
      return http.post("/building/notice/delete", { notice_ids: noticeID });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([notice_list_key]);
      toast({
        ...success,
        title: "게시글 삭제 완료",
        description: "공지사항 게시글이 삭제되었습니다.",
      });
    },
    onError: (err: AxiosError) => {
      console.error(err);
      toast({
        ...warning,
        title: "게시글 삭제 실패",
        description: "공지사항 게시글 삭제에 실패했습니다. 새로고침 후 다시 시도해주세요.",
      });
    },
  });
};
