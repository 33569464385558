import { twMerge } from "tailwind-merge";
import Icon from "../types/solar-icon";
import { HTMLAttributes, ReactElement } from "react";
import IconSpinner from "./icons/IconSpinner";

type Props = {
  maxWidth?: number;
  iconType?: "check" | "info" | "error" | "warning" | "loading";
  theme?: "description" | "list" | "actions" | "link on right" | "accent border" | "dismiss button";
  title?: string;
  description?: string;
  descriptionElement?: ReactElement;
  button1Text?: string;
  button1OnClick?: () => void;
  button2Text?: string;
  button2OnClick?: () => void;
  sideButton?: ReactElement;
} & HTMLAttributes<HTMLElement>;

export default function CustomToast({
  iconType = "info",
  title,
  description,
  descriptionElement,
  button1Text,
  button1OnClick,
  button2Text,
  button2OnClick,
  sideButton,
  ...props
}: Props) {
  const iconElement = {
    check: <Icon.CheckCircle iconStyle="Bold" size={20} color="#4ADE80" />,
    info: <Icon.InfoCircle iconStyle="Bold" size={20} color="#60A5FA" />,
    error: <Icon.CloseCircle iconStyle="Bold" size={20} color="#F87171" />,
    warning: <Icon.DangerTriangle iconStyle="Bold" size={20} color="#FACC15" />,
    loading: <IconSpinner className="h-5 w-5 animate-spin" />,
  }[iconType];

  const toastClass = twMerge("flex items-start gap-3 z-50", props.className);

  return (
    <div className={toastClass}>
      {iconElement}
      <div className="flex flex-1 flex-col items-start gap-2.5">
        <div className="flex w-full items-start justify-between space-x-2.5">
          <div className="flex flex-col items-start gap-2 text-white">
            {title && <span className="text-sm font-medium">{title}</span>}
            {description && <span className="text-sm font-normal text-white">{description}</span>}
            {descriptionElement && descriptionElement}
          </div>
          {sideButton && sideButton}
        </div>

        {button1Text && (
          <div className="flex items-start gap-3.5">
            <button
              className="flex items-center justify-center py-1.5 pr-2 text-sm font-semibold text-white underline underline-offset-4"
              onClick={button1OnClick}
            >
              {button1Text}
            </button>
            {button2Text && (
              <button
                className="flex items-center justify-center px-2 py-1.5 text-sm font-semibold text-white underline underline-offset-4"
                onClick={button2OnClick}
              >
                {button2Text}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
