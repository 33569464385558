import { memo, useState } from "react";
import { ITenantRegularPoint } from "../../../../../lib/types/Imodels";
import { RentalInput, SkySquare, Square, TableBodyBox, TableBox } from "../../../../maintenance-fee/commons/styled";

const ManagePointItem = ({
  el,
  idx,
  onChangePoint,
}: {
  onChangePoint: (el: ITenantRegularPoint, str: string) => void;
  el: ITenantRegularPoint;
  idx: number;
}) => {
  const [price, setPrice] = useState<number>();

  return (
    <TableBodyBox>
      <TableBox style={{ width: "7%", textAlign: "center" }}>{idx}</TableBox>
      <Square w="30%">{el.tenant_name}</Square>
      <Square w="33%">
        {el.dong_ho.length === 0
          ? ""
          : `${el.dong_ho[0].dong}동 ${el.dong_ho[0].ho}호 ${
              el.dong_ho.length > 1 ? `외 ${el.dong_ho.length - 1}개` : ""
            }`}
      </Square>
      <SkySquare w="30%" p={true} style={{ textAlign: "right" }}>
        <RentalInput
          value={String(el.point !== undefined ? el.point : price || "")}
          onChange={(e) => {
            if (Number.isNaN(Number(e.target.value))) return;
            setPrice(Number(e.target.value));
            onChangePoint(el, e.target.value);
          }}
        />
      </SkySquare>
    </TableBodyBox>
  );
};

export const MemoizedPointItem = memo(ManagePointItem);
