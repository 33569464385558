import ConfirmModal from "../../../components/modal/ConfirmModal";
import IconUser from "../../../components/icons/IconUser";
import IconPhone from "../../../components/icons/IconPhone";
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "../../../components/Input/Input";
import { useVoteDelegateMutation } from "../../../requests/vote";
import { useToast } from "@chakra-ui/react";

type Props = {
  voteId: string;
  signaturesId: string;
  isDelegateOpen: boolean;
  onDelegateClose: () => void;
};

type Inputs = {
  name: string;
  phone: string;
};

export default function VoteDelegateModal({ voteId, signaturesId, isDelegateOpen, onDelegateClose }: Props) {
  const toast = useToast();
  const { register, handleSubmit, setValue, reset } = useForm<Inputs>();
  const { mutateAsync: delegateVote } = useVoteDelegateMutation();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    toast.promise(
      delegateVote({ voteId: voteId, signaturesId: signaturesId, name: data.name, phoneNumber: data.phone }),
      {
        success: { position: "top", title: `${data.name}님에게 위임되었습니다.` },
        error: { position: "top", title: "위임 동작에 실패하였습니다" },
        loading: { position: "top", title: `${data.name}님에게 위임 중입니다.` },
      },
    );
  };

  return (
    <ConfirmModal
      title={"투표 위임"}
      isOpen={isDelegateOpen}
      onCloseComplete={() => {
        reset();
      }}
      onClose={() => {
        onDelegateClose();
      }}
      width={488}
      size={"lg"}
      onClickSave={() => {
        handleSubmit(onSubmit)();
        onDelegateClose();
      }}
      close="위임하기"
    >
      <p className="font-semibold text-gray-500">
        수임자 정보 <span className="text-blue-600">*</span>
      </p>
      <p className="mt-3 text-gray-400">
        위임 받으시는 분의 성함과 문자 수신이 가능한 연락처를 입력해주세요.
        <br /> 위임과 동시에 수임자에게 투표가 즉시 발송됩니다.
      </p>
      <div className="mt-4 flex flex-col gap-2">
        <Input
          {...register("name", {
            required: "이름은 필수 입력 사항입니다.",
            maxLength: { value: 10, message: "이름은 10글자 이내로 입력해주세요." },
          })}
          iconElement={<IconUser />}
          placeholder="이름을 입력해주세요."
          showDeleteButton={true}
          onBlur={(e) => setValue("name", e.target.value.trim())}
          onDelete={() => setValue("name", "", { shouldValidate: true })}
        />
        <Input
          {...register("phone", {
            required: "전화번호는 필수 입력 사항입니다.",
            pattern: { value: /^[0-9]+$/, message: "전화번호는 숫자만 입력 가능합니다." },
          })}
          type="number"
          iconElement={<IconPhone />}
          placeholder="전화번호를 입력해주세요."
          showDeleteButton={true}
          onBlur={(e) => setValue("phone", e.target.value.trim())}
          onDelete={() => setValue("phone", "", { shouldValidate: true })}
        />
      </div>
    </ConfirmModal>
  );
}
