import { HTMLAttributes } from "react";

export type Props = object & HTMLAttributes<HTMLOrSVGElement>;

export default function IconMail({ className, ...props }: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.64233 4.30968C1.66602 5.286 1.66602 6.85734 1.66602 10C1.66602 13.1427 1.66602 14.7141 2.64233 15.6904C3.61864 16.6667 5.18999 16.6667 8.33268 16.6667H11.666C14.8087 16.6667 16.3801 16.6667 17.3564 15.6904C18.3327 14.7141 18.3327 13.1427 18.3327 10C18.3327 6.85734 18.3327 5.286 17.3564 4.30968C16.3801 3.33337 14.8087 3.33337 11.666 3.33337H8.33268C5.18999 3.33337 3.61864 3.33337 2.64233 4.30968ZM15.4795 6.26659C15.7005 6.53176 15.6646 6.92587 15.3995 7.14685L13.5691 8.67218C12.8304 9.28773 12.2318 9.78664 11.7034 10.1265C11.153 10.4805 10.6169 10.7041 9.99935 10.7041C9.38178 10.7041 8.84574 10.4805 8.29533 10.1265C7.76695 9.78664 7.16828 9.28773 6.42965 8.67219L4.59923 7.14685C4.33406 6.92587 4.29823 6.53176 4.51921 6.26659C4.74019 6.00142 5.13429 5.96559 5.39946 6.18657L7.19854 7.6858C7.97601 8.33369 8.51579 8.78205 8.9715 9.07515C9.41263 9.35887 9.71179 9.4541 9.99935 9.4541C10.2869 9.4541 10.5861 9.35887 11.0272 9.07515C11.4829 8.78205 12.0227 8.33369 12.8002 7.6858L14.5992 6.18657C14.8644 5.96559 15.2585 6.00142 15.4795 6.26659Z"
        fill="white"
      />
    </svg>
  );
}
