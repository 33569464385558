import { memo, useEffect, useMemo, useRef, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";
import { FileFormat } from "../../../../lib/utils/format";
import { useToast } from "@chakra-ui/react";
import { warning } from "../../../../lib/theme/toast";
import { fetchClient } from "../../../../lib/api/axios";
import { upload_file } from "../../../../lib/api/queries/urls";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import ImageResize from "@looop/quill-image-resize-module-react";

declare module "react-quill";
// props 타입정의
type QuillEditorProps = {
  onChangeMyEditor?: (value: string) => void;
  data: string | undefined;
  isEdit: boolean;
  methods: UseFormReturn<FieldValues, object>;
};

Quill.register("modules/ImageResize", ImageResize);

const QuillEditor = memo(({ data, isEdit, methods }: QuillEditorProps) => {
  // const methods = useForm({ mode: "onChange" });
  const toast = useToast();
  const quillRef = useRef<ReactQuill>(null);
  const [quill, setQuill] = useState("");

  useEffect(() => {
    if (!data || !isEdit) return;

    setQuill(data || "");
  }, [data, isEdit]);

  const checkCharacterCount = (e: KeyboardEvent) => {
    const content = document.querySelector(".ql-editor")?.textContent;
    if ((content?.length || 0) + 1 > 5000 && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  const onChangeMyEditor = (value: string) => {
    // const anchor_start = `&lt;`;
    // const anchor_end = `&gt;`;s
    // const anchor = value.replace(
    //   "<img src=",
    //   `<img onclick=${onClickOpenWindow} src=`
    // );
    methods.setValue("body", value === "<p><br></p>" ? "" : value);
    // console.log("----->결과물", value);
    methods.trigger("body"); // register로 등록하지 않고, 강제로 값을 넣어주는 기능
  };

  const handleImage = async () => {
    // const { getEditor } = quillRef?.current;
    // const range = quillRef.current?.getEditorSelection()?.index;

    // 이미지 업로드 갯수 제한
    // console.log(methods.getValues().body.split("<img"));
    // if (methods.getValues().body.split("<img").length - 1 >= 1)
    //   return alert("1개이상");
    const range = quillRef.current?.getEditorSelection();

    quillRef?.current?.getEditor().clipboard.dangerouslyPasteHTML(Number(range?.index), `${quill}`);
    // .clipboard.dangerouslyPasteHTML(0, `${quill}`);

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      // 이미지 핸들 로직
      const editorFile = input.files;
      const formData = new FormData();

      if (editorFile !== null) {
        Array.from(editorFile).map((el: File) => {
          const newArr = el.name.split(".");
          const temp = newArr[newArr.length - 1].toLowerCase();

          if (!FileFormat.includes(temp))
            return toast({
              ...warning,
              title: "알림",
              description: "파일은 GIF, JPEG, JPG, PNG와 같은 이미지 형식으로 등록 가능합니다.",
            });

          formData.append("body_files", el);
        });
      }

      if (formData.has("body_files")) {
        const result = await fetchClient
          .post<BaseResponse<string[]>>(upload_file, formData)
          .then((res) => res.data.data)
          .catch((err) => console.log(err.message));

        if (range !== null && range !== undefined) {
          const quill = quillRef.current?.getEditor();
          quill?.setSelection(range.index, range.length);

          quill?.clipboard.dangerouslyPasteHTML(
            range.index,
            // `<a href=${result} target="_blank" rel="noreferrer noopener">
            //   <img src=${result} alt="이미지 태그가 삽입됩니다." />
            // </a>`
            `<img src=${result} alt="이미지 태그가 삽입됩니다." />`,
          );

          // quill?.insertEmbed(range.index + 1, "block", "<p><br></p>");
        }
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        // 툴바에 넣을 기능
        container: [
          ["bold", "underline", "strike", "blockquote", "clean"],
          [{ color: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"],
        ],
        handlers: {
          image: handleImage,
        },
      },
      ImageResize: { modules: ["Resize"] },
    }),
    [],
  );

  //"italic", { size: ["small", false, "large", "huge"] }, 들여쓰기 내여쓰기 취소, 정렬 취소

  return (
    <>
      <ReactQuill
        ref={quillRef}
        // formats={["image"]}
        value={quill}
        onKeyPress={checkCharacterCount}
        onChange={onChangeMyEditor}
        modules={modules}
        theme="snow"
        style={{
          width: "100%",
          height: "85%",
          marginBottom: "6%",
          wordBreak: "break-all", // 길이가 긴 단어를 강제로 줄바꿈 처리
        }} // style
      />
    </>
  );
});

export default QuillEditor;
