import { Input } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { colors } from "../styled/common";

export const InputStyle = styled(Input)`
  background-color: #fff;
  color: ${colors.gray4};
  margin-right: 8px;
  border-radius: 0;
  border: 1px solid ${colors.gray3};
  padding: 8px 12px;
  -webkit-padding-after: 9px;
  -ms-padding-after: 9px;
  -moz-padding-after: 9px;
  ::placeholder {
    color: ${colors.gray2};
  }
`;
