import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { useDisclosure } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import FullPageSpinner from "../../../../components/Spinner";
import {
  MainContainer,
  UpdateIconBox,
  MainActionBox,
  BoxInDetail,
  ShadowBoxInDetail,
} from "../../../../components/styled/webApp";
import { getTime } from "../../../../lib/utils/format";
import { isCheckedOperateTime } from "../../commons/func";
import { useMutatePublicRoom } from "../../commons/queries";
import { SavedDetails } from "../../commons/styled";
import { IOperateInfo } from "../../commons/types";
import Basicframe, { RoomPublicContext } from "../../component/basicframe";
import EditModalNoHeader from "../../../../components/modal/responsive/edit";
import { OperationTime } from "./OperationItem";
import SavedCloseDays from "./savedDays";
import { toast } from "../../../../lib/utils/toast";

const FacilityOperation = () => {
  const { room_info: data } = useContext(RoomPublicContext);
  const { updatePublicRoom } = useMutatePublicRoom();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [timeObj, setTimeObj] = useState<IOperateInfo>({
    start_time_weekday: "",
    end_time_weekday: "",
  });

  useEffect(() => {
    if (!data) return;
    const time: IOperateInfo = {
      start_time_weekday: getTime(data.start_time_weekday) || "00:00",
      end_time_weekday: String(data.end_time_weekday).includes("23:59")
        ? "24:00"
        : getTime(data.end_time_weekday) || "00:00",
    };

    time.start_time_holiday = data.start_time_holiday ? getTime(data.start_time_holiday) : "none";
    time.end_time_holiday = data.end_time_holiday
      ? String(data.end_time_holiday).includes("23:59")
        ? "24:00"
        : getTime(data.end_time_holiday) || "00:00"
      : "none";

    setTimeObj(time);
  }, [data]);

  const onBeforeUpdate = async () => {
    const errors = isCheckedOperateTime(timeObj);

    switch (errors) {
      case "시간 설정 오류":
        return toast.warning({
          title: "알림",
          description: "평일 혹은 주말 운영 시간을 정확히 설정해주세요.",
        });
      case "24시간 운영 오류":
        return toast.warning({
          title: "알림",
          description: "운영 시간을 24시간으로 하려면 종료 시간을 24:00으로 선택해주세요.",
        });
      // default:
      //   return mutation.mutate()
    }

    const time = {
      room_public_id: String(data?._id),
      start_time_weekday: `2000-01-01T${timeObj.start_time_weekday}:00Z`,
      end_time_weekday: `2000-01-01T${timeObj.end_time_weekday === "24:00" ? "23:59" : timeObj.end_time_weekday}:00Z`,
      start_time_holiday: timeObj.start_time_holiday === "none" ? "" : `2000-01-01T${timeObj.start_time_holiday}Z`,
      end_time_holiday:
        timeObj.end_time_holiday === "none"
          ? ""
          : `2000-01-01T${timeObj.end_time_holiday === "24:00" ? "23:59" : timeObj.end_time_holiday}Z`,
    };

    try {
      await updatePublicRoom.mutateAsync(time);
      // isOpen
      //   ? await updatePublicRoom.mutateAsync(time)
      //   : await updatePublicRoom.mutateAsync(holiday);

      toast.success({
        title: "시설 정보 변경",
        description: `해당 시설 정보가 변경되었습니다.`,
      });
    } catch {
      return toast.warning({
        title: "시설 정보 수정 오류",
        description: "시설 수정에 일시적으로 실패했습니다. 잠시 후 다시 시도해주세요.",
      });
    }

    onClose();
    // isOpen ? onClose() : offDay();
    // isOpen ? mutation.mutate(time) : mutation.mutate(holiday);
  };

  const onClickToInitial = () => {
    if (!data) return;
    const time: IOperateInfo = {
      start_time_weekday: getTime(data.start_time_weekday) || "00:00",
      end_time_weekday: String(data.end_time_weekday).includes("23:59")
        ? "24:00"
        : getTime(data.end_time_weekday) || "00:00",
    };

    time.start_time_holiday = data.start_time_holiday ? getTime(data.start_time_holiday) : "none";
    time.end_time_holiday = data.end_time_holiday
      ? String(data.end_time_holiday).includes("23:59")
        ? "24:00"
        : getTime(data.end_time_holiday) || "00:00"
      : "none";

    setTimeObj(time);
    onClose();
  };

  if (!data) return <FullPageSpinner />;
  return (
    <MainContainer>
      <MainActionBox>
        <ShadowBoxInDetail>
          <BoxInDetail>
            <h4>영업시간</h4>
            <UpdateIconBox onClick={onOpen}>
              수정하기 <FiChevronRight />
            </UpdateIconBox>
          </BoxInDetail>
          <SavedDetails style={{ paddingTop: 0 }}>
            <div className="section">평일</div>
            <div>
              {getTime(data.start_time_weekday)} -{" "}
              {String(data.end_time_weekday).includes("23:59") ? "24:00" : getTime(data.end_time_weekday)}
            </div>
          </SavedDetails>
          <SavedDetails>
            <div className="section">주말</div>
            {data.start_time_holiday && data.end_time_holiday ? (
              <div>
                {getTime(data.start_time_holiday)} -{" "}
                {String(data.end_time_holiday).includes("23:59") ? "24:00" : getTime(data.end_time_holiday)}
              </div>
            ) : (
              <div>미운영</div>
            )}
          </SavedDetails>
          <EditModalNoHeader isOpen={isOpen} onClose={onClickToInitial} onClickUpdate={onBeforeUpdate}>
            <OperationTime isModal={true} timeObj={timeObj} setTimeObj={setTimeObj} />
          </EditModalNoHeader>
        </ShadowBoxInDetail>
        <SavedCloseDays isPublic={true} data={data} />
        {/* <ShadowBoxInDetail>
          <BoxInDetail>
            <h4>휴무일</h4>
            <div onClick={onDayOff}>
              수정하기 <ChevronRightIcon />
            </div>
          </BoxInDetail>
          {!data.regular_holiday_weeks?.length &&
          !data.regular_holiday_days?.length &&
          !data.holidays?.length ? (
            <SavedDetails style={{ paddingTop: 0, borderBottom: "none" }}>
              <div>휴무일이 없습니다.</div>
            </SavedDetails>
          ) : (
            <>
              <SavedDetails style={{ paddingTop: 0 }}>
                <div className="section">정기휴무일</div>
                <div>
                  {data.regular_holiday_weeks?.length === 5 ? (
                    "매주"
                  ) : (
                    <>
                      매달{" "}
                      {data.regular_holiday_weeks
                        ?.sort()
                        .map((el, idx) =>
                          idx === (data.regular_holiday_weeks?.length || 1) - 1
                            ? `${weeklyArr[el]} `
                            : `${weeklyArr[el]}, `
                        )}
                    </>
                  )}
                  {newArr.map((el, idx) =>
                    idx === newArr.length - 1
                      ? `${dayArr[el]}요일`
                      : `${dayArr[el]}요일, `
                  )}
                </div>
              </SavedDetails>
              {data.holidays && data.holidays.length !== 0 && (
                <SavedDetails>
                  <div className="section">법정공휴일</div>
                  <div>
                    {data.holidays.map((el, idx) =>
                      el.includes("-1") &&
                      idx === (data.holidays || []).length - 1
                        ? el.replace("-1", " 전날")
                        : el.includes("-1")
                        ? el.replace("-1", " 전날, ")
                        : el.includes("+1") &&
                          idx === (data.holidays || []).length - 1
                        ? el.replace("+1", " 다음날")
                        : el.includes("+1")
                        ? el.replace("+1", " 다음날, ")
                        : idx === (data.holidays || []).length - 1
                        ? el
                        : `${el}, `
                    )}
                  </div>
                </SavedDetails>
              )}
            </>
          )}
          <EditModalNoHeader
            isOpen={isDayOff}
            onClose={offDay}
            onClickUpdate={onBeforeUpdate}
          >
            <ClosedDays isModal={true} {...dayOff} />
          </EditModal>
        </ShadowBoxInDetail> */}
      </MainActionBox>
    </MainContainer>
  );
};

export default function FixedFacilityOperation() {
  return (
    <Basicframe>
      <FacilityOperation />
    </Basicframe>
  );
}
