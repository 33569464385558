import { useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../lib/api/axios";
import { BaseResponse } from "../../lib/api/queries/commons/types";
import { ISchedule } from "../../lib/types/Imodels";
import { schedule_list } from "../../lib/api/queries/urls";
import { useState } from "react";
import { schedule_list_key } from "../../lib/api/queries/keys";
import moment from "moment";
import PublicReservationUI from "./_view/presenter";
import { usePagination } from "@ajna/pagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import useUserAuth from "../../components/hooks/useUserAuth";
import FullPageSpinner from "../../components/Spinner";
import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";
import { unAuthUser } from "../../lib/theme/toast";
import PaginationUI from "../../components/pagination";

export default function PublicReservation() {
  const toast = useToast();
  const navigate = useNavigate();
  const auth = useUserAuth("room_public");
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = Number(searchParams.get("page") || 1);
  const { setCurrentPage } = usePagination({
    initialState: { currentPage },
  });
  const dongArr = searchParams.getAll("dong") || [];
  const floorArr = searchParams.getAll("floor") || [];
  const status = searchParams.get("status") || "";
  const search = searchParams.get("search") || "";
  const checklocation = { dongArr, floorArr }; //시설 위치 선택 체크박스 -> 공통 요소로 이동
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [allPage, setAllpage] = useState(0);
  const [total, setTotal] = useState(0);

  const initialParams = {
    page: String(currentPage),
    dong: dongArr,
    floor: floorArr,
    search,
    status,
    start_date: dateRange[0] ? moment(dateRange[0]).format("YYYY-MM-DD") : "",
    end_date: dateRange[1] ? moment(dateRange[1]).format("YYYY-MM-DD") : "",
  };

  const onUpdateParams = (value: string, str: string) => {
    setSearchParams({ ...initialParams, ...{ [value]: str } });
  };

  const { data, refetch } = useQuery(
    [schedule_list_key, currentPage],
    () =>
      fetchClient
        .get<BaseResponse<ISchedule[]>>(schedule_list, {
          params: {
            is_reservation_only: false,
            start_date: dateRange[0] ? moment(dateRange[0]).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
            // : searchParams.get("start_date"),
            end_date: dateRange[1]
              ? moment(dateRange[1]).format("YYYY-MM-DD")
              : moment().add(1, "months").format("YYYY-MM-DD"),
            check_status: status,
            search_name: search,
            dong_list: dongArr,
            floor_list: floorArr,
            limit: 10,
            page: currentPage,
          },
        })
        .then((res) => {
          setAllpage(res.data.total_page_num || 0);
          setTotal(res.data.total_num);
          return res.data.data;
        }),
    {
      enabled: auth === "read" || auth === "edit",
      // keepPreviousData: true,
      onError: (err: AxiosError) => {
        if (err.response?.status === 401) {
          toast({
            ...unAuthUser,
            description: "공용시설에 대한 권한이 없습니다. 대시보드 현황 페이지로 이동합니다.",
          });
          navigate("/dashboard");
        }
      },
    },
  );

  const onClickSubmit = () => {
    setSearchParams({ ...initialParams, ...{ page: String(1) } });
    currentPage === 1 ? refetch() : setCurrentPage(1);
  };

  if (auth === undefined) return <FullPageSpinner />;

  return (
    <div className="flex min-h-full w-full flex-col rounded-2xl bg-white px-6 py-4">
      <PublicReservationUI
        data={data}
        searchParams={searchParams}
        onUpdateParams={onUpdateParams}
        dateRange={dateRange}
        setDateRange={setDateRange}
        onClickSubmit={onClickSubmit}
        refetch={refetch}
        total={total}
        {...checklocation}
      />
      <PaginationUI
        page={currentPage}
        onChangeFunc={(page) =>
          setSearchParams({
            ...initialParams,
            ...{ page: String(page) },
          })
        }
        setPage={setCurrentPage}
        allPage={allPage}
      />
    </div>
  );
}
