import * as R from "../../commons/styled";
import { ICloumnObj, ITenantFeeDetail } from "../../../../lib/types/Imodels";
import { memo, useState } from "react";
import { NewInputColumn } from "./InputCloumnItem";
import { Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";

interface IRentalList {
  el: ITenantFeeDetail;
  // el: ICloumnObj;
  method: ICloumnObj[];
  idx: number;
  // setTotal: Dispatch<SetStateAction<string>>;
  onChangeCharge: (el: ITenantFeeDetail, newItem: ICloumnObj[]) => void;
}

const TenantBox = styled(R.Square)`
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MaintenanceItem = (props: IRentalList) => {
  // const { inputs, setInputs } = useContext(BillContext);
  const [items, setItems] = useState<ICloumnObj[]>(props.el.items);
  // console.log("items", props.el.items);
  const onChangeItems = (id: ICloumnObj[], obj: ICloumnObj) => {
    // id.value = value;
    // const newarr = props.el.items.map((el) =>
    //   el.key === id.key ? { ...el, value: value } : el
    // );
    const newArr = id.map((el) =>
      el.key === obj.key ? { ...el, value: obj.value } : el
    );
    // console.log(props.el.items, newarr);
    setItems(newArr);
    // const price = newarr
    //   .map((el) => el.value)
    //   .reduce((cur, acc) => Number(cur || 0) + Number(acc || 0), 0);
    // console.log(newarr.map((el) => el.value));

    // if (newarr.filter((el) => el.key === "납기내").length === 0) {
    //   newarr.push({ key: "납기내", value: String(price) });
    // } else {
    //   newarr[29].value = String(price);
    // }
    // setItems(newarr);
    props.onChangeCharge(props.el, newArr);
  };

  return (
    // <R.SkySquare p={true} key={uuidv4()}>
    //   <R.RentalInput
    //     // value={props.ele?.value || price}
    //     defaultValue={props.el?.value}
    //     onChange={(e) => {
    //       // setPrice(e.target.value);
    //       // console.log(props.idx);
    //       props.onChangeCharge(props.el, String(props.idx), e.target.value);
    //     }}
    //   />
    // </R.SkySquare>
    <div>
      <div>
        <Flex>
          <R.BillStickyBox w={true}>
            <R.Square isManage={true} w="8%" style={{ padding: "12px 4px" }}>
              {props.idx}
            </R.Square>
            <R.Square isManage={true} w="25%">
              {props.el.dong}동 {props.el.ho}호
            </R.Square>
            <TenantBox isManage={true} w="25%">
              {props.el.tenant_name || ""}
            </TenantBox>
            <R.Square isManage={true} w="22%">
              {props.el.tenant_registration_number || ""}
            </R.Square>
            <R.CostSquare w="20%" style={{ textAlign: "right" }}>
              {/* {props.el.items
            .reduce((cur, acc) => cur.value + acc.value, { key:'', value: ''})
            .toLocaleString("kr")} */}
              {Math.ceil(
                props.el.price === undefined
                  ? Number(
                      props.el.items.find((el) => el.key === "납기내")?.value ||
                        0
                    )
                  : props.el.price
              ).toLocaleString("kr")}
              {/* {props.el.items
            .map((el) => el.value)
            .reduce((cur, acc) => Number(cur || 0) + Number(acc || 0), 0)} */}
            </R.CostSquare>
          </R.BillStickyBox>
          <R.LetterDetails>
            {props.el.items
              .filter((el) => el.key !== "납기내")
              .map((el) => (
                // el.key !== "납기내"
                // <R.SkySquare p={true} key={uuidv4()}>
                //   <R.RentalInput
                //     value={el.value || price}
                //     id={el.key}
                //     defaultValue={el.value || ""}
                //     onChange={(e) => {
                //       setPrice(e.target.value);
                //       setItems(
                //         items.map((el) =>
                //           el.key === e.target.id
                //             ? { ...el, value: e.target.value }
                //             : el
                //         )
                //       );
                //       // props.onChangeCharge(props.el, String(index), e.target.value);
                //     }}
                //   />
                // </R.SkySquare>
                <NewInputColumn
                  key={el.key}
                  ele={el}
                  items={items}
                  // onChangeCharge={props.onChangeCharge}
                  onChangeItems={onChangeItems}
                  // setItems={setItems}
                />
              ))}
          </R.LetterDetails>
        </Flex>
      </div>
    </div>
  );
};

export const MemoizedItem = memo(MaintenanceItem);
