import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useRoomType } from "../../../lib/utils";
import NewSurveyPageUI from "./_view/presenter";
import { v4 as uuidv4 } from "uuid";
import { useToast } from "@chakra-ui/react";
import { checkFileValidate, success, unAuthUser, warning } from "../../../lib/theme/toast";
import { checkFileValidation } from "../../../lib/utils/format";
import { fetchClient } from "../../../lib/api/axios";
import { useNavigate, useParams } from "react-router-dom";
import { survey_create, survey_update } from "../../../lib/api/queries/urls";
import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import FullPageSpinner from "../../../components/Spinner";
import GetAuthorized from "../../../components/Authrized";
import useUserAuth from "../../../components/hooks/useUserAuth";
import moment from "moment";
import { BaseMutation } from "../../../lib/api/queries/commons/types";
import { ISurvey, ISurveyQuestion } from "../../../lib/types/Imodels";
import { INewSurvey } from "./types";

export default function NewSurveyPage(Iprops: BaseMutation<ISurvey>) {
  const toast = useToast();
  const navigate = useNavigate();
  const { surveyId } = useParams();
  const auth = useUserAuth("survey");
  const room_type_arr = useRoomType().filter((el) => el.label !== "owner");
  const onlyOffice = room_type_arr.length <= 1;

  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [target, setTarget] = useState("all");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isDirect, setIsDirect] = useState(false);
  const [surveyTitle, setSurveyTitle] = useState("");
  const [surveyBody, setSurveyBody] = useState("");
  const fileRef = useRef<HTMLInputElement>(null);
  const [imgBase, setImgBase] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const initial = uuidv4();
  const answerID = uuidv4();
  //participated_num, _id 빼고 전달
  const [inputs, setInputs] = useState<ISurveyQuestion[]>([
    {
      _id: initial,
      question_type: "single_choice",
      question: "",
      participated_num: 0,
      is_required: true,
      answers: [{ _id: answerID, answer: "", participated_num: 0 }],
    },
  ]);

  useEffect(() => {
    //수정을 위한 데이터 세팅
    if (Iprops.isEdit === undefined || !Iprops.data) return;

    setCheckedItems(Iprops.data.survey_tenant_to);
    setTarget(Iprops.data.survey_person_to);
    setStartDate(Iprops.data.start_time);
    setEndDate(Iprops.data.end_time);
    setSurveyTitle(Iprops.data.title);
    Iprops.data.image && setImgBase(Iprops.data.image);
    setSurveyBody(Iprops.data.body);
    if (Iprops.data.questions_answers !== undefined) {
      setInputs(
        Iprops.data.questions_answers?.map((el, idx) => ({
          ...el,
          _id: String(idx + 1),
          question: el.question,
          question_type: el.question_type,
          is_required: el.is_required,
          image: el.image ? el.image : "",
          answers: el.answers,
        })),
      );
    }
  }, [Iprops.isEdit, Iprops.data]);

  const onGetFileUrl = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files?.[0];

    if (files === undefined) return;
    const isVaild = checkFileValidation(files);
    if (!isVaild) return toast({ ...checkFileValidate });

    setFile(files);
    setImgBase(URL.createObjectURL(files));

    e.target.value = "";
  };

  const onAddQuestion = () => {
    const newArr = [...inputs];
    // const newArr = [...QuestionArr];
    const newId = uuidv4();
    const answerID = uuidv4();
    // newArr.push(
    //   <QuestionItems key={uuidv4()} id={newId} num={QuestionArr.length + 1} />
    // );
    // setQuestionArr(newArr);
    // const newInput = [...inputs];
    newArr.push({
      _id: newId,
      question_type: "single_choice",
      question: "",
      participated_num: 0,
      is_required: false,
      answers: [{ _id: answerID, answer: "", participated_num: 0 }],
    });
    setInputs(newArr);
  };

  const onCheckedAll = (checked: boolean) => {
    if (checked) {
      const allCheckedArr: string[] = [];
      room_type_arr?.forEach((el) => allCheckedArr.push(el.label));
      setCheckedItems(allCheckedArr);
    } else {
      setCheckedItems([]);
    }
  };
  const onChangeChecked = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      const newArr = checkedItems.filter((el) => el !== id);
      setCheckedItems(newArr);
    }
    if (checkedItems.length === room_type_arr.length || 0) {
      return (checked = true);
    }
  };

  const onDeleteImg = () => {
    setImgBase("");
    setFile(null);
  };

  const mutation = useMutation((data: FormData) =>
    fetchClient
      .post(Iprops.isEdit ? survey_update : survey_create, data)
      .then((res) => {
        toast({
          ...success,
          title: `설문조사 ${Iprops.isEdit ? "수정" : "등록"} 완료`,
          description: `설문조사 ${Iprops.isEdit ? "수정" : "등록"}이 완료되었습니다.`,
        });
        navigate("/survey");
        return res.data.data;
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) return toast({ ...unAuthUser });
        if (err.response?.status === 505) {
          toast({
            ...warning,
            title: "설문조사 등록 오류",
            description: "설문 등록을 위해 모든 항목을 기입해야합니다. 질문과 응답을 다시 한번 확인해주세요.",
          });
        }
      }),
  );

  //설문 등록
  const onRegisterSurvey = () => {
    if (auth !== "edit") return toast({ ...unAuthUser });
    if (mutation.isLoading) return;

    const formData = new FormData();
    formData.append("start_time", `${moment(startDate).format("YYYY-MM-DDTHH:mm")}:00Z`);
    formData.append("end_time", `${moment(endDate).format("YYYY-MM-DDTHH:mm")}:00Z`);
    onlyOffice
      ? formData.append("survey_tenant_to", "office")
      : checkedItems.map((el) => formData.append("survey_tenant_to", el));
    formData.append("survey_person_to", target);
    formData.append("title", surveyTitle);
    formData.append("body", surveyBody);
    file !== null && formData.append("image", file);

    const except = [...inputs];
    const newArr = except.map((el) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { _id, participated_num, ...rest } = el;

      const newArr: any = el.answers.map((ele) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { _id, participated_num, ...ret } = ele;
        return ret;
      });

      rest.answers = newArr;
      return rest;
    });
    formData.append("questions_answers", JSON.stringify(newArr));

    mutation.mutate(formData);
  };

  const onEditSurvey = () => {
    if (auth !== "edit") return toast({ ...unAuthUser });
    if (mutation.isLoading) return;

    const formData = new FormData();
    surveyId && formData.append("survey_id", surveyId);
    formData.append(
      "start_time",
      typeof startDate === "string" ? startDate : `${moment(startDate).format("YYYY-MM-DDTHH:mm")}:00Z`,
    );
    formData.append(
      "end_time",
      typeof endDate === "string" ? endDate : `${moment(endDate).format("YYYY-MM-DDTHH:mm")}:00Z`,
    );

    onlyOffice
      ? formData.append("survey_tenant_to", "office")
      : checkedItems.map((el) => formData.append("survey_tenant_to", el));
    formData.append("survey_person_to", target);
    formData.append("title", surveyTitle);
    formData.append("body", surveyBody);
    file !== null && formData.append("image", file);

    const except = [...inputs];
    const newArr = except.map((el) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { _id, participated_num, ...rest } = el;

      const newArr: any = el.answers.map((ele) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { _id, participated_num, ...ret } = ele;
        return ret;
      });

      rest.answers = newArr;
      return rest;
    });
    formData.append("questions_answers", JSON.stringify(newArr));

    mutation.mutate(formData);
  };

  const props: INewSurvey = {
    onCheckedAll,
    onChangeChecked,
    checkedItems,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    isDirect,
    setIsDirect,
    onAddQuestion,
    imgBase,
    fileRef,
    onGetFileUrl,
    onDeleteImg,
    surveyTitle,
    setSurveyTitle,
    target,
    setTarget,
    surveyBody,
    setSurveyBody,
    onRegisterSurvey,
    inputs,
    setInputs,
    onEditSurvey,
    isEdit: Iprops.isEdit,
    data: Iprops.data,
  };

  if (auth === undefined) return <FullPageSpinner />;
  if (auth !== "edit") return <GetAuthorized />;
  return (
    // <SurveyContext.Provider value={{ inputs, setInputs, onDeleteQuestion }}>
    <NewSurveyPageUI {...props} />
    // </SurveyContext.Provider>
  );
}
