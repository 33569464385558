import { useDisclosure, useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../../lib/api/axios";
import { notice_delete, notice_detail } from "../../../lib/api/queries/urls";
import { notice_detail_key, notice_list_key } from "../../../lib/api/queries/keys";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { INotice } from "../../../lib/types/Imodels";
import NoticeDetailPageUI from "./_view/presenter";
import { queryClient } from "../../..";
import { success, warning } from "../../../lib/theme/toast";
import useUserAuth from "../../../components/hooks/useUserAuth";
import FullPageSpinner from "../../../components/Spinner";
import GetAuthorized from "../../../components/Authrized";
import { addHook, sanitize } from "dompurify";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";

export default function DetailNotification() {
  const toast = useToast();
  const navigate = useNavigate();
  const { noticeId } = useParams();
  const auth = useUserAuth("notice");
  const { isOpen, onOpen, onClose } = useDisclosure();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSanitized] = useState<string | Node>("");

  const { data } = useQuery(
    [notice_detail_key],
    () => fetchClient.post<BaseResponse<INotice>>(notice_detail, { notice_id: noticeId }).then((res) => res.data.data),
    {
      enabled: auth === "edit" || auth === "read",
    },
  );

  useEffect(() => {
    if (!data) return;

    const TEMPORARY_ATTRIBUTE = "data-temp-href-target";
    addHook("beforeSanitizeAttributes", function (node) {
      if (node.tagName === "A") {
        if (!node.hasAttribute("target")) {
          node.setAttribute("target", "_blank");
        }

        if (node.hasAttribute("target")) {
          node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute("target") || "");
        }
      }
    });

    addHook("afterSanitizeAttributes", function (node) {
      if (node.tagName === "A" && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
        node.setAttribute("target", node.getAttribute(TEMPORARY_ATTRIBUTE) || "");
        node.removeAttribute(TEMPORARY_ATTRIBUTE);
      }
    });
    setSanitized(sanitize(String(data.body || "")));
  }, [data]);

  // const [download, setDownload] = useState<boolean>();
  // useEffect(() => {
  //   if (data && data.attached_files) setDownload(true);
  // }, [data]);

  // const onClickDownload = () => {
  //   console.log(typeof data?.attached_files?.[0]);
  //   if (download) {
  //     fetch(data?.attached_files?.[0] || "", { method: "GET" })
  //       .then((res) => {
  //         return res.blob();
  //       })
  //       .then((blob) => {
  //         const url = window.URL.createObjectURL(blob);
  //         const a = document.createElement("a");
  //         a.href = url;
  //         a.download = `{원하는 파일명}.pdf`;
  //         document.body.appendChild(a);
  //         a.click();
  //         // setTimeout(_ => {
  //         //   window.URL.revokeObjectURL(url);
  //         // }, 60000);
  //         a.remove();
  //       })
  //       .catch((err) => {
  //         console.error("err: ", err);
  //       });
  //   }
  // };

  const onMoveToEditNotice = () => {
    navigate(`/notice/${noticeId}/edit`);
  };

  const mutation = useMutation(() =>
    fetchClient
      .post(notice_delete, {
        notice_ids: [data?._id],
      })
      .then(() => {
        queryClient.invalidateQueries([notice_list_key, 1]);
        toast({
          ...success,
          title: "게시글 삭제 완료",
          description: "공지사항 게시글이 삭제되었습니다. 공지 사항 페이지로 이동합니다.",
        });
        onClose();
        return navigate("/notice", { replace: true });
      })
      .catch((err: AxiosError) => {
        if (err.response?.status !== 401)
          toast({
            ...warning,
            title: err.response?.status === 401 ? "게시물 권한 오류" : "알림",
            description:
              err.response?.status === 401
                ? "해당 게시물에 대한 삭제 권한이 없습니다. 권한 필요 시 최고관리자에게 문의해주세요."
                : "일시적인 오류로 게시물 삭제에 실패했습니다. 새로고침 후 다시 시도해주세요.",
          });
      }),
  );

  const onClickDelete = () => {
    // console.log([data?._id]);
    if (mutation.isLoading) return;
    mutation.mutate();
  };

  if (auth === undefined) return <FullPageSpinner />;
  if (auth === "no_auth") return <GetAuthorized />;
  return (
    <NoticeDetailPageUI
      data={data}
      onMoveToEditNotice={onMoveToEditNotice}
      onClickDelete={onClickDelete}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
    />
  );
}
