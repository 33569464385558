import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import {
  department_list_key,
  permission_list_key,
  user_detail_key,
  user_list_key,
} from "../../../../lib/api/queries/keys";
import { department_list, permission_list, user_detail } from "../../../../lib/api/queries/urls";
import { IAuth, IDeparts } from "../../../../lib/types/Imodels";
import { AxiosError } from "axios";
import { profile_update } from "../../../../lib/api/queries/urls";
import { IUser } from "../../../../lib/types/Imodels";
import { useContext } from "react";
import { GlobalContext } from "../../../../App";
import { toast } from "../../../../lib/utils/toast";

export const usefetchUserDetails = (isOpen: boolean, user_id: string) => {
  return useQuery(
    [user_detail_key, user_id],
    () =>
      fetchClient
        .post<BaseResponse<IUser>>(user_detail, { user_id })
        .then((res) => res.data.data)
        .catch((err) => {
          toast.warning({
            title: "알림",
            description:
              err.response?.status === 504
                ? "이미 삭제된 관리자 계정입니다. 새로고침 후에도 동일한 오류 발생시 오피스너 담당자에게 연락주세요."
                : "일시적인 오류로 관리자 정보 불러오기에 실패했습니다. 잠시 후 다시 시도해주세요.",
          });
          return undefined;
        }),
    {
      enabled: isOpen,
      retry: 0,
    },
  );
};

export const useMutationUserInfo = () => {
  const queryClient = useQueryClient();

  const editUser = useMutation(
    (formData: FormData) => fetchClient.post<BaseResponse<IUser>>(profile_update, formData),
    {
      onSuccess: () => {
        console.log("success");
        queryClient.invalidateQueries([user_list_key]);
        queryClient.invalidateQueries([user_detail_key]);
      },
      onError: (err: AxiosError) => {
        console.error(err);
      },
    },
  );

  return { editUser };
};

export const usefetchPermissionList = (isEnabled: boolean) => {
  const { buildInfo } = useContext(GlobalContext);

  return useQuery(
    [permission_list_key, buildInfo?._id], // 다른 건물로 다시 로그인 했을 경우
    () => fetchClient.get<BaseResponse<IAuth[]>>(permission_list).then((res) => res.data.data),
    {
      enabled: isEnabled && !!buildInfo,
      staleTime: 31557600000, //무한 쿼리 대신 무효화
    },
  );
};

export const usefetchTeamList = (isEnabled: boolean) => {
  const { buildInfo } = useContext(GlobalContext);

  return useQuery(
    [department_list_key, buildInfo?._id],
    () => fetchClient.get<BaseResponse<IDeparts[]>>(department_list).then((res) => res.data.data),
    {
      enabled: isEnabled && !!buildInfo,
      staleTime: 31557600000, //무한 쿼리 대신 무효화
    },
  );
};
