import { useContext } from "react";
import useUserAuth from "../../../components/hooks/useUserAuth";
import FullPageSpinner from "../../../components/Spinner";
import GetAuthorized from "../../../components/Authrized";
import { GlobalContext } from "../../../App";
import NotFound from "../../not-found";
import { useFetchListForNewManangement } from "../commons/queries";
import CreateManagementBill from "../maintenance/new/management";

export default function MaintenanceFeeCreatePage() {
  const { buildInfo } = useContext(GlobalContext);
  const auth = useUserAuth("management_fee");
  const hasFunc = buildInfo?.services.MAINTENANCE_FEE?.enabled;

  const [{ data: room, isFetching }, { data: saved }] = useFetchListForNewManangement(true);

  if (!buildInfo || isFetching) return <FullPageSpinner />;
  if (buildInfo.management_fee_upload_type === "file" || !hasFunc) return <NotFound />; //관리비 타입이 있다는 건 이 기능을 쓴다는 것
  if (auth !== "edit") return <GetAuthorized />;

  return <CreateManagementBill isEdit={false} room={room} data={undefined} saved={saved} />;
}
