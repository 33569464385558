import { ModalBody } from "@chakra-ui/react";
import LottieData from "../../../lotties/complete.json";
import { Player } from "@lottiefiles/react-lottie-player";

export default function VoteComplete() {
  return (
    <>
      <ModalBody className="flex" padding={0}>
        <div className="flex h-full w-full flex-col items-center justify-center">
          <Player
            loop={false}
            src={LottieData}
            autoplay={true}
            style={{ height: "300px", width: "300px" }}
            keepLastFrame={true}
          />
          <p>투표를 성공적으로 발송하였습니다!</p>
        </div>
      </ModalBody>
    </>
  );
}
