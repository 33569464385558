import styled from "@emotion/styled";
import { MdSearch } from "@react-icons/all-files/md/MdSearch";
import {
  ChangeEvent,
  CSSProperties,
  FC,
  KeyboardEventHandler,
  MouseEventHandler,
} from "react";
import { colors } from "../../styled/common";
import { MouseShadowBox } from "../../styled/webApp";

interface IRadiusInput {
  id?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string;
  className?: string;
  _style?: CSSProperties;
  _onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  _onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  _onClick?: MouseEventHandler<SVGElement>;
}

const InputInShadow = styled.input`
  flex: 1;
  ::placeholder {
    color: ${colors.gray2};
  }
`;

const GrayRadiusWithIcon: FC<IRadiusInput> = ({ ...props }) => {
  const { _style, _onChange, _onKeyUp, _onClick, className, ...rest } = props;

  return (
    <MouseShadowBox className={className} style={_style}>
      <InputInShadow onChange={_onChange} onKeyUp={_onKeyUp} {...rest} />
      <MdSearch onClick={_onClick} style={{ color: colors.gray3 }} />
    </MouseShadowBox>
  );
};

export default GrayRadiusWithIcon;
