import { HTMLAttributes } from "react";

export type Props = object & HTMLAttributes<HTMLOrSVGElement>;

export default function IconVote(props: Props) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        {...props}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.29289 3.29289C2 3.58579 2 4.05719 2 5C2 5.94281 2 6.41421 2.29289 6.70711C2.58579 7 3.05719 7 4 7H20C20.9428 7 21.4142 7 21.7071 6.70711C22 6.41421 22 5.94281 22 5C22 4.05719 22 3.58579 21.7071 3.29289C21.4142 3 20.9428 3 20 3H4C3.05719 3 2.58579 3 2.29289 3.29289ZM20.069 8.49993H20.0689H3.93114H3.93107C3.78994 8.49999 3.64485 8.50005 3.5 8.49805V12.9999C3.5 16.7711 3.5 18.6568 4.67157 19.8283C5.84315 20.9999 7.72876 20.9999 11.5 20.9999H12.5C16.2712 20.9999 18.1569 20.9999 19.3284 19.8283C20.5 18.6568 20.5 16.7711 20.5 12.9999V8.49805C20.3552 8.50005 20.2101 8.49999 20.069 8.49993ZM15.0595 13.2495C15.3353 12.9405 15.3085 12.4664 14.9995 12.1906C14.6905 11.9147 14.2164 11.9415 13.9406 12.2505L10.9286 15.6239L10.0595 14.6505C9.78359 14.3415 9.30947 14.3147 9.0005 14.5906C8.69152 14.8664 8.66468 15.3405 8.94055 15.6495L10.3691 17.2495C10.5114 17.4089 10.7149 17.5 10.9286 17.5C11.1422 17.5 11.3457 17.4089 11.488 17.2495L15.0595 13.2495Z"
        fill="#D1D5DB"
      />
    </svg>
  );
}
