import { cssVar, extendTheme } from "@chakra-ui/react";
import { colors, m_color } from "../../components/styled/common";
import { breakPoints } from "../../components/styled/media";
import { btn, m_btn, tag, text } from "./common";

const styles = {
  global: {
    html: { width: "100%", height: "100%", fontSize: "16px" },
    body: {
      width: "100%",
      height: "100%",
      color: colors.gray5,
      lineHeight: "1.5",
      fontFamily: "Noto Sans CJK KR, Noto Sans KR, sans-serif",
      fontWeight: 400,
      wordBreak: "keep-all",
    },
    "button, div": { borderColor: colors.whiteColor },
    "*:focus, *:focus-visible, *[data-focus-visible]": {
      outline: "none",
      boxShadow: "0 0 0 0px #fff !important",
    },
    ["@media (max-width:768px)"]: {
      html: { fontSize: "14px" },
    },
  },
};

const $endColor = cssVar("skeleton-end-color");

const components = {
  Text: {
    //텍스트 theme 정리 필요
    variants: {
      bolder: {
        fontSize: "1.5rem",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        lineHeight: "initial",
      },
      static: {
        fontSize: "1.5rem",
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        paddingBottom: "24px",
        borderBottom: `2px solid ${colors.gray4}`,
        lineHeight: "initial",
      },
      default: {
        fontSize: "1rem",
        fontWeight: 500,
        paddingBottom: "4px",
        lineHeight: "initial",
      },
      title: {
        width: "100%",
        color: colors.mainBlue,
        fontSize: "1.25rem",
        fontWeight: 500,
        paddingBottom: "8px",
        lineHeight: "initial",
        borderBottom: `1px solid ${colors.gray4}`,
      },
      subTitle: {
        width: "100%",
        color: colors.mainBlue,
        fontSize: "1.25rem",
        fontWeight: 500,
        paddingBottom: "4px",
        lineHeight: "initial",
      },
      text_red: {
        color: m_color.danger5,
        fontWeight: 700,
        width: "fit-content",
        ...text,
      },
    },
  },
  Tag: {
    variants: {
      schedule: () => {
        return {
          container: {
            color: colors.whiteColor,
            backgroundColor: "#00A8FF",
            margin: "0px 4px 0px 8px",
            ...tag,
          },
        };
      },
      reservation: () => {
        return {
          container: {
            color: colors.whiteColor,
            backgroundColor: "#FFBC10",
            margin: "0px 4px 0px 8px",
            ...tag,
          },
        };
      },
      important: () => {
        return {
          container: {
            color: colors.whiteColor,
            backgroundColor: colors.red1,
            margin: "0px 4px 0px 8px",
            ...tag,
          },
        };
      },
      done: () => {
        return {
          container: {
            color: colors.whiteColor,
            backgroundColor: colors.gray3,
            margin: "0px",
            ...tag,
          },
        };
      },
    },
  },
  Button: {
    variants: {
      cancel: {
        border: `1px solid ${colors.gray3}`,
        color: colors.gray4,
        backgroundColor: colors.whiteColor,
        ...btn,
      },
      delete: {
        border: `1px solid ${colors.red1}`,
        color: colors.red1,
        marginLeft: "0.5rem",
        ...btn,
      },
      blueLine: {
        border: `1px solid ${colors.mainBlue}`,
        color: colors.mainBlue,
        marginLeft: "0.5rem",
        ...btn,
      },
      bgBlue: {
        border: `1px solid ${colors.mainBlue}`,
        backgroundColor: colors.mainBlue,
        color: colors.whiteColor,
        marginLeft: "0.5rem",
        _disabled: {
          border: "1px solid #D1D5DB",
          background: "#D1D5DB",
          cursor: "not-allowed",
        },
        _hover: { _disabled: { background: "#D1D5DB" } },
        ...btn,
      },
      basic: {
        border: `1px solid ${colors.gray3}`,
        color: colors.gray3,
        lineHeight: 1.5,
        height: "auto",
        padding: "8px 12px",
        fontWeight: 500,
        WebkitPaddingBefore: "9px",
        MozPaddingBefore: "9px",
        MsPaddingBefore: "9px",
        MsPaddingAfter: "9px",
        borderRadius: 0,
      },
      m_blue: {
        background: m_color.mainBlue,
        color: m_color.whiteColor,
        _hover: {
          _disabled: {
            background: m_color.mainBlue,
          },
        },
        ...m_btn,
      },
      m_primary: {
        backgroundColor: m_color.primary1,
        color: m_color.mainBlue,
        ...m_btn,
      },
      m_primary_line: {
        backgroundColor: colors.whiteColor,
        border: `1px solid ${colors.mainBlue}`,
        color: colors.mainBlue,
        ...m_btn,
      },
      m_danger: {
        backgroundColor: m_color.danger5,
        color: m_color.whiteColor,
        ...m_btn,
      },
      m_light_danger: {
        backgroundColor: m_color.danger1,
        color: m_color.danger4,
        ...m_btn,
      },
      m_gray: {
        backgroundColor: colors.gray1,
        color: colors.gray4,
        ...m_btn,
      },
    },
  },
  Table: {
    //없앨 수 있도록
    variants: {
      default: {
        th: {
          textAlign: "center",
          fontWeight: 400,
          fontSize: "16px",
          backgroundColor: colors.gray1,
          border: "none",
          lineHeight: "1.5",
          borderTop: `1px solid ${colors.gray4}`,
          borderBottom: `1px solid ${colors.gray4}`,
        },
        td: {
          textAlign: "center",
          fontWeight: 400,
          lineHeight: "1.5",
          border: "none",
          borderBottom: `1px solid ${colors.gray3}`,
          backgroundColor: colors.whiteColor,
        },
      },
      contents: {
        width: "100%",
        td: {
          minHeight: "56px",
          padding: "8px 0",
          lineHeight: "1.5",
          fontWeight: 400,
          border: "none",
          borderBottom: `1px solid ${colors.gray2}`,
          backgroundColor: colors.whiteColor,
        },
      },
      main: {
        width: "100%",
        table: { borderTop: `1px solid ${colors.gray4}` },
        td: {
          lineHeight: "1.5",
          width: "15vw",
          padding: "8px 0",
          fontWeight: 400,
          border: "none",
          borderBottom: `1px solid ${colors.gray2}`,
          backgroundColor: colors.whiteColor,
        },
      },
    },
  },
  Menu: {
    variants: {
      default: {
        button: {
          padding: "8px 12px",
          WebkitPaddingBefore: "9px",
          MozPaddingBefore: "9px",
          MsPaddingBefore: "9px",
          MsPaddingAfter: "9px",
          width: "180px",
          color: colors.gray4,
          border: `1px solid ${colors.gray3}`,
        },
        list: {
          margin: "-10px 0",
          padding: 0,
          width: "180px",
          border: `1px solid ${colors.gray3}`,
          minWidth: "180px",
          textAlign: "right",
          boxShadow: "none",
          borderRadius: 0,
        },
      },
      contents: {
        button: {
          padding: "8px 12px",
          WebkitPaddingBefore: "9px",
          MozPaddingBefore: "9px",
          MsPaddingBefore: "9px",
          MsPaddingAfter: "9px",
          marginRight: "8px",
          width: "120px",
          color: colors.gray4,
          border: `1px solid ${colors.gray3}`,
        },
        list: {
          margin: "-10px 0",
          padding: 0,
          width: "120px",
          border: `1px solid ${colors.gray3}`,
          minWidth: "100px",
          textAlign: "right",
          boxShadow: "none",
          borderRadius: 0,
        },
      },
      gray: {
        button: {
          padding: "0.75rem 1.5rem",
          width: "100%",
          color: colors.gray4,
          background: colors.gray1,
          borderRadius: "0.5rem",
          span: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },
        },
        list: {
          padding: 0,
          width: "100%",
          textAlign: "left",
          boxShadow: "none",
          borderRadius: "0.5rem",
          borderWidth: 0,
        },
        item: {
          bg: "#F9FAFB",
          padding: 0,
          ":first-of-type": {
            borderRadius: "0.5rem 0.5rem 0 0",
            label: { borderRadius: "0.5rem 0.5rem 0 0" },
          },
          ":last-of-type": {
            borderRadius: "0 0 0.5rem 0.5rem",
            label: {
              borderRadius: "0 0 0.5rem 0.5rem",
            },
          },
          _hover: {
            background: m_color.primary1,
            color: colors.mainBlue,
          },
          label: {
            width: "100%",
            padding: "0.75rem 1.5rem",
            _checked: {
              background: m_color.primary1,
              color: m_color.mainBlue,
            },
          },
        },
      },
    },
  },
  Drawer: {
    variants: {
      default: {
        dialog: {
          maxWidth: "26rem",
          ["@media (max-width:540px)"]: { maxWidth: "100%" },
          ["@media (min-width:1824px)"]: { maxWidth: "30%" },
        },
        header: {
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1.25rem 1.5rem",
          fontSize: "1.5rem",
          fontWeight: 400,
          borderBottom: `1px solid ${colors.gray3}`,
        },
        body: {
          padding: "1.5rem 1.5rem 0 1.5rem",
          color: colors.gray4,
        },
        footer: { padding: "0 1.5rem 3.5rem 1.5rem" },
      },
    },
  },
  Modal: {
    variants: {
      default: {
        dialogContainer: { padding: "0 16px" },
        header: {
          padding: "20px 24px",
          fontSize: "24px",
          fontWeight: 400,
          borderBottom: `1px solid ${colors.gray3}`,
        },
        body: { padding: "24px" },
        footer: {
          marginTop: "4rem",
          borderTop: `1px solid ${colors.gray2}`,
          padding: "20px 0 0 0",
        },
      },
      contents: {
        header: {
          padding: "20px 24px",
          fontSize: "24px",
          fontWeight: 400,
          borderBottom: `1px solid ${colors.gray3}`,
        },
        body: {
          padding: "32px 24px 0px",
        },
        footer: {
          padding: "20px 24px 36px",
        },
      },
      responsive: {
        dialogContainer: {
          alignItems: "center",
          [`@media ${breakPoints.mobile}`]: { padding: "0 1rem" },
        },
        dialog: {
          // alignItems: "center",
          marginTop: 0,
          padding: "1.5rem",
          minHeight: "14rem",
          borderRadius: "1rem",
        },
        header: {
          padding: 0,
          fontSize: "1.25rem",
          fontWeight: 500,
        },
        body: {
          padding: "12px 0 0 0",
          fontSize: "0.875rem",
        },
        footer: { padding: "0" },
      },
      with_contents: {
        dialogContainer: {
          alignItems: "center",
          [`@media ${breakPoints.mobile}`]: { padding: "0 1rem" },
        },
        dialog: {
          marginTop: 0,
          minHeight: "24rem",
          borderRadius: "1rem",
        },
        header: {
          padding: "1.5rem 1.5rem 1.25rem 1.5rem",
          fontSize: "1.25rem",
          fontWeight: 500,
        },
        body: { padding: "0 1.5rem" },
        footer: { padding: "1.5rem" },
      },
    },
  },
  Box: {
    variants: {
      default: {
        border: `1px solid ${colors.gray3}`,
        borderRadius: 0,
        backgroundColor: colors.whiteColor,
        color: colors.gray2,
        marginRight: "8px",
        padding: "8px 12px",
        maxW: "500px",
        variant: "unstyled",
      },
    },
  },
  input: {
    variants: {
      default: {
        border: `1px solid ${colors.gray3}`,
        color: colors.gray2,
        fontWeight: 500,
        resize: "none",
        width: "100%",
        height: "260px",
      },
    },
  },
  Select: {
    variants: {
      default: () => {
        return {
          icon: { color: colors.gray4 },
          field: {
            border: `1px solid ${colors.gray3}`,
            borderRadius: 0,
            backgroundColor: colors.whiteColor,
            width: "180px",
            color: colors.gray4,
            height: "fit-content",
            padding: "10px 12px",
            WebkitPaddingBefore: "11px",
            MozPaddingBefore: "11px",
            MsPaddingBefore: "11px",
            MsPaddingAfter: "11px",
          },
        };
      },
      fitContent: () => {
        return {
          field: {
            border: `1px solid ${colors.gray3}`,
            borderRadius: 0,
            backgroundColor: colors.whiteColor,
            color: colors.gray4,
            height: "fit-content",
            padding: "10px 12px",
          },
        };
      },
    },
  },
  Switch: {
    variants: {
      default: {
        track: {
          _checked: {
            bg: colors.mainBlue,
            borderColor: colors.mainBlue,
            _hover: {
              bg: colors.mainBlue,
              borderColor: colors.mainBlue,
            },
          },
        },
      },
    },
  },
  Checkbox: {
    variants: {
      default: {
        control: {
          _checked: {
            bg: colors.mainBlue,
            borderColor: colors.mainBlue,
            _hover: {
              bg: colors.mainBlue,
              borderColor: colors.mainBlue,
            },
          },
        },
      },
    },
  },
  Radio: {
    variants: {
      default: {
        control: {
          _checked: {
            bg: colors.mainBlue,
            borderColor: colors.mainBlue,
            _hover: {
              bg: colors.mainBlue,
              borderColor: colors.mainBlue,
            },
          },
        },
      },
    },
  },
  Skeleton: {
    variants: {
      default: {
        height: "20px",
        margin: "0 16px 0 0",
        [$endColor.variable]: colors.gray2,
      },
    },
  },
};

const theme = extendTheme({ colors, styles, components });
export default theme;
