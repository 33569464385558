import { useDisclosure } from "@chakra-ui/react";
import PublicRoomInfo from "../../components/detailModal/publicRoomInfo";
import { usePublicDetail } from "../../commons/queries";
import { IRoomPublic } from "../../../../lib/types/Imodels";
import { TimelineRooms, TimelineHeader } from "./styled";
import useUserAuth from "../../../../components/hooks/useUserAuth";
import { IoMdLock } from "@react-icons/all-files/io/IoMdLock";
import { FixedTitle } from "../../../../components/styled";

export const SidebarTitle = (props: { el?: IRoomPublic }) => {
  // console.log(props.el._id);
  const auth = useUserAuth("room_public");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data } = usePublicDetail(String(props.el?._id), isOpen);

  return (
    <TimelineRooms>
      <div
        style={{
          width: "30%",
          backgroundColor: "#D1D5DB",
          color: "#fff",
        }}
      >
        {props.el?.dong}
      </div>
      <div
        style={{
          width: "20%",
          backgroundColor: " #F4F4F5",
        }}
      >
        {props.el?.floor}층
      </div>
      <FixedTitle
        onClick={() => {
          if (auth === undefined || auth === "no_auth") return;
          onOpen();
        }}
        style={{
          width: "50%",
          padding: "0 8px",
          cursor: "pointer",
          textAlign: "left",
        }}
        id={String(props.el?._id)}
      >
        {props.el?.name}
        {props.el?.is_approval_required && (
          <IoMdLock className="react-icon-static" style={{ fontSize: "14px", marginBottom: "2px" }} />
        )}
      </FixedTitle>
      {data && <PublicRoomInfo isOpen={isOpen} onClose={onClose} data={data} />}
    </TimelineRooms>
  );
};

export const SidebarClassification = () => {
  return (
    <TimelineHeader style={{ height: "48px", fontSize: "15px" }}>
      <div style={{ width: "30%" }}>동</div>
      <div style={{ width: "20%" }}>층</div>
      <div
        style={{
          padding: "0 8px",
          justifyContent: "flex-start",
          width: "50%",
        }}
      >
        회의실명
      </div>
    </TimelineHeader>
  );
};
