import { Box, Select, Button, Flex, Text } from "@chakra-ui/react";
import * as C from "../../../../components/styled/index";
import * as R from "./styled";
import "./timeline.css";
import Timeline, { DateHeader, SidebarHeader, TimelineHeaders } from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import SmallInputs from "../../../../components/Input/shortText";
import { items, testgroups } from "../../../../testdata/testSet";
import { IPUblicType } from "./types";
import { SidebarClassification } from "./timeline";
import GAEvents from "../../../../lib/utils/analytics";
import { format_day } from "../../../../lib/utils/dataFormat";
import MainHeaderPart from "../../../../components/Main/HeaderPart";
import { IoMdLock } from "@react-icons/all-files/io/IoMdLock";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import CheckLocationBox from "../../components/checkBox/locationBox";

export default function MeetingRoomPageUI(props: IPUblicType) {
  const checklocation = {
    dongArr: props.dongArr,
    floorArr: props.floorArr,
    setDongArr: props.setDongArr,
    setFloorArr: props.setFloorArr,
  };

  return (
    <C.Wrapper>
      <MainHeaderPart
        isIcon={false}
        title="회의실"
        authKey="room_public"
        obj={{
          category: GAEvents.Category.public,
          action: GAEvents.Action.public.setting,
        }}
      >
        <Text>회의실 예약 현황은 시설유형 선택을 하여 검색하여야 조회가 가능합니다.</Text>
        <Text fontWeight={500}>신규 회의실 등록 및 기존 시설 수정, 삭제는 회의실 설정에서 해주세요.</Text>
      </MainHeaderPart>
      <C.WrapperBody>
        <Flex alignItems="flex-end">
          <CheckLocationBox {...checklocation} />
          <C.SelectBox>
            <Text variant="default">예약확정 방식</Text>
            <Select variant="default" value={props.status} onChange={(e) => props.setStatus(e.target.value)}>
              <option value="">전체</option>
              <option value="pending">예약신청</option>
              <option value="approved">예약확정</option>
            </Select>
          </C.SelectBox>
          <Flex alignItems="flex-end">
            <Box>
              <Text variant="default">검색</Text>
              <SmallInputs
                value={props.search}
                onChange={(e) => props.setSearch(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && props.onClickSubmit()}
                placeholder="검색할 회의실명을 입력해주세요."
              />
            </Box>
            <Button variant="basic" onClick={props.onClickSubmit}>
              검색
            </Button>
          </Flex>
        </Flex>
        <C.TableListBox>
          <C.DateWithIconBox>
            <DatePicker
              locale={ko}
              dateFormat="yyyy-MM-dd"
              selected={new Date(props.today)}
              onChange={(date: Date) => props.setToday(moment(date).format("YYYY-MM-DD"))}
              customInput={
                <C.InnerTextBox style={{ fontSize: "18px" }}>
                  <Text>
                    {moment.utc(props.today).format("YYYY년 MM월 DD일 ")}
                    {format_day(moment.utc(props.today).day())}요일
                  </Text>
                  <FiChevronDown style={{ fontSize: "24px" }} />
                </C.InnerTextBox>
              }
            />
          </C.DateWithIconBox>
          <Flex p="8px 0 16px">
            <R.TextStyle>
              <R.ShowBox />
              <span>예약가능</span>
            </R.TextStyle>
            <R.TextStyle>
              <R.ShowBox className="disAvailable" />
              <span>예약불가능</span>
            </R.TextStyle>
            <R.TextStyle>
              <R.ShowBox className="need-approval" />
              <span>예약신청</span>
            </R.TextStyle>
            <R.TextStyle>
              <R.ShowBox className="confirm-reservation" />
              <span>예약확정</span>
            </R.TextStyle>
            <R.TextStyle>
              <IoMdLock fontSize="14px" />
              <span>관리자 확인 후 예약확정</span>
            </R.TextStyle>
          </Flex>
          <R.TimelineBox>
            {props.groups?.length === 0 ? (
              <>일치하는 회의실 및 예약 신청이 없습니다.</>
            ) : (
              <Timeline
                groups={props.groups ? props.groups : testgroups}
                items={props.reservation || items}
                sidebarWidth={300}
                defaultTimeStart={moment(props.today).startOf("day")}
                defaultTimeEnd={moment(props.today).endOf("day")}
                // minZoom={}
                visibleTimeStart={0 || moment(props.today).startOf("day")}
                visibleTimeEnd={0 || moment(props.today).endOf("day")}
                onItemClick={(e) => props.detailBooked(e)}
                onItemSelect={(e) => props.detailBooked(e)}
                canMove={false}
                canResize={false}
                canChangeGroup={false}
                traditionalZoom={false}
                // onTimeChange={}
              >
                <TimelineHeaders>
                  <SidebarHeader>
                    {({ getRootProps }) => {
                      return (
                        <div {...getRootProps()}>
                          <SidebarClassification />
                        </div>
                      );
                    }}
                  </SidebarHeader>
                  <DateHeader height={48} style={{ backgroundColor: "#fff" }} />
                </TimelineHeaders>
              </Timeline>
            )}
          </R.TimelineBox>
        </C.TableListBox>
      </C.WrapperBody>
    </C.Wrapper>
  );
}
