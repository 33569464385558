import { queryClient } from "../../..";
import {
  public_list_key,
  complaint_list_key,
  company_list_key,
  register_card_key,
  parking_history_keys,
  temperature_list_key,
} from "../../../lib/api/queries/keys";
import { RiLoaderLine } from "@react-icons/all-files/ri/RiLoaderLine";
import { RiTempHotLine } from "@react-icons/all-files/ri/RiTempHotLine";
import { MdQuestionAnswer } from "@react-icons/all-files/md/MdQuestionAnswer";
import { MdLocalCafe } from "@react-icons/all-files/md/MdLocalCafe";
import { MdAssignmentInd } from "@react-icons/all-files/md/MdAssignmentInd";
import { MdDirectionsCar } from "@react-icons/all-files/md/MdDirectionsCar";
import { MdPersonAdd } from "@react-icons/all-files/md/MdPersonAdd";
import { NavigateFunction } from "react-router-dom";

//알림 format
export const format_notification = (status: string) => {
  switch (status) {
    case "room_public_approval_request":
      return "공용시설 승인 요청";
    case "tenant_master_auto_approval":
      return "계정 승인";
    case "tenant_master_approval_request":
      return "계정 승인 요청";
    case "complaint_commented":
      return "민원 댓글";
    case "complaint_registered":
      return "민원 접수";
    case "resident_card_registered":
      return "입주카드";
    case "car_registered":
      return "신규 정기 주차 신청";
    case "temperature_feedback_registered":
      return "온도 조절 요청";
    case "complaint_assigned":
      return "민원 배정";
    default:
      return "새 알림";
  }
};

export const format_notify_icon = (status: string) => {
  switch (status) {
    case "room_public_approval_request":
      return <MdLocalCafe />;
    case "complaint_commented":
    case "complaint_registered":
    case "complaint_assigned":
      return <MdQuestionAnswer />;
    case "resident_card_registered":
      return <MdAssignmentInd />;
    case "car_registered":
      return <MdDirectionsCar />;
    case "temperature_feedback_registered":
      return <RiTempHotLine />;
    case "tenant_master_auto_approval":
    case "tenant_master_approval_request":
      return <MdPersonAdd style={{ transform: "scaleX(-1)" }} />;
    default:
      return <RiLoaderLine />;
  }
};

export const format_notify_desc = (status: string) => {
  switch (status) {
    case "room_public_approval_request":
      return "회의실에 신규 예약건이 있어요.";
    case "tenant_master_auto_approval":
      return "입주자 마스터 계정이 자동 승인되었어요.";
    case "tenant_master_approval_request":
      return "입주자 마스터 계정 승인 요청이 있어요.";
    case "complaint_commented":
      return "민원에 새로운 댓글이 있어요.";
    case "complaint_registered":
      return "새로운 민원이 접수되었어요.";
    case "resident_card_registered":
      return "신규 입주자카드가 등록되었어요.";
    case "car_registered":
      return "새로운 정기주차 신청이 있어요.";
    case "temperature_feedback_registered":
      return "온도 조절 요청이 접수되었어요.";
    case "complaint_assigned":
      return "우리팀이 담당할 민원이 있어요.";
    default:
      return "새로운 알림을 받아오는 중입니다.";
  }
};

export const notification_action = (str: string | undefined) => {
  if (!str) return;

  switch (str) {
    case "room_public_approval_request":
      queryClient.invalidateQueries([public_list_key]);
      break;
    case "complaint_registered": //민원 페치 되는지 확인
    case "complaint_commented":
    case "complaint_assigned":
      queryClient.invalidateQueries([complaint_list_key]);
      break;
    case "tenant_master_approval_request":
      queryClient.invalidateQueries([company_list_key]);
      // isClicked &&
      //   (sessionStorage.setItem("tenant_info", `${route}`),
      //   console.log(route),
      //   navigate(`/company/list`));
      break;
    case "resident_card_registered":
      queryClient.invalidateQueries([register_card_key]);
      break;
    case "car_registered": //주차들어갈때 확인필요
      queryClient.invalidateQueries([parking_history_keys]);
      break;
    case "temperature_feedback_registered":
      queryClient.invalidateQueries([temperature_list_key, new Date()]);
      break;
  }
};

export const onActionInNotification = (navigate: NavigateFunction, type: string, data: string) => {
  switch (type) {
    case "tenant_master_auto_approval":
    case "tenant_master_approval_request":
      navigate(`/companies`);
      sessionStorage.setItem("tenant_info", `${data}`);
      break;
    case "resident_card_registered":
      navigate(`/company/registration`);
      sessionStorage.setItem("access_card", `${data}`);
      break;
    case "car_registered":
      navigate(`/parking`);
      sessionStorage.setItem("car_register", `${data}`);
      break;
    case "room_public_approval_request": //지금은 회의실 모달
      navigate(`/meeting-rooms`);
      sessionStorage.setItem("meeting_info", `${data}`);
      break;
    case "complaint_registered":
    case "complaint_commented":
    case "complaint_assigned":
      navigate(`/complaints/${data}`);
      break;
    case "temperature_feedback_registered":
      navigate("/temperature");
      break;
  }
};
