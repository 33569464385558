import * as C from "../../styled/index";
import styled from "@emotion/styled";
import { format_room } from "../../../lib/utils/dataFormat";
import { ITenant } from "../../../lib/types/Imodels";
import { Dispatch, SetStateAction } from "react";
import { FaRegCheckCircle } from "@react-icons/all-files/fa/FaRegCheckCircle";
import { breakPoints } from "../../styled/media";
import { ISelectTenant } from "./Tenant";

const ListBox = styled.div<{ c: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ c }) => (c === "even" ? "#F4F4F5" : "#fff")};
  cursor: pointer;
  position: relative;
  padding: 0 12px;
  cursor: pointer;
  svg {
    font-size: larger;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #eff6ff;
  }
`;

const ListItem = styled(C.FixedTitle)<{ w: string }>`
  ${({ w }) => ` width: ${w}`};
  padding: 12px 6px;
  &:first-of-type {
    padding: 12px 6px 12px 0px;
  }
  @media ${breakPoints.mobile} {
    padding: 12px 8px 12px 0;
    &:first-of-type {
      display: none;
    }
    :last-of-type {
      width: 70%;
    }
  }
`;

interface ICompanySearch {
  el: ITenant;
  idx: number;
  clicked: ISelectTenant | undefined;
  setClicked: Dispatch<SetStateAction<ISelectTenant | undefined>>;
}

export default function TenantItem(props: ICompanySearch) {
  const isSelect = props.clicked?._id === props.el._id;
  const onChecked = () => {
    props.setClicked(props.el);
  };

  return (
    <ListBox onClick={onChecked} className={isSelect ? "selected" : ""} c={props.idx % 2 === 0 ? "even" : "odd"}>
      <ListItem w="20%">{format_room(props.el.tenant_type)}</ListItem>
      <ListItem w="30%">{props.el.dong_ho}</ListItem>
      <ListItem w="50%">{props.el.name}</ListItem>
      {isSelect && <FaRegCheckCircle />}
    </ListBox>
  );
}
