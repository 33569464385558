import { Button, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useLocation, useSearchParams } from "react-router-dom";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import * as XLSX from "xlsx";
import { IRequest, ITemperatureHistory } from "../../../lib/types/Imodels";
import { success, warning } from "../../../lib/theme/toast";
import { complaint_file, temperature_file } from "../../../lib/api/queries/urls";
import { RiDownloadLine } from "@react-icons/all-files/ri/RiDownloadLine";
import styled from "@emotion/styled";
import { breakPoints } from "../../../components/styled/media";
import ResponsiveConfirm from "../../../components/modal/responsive/confirm";

interface IDownload {
  isToast: boolean;
  dateRange: string;
  search?: string;
  tabPath?: string;
  dong?: string;
  floor?: string;
  location?: string;
}

const DownloadButton = styled(Button)`
  @media ${breakPoints.mobile} {
    display: none;
  }
`;

//온도조절, 민원, 배정팀에서 모두 쓰임
export default function ExcelFileDownload(prop: IDownload) {
  const { dateRange, search, tabPath, dong, floor, location, ...props } = prop;

  const toast = useToast();
  const params = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure(); //데이터 추출

  const isNeedDepartment = params.pathname.includes("todo"); //온도조절인지 민원 or 팀여부 확인
  const isTemperture = params.pathname.includes("temperature");
  const [searchParams] = useSearchParams();
  const range = dateRange.split(" ~ ");

  const getComplaintList = () =>
    fetchClient.get<BaseResponse<IRequest[]>>(complaint_file, {
      params: {
        start_date: range[0],
        end_date: range[1],
        progress_type: searchParams.get("progress") || "all",
        complaint_type: searchParams.get("complaint") || "",
        search: search || undefined,
        department_id: isNeedDepartment ? tabPath : undefined,
      },
    });

  const getTemperature = () =>
    fetchClient.get<BaseResponse<ITemperatureHistory[]>>(temperature_file, {
      params: {
        dong,
        floor,
        location,
        start_date: range[0],
        end_date: range[1],
      },
    });

  const onSetToFile = (result: IRequest[] | ITemperatureHistory[]) => {
    const ws = XLSX.utils.json_to_sheet(result);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `${isTemperture ? "온도 조절" : "민원"} 현황`); //시트 이름을 '발송처리'로 한다.

    return XLSX.writeFile(wb, `${range[0]}_${range[1]}_${isTemperture ? "온도 조절" : "민원"}현황.xlsx`);
  };

  const onClickDownload = async () => {
    try {
      const result = await (isTemperture ? getTemperature() : getComplaintList());
      await onSetToFile(result.data.data);

      //결과
      toast({
        ...success,
        title: "데이터 추출",
        description: `${isTemperture ? "온도" : "민원"} 데이터 추출이 완료되었습니다. 다운로드된 파일을 확인해주세요.`,
      });
      onClose();
    } catch (err) {
      toast({
        ...warning,
        title: "데이터 추출",
        description: `일시적인 오류로 ${
          isTemperture ? "온도 조절" : "민원"
        } 데이터 추출에 실패했습니다. 잠시 후 다시 시도해주세요.`,
      });
    }
  };

  return (
    <>
      <DownloadButton
        mr={isTemperture ? "0" : "0.75rem"}
        variant="m_gray"
        onClick={() => {
          if (props.isToast)
            return toast({
              ...warning,
              title: "데이터 추출",
              description: "추출할 데이터가 없습니다. 필터를 다시 설정 후 시도해주세요.",
            });
          onOpen();
        }}
      >
        <RiDownloadLine />
        데이터 추출
      </DownloadButton>
      <ResponsiveConfirm
        title="데이터 추출"
        isOpen={isOpen}
        before="취소"
        next="추출"
        onClose={onClose}
        onAction={onClickDownload}
      >
        <Text>
          {dateRange} 내 {isTemperture ? "온도 조절" : "민원"} 데이터를 추출할까요?
        </Text>
        <Text fontSize="12px" pt="4px" color="#9ca3af">
          *한 파일 당 추출 기간은 최대 1년입니다.
        </Text>
      </ResponsiveConfirm>
    </>
  );
}
