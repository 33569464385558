import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from "@chakra-ui/react";
import { useDeleteNoticeMutation } from "../../../requests/notice";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  noticeId: string[];
};

export default function NoticeDeleteModal({ isOpen, onClose, noticeId }: Props) {
  const mutation = useDeleteNoticeMutation();

  return (
    <Modal variant="default" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mt="7.75rem">
        <ModalHeader>삭제</ModalHeader>
        <ModalBody>
          <p>체크하신 공지 내역을 모두 삭제할까요?</p>
          <p>삭제된 데이터는 복구할 수 없습니다.</p>
          <ModalFooter>
            <Button onClick={onClose} variant="cancel">
              취소
            </Button>
            <Button
              disabled={mutation.isLoading}
              onClick={() => {
                mutation.mutate(noticeId);
                onClose();
              }}
              variant="delete"
            >
              삭제
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
