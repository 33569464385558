import { useCallback, useState } from "react";
import { Text, Button, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { colors } from "../../../../components/styled/common";
import { Wrapper } from "../../../../components/styled";
import { ActiveBox, MainActionBox } from "../../../../components/styled/webApp";
import {
  HeaderSquare,
  SkySquare,
  TableBox,
  TableHeader,
  TrangleGraySquare,
} from "../../../maintenance-fee/commons/styled";
import SubframePart from "../../../../components/Main/subframePart";
import ResponsiveConfirm from "../../../../components/modal/responsive/confirm";
import { HiCheck } from "@react-icons/all-files/hi/HiCheck";
import FullPageSpinner from "../../../../components/Spinner";
import { MemoizedPointItem } from "./_view/pointItem";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { point_recharge, tenant_regular_point_list } from "../../../../lib/api/queries/urls";
import { warning } from "../../../../lib/theme/toast";
import { PointInputBox } from "../../commons/styled";
import { regular_point_list_key } from "../../../../lib/api/queries/keys";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { ITenantRegularPoint } from "../../../../lib/types/Imodels";
import GrayRadiusWithIcon from "../../../../components/Input/search";

const SetPointBox = styled.div`
  display: flex;
  color: ${colors.gray4};
`;

const PointApplyInputBox = styled(PointInputBox)`
  width: auto;
  margin-right: 0.75rem;
  padding: 0;
  justify-content: space-between;
  div {
    padding: 0.75rem;
  }
  .input-button {
    border-left: 1px solid ${colors.gray3};
    cursor: pointer;
  }
  input {
    width: 8.5rem;
  }
`;

const SaveButtonBox = styled(Wrapper)`
  min-height: auto;
  height: 5.5rem;
  margin-top: 0.5rem;
  justify-content: center;
  flex-direction: row;
  padding: 1rem;
`;

const PointActionBox = styled(MainActionBox)`
  width: 40rem;
`;

export default function PointSettingManage() {
  const toast = useToast();
  const [search, setSearch] = useState("");
  const [individual, setIndividual] = useState(false); //이거 트루면 입주사 개별 설정임
  const [applyBtn, setApplyBtn] = useState(false); //이거 트루면 적용 버튼 누른거
  const { isOpen, onOpen, onClose } = useDisclosure(); //포인트 설정 확인
  const [tenantArr, setTenantArr] = useState<ITenantRegularPoint[]>([]); //원래 보여줄 값
  const [changedArr, setChangedArr] = useState<ITenantRegularPoint[]>([]); //변경된 입주사 모음
  const [allPoints, setAllPoints] = useState<number>(); //설정할 포인트

  const { data, refetch } = useQuery([regular_point_list_key], () =>
    fetchClient.get<BaseResponse<ITenantRegularPoint[]>>(tenant_regular_point_list).then((res) => res.data.data),
  );

  const onChangePoint = useCallback(
    (el: ITenantRegularPoint, str: string) => {
      // const item = { ...el };
      // item.price = Number(str);
      el.point = Number(str);
      const newArr = [...changedArr];
      // console.log(newArr);
      if (changedArr.length === 0) return setChangedArr([el]);
      const idx = newArr.findIndex((tenant: ITenantRegularPoint) => tenant.tenant_id === el.tenant_id);
      idx === -1 ? newArr.push(el) : (newArr[idx] = el);
      setChangedArr(newArr);
      // console.log(fetchItemlist.data, "새거", changed);
      //바뀐것
      // if(newArr.length)
      // newArr.map((ele) => ele.tenant_id === el.tenant_id ? el : ele )
      // setChangedArr()
      //원본
      // const newArr = tenantArr.map((tenant: ITenantRegularPoint) =>
      //   tenant.tenant_id === el.tenant_id ? item : tenant
      // );
      // setTenantArr(newArr);
    },
    [changedArr],
  );

  const mutation = useMutation((newArr: { tenant_id: string; amount: number }[]) =>
    fetchClient
      .post(point_recharge, {
        // point_per_month: allPoints,
        point_per_month: allPoints === undefined ? 0 : allPoints,
        tenants_and_points: newArr,
      })
      .then(() => {
        onClose();
        alert(
          "입주사별 포인트가 설정되었습니다. 설정된 포인트는 입주사 포인트 이용내역 페이지에서 확인할 수 있습니다.",
        );
        refetch();
        //페이지 이동 없다면 초기값으로 설정 필요 - navigate("/point/history");
        setAllPoints(undefined);
        setIndividual(false);
        setApplyBtn(false);
        setTenantArr([]);
        setChangedArr([]);
      })
      .catch((err) => console.log(err)),
  );

  const onClickBeforeOpenModal = () => {
    if (allPoints === undefined && changedArr.length === 0)
      return toast({
        ...warning,
        title: "알림",
        description: "전체 포인트 혹은 입주사별로 개별 포인트 설정 후 등록해주세요.",
      });
    onOpen();
  };

  const onProcessBeforeSavePoint = () => {
    if (mutation.isLoading) return;
    const newArr: { tenant_id: string; amount: number }[] = [];

    if (individual && !applyBtn) {
      //포인트 작성 X, 개별 설정 0
      tenantArr.forEach((el: ITenantRegularPoint) => {
        if (el.point !== undefined) newArr.push({ tenant_id: el.tenant_id, amount: el.point });
      });
      // return console.log(newArr);
      // return mutation.mutate(newArr);
    } else if (applyBtn && changedArr.length === 0) {
      //포인트 작성 0, 개별 설정 X -> 빈배열로 console.log("여기는 Point만 보냄");
      return mutation.mutate([]);
    } else {
      //포인트 작성 0, 개별 설정 0
      changedArr.forEach((el: ITenantRegularPoint) => {
        if (el.point !== undefined) newArr.push({ tenant_id: el.tenant_id, amount: el.point });
      });
    }
    mutation.mutate(newArr);
    // newArr = changedArr.map((el: ITenantRegularPoint) => {
    //   const obj = { amount: 0, tenant_id: "" };
    //   obj.amount = el.point || 0;
    //   obj.tenant_id = el.tenant_id;
    //   return obj;
    // });
    // console.log(individual, newArr);
  };

  const { isOpen: isAll, onOpen: onAll, onClose: offAll } = useDisclosure(); //포인트 설정 확인
  const onClosBeforeChange = () => {
    offAll();
    setIndividual(true);
    setApplyBtn(false);
  };

  const onChangeAllPoint = () => {
    offAll();
    const newArr = data?.map((el) => ({
      ...el,
      point: allPoints,
    }));
    setTenantArr(newArr ? newArr : []);
    // setChangedPoint(undefined);
    if (changedArr.length !== 0) setChangedArr([]);
  };

  // const onOpenBatchPoint = _.debounce(() => onAll(), 1800);
  // const onChangeMemo = (e: ChangeEvent<HTMLInputElement>) => {
  //   setAllPoints(Number(e.target.value));
  //   if (changedArr.length !== 0 && allPoints !== undefined) {
  //     //변경사항 있으면 팝업
  //     // setChangedPoint(allPoints);
  //     return onOpenBatchPoint();
  //   }
  //   // setAllPoints(Number(e.target.value));
  //   if (Number(e.target.value) === 0 && changedArr.length !== 0)
  //     setChangedArr([]);
  //   if (!fetchItemlist.data) return;
  //   const newArr = fetchItemlist.data.map((el) => ({
  //     ...el,
  //     point: Number(e.target.value),
  //   }));
  //   setTenantArr(newArr);
  // };

  const onClickApplyPoint = () => {
    if (allPoints === undefined) return; //변경포인트가 없을때
    setApplyBtn(true); //변경포인트가 있고, 적용 버튼 누른 상태
    setIndividual(false);
    if (changedArr.length !== 0) return onAll(); //변경한 포인트가 있고, 매월 포인트를 다시 지정하려고할 때
    //변경한 포인트가 없을때, 매월 포인트를 다시 지정했을경우
    if (!data) return;
    const newArr = data.map((el) => ({
      ...el,
      point: allPoints,
    }));
    setTenantArr(newArr);
  };

  const onClickBeforeBack = () => {
    //되돌아가기 모달 여부
    if (allPoints !== undefined || changedArr.length !== 0) return true;
    return false;
  };

  return (
    <>
      <SubframePart
        name="입주사별 포인트 설정"
        styled={{
          minHeight: "auto",
          height: "calc(100% - 6rem)",
          overflowY: "auto",
        }}
        isWarnMessage={onClickBeforeBack}
      >
        <PointActionBox>
          <SetPointBox>
            <ResponsiveConfirm
              next="일괄 변경하기"
              before="취소"
              title="일괄 변경"
              isOpen={isAll}
              onClose={onClosBeforeChange}
              onAction={onChangeAllPoint}
            >
              <Text>지금 포인트를 변경하시면 모든 정보가 변경됩니다. </Text>
              <Text>일괄 변경하시겠습니까?</Text>
            </ResponsiveConfirm>
            <PointApplyInputBox>
              <Flex>
                매월
                <input
                  placeholder="1000"
                  value={allPoints === undefined ? "" : String(allPoints)}
                  // defaultValue={changedPoint || allPoints}
                  // value={isAll ? changedPoint : allPoints}
                  // value={changedPoint || allPoints || ""}
                  // onChange={(e) => {
                  //   setAllPoints(Number(e.target.value));
                  //   const newArr = fetchItemlist.data?.map((el) => ({
                  //     ...el,
                  //     point: Number(e.target.value),
                  //   }));
                  //   setTenantArr(newArr);
                  //   if (Number(e.target.value) === 0 && changedArr.length !== 0)
                  //     setChangedArr([]);
                  //   _.debounce((value: string) => {
                  //     console.log("팝업");
                  //   }, 3000);
                  // }}
                  onChange={(e) => {
                    if (Number.isNaN(Number(e.target.value))) return;
                    setAllPoints(Number(e.target.value));
                  }}
                  // onChange={(e) => onChangeMemo(e)}
                />
                P
              </Flex>
              <div className="input-button" onClick={onClickApplyPoint}>
                적용
              </div>
            </PointApplyInputBox>
            <ActiveBox
              isActive={changedArr.length === 0 ? individual : true}
              style={{ padding: "0.75rem 1rem" }}
              onClick={() => {
                if (!allPoints && !individual) {
                  setIndividual(true);
                  setTenantArr(data ? data : []);
                }
                return;
              }}
            >
              <HiCheck />
              입주사별 설정
            </ActiveBox>
          </SetPointBox>
          <GrayRadiusWithIcon
            _style={{ margin: "16px 0 24px 0" }}
            value={search}
            placeholder="입주사명, 호수로 검색가능"
            _onChange={(e) => setSearch(e.target.value)}
            _onKeyUp={(e) => e.key === "Enter" && refetch()}
            _onClick={() => refetch()}
          />
          {!data ? (
            <FullPageSpinner />
          ) : (
            <>
              {tenantArr.length !== 0 && (
                <>
                  <TableHeader>
                    <TableBox style={{ width: "7%" }}>
                      <TrangleGraySquare>
                        <div />
                      </TrangleGraySquare>
                    </TableBox>
                    <HeaderSquare w="30%">입주사명</HeaderSquare>
                    <HeaderSquare w="33%">호실</HeaderSquare>
                    <SkySquare w="30%">포인트</SkySquare>
                  </TableHeader>
                  {tenantArr.map((el: ITenantRegularPoint, idx: number) => (
                    <MemoizedPointItem key={String(el.tenant_id)} onChangePoint={onChangePoint} el={el} idx={idx + 1} />
                  ))}
                </>
              )}
            </>
          )}
        </PointActionBox>
      </SubframePart>
      {/* <ForSaveContainer>
        <HeaderBox>
          <LeftIcons onClick={() => navigate(-1)} />
          <p>입주사별 포인트 설정</p>
        </HeaderBox>       </ForSaveContainer>*/}
      <SaveButtonBox>
        <Button variant="m_blue" fontSize="1.25rem" w="15rem" onClick={onClickBeforeOpenModal}>
          등록완료
        </Button>
      </SaveButtonBox>
      <ResponsiveConfirm
        next="등록하기"
        before="취소"
        title="입주사별 포인트 설정"
        isOpen={isOpen}
        onClose={onClose}
        onAction={onProcessBeforeSavePoint}
      >
        <Text>작성하신 정보로 포인트를 설정할까요?</Text>
      </ResponsiveConfirm>
    </>
  );
}
