import { DrawerBody, Flex, UseModalProps } from "@chakra-ui/react";
import CommonDrawerModal from "../../Drawer";
import { format_date } from "../../../lib/utils/dataFormat";
import { format_notify_icon, format_notification, format_notify_desc, onActionInNotification } from "./func";
import styled from "@emotion/styled";
import { colors } from "../../styled/common";
import { useNavigate } from "react-router-dom";
import { fetchClient } from "../../../lib/api/axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { INotification } from "../../../lib/types/Imodels";
import { notification_group_key } from "../../../lib/api/queries/keys";
import { queryClient } from "../../..";
import { toast } from "../../../lib/utils/toast";

interface INotificationBox extends UseModalProps {
  data: INotification[];
  setDots: Dispatch<SetStateAction<boolean>>;
}

const NewAlert = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
`;

const NoMessage = styled.div`
  width: 100%;
  height: 6.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.gray4};
`;

const AlertLi = styled.li`
  list-style: none;
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 1rem 0px;
  cursor: pointer;
  font-weight: 500;
  .icon {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: ${colors.gray1};
    color: ${colors.gray2};
    border-radius: 12px;
    font-size: 1.75rem;
  }
  :last-of-type {
    padding-bottom: 3rem;
  }
`;

const AlertTitle = styled(Flex)`
  padding: 0;
  margin: 0;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 20px;
  line-height: 1.5;
  font-size: 0.75rem;
  font-weight: 400;
  > span {
    color: ${colors.gray4};
    padding-top: 4px;
  }
  div {
    font-weight: 500;
    font-size: 1rem;
    /* justify-content: space-between;
    span {
      color: ${colors.orange1};
      padding-left: 4px;
    } */
  }
`;

export default function NotificationBox(props: INotificationBox) {
  const navigate = useNavigate();

  const [newArr, setNewArr] = useState<INotification[]>([]);
  const [oldArr, setOldArr] = useState<INotification[]>([]);

  useEffect(() => {
    const newOne: INotification[] = [];
    const oldOne: INotification[] = [];

    props.data.forEach((el: INotification) => {
      el.is_checked ? oldOne.push(el) : newOne.push(el);
    });

    setOldArr(oldOne);
    setNewArr(newOne);
    props.setDots(newOne.length === 0 ? false : true);
  }, [props.data]);

  const isFetch = (fetch: INotification) =>
    fetchClient.post("/building/notification", {
      notification_id: fetch,
    });

  const onNotifyPage = async (fetch: INotification, id: string, data: string) => {
    // console.log("passed", sessionStorage.getItem("meeting_info"));
    //1. 빌딩 승인 여부
    //2. 알림 상태
    if (!data) return;

    try {
      await isFetch(fetch);

      onActionInNotification(navigate, id, data);
      if (newArr.length === 1) {
        props.setDots(false);
        queryClient.invalidateQueries([notification_group_key]);
      }
      props.onClose();
    } catch (err) {
      toast.warning({
        title: "알림",
        description: "알림과 관련된 데이터를 요청 중입니다. 잠시 후 다시 시도해주세요.",
      });
    }
  };

  // const sortDate = (a: INotification, b: INotification) => {
  //   const prev = a.createdAt || new Date();
  //   const next = b.createdAt || new Date();

  //   return prev > next ? -1 : prev < next ? 1 : 0;
  // };

  return (
    <CommonDrawerModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      closeOnOverlayClick={true}
      title="알림"
      styled={{ borderBottom: "none", fontWeight: 500 }}
    >
      <DrawerBody style={{ color: "#111827" }}>
        <NewAlert>새로운 알림</NewAlert>
        {newArr.length === 0 ? (
          <NoMessage>새로운 알림이 없습니다.</NoMessage>
        ) : (
          <ul style={{ width: "100%" }}>
            {newArr.map((el) => (
              <AlertLi
                key={String(el._id)}
                id={String(el._id)}
                onClick={() =>
                  onNotifyPage(
                    el._id,
                    el.notification_type,
                    el.notification_type === "tenant_master_approval_request" ||
                      el.notification_type === "tenant_master_auto_approval"
                      ? el.tenant_id
                      : el.data,
                  )
                }
              >
                <div className="icon">{format_notify_icon(el.notification_type)}</div>
                <AlertTitle>
                  <p>{format_notification(el.notification_type)}</p>
                  <div>{format_notify_desc(el.notification_type)}</div>
                  <span>{format_date(el.createdAt)}</span>
                </AlertTitle>
              </AlertLi>
            ))}
          </ul>
        )}
        <NewAlert>지난 알림</NewAlert>
        {oldArr.length === 0 ? (
          <NoMessage>지난 알림이 없습니다.</NoMessage>
        ) : (
          <ul style={{ width: "100%" }}>
            {oldArr.map((el) => (
              <AlertLi
                key={String(el._id)}
                id={String(el._id)}
                onClick={() =>
                  onNotifyPage(
                    el._id,
                    el.notification_type,
                    el.notification_type === "tenant_master_approval_request" ||
                      el.notification_type === "tenant_master_auto_approval"
                      ? el.tenant_id
                      : el.data,
                  )
                }
              >
                <div className="icon">{format_notify_icon(el.notification_type)}</div>
                <AlertTitle>
                  <p>{format_notification(el.notification_type)}</p>
                  <div>{format_notify_desc(el.notification_type)}</div>
                  <span>{format_date(el.createdAt)}</span>
                </AlertTitle>
              </AlertLi>
            ))}
          </ul>
        )}
      </DrawerBody>
    </CommonDrawerModal>
  );
}
