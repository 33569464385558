import { IRegistListUI } from "../../commons/type";
import { format_registration, format_room } from "../../../../lib/utils/dataFormat";
import moment from "moment";
import * as S from "../../../../components/styled";
import { useRoomType } from "../../../../lib/utils";

export default function CardListItems(props: IRegistListUI) {
  const room_type = useRoomType();

  const onOpenRegisterCard = () => {
    if (!props.el) return;
    props.setIsModalOpen(true);
    sessionStorage.setItem("access_card", String(props.el._id));
  };

  return (
    <>
      <S.ListTableBody w={room_type.length > 1 ? "20%" : "25%"} onClick={onOpenRegisterCard}>
        <S.FixedTitle style={{ textAlign: "center" }}>{props.el.tenant_name}</S.FixedTitle>
        {room_type.length > 1 && <div>{format_room(props.el.room_type)}</div>}
        <div>{`${props.el.dong_ho.split(" ")[0]}동 ${props.el.dong_ho.split(" ")[1]}호 ${
          props.el.room_num > 1 ? `외 ${props.el?.room_num - 1}` : ""
        }`}</div>
        <div>{moment.utc(props.el.createdAt).format("YYYY-MM-DD HH:mm")}</div>
        <div>{format_registration(props.el.registration_type)}</div>
      </S.ListTableBody>
    </>
  );
}
