import { InputProps } from "@chakra-ui/react";
import { InputStyle } from "../styled";

export const MoreContentInputs: React.FC<InputProps> = ({ ...props }) => {
  return (
    <>
      <InputStyle
        w="100%"
        variant="unstyled"
        autoComplete="off"
        {...props}
      ></InputStyle>
    </>
  );
};

export default MoreContentInputs;
