import { Outlet } from "react-router-dom";
import CustomHeading from "../../components/CustomHeading";
import useUserAuth from "../../components/hooks/useUserAuth";
import GetAuthorized from "../../components/Authrized";

export default function CompanyLayout() {
  const auth = useUserAuth("tenant");

  if (auth === "no_auth") return <GetAuthorized />;

  return (
    <div className="w-full">
      <CustomHeading
        headings={[
          {
            text: "입주사 관리",
            path: "/companies",
          },
          {
            text: "입주카드",
            path: "/companies/cards",
          },
        ]}
        sizeTheme="md"
      />
      <Outlet />
    </div>
  );
}
