import {
  Text,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
  Select,
  RadioGroup,
  Radio,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import PublicCheckedBox from "../../components/checkBox/checkTenant";
import * as C from "../../../../components/styled/index";
import * as R from "./styled";
import { allTime, format_room } from "../../../../lib/utils/dataFormat";
import { ChangeEvent, useContext, useState } from "react";
import SelectButton from "../../../../components/selectBox/checkedOption/buttonItems";
import { success, unAuthUser, warning, WarningTime } from "../../../../lib/theme/toast";
import { fetchClient } from "../../../../lib/api/axios";
import { public_update_batch } from "../../../../lib/api/queries/urls";
import { IUpdateBatch, IUpdateMeetingRoom, IUpdatePost } from "../../commons/types";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import SingleRow, { WithBox, WrapFlex } from "../../../../components/modal/table/singleRow";
import { useRoomType } from "../../../../lib/utils";
import SmallInputs from "../../../../components/Input/shortText";
import { queryClient } from "../../../..";
import { public_detail_key, public_list_key } from "../../../../lib/api/queries/keys";
import { Controller, useForm } from "react-hook-form";
import { GlobalContext } from "../../../../App";
import { AxiosError } from "axios";
import { onChangeNumbers } from "../../commons/func";

export default function UpdateBatchMeetingRoom(props: IUpdateBatch) {
  const toast = useToast();
  const { buildInfo } = useContext(GlobalContext);
  const { isOpen, onClose, onOpen } = useDisclosure(); // 일괄 변경 확정 모달
  const { isOpen: isCancel, onClose: offCancle, onOpen: onCancle } = useDisclosure(); // 되돌아가기 모달
  const room_type = useRoomType();

  const { handleSubmit, control, resetField, getValues } = useForm<IUpdateMeetingRoom>({
    mode: "onChange",
    // defaultValues: {
    //   guide: undefined,
    //   isUserApproved: undefined,
    //   method: undefined,
    //   payment_description: undefined,
    //   people: undefined,
    //   price: undefined,
    // },
  });

  const [checkedType, setCheckedType] = useState<string[]>([]);
  const [options, setOptions] = useState("");
  const [optionArr, setOptionArr] = useState<string[]>([]);
  const [days, setDays] = useState("");
  const [start, setStarts] = useState("");
  const [end, setEnds] = useState("");
  const [timeArr, setTimeArr] = useState<string[]>([]);
  const [updateItem, setUpdateItem] = useState<string[]>([]);

  const onCheckedAll = (checked: boolean) => {
    if (checked) {
      const allCheckedArr: string[] = [];
      room_type?.forEach((el) => allCheckedArr.push(el.label));
      setCheckedType(allCheckedArr);
    } else {
      setCheckedType([]);
    }
  };

  const onChangeChecked = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedType([...checkedType, id]);
    } else {
      const newArr = checkedType.filter((el) => el !== id);
      if (newArr !== undefined) setCheckedType(newArr);
    }
    if (checkedType.length === room_type.length || 0) {
      return (checked = true);
    }
  };

  const onDeleteType = (str: string) => {
    const newArr = checkedType.filter((el) => el !== str);
    setCheckedType(newArr);
  };

  const onChangeOption = () => {
    const newArr = [...optionArr];
    if (options === "")
      return toast({
        ...warning,
        title: "알림",
        description: "시설 옵션을 입력 후 추가해주세요.",
      });

    if (newArr.includes(options) || newArr.includes(options.toLocaleUpperCase()))
      return toast({
        ...warning,
        title: "알림",
        description: "이미 포함된 시설 옵션입니다.",
      });

    newArr.push(options);
    setOptions("");
    setOptionArr(newArr);
  };

  const OptionDel = (idx: number) => {
    const newArr = optionArr.filter((_, index) => index !== idx);
    setOptionArr(newArr);
  };

  const onClickAdd = () => {
    if (days === "")
      return toast({
        ...warning,
        title: "알림",
        description: "평일 혹은 주말을 먼저 선택해주세요.",
      });

    if (!start || !end)
      return toast({
        ...warning,
        title: "알림",
        description: "시간을 정확히 선택해주세요.",
      });

    if (days === "평일") {
      const newArr = timeArr.filter((el) => !el.includes("평일"));
      setTimeArr([...newArr, `평일 ${start} - ${end}`]);
    } else {
      const newArr = timeArr.filter((el) => !el.includes("주말"));
      setTimeArr([...newArr, `주말 ${start} - ${end}`]);
    }
  };

  const onSetStartTime = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = moment(`2001-01-01T${e.target.value}:00`);
    const prev = moment(`2001-01-01T${!end ? "24:00" : end}:00`);
    // const holi = moment(`2001-01-01T${selectAll.start_time_holiday}:00`);

    if (moment.duration(prev.diff(value)).asHours() <= 0)
      return toast({
        ...WarningTime,
        description: "시작시간은 종료시간보다 이후거나 같을 수 없습니다.",
      });

    setStarts(e.target.value);
  };

  const onSetEndTime = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = moment(`2001-01-01T${e.target.value}:00`);
    const prev = moment(`2001-01-01T${!start ? "00:00" : start}:00`);
    // const holi = moment(`2001-01-01T${selectAll.start_time_holiday}:00`);

    if (moment.duration(prev.diff(value)).asHours() >= 0)
      return toast({
        ...WarningTime,
        description: "종료시간은 시작시간보다 이전이거나 같을 수 없습니다.",
      });

    setEnds(e.target.value);
  };

  const onDeleteTime = (idx: number) => {
    const newArr = timeArr.filter((_, index) => index !== idx);
    setTimeArr(newArr);
  };

  const onCheckedEditedItem = () => {
    const temp: string[] = [];
    checkedType.length && temp.push("입주사 조건");
    optionArr.length && temp.push("시설 옵션");
    timeArr.length && temp.push("이용 가능 시간");

    const checkedTime = timeArr.find((el) => el.includes("주말"));
    if (checkedTime && timeArr.length === 1)
      return toast({
        ...warning,
        title: "알림",
        description: "이용 가능 시간 설정에서 평일은 필수 선택입니다.",
      });

    const format_keys = (str: string) => {
      switch (str) {
        case "description":
          return "예약정책";
        case "payment_description":
          return "결제안내";
        case "people_num":
          return "최대 수용 인원";
        case "method":
          return "결제수단";
        default:
          return "금액";
      }
    };

    const { is_approval_required, ...result } = getValues();
    if (is_approval_required !== undefined) temp.push("예약확정 방식");
    for (const keys in result) {
      if (result[keys as keyof typeof result]) {
        const key = format_keys(keys);
        temp.push(key);
      }
    }

    if (temp.length === 0)
      return toast({
        ...warning,
        title: "알림",
        description: "변경된 시설 정보가 없습니다. 변경할 정보를 다시 확인 후 저장해주세요.",
      });

    setUpdateItem(temp);
    onOpen();
  };

  const mutation = useMutation((obj: IUpdatePost) => fetchClient.put(public_update_batch, obj), {
    onSuccess: (res) => {
      props.setCheckedItems([]);
      queryClient.invalidateQueries([public_list_key]);
      queryClient.invalidateQueries([public_detail_key]); // 타켓Id 없으면 무효화 안해도됨, 상세 정보를 본 뒤 일괄 변경을 했으면 무효화 필요
      toast({
        ...success,
        title: "회의실 일괄 변경",
        description: "회의실 정보가 일괄 변경되었습니다. 변경된 정보는 회의실 상세 정보에서 확인할 수 있습니다.",
      });
      props.onClose();
      return res.data.data;
    },
    onError: (err: AxiosError) => {
      if (err.response?.status === 401) {
        toast({ ...unAuthUser });
        return undefined;
      }
      if (err.response?.status === 501) {
        toast({
          ...warning,
          title: "회의실 일괄 변경",
          description: "이용 가능 시간 설정에서 평일은 필수 선택입니다.",
        });
        return undefined;
      }
    },
  });

  const onSubmitBatch = (data: IUpdateMeetingRoom) => {
    const obj: IUpdatePost = {
      room_public_ids: props.checkedItems,
    };

    if (optionArr.length !== 0) obj.options = optionArr;
    if (checkedType.length !== 0) obj.available_rooms = checkedType;

    const weekdays = timeArr.filter((el) => el.includes("평일"));
    const holidays = timeArr.filter((el) => el.includes("주말"));
    if (weekdays.length !== 0) {
      obj.start_time_weekday = `2000-01-01T${weekdays[0].split(" ")[1]}Z`;
      obj.end_time_weekday = `2000-01-01T${
        weekdays[0].split(" ")[3] === "24:00" ? "00:00" : weekdays[0].split(" ")[3]
      }Z`;
    }
    if (holidays.length !== 0) {
      obj.start_time_holiday = `2000-01-01T${holidays[0].split(" ")[1]}Z`;
      obj.end_time_holiday = `2000-01-01T${
        holidays[0].split(" ")[3] === "24:00" ? "00:00" : holidays[0].split(" ")[3]
      }Z`;
    }

    if (data.people_num && data.people_num !== "0") obj.people_num = Number(data.people_num);
    obj.is_approval_required = data.is_approval_required;
    obj.payment_description = data.payment_description;
    obj.description = data.description;
    if (data.method !== undefined) {
      if (data.method === "point") {
        obj.payment_method_and_price = [{ payment_method: data.method, price: Number(data.price || "") }]; //포인트가 있을때만, 혹시나 method 선택없이 추가 되는 것 방지
      } else {
        obj.payment_method_and_price = [{ payment_method: data.method }];
      }
    }

    mutation.mutate(obj);
  };

  return (
    <Modal variant="contents" onClose={props.onClose} isOpen={props.isOpen}>
      <ModalOverlay />
      <form
        onKeyDown={(e) => {
          if (e.code === "Enter") return e.preventDefault();
        }}
      >
        <ModalContent w="860px" maxW="860px">
          <ModalHeader>회의실 정보 일괄 변경</ModalHeader>
          <ModalBody>
            <R.InfoBox>
              <p>주의사항</p>
              <ul style={{ padding: "0 0 0 24px" }}>
                <li>일괄변경이 필요한 조건을 모두 선택해주시고, 모든 옵션 선택 후에는 저장까지 해주셔야 변경됩니다.</li>
                <li>
                  일괄변경 후에도 <span style={{ textDecoration: "underline" }}>이미 예약 완료된 건은 그대로 유지</span>
                  되어 입주자가 해당 시설을 이용할 수 있습니다.
                </li>
              </ul>
            </R.InfoBox>
            <C.IntervalBox>
              <Text variant="title">1. 기본 정보</Text>
              <SingleRow title="최대 수용 인원">
                <Controller
                  control={control}
                  name="people_num"
                  render={({ field: { onChange, ...field } }) => (
                    <R.RoomInput
                      type="number"
                      onWheel={(e) => e.currentTarget.blur()}
                      onChange={(e) => {
                        const value = onChangeNumbers(e);
                        if (value === "false") return;
                        onChange(value);
                      }}
                      placeholder="숫자만 입력 가능"
                      {...field}
                    />
                  )}
                />
                <C.WordBox>명</C.WordBox>
              </SingleRow>
              <SingleRow title="시설 옵션">
                <WrapFlex style={{ padding: 0, alignItems: "flex-start" }}>
                  <Flex>
                    <R.RoomInput
                      style={{ marginRight: "8px", minWidth: "250px" }}
                      value={options}
                      onChange={(e) => setOptions(e.target.value)}
                      placeholder="모니터, 칠판 등 시설 옵션 입력"
                    />
                    <Button onClick={onChangeOption} variant="cancel">
                      추가
                    </Button>
                  </Flex>
                  <div style={{ width: "100%" }}>
                    {optionArr.map((el, index) => (
                      <C.TagArr key={el}>
                        {el}
                        <C.TagCloseIcon onClick={() => OptionDel(index)} />
                      </C.TagArr>
                    ))}
                  </div>
                </WrapFlex>
              </SingleRow>
            </C.IntervalBox>
            <C.IntervalBox>
              <Text variant="title">2. 운영 조건</Text>
              {room_type.length > 1 && (
                <SingleRow title="입주사 조건">
                  <WithBox style={{ padding: 0, alignItems: "flex-start" }}>
                    <PublicCheckedBox default="입주사 전체" checkedItems={checkedType} onCheckedAll={onCheckedAll}>
                      {room_type?.map((el) => (
                        <SelectButton
                          key={el.label}
                          checkedItems={checkedType}
                          onChangeChecked={onChangeChecked}
                          value={el.value}
                          label={el.label}
                        />
                      ))}
                    </PublicCheckedBox>
                    <div>
                      {JSON.stringify(room_type.map((el) => el.label)) === JSON.stringify(checkedType) ? (
                        <C.TagArr>
                          입주사 전체
                          <C.TagCloseIcon onClick={() => onCheckedAll(false)} />
                        </C.TagArr>
                      ) : (
                        <>
                          {checkedType?.map((el) => (
                            <C.TagArr key={el}>
                              {format_room(el)}
                              <C.TagCloseIcon onClick={() => onDeleteType(el)} />
                            </C.TagArr>
                          ))}
                        </>
                      )}
                    </div>
                  </WithBox>
                </SingleRow>
              )}
              {/* 추후 추가 예정 <PublicTable title="위치 조건">
                <Select w={"100px"} marginRight="2" variant="fitContent">
                  동 선택
                </Select>
                <Select w={"100px"} marginRight="2" variant="fitContent">
                  층 선택
                </Select>
                <DefaultButton>추가</DefaultButton>
              </PublicTable> */}
              <SingleRow title="이용 가능 시간">
                <WithBox style={{ padding: 0, alignItems: "flex-start" }}>
                  <Flex alignItems="center">
                    <Select
                      w="100px"
                      mr="2"
                      variant="fitContent"
                      value={days}
                      onChange={(e) => setDays(e.target.value)}
                    >
                      <option value="">선택</option>
                      <option value="평일">평일</option>
                      <option value="주말">주말</option>
                    </Select>
                    <Select mr="2" w="100px" variant="fitContent" onChange={onSetStartTime} value={start}>
                      <option value="">오픈시간</option>
                      {allTime.map((el) => (
                        <option key={el} value={el}>
                          {el}
                        </option>
                      ))}
                    </Select>
                    -
                    <Select w="100px" margin="0px 8px" variant="fitContent" onChange={onSetEndTime} value={end}>
                      <option value="">종료시간</option>
                      {allTime.map((el) => (
                        <option key={el} value={el}>
                          {el}
                        </option>
                      ))}
                    </Select>
                    <Button onClick={onClickAdd} variant="cancel">
                      추가
                    </Button>
                  </Flex>
                  <div>
                    {timeArr.map((el, index) => (
                      <C.TagArr key={el}>
                        {el}
                        <C.TagCloseIcon onClick={() => onDeleteTime(index)} />
                      </C.TagArr>
                    ))}
                  </div>
                </WithBox>
              </SingleRow>
            </C.IntervalBox>
            <C.IntervalBox>
              <Text variant="title">3. 예약</Text>
              <SingleRow title="예약확정 방식">
                <Controller
                  control={control}
                  name="is_approval_required"
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      color="#6B7280"
                      onChange={(e) => onChange(JSON.parse(e))}
                      value={String(value)}
                      // onChange={(e) => setApproval(e)}
                      // value={approval}
                    >
                      <Radio mr={9} value="false" variant="default">
                        예약신청과 동시에 확정
                      </Radio>
                      <Radio value="true" variant="default">
                        관리자 확인 후 확정
                      </Radio>
                    </RadioGroup>
                  )}
                />
              </SingleRow>
              <SingleRow title="결제수단 및 금액">
                <span style={{ paddingRight: "40px" }}>30분 /</span>
                <Controller
                  control={control}
                  name="method"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      w="160px"
                      mr="1"
                      variant="fitContent"
                      onChange={(e) => (resetField("price"), onChange(e))}
                      value={value}
                    >
                      <option disabled={!buildInfo?.is_conference_room_point_used} value="">
                        결제수단
                      </option>
                      <option disabled={!buildInfo?.is_conference_room_point_used} value="point">
                        포인트
                      </option>
                      <option value="free">무료</option>
                    </Select>
                  )}
                />
                <Controller
                  control={control}
                  name="price"
                  render={({ field: { onChange, value } }) => (
                    <SmallInputs
                      isDisabled={getValues().method === "free" ? true : !buildInfo?.is_conference_room_point_used}
                      _disabled={{ backgroundColor: "#f4f4f5" }}
                      style={{ minWidth: "208px" }}
                      placeholder="결제 금액 입력"
                      onChange={(e) => {
                        const value = onChangeNumbers(e);
                        if (value === "false") return;
                        onChange(value);
                      }}
                      onWheel={(e) => e.currentTarget.blur()}
                      type="number"
                      value={value || ""} //가격 undefined 가능성 O
                    />
                  )}
                />
              </SingleRow>
              <SingleRow title="예약정책">
                <WrapFlex>
                  <Controller
                    control={control}
                    name="description"
                    render={({ field }) => (
                      <C.BasicTextArea
                        h="208px"
                        // value={policy}
                        placeholder="회의실 예약 시 숙지해야 할 정책을 작성해주세요."
                        // onChange={(e) => setPolicy(e.target.value)}
                        {...field}
                      />
                    )}
                  />
                </WrapFlex>
              </SingleRow>
              <SingleRow title="결제안내">
                <WrapFlex>
                  <Controller
                    control={control}
                    name="payment_description"
                    render={({ field }) => (
                      <C.BasicTextArea
                        h="208px"
                        // value={policy}
                        placeholder="결제 시 안내 사항을 작성해주세요."
                        // onChange={(e) => setPolicy(e.target.value)}
                        {...field}
                      />
                    )}
                  />
                </WrapFlex>
              </SingleRow>
            </C.IntervalBox>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onCancle} type="button" variant="cancel">
              취소
            </Button>
            <ConfirmModal
              close="확인"
              title="되돌아가기"
              isOpen={isCancel}
              onClickCancle={offCancle}
              onClickSave={props.onClose}
              onClose={offCancle}
              blockScrollOnMount={false}
            >
              <Text>지금까지 작성하신 내용은 저장되지 않습니다.</Text>
              <Text>회의실 설정 페이지로 이동할까요?</Text>
            </ConfirmModal>
            <Button variant="bgBlue" onClick={onCheckedEditedItem}>
              저장하기
            </Button>
          </ModalFooter>
        </ModalContent>
        <ConfirmModal
          close="변경하기"
          title="회의실 정보 일괄 변경"
          isOpen={isOpen}
          onClickCancle={onClose}
          onClickSave={handleSubmit(onSubmitBatch)}
          onClose={onClose}
          styleConfig={{ marginTop: "40px" }}
          blockScrollOnMount={false}
        >
          {mutation.isLoading ? (
            <div style={{ minHeight: "72px", position: "relative" }}>
              <C.CustomSpin thickness="2px" speed="0.65s" size="lg" />
            </div>
          ) : (
            <div>
              일괄 변경되는 항목을 다시 한번 확인해주세요.
              <R.UpdateList>
                <li>
                  변경 항목 :{updateItem.map((el, index) => (index === updateItem.length - 1 ? ` ${el}` : ` ${el},`))}
                </li>
              </R.UpdateList>
              <Text pt="6">계속해서 선택하신 회의실 정보를 변경할까요?</Text>
            </div>
          )}
        </ConfirmModal>
      </form>
    </Modal>
  );
}
