import { useNavigate, useParams } from "react-router-dom";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../App";
import useUserAuth from "../../../components/hooks/useUserAuth";
import BillLetterView from "../component/DetailBillLetter";
import { useQuery } from "@tanstack/react-query";
import { manage_fee_list } from "../../../lib/api/queries/urls";
import { manage_fee_list_key } from "../../../lib/api/queries/keys";
import { fetchClient } from "../../../lib/api/axios";
import { IRentalList } from "../../../lib/types/Imodels";
import { usePagination } from "@ajna/pagination";
import FullPageSpinner from "../../../components/Spinner";
import GetAuthorized from "../../../components/Authrized";
import { useDeleteLetter } from "../commons/queries";
import NotFound from "../../not-found";

export default function MaintenanceFeeDetailPage() {
  const { buildInfo } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { billId } = useParams();
  const hasFunc = buildInfo?.services.MAINTENANCE_FEE?.enabled;
  const auth = useUserAuth("management_fee");
  const { mutation } = useDeleteLetter(navigate);

  const [dong, setDong] = useState("");
  const [status, setStatus] = useState("");
  const [inputs, setInputs] = useState({ id: "search_ho", value: "" });
  const [allPage, setAllPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const { currentPage, setCurrentPage } = usePagination({
    initialState: { currentPage: 1 },
  });

  const { data, refetch } = useQuery(
    [manage_fee_list_key, currentPage],
    () =>
      fetchClient
        .get<BaseResponse<IRentalList>>(manage_fee_list, {
          params: {
            limit: 10,
            page: currentPage,
            management_fee_id: billId,
            upload_type: buildInfo?.management_fee_upload_type,
            payment_status: status,
            dong,
            [inputs.id]: inputs.value,
          },
        })
        .then((res) => {
          setAllPage(res.data.total_page_num || 1);
          setTotal(res.data.total_num || 0);
          return res.data.data;
        }),
    {
      enabled: !!buildInfo && (auth === "read" || auth === "edit"),
      keepPreviousData: true,
    },
  );

  const onRefetch = () => {
    if (currentPage !== 1) return setCurrentPage(1);
    refetch();
  };

  const onDeleteBill = () => {
    if (mutation.isLoading || !billId) return;
    mutation.mutate(billId);
  };

  if (hasFunc === undefined || !auth) return <FullPageSpinner />;
  if (!hasFunc) return <NotFound />;
  if (auth === "no_auth") return <GetAuthorized />;
  return (
    <BillLetterView
      auth={auth}
      data={data}
      inputs={inputs}
      setInputs={setInputs}
      dong={dong}
      setDong={setDong}
      status={status}
      setStatus={setStatus}
      onRefetch={onRefetch}
      total={total}
      onDeleteBill={onDeleteBill}
      page={allPage}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      isDirect={buildInfo?.management_fee_upload_type === "direct"}
    />
  );
}
