import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Input from "../../../../components/Input/Input";
import Button from "../../../../components/button/Button";
import IconSearch from "../../../../components/icons/IconSearch";
import IconWhitePlus from "../../../../components/icons/IconWhitePlus";
import Icon from "../../../../types/iconsax";
import formatPhoneNumber from "../../../../utils/formatPhoneNumber";
import { useCompanyDetailQuery } from "../../../../requests/company";
import CompanyAddMemberModal from "../../../../views/company/modal/CompanyAddMemberModal";
import { useOverlay } from "@toss/use-overlay";
import CompanyModifyPermissionModal from "../../../../views/company/modal/CompanyModifyPermissionModal";
import CompanyDeleteMemberModal from "../../../../views/company/modal/CompanyDeleteMemberModal";
import CustomTooltip from "../../../../components/CustomTooltip";
import { twMerge } from "tailwind-merge";
import { useCallback, useRef } from "react";
import { useDebounce } from "../../../../hooks/operators/use-debounce";
import useUserAuth from "../../../../components/hooks/useUserAuth";

const PERMISSION = {
  1: "일반멤버",
  2: "관리멤버",
  3: "최고관리자",
};

export default function CompanyDetailMemberPage() {
  const { companyId } = useParams() as { companyId: string };

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const params = new URLSearchParams(search);

  const auth = useUserAuth("tenant");
  const isEdit = auth === "edit";

  const searchText = searchParams.get("searchText") ?? "";

  const createQueryString = useCallback(
    (key: string, value: string, deleteKey?: string[]) => {
      value !== "" ? params.set(key, value) : params.delete(key);
      deleteKey && deleteKey.forEach((item) => params.delete(item));
      params.delete("page");
      return params.toString();
    },
    [search],
  );

  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleTextChange = useDebounce(
    (text) => {
      navigate(pathname + "?" + createQueryString("searchText", text));
    },
    300,
    [search],
  );

  const { data } = useCompanyDetailQuery(companyId);

  const filterData =
    data?.data?.members?.filter((v) => v.name?.includes(searchText) || v.phoneNumber?.includes(searchText)) || [];

  const masterMember = filterData.find((v) => v.permission === 3);

  const userMember = filterData.filter((v) => v.permission !== 3);

  const permissionCnt = [0, 0, 0];
  data?.data?.members?.map((item) => {
    permissionCnt[item.permission - 1] += 1;
  });

  const overlay = useOverlay();

  const handleAddMemberClick = async (target: "master" | "member") => {
    overlay.open(({ isOpen, close }) => (
      <CompanyAddMemberModal
        isOpen={isOpen}
        onClose={close}
        target={target}
        companyId={companyId}
        companyName={data?.data.name ?? ""}
      />
    ));
  };

  const handleModifyPermissionClick = async (memberId: string) => {
    const member = data?.data.members.find((v) => v.id === memberId);

    if (!member) return;

    overlay.open(({ isOpen, close }) => (
      <CompanyModifyPermissionModal
        isOpen={isOpen}
        onClose={close}
        memberData={{ ...member, companyId: companyId, companyName: data?.data.name ?? "" }}
        hasMaster={masterMember ? true : false}
      />
    ));
  };

  const handleDeleteMemberClick = (memberId: string) => {
    const member = data?.data.members.find((v) => v.id === memberId);

    if (!member) return;

    overlay.open(({ isOpen, close }) => (
      <CompanyDeleteMemberModal
        isOpen={isOpen}
        onClose={close}
        memberData={{ ...member, companyId: companyId, companyName: data?.data.name ?? "" }}
      />
    ));
  };

  return (
    <div className="mb-[60px] flex flex-col gap-3 px-6 pt-6">
      <div className="flex w-full items-center justify-between">
        <p className="text-sm font-semibold text-black">{`최고관리자 ${permissionCnt[2]}명 관리멤버 ${permissionCnt[1]}명 일반 ${permissionCnt[0]}명`}</p>
        <div className="flex flex-1 items-center justify-end gap-3">
          <div className="flex w-full max-w-[444px]">
            <Input
              ref={searchInputRef}
              themeSize="sm"
              placeholder="이름 또는 연락처"
              defaultValue={searchText}
              iconElement={<IconSearch className="h-5 w-5" />}
              onChange={(e) => handleTextChange(e.target.value)}
              maxLength={20}
              showDeleteButton={true}
              onDelete={() => {
                if (searchInputRef.current) searchInputRef.current.value = "";
                navigate(pathname + "?" + createQueryString("searchText", ""));
              }}
            />
          </div>
          {isEdit && (
            <Button
              designSchema={"primary"}
              buttonText={"구성원 추가"}
              size={"sm"}
              iconElement={<IconWhitePlus />}
              onClick={() => handleAddMemberClick(masterMember ? "member" : "master")}
            />
          )}
        </div>
      </div>
      <div className="relative flex max-h-[573px] flex-col divide-y divide-gray-200 overflow-y-auto rounded-xl border border-gray-200">
        {masterMember ? (
          <div className="flex items-center">
            <div className="flex w-full items-center">
              <div className="flex w-1/4 items-center gap-4 px-6 py-4">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600">
                  <Icon.Crown1 variant="Bold" size={20} color="#EEF2FF" />
                </span>
                <span
                  className={twMerge(
                    "break-all text-sm font-medium text-gray-900",
                    (!masterMember.name || masterMember.name?.trim() === "") && "text-gray-400",
                  )}
                >
                  {masterMember.name?.trim() || "이름 없는 구성원"}
                </span>
              </div>
              <div className="flex w-1/4 flex-col px-6 py-4">
                <span className="text-sm font-medium text-gray-900">
                  {formatPhoneNumber(masterMember.phoneNumber?.trim() || "-")}
                </span>
              </div>
              <p className="flex w-1/4 px-6 py-4 text-sm font-medium text-gray-900">
                {masterMember.userId ? "가입완료" : "미가입"}
              </p>
              <div className="flex w-1/4 gap-2 px-6 py-4">
                <span className="text-sm font-medium text-gray-900">최고관리자</span>
                {isEdit && (
                  <span
                    className="cursor-pointer text-sm font-medium text-gray-500 underline underline-offset-2"
                    onClick={() => handleModifyPermissionClick(masterMember.id)}
                  >
                    변경
                  </span>
                )}
              </div>
            </div>
            {isEdit && (
              <div className="flex px-6 py-4 ">
                <div className="flex h-9 w-[7.5rem] shadow-sm">
                  {/* <button className="flex flex-1 items-center justify-center gap-2.5 border-r border-gray-200 bg-white p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-800 active:bg-gray-200 active:text-gray-800">
                  <Icon.ExportSquare size={20} />
                </button> */}
                  <CustomTooltip position="bottom" message="입주사에서 내보내기" disabled={!isEdit}>
                    <button
                      className="flex flex-1 items-center justify-center gap-2.5 rounded-md border border-gray-300 bg-white p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-800 active:bg-gray-200 active:text-gray-800 disabled:bg-gray-50 disabled:opacity-40"
                      disabled={!isEdit}
                      onClick={() => handleDeleteMemberClick(masterMember.id)}
                    >
                      <Icon.Trash size={20} />
                    </button>
                  </CustomTooltip>
                </div>
              </div>
            )}
          </div>
        ) : (
          !searchText && (
            <div className="flex items-center">
              <div className="flex w-full items-center">
                <div className="flex w-3/4 items-center gap-4 px-6 py-4">
                  <span className="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-600">
                    <Icon.Crown1 variant="Bold" size={20} color="#EEF2FF" />
                  </span>
                  <span className="text-sm font-medium text-red-500">최고관리자 미등록</span>
                </div>
                <div className="flex w-1/4 gap-2 px-6 py-4">
                  <span className="text-sm font-medium text-gray-900">최고관리자</span>
                </div>
              </div>
              {isEdit && (
                <div className="flex px-6 py-4 ">
                  <button
                    className="flex h-9 w-[7.5rem] cursor-pointer items-center justify-center gap-1 rounded-md border border-gray-300 shadow-sm hover:bg-gray-100 active:bg-gray-200"
                    onClick={() => handleAddMemberClick("master")}
                  >
                    <Icon.Add size={20} color="#6B7280" />
                    <span className="text-sm font-medium text-gray-700">등록하기</span>
                  </button>
                </div>
              )}
            </div>
          )
        )}
        {userMember.map((item, i) => (
          <div key={item.id} className="relative flex items-center">
            <div className="flex w-full items-center">
              <div className="flex w-1/4 items-center gap-4 px-6 py-4">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100">
                  <Icon.Profile variant="Bold" size={20} color="#818CF8" />
                </span>
                <span
                  className={twMerge(
                    "break-all text-sm font-medium text-gray-900",
                    (!item.name || item.name?.trim() === "") && "text-gray-400",
                  )}
                >
                  {item.name?.trim() || "이름 없는 구성원"}
                </span>
              </div>
              <div className="flex w-1/4 flex-col px-6 py-4">
                <span className="text-sm font-medium text-gray-900">
                  {formatPhoneNumber(item.phoneNumber?.trim() || "-")}
                </span>
              </div>
              <p className="flex w-1/4 px-6 py-4 text-sm font-medium text-gray-900">
                {item.userId ? "가입완료" : "미가입"}
              </p>
              <div className="flex w-1/4 gap-2 px-6 py-4">
                <span className="text-sm font-medium text-gray-900">{PERMISSION[item.permission]}</span>
                {isEdit && (
                  <span
                    className="cursor-pointer text-sm font-medium text-gray-500 underline underline-offset-2"
                    onClick={() => handleModifyPermissionClick(item.id)}
                  >
                    변경
                  </span>
                )}
              </div>
            </div>
            {isEdit && (
              <div className="relative flex px-6 py-4">
                <div className="relative flex h-9 w-[7.5rem] shadow-sm">
                  {/* <button className="flex flex-1 items-center justify-center gap-2.5 border-r border-gray-200 bg-white p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-800 active:bg-gray-200 active:text-gray-800">
                      <Icon.ExportSquare size={20} />
                    </button> */}
                  <CustomTooltip position="bottom" message="입주사에서 내보내기" disabled={!isEdit}>
                    <button
                      className="flex flex-1 items-center justify-center gap-2.5 rounded-md border border-gray-300 bg-white p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-800 active:bg-gray-200 active:text-gray-800"
                      onClick={() => handleDeleteMemberClick(item.id)}
                    >
                      <Icon.Trash size={20} />
                    </button>
                  </CustomTooltip>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
