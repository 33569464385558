import { Checkbox, CheckboxGroup, Flex } from "@chakra-ui/react";
import { LastShadowBox } from "../../../../../components/styled/webApp";
import { IOpenModal } from "../../../commons/types";
import { Dispatch, SetStateAction } from "react";
import { onPushItems, tenant_type } from "../../../commons/func";
import { useRoomType } from "../../../../../lib/utils";

interface IOperationTime extends IOpenModal {
  isTenantArr: string[];
  setIsTenantArr: Dispatch<SetStateAction<string[]>>;
}

export const RestrictUser = ({ isModal, ...props }: IOperationTime) => {
  const room_type = useRoomType();

  return (
    <LastShadowBox isModal={isModal}>
      <div>
        <h4>
          이용 가능한 호실용도를 모두 선택해주세요.
          {!isModal && <span> *</span>}
        </h4>
        <Flex>
          <CheckboxGroup>
            {tenant_type.map((el) => (
              <Checkbox
                key={el.id}
                mr="1.5rem"
                variant="default"
                isChecked={props.isTenantArr.includes(el.label)}
                isDisabled={room_type.findIndex((room) => room.label === el.label) === -1}
                onChange={() => onPushItems(el.label, props.isTenantArr, props.setIsTenantArr)}
              >
                {el.value}
              </Checkbox>
              //   <C.ActiveButton
              //   key={el.id}
              //   style={{ minWidth: "7rem" }}
              //   isActive={props.isTenantArr.includes(el.label)}
              //   onClick={() =>
              //     onPushItems(el.label, props.isTenantArr, props.setIsTenantArr)
              //   }
              // >
              //   {el.value}
              // </C.ActiveButton>
            ))}
          </CheckboxGroup>
        </Flex>
      </div>
    </LastShadowBox>
  );
};

// export const RestrictUserBox = () => {
//   const { onOpen, isOpen, onClose } = useDisclosure(); //되돌아가기
//   // const { onOpen, isOpen, onClose } = useDisclosure(); //저장하기

//   return (
//     <>
//       <RestrictUser />
//       <ResponsiveConfirm
//         next="이동"
//         before="취소"
//         title="이전 단계 이동"
//         isOpen={isOpen}
//         onClose={onClose}
//         onAction={() => console.log("스테이트써야")}
//       >
//         <>
//           {isOpen ? (
//             <>
//               이전 단계로 이동할까요? <br />
//               이전 단계로 이동 시 입력한 이용제한 정보는 모두 사라집니다.
//             </>
//           ) : (
//             <>작성하신 정보로 시설을 등록할까요?</>
//           )}
//         </>
//       </ResponsiveConfirm>
//       <Flex>
//         <Button
//           variant="m_gray"
//           fontSize="1.25rem"
//           w="100%"
//           mr="1rem"
//           onClick={onOpen}
//         >
//           이전
//         </Button>
//         <Button w="100%" fontSize="1.25rem" variant="m_blue">
//           등록완료
//         </Button>
//       </Flex>
//     </>
//   );
// };
