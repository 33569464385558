import { Text, useDisclosure, useToast, Flex } from "@chakra-ui/react";
import * as D from "../style";
import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { fetchClient } from "../../../../lib/api/axios";
import { BaseResponse } from "../../../../lib/api/queries/commons/types";
import { IComment } from "../../../../lib/types/Imodels";
import { queryClient } from "../../../..";
import { complaint_detail_key } from "../../../../lib/api/queries/keys";
import { success, unAuthUser, warning } from "../../../../lib/theme/toast";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import { complaint_comment_delete } from "../../../../lib/api/queries/urls";
import useUserAuth from "../../../../components/hooks/useUserAuth";
import ActionBottomSheet from "../../../../components/Menu/bottomSheet";
import RespCancelModal from "../../../../components/modal/responsive/cancel";
import { MiniStandard } from "../../../../components/styled/webApp";
import { MdMoreHoriz } from "@react-icons/all-files/md/MdMoreHoriz";
import { IComplaintComment } from "../../commons/types";
import { ImageArr, ShowImages } from "../../../../components/styled";
import { GlobalContext } from "../../../../App";
import { useContext } from "react";

const ReplyListItem: FC<IComplaintComment> = ({ data, idx, ...props }) => {
  const { user } = useContext(GlobalContext);
  const toast = useToast();
  const { complaintId } = useParams();
  const auth = useUserAuth("complaint");

  const [isOpen, setIsOpen] = useState(false); //관리자 댓글 수정, 삭제
  // const [isEdit, setIsEdit] = useState(false); //답글 수정 여부
  const { isOpen: isDel, onOpen, onClose } = useDisclosure(); //삭제 확인 모달
  const [commnetHeight, setCommnetHeight] = useState(24); //답글 작성 textarea 높이
  const comment_ref = useRef<HTMLTextAreaElement>(null);
  // const commentBody = data?._id ? document.querySelector(`${data._id}`) : null;

  useEffect(() => {
    if (comment_ref.current === null) return;
    // console.log("changed", data?.body, comment_ref.current);

    setCommnetHeight(comment_ref.current?.scrollHeight);
  }, [comment_ref]);

  const mutation = useMutation(
    () =>
      fetchClient
        .post<BaseResponse<IComment>>(complaint_comment_delete, {
          comment_id: data?._id,
        })
        .then((res) => res.data.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([complaint_detail_key, complaintId]);
        toast({
          ...success,
          title: "답글 삭제 완료",
          description: "민원에 대한 답글이 삭제되었습니다.",
        });
        onClose();
      },
      onError: (err: AxiosError) => {
        err.response?.status === 401 && toast({ ...unAuthUser });
      },
    },
  );

  return (
    <D.RegisteredComment className="comment-box">
      <Flex justifyContent="space-between">
        <div>
          <span style={{ color: "#111827", fontWeight: "500" }}>{data?.user_name}</span>
          <span style={{ margin: "0 8px" }}>•</span>
          <span>{moment.utc(data?.updatedAt).format("YYYY-MM-DD HH:mm")}</span>
        </div>
        {data?.company_type === "building" &&
          auth === "edit" &&
          data?.user_id &&
          data.user_id === user?._id.toString() && (
            <MiniStandard
              isOpen={isOpen}
              ref={props.refs}
              id={String(isOpen)}
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <MdMoreHoriz />
              <ActionBottomSheet
                isOpen={isOpen}
                onDeleteAction={(e) => {
                  setIsOpen(false);
                  if (props.editId === data?._id) {
                    toast({
                      ...warning,
                      title: "답글 삭제",
                      description: "해당 댓글은 수정중입니다.",
                    });
                    e.stopPropagation();
                    return;
                  }
                  onOpen();
                }}
                setIsOpen={setIsOpen}
                onEditAction={() => {
                  setIsOpen(false);
                  props.onChangeCommentState(idx);
                }}
              />
            </MiniStandard>
          )}
        <RespCancelModal
          isOpen={isDel}
          onClose={onClose}
          next="삭제"
          title="답글 삭제"
          onAction={() => {
            if (mutation.isLoading) return;
            mutation.mutate();
          }}
        >
          <Text>이 답글을 삭제할까요?</Text>
          <Text>삭제된 데이터는 복구할 수 없습니다.</Text>
        </RespCancelModal>
      </Flex>
      <textarea readOnly={true} ref={comment_ref} style={{ height: `${commnetHeight}px` }} value={data?.body || ""} />
      <ImageArr>
        {data?.images.map((el, idx) => (
          <div key={el}>
            <ShowImages src={el} alt={`관련 이미지 ${idx + 1}`} onClick={() => window.open(el, "_blank")} />
          </div>
        ))}
      </ImageArr>
    </D.RegisteredComment>
  );
};

export default ReplyListItem;
