import { PropsWithChildren, useState, Dispatch, SetStateAction, useEffect, useContext } from "react";
import LayoutHeader from "./header";
import SideBar from "./SideBar";
import { useLocation } from "react-router-dom";
import LayoutFooter from "./footer";
import { useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../lib/api/axios";
import { BaseResponse } from "../../lib/api/queries/commons/types";
import { INotification } from "../../lib/types/Imodels";
import { notification_list } from "../../lib/api/queries/urls";
import { notification_group_key } from "../../lib/api/queries/keys";
import { GlobalContext } from "../../App";
import { twMerge } from "tailwind-merge";

export interface INotifications {
  dots: boolean;
  setDots: Dispatch<SetStateAction<boolean>>;
  list: INotification[] | undefined;
}

export interface IHeader extends INotifications {
  isMenu: boolean;
  setIsMenu: Dispatch<SetStateAction<boolean>>;
}

export default function Layout({ children }: PropsWithChildren) {
  const location = useLocation();
  const { buildInfo } = useContext(GlobalContext);
  const isMobile =
    window.navigator.userAgent.includes("Mobi") ||
    window.navigator.userAgent.includes("iPhone") ||
    window.navigator.userAgent.includes("Android");
  const [isNeedFooter, setIsNeedFooter] = useState(isMobile);

  const [dots, setDots] = useState(false);
  const { data } = useQuery(
    [notification_group_key, buildInfo?._id],
    () =>
      fetchClient.get<BaseResponse<INotification[]>>(notification_list).then((res) => {
        res.data.data.find((el) => !el.is_checked) !== undefined && setDots(true);
        return res.data.data;
      }),
    { enabled: buildInfo?._id !== undefined },
  );

  useEffect(() => {
    if (!isMobile) return setIsNeedFooter(isMobile);

    const path = !(
      location.pathname.includes("new") ||
      location.pathname.includes("create") ||
      location.pathname.includes("detail") ||
      location.pathname.includes("edit")
    );

    setIsNeedFooter(path);
  }, [location.pathname]);

  //header modal
  const [isMenu, setIsMenu] = useState(false);
  const onCloseModal = () => {
    if (isMenu) setIsMenu(false);
  };

  const notifications: INotifications = {
    dots,
    setDots,
    list: data,
  };

  const headerProps: IHeader = {
    isMenu,
    setIsMenu,
    ...notifications,
  };

  return (
    <div className="h-full w-full max-md:fixed max-md:overflow-hidden">
      <LayoutHeader {...headerProps} />
      <div
        onClick={onCloseModal}
        className={twMerge(
          `flex w-full max-lg:h-[calc(100vh-72px)] max-md:h-[calc(100vh-140px)]`,
          isNeedFooter ? "h-[calc(100vh-120px)]" : "h-[calc(100vh-64px)]",
        )}
      >
        <SideBar />
        <div className="relative flex-1 max-lg:w-[calc(100%-13rem)] max-md:w-full max-md:p-1">
          <div className="h-full overflow-y-scroll">{children}</div>
          <div className="max-md:h-[90px] max-md:w-full max-md:bg-inherit" />
        </div>
      </div>
      {isNeedFooter && <LayoutFooter {...notifications} />}
    </div>
  );
}
