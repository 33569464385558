import { create } from "zustand";

interface VoteState {
  voteId: string;
  voteInput: {
    title: string;
    description: string;
    agendas: { title: string }[];
    senderName: string;
    senderContact: string;
  };
  selectOwners: {
    _id: string;
    user_name: string;
    user_phone_number: string;
    count: number;
    dong: string;
    ho: string;
    room_ids: string[];
  }[];
  checkWarn: boolean;
  endDate: Date | null;
}

export const useVoteStore = create<VoteState>(() => ({
  voteId: "",
  voteInput: {
    title: "",
    description: "",
    agendas: [],
    senderName: "",
    senderContact: "",
  },
  selectOwners: [],
  checkWarn: false,
  endDate: null,
}));

export const useSelectOwners = () => {
  return useVoteStore((state) => state.selectOwners);
};

export const setSelectOwners = (selectOwners: VoteState["selectOwners"]) => {
  return useVoteStore.setState({ selectOwners });
};

export const getSelectOwners = () => {
  return useVoteStore.getState().selectOwners;
};

export const setVoteInput = (voteInput: VoteState["voteInput"]) => {
  return useVoteStore.setState({ voteInput });
};

export const resetVoteStore = () => {
  return useVoteStore.setState({
    voteId: "",
    voteInput: { title: "", description: "", agendas: [], senderName: "", senderContact: "" },
    selectOwners: [],
  });
};

export const useCheckWarn = () => {
  return useVoteStore((state) => state.checkWarn);
};

export const setCheckWarn = (checkWarn: boolean) => {
  return useVoteStore.setState({ checkWarn });
};

export const useEndDate = () => {
  return useVoteStore((state) => state.endDate);
};

export const setEndDate = (endDate: Date | null) => {
  return useVoteStore.setState({ endDate });
};
