import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { delete_rental, manage_fee_delete, manage_tenant_list, saved_fixed_fee } from "../../../lib/api/queries/urls";
import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import {
  account_list_key,
  bank_list_key,
  manage_tenant_list_key,
  saved_fixed_fee_key,
} from "../../../lib/api/queries/keys";
import { account_list, bank_list } from "../../../lib/api/queries/urls";
import { IAccount, IBank, ISavedManagement } from "../../../lib/types/Imodels";
import { IFetchMenagementList } from "./types";
import { NavigateFunction } from "react-router-dom";

//은행 종류 조회
export const useBankAccount = (isOpen: boolean) => {
  return useQuery(
    [bank_list_key],
    () => fetchClient.get<BaseResponse<IBank[]>>(bank_list).then((res) => res.data.data),
    {
      enabled: isOpen,
    },
  );
};

//등록한 계좌 리스트 조회
export const useAccountList = () => {
  return useQuery(
    [account_list_key],
    () => fetchClient.get<BaseResponse<IAccount[]>>(account_list).then((res) => res.data.data),
    { staleTime: Infinity },
  );
};

//입주사 관리비용 호실 조회
export const useManangementList = (isValid: boolean) => {
  return useQuery(
    [manage_tenant_list_key],
    () => fetchClient.get<IFetchMenagementList>(manage_tenant_list).then((res) => res.data),
    { enabled: isValid },
  );
};

export const useFetchListForNewManangement = (isValid: boolean, isBoth?: boolean) => {
  return useQueries({
    queries: [
      {
        queryKey: [manage_tenant_list_key],
        queryFn: () => fetchClient.get<IFetchMenagementList>(manage_tenant_list).then((res) => res.data),
        enabled: isValid,
      },
      {
        queryKey: [saved_fixed_fee_key],
        queryFn: () =>
          fetchClient
            .get<BaseResponse<{ key: string; value: ISavedManagement[] }>>(saved_fixed_fee)
            .then((res) => res.data.data),
        enabled: isBoth === undefined ? isValid : isBoth,
      },
    ],
  });
};

//임대료 고지서 삭제
export const useDeleteBill = (str: string) =>
  fetchClient.post<BaseResponse<string>>(delete_rental, { rent_fee_id: str });

//관리비 고지서 삭제
export const useDeleteLetter = (navigate: NavigateFunction) => {
  const mutation = useMutation(
    (str: string) =>
      fetchClient.post<BaseResponse<string>>(manage_fee_delete, {
        management_fee_id: str,
      }),
    {
      retry: 0,
      onSuccess: () => {
        alert("관리비 납부 고지서를 삭제했습니다. 확인을 누르면 관리비 현황 페이지로 이동합니다.");
        navigate("/maintenance-fee", { replace: true });
      },
      onError: () => alert("일시적인 오류로 납부 고지서 삭제에 실패했습니다. 잠시 후 다시 시도해주세요."),
    },
  );
  return { mutation };
};
