import { Tab, TabList, TabPanel, TabPanels, Tabs, Tooltip, useDisclosure } from "@chakra-ui/react";
import VoteMemberTable from "./VoteMemberTable";
import VoteResult from "./VoteResult";
import IconCircleDanger from "../../components/icons/IconCircleDanger";
import { VoteDetailResponse } from "../../requests/vote";
import { Link } from "react-router-dom";
import Button from "../../components/button/Button";
import IconCalendar from "../../components/icons/IconCalendar";
import IconVote from "../../components/icons/IconVote";
import IconArrow from "../../components/icons/IconArrow";
import IconProhibition from "../../components/icons/IconProhibition";
import VoteExtendTimeModal from "./modal/VoteExtendTimeModal";
import VoteCancelModal from "./modal/VoteCancelModal";
import VoteEarlyEndModal from "./modal/VoteEarlyEndModal";
import useUserAuth from "../../components/hooks/useUserAuth";
import GetAuthorized from "../../components/Authrized";

export default function VoteDetail(rest: VoteDetailResponse["data"]) {
  const { title, status, startAt, endAt, signatures, _id: id } = rest;
  const { isOpen: isExtendTimeOpen, onOpen: onExtendTimeOpen, onClose: onExtendTimeClose } = useDisclosure();
  const { isOpen: isCancelOpen, onOpen: onCancelOpen, onClose: onCancelClose } = useDisclosure();
  const { isOpen: isEarlyEndOpen, onOpen: onEarlyEndOpen, onClose: onEarlyEndClose } = useDisclosure();

  const auth = useUserAuth("vote");
  const isEdit = auth === "edit";

  if (status === "진행전") return null;

  const statusStyles = {
    진행중: "bg-green-500",
    진행완료: "bg-cyan-500",
    중단: "bg-red-500",
  };

  const statusTexts = {
    진행중: "진행중",
    진행완료: "마감된 투표",
    중단: "취소된 투표",
  };

  const startDateFormatted = new Date(startAt).toLocaleDateString();
  const endDateFormatted = new Date(endAt).toLocaleDateString();

  const allSignaturesCompleted = signatures.every((signature) =>
    signature.delegate.name ? signature.delegate.delegate_status === true : signature.signatureStatus === true,
  );

  if (auth === "no_auth") return <GetAuthorized />;

  return (
    <div className="flex flex-col">
      <div className="flex h-10 items-center gap-1 border-b border-gray-200 px-6">
        <Link
          to={"/votes"}
          className="rounded-lg p-2 text-xs font-medium text-gray-500 transition-all hover:bg-gray-200"
        >
          투표
        </Link>
        <IconArrow />
        <p className="cursor-pointer p-2 text-xs">{title}</p>
      </div>
      <div className="w-full rounded-2xl bg-white px-6 py-5">
        <div className={`mb-1.5 flex w-fit items-center gap-1.5 rounded-lg border border-gray-200 px-2 py-0.5`}>
          <span className={`h-1.5 w-1.5 rounded-full ${statusStyles[status]}`} />
          <span className="text-xs font-medium text-gray-500">{statusTexts[status]}</span>
        </div>
        <div className="flex gap-4">
          <h2 className="text-2xl font-bold text-gray-900">{title}</h2>
          {isEdit && status === "진행중" && (
            <Button
              disabled={status !== "진행중"}
              iconElement={<IconProhibition />}
              designSchema={"error"}
              buttonText={"투표 취소"}
              size={"sm"}
              onClick={onCancelOpen}
            />
          )}
        </div>
        <div className="mt-4 flex h-[80px] w-fit items-center gap-3 rounded-xl bg-gray-50 px-4 py-3.5">
          <div className="flex w-[275px] flex-col gap-1">
            <p className="text-sm font-medium text-gray-500">투표시작</p>
            <p className="font-medium text-gray-700">{startDateFormatted}</p>
          </div>
          <div className="h-[46px] w-[1px] bg-gray-200" />
          <div className="flex w-[275px] flex-col gap-1">
            <p className="text-sm font-medium text-gray-500">투표마감</p>
            <p className="font-medium text-gray-700">{endDateFormatted}</p>
          </div>
          {isEdit && status === "진행중" && (
            <>
              <div className="h-[46px] w-[1px] bg-gray-200" />
              <div className="flex w-[206px] gap-2">
                <Button
                  disabled={status !== "진행중"}
                  iconElement={<IconCalendar />}
                  designSchema={"secondaryGray"}
                  buttonText={"기간연장"}
                  size={"sm"}
                  onClick={onExtendTimeOpen}
                />
                <Tooltip
                  label="모든 참여자가 서명을 완료했어요.지금 투표 조기 마감 기능을 사용할 수 있어요."
                  defaultIsOpen={allSignaturesCompleted}
                  rounded={"md"}
                  hasArrow={true}
                  padding={4}
                  isDisabled={!allSignaturesCompleted}
                >
                  <div>
                    <Button
                      onClick={onEarlyEndOpen}
                      disabled={!allSignaturesCompleted}
                      iconElement={<IconVote className="h-4 w-4 fill-gray-500" />}
                      designSchema={"secondaryGray"}
                      buttonText={"조기마감"}
                      size={"sm"}
                    />
                  </div>
                </Tooltip>
              </div>
            </>
          )}
        </div>
      </div>
      <Tabs className="w-full rounded-2xl border-gray-200 bg-white">
        <TabList className="h-12 gap-6 px-6 font-semibold text-[#9CA3AF]">
          <Tab
            padding={0}
            _active={{ backgroundColor: "" }}
            _selected={{ color: "#111827", fontWeight: 600, borderBottom: "2px solid #111827", padding: 0 }}
          >
            투표 참여자
          </Tab>
          <Tab
            padding={0}
            _active={{ backgroundColor: "" }}
            _selected={{ color: "#111827", fontWeight: 600, borderBottom: "2px solid #111827", padding: 0 }}
          >
            투표 결과
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel padding={0} className="flex flex-col items-center gap-5">
            {status === "중단" ? (
              <div className="mt-[200px] flex gap-1">
                <IconCircleDanger />
                <p className="text-gray-400">중단된 투표는 투표 정보를 확인할 수 없습니다.</p>
              </div>
            ) : (
              <VoteMemberTable {...rest} />
            )}
          </TabPanel>
          <TabPanel>
            {status === "중단" ? (
              <div className="mt-[184px] flex h-full items-center justify-center gap-1">
                <IconCircleDanger />
                <p className="text-gray-400">중단된 투표는 투표 정보를 확인할 수 없습니다.</p>
              </div>
            ) : (
              <VoteResult {...rest} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <VoteExtendTimeModal
        isOpen={isExtendTimeOpen}
        onClose={onExtendTimeClose}
        startAt={rest.startAt}
        endAt={rest.endAt}
        voteId={id}
      />
      <VoteCancelModal isOpen={isCancelOpen} onClose={onCancelClose} voteId={id} />
      <VoteEarlyEndModal isOpen={isEarlyEndOpen} onClose={onEarlyEndClose} title={title || ""} voteId={id} />
    </div>
  );
}
