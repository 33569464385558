import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from "@headlessui/react";
import Icon from "../types/iconsax";
import { Dispatch, SetStateAction } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  frontIconElement?: React.ReactNode;
  lastIconElement?: React.ReactNode;
  lastIconOnClick?: () => void;
  type?: "default" | "add" | "dropdown";
  designSchema?: "default" | "orange" | "indigo" | "blue";
  size: "sm" | "md";
  label: string;
  onClick?: () => void;
  data?: { [key: string]: string }[]; // dropdown에 쓰일 데이터
  selected?: { [key: string]: string }; // dropdown 선택된 데이터
  setSelected?: Dispatch<SetStateAction<{ [key: string]: string }>>; // dropdown 선택된 데이터 변경
  disabled?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export default function CustomChip({
  frontIconElement,
  lastIconElement,
  type = "default",
  designSchema = "default",
  size,
  label,
  onClick,
  data,
  selected,
  setSelected,
  disabled = false,
  className,
}: Props) {
  const themeClasses: Record<string, string> = {
    default: "border-gray-300 bg-white text-gray-700",
    orange: "border-orange-300 bg-orange-50 text-orange-500",
    indigo: "border-indigo-300 bg-indigo-50 text-indigo-500",
    blue: "border-blue-300 bg-blue-50 text-blue-500",
  };

  const sizeClasses = {
    sm: "h-6 px-2 text-xs font-medium",
    md: "h-8 px-3 text-xs font-medium",
  };

  const chipClass = twMerge(
    "flex items-center gap-1 rounded-full border",
    themeClasses[designSchema],
    sizeClasses[size],
    type === "add" && "border-dashed",
    onClick && "cursor-pointer",
    disabled && "opacity-40",
    className,
  );

  if (type === "dropdown") {
    return (
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative">
          <div className={"group relative flex items-center"}>
            <ListboxButton className={chipClass}>
              {frontIconElement && frontIconElement}
              {selected?.name}
              <Icon.ArrowDown2 variant="Bold" size={12} className="ui-open:rotate-180" aria-hidden="true" />
            </ListboxButton>
          </div>
          <Transition leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <ListboxOptions className="absolute mt-1.5 max-h-[22.5rem] w-full overflow-scroll rounded-lg border border-gray-300 bg-white text-sm shadow-lg empty:hidden">
              {data?.map((item, index) => (
                <ListboxOption
                  key={index}
                  value={item}
                  className="flex h-9 cursor-default select-none items-center gap-2 p-3 ui-active:bg-gray-50"
                >
                  <div className="text-xs font-medium text-gray-400 ui-active:text-gray-500">{item.name}</div>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      </Listbox>
    );
  }

  return (
    <div className={chipClass} onClick={onClick}>
      {frontIconElement && frontIconElement}
      <span>{label}</span>
      {lastIconElement && lastIconElement}
    </div>
  );
}
