import styled from "@emotion/styled";
import { colors } from "./common";

// **************** 권한, 404 페이지, PC 확인 안내 ********************************
export const MediaInfoBox = styled.div`
  width: 100%;
  min-height: 100%;
  position: relative;
`;

export const MediaInfo = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.25rem;
  text-align: center;
  svg {
    width: 100%;
    font-size: 3rem;
    margin-bottom: 2rem;
    color: ${colors.gray2};
  }
`;
