import styled from "@emotion/styled";
import { CSSProperties } from "react";
import { colors } from "../styled/common";
import { StepShadowBox } from "../styled/webApp";

const ActiveStep = styled.div<{ active: boolean }>`
  width: 100%;
  padding: 1.25rem 0;
  border-radius: 8px;
  font-size: 0.875rem;
  text-align: center;
  background-color: ${({ active }) =>
    active ? colors.mainBlue : colors.whiteColor};
  color: ${({ active }) => (active ? colors.whiteColor : colors.gray4)};
  cursor: pointer;
  :first-of-type {
    border-radius: 8px 0 0 8px;
  }
  :last-of-type {
    border-radius: 0 8px 8px 0;
  }
`;

interface IStepBox {
  step: string[];
  isActive: number;
  onClickStep: (el: string, idx: number) => void;
  styled?: CSSProperties;
}

function ShadowStepBox(props: IStepBox) {
  const { step, isActive, styled, onClickStep } = props;

  return (
    <StepShadowBox style={styled}>
      {step.map((el, idx) => (
        <ActiveStep
          key={el}
          active={el === step[isActive]}
          id={el}
          onClick={(e) => onClickStep(e.currentTarget.id, idx)}
        >
          {el}
        </ActiveStep>
      ))}
    </StepShadowBox>
  );
}

export default ShadowStepBox;
