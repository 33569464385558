import { Box, Button, Checkbox, Flex, Select, Text, useDisclosure } from "@chakra-ui/react";
import * as C from "../../../components/styled/index";
import * as P from "./_view/styled";
import SmallInputs from "../../../components/Input/shortText";
import { useQuery } from "@tanstack/react-query";
import { fetchClient } from "../../../lib/api/axios";
import { BaseResponse } from "../../../lib/api/queries/commons/types";
import { IRoomPublic } from "../../../lib/types/Imodels";
import RegisterModal from "../components/registerModal";
import { useState } from "react";
import { public_detail_key, public_list_key } from "../../../lib/api/queries/keys";
import { public_detail, public_list } from "../../../lib/api/queries/urls";
import MeetingRoomItem from "./_view/tableItems";
import CancleModal from "../../../components/modal/CancelModal";
import PaginationUI from "../../../components/pagination";
import { usePagination } from "@ajna/pagination";
import InfoModal from "../../../components/modal/info";
import UpdateBatchMeetingRoom from "./_view/UpdateBatch";
import useUserAuth from "../../../components/hooks/useUserAuth";
import CheckLocationBox from "../components/checkBox/locationBox";
import { useDeleteMeetingRoom } from "../commons/queries";

export default function PublicFacilityList() {
  const [targetId, setTargetId] = useState(""); //table에서 클릭된 Id값
  const { isOpen, onOpen, onClose } = useDisclosure(); //등록 모달
  const { isOpen: isDel, onOpen: delOpen, onClose: offDel } = useDisclosure(); //삭제 및 재확인 모달
  const { isOpen: isBatch, onOpen: onBatch, onClose: offBatch } = useDisclosure(); //일괄 수정 모달
  const [checkedItems, setCheckedItems] = useState<string[]>([]); //체크박스
  const { currentPage, setCurrentPage } = usePagination({
    initialState: { currentPage: 1 },
  });
  const [allPage, setAllpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [dongArr, setDongArr] = useState<string[]>([]); //동 선택
  const [floorArr, setFloorArr] = useState<string[]>([]); //층 선택
  const checklocation = {
    dongArr,
    floorArr,
    setDongArr,
    setFloorArr,
  }; //시설 위치 선택 체크박스 -> 공통 요소로 이동
  const [approval, setApproval] = useState("");
  const [search, setSearch] = useState("");
  const [unremovableList, setUnremovableList] = useState<any>([]);

  const auth = useUserAuth("room_public");
  const isEdit = auth === "edit";

  const { data: public_data, refetch } = useQuery(
    [public_list_key, currentPage],
    () =>
      fetchClient
        .get<BaseResponse<IRoomPublic[]>>(public_list, {
          params: {
            is_only_room_public: true,
            limit: 10,
            page: currentPage,
            dong_list: dongArr.length === 0 ? "" : dongArr,
            floor_list: floorArr.length === 0 ? "" : floorArr,
            search_name: search,
            is_approval_required: approval,
          },
        })
        .then((res) => {
          setAllpage(res.data.total_page_num || 0);
          setTotal(res.data.total_num);
          return res.data.data;
        }),
    { keepPreviousData: true },
  );

  const { data: target } = useQuery(
    [public_detail_key, targetId],
    () =>
      fetchClient
        .post<BaseResponse<IRoomPublic>>(public_detail, {
          room_public_id: targetId,
        })
        .then((res) => {
          return res.data.data;
        }),
    { enabled: !!targetId },
  );

  const onCheckedAll = (checked: boolean) => {
    if (checked) {
      const allCheckedArr: string[] = [];
      public_data?.forEach((el) => allCheckedArr.push(String(el._id)));
      setCheckedItems(allCheckedArr);
    } else {
      setCheckedItems([]);
    }
  };

  const onChangeChecked = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      const newArr = checkedItems.filter((el) => el !== id);
      if (newArr !== undefined) setCheckedItems(newArr);
    }
    if (checkedItems.length === public_data?.length || 0) {
      return (checked = true);
    }
  };

  const { mutation } = useDeleteMeetingRoom();
  const onClickDelete = async () => {
    if (mutation.isLoading) return;
    try {
      const response: any = await mutation.mutateAsync(checkedItems);
      if (response.data.data) {
        if (response?.data.data.length > 0) {
          const UnremovableListInfo = [];
          if (public_data) {
            for (let i = 0; i < response.data.data.length; i++) {
              for (const subItem of public_data) {
                if (subItem._id.toString() === response.data.data[i].room_public_id) {
                  UnremovableListInfo.push(subItem);
                  break;
                }
              }
            }
            setUnremovableList(UnremovableListInfo);
          }
        }
      } else {
        refetch();
        offDel();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="flex min-h-full w-full flex-col bg-white p-0 md:px-6">
      <p className="mt-2">신규 회의실을 등록하고 전체 회의실 리스트에서 회의실 정보를 수정, 삭제할 수 있어요.</p>
      {isEdit && (
        <div className="flex pt-5">
          <Button m="0" variant="bgBlue" onClick={onOpen}>
            신규 회의실 등록
          </Button>
          {isOpen && <RegisterModal isOpen={isOpen} onClose={onClose} />}
        </div>
      )}
      <C.WrapperBody>
        <div className="flex items-end py-5">
          <CheckLocationBox {...checklocation} />
          <Box mr={2} w="200px">
            <Text variant="default">예약확정 방식</Text>
            <Select
              variant="default"
              style={{ width: "100%" }}
              value={approval}
              onChange={(e) => setApproval(e.target.value)}
            >
              <option value="">전체</option>
              <option value="true">관리자 확인 후 확정</option>
              <option value="false">예약신청과 동시에 확정</option>
            </Select>
          </Box>
          <Flex alignItems="flex-end">
            <Box>
              <Text variant="default">검색</Text>
              <SmallInputs
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="검색할 회의실명을 입력해주세요"
                onKeyDown={(e) => (e.key === "Enter" && currentPage === 1 ? refetch() : setCurrentPage(1))}
              />
            </Box>
            <Button onClick={() => (currentPage === 1 ? refetch() : setCurrentPage(1))} variant="basic">
              검색
            </Button>
          </Flex>
        </div>
        <Box>
          <P.TableFlex>
            <C.TableText>
              총 <C.TableCount>{total}개</C.TableCount>의 회의실이 조회되었습니다.
            </C.TableText>
            {auth === "edit" && (
              <Box>
                <Button isDisabled={checkedItems.length === 0 ? true : false} variant="blueLine" onClick={onBatch}>
                  회의실 정보 일괄 변경
                </Button>
                {isBatch && (
                  <UpdateBatchMeetingRoom
                    isOpen={isBatch}
                    onClose={offBatch}
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                  />
                )}
                <Button isDisabled={checkedItems.length === 0 ? true : false} variant="delete" onClick={delOpen}>
                  삭제
                </Button>
              </Box>
            )}
            {unremovableList?.length !== 0 ? (
              <InfoModal
                title="삭제 불가"
                isOpen={isDel}
                onClose={() => {
                  setUnremovableList([]);
                  offDel();
                  refetch();
                }}
                style={{ width: "540px", marginTop: "7.75rem" }}
              >
                <Text>선택하신 회의실은 이미 예약이 존재합니다.</Text>
                <Text>이미 예약건이 있는 회의실은 삭제가 불가하니 아래 리스트를 확인해주세요.</Text>
                <P.ScheduleItem>
                  <ul>
                    <P.ScheduleList>
                      현재 예약건이 있는 회의실
                      <ul>{unremovableList?.map((el: any) => <li key={String(el._id)}>{el.name}</li>)}</ul>
                    </P.ScheduleList>
                  </ul>
                </P.ScheduleItem>
              </InfoModal>
            ) : (
              <CancleModal isOpen={isDel} onClose={offDel} onClickCancel={offDel} onClickSave={onClickDelete}>
                <Text>삭제된 데이터는 복구할 수 없습니다.</Text>
                <Text>정말로 삭제하시겠습니까?</Text>
              </CancleModal>
            )}
          </P.TableFlex>
          <C.ListTableBox>
            <C.ListTableHeader w="20%">
              <C.ListCheckBox style={{ width: "10%" }}>
                <Checkbox
                  variant="default"
                  isChecked={checkedItems.length === public_data?.length ? true : false}
                  onChange={(e) => onCheckedAll(e.target.checked)}
                ></Checkbox>
              </C.ListCheckBox>
              <div>분류</div>
              <div>회의실명</div>
              <div>최대 수용 인원</div>
              <div>예약확정 방식</div>
              <div>등록일</div>
            </C.ListTableHeader>
            {public_data?.map((el) => (
              <MeetingRoomItem
                key={String(el._id)}
                checkedItems={checkedItems}
                onChangeChecked={onChangeChecked}
                el={el}
                target={target}
                setTargetId={setTargetId}
              />
            ))}
          </C.ListTableBox>
        </Box>
        <PaginationUI page={currentPage} setPage={setCurrentPage} allPage={allPage} />
      </C.WrapperBody>
    </div>
  );
}
