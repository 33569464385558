import moment from "moment";

export const room_type = [
  { id: 1, label: "office", value: "사무용" },
  { id: 2, label: "residential", value: "주거용" },
  { id: 3, label: "store", value: "상가용" },
];

export const format_room = (status: string) => {
  switch (status) {
    case "office":
      return "사무용";
    case "residential":
      return "주거용";
    case "store":
      return "상가용";
    case "owner":
      return "소유자";
  }
};

export const format_tenant_type = (status: string) => {
  switch (status) {
    case "office":
      return "오피스";
    case "residential":
      return "주거";
    case "store":
      return "상가";
  }
};

export const format_type = (arr: string[] | undefined) => {
  if (arr === undefined) return;

  return arr.map((el, idx) => (idx + 1 === arr.length ? `${format_room(el)} ` : `${format_room(el)}, `));
};

export const format_approval = (status: string) => {
  switch (status) {
    case "approved":
      return "가입 완료";
    case "before_join":
      return "미가입";
    case "waiting_to_join":
      return "가입 중";
    case "before_approval":
      return "승인 대기중";
  }
};

// export const approval_type = ["가입 완료", "승인 대기중", "가입 중", "미가입"];
export const approval_type = [
  { id: 1, label: "approved", value: "가입 완료" },
  { id: 2, label: "before_approval", value: "승인 대기중" },
  { id: 3, label: "waiting_to_join", value: "가입 중" },
  { id: 4, label: "before_join", value: "미가입" },
];

//공용시설 시간 선택
export const allTime = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "24:00",
];

//관리비 -> 월 선택
export const Month = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

//관리비 -> 날짜 선택
export const Payment_date = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
];

export const format_day = (value: number, lo?: string) => {
  switch (value) {
    case 0:
      return lo ? "Sun" : "일";
    case 1:
      return lo ? "Mon" : "월";
    case 2:
      return lo ? "Tue" : "화";
    case 3:
      return lo ? "Wed" : "수";
    case 4:
      return lo ? "Fri" : "목";
    case 5:
      return lo ? "Fri" : "금";
    case 6:
      return lo ? "Sat" : "토";
  }
};

//관리비 부과 대상 -> 사용 X
export const target_type = (value: string | undefined) => {
  switch (value) {
    case "owner":
      return "소유자";
    case "tenant":
      return "입주사";
    default:
      return "기타";
  }
};

//설문조사 처리 상태 변환
export const format_survey = (status: string) => {
  switch (status) {
    case "waiting":
      return "대기";
    case "proceeding":
      return "진행";
    case "completed":
      return "종료";
  }
};

export const format_survey_to = (status: string) => {
  switch (status) {
    case "all":
      return "전체";
    case "master":
      return "입주사 대표";
    case "owner":
      return "소유자";
  }
};

//입주자 카드 등록 상태
export const format_registration = (status: string) => {
  switch (status) {
    case "pending":
      return "대기";
    case "completed":
      return "완료";
  }
};

export const format_move_type = (status: string) => {
  switch (status) {
    case "monthly_rent":
      return "월세";
    case "own_house":
      return "자가";
    case "etc":
      return "기타";
    default:
      return "";
  }
};

// [
//   {
//     _id: "tenant_master_approval_request",
//     title: "계정 승인 요청",
//     description: "입주자 마스터에게 계정 승인요청이 ",
//   },
//   {
//     _id: "tenant_master_auto_approval",
//     title: "계정 자동 승인",
//     description: "입주자 마스터에게 계정 승인이 ",
//   },
//   {
//     _id: "room_public_approval_request",
//     title: "공용시설 승인 요청",
//     description: "공용시설 예약건이 ",
//   },
// ];

export const format_date = (date: Date | undefined) => {
  if (date === undefined) return;
  const cur = moment(new Date());
  const days = moment(date).add(-9, "hours").format("YYYY-MM-DD");
  const pst = moment(date).add(-9, "hours");

  if (cur.diff(days, "days") > 7) {
    return `${pst.format("YYYY년 MM월 DD일")}`;
  } else if (cur.diff(days, "days") === 7) {
    return "1주일 전";
  } else if (cur.diff(days, "days") >= 1) {
    return `${cur.diff(days, "days")}일 전`;
  } else if (cur.diff(pst, "hours") > 6) {
    return `${pst.format("HH:mm")}`;
  } else if (cur.diff(pst, "hours") > 0) {
    return `${cur.diff(pst, "hours")}시간 전`;
  } else if (cur.diff(pst, "minutes") > 0) {
    return `${cur.diff(pst, "minutes")}분 전`;
  } else {
    return "방금";
  }
};

export const format_progress = (status: string) => {
  switch (status) {
    case "in_use":
      return "등록완료";
    case "approval_requested":
      return "신규신청";
    case "canceled":
      return "해지완료";
  }
};

export const format_permission = (status: string | undefined) => {
  switch (status) {
    case "room_public":
      return "공용시설";
    case "notice":
      return "공지";
    case "management_fee":
      return "관리비";
    case "complaint":
      return "민원";
    case "survey":
      return "설문조사";
    case "tenant":
      return "입주관리";
    case "car":
      return "주차관리";
    case "rent_fee":
      return "임대료관리";
    case "vote":
      return "투표 관리";
    case "air_conditioning":
      return "냉난방";
    default:
      return "";
  }
};

export const format_user_status = (str: string | undefined) => {
  switch (str) {
    case "pending":
      return "예약신청";
    case "approved":
      return "예약확정";
    case "completed":
      return "이용완료";
    case "rejected":
      return "예약취소(관리자)";
    case "canceled":
      return "예약취소(이용자)";
    default:
      return "";
  }
};

//포인트 결제 상태 관련
export const format_payment_type = (str: string) => {
  switch (str) {
    case "recharge":
      return "충전";
    case "deduct":
      return "차감";
    case "pay":
      return "결제완료";
    case "refund":
      return "환불완료";
    default:
      return "";
  }
};
