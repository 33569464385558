import { Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { IoClose } from "@react-icons/all-files/io5/IoClose";
import { colors, m_color } from "./common";
import { breakPoints } from "./media";

export const MainContainer = styled.div`
  width: 100%;
  padding: 1.25rem 1.5rem;
  border-top: 1px solid ${colors.gray2};
`;

export const WrapperGround = styled.div`
  width: 100%;
  min-height: 100%;
  border-radius: 1rem;
  background-color: ${colors.whiteColor}; /* 아래와 같음, 합치기 */
`;

export const FlexibleWrapper = styled(WrapperGround)`
  background-color: ${colors.whiteColor};
  border-radius: 1rem;
`;

export const ContourWrapper = styled.div<{ location?: string }>`
  width: 100%;
  padding: 1.5rem;
  border-radius: ${({ location }) =>
    location === "center"
      ? 0
      : location === "only"
      ? "1rem"
      : location === "last"
      ? "0 0 1rem 1rem"
      : "1rem 1rem 0 0"};
  @media ${breakPoints.mobile} {
    padding: 1rem;
    border-radius: 0;
  }
`;

export const GrayContour = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${colors.gray1};
  border-top: 1px solid ${colors.gray2};
`;

export const MainActionBox = styled.div`
  width: 31rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 31rem) {
    width: 100%;
  }
`;

//flexbox
export const FlexAlignBox = styled.div<{ w?: string }>`
  display: flex;
  align-items: center;
  ${({ w }) => w && `width:${w}`};
`;

//gray background flexbox
export const GrayInfoBox = styled(FlexAlignBox)`
  padding: 0.75rem;
  justify-content: space-between;
  background-color: ${colors.gray1};
  border-radius: 8px;
  margin-bottom: 0.75rem;
  .edit {
    cursor: pointer;
    color: ${colors.gray3};
  }
`;

//drawer 내부 정보 헤더
export const DetailBoxInDrawer = styled.div`
  color: ${colors.gray4};
  padding: 0 0 2.5rem 0;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.75rem;
    font-weight: 500;
    h4 {
      color: ${colors.gray5};
      span {
        color: ${colors.mainBlue};
      }
    }
    span {
      margin-top: 4px;
      font-size: 0.75rem;
    }
  }
`;

//시설 예약관련 Drawer 내부 정보 리스트 형식
export const DetailsInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0;
  border-bottom: 1px solid ${colors.gray2};
  h6 {
    font-weight: 500;
    color: ${colors.gray5};
  }
  .items {
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
  }
  :first-of-type {
    padding-top: 0;
  }
`;

//테이블 상단 (목록 + 버튼 Flex 정렬)
export const ItemSetBox = styled(FlexAlignBox)`
  width: 100%;
  justify-content: space-between;
  padding-bottom: 1rem;
`;

export const ItemTitle = styled.div`
  display: flex;
  font-size: 24px;
  font-weight: 500;
  color: ${colors.gray5};
  span {
    margin-left: 4px;
    color: ${colors.mainBlue};
  }
`;

export const ItemSettingBox = styled(FlexAlignBox)`
  font-size: 1rem;
  color: ${colors.gray4};
  > div {
    margin: 0 8px;
    img {
      width: 16px;
      margin-right: 4px;
    }
    svg {
      margin-right: 4px;
      font-size: 1rem;
    }
  }
`;

//기본 쉐도우 박스 - 스텝별
export const StepShadowBox = styled.div<{ isModal?: boolean }>`
  width: 100%;
  display: flex;
  color: ${colors.gray4};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-bottom: 1.25rem;
  ${({ isModal }) =>
    isModal && `box-shadow:none; border-radius:0; margin-bottom:0`};
`;

//기본 쉐도우 박스 - 묶음 형식
export const ShadowBox = styled(StepShadowBox)<{ isMore?: boolean }>`
  display: block;
  padding: 1.75rem;
  > div {
    width: 100%;
    font-weight: 500;
    ${({ isMore }) => isMore && `padding-bottom:1.75rem`};
    h4 {
      padding-bottom: 0.75rem;
      color: ${colors.gray5};
      span {
        color: ${colors.mainBlue};
      }
    }
    > span {
      font-size: 0.74rem;
    }
    :last-of-type {
      ${({ isMore }) => isMore && `padding-bottom:0`};
    }
  }
  @media (max-width: 31rem) {
    padding: 1.25rem;
  }
`;

//기본 쉐도우 박스 - 묶음 형식 + 마지막 박스로 저장 버튼
export const LastShadowBox = styled(ShadowBox)`
  ${({ isModal }) => !isModal && `margin-bottom: 2rem`};
`;

//필수입력, + 추가
export const AddMoreButton = styled.div`
  width: 100%;
  font-weight: 700;
  color: ${colors.mainBlue};
  text-align: right;
  cursor: pointer;
`;

export const Required = styled(AddMoreButton)`
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
  cursor: default;
`;

// 수정, 삭제 액션에 아이콘과 함께 있는 div ex. 수정하기 >, 처리상태 v
export const UpdateIconBox = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.mainBlue};
  font-size: 0.875rem;
  svg {
    font-size: 1rem;
  }
`;

// 저장 후 수정할 때
export const ShadowBoxInDetail = styled(StepShadowBox)`
  display: block;
  color: ${colors.gray4};
  padding: 1.75rem;
  font-size: 0.875rem;
`;

export const BoxInDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  padding-bottom: 0.75rem;
  h4 {
    font-size: 1rem;
    color: ${colors.gray5};
  }
`;

export const InputWithTextBox = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.gray3};
  padding: 0.75rem;
  border-radius: 0.5rem;
  margin-right: 4px;
  position: relative;
  input {
    width: 7rem;
  }
`;

//삭제 아이콘 absolute
export const CircleDeleteIcon = styled(IoClose)`
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: ${colors.whiteColor};
  border-radius: 100%;
  padding: 2px;
  border: 1px solid ${colors.gray2};
  color: ${colors.gray2};
  cursor: pointer;
  font-size: 1.25rem;
`;

//조회 및 필터 페이지
export const FliterBox = styled.div`
  padding-bottom: 0.75rem;
  color: ${colors.gray4};
`;

//회색 섀도우 검색창
export const MouseShadowBox = styled(StepShadowBox)<{ isModal?: boolean }>`
  width: auto;
  align-items: center;
  margin: 0 0.5rem 0 0;
  padding: 0.75rem;
  cursor: pointer;
  svg {
    font-size: 1.25rem;
    color: ${colors.mainBlue};
  }
`;

//검색 결과 수
export const ShowedCount = styled.div`
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 8px;
  color: ${colors.gray4};
`;

//조회 테이블
export const TableWrapper = styled.div`
  width: 100%;
  font-size: 1rem;
`;

export const CommonTableWrap = styled.div`
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid ${colors.gray3};
  display: flex;
  justify-content: space-between;
  text-align: left;
  cursor: pointer;
  padding: 0.75rem 1rem;
  div {
    min-width: 15%;
    padding-right: 0.5rem;
  }
  @media ${breakPoints.mobile} {
    padding: 0.75rem;
    .none {
      display: none;
    }
  }
`;

export const TableHeaderWrapper = styled(CommonTableWrap)`
  border-top: 1px solid ${colors.gray4};
  border-bottom: 1px solid ${colors.gray4};
  cursor: auto;
`;

export const NoHistoryInData = styled.div`
  height: 45vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

//회색 -> 파랑으로 변하는 버튼(참고. 디자인 시스템 -> button with icon)
export const ActiveBox = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ isActive }) => (isActive ? colors.mainBlue : colors.gray4)};
  background-color: ${({ isActive }) =>
    isActive ? m_color.primary1 : colors.gray1};
  padding: 0.75rem;
  border-radius: 0.5rem;
  cursor: pointer;
  svg {
    font-size: x-large;
    margin-right: 4px;
  }
`;

//디테일 페이지
export const HeaderArrowBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  svg {
    cursor: pointer;
    font-size: 1.5rem;
  }
  @media ${breakPoints.phones} {
    margin-bottom: 1.25rem;
  }
`;

export const HeaderIconBox = styled.div`
  img {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
  svg {
    font-size: 20px;
    vertical-align: middle;
    color: ${colors.gray4};
  }
  .mobile {
    display: none;
  }
  @media ${breakPoints.mobile} {
    display: flex;
    align-items: center;
    .mobile {
      display: flex;
      align-items: center;
    }
  }
`;

//미니 모달 기준점
export const MiniStandard = styled.div<{ isOpen: boolean }>`
  position: relative;
  cursor: pointer;
  z-index: ${({ isOpen }) => (isOpen ? 10 : 0)};
`;

//미니 모달
export const MinimunBox = styled.div<{ isOpen: boolean }>`
  width: 7.5rem;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: absolute;
  border-radius: 8px;
  top: 1.75rem;
  right: 0;
  padding: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background-color: ${colors.whiteColor};
  color: ${colors.gray4};
  ul {
    width: 100%;
    list-style: none;
  }
  li {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 4px;
    svg {
      font-size: 1.25rem;
      margin-right: 0.5rem;
      color: ${colors.mainBlue};
    }
    :hover {
      background-color: ${colors.gray1};
    }
  }
  @media ${breakPoints.mobile} {
    top: 2.25rem;
  }
`;

export const DetailHeaderTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 500;
`;

//메인 헤더 조절
export const DetailTitleBox = styled.div`
  font-size: 1.5rem;
  padding-top: 4px;
  word-break: keep-all;
  @media ${breakPoints.mobile} {
    font-size: 1.25rem;
    padding-top: 8px;
  }
`;

//작성자 혹은 게시글 업데이트 정보
export const WriterInfo = styled(Flex)`
  width: 100%;
  padding: 16px 0;
  color: ${colors.gray4};
  flex-wrap: wrap;
  border-bottom: 1px solid ${colors.gray2};
  > div {
    align-items: flex-start;
  }
  p {
    margin-right: 4px;
  }
  @media ${breakPoints.mobile} {
    flex-direction: column;
    p {
      margin-right: 1rem;
    }
    span {
      margin-right: 0;
    }
    padding-bottom: 8px;
    div:last-of-type {
      padding-bottom: 0;
    }
  }
`;

//상세 정보
export const DetailBodyBox = styled.div`
  width: 100%;
  font-size: 1.25rem;
  margin-top: 1.25rem;
`;

//상세 정보 텍스트 박스
export const FlexibleTextArea = styled.textarea<{ h: number }>`
  width: 100%;
  resize: none;
  border: none;
  min-height: 96px;
  height: ${({ h }) => `${h}px`};
  @media ${breakPoints.mobile} {
    min-height: 72px;
  }
`;

//semantic input custom css
export const DateInputWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background-color: ${colors.gray1};
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  color: ${colors.gray4};
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    font-size: 16px;
  }
  @media ${breakPoints.desktop} {
    max-width: 16rem;
  }
`;
