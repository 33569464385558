import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper.min.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { ISwiper } from "../types";

const ModalContainer = styled(ModalContent)`
  /* margin-top: 3.75rem; */
  background: none;
  box-shadow: none;
  max-width: 640px;
  height: 692px;
`;

const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .imageSlider {
    width: 100%;
    height: 640px;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
`;

const NavigateButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border: 1px solid #6b7280;
  background-color: #fff;
  color: #6b7280;
  svg {
    width: 100%;
    font-size: 24px;
  }
`;

export default function SwiperModal({ isOpen, onClose, ...props }: ISwiper) {
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const [swiperSetting, setSwiperSetting] = useState<SwiperProps | null>(null);

  useEffect(() => {
    if (!swiperSetting) {
      // console.log(nextRef.current);
      setSwiperSetting({
        navigation: {
          prevEl: prevRef.current, // 이전 버튼
          nextEl: nextRef.current, // 다음 버튼
        },
        onBeforeInit: (swiper: SwiperCore) => {
          if (typeof swiper.params.navigation !== "boolean") {
            if (swiper.params.navigation) {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
            }
          }
          swiper.navigation.update();
        },
      });
    }
  }, [swiperSetting]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContainer>
        <SliderContainer>
          <NavigateButton ref={prevRef} style={{ right: "48px" }}>
            <FiChevronLeft />
          </NavigateButton>
          <NavigateButton ref={nextRef}>
            <FiChevronRight />
          </NavigateButton>
          {swiperSetting && (
            <Swiper
              loop={true}
              slidesPerView={1}
              modules={[Navigation]}
              // onSwiper={(swiper) => console.log(swiper)}
              {...swiperSetting}
            >
              {props.data.map((el) => (
                <SwiperSlide className="imageSlider" key={uuidv4()}>
                  <img src={el} />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </SliderContainer>
      </ModalContainer>
    </Modal>
  );
}
